import React from 'react';
import { Switch } from 'react-router-dom';
import RouteWrapper from './Route';

import Dashboard from '~/pages/Dashboard';

// Animals Inputs
import AnimalInput from '~/pages/Animal/Input/Lot';
import AnimalInputRegister from '~/pages/Animal/Input/Lot/Register';
import AnimalInputEdit from '~/pages/Animal/Input/Lot/Edit';

// Animals Inputs aparte
import AnimalInputLotApart from '~/pages/AnimalInputLotApart';
import AnimalInputLotApartAdd from '~/pages/AnimalInputLotApart/Add';
import AnimalInputLotApartView from '~/pages/AnimalInputLotApart/View';

import AnimalInputUnity from '~/pages/AnimalUnity/AnimalInputUnity';
import IndividualAnimalInputEdit from '~/pages/AnimalUnity/AnimalInputUnity/Edit';
import IndividualAnimalInputAdd from '~/pages/AnimalUnity/AnimalInputUnity/Add';

// Animals Outputs
import AnimalOutput from '~/pages/Animal/Output';
import AnimalOutputAdd from '~/pages/Animal/Output/Add';
import AnimalOutputEdit from '~/pages/Animal/Output/Edit';

// Animals ScrapOutput
import AnimalScrapOutput from '~/pages/Animal/ScrapOutput';
import AnimalScrapOutputRegister from '~/pages/Animal/ScrapOutput/Register';
import AnimalScrapOutputEdit from '~/pages/Animal/ScrapOutput/Edit';

// Animals DeathOutput
import AnimalDeathOutput from '~/pages/Animal/DeathOutput';
import AnimalDeathOutputRegister from '~/pages/Animal/DeathOutput/Register';
import AnimalDeathOutputEdit from '~/pages/Animal/DeathOutput/Edit';

// Animals TransferLot
import AnimalTransferLot from '~/pages/Animal/Transfer/Lot';
import AnimalTransferLotRegister from '~/pages/Animal/Transfer/Lot/Register';

// Animals TransferPaddock
import AnimalTransferPaddock from '~/pages/Animal/Transfer/Paddock';
import AnimalTransferPaddockRegister from '~/pages/Animal/Transfer/Paddock/Register';

// Breed
import Breed from '~/pages/Breed';
import BreedRegister from '~/pages/Breed/Register';

// Cause of Death
import CauseOfDeath from '~/pages/CauseOfDeath';
import CauseOfDeathRegister from '~/pages/CauseOfDeath/Register';

// Diet
import Diet from '~/pages/Diet';
import DietAdd from '~/pages/Diet/Add';
import DietRegister from '~/pages/Diet/Register';

// DietSwitch
import DietSwitch from '~/pages/DietSwitch';
import DietSwitchRegister from '~/pages/DietSwitch/Register';

// Employee
import Employee from '~/pages/Employee';
import EmployeeRegister from '~/pages/Employee/Register';

// Feed
import Feed from '~/pages/Feed';
import FeedAdd from '~/pages/Feed/Add';
import FeedRegister from '~/pages/Feed/Register';
import ManufactoringFeed from '~/pages/ManufactoringFeed';
import ManufactoringFeedAdd from '~/pages/ManufactoringFeed/Register';
import ManufactoringFeedEdit from '~/pages/ManufactoringFeed/Edit';

// Food
import Food from '~/pages/Food';
import FoodRegister from '~/pages/Food/Register';

// FoodCorrection
import FoodCorrection from '~/pages/FoodCorrection';
import FoodCorrectionAdd from '~/pages/FoodCorrection/Add';

// Lots
import Lots from '~/pages/Lots';
import LostsDetail from '~/pages/Lots/DetailLot';

// Animals
import Animals from '~/pages/Animals';
import AnimalsEdit from '~/pages/Animals/Edit';
import AnimalsEditInLot from '~/pages/Animals/EditInLot';

// OcurrencesType
import OcurrencesType from '~/pages/OcurrencesType';
import OcurrencesTypeAdd from '~/pages/OcurrencesType/Add';
import OcurrencesTypeEdit from '~/pages/OcurrencesType/Edit';

// Ocurrences
import Ocurrences from '~/pages/Ocurrences';
import OcurrencesAdd from '~/pages/Ocurrences/Add';
import OcurrencesView from '~/pages/Ocurrences/View';

// ManufactoringDiet
import ManufactoringDiet from '~/pages/ManufactoringDiet';
import ManufactoringDietRegister from '~/pages/ManufactoringDiet/Register';
import ManufactoringDietRegisterPerDay from '~/pages/ManufactoringDiet/RegisterPerDay';

// Module
import Module from '~/pages/Module';
import ModuleRegister from '~/pages/Module/Register';

// MS
import MSBeat from '~/pages/MSBeat';
import MSCorrection from '~/pages/MSCorrection';
import MSCorrectionAdd from '~/pages/MSCorrection/Add';

// OperationalCost
import OperationalCost from '~/pages/OperationalCost';
import OperationalCostRegister from '~/pages/OperationalCost/Register';
import OperationalCostEdit from '~/pages/OperationalCost/Edit';

// ActionsLogs
import ActionsLogs from '~/pages/ActionsLogs';

// SanitaryCost
import SanitaryCost from '~/pages/SanitaryCost';
import SanitaryCostRegister from '~/pages/SanitaryCost/Register';
import SanitaryCostEdit from '~/pages/SanitaryCost/Edit';

// Setting
import Setting from '~/pages/Setting';

// Treatment
import ForecastTreatment from '~/pages/Treatment/Forecast';
import PerformedTreatment from '~/pages/Treatment/Performed';

// TreatySetting
import TreatySetting from '~/pages/TreatySetting';
import TreatySettingRegister from '~/pages/TreatySetting/Register';

// TroughReading
import TroughReading from '~/pages/TroughReading';
import TroughReadingAdd from '~/pages/TroughReading/Add';
import TroughReadingLitsDietSwitches from '~/pages/TroughReading/LitsDietSwitches';

// TroughSetting
import TroughSetting from '~/pages/TroughSetting';
import TroughSettingRegister from '~/pages/TroughSetting/Register';

// Profile
import Profile from '~/pages/Profile';

// PurchaseFoods
import PurchaseFoods from '~/pages/PurchaseFoods';
import PurchaseFoodsAdd from '~/pages/PurchaseFoods/Add';
import PurchaseFoodsEdit from '~/pages/PurchaseFoods/Edit';

// Stackholder
import Stakeholder from '~/pages/Stakeholder';
import StakeholderAdd from '~/pages/Stakeholder/Add';
import StakeholderEdit from '~/pages/Stakeholder/Edit';

// Intermediate Weighing
import IntermediateWeighing from '~/pages/IntermediateWeighing';
import IntermediateWeighingAdd from '~/pages/IntermediateWeighing/Add';

// Intermediate Weighing individual
import IntermediateWeightAnimal from '~/pages/IntermediateWeightAnimal';
import IntermediateWeightAnimalAdd from '~/pages/IntermediateWeightAnimal/Add';
import AddInputWeightCorrectionIndividual from '~/pages/IntermediateWeightAnimal/AddInputWeightCorrectionIndividual';

// InputWeightCorrection
import InputWeightCorrectionAdd from '~/pages/IntermediateWeighing/AddInputWeightCorrection';

// Reports
import ConsumptionLot from '~/pages/Reports/ConsumptionLot';
import DietManufacturingEfficiency from '~/pages/Reports/DietManufacturingEfficiency';
import EfficiencyTreatment from '~/pages/Reports/EfficiencyTreatment';
import HistoricFood from '~/pages/Reports/HistoricFood';
import ActiveLot from '~/pages/Reports/ActiveLot';
import ActivePaddock from '~/pages/Reports/ActivePaddock';
import ReportAnimalOutput from '~/pages/Reports/AnimalOutput';
import SummaryFood from '~/pages/Reports/SummaryFood';
import ZootechnicalLot from '~/pages/Reports/ZootechnicalLot';
import ZootechnicalLotResume from '~/pages/Reports/ZootechnicalLotResume';
import EconomicClosing from '~/pages/Reports/EconomicClosing';
import EconomicClosingResume from '~/pages/Reports/EconomicClosingResume';
import SlaughterForecast from '~/pages/Reports/SlaughterForecast';

// individual animals
import IndividualAnimalTransferLot from '~/pages/AnimalUnity/Transfer/Lot';
import IndividualAnimalTransferLotRegister from '~/pages/AnimalUnity/Transfer/Lot/Register';
import IndividualAnimalTransferLotView from '~/pages/AnimalUnity/Transfer/Lot/View';
import IndividualAnimalOutput from '~/pages/AnimalUnity/Output';
import EditIndividualAnimalsInfos from '~/pages/AnimalUnity/Output/EditIndividualAnimalsInfos';
import IndividualAnimalOutputRegister from '~/pages/AnimalUnity/Output/Register';
import IndividualAnimalScrapOutput from '~/pages/AnimalUnity/ScrapOutput';
import IndividualAnimalScrapOutputRegister from '~/pages/AnimalUnity/ScrapOutput/Register';
import IndividualAnimalDeathOutputRegister from '~/pages/AnimalUnity/DeathOutput/Register';
import IndividualAnimalDeathOutput from '~/pages/AnimalUnity/DeathOutput';

import Users from '~/pages/Users';
import UserRegister from '~/pages/Users/Register';

export default function DashboardRoutes() {
  return (
    <Switch>
      <RouteWrapper path="/dashboard" exact isPrivate component={Dashboard} />

      {/* Animals Input */}
      <RouteWrapper
        path="/dashboard/animalInput"
        exact
        component={AnimalInput}
        isPrivate
        permissions={['animalInput_view']}
      />
      <RouteWrapper
        path="/dashboard/animalInput/add"
        component={AnimalInputRegister}
        isPrivate
        permissions={['animalInput_new']}
      />
      <RouteWrapper
        path="/dashboard/animalInput/edit/:id"
        component={AnimalInputEdit}
        isPrivate
        permissions={['animalInput_edit']}
      />
      <RouteWrapper
        path="/dashboard/animalInputUnity"
        exact
        component={AnimalInputUnity}
        isPrivate
        permissions={['animalInput_view']}
      />
      <RouteWrapper
        path="/dashboard/animalInputUnity/add"
        component={IndividualAnimalInputAdd}
        isPrivate
        permissions={['animalInput_new']}
      />
      <RouteWrapper
        path="/dashboard/animalInputUnity/edit/:id"
        component={IndividualAnimalInputEdit}
        isPrivate
        permissions={['animalInput_edit']}
      />
      <RouteWrapper
        path="/dashboard/animalInputApart"
        exact
        component={AnimalInputLotApart}
        isPrivate
        permissions={['animalInput_view']}
      />
      <RouteWrapper
        path="/dashboard/animalInputApart/Add"
        exact
        component={AnimalInputLotApartAdd}
        isPrivate
        permissions={['animalInput_new']}
      />
      <RouteWrapper
        path="/dashboard/animalInputApart/view/:id"
        exact
        component={AnimalInputLotApartView}
        isPrivate
        permissions={['animalInput_view']}
      />

      {/* Animals Output */}
      <RouteWrapper
        path="/dashboard/animalOutput"
        exact
        component={AnimalOutput}
        isPrivate
        permissions={['animalOutput_view']}
      />
      <RouteWrapper
        path="/dashboard/animalOutput/add"
        component={AnimalOutputAdd}
        isPrivate
        permissions={['animalOutput_new']}
      />
      <RouteWrapper
        path="/dashboard/animalOutput/edit/:id"
        component={AnimalOutputEdit}
        isPrivate
        permissions={['animalOutput_edit']}
      />

      {/* Animals ScrapOutput */}
      <RouteWrapper
        path="/dashboard/animalScrapOutput"
        component={AnimalScrapOutput}
        exact
        isPrivate
        permissions={['animalScrapOutput_view']}
      />
      <RouteWrapper
        path="/dashboard/animalScrapOutput/edit/:id"
        component={AnimalScrapOutputEdit}
        isPrivate
        permissions={['animalScrapOutput_edit']}
      />
      <RouteWrapper
        path="/dashboard/animalScrapOutput/add"
        component={AnimalScrapOutputRegister}
        isPrivate
        permissions={['animalScrapOutput_new']}
      />

      {/* Animals DeathOutput */}
      <RouteWrapper
        path="/dashboard/animalDeathOutput"
        component={AnimalDeathOutput}
        exact
        isPrivate
        permissions={['animalDeathOutput_view']}
      />
      <RouteWrapper
        path="/dashboard/animalDeathOutput/edit/:id"
        component={AnimalDeathOutputEdit}
        isPrivate
        permissions={['animalDeathOutput_edit']}
      />
      <RouteWrapper
        path="/dashboard/animalDeathOutput/add"
        component={AnimalDeathOutputRegister}
        isPrivate
        permissions={['animalDeathOutput_new']}
      />

      {/* Animals TransferLot */}
      <RouteWrapper
        path="/dashboard/animalTransferLot"
        exact
        component={AnimalTransferLot}
        isPrivate
        permissions={['animalTransferLot_view']}
      />
      <RouteWrapper
        path="/dashboard/animalTransferLot/add"
        component={AnimalTransferLotRegister}
        isPrivate
        permissions={['animalTransferLot_new']}
      />
      <RouteWrapper
        path="/dashboard/animalTransferLot/edit/:id"
        component={AnimalTransferLotRegister}
        isPrivate
        permissions={['animalTransferLot_edit']}
      />

      {/* Animals TransferPaddock */}
      <RouteWrapper
        path="/dashboard/animalTransferPaddock"
        exact
        component={AnimalTransferPaddock}
        isPrivate
        permissions={['animalTransferPaddock_view']}
      />
      <RouteWrapper
        path="/dashboard/animalTransferPaddock/add"
        component={AnimalTransferPaddockRegister}
        isPrivate
        permissions={['animalTransferPaddock_new']}
      />
      <RouteWrapper
        path="/dashboard/animalTransferPaddock/edit/:id"
        component={AnimalTransferPaddockRegister}
        isPrivate
        permissions={['animalTransferPaddock_edit']}
      />

      {/* Breed */}
      <RouteWrapper
        path="/dashboard/breed"
        exact
        component={Breed}
        isPrivate
        permissions={['breed_view']}
      />
      <RouteWrapper
        path="/dashboard/breed/add"
        component={BreedRegister}
        isPrivate
        permissions={['breed_new']}
      />
      <RouteWrapper
        path="/dashboard/breed/edit/:id"
        component={BreedRegister}
        isPrivate
        permissions={['breed_edit']}
      />

      {/* Diet */}
      <RouteWrapper
        path="/dashboard/diet"
        exact
        component={Diet}
        isPrivate
        permissions={['diet_view']}
      />
      <RouteWrapper
        path="/dashboard/diet/add"
        component={DietAdd}
        isPrivate
        permissions={['diet_new']}
      />
      <RouteWrapper
        path="/dashboard/diet/edit/:id"
        component={DietRegister}
        isPrivate
        permissions={['diet_edit']}
      />
      <RouteWrapper
        path="/dashboard/diet/view/:id"
        component={DietRegister}
        isPrivate
      />

      {/* Diet Switch */}
      <RouteWrapper
        path="/dashboard/dietSwitch"
        exact
        component={DietSwitch}
        isPrivate
        permissions={['dietSwitch_view']}
      />
      <RouteWrapper
        path="/dashboard/dietSwitch/add"
        component={DietSwitchRegister}
        isPrivate
        permissions={['dietSwitch_new']}
      />

      {/* Feed */}
      <RouteWrapper
        path="/dashboard/feed"
        exact
        component={Feed}
        isPrivate
        permissions={['feed_view']}
      />
      <RouteWrapper
        path="/dashboard/feed/add"
        component={FeedAdd}
        isPrivate
        permissions={['feed_new']}
      />
      <RouteWrapper
        path="/dashboard/feed/edit/:id"
        component={FeedRegister}
        isPrivate
        permissions={['feed_edit']}
      />

      {/* IntermediateWeighing */}
      <RouteWrapper
        path="/dashboard/intermediateWeight"
        exact
        component={IntermediateWeighing}
        isPrivate
        permissions={['intermediateWeight_view']}
      />
      <RouteWrapper
        path="/dashboard/intermediateWeight/add"
        component={IntermediateWeighingAdd}
        isPrivate
        permissions={['intermediateWeight_new']}
      />

      {/* InputWeightCorrection */}
      <RouteWrapper
        path="/dashboard/intermediateWeight/addInputWeightCorrection"
        component={InputWeightCorrectionAdd}
        isPrivate
        permissions={['intermediateWeight_new']}
      />

      <RouteWrapper
        path="/dashboard/intermediateWeightAnimal"
        exact
        component={IntermediateWeightAnimal}
        isPrivate
        permissions={['intermediateWeight_view']}
      />

      <RouteWrapper
        path="/dashboard/intermediateWeightAnimal/add"
        exact
        component={IntermediateWeightAnimalAdd}
        isPrivate
        permissions={['intermediateWeight_new']}
      />

      <RouteWrapper
        path="/dashboard/intermediateWeightAnimal/addInputWeightCorrectionAnimal"
        component={AddInputWeightCorrectionIndividual}
        isPrivate
        permissions={['intermediateWeight_new']}
      />

      {/* Lots */}
      <RouteWrapper
        path="/dashboard/lots"
        exact
        component={Lots}
        isPrivate
        permissions={['lots_view']}
      />
      <RouteWrapper
        path="/dashboard/lots/detail/:id"
        component={LostsDetail}
        isPrivate
      />

      {/* Animals */}
      <RouteWrapper
        path="/dashboard/animals"
        exact
        component={Animals}
        isPrivate
        permissions={['animals_view']}
      />
      <RouteWrapper
        path="/dashboard/animals/edit/:id"
        component={AnimalsEdit}
        isPrivate
        permissions={['animals_edit']}
      />
      <RouteWrapper
        path="/dashboard/animals/editInLot"
        component={AnimalsEditInLot}
        isPrivate
        permissions={['animals_edit']}
      />

      {/* OcurrencesType */}
      <RouteWrapper
        path="/dashboard/ocurrencesType"
        exact
        component={OcurrencesType}
        isPrivate
        permissions={['ocurrences_view']}
      />
      <RouteWrapper
        path="/dashboard/ocurrencesType/add"
        component={OcurrencesTypeAdd}
        isPrivate
        permissions={['ocurrences_new']}
      />
      <RouteWrapper
        path="/dashboard/ocurrencesType/edit/:id"
        component={OcurrencesTypeEdit}
        isPrivate
        permissions={['ocurrences_edit']}
      />

      {/* Ocurrences */}
      <RouteWrapper
        path="/dashboard/ocurrences"
        exact
        component={Ocurrences}
        isPrivate
        permissions={['ocurrences_view']}
      />
      <RouteWrapper
        path="/dashboard/ocurrences/add"
        component={OcurrencesAdd}
        isPrivate
        permissions={['ocurrences_new']}
      />
      <RouteWrapper
        path="/dashboard/ocurrences/view/:id"
        component={OcurrencesView}
        isPrivate
        permissions={['ocurrences_view']}
      />

      {/* Manufactoring Diet */}
      <RouteWrapper
        path="/dashboard/manufactoringDiet"
        exact
        component={ManufactoringDiet}
        isPrivate
        permissions={['manufactoringDiet_view']}
      />
      <RouteWrapper
        path="/dashboard/manufactoringDiet/add"
        component={ManufactoringDietRegister}
        isPrivate
        permissions={['manufactoringDiet_new']}
      />
      <RouteWrapper
        path="/dashboard/manufactoringDiet/addPerDay"
        component={ManufactoringDietRegisterPerDay}
        isPrivate
        permissions={['manufactoringDiet_new']}
      />
      <RouteWrapper
        path="/dashboard/manufactoringDiet/edit/:id"
        component={ManufactoringDietRegister}
        isPrivate
        permissions={['manufactoringDiet_edit']}
      />

      {/* ManufactoringFeed */}
      <RouteWrapper
        path="/dashboard/manufactoringFeed"
        exact
        component={ManufactoringFeed}
        isPrivate
        permissions={['manufactoringFeed_view']}
      />
      <RouteWrapper
        path="/dashboard/manufactoringFeed/add"
        component={ManufactoringFeedAdd}
        isPrivate
        permissions={['manufactoringFeed_new']}
      />
      <RouteWrapper
        path="/dashboard/manufactoringFeed/edit/:id"
        component={ManufactoringFeedEdit}
        isPrivate
        permissions={['manufactoringFeed_edit']}
      />

      {/* Employee */}
      <RouteWrapper
        path="/dashboard/employee"
        exact
        component={Employee}
        isPrivate
        permissions={['employee_view']}
      />
      <RouteWrapper
        path="/dashboard/employee/add"
        component={EmployeeRegister}
        isPrivate
        permissions={['employee_new']}
      />
      <RouteWrapper
        path="/dashboard/employee/edit/:id"
        component={EmployeeRegister}
        isPrivate
        permissions={['employee_edit']}
      />

      {/* Food */}
      <RouteWrapper
        path="/dashboard/food"
        exact
        component={Food}
        isPrivate
        permissions={['food_view']}
      />
      <RouteWrapper
        path="/dashboard/food/add"
        component={FoodRegister}
        isPrivate
        permissions={['food_new']}
      />
      <RouteWrapper
        path="/dashboard/food/edit/:id"
        component={FoodRegister}
        isPrivate
        permissions={['food_edit']}
      />

      {/* FoodCorrection */}
      <RouteWrapper
        path="/dashboard/foodCorrection"
        exact
        component={FoodCorrection}
        isPrivate
        permissions={['foodCorrection_view']}
      />
      <RouteWrapper
        path="/dashboard/foodCorrection/add"
        component={FoodCorrectionAdd}
        isPrivate
        permissions={['foodCorrection_new']}
      />

      {/* Module */}
      <RouteWrapper
        path="/dashboard/module"
        exact
        component={Module}
        isPrivate
        permissions={['module_view']}
      />
      <RouteWrapper
        path="/dashboard/module/add"
        component={ModuleRegister}
        isPrivate
        permissions={['module_new']}
      />
      <RouteWrapper
        path="/dashboard/module/edit/:id"
        component={ModuleRegister}
        isPrivate
        permissions={['module_edit']}
      />

      {/* MS Beat */}
      <RouteWrapper
        path="/dashboard/msBeat"
        exact
        component={MSBeat}
        isPrivate
        permissions={['msBeat_view']}
      />

      {/* MS Correction */}
      <RouteWrapper
        path="/dashboard/msCorrection"
        exact
        component={MSCorrection}
        isPrivate
        permissions={['msCorrection_view']}
      />
      <RouteWrapper
        path="/dashboard/msCorrection/add"
        component={MSCorrectionAdd}
        isPrivate
        permissions={['msCorrection_new']}
      />

      {/* Operational Cost */}
      <RouteWrapper
        path="/dashboard/operationalCost"
        exact
        component={OperationalCost}
        isPrivate
        permissions={['operationalCost_view']}
      />
      <RouteWrapper
        path="/dashboard/operationalCost/add"
        component={OperationalCostRegister}
        isPrivate
        permissions={['operationalCost_new']}
      />
      <RouteWrapper
        path="/dashboard/operationalCost/edit/:id"
        exact
        component={OperationalCostEdit}
        isPrivate
        permissions={['operationalCost_edit']}
      />

      {/* Actions Log */}
      <RouteWrapper
        path="/dashboard/actionsLogs"
        exact
        component={ActionsLogs}
        isPrivate
        roles={['administrator', 'beefer']}
      />

      {/* Sanitary Cost */}
      <RouteWrapper
        path="/dashboard/sanitaryCost"
        exact
        component={SanitaryCost}
        isPrivate
        permissions={['sanitaryCost_view']}
      />
      <RouteWrapper
        path="/dashboard/sanitaryCost/add"
        component={SanitaryCostRegister}
        isPrivate
        permissions={['sanitaryCost_new']}
      />
      <RouteWrapper
        path="/dashboard/sanitaryCost/edit/:id"
        exact
        component={SanitaryCostEdit}
        isPrivate
        permissions={['sanitaryCost_edit']}
      />

      {/* Treatment */}
      <RouteWrapper
        path="/dashboard/treatmentForecast"
        exact
        component={ForecastTreatment}
        isPrivate
        permissions={['treatmentForecast_view']}
      />
      <RouteWrapper
        path="/dashboard/treatmentPerformed"
        exact
        component={PerformedTreatment}
        isPrivate
        permissions={['treatmentPerformed_view']}
      />

      {/* TreatySetting */}
      <RouteWrapper
        path="/dashboard/treatySetting"
        exact
        component={TreatySetting}
        isPrivate
        permissions={['treatySetting_view']}
      />
      <RouteWrapper
        path="/dashboard/treatySetting/add"
        exact
        component={TreatySettingRegister}
        isPrivate
        permissions={['treatySetting_new']}
      />
      <RouteWrapper
        path="/dashboard/treatySetting/edit/:id"
        exact
        component={TreatySettingRegister}
        isPrivate
        permissions={['treatySetting_edit']}
      />

      {/* TroughReading */}
      <RouteWrapper
        path="/dashboard/troughReading"
        exact
        component={TroughReading}
        isPrivate
        permissions={['troughReading_view']}
      />
      <RouteWrapper
        path="/dashboard/troughReading/add"
        exact
        component={TroughReadingAdd}
        isPrivate
        permissions={['troughReading_new']}
      />
      <RouteWrapper
        path="/dashboard/troughReading/dietSwitch"
        exact
        component={TroughReadingLitsDietSwitches}
        isPrivate
        permissions={['troughReading_new']}
      />

      <RouteWrapper
        path="/dashboard/causeOfDeath"
        exact
        component={CauseOfDeath}
        isPrivate
        permissions={['causeOfDeath_view']}
      />
      <RouteWrapper
        path="/dashboard/causeOfDeath/edit/:id"
        exact
        component={CauseOfDeathRegister}
        isPrivate
        permissions={['causeOfDeath_edit']}
      />

      <RouteWrapper
        path="/dashboard/causeOfDeath/add"
        exact
        component={CauseOfDeathRegister}
        isPrivate
        permissions={['causeOfDeath_new']}
      />

      {/* TroughSetting */}
      <RouteWrapper
        path="/dashboard/troughSetting"
        exact
        component={TroughSetting}
        isPrivate
        permissions={['troughSetting_view']}
      />
      <RouteWrapper
        path="/dashboard/troughSetting/add"
        exact
        component={TroughSettingRegister}
        isPrivate
        permissions={['troughSetting_new']}
      />
      <RouteWrapper
        path="/dashboard/troughSetting/edit/:id"
        exact
        component={TroughSettingRegister}
        isPrivate
        permissions={['troughSetting_edit']}
      />

      {/* Profile  */}
      <RouteWrapper
        path="/dashboard/profile"
        exact
        component={Profile}
        isPrivate
      />

      {/* PurchaseFoods */}
      <RouteWrapper
        path="/dashboard/purchaseFoods"
        exact
        component={PurchaseFoods}
        isPrivate
        permissions={['purchaseFoods_view']}
      />
      <RouteWrapper
        path="/dashboard/purchaseFoods/add"
        component={PurchaseFoodsAdd}
        isPrivate
        permissions={['purchaseFoods_new']}
      />
      <RouteWrapper
        path="/dashboard/purchaseFoods/edit/:id"
        component={PurchaseFoodsEdit}
        isPrivate
        permissions={['purchaseFoods_edit']}
      />

      {/* Setting */}
      <RouteWrapper
        path="/dashboard/setting"
        exact
        component={Setting}
        isPrivate
      />

      {/* Stakeholder */}
      <RouteWrapper
        path="/dashboard/stakeholder"
        exact
        component={Stakeholder}
        isPrivate
        permissions={['stakeholder_view']}
      />
      <RouteWrapper
        path="/dashboard/stakeholder/add"
        component={StakeholderAdd}
        isPrivate
        permissions={['stakeholder_new']}
      />
      <RouteWrapper
        path="/dashboard/stakeholder/edit/:id"
        component={StakeholderEdit}
        isPrivate
        permissions={['stakeholder_edit']}
      />

      {/* Reports */}
      <RouteWrapper
        path="/dashboard/reportActiveLot"
        component={ActiveLot}
        isPrivate
        permissions={['reportActiveLot_view']}
      />
      <RouteWrapper
        path="/dashboard/reportActivePaddock"
        component={ActivePaddock}
        isPrivate
        permissions={['reportActiveLot_view']}
      />
      <RouteWrapper
        path="/dashboard/reportConsumptionLot"
        component={ConsumptionLot}
        isPrivate
        permissions={['reportConsumptionLot_view']}
      />
      <RouteWrapper
        path="/dashboard/reportDietManufacturingEfficiency"
        component={DietManufacturingEfficiency}
        isPrivate
        permissions={['reportDietManufacturingEfficiency_view']}
      />
      <RouteWrapper
        path="/dashboard/reportEfficiencyTreatment"
        component={EfficiencyTreatment}
        isPrivate
        permissions={['reportEfficiencyTreatment_view']}
      />
      <RouteWrapper
        path="/dashboard/reportAnimalOutput"
        component={ReportAnimalOutput}
        isPrivate
        permissions={['reportAnimalOutput_view']}
      />
      <RouteWrapper
        path="/dashboard/reportHistoricFood"
        component={HistoricFood}
        isPrivate
        permissions={['reportHistoricFood_view']}
      />
      <RouteWrapper
        path="/dashboard/reportSummaryFood"
        component={SummaryFood}
        isPrivate
        permissions={['reportSummaryFood_view']}
      />
      <RouteWrapper
        path="/dashboard/reportZootechnicalLot"
        component={ZootechnicalLot}
        isPrivate
        permissions={['reportZootechnicalLot_view']}
      />
      <RouteWrapper
        path="/dashboard/reportZootechnicalLotResume"
        component={ZootechnicalLotResume}
        isPrivate
        permissions={['reportZootechnicalLot_view']}
      />
      <RouteWrapper
        path="/dashboard/reportEconomicClosing"
        component={EconomicClosing}
        isPrivate
        permissions={['reportEconomicClosing_view']}
      />
      <RouteWrapper
        path="/dashboard/reportEconomicClosingResume"
        component={EconomicClosingResume}
        isPrivate
        permissions={['reportEconomicClosing_view']}
      />
      <RouteWrapper
        path="/dashboard/slaughterForecast"
        component={SlaughterForecast}
        isPrivate
        permissions={['slaughterForecast_view']}
      />

      {/* Individual Animals transfer */}
      <RouteWrapper
        path="/dashboard/individualAnimalTransferLot"
        exact
        component={IndividualAnimalTransferLot}
        isPrivate
        permissions={['animalTransferLot_view']}
      />
      <RouteWrapper
        path="/dashboard/individualAnimalTransferLot/add"
        component={IndividualAnimalTransferLotRegister}
        isPrivate
        permissions={['animalTransferLot_new']}
      />
      <RouteWrapper
        path="/dashboard/individualAnimalTransferLot/view/:id"
        component={IndividualAnimalTransferLotView}
        isPrivate
        permissions={['animalTransferLot_view']}
      />

      {/* Individual Animals output */}
      <RouteWrapper
        path="/dashboard/individualAnimalOutput"
        exact
        component={IndividualAnimalOutput}
        isPrivate
        permissions={['animalOutput_view']}
      />
      <RouteWrapper
        path="/dashboard/individualAnimalOutput/edit/:id"
        exact
        component={EditIndividualAnimalsInfos}
        isPrivate
        permissions={['animalOutput_edit']}
      />
      <RouteWrapper
        path="/dashboard/individualAnimalOutput/add"
        component={IndividualAnimalOutputRegister}
        isPrivate
        permissions={['animalOutput_new']}
      />
      <RouteWrapper
        path="/dashboard/individualAnimalOutput/edit"
        component={IndividualAnimalOutputRegister}
        isPrivate
        permissions={['animalOutput_edit']}
      />
      {/* <RouteWrapper
        path="/dashboard/individualAnimalOutputGta"
        exact
        component={IndividualAnimalOutputGta}
        isPrivate
        permissions={['animalOutput_view']}
      /> */}
      {/* <RouteWrapper
        path="/dashboard/individualAnimalOutputGta/add"
        component={IndividualAnimalOutputRegisterGta}
        isPrivate
        permissions={['animalOutput_new']}
      /> */}
      {/* <RouteWrapper
        path="/dashboard/individualAnimalOutputGta/edit"
        component={IndividualAnimalOutputRegisterGta}
        isPrivate
        permissions={['animalOutput_edit']}
      /> */}

      {/* Individual Animals ScrapOutput */}
      <RouteWrapper
        path="/dashboard/individualAnimalScrapOutput"
        component={IndividualAnimalScrapOutput}
        exact
        isPrivate
        permissions={['animalScrapOutput_view']}
      />
      {/* <RouteWrapper
        path="/dashboard/individualAnimalScrapOutput/edit"
        component={IndividualAnimalScrapOutputRegister}
        isPrivate
        permissions={['animalScrapOutput_edit']}
      /> */}
      <RouteWrapper
        path="/dashboard/individualAnimalScrapOutput/add"
        component={IndividualAnimalScrapOutputRegister}
        isPrivate
        permissions={['animalScrapOutput_new']}
      />

      {/* Individual Animals AnimalDeathOutput */}
      <RouteWrapper
        path="/dashboard/individualAnimalDeathOutput"
        component={IndividualAnimalDeathOutput}
        exact
        isPrivate
        permissions={['animalDeathOutput_view']}
      />
      {/* <RouteWrapper
        path="/dashboard/individualAnimalDeathOutput/edit"
        component={IndividualAnimalDeathOutputRegister}
        isPrivate
        permissions={['animalDeathOutput_edit']}
      /> */}
      <RouteWrapper
        path="/dashboard/individualAnimalDeathOutput/add"
        component={IndividualAnimalDeathOutputRegister}
        isPrivate
        permissions={['animalDeathOutput_new']}
      />

      <RouteWrapper
        exact
        path="/dashboard/users"
        component={Users}
        isPrivate
        permissions={['user_view']}
      />

      <RouteWrapper
        path="/dashboard/users/add"
        component={UserRegister}
        isPrivate
        permissions={['user_new']}
      />

      <RouteWrapper
        path="/dashboard/users/edit/:id"
        component={UserRegister}
        isPrivate
        permissions={['user_edit']}
      />
    </Switch>
  );
}

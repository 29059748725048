import styled from 'styled-components';
import { Paper } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledPaper = styled(Paper)`
  padding: 10px;
  max-width: 800px;
  width: 100%;
  margin-bottom: 28px;
`;

export const ContentDate = styled.div`
  min-width: 144px;
`;

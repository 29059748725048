import React, { useState, useEffect } from 'react';

import {
  Grid,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
  MenuItem
} from '@material-ui/core';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@material-ui/icons/Delete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Autocomplete } from '@material-ui/lab';
import {
  parseISO,
} from 'date-fns';
import ShowAnimal from '../Components/ShowAnimal';
import {
  Container,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
  StyledSubmitButton,
  Error,
  ContentAnimals
} from './styles';

// Query
import { useApiV2ShowDataTransfer } from '~/hooks/apiV2/animalHandling/IndividualAnimalTransfer/useApiV2ShowDataTransfer';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';
import {
  useApiV2AddIndividualAnimalTransfer
} from '~/hooks/apiV2/animalHandling/IndividualAnimalTransfer/useApiV2AddIndividualAnimalTransfer';

import ButtonSubmit from '~/components/ButtonSubmit';
import Can from '~/components/Can';
import ButtonTable from '~/components/ButtonTable';
import colors from '~/styles/colors';
import InputDatePicker from '~/components/InputDatePicker';
import history from '~/services/history';
import { formatNamePaddock } from '~/utils/format';
import Loader from '~/components/Loader';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.value,
});

const schema = Yup.object().shape({
  // adiconar
});

export default function Register() {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [animals, setAnimals] = useState([]);

  const [currentLot, setCurrentLot] = useState({});

  const [listLots, setListLots] = useState([]);
  const [lots, setLots] = useState([]);

  const [listLine, setListLine] = useState([]);
  const [listPaddock, setListPaddock] = useState([]);
  const [diets, setDiets] = useState([]);
  const [modules, setModules] = useState([]);

  const [disabledDietOptions, setDisabledDietOptions] = useState(false);
  const [disabledModuleOptions, setDisabledModuleOptions] = useState(false);

  // Query
  const { data: lotsData, isLoading: isLoadingData } = useApiV2ShowDataTransfer();
  const { data: settings, isLoading: isLoadingSettings } = useApiV2GlobalSetting();
  const addIndividualAnimalTransferLot = useApiV2AddIndividualAnimalTransfer();

  useEffect(() => {
    if (lotsData) {
      const newLots = lotsData.optionsLots.map(lot => ({
        secure_id: lot.secure_id,
        value: lot.name
      }));

      setListLots(newLots);
      setLots(lotsData.optionsLots);
      setModules(lotsData.optionsModules);
      setDiets(lotsData.optionsDiets);
    }
  }, [lotsData]);

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleRemove(secure_id) {
    setAnimals(animals.filter(animal => animal.secure_id !== secure_id));
  }

  function handleSelectModule(event, setFieldValue) {
    const currentModule = modules.find(
      module => module.secure_id === event.target.value
    );

    if (currentModule) {
      setFieldValue('module_id', currentModule.secure_id);
      setFieldValue('line_id', '');
      setFieldValue('paddock_id', '');
      setListLine(currentModule.lines);
    } else {
      setFieldValue('module_id', '');
      setFieldValue('line_id', '');
      setFieldValue('paddock_id', '');
      setListLine([]);
    }
  }

  function handleSelectLine(event, setFieldValue) {
    const currentLine = listLine.find(
      line => line.secure_id === event.target.value
    );

    if (currentLine) {
      setFieldValue('line_id', currentLine.secure_id);
      setFieldValue('paddock_id', '');
      setListPaddock(currentLine.paddocks);
    } else {
      setFieldValue('line_id', '');
      setFieldValue('paddock_id', '');
      setListPaddock([]);
    }
  }

  function handleSelectPaddock(event, setFieldValue) {
    const currentPaddock = listPaddock.find(
      paddock => paddock.secure_id === event.target.value
    );

    if (currentPaddock) {
      setFieldValue('paddock_id', currentPaddock.secure_id);

      if (currentPaddock.diet) {
        setFieldValue('diet_id', currentPaddock.diet.secure_id);
        setDisabledDietOptions(true);
      }
    } else {
      setFieldValue('paddock_id', '');
      setFieldValue('diet_id', '');
      setDisabledDietOptions(false);
    }
  }

  function handleSelectLot(value, setFieldValue) {
    const selectedLot = value && lots.find(lot => lot.secure_id === value.secure_id);

    if (selectedLot) {
      setCurrentLot(selectedLot);

      setFieldValue('destination_lot', value);
      setFieldValue('destination_lot_id', selectedLot.secure_id);

      if (selectedLot.paddock) {
        setFieldValue('module_id', selectedLot.paddock.line.module.secure_id);
        setFieldValue('line_id', selectedLot.paddock.line.secure_id);
        setFieldValue('paddock_id', selectedLot.paddock.secure_id);
        setDisabledModuleOptions(true);

        setListLine([selectedLot.paddock.line]);
        setListPaddock([selectedLot.paddock]);
      } else {
        setFieldValue('module_id', '');
        setFieldValue('line_id', '');
        setFieldValue('paddock_id', '');
        setDisabledModuleOptions(false);

        setListLine([]);
        setListPaddock([]);
      }

      if (selectedLot.diet) {
        setFieldValue('diet_id', selectedLot.diet.secure_id);
        setDisabledDietOptions(true);
      } else {
        setFieldValue('diet_id', '');
        setDisabledDietOptions(false);
      }

      if (selectedLot.dateTransfer) {
        setFieldValue('date', parseISO(selectedLot.dateTransfer));
      } else {
        setFieldValue('date', new Date());
      }
    } else {
      setCurrentLot(null);

      setFieldValue('destination_lot', null);
      setFieldValue('destination_lot_id', '');
      setFieldValue('module_id', '');
      setFieldValue('line_id', '');
      setFieldValue('paddock_id', '');
      setFieldValue('diet_id', '');

      setDisabledModuleOptions(false);
      setDisabledDietOptions(true);

      setFieldValue('module_id', '');
      setFieldValue('line_id', '');
      setFieldValue('paddock_id', '');
      setDisabledModuleOptions(false);

      setListLine([]);
      setListPaddock([]);

      setFieldValue('diet_id', '');
      setDisabledDietOptions(false);

      setFieldValue('date', new Date());
    }
  }

  async function handleSubmit(values) {
    if (animals.length <= 0) return;

    const data = {
      destinLotSecureId: values.destination_lot_id,
      paddockSecureId: values.paddock_id,
      dietSecureId: values.diet_id,
      date: values.date,
      animals: animals.map(animal => ({
        animalSecureId: animal.secure_id,
        originLotSecureId: animal.lot.secure_id,
      }))
    }
    await addIndividualAnimalTransferLot.mutateAsync(data);
  }

  return (
    <>
      {isLoadingData || isLoadingSettings ? (
        <Loader />
      ) : (
        <Formik
          validationSchema={schema}
          initialValues={{
            date: lotsData && lotsData.dateEntry ? parseISO(lotsData.dateEntry) : new Date(),
            destination_lot_id: '',
            destination_lot: null,
            module_id: '',
            line_id: '',
            paddock_id: '',
            diet_id: ''
          }}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, touched, handleChange, isSubmitting }) => (
            <Form>
              <Container>
                <StyledPaper style={{ marginBottom: 16, marginTop: 16 }}>
                  <Grid container spacing={2}>
                    <Grid item md={4} sm xs={12}>
                      <InputDatePicker
                        name="date"
                        inputVariant="outlined"
                        required
                        maxDate={
                          !!lotsData && lotsData.dateEntry
                            ? parseISO(lotsData.dateEntry)
                            : new Date()
                        }
                        minDate={
                          !!lotsData && lotsData.dateEntry
                            ? parseISO(lotsData.dateEntry)
                            : new Date(null)
                        }
                        selectedDate={values.date}
                        handleChangeDate={date => {
                          setFieldValue('date', date);
                        }}
                      />
                      <ErrorMessage name="date" component={Error} />
                    </Grid>

                    {animals.length > 0 && (
                      <Grid item md={4} sm xs={12}>
                        <Autocomplete
                          id="destination_lot-input"
                          name="destination_lot"
                          size="small"
                          noOptionsText="Sem opções"
                          filterOptions={filterOptions}
                          options={listLots.filter(lot => animals.every(animal => animal.lot.secure_id !== lot.secure_id))}
                          defaultValue
                          value={values.destination_lot}
                          getOptionLabel={option => option.value}
                          onChange={(event, value) => {
                            handleSelectLot(value, setFieldValue)
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Lote de Destino"
                              required
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                </StyledPaper>
                {animals.length > 0 && (
                  <StyledPaper style={{ marginBottom: 16, marginTop: 16 }}>
                    <Grid container spacing={2}>
                      <Grid item sm xs={12}>
                        <TextField
                          name="module_id"
                          label="Módulo"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          InputLabelProps={{
                            shrink: values.module_id,
                          }}
                          disabled={disabledModuleOptions}
                          error={!!errors.module_id && touched.module_id}
                          value={values.module_id}
                          onChange={event =>
                            handleSelectModule(event, setFieldValue)
                          }
                        >
                          {modules.map(item => (
                            <MenuItem key={item.secure_id} value={item.secure_id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <ErrorMessage name="module_id" component={Error} />
                      </Grid>
                      <Grid item sm xs={12}>
                        <TextField
                          name="line_id"
                          label="Linha"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          InputLabelProps={{
                            shrink: values.line_id,
                          }}
                          disabled={disabledModuleOptions}
                          error={!!errors.line_id && touched.line_id}
                          value={values.line_id}
                          onChange={event =>
                            handleSelectLine(event, setFieldValue)
                          }
                        >
                          {listLine.map(item => (
                            <MenuItem key={item.secure_id} value={item.secure_id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <ErrorMessage name="line_id" component={Error} />
                      </Grid>
                      <Grid item sm xs={12}>
                        <TextField
                          name="paddock_id"
                          label="Piquete"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          InputLabelProps={{
                            shrink: values.paddock_id,
                          }}
                          disabled={disabledModuleOptions}
                          error={!!errors.paddock_id && touched.paddock_id}
                          value={values.paddock_id}
                          onChange={event => {
                            handleSelectPaddock(event, setFieldValue);
                          }}
                        >
                          {listPaddock.map(item => (
                            <MenuItem key={item.secure_id} value={item.secure_id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <ErrorMessage name="paddock_id" component={Error} />
                      </Grid>
                      <Grid item sm xs style={{ marginLeft: 8 }}>
                        <TextField
                          name="diet_id"
                          label="Dieta"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          disabled={disabledModuleOptions || disabledDietOptions}
                          size="small"
                          InputLabelProps={{
                            shrink: values.diet_id,
                          }}
                          error={!!errors.diet_id && touched.diet_id}
                          value={values.diet_id}
                          onChange={handleChange}
                        >
                          {diets.map(item => (
                            <MenuItem
                              key={item.secure_id}
                              value={item.secure_id}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <ErrorMessage name="diet_id" component={Error} />
                      </Grid>
                    </Grid>
                  </StyledPaper>
                )}

                <ContentAnimals>
                  <StyledPaper>
                    <StyledTableContainer>
                      <Table stickyHeader>
                        <StyledTableHead>
                          <TableRow>
                            <TableCell align="center">
                              <TableSortLabel>Identificador</TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                              <TableSortLabel>Origem</TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                              <TableSortLabel>Destino</TableSortLabel>
                            </TableCell>
                            <TableCell align="center" />
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {animals.length > 0 &&
                            animals
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map(animal => (
                                <TableRow key={animal.secure_id}>
                                  <TableCell align="center">
                                    {animal[settings.settings.default_identification]}
                                  </TableCell>
                                  <TableCell align="center">
                                    {`${animal.lot.code} - ${formatNamePaddock(
                                      settings,
                                      animal.lot.paddock
                                    )}`}
                                  </TableCell>
                                  <TableCell align="center">
                                    {currentLot &&
                                      currentLot.secure_id
                                      ? currentLot.name
                                      : 'Selecione o lote de destino'}
                                  </TableCell>
                                  <Can
                                    checkRole={[
                                      'administrator',
                                      'owner',
                                      'manager',
                                    ]}
                                  >
                                    <StyledTableCell align="right">
                                      <ButtonTable
                                        icon={<DeleteIcon color="error" />}
                                        background={colors.error}
                                        onClick={() =>
                                          handleRemove(animal.secure_id)
                                        }
                                      />
                                    </StyledTableCell>
                                  </Can>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      labelRowsPerPage="Linhas por pág."
                      component="div"
                      count={animals.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </StyledPaper>

                  <ShowAnimal
                    animals={animals}
                    setAnimals={setAnimals}
                    settings={settings.settings}
                  />
                </ContentAnimals>
                <Grid
                  container
                  justify="flex-end"
                  spacing={2}
                  style={{ marginTop: 12 }}
                >
                  <StyledSubmitButton
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      history.push('/dashboard/individualAnimalTransferLot')
                    }
                  >
                    Cancelar
                  </StyledSubmitButton>
                  <ButtonSubmit title="Cadastrar" loading={isSubmitting} disabled={animals.length <= 0} />
                </Grid>
              </Container>
            </Form>
          )}
        </Formik>
      )
      }
    </>
  );
}

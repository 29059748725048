import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import { formatDateOnly } from '~/utils/format';

export async function getDietSwitches(
  sort,
  page = 1,
  limit = 50,
  diet,
  paddock
) {
  try {
    const { data } = await apiV2.get(
      `/v1/feedlot/food-management/dietSwitch?sort=${sort}`,
      {
        params: { page, limit, diet, paddock },
      }
    );

    const newData = data.data.map(animalTransfer => ({
      ...animalTransfer,
      initial_date_diet: animalTransfer.initial_date_diet
        ? formatDateOnly(animalTransfer.initial_date_diet.replace('Z', ''))
        : '',
      final_date_diet: animalTransfer.final_date_diet
        ? formatDateOnly(animalTransfer.final_date_diet.replace('Z', ''))
        : '',
    }));

    return {
      dietSwitch: newData,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    };
  } catch {
    toast.error('Não foi possível buscar trocas de dieta!');
  }
}

export function useApiV2DietSwitch(sort, page, limit, diet, paddock) {
  return useQuery(
    ['dietSwitch', sort, page, limit, diet, paddock],
    () => getDietSwitches(sort, page, limit, diet, paddock),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  );
}
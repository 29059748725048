import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

import { formatDateOnly } from '~/utils/format';

export async function getActionsLogs(sort, page, limit, initialDate, finalDate) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/admin/actionsLog?sort=${sort}`, {
      params: {
        page,
        limit,
        initialDate,
        finalDate
      }
    });

    const newData = data.data.map(actionsLogs => ({
      ...actionsLogs,
      formattedCreatedAt: formatDateOnly(actionsLogs.created_at),
    }));

    return {
      actionsLogs: newData,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    }
  } catch {
    toast.error('Falha para carregar os logs!');
  }
}

export function useApiV2ActionsLogs(sort = '', page = 1, limit = 50, initialDate = new Date(), finalDate = new Date()) {
  return useQuery(
    ['actionsLogs', sort, page, limit, initialDate, finalDate],
    () => getActionsLogs(sort, page, limit, initialDate, finalDate),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  );
}

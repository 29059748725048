import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import { queryClient } from '~/services/queryClient';

export function useApiV2EditNotification() {
  return useMutation(
    async (data) => {
      const { id, ...newData } = data
      await apiV2.put(`/v1/feedlot/user/notification/${id}`, newData)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('notification');
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao tentar marcar notificação.'
        toast[type](message)
      }
    }
  );
}

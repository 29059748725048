/* eslint-disable array-callback-return */
import React, { useEffect, useMemo, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { toast } from 'react-toastify'

// MUI
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import SaveIcon from '@material-ui/icons/Save';

// Local
import { states, funcsEmployee } from '~/utils/options';
import colors from '~/styles/colors';
import history from '~/services/history';
import Loader from '~/components/Loader';
import NumberFormatForm from '~/components/NumberFormatForm';

// Query
import { useApiV2SelectEmployee } from '~/hooks/apiV2/register/employee/useApiV2SelectEmployee';
import { useApiV2AddEmployee } from '~/hooks/apiV2/register/employee/useApiV2AddEmployee';
import { useApiV2EditEmployee } from '~/hooks/apiV2/register/employee/useApiV2EditEmployee';

// Styled Components
import {
  Container,
  StyledPaper,
  StyledGrid,
  ContentItem,
  ContentSubmitButtons,
  StyledSubmitButton,
  Error,
} from './styles';

const schema = Yup.object().shape({
  name: Yup.string().required('Digite o nome'),
  cpf: Yup.string().nullable(),
  address: Yup.string().nullable(),
  addressNumber: Yup.string().nullable(),
  neighborhood: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().nullable(),
  cep: Yup.string().nullable(),
  telephone: Yup.string().nullable(),
  celphone: Yup.string().nullable(),
  email: Yup.string().nullable(),
  function: Yup.array()
    .of(Yup.string())
    .required('Informe uma ou mais funções'),
});

export default function Add({ match }) {
  const [functions, setFunctions] = useState([]);

  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  const { data, isLoading, error } = useApiV2SelectEmployee(secure_id)
  const addEmployee = useApiV2AddEmployee()
  const editEmployee = useApiV2EditEmployee()

  const selectedState =
    data && data.currentEmployee &&
    states.find(currentState => currentState.value === data.currentEmployee.state);

  async function handleSubmit(formData) {
    if (!functions && functions.length <= 0) {
      return;
    }
    let functionEmp = '';
    const arrString = [];
    if (functions && functions.length === 1) {
      functionEmp = functions[0].value;
    } else {
      functions.map(func => arrString.push(func.value));
    }
    const data = {
      ...formData,
      function: functionEmp || arrString.join([',']),
      secure_id,
      state: formData.state.value,
    };

    if (secure_id) {
      await editEmployee.mutateAsync(data)
    } else {
      await addEmployee.mutateAsync(data)
    }
  }

  function handleBack() {
    history.goBack();
  }

  useEffect(() => {
    if (data) {
      if (
        data.currentEmployee &&
        data.currentEmployee.function &&
        data.currentEmployee.function.split(',')
      ) {
        const returnData = [];
        funcsEmployee.filter(func => {
          data.currentEmployee.function.split(',').map(cur => {
            if (func.value === cur) {
              returnData.push(func);
            }
            return true;
          });
        });
        setFunctions(returnData);
      } else {
        const returnData = funcsEmployee.find(
          func => func.value === data.currentEmployee.function
        );
      }
    }
  }, [data]);

  if (secure_id && error) {
    history.push('/dashboard/employee');
    toast.error('Não foi possível carregar o funcionário!');

    return (
      <Container>
        <p>Não foi possível carregar o funcionário!</p>
      </Container>
    )
  }

  return (
    <center>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <ContentItem>
              <StyledPaper>
                <Formik
                  validationSchema={schema}
                  enableReinitialize
                  initialValues={{
                    name: (data && data.currentEmployee.name) ? data.currentEmployee.name : '',
                    cpf: (data && data.currentEmployee.cpf) || '',
                    address: (data && data.currentEmployee.address) || '',
                    addressNumber: (data && data.currentEmployee.address_number) || '',
                    addressComplement: (data && data.currentEmployee.complement) || '',
                    neighborhood: (data && data.currentEmployee.neighborhood) || '',
                    city: (data && data.currentEmployee.city) || '',
                    state: selectedState || '',
                    cep: (data && data.currentEmployee.cep) || '',
                    telephone: (data && data.currentEmployee.telephone) || '',
                    celphone: (data && data.currentEmployee.celphone) || '',
                    email: (data && data.currentEmployee.email) || '',
                    function: (data && data.currentEmployee.function)
                      ? funcsEmployee.filter(func => {
                        return (
                          data.currentEmployee.function
                            .split(',')
                            .indexOf(func.value) > -1
                        );
                      })
                      : [],
                  }}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue, values, errors, touched, isSubmitting }) => (
                    <Form>
                      <StyledGrid container spacing={2} justify="space-around">
                        <Grid item xs={12} sm={8}>
                          <TextField
                            id="name"
                            name="name"
                            variant="outlined"
                            label="Nome Completo"
                            autoComplete="nope"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            size="small"
                            fullWidth
                            required
                            autoFocus
                            value={values.name}
                            error={touched.name && errors.name}
                            onChange={e =>
                              setFieldValue('name', e.target.value)
                            }
                          />
                          <ErrorMessage name="name" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <NumberFormatForm
                            format="###.###.###-##"
                            mask="_"
                            id="cpf"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            name="cpf"
                            variant="outlined"
                            label="CPF"
                            autoComplete="nope"
                            size="small"
                            isNumericString={false}
                            decimalScale={0}
                            thousandSeparator={false}
                            fullWidth
                            value={values.cpf}
                            error={touched.cpf && errors.cpf}
                            onChange={e => setFieldValue('cpf', e.target.value)}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage name="cpf" component={Error} />
                        </Grid>
                      </StyledGrid>

                      <StyledGrid container spacing={2} justify="space-around">
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="email"
                            name="email"
                            type="email"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            variant="outlined"
                            label="Email"
                            autoComplete="nope"
                            size="small"
                            value={values.email}
                            error={touched.email && errors.email}
                            fullWidth
                            onChange={e =>
                              setFieldValue('email', e.target.value)
                            }
                          />
                          <ErrorMessage name="email" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <NumberFormatForm
                            format="(##)####-####"
                            id="telephone"
                            name="telephone"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            variant="outlined"
                            label="Telefone"
                            autoComplete="nope"
                            size="small"
                            fullWidth
                            isNumericString={false}
                            decimalScale={0}
                            thousandSeparator={false}
                            value={values.telephone}
                            error={touched.telephone && errors.telephone}
                            onChange={e =>
                              setFieldValue('telephone', e.target.value)
                            }
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage name="telephone" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <NumberFormatForm
                            format="(##)#####-####"
                            id="celphone"
                            name="celphone"
                            variant="outlined"
                            label="Celular"
                            autoComplete="nope"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            size="small"
                            fullWidth
                            isNumericString={false}
                            decimalScale={0}
                            thousandSeparator={false}
                            value={values.celphone}
                            error={touched.telephone && errors.telephone}
                            onChange={e =>
                              setFieldValue('celphone', e.target.value)
                            }
                            setFieldValue={setFieldValue}
                          />

                          <ErrorMessage name="celphone" component={Error} />
                        </Grid>
                      </StyledGrid>
                      <StyledGrid container spacing={2} justify="space-around">
                        <Grid item xs={12} sm={12}>
                          <Autocomplete
                            id="function-input"
                            name="function"
                            size="small"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            required
                            options={funcsEmployee}
                            getOptionLabel={option => option.value}
                            style={{ width: '100%' }}
                            multiple
                            onChange={(e, value) => {
                              setFunctions(value);
                              setFieldValue('function', value);
                            }}
                            error={touched.name && errors.name}
                            value={functions}
                            defaultValue={values.function}
                            renderInput={params => (
                              <TextField
                                {...params}
                                name="function"
                                label="Função *"
                                variant="outlined"
                                fullWidth
                              />
                            )}
                          />
                          <ErrorMessage name="function" component={Error} />
                        </Grid>
                      </StyledGrid>

                      <StyledGrid container spacing={2} justify="space-around">
                        <Grid item xs={12} sm={3}>
                          <NumberFormatForm
                            format="#####-###"
                            id="cep"
                            name="cep"
                            variant="outlined"
                            label="CEP"
                            autoComplete="nope"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            size="small"
                            fullWidth
                            isNumericString={false}
                            decimalScale={0}
                            thousandSeparator={false}
                            value={values.cep}
                            error={touched.cpf && errors.cpf}
                            onChange={e => setFieldValue('cep', e.target.value)}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage name="cep" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm>
                          <TextField
                            id="address"
                            name="address"
                            variant="outlined"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            label="Endereço"
                            autoComplete="nope"
                            size="small"
                            fullWidth
                            value={values.address}
                            error={touched.address && errors.address}
                            onChange={e =>
                              setFieldValue('address', e.target.value)
                            }
                          />
                          <ErrorMessage name="address" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                          <NumberFormatForm
                            id="addressNumber"
                            name="addressNumber"
                            variant="outlined"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            label="Número"
                            autoComplete="nope"
                            size="small"
                            fullWidth
                            decimalScale={0}
                            isNumericString={false}
                            value={values.addressNumber}
                            error={
                              touched.addressNumber && errors.addressNumber
                            }
                            onChange={e =>
                              setFieldValue('addressNumber', e.target.value)
                            }
                            setFieldValue={setFieldValue}
                          />

                          <ErrorMessage
                            name="addressNumber"
                            component={Error}
                          />
                        </Grid>
                      </StyledGrid>

                      <StyledGrid container spacing={2} justify="space-around">
                        <Grid item xs={12} sm>
                          <TextField
                            id="neighborhood"
                            name="neighborhood"
                            variant="outlined"
                            label="Bairro"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            autoComplete="nope"
                            size="small"
                            fullWidth
                            value={values.neighborhood}
                            error={touched.neighborhood && errors.neighborhood}
                            onChange={e =>
                              setFieldValue('neighborhood', e.target.value)
                            }
                          />
                          <ErrorMessage name="neighborhood" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm>
                          <TextField
                            id="city"
                            name="city"
                            variant="outlined"
                            label="Cidade"
                            autoComplete="nope"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            size="small"
                            fullWidth
                            value={values.city}
                            error={touched.city && errors.city}
                            onChange={e =>
                              setFieldValue('city', e.target.value)
                            }
                          />
                          <ErrorMessage name="city" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm="auto">
                          <Autocomplete
                            id="state-input"
                            name="state"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            size="small"
                            value={values.state}
                            options={states}
                            getOptionLabel={option => option.value}
                            style={{ width: '100%' }}
                            onChange={(e, value) =>
                              setFieldValue('state', value)
                            }
                            renderInput={params => (
                              <TextField
                                {...params}
                                name="state"
                                label="Estado"
                                variant="outlined"
                                fullWidth
                                error={touched.state && errors.state}
                              />
                            )}
                          />
                          <ErrorMessage name="state" component={Error} />
                        </Grid>
                      </StyledGrid>

                      <StyledGrid container spacing={2} justify="space-around">
                        <Grid item xs={12} sm={12}>
                          <TextField
                            id="addressComplement"
                            name="addressComplement"
                            variant="outlined"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            label="Complemento"
                            autoComplete="nope"
                            size="small"
                            fullWidth
                            value={values.addressComplement}
                            onChange={e =>
                              setFieldValue(
                                'addressComplement',
                                e.target.value
                              )
                            }
                          />
                        </Grid>
                      </StyledGrid>

                      <ContentSubmitButtons>
                        {!secure_id ? (
                          <StyledSubmitButton
                            variant="contained"
                            type="submit"
                            color={colors.white}
                            background={colors.success}
                            startIcon={<SaveIcon />}
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? 'Cadastrando...' : 'Cadastrar'}
                          </StyledSubmitButton>
                        ) : (
                          <StyledSubmitButton
                            variant="contained"
                            type="submit"
                            color={colors.white}
                            background={colors.success}
                            startIcon={<SaveIcon />}
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? 'Alterando...' : 'Alterar'}
                          </StyledSubmitButton>
                        )}
                        <StyledSubmitButton
                          variant="outlined"
                          color="primary"
                          onClick={handleBack}
                        >
                          Cancelar
                        </StyledSubmitButton>
                      </ContentSubmitButtons>
                    </Form>
                  )}
                </Formik>
              </StyledPaper>
            </ContentItem>
          </>
        )}
      </Container>
    </center>
  );
}

Add.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

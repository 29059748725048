import styled from 'styled-components';
import {
  Paper,
  Grid,
  Button,
  TextField,
  TableContainer,
  TableHead,
  TableCell,
} from '@material-ui/core';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.div`
  padding: 0px 40px 0 40px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 22px;
`;

export const StyledTextField = styled(TextField)``;

export const StyledButton = styled(Button)`
  color: ${colors.white};
  margin: 6px 0 0 16px;
  transition: background 2s;

  &:hover {
    background: ${darken(0.06, colors.success)};
  }
`;

export const AnimalInfos = styled(Grid)`
  margin: 16px 0 16px 0;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  padding-right: 16px;
`;

export const CardTitle = styled.p`
  color: ${colors.primary};
  text-align: center;
  font-size: 12px;
  font-weight: 500;
`;

export const CardBody = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`;

export const Card = styled(Grid)`
  background-color: ${colors.lighter};
  padding: 8px;
  border-radius: 8px;
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

export const ContentAnimals = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;

  @media(max-width: 940px) {
    flex-direction: column-reverse;
  }
`;

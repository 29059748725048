import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import {
  Modal,
  Backdrop,
  Fade,
  Grid,
  FormControlLabel,
  Button,
  Checkbox,
  Typography,
  Switch,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import PrintIcon from '@material-ui/icons/Print';

import {
  Container,
  StyledPaper,
  StyledGrid,
  CloseButton,
  ContentButton,
  ContentPrint,
} from './styles';

import Print from './Print';
import PrintGrouped from './PrintGrouped';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

export default function ModalManufactoringDiet({
  visibleModal,
  handleModal,
  data,
  currentDiet,
  currentDate,
  treaties,
}) {
  const [treatiesPrint, setTreatiesPrint] = useState([]);
  const [dataPrint, setDataPrint] = useState([]);
  const [totalPrintTreaties, setTotalPrintTreaties] = useState([]);
  const [groupedData, setGroupedData] = useState(false);
  const [totalsForecast, setTotalsForecast] = useState(0)

  const { data: settings } = useApiV2GlobalSetting();

  const PrintScreen = useRef();

  function handleClose() {
    handleModal();
  }

  function handleSelectTreaty(treaty) {
    const newTreaties = [...treatiesPrint];
    const indexTreaty = newTreaties.findIndex(
      item => item.secure_id === treaty.secure_id
    );

    newTreaties.splice(indexTreaty, 1, {
      ...treaty,
      selected: !treaty.selected,
    });

    setTreatiesPrint(newTreaties);

    const selectedTreaties = dataPrint.map(treatment => ({
      ...treatment,
      treatyTreatments: treatment.treatyTreatments.map(item => ({
        ...item,
        selected:
          item.treaty.secure_id === treaty.secure_id
            ? !treaty.selected
            : item.selected,
      })),
    }));

    const newData = selectedTreaties.map(treatment => ({
      ...treatment,
      total_forecast: treatment.treatyTreatments.reduce((accumulator, newTreaty) => {
        if (newTreaty.selected) {
          return accumulator + newTreaty.forecast;
        }

        return accumulator;
      }, 0),
      total_performed: treatment.treatyTreatments.reduce((accumulator, newTreaty) => {
        if (newTreaty.selected) {
          return accumulator + newTreaty.performed;
        }

        return accumulator;
      }, 0),
    }));

    setDataPrint(newData);
  }

  useEffect(() => {
    setDataPrint(
      data.map(treatment => ({
        ...treatment,
        treatyTreatments: treatment.treatyTreatments.map(treaty => ({
          ...treaty,
          selected: true,
        })),
      }))
    );
  }, [data]);

  useEffect(() => {
    setTreatiesPrint(
      treaties.map(treaty => ({
        ...treaty,
        selected: true,
      }))
    );
  }, [treaties]);

  useEffect(() => {
    const allTreaties = [];

    dataPrint.map(treatment => {
      treatment.treatyTreatments.map(treaty => {
        if (treaty.selected) {
          allTreaties.push(treaty);
        }

        return null;
      });

      return null;
    });

    const totals_forecast = allTreaties.reduce((acc, { forecast, performed }) => {
      const valueToSum = performed ? performed : forecast;
      return acc + valueToSum
    }, 0)
    setTotalsForecast(totals_forecast)

    const groupTreaties = allTreaties.reduce(
      (accumulator, { treaty, forecast, performed }) => {
        accumulator[treaty.secure_id] = {
          name: accumulator[treaty.secure_id] ? accumulator[treaty.secure_id].name : treaty.name,
          forecast:
            (accumulator[treaty.secure_id] ? accumulator[treaty.secure_id].forecast : 0) + +forecast,
          performed:
            (accumulator[treaty.secure_id] ? accumulator[treaty.secure_id].performed : 0) + +performed,
        };

        return accumulator;
      },
      {}
    );

    const totalsTreaties = Object.keys(groupTreaties).map(key => ({
      secure_id: key,
      name: groupTreaties[key].name,
      totalForecast: groupTreaties[key].forecast,
      totalPerformed: groupTreaties[key].performed,
    }));

    setTotalPrintTreaties(
      totalsTreaties.sort((a, b) => (a.name > b.name ? 1 : -1))
    );
  }, [dataPrint]);

  return (
    <>
      <Modal
        open={visibleModal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={visibleModal}>
          <Container>
            <StyledPaper>
              <Typography variant="h6">Tratos</Typography>
              {(!currentDiet || currentDiet.name === 'Todas') && (
                <Typography variant="subtitle1">
                  <Switch
                    color="primary"
                    onChange={() => setGroupedData(value => !value)}
                  />
                  Agrupar piquetes por dietas
                </Typography>
              )}
              <StyledGrid container alignItems="center" justifyContent="center">
                {treatiesPrint.map((treaty, index) => (
                  <Grid item xs sm>
                    <FormControlLabel
                      label={treaty.name}
                      control={
                        <Checkbox
                          onChange={() => handleSelectTreaty(treaty)}
                          color="primary"
                          checked={treaty.selected}
                        />
                      }
                    />
                  </Grid>
                ))}
              </StyledGrid>

              <ContentButton>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<PrintIcon />}
                      disabled={dataPrint.length === 0}
                    >
                      Imprimir
                    </Button>
                  )}
                  onBeforeGetContent={handleClose}
                  content={() => PrintScreen.current}
                />
              </ContentButton>
            </StyledPaper>

            <CloseButton stype="button" onClick={handleModal}>
              <CancelIcon fontSize="large" variant="outlined" />
            </CloseButton>
          </Container>
        </Fade>
      </Modal>
      {settings && (
        <ContentPrint>
          {groupedData ? (
            <PrintGrouped
              ref={PrintScreen}
              diet={currentDiet}
              date={currentDate}
              treatments={dataPrint}
              treaties={treatiesPrint}
              settings={settings.settings}
              totalsForecast={totalsForecast}
            />
          ) : (
            <Print
              ref={PrintScreen}
              diet={currentDiet}
              date={currentDate}
              treatments={dataPrint}
              treaties={treatiesPrint}
              totalTreaties={totalPrintTreaties}
              settings={settings.settings}
              totalsForecast={totalsForecast}
            />
          )}
        </ContentPrint>
      )}
    </>
  );
}

ModalManufactoringDiet.propTypes = {
  visibleModal: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentDiet: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  currentDate: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { TextFieldNumber } from './styles';

export default function NumberFormatFormNegative({
  name,
  setFieldValue,
  ...props
}) {
  return (
    <NumberFormat
      id={name}
      customInput={TextFieldNumber}
      min="0.00"
      autoComplete="off"
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      variant="outlined"
      size="small"
      fullWidth
      isAllowed={val => {
        const { formattedValue, floatValue } = val;
        return (
          formattedValue === '' ||
          // formattedValue === '-' ||
          (floatValue <= 9999999999.9999 && floatValue >= -9999999999.9999)
        );
      }}
      onValueChange={values =>
        setFieldValue(name, values.value !== '' ? -values.floatValue : '')
      }
      {...props}
    />
  );
}

NumberFormatFormNegative.defaultProps = {
  setFieldValue: () => {},
};

NumberFormatFormNegative.propTypes = {
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func,
};

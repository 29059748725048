import {
  TableCell, TableRow
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import ActionLoader from '~/components/ActionLoader';
import ButtonTable from '~/components/ButtonTable';
import Can from '~/components/Can';
import apiV2 from '~/services/apiV2';
import { queryClient } from '~/services/queryClient';
import colors from '~/styles/colors';
import { formatDateOnly, formatNumber } from '~/utils/format';
import Modal from '../Modal';
import {
  StyledTableCell,
  StyledTableCellEllipsis
} from './styles';

export function CardTableManufactoringDiet({ manufactoring }) {
  const [visibleModal, setVisibleModal] = useState(false);
  function handleCloseModal() {
    setVisibleModal(false);
  }

  function handleOpenModal() {
    setVisibleModal(true);
  }

  const deleteManufactoringDiet = useMutation(async () => {
    return await apiV2.delete(`v1/feedlot/food-management/manufacturing-diet/${manufactoring.secure_id}`)
  }, {
    onSuccess: (response) => {
      queryClient.invalidateQueries('manufactoringDiet');
      queryClient.invalidateQueries('V2GlobalDiets');
      queryClient.invalidateQueries('V2GlobalEmployees');
      queryClient.invalidateQueries('getTreatmentsForCreatePerDaysManufactoringDiet');
      queryClient.invalidateQueries('getTreatmentsForCreateManufactoringDiet');
      queryClient.invalidateQueries('foods');
      if (response.status === 204) {
        return toast.error('Falha ao tentar apagar a fabricação da dieta.')
      }
      return toast.success(response.data?.message)
    },
    onError: (error) => {
      if (!!error) {
        if (!!error.response.data?.type) {
          if (error.response.data?.type === 'warning') {
            return toast.warning(error.response.data.message)
          }
          return toast.error(error.response.data.message)
        } else {
          return toast.error('Ocorreu um erro ao tentar apagar a fabricação da dieta.')
        }
      } else {
        return toast.error('Ocorreu um erro ao tentar apagar a fabricação da dieta.')
      }
    }
  })

  async function handleDelete() {
    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar essa fabricação de dieta?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar fabricação!',
    }).then(async result => {
      if (result.value) {
        await deleteManufactoringDiet.mutateAsync()
      }
    });
  }

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          {formatDateOnly(manufactoring.date)}
        </TableCell>
        <StyledTableCellEllipsis>
          {manufactoring.diet.name}
        </StyledTableCellEllipsis>
        <TableCell align="right">
          {formatNumber(manufactoring.total_forecast, 0, 4)}
        </TableCell>
        <TableCell align="right">
          {formatNumber(manufactoring.qtd_performed, 0, 0)}
        </TableCell>
        <TableCell align="right">
          {formatNumber(manufactoring.percent_ms_diet, 2, 2)}
        </TableCell>
        <StyledTableCell align="right">
          {`R$ ${formatNumber(manufactoring.price_ton, 2, 2)}`}
        </StyledTableCell>
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['manufactoringDiet_edit', 'manufactoringDiet_delete']}
        >
          {can =>
            can ? (
              <StyledTableCell align="right">
                {!!deleteManufactoringDiet.isLoading ? (
                  <ActionLoader text="Deletando" />
                ) : (
                  <>
                    <ButtonTable
                      icon={<VisibilityIcon />}
                      background={colors.dark}
                      lighten={0.5}
                      onClick={handleOpenModal}
                    />
                    <Can
                      checkRole={['administrator', 'owner', 'beefer']}
                      checkPermission={['manufactoringDiet_delete']}
                    >
                      <ButtonTable
                        icon={<DeleteIcon color="error" />}
                        background={colors.error}
                        onClick={handleDelete}
                      />
                    </Can>

                  </>
                )}
              </StyledTableCell>
            ) : (
              <StyledTableCell align="right">
                <ButtonTable
                  icon={<VisibilityIcon />}
                  background={colors.dark}
                  lighten={0.5}
                  onClick={handleOpenModal}
                />
              </StyledTableCell>
            )
          }
        </Can>
      </TableRow>
      {visibleModal && (
        <Modal
          visibleModal={visibleModal}
          handleCloseModal={handleCloseModal}
          secure_id={manufactoring.secure_id}
        />
      )}
    </>
  )
}
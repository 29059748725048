import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
} from '@material-ui/core';

//Query
import { useApiV2Treaty } from '~/hooks/apiV2/register/treaty/useApiV2Treaty';

import {
  Container,
  StyledGrid,
  StyledPaper,
  ContentButton,
  StyledTableContainer,
  StyledTableHead,
  TitleTotal,
  TitleTable,
  TableCellStyled,
} from './styles';

import { formatNumber } from '~/utils/format';
import history from '~/services/history';
import Can from '~/components/Can';
import Loader from '~/components/Loader';
import ButtonNew from '~/components/ButtonNew';
import { CardTableTreaty } from './Components/CardTableTreaty';

export default function TreatySetting() {
  const [field, setField] = useState('name');
  const [direction, setDirection] = useState('asc');

  const { data, isLoading } = useApiV2Treaty(`${field}%7C${direction}`)

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleAdd() {
    history.push('/dashboard/treatySetting/add');
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={1}>
          <Grid item sm />

          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['treatySetting_new']}
          >
            <Grid item sm>
              <ContentButton>
                <ButtonNew title="Nova" onClick={handleAdd} />
              </ContentButton>
            </Grid>
          </Can>
        </StyledGrid>

        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell align="left">
                      <TableSortLabel
                        active={field === 'name'}
                        direction={field === 'name' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('name')}
                      >
                        Trato
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={field === 'proportion'}
                        direction={field === 'proportion' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('proportion')}
                      >
                        Proporção (%)
                      </TableSortLabel>
                    </TableCell>
                    <Can checkRole={['administrator', 'owner', 'manager']}>
                      <TableCell align="right" />
                    </Can>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {data.treaties.map(treaty => (
                    <CardTableTreaty
                      key={treaty.secure_id}
                      treaty={treaty}
                    />
                  ))}
                  <TableRow>
                    <TableCell>
                      <TitleTotal>Total</TitleTotal>
                    </TableCell>
                    <TableCell align="right">
                      {`${formatNumber(data.totalProportion, 0, 4)}%`}
                    </TableCell>
                    <Can checkRole={['administrator', 'owner', 'manager']}>
                      <TableCell />
                    </Can>
                  </TableRow>

                  <TableRow>
                    {data.totalProportion > 0 && data.totalProportion < 100 && (
                      <>
                        <TableCell>
                          <TitleTable>% Incompleto</TitleTable>
                        </TableCell>
                        <TableCellStyled align="right">
                          {`${formatNumber(data.totalProportion - 100, 0, 4)}%`}
                        </TableCellStyled>
                        <TableCell />
                      </>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </StyledTableContainer>
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

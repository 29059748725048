import { useMutation } from 'react-query';
import { toast } from 'react-toastify'

import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2AddDietSwitch(closeModal = null, setAdding = null) {
  return useMutation(
    async (data) => {
      if (setAdding) setAdding(true);
      const response = await apiV2.post('/v1/feedlot/food-management/dietSwitch', data);

      return response;
    },
    {
      onSuccess: () => {
        if (setAdding) setAdding(false);
        queryClient.invalidateQueries();

        toast.success('Troca de dieta cadastrada com sucesso!');
        if (closeModal) {
          closeModal(true)
        } else {
          history.push('/dashboard/dietSwitch');
        }
      },
      onError: (error) => {
        if (setAdding) setAdding(false);
        if (closeModal) {
          closeModal(false)
        }

        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Não foi possível cadastrar troca de dieta!'
        toast[type](message)
      }
    }
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Query
import { useV2GlobalDiets } from '~/hooks/apiV2/global/diet/useV2GlobalDiets';

import {
  Content,
  StyledTablePaper,
  StyledGridItem,
  StyledFormControl,
  ContentDate,
} from './styles';

import DatePicker from '~/components/DatePicker';

const dietDefaultOption = {
  value: null,
  name: 'Todas',
};

const performedOptions = [
  {
    id: 0,
    value: 'all',
    content: 'Todos',
  },
  {
    id: 1,
    value: true,
    content: 'Sim',
  },
  {
    id: 2,
    value: false,
    content: 'Não',
  },
];

export default function Filter({
  currentDiet,
  setCurrentDiet,
  currentDate,
  setCurrentDate,
  setCurrentPerformed,
}) {
  const [diets, setDiets] = useState([]);

  // Query
  const { data: dietsData } = useV2GlobalDiets();

  useEffect(() => {
    if (dietsData) {
      setDiets(dietsData.diets);
    }
  }, [dietsData])

  function handleChangeDiet(event) {
    setCurrentDiet(event.target.value);
  }

  function handleChangeDate(date) {
    setCurrentDate(date);
  }

  function handleChangePerformed(event) {
    setCurrentPerformed(event.target.value);
  }

  return (
    <Content>
      <StyledTablePaper>
        <Grid container sm alignItems="center">
          <StyledGridItem item xs sm>
            <StyledFormControl size="small" fullWidth>
              <InputLabel id="input-diet">Dieta</InputLabel>
              <Select
                id="diet"
                defaultValue={dietDefaultOption}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                value={currentDiet || dietDefaultOption}
                onChange={handleChangeDiet}
              >
                <MenuItem value={dietDefaultOption}>
                  {dietDefaultOption.name}
                </MenuItem>
                {diets.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </StyledGridItem>

          <StyledGridItem item xs sm>
            <ContentDate>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  maxDate={new Date()}
                  selectedDate={currentDate}
                  handleChangeDate={date => handleChangeDate(date)}
                />
              </MuiPickersUtilsProvider>
            </ContentDate>
          </StyledGridItem>

          <StyledGridItem item xs sm>
            <StyledFormControl size="small" fullWidth>
              <InputLabel id="input-performed">Realizado</InputLabel>
              <Select
                id="performed"
                defaultValue={performedOptions[2]}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                value={performedOptions.content}
                onChange={handleChangePerformed}
              >
                {performedOptions.map(item => (
                  <MenuItem key={item.id} value={item}>
                    {item.content}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </StyledGridItem>
        </Grid>
      </StyledTablePaper>
    </Content>
  );
}

Filter.propTypes = {
  currentDiet: PropTypes.shape({
    secure_id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  setCurrentDiet: PropTypes.func.isRequired,
  currentDate: PropTypes.string.isRequired,
  setCurrentDate: PropTypes.func.isRequired,
  setCurrentPerformed: PropTypes.func.isRequired,
};

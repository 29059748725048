import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2EditPurchaseFood() {
  return useMutation(
    async (data) => {
      const { secure_id, ...newData } = data
      await apiV2.put(`/v1/feedlot/admin/purchaseFood/${secure_id}`, newData)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('purchaseFoods');
        toast.success('Compra de alimentos alterada!');

        history.push('/dashboard/purchaseFoods');
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao alterar compra de alimentos.'
        toast[type](message)
      }
    }
  );
}

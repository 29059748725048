import styled, { keyframes } from 'styled-components';
import {
  Paper,
  Button,
  IconButton,
} from '@material-ui/core';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 1024px;
  width: 100%;
  padding: 40px;
`;

export const StyledPaper = styled(Paper)`
  position: relative;
  width: 100%;
  padding: 16px;
`;

export const ContentItem = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.marginTop || 50}px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

const shake = keyframes`
  0%,
  100% {
    transform: translateY(0) rotate(0);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateY(-10px) rotate(-6deg);
  }
  30% {
    transform: translateY(5px) rotate(6deg);
  }
  45% {
    transform: translateY(-5px) rotate(-3.6deg);
  }
  60% {
    transform: translateY(4px) rotate(2.4deg);
  }
  75% {
    transform: translateY(-3px) rotate(-1.2deg);
  }
`;

export const ContentAlerts = styled.div`
  position: absolute;
  top: 30px;
  right: ${props => (props.changeDiet ? -50 : -30)}px;

  display: flex;
  flex-direction: row;
`;

export const Alert = styled.div`
  animation: ${shake} 0.8s infinite both;
`;

export const AlertWarning = styled.div`
  animation: ${shake} 1s infinite both;

  svg {
    color: ${colors.warning};
  }
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: 5px;
  color: ${colors.white};
  background: ${colors.success};
  transition: background 1s;

  &:hover {
    background: ${darken(0.06, colors.success)};
  }
`;

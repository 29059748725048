import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import AuthLayout from '~/pages/_layouts/auth';
import DefaultLayout from '~/pages/_layouts/default';

import { useCan } from '~/hooks/useCan';
import { useAuthContext } from '~/contexts/AuthContext';

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  permissions = null,
  roles = null,
  ...rest
}) {
  const { user } = useAuthContext();
  const userAccessPage = useCan({
    permissions: permissions,
    roles: roles
  })

  if (!user.isAuthenticated && isPrivate) {
    return <Redirect to="/" />;
  }

  if (user.isAuthenticated && !isPrivate) {
    return <Redirect to="/dashboard" />;
  }

  if (permissions || roles) {
    if (!userAccessPage) {
      return <Redirect to="/dashboard" />;
    }
  }

  const Layout = user.isAuthenticated ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
};

RouteWrapper.defaultProps = {
  isPrivate: false,
  component: null,
};

/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  Divider,
} from '@material-ui/core';
import { format } from 'date-fns';

import {
  Container,
  Header,
  Column,
  StyledTablePaper,
  StyledTableHead,
  TitleTable,
  StyledTablePaperMix,
  Title,
} from './styles';

import logo from '~/assets/images/logo_black.png';
import { formatNumber } from '~/utils/format';

export default class PrintMSBeats extends Component {
  render() {
    const {
      item,
      foods,
      preMixes,
      capacity,
      totalInclMS,
      totalInclMO,
    } = this.props;

    const renderFooter = food => {
      const newTotalInclMS = food.preMixes.reduce(
        (total, pm) => total + parseFloat(pm.percent_incl_ms_food.toFixed(4)),
        0
      );

      const newTotalInclMO = food.preMixes.reduce(
        (total, pm) => total + parseFloat(pm.percent_incl_mo_food.toFixed(4)),
        0
      );

      const totalCapacity = food.preMixes.reduce(
        (total, pm) =>
          total + parseFloat(Number(pm.capacity_in_the_wagon).toFixed(4)),
        0
      );

      return (
        <TableRow>
          <TableCell colSpan={2} />
          <TableCell align="left">
            <TitleTable>Total</TitleTable>
          </TableCell>
          <TableCell />
          <TableCell align="right">{`${formatNumber(
            newTotalInclMS,
            0,
            4
          )}%`}</TableCell>
          <TableCell align="right">{`${formatNumber(
            Math.round(newTotalInclMO),
            0,
            4
          )}%`}</TableCell>
          <TableCell align="right">{totalCapacity || null}</TableCell>
        </TableRow>
      );
    };

    return (
      <Container>
        <div style={{ height: '90vh' }}>
          <Header>
            <img src={logo} alt="logo" />
            <Typography variant="h5">Batidas de Dieta/Ração</Typography>
            <Column>
              <Typography variant="subtitle2">{`Dieta: ${
                item && item.name
              }`}</Typography>
              <Typography variant="body2">{`Data: ${String(
                format(new Date(), 'dd/MM/yyyy HH:mm:ss')
              )}`}</Typography>
            </Column>
          </Header>

          <StyledTablePaper elevation={0}>
            <TableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>Alimento</TableCell>
                    <TableCell align="right">MS (%)</TableCell>
                    {item && item.type === 'feed' ? null : (
                      <TableCell align="right">Incl. MS (%)</TableCell>
                    )}
                    <TableCell align="right">Incl. MO (%)</TableCell>
                    <TableCell align="right">Montagem do vagão (Kg)</TableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {foods.map(food => (
                    <TableRow key={food.secure_id}>
                      <TableCell component="th" scope="row">
                        {food.name}
                      </TableCell>
                      <TableCell align="right">{`${formatNumber(
                        food.percent_ms,
                        0,
                        4
                      )}%`}</TableCell>
                      {item && item.type === 'feed' ? null : (
                        <TableCell align="right">{`${formatNumber(
                          food.pivot.percent_incl_ms_food,
                          0,
                          4
                        )}%`}</TableCell>
                      )}
                      <TableCell align="right">{`${formatNumber(
                        food.pivot.percent_incl_mo_food,
                        0,
                        4
                      )}%`}</TableCell>
                      <TableCell align="right">
                        {formatNumber(food.capacity_in_the_wagon, 0, 4)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell align="left">
                      <TitleTable>Total</TitleTable>
                    </TableCell>
                    <TableCell align="right" />
                    {item && item.type === 'feed' ? null : (
                      <TableCell align="right">{`${formatNumber(
                        totalInclMS,
                        0,
                        4
                      )}%`}</TableCell>
                    )}
                    <TableCell align="right">{`${formatNumber(
                      totalInclMO,
                      0,
                      4
                    )}%`}</TableCell>
                    <TableCell align="right">
                      {formatNumber(capacity, 0, 4)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </StyledTablePaper>
        </div>

        {preMixes.length > 0 && (
          <StyledTablePaperMix elevation={0}>
            <Title color="primary">Pré Misturas</Title>

            <Divider lighth />

            {preMixes.map(food => (
              <TableContainer key={food.secure_id} style={{ marginBottom: 24 }}>
                <Table stickyHeader>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell colSpan={2}>Mistura</TableCell>
                      <TableCell>Alimento</TableCell>
                      <TableCell align="right">MS (%)</TableCell>
                      <TableCell align="right">Incl. MS (%)</TableCell>
                      <TableCell align="right">Incl. MO (%)</TableCell>
                      <TableCell align="right">
                        Montagem misturador (Kg)
                      </TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>{food.name}</TableCell>
                    </TableRow>
                    {food.preMixes.map(mix => (
                      <TableRow key={mix.secure_id}>
                        <TableCell colSpan={2} />
                        <TableCell component="th" scope="row">
                          {mix.food.name}
                        </TableCell>
                        <TableCell align="right">{`${formatNumber(
                          mix.food.percent_ms,
                          0,
                          4
                        )}%`}</TableCell>
                        <TableCell align="right">{`${formatNumber(
                          mix.percent_incl_ms_food,
                          0,
                          4
                        )}%`}</TableCell>
                        <TableCell align="right">{`${formatNumber(
                          mix.percent_incl_mo_food,
                          0,
                          4
                        )}%`}</TableCell>
                        <TableCell align="right">
                          {formatNumber(mix.capacity_in_the_wagon, 0, 4)}
                        </TableCell>
                      </TableRow>
                    ))}
                    {renderFooter(food)}
                  </TableBody>
                </Table>
              </TableContainer>
            ))}
          </StyledTablePaperMix>
        )}
      </Container>
    );
  }
}

PrintMSBeats.propTypes = {
  item: PropTypes.shape({
    secure_id: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  foods: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  preMixes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  capacity: PropTypes.number.isRequired,
  totalInclMS: PropTypes.number.isRequired,
  totalInclMO: PropTypes.number.isRequired,
};

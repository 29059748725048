import { useQuery } from 'react-query';
import apiV2 from '~/services/apiV2';

export async function getPermissions() {
  const { data } = await apiV2.get('/v1/feedlot/register/user/permissions');
  return data;
}

export function useApiV2Permissions() {
  return useQuery(['permissions'], () => getPermissions(), {
    staleTime: 3600000,
  });
}

/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import {
  TableContainer,
  Table,
  TableCell,
  Typography,
  TableRow,
} from '@material-ui/core';

import {
  Container,
  Header,
  Column,
  StyledTablePaper,
  StyledTableHead,
  StyledTableBody,
  StyledPaper,
} from './styles';

import logo from '~/assets/images/logo_black.png';
import colors from '~/styles/colors';

export default class PrintEfficiencyTreatment extends Component {
  render() {
    const { data, initialDate, finalDate, series, days } = this.props;
    const options = {
      chart: {
        toolbar: {
          show: false,
          tools: {
            zoomin: false,
            zoomout: false,
            zoom: false,
            selection: true,
            reset: false,
            pan: false,
            download: true,
          },
        },
        animations: {
          enabled: true
        }
      },
      noData: {
        text: 'Sem dados',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: colors.grey,
          fontSize: '32px',
          fontFamily: 'Roboto',
        },
      },
      stroke: {
        width: [0, 4],
      },
      colors: [colors.chartSecondary, colors.chartPrimary],
      labels: days,
      yaxis: [
        {
          title: {
            text: 'Total (Kg)',
          },
        },
      ],
    };

    return (
      <Container>
        <Header>
          <img src={logo} alt="logo" style={{ flexBasis: '10%' }} />
          <div
            style={{
              flexBasis: '80%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h6">Eficiência de Tratos</Typography>
            <Column>
              <Typography variant="body2">{`De: ${String(
                format(initialDate, 'dd/MM/yyyy')
              )} à ${format(finalDate, 'dd/MM/yyyy')}`}</Typography>
            </Column>
          </div>
        </Header>

        <StyledPaper elevation={0}>
          {series && (
            <Chart
              options={options}
              series={series}
              type="line"
              height={500}
              width={1000}
            />
          )}
        </StyledPaper>

        <StyledTablePaper elevation={0}>
          <TableContainer>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell>Dieta</TableCell>
                  <TableCell align="right">Qtd.Previsto</TableCell>
                  <TableCell align="right">Qtd. Realizado</TableCell>
                  <TableCell align="right">Desvio (%)</TableCell>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody>
                {data && data.map(efficency => (
                  <TableRow key={efficency.secure_id}>
                    <TableCell component="th" scope="row">
                      {efficency.date}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {`${efficency.diet && efficency.diet.name}`}
                    </TableCell>
                    <TableCell align="right">
                      {efficency.total_forecast}
                    </TableCell>
                    <TableCell align="right">
                      {efficency.total_performed}
                    </TableCell>
                    <TableCell align="right">
                      {`${efficency.deviation} %`}
                    </TableCell>
                  </TableRow>
                ))}
              </StyledTableBody>
            </Table>
          </TableContainer>
        </StyledTablePaper>
      </Container>
    );
  }
}

PrintEfficiencyTreatment.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  series: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  days: PropTypes.arrayOf(PropTypes.string).isRequired,
  initialDate: PropTypes.instanceOf(Date).isRequired,
  finalDate: PropTypes.instanceOf(Date).isRequired
};

import React, { useState } from 'react';
import Swal from 'sweetalert2';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';

// Query
import { useApiV2Module } from '~/hooks/apiV2/register/module/useApiV2Module'
import { useApiV2RemoveModule } from '~/hooks/apiV2/register/module/useApiV2RemoveModule'

import {
  Container,
  StyledGrid,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
} from './styles';

import history from '~/services/history';
import colors from '~/styles/colors';
import Loader from '~/components/Loader';
import ButtonNew from '~/components/ButtonNew';
import Can from '~/components/Can';
import { CardTableModule } from './Components/CardTableModule';

export default function Module() {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const [field, setField] = useState('name');
  const [direction, setDirection] = useState('asc');

  const [deleting, setDeleting] = useState('');

  // Query
  const { data, isLoading } = useApiV2Module(`${field}%7C${direction}`, page + 1, rowsPerPage);
  const removeModule = useApiV2RemoveModule(setDeleting);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function hasTreatment({ has_treatments, has_lots, has_trough_readings }) {
    return has_treatments > 0 || has_lots > 0 || has_trough_readings > 0
  }

  async function handleEdit({ secure_id }) {
    history.push(`/dashboard/module/edit/${secure_id}`);
  }

  function handleDelete({ secure_id }) {
    async function performRemoveModule() {
      await removeModule.mutateAsync(secure_id)
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar esse módulo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar módulo!',
    }).then(result => {
      if (result.value) {
        performRemoveModule();
      }
    });
  }

  function handleAdd() {
    history.push('/dashboard/module/add');
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={2}>
          <Grid item sm />
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['module_new']}
          >
            <Grid item sm>
              <ButtonNew title="Novo" onClick={handleAdd} />
            </Grid>
          </Can>
        </StyledGrid>

        {isLoading && !deleting ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'name'}
                        direction={field === 'name' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('name')}
                      >
                        Módulo
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">Qtd. de Linhas</TableCell>
                    <TableCell align="right">Qtd. de Piquetes</TableCell>
                    <TableCell align="right" colSpan={2} />
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {data && data.modules.map(module => (
                    <CardTableModule
                      key={module.secure_id}
                      module={module}
                    />
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

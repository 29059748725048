import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Hidden,
  TextField,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

// Query
import { useApiV2PurchaseFood } from '~/hooks/apiV2/admin/purchaseFood/useApiV2PurchaseFood'

import {
  Container,
  StyledGrid,
  StyledPaper,
  ContentButton,
  StyledAddButton,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCellEllipsis,
} from './styles';

import history from '~/services/history';
import Loader from '~/components/Loader';
import Can from '~/components/Can';
import { useDebouncedPromise } from '~/utils/useDebouncedPromise';
import { CardTablePurchaseFood } from './Components/CardTablePurchaseFood';

export default function PurchaseFoods() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [arrayPurchase, setArrayPurchase] = useState([]);
  const [search, setSearch] = useState('');

  const field = 'date';
  const direction = 'desc';

  const debouncedChange = useDebouncedPromise(HandleSearch, 500);

  // Query
  const { data: shoppingsData, isLoading } = useApiV2PurchaseFood(`${field}%7C${direction}`, page + 1, rowsPerPage, search);

  function HandleSearch(value) {
    if (value.length === 0) {
      setSearch('');
    }
    setPage(0);
    setSearch(value.replace(/\s/g, '%'));
  }

  function handleChangePage(_, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleAdd() {
    history.push('/dashboard/purchaseFoods/add');
  }

  useEffect(() => {
    if (shoppingsData) {
      if (shoppingsData.shoppings) {
        setArrayPurchase(shoppingsData.shoppings);
      }
    }
  }, [shoppingsData]);

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={1}>
          <Grid xs={6} item>
            <TextField
              style={{ background: 'white' }}
              size="small"
              placeholder="Pesquisar por produto"
              onChange={e => {
                debouncedChange(e.target.value);
              }}
              variant="outlined"
              autoComplete="nope"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['purchaseFoods_new']}
          >
            <Grid item xs={6} sm={5} md={5} lg={3} xl>
              <ContentButton>
                <StyledAddButton
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAdd}
                >
                  Novo
                </StyledAddButton>
              </ContentButton>
            </Grid>
          </Can>
        </StyledGrid>
        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <Hidden xsDown implementation="js">
                    <TableRow>
                      <StyledTableCellEllipsis align="left">
                        Nome Fantasia/Fornecedor
                      </StyledTableCellEllipsis>
                      <TableCell align="center">Data</TableCell>
                      <TableCell align="left">Produtos</TableCell>
                      <TableCell align="right">Total Produtos</TableCell>
                      <TableCell align="right">Frete</TableCell>
                      <TableCell align="right">Total</TableCell>
                      <Can checkRole={['administrator', 'owner', 'manager']}>
                        <TableCell align="right" colSpan={2} />
                      </Can>
                    </TableRow>
                  </Hidden>
                  <Hidden smUp implementation="js">
                    <TableRow>
                      <StyledTableCellEllipsis align="left">
                        Forn
                      </StyledTableCellEllipsis>
                      <TableCell align="center">Data</TableCell>
                      <StyledTableCellEllipsis align="left">
                        Prod
                      </StyledTableCellEllipsis>
                      <TableCell align="right">Total Prod</TableCell>
                      <TableCell align="right">Frete</TableCell>
                      <TableCell align="right">Total</TableCell>
                      <Can checkRole={['administrator', 'owner', 'manager']}>
                        <TableCell align="right" colSpan={2} />
                      </Can>
                    </TableRow>
                  </Hidden>
                </StyledTableHead>
                <TableBody>
                  {arrayPurchase
                    .map(shopping => (
                      <CardTablePurchaseFood
                        key={shopping.secure_id}
                        shopping={shopping}
                      />
                    ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={shoppingsData ? shoppingsData.total : 0}
              rowsPerPage={shoppingsData ? shoppingsData.perPage : 0}
              page={shoppingsData ? shoppingsData.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { format, subDays } from 'date-fns';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Typography,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import {
  Container,
  Header,
  Column,
  StyledTablePaper,
  StyledTableHead,
  StyledTableBody,
  StyledTableCell,
} from './styles';

import logo from '~/assets/images/logo_black.png';
import { formatNamePaddock } from '~/utils/format';

export default class PrintTroughReading extends Component {
  render() {
    const { module, line, date, data, troughSettings, settings } = this.props;

    function renderLogTroughReading(treatment) {
      let cIndex = 0;

      const allDates = [
        format(subDays(date, 1), 'yyyy-MM-dd'),
        format(subDays(date, 2), 'yyyy-MM-dd'),
        format(subDays(date, 3), 'yyyy-MM-dd'),
      ];

      const log = allDates.map(day => {
        if (treatment.logTroughReadings[cIndex]) {
          const newCurrentDate = format(
            new Date(treatment.logTroughReadings[cIndex].date),
            'yyyy-MM-dd'
          );

          if (newCurrentDate === day) {
            cIndex += 1;

            return (
              <TableCell align="center">
                {treatment.logTroughReadings[cIndex - 1].troughSetting.note}
              </TableCell>
            );
          }
        }

        return <TableCell align="right" />;
      });

      cIndex = 0;
      return log;
    }

    return (
      <Container>
        <Header>
          <img src={logo} alt="logo" />
          <Typography variant="h5">Leitura de cocho</Typography>
          <Column>
            <Typography variant="subtitle2">
              {`Módulo: ${module ? module.name : 'Todos'}`}
            </Typography>
            <Typography variant="subtitle2">
              {`Linha: ${line ? line.name : 'Todos'}`}
            </Typography>
            <Typography variant="subtitle2">{`Data: ${String(
              format(date, 'dd/MM/yyyy')
            )}`}</Typography>
          </Column>
        </Header>

        <StyledTablePaper elevation={0}>
          <TableContainer>
            <Table stickyHeader>
              <StyledTableHead>
                <TableRow>
                  <TableCell>Piquete</TableCell>
                  <TableCell align="left">Leitura</TableCell>
                  <TableCell align="center">Dia 1</TableCell>
                  <TableCell align="center">Dia 2</TableCell>
                  <TableCell align="center">Dia 3</TableCell>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody>
                {data.map(treatment => (
                  <TableRow key={treatment.secure_id}>
                    <StyledTableCell component="th" scope="row">
                      {formatNamePaddock(settings, treatment.paddock)}
                    </StyledTableCell>
                    <TableCell>
                      {troughSettings && troughSettings.length > 0 && troughSettings.map(setting => (
                        <FormControlLabel
                          key={setting.secure_id}
                          value={Number(setting.secure_id)}
                          control={<Radio color="primary" size="medium" />}
                          label={setting.note}
                        />
                      ))}
                    </TableCell>
                    {renderLogTroughReading(treatment)}
                  </TableRow>
                ))}
              </StyledTableBody>
            </Table>
          </TableContainer>
        </StyledTablePaper>
      </Container>
    );
  }
}

PrintTroughReading.propTypes = {
  module: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  line: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  date: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  troughSettings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  settings: PropTypes.shape({}).isRequired,
};

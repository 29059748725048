import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  ListSubheader,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { GiCow } from 'react-icons/gi';
import IconStorage from '@material-ui/icons/Storage';
import ErrorIcon from '@material-ui/icons/Error';

import { useAuthContext } from '~/contexts/AuthContext';

// Query
import { useApiV2Dashboard } from '~/hooks/apiV2/dashboard/useApiV2Dashboard'
import { useApiV2AlterSettingDashboard } from '~/hooks/apiV2/dashboard/useApiV2AlterSettingDashboard'

import {
  Container,
  Header,
  Cards,
  Card,
  RowCard,
  StyledPaperMedium,
  StyledList,
  StyledListItemInfo,
  Alert,
  StyledDivGrid,
} from './styles';

import HeaderMenu from './Components/HeaderMenu';
import ItemAlert from './Components/ItemAlert';

import { formatNumber } from '~/utils/format';
import colors from '~/styles/colors';
import Loader from '~/components/Loader';

export default function Dashboard() {
  const { user } = useAuthContext()

  const [anchorElMenuStatus, setAnchorElMenuStatus] = useState(null);
  const [titleStatus, setTitleStatus] = useState('Ontem');

  const { data: dataDashboard, isLoading: isLoadingDashboard } = useApiV2Dashboard();
  const alterSettingDashboard = useApiV2AlterSettingDashboard();

  const listOptionsStatus = [
    {
      id: 1,
      label: 'Ontem',
      type: 'yesterday',
      visible: dataDashboard && dataDashboard.settingDashboard.status !== 'yesterday',
    },
    {
      id: 2,
      label: 'Mensal',
      type: 'month',
      visible: dataDashboard && dataDashboard.settingDashboard.status !== 'month',
    },
    {
      id: 3,
      label: 'Anual',
      type: 'year',
      visible: dataDashboard && dataDashboard.settingDashboard.status !== 'year',
    },
  ];

  async function handleChangeStatus(event) {
    const { type } = listOptionsStatus.find(
      option => option.id === event.target.value
    );
    await alterSettingDashboard.mutateAsync({ status: type });
    setAnchorElMenuStatus(null);
  }

  useEffect(() => {
    if (dataDashboard) {
      setTitleStatus(
        dataDashboard.settingDashboard.status === 'year'
          ? 'Anual'
          : dataDashboard.settingDashboard.status === 'month'
            ? 'Mensal'
            : 'Ontem'
      );
    }
  }, [dataDashboard])

  return (
    <center>
      <Container>
        {isLoadingDashboard ? (
          <Loader />
        ) : (
          <>
            <Header>
              <Typography variant="h5">{`Bem vindo, ${user.name}`}</Typography>
            </Header>

            <Cards>
              <StyledDivGrid>
                <div className='cards'>
                  <Card>
                    <Typography variant="h6">Animais ativos</Typography>
                    <RowCard>
                      <Typography variant="h4" color="textSecondary">
                        {formatNumber(dataDashboard.card.amount_animals_active, 0, 0)}
                      </Typography>
                      <div style={{ background: colors.chartPrimary }}>
                        <GiCow size={30} />
                      </div>
                    </RowCard>
                  </Card>
                </div>
                <div className='cards'>
                  <Card>
                    <Typography variant="h6">Animais em banco</Typography>
                    <RowCard>
                      <Typography variant="h4" color="textSecondary">
                        {formatNumber(dataDashboard.card.amount_animals_total, 0, 0)}
                      </Typography>
                      <div style={{ background: colors.chartSecondary }}>
                        <IconStorage />
                      </div>
                    </RowCard>
                  </Card>
                </div>
                <div className='alerts'>
                  <StyledPaperMedium>
                    <StyledList
                      component="div"
                      subheader={
                        <ListSubheader
                          component="div"
                          style={{
                            backgroundColor: colors.white,
                          }}
                        >
                          <span>Alertas</span>
                          {dataDashboard.alerts.length > 0 && (
                            <Alert>
                              <ErrorIcon color="error" />
                            </Alert>
                          )}
                        </ListSubheader>
                      }
                    >
                      {dataDashboard.alerts.map((alert, index) => {
                        return (
                          <div key={alert}>
                            <ItemAlert alert={alert} />
                            {index !== dataDashboard.alerts.length - 1 && <Divider />}
                          </div>
                        );
                      })}
                    </StyledList>
                  </StyledPaperMedium>
                </div>
                <div className='infos'>
                  <StyledPaperMedium>
                    <StyledList
                      component="nav"
                      subheader={
                        <ListSubheader
                          component="div"
                          style={{
                            backgroundColor: colors.white,
                          }}
                        >
                          <HeaderMenu
                            title={titleStatus}
                            list={listOptionsStatus}
                            onClick={event =>
                              setAnchorElMenuStatus(event.currentTarget)
                            }
                            anchorElMenu={anchorElMenuStatus}
                            setAnchorElMenu={setAnchorElMenuStatus}
                            handleChange={handleChangeStatus}
                          />
                        </ListSubheader>
                      }
                    >
                      <StyledListItemInfo>
                        <ListItemText
                          primary="Entrada de animais"
                          secondary={
                            formatNumber(dataDashboard.status.amount_animals_input, 0, 0) ||
                            '0'
                          }
                        />
                      </StyledListItemInfo>
                      <StyledListItemInfo>
                        <ListItemText
                          primary="Venda de animais"
                          secondary={
                            formatNumber(
                              dataDashboard.status.amount_animals_output_sale,
                              0,
                              0
                            ) || '0'
                          }
                        />
                      </StyledListItemInfo>
                      <StyledListItemInfo>
                        <ListItemText
                          primary="Morte de animais"
                          secondary={
                            formatNumber(
                              dataDashboard.status.amount_animals_output_death,
                              0,
                              0
                            ) || '0'
                          }
                        />
                      </StyledListItemInfo>
                      <StyledListItemInfo>
                        <ListItemText
                          primary="Refugo de animais"
                          secondary={
                            formatNumber(
                              dataDashboard.status.amount_animals_output_scrap,
                              0,
                              0
                            ) || '0'
                          }
                        />
                      </StyledListItemInfo>
                    </StyledList>
                  </StyledPaperMedium>
                </div>
              </StyledDivGrid>
            </Cards>

            {/* <Cards>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md>
                  <Card>
                    <Typography variant="h6">Animais ativos</Typography>
                    <RowCard>
                      <Typography variant="h4" color="textSecondary">
                        {formatNumber(dataDashboard.card.amount_animals_active, 0, 0)}
                      </Typography>
                      <div style={{ background: colors.chartPrimary }}>
                        <GiCow size={30} />
                      </div>
                    </RowCard>
                  </Card>
                </Grid>
                <Grid item xs={12} sm={6} md>
                  <Card>
                    <Typography variant="h6">Animais em banco</Typography>
                    <RowCard>
                      <Typography variant="h4" color="textSecondary">
                        {formatNumber(dataDashboard.card.amount_animals_total, 0, 0)}
                      </Typography>
                      <div style={{ background: colors.chartSecondary }}>
                        <IconStorage />
                      </div>
                    </RowCard>
                  </Card>
                </Grid>
              </Grid>
            </Cards>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <StyledPaperMedium>
                      <StyledList
                        component="nav"
                        subheader={
                          <ListSubheader
                            component="div"
                            style={{
                              backgroundColor: colors.white,
                            }}
                          >
                            <HeaderMenu
                              title={titleStatus}
                              list={listOptionsStatus}
                              onClick={event =>
                                setAnchorElMenuStatus(event.currentTarget)
                              }
                              anchorElMenu={anchorElMenuStatus}
                              setAnchorElMenu={setAnchorElMenuStatus}
                              handleChange={handleChangeStatus}
                            />
                          </ListSubheader>
                        }
                      >
                        <StyledListItemInfo>
                          <ListItemText
                            primary="Entrada de animais"
                            secondary={
                              formatNumber(dataDashboard.status.amount_animals_input, 0, 0) ||
                              '0'
                            }
                          />
                        </StyledListItemInfo>
                        <StyledListItemInfo>
                          <ListItemText
                            primary="Venda de animais"
                            secondary={
                              formatNumber(
                                dataDashboard.status.amount_animals_output_sale,
                                0,
                                0
                              ) || '0'
                            }
                          />
                        </StyledListItemInfo>
                        <StyledListItemInfo>
                          <ListItemText
                            primary="Morte de animais"
                            secondary={
                              formatNumber(
                                dataDashboard.status.amount_animals_output_death,
                                0,
                                0
                              ) || '0'
                            }
                          />
                        </StyledListItemInfo>
                        <StyledListItemInfo>
                          <ListItemText
                            primary="Refugo de animais"
                            secondary={
                              formatNumber(
                                dataDashboard.status.amount_animals_output_scrap,
                                0,
                                0
                              ) || '0'
                            }
                          />
                        </StyledListItemInfo>
                      </StyledList>
                    </StyledPaperMedium>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <StyledPaperMedium>
                      <StyledList
                        component="div"
                        subheader={
                          <ListSubheader
                            component="div"
                            style={{
                              backgroundColor: colors.white,
                            }}
                          >
                            <span>Alertas</span>
                            {dataDashboard.alerts.length > 0 && (
                              <Alert>
                                <ErrorIcon color="error" />
                              </Alert>
                            )}
                          </ListSubheader>
                        }
                      >
                        {dataDashboard.alerts.map((alert, index) => {
                          return (
                            <div key={alert}>
                              <ItemAlert alert={alert} />
                              {index !== dataDashboard.alerts.length - 1 && <Divider />}
                            </div>
                          );
                        })}
                      </StyledList>
                    </StyledPaperMedium>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
          </>
        )}
      </Container>
    </center>
  );
}

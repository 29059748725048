import React from 'react';
import { AiOutlineLoading } from 'react-icons/ai';

import { Container } from './styles';

import colors from '~/styles/colors';

export default function Loader() {
  return (
    <Container>
      <AiOutlineLoading size={45} color={colors.light} />
    </Container>
  );
}

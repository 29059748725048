import React, { useState, useEffect, useMemo } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

// Toast
import { toast } from 'react-toastify';

// MUI
import {
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Hidden,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// Locals
import { parseNumericUserInput, formatNumber } from '~/utils/format';
import NumberFormatForm from '~/components/NumberFormatForm';
import ButtonTable from '~/components/ButtonTable';
import ModalFood from '~/components/ModalFood';
import history from '~/services/history';
import Loader from '~/components/Loader';
import ModalStakeholder from '~/components/ModalStakeholder';
import ButtonSubmit from '~/components/ButtonSubmit';

// Styled Components
import {
  Container,
  StyledPaper,
  ContentItem,
  ContentSubmitButtons,
  StyledSubmitButton,
  StyledGridItem,
  StyledTextField,
  StyledDivider,
  StyledTableHead,
  StyledTableCell,
  StyledIconButton,
  Error,
  TextInfo,
  StyledButton,
  StyledGridItemColumn,
  MobileStyledGridItemColumn,
  StyledGridItem1st,
  MobileContentSubmitButtons,
} from './styles';
import colors from '~/styles/colors';

// Query
import { useApiV2SelectPurchaseFood } from '~/hooks/apiV2/admin/purchaseFood/useApiV2SelectPurchaseFood';
import { useApiV2EditPurchaseFood } from '~/hooks/apiV2/admin/purchaseFood/useApiV2EditPurchaseFood';
import { useApiV2GlobalFood } from '~/hooks/apiV2/global/food/useApiV2GlobalFood';
import { useApiV2Stakeholders } from '~/hooks/apiV2/global/stakeholder/useApiV2GlobalStakeholder';
import { useAuthContext } from '~/contexts/AuthContext';
import { userHasPermission } from '~/utils/userHasPermission';

const schema = Yup.object().shape({
  provider: Yup.object().required('Informe um fornecedor').nullable(),
  date: Yup.string().required('Informe uma data'),
  // food_price: Yup.string().required('Informe o preço do alimento'),
  // food_id: Yup.string().required('Selecione o alimento'),
  total_freight: Yup.string().required('Informe o preço do frete'),
  // amount: Yup.string().required('Informe uma quantidade'),
});

export default function Edit({ match }) {
  const { user } = useAuthContext();

  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  const [listFood, setListFood] = useState([]);
  const [currentFood, setCurrentFood] = useState('');
  const [visibleModalFood, setVisibleModalFood] = useState(false);
  const [visibleModalStakeholder, setVisibleModalStakeholder] = useState(false);
  const [listProviders, setListProviders] = useState([]);
  const [provider, setProvider] = useState(null);
  const [qtd, setQtd] = useState('');
  const [foodPrice, setFoodPrice] = useState('');
  const [messageErro, setMessageErro] = useState('');

  const [userNoAbleToEdit, setUserNoAbleToEdit] = useState(false);

  // Query
  const { data: currentShoppingData, isLoading, error } = useApiV2SelectPurchaseFood(secure_id)
  const { data: foodsData } = useApiV2GlobalFood([true], null, 'food');
  const { data: providersData } = useApiV2Stakeholders('provider,both')
  const editPurchaseFood = useApiV2EditPurchaseFood()

  function handleModalStakeholder() {
    setVisibleModalStakeholder(!visibleModalStakeholder);
  }

  function handleRemoveFoodToList(indexFood) {
    const newlistFood = listFood.filter((food, index) => index !== indexFood);

    // Alter all porcents of MO old with the new total MO
    const alterDataFood = newlistFood.map(food => ({
      ...food,
    }));
    setListFood(alterDataFood);
  }

  function handleAddFoodToList() {
    const existFood = listFood.find(food => food.secure_id === currentFood.secure_id);

    if (existFood) {
      setMessageErro('Item já cadastrado !');
      return;
    }

    if (!qtd || !foodPrice || !currentFood) {
      setMessageErro('Verifique os campos!');
      return;
    }

    if (!existFood && Object.values(currentFood).length > 0) {
      setMessageErro('');
      const totalPriceFood = parseFloat(foodPrice) * qtd;

      const list = {
        secure_id: currentFood.secure_id,
        food_name: currentFood.name,
        foodPrice,
        qtd,
        totalFoodPrice: totalPriceFood,
        amount_ton: parseNumericUserInput(qtd),
        price_ton: parseNumericUserInput(foodPrice),
      };
      setListFood([...listFood, list]);
      setQtd('');
      setFoodPrice('');
      setCurrentFood('');
    }
  }

  function handleModalFood() {
    setVisibleModalFood(!visibleModalFood);
  }

  async function handleEditShopping(values) {
    const newDate = format(new Date(values.date), 'yyyy-MM-dd');

    const items = listFood.map(food => ({
      amountTon: food.amount_ton,
      priceTon: food.price_ton,
      foodSecureId: food.secure_id,
    }));

    const totalAmountTon = listFood.reduce(
      (total, food) => total + food.amount_ton,
      0
    );

    const data = {
      secure_id,
      date: newDate,
      totalPrice: listFood.reduce((total, food) => {
        return total + food.amount_ton * food.price_ton;
      }, 0),
      totalFreight: values.total_freight,
      totalAmountTon: totalAmountTon,
      providerSecureId: values.provider.secure_id,
      obs: values.obs,
      items
    };

    await editPurchaseFood.mutateAsync(data)
  }

  function handleBack() {
    history.goBack();
  }

  useEffect(() => {
    if (providersData && providersData.length > 0) {
      const newListProviders = providersData.map(providerItem => ({
        secure_id: providerItem.secure_id,
        value: providerItem.fantasy_name
          ? `${providerItem.fantasy_name} - ${providerItem.name}`
          : providerItem.name,
        text: providerItem.name,
      }));

      setListProviders(newListProviders);
    }
  }, [providersData]);

  useEffect(() => {
    if (currentShoppingData && currentShoppingData.currentShopping) {
      const items = currentShoppingData.currentShopping.purchaseFoodItems.map(foodItem => ({
        amount_ton: foodItem.amount_ton,
        price_ton: foodItem.price_ton,
        name: foodItem.food.name,
        secure_id: foodItem.food.secure_id,
      }));

      setListFood(items || []);
    }
  }, [currentShoppingData]);

  useEffect(() => {
    if (currentShoppingData && listProviders.length > 0) {
      setProvider(listProviders.find(providerItem => providerItem.secure_id === currentShoppingData.currentShopping.provider.secure_id))
    }
  }, [currentShoppingData, listProviders]);

  useEffect(() => {
    if (currentShoppingData) {
      const userHasValidPermissions = userHasPermission({
        user,
        permissions: [],
        roles: ["administrator"]
      });
      setUserNoAbleToEdit(currentShoppingData.currentShopping.daysDifference >= 30 ? !userHasValidPermissions : false);
    }
  }, [currentShoppingData, user]);

  if (error) {
    history.push('/dashboard/purchaseFoods');
    toast.error('Não foi possível carregar a compra!');

    return (
      <p>Não foi possível carregar a compra!</p>
    )
  }

  return (
    <center>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <ContentItem>
              <StyledPaper>
                <Formik
                  enableReinitialize
                  validationSchema={schema}
                  initialValues={{
                    provider: provider,
                    date: currentShoppingData && currentShoppingData.currentShopping.date,
                    obs: currentShoppingData && currentShoppingData.currentShopping.obs,
                    total_freight: currentShoppingData && currentShoppingData.currentShopping.total_freight,
                  }}
                  onSubmit={handleEditShopping}
                >
                  {({ setFieldValue, values, errors, touched, isSubmitting }) => (
                    <Form>
                      {userNoAbleToEdit && (
                        <Error>
                          Compra com mais de 30 dias, entre em contato com o suporte para realizar a ação. *
                        </Error>
                      )}
                      <Grid container>
                        <StyledGridItem fullWidth item sm xs="12">
                          <Grid container>
                            <Grid item xs sm>
                              <Autocomplete
                                id="provider-input"
                                name="provider"
                                value={values.provider}
                                options={listProviders}
                                getOptionLabel={option => option.value}
                                style={{ width: '100%' }}
                                size="small"
                                disabled={userNoAbleToEdit}
                                onChange={(e, value) => {
                                  setFieldValue('provider', value);
                                }}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    // name="provider_id"
                                    label="Fornecedor"
                                    variant="outlined"
                                    fullWidth
                                  />
                                )}
                              />
                            </Grid>
                            <Grid xs="auto" sm="auto">
                              <StyledIconButton
                                style={{ marginTop: '5px' }}
                                variant="contained"
                                size="small"
                                onClick={handleModalStakeholder}
                              >
                                <AddIcon />
                              </StyledIconButton>
                            </Grid>
                          </Grid>
                          <ErrorMessage name="provider" component={Error} />
                        </StyledGridItem>
                        <StyledGridItem item sm>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={ptBR}
                          >
                            <KeyboardDatePicker
                              name="date"
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              size="small"
                              id="date-picker-inline"
                              label="Data da compra"
                              inputVariant="outlined"
                              value={values.date}
                              disabled={userNoAbleToEdit}
                              onChange={date =>
                                setFieldValue('date', new Date(date))
                              }
                              style={{ marginTop: 0, marginBottom: 0 }}
                              fullWidth
                              autoOk
                              error={!!errors.date && touched.date}
                            />
                            <ErrorMessage name="date" component={Error} />
                          </MuiPickersUtilsProvider>
                        </StyledGridItem>
                      </Grid>
                      <StyledDivider />
                      <TextInfo>Alimentos</TextInfo>
                      <Grid container>
                        <StyledGridItem1st item sm>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            <InputLabel id="food-label">Alimento</InputLabel>
                            <Select
                              labelId="food-label"
                              labelWidth={65}
                              id="food-input"
                              name="secure_id"
                              value={currentFood}
                              disabled={userNoAbleToEdit}
                              onChange={e => setCurrentFood(e.target.value)}
                            >
                              {foodsData && foodsData.map(food => (
                                <MenuItem key={food.secure_id} value={food}>
                                  {food.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage name="secure_id" component={Error} />
                          </FormControl>
                          <StyledIconButton
                            style={{ marginTop: '5px' }}
                            variant="contained"
                            size="small"
                            onClick={handleModalFood}
                          >
                            <AddIcon />
                          </StyledIconButton>
                        </StyledGridItem1st>
                        <StyledGridItem item sm>
                          <NumberFormatForm
                            name="amount"
                            label="Quantidade"
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={qtd}
                            isNumericString={false}
                            autoComplete="nope"
                            decimalScale={3}
                            disabled={userNoAbleToEdit}
                            onChange={e => setQtd(e.target.value)}
                            setFieldValue={setFieldValue}
                            error={touched.amount && errors.amount}
                          />
                          <ErrorMessage name="amount" component={Error} />
                        </StyledGridItem>
                        <StyledGridItem item sm>
                          <NumberFormatForm
                            name="food_price"
                            label="Preço Unitário"
                            prefix="R$"
                            isNumericString={false}
                            variant="outlined"
                            size="small"
                            fullWidth
                            autoComplete="nope"
                            decimalScale={2}
                            fixedDecimalScale
                            value={foodPrice}
                            disabled={userNoAbleToEdit}
                            onChange={e => setFoodPrice(e.target.value)}
                            setFieldValue={setFieldValue}
                            error={touched.food_price && errors.food_price}
                          />
                          <ErrorMessage name="food_price" component={Error} />
                        </StyledGridItem>
                        <Hidden xsDown implementation="css">
                          <StyledGridItemColumn item sm="auto">
                            <StyledButton
                              variant="contained"
                              color={colors.white}
                              background={colors.success}
                              onClick={handleAddFoodToList}
                              disabled={userNoAbleToEdit}
                            >
                              Adicionar
                            </StyledButton>
                            {messageErro !== '' && <Error>{messageErro}</Error>}
                          </StyledGridItemColumn>
                        </Hidden>
                        <Hidden smUp implementation="js">
                          <MobileStyledGridItemColumn item sm="auto">
                            <StyledButton
                              variant="contained"
                              color={colors.white}
                              background={colors.success}
                              onClick={handleAddFoodToList}
                            >
                              Adicionar
                            </StyledButton>
                            {messageErro !== '' && <Error>{messageErro}</Error>}
                          </MobileStyledGridItemColumn>
                        </Hidden>
                      </Grid>
                      <br />
                      <TableContainer>
                        <Table stickyHeader>
                          <StyledTableHead>
                            <TableRow>
                              <TableCell align="left">Descrição</TableCell>
                              <StyledTableCell align="right">
                                Preço (ton)
                              </StyledTableCell>
                              <TableCell align="right">Qtd/Ton</TableCell>
                              <TableCell align="right">Total</TableCell>
                              <TableCell />
                            </TableRow>
                          </StyledTableHead>
                          <TableBody>
                            {listFood.map((food, index) => (
                              <TableRow key={food.secure_id}>
                                <TableCell component="th" scope="row">
                                  {foodsData && foodsData.length > 0 &&
                                    foodsData.find(
                                      cFood => cFood.secure_id === food.secure_id
                                    ).name}
                                </TableCell>
                                <StyledTableCell align="right">
                                  {`R$ ${formatNumber(food.price_ton, 2, 2)}`}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {food.amount_ton}
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  {`R$ ${formatNumber(
                                    food.amount_ton * food.price_ton,
                                    2,
                                    2
                                  )}`}
                                </StyledTableCell>

                                <TableCell align="center">
                                  <ButtonTable
                                    icon={<DeleteIcon color="error" />}
                                    background={colors.error}
                                    onClick={() =>
                                      handleRemoveFoodToList(index)
                                    }
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <br />
                      <TextInfo>Totais da compra</TextInfo>
                      <Grid container>
                        <StyledGridItem item sm style={{ marginTop: '9px' }}>
                          <NumberFormatForm
                            allowedDecimalSeparators={[',']}
                            name="total_freight"
                            label="Total do Frete"
                            variant="outlined"
                            fullWidth
                            autoComplete="nope"
                            decimalScale={2}
                            value={values.total_freight}
                            fixedDecimalScale
                            prefix="R$"
                            disabled={userNoAbleToEdit}
                            onChange={event =>
                              setFieldValue(
                                'total_freight',
                                parseNumericUserInput(event.target.value)
                              )
                            }
                            setFieldValue={setFieldValue}
                            error={
                              touched.total_freight && !!errors.total_freight
                            }
                          />
                          <ErrorMessage
                            name="total_freight"
                            component={Error}
                          />
                        </StyledGridItem>
                        <StyledGridItem item sm>
                          <StyledTextField
                            name="product"
                            variant="outlined"
                            label="Total dos produtos"
                            size="small"
                            fullWidth
                            disabled
                            value={`R$ ${formatNumber(
                              listFood.reduce((total, food) => {
                                return total + food.amount_ton * food.price_ton;
                              }, 0),
                              2,
                              2
                            )}`}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </StyledGridItem>
                        <StyledGridItem item sm>
                          <StyledTextField
                            name="order"
                            variant="outlined"
                            size="small"
                            label="Total do pedido"
                            fullWidth
                            disabled
                            value={`R$ ${formatNumber(
                              Number(values.total_freight) +
                              listFood.reduce((total, food) => {
                                return (
                                  total + food.amount_ton * food.price_ton
                                );
                              }, 0),
                              2,
                              2
                            )}`}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </StyledGridItem>
                      </Grid>
                      <StyledDivider />
                      <TextInfo>Dados adicionais</TextInfo>
                      <Grid container>
                        <StyledGridItem item sm>
                          <StyledTextField
                            id="obs"
                            name="obs"
                            variant="outlined"
                            label="Observação"
                            autoComplete="nope"
                            fullWidth
                            value={values.obs}
                            disabled={userNoAbleToEdit}
                            onChange={event => setFieldValue('obs', event.target.value)}
                            multiline
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </StyledGridItem>
                      </Grid>
                      <Hidden xsDown implementation="css">
                        <ContentSubmitButtons>
                          <StyledSubmitButton
                            variant="outlined"
                            color="primary"
                            onClick={handleBack}
                          >
                            Cancelar
                          </StyledSubmitButton>
                          {listFood.length > 0 ? (
                            <ButtonSubmit
                              title="Alterar"
                              disabled={
                                (userNoAbleToEdit)
                                || isSubmitting || listFood.length === 0
                              }
                              loading={isSubmitting}
                            />
                          ) : (
                            <StyledSubmitButton
                              variant="contained"
                              color={colors.white}
                              background={colors.success}
                              startIcon={<SaveIcon />}
                              disabled
                            >
                              {isSubmitting ? 'Alterando...' : 'Alterar'}
                            </StyledSubmitButton>
                          )}
                        </ContentSubmitButtons>
                      </Hidden>

                      <Hidden smUp implementation="css">
                        <MobileContentSubmitButtons>
                          <StyledSubmitButton
                            variant="outlined"
                            color="primary"
                            onClick={handleBack}
                          >
                            Cancelar
                          </StyledSubmitButton>
                          <ButtonSubmit
                            title="Alterar"
                            disabled={isSubmitting || listFood.length === 0}
                            loading={isSubmitting}
                            onClick={handleEditShopping}
                          />
                        </MobileContentSubmitButtons>
                      </Hidden>
                    </Form>
                  )}
                </Formik>
              </StyledPaper>
            </ContentItem>
          </>
        )}

        <ModalStakeholder
          visibleModal={visibleModalStakeholder}
          handleModal={handleModalStakeholder}
          type="provider,both"
        />

        <ModalFood
          visibleModal={visibleModalFood}
          handleModal={handleModalFood}
        />
      </Container>
    </center>
  );
}

Edit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

import React, { useState, useEffect } from 'react';
import {
  Grid,
  FormControlLabel,
  Switch,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Button
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import Chart from 'react-apexcharts';
import ReactToPrint from 'react-to-print'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

// Query
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';
import { useApiV2ConsumptionLot } from '~/hooks/apiV2/Report/consumptionLot/useApiV2ConsumptionLot';

import Print from './Print'

import {
  Container,
  StyledFormControlLabel,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  ContentPrint
} from './styles';

import {
  formatDateOnly,
  formatNumber,
  formatNamePaddock,
} from '~/utils/format';
import { groupByDay } from '~/utils/common';
import colors from '~/styles/colors';
import Filter from './Components/Filter';
import Loader from '~/components/Loader';
import { useRef } from 'react';

export default function ConsumptionLot() {
  const [printing, setPrinting] = useState(false);
  const [type, setType] = useState('pv');
  const [series, setSeries] = useState([]);
  const [days, setDays] = useState([]);
  const [maxY, setMaxY] = useState(0);
  const [showLabel, setShowLabel] = useState(false);

  const PrintScreen = useRef(null)
  const [filters, setFilters] = useState({
    initialDate: new Date(),
    finalDate: new Date(),
    modules: [],
    lines: [],
    paddocks: [],
    lots: []
  });

  // Query
  const { data: settingsData, isLoading: isLoadingSetting } = useApiV2GlobalSetting();
  const { data: consumptionLots, isLoading: isLoadingConsumptionLots } = useApiV2ConsumptionLot(filters);

  function handleChangeType(event) {
    setType(event.target.value === 'pv' ? 'ms' : 'pv');
  }

  function handleChangeLabel() {
    setShowLabel(!showLabel);
  }

  useEffect(() => {
    if (consumptionLots && consumptionLots.length > 0) {
      const target = [];
      const forecast = [];
      const performed = [];

      const newDays = [
        ...new Set(
          consumptionLots.map(treatmentLot => formatDateOnly(treatmentLot.date))
        ),
      ];

      const groupByConsumptionDay = groupByDay('date');

      const consumptionLotsGroupDay = groupByConsumptionDay(consumptionLots);

      const consumptionLotsDay = Object.values(consumptionLotsGroupDay);

      consumptionLotsDay.map(consumptionLot => {
        const {
          totalTarget,
          totalForecast,
          totalPerformed,
        } = consumptionLot.reduce(
          (accumulator, treatmentLot) => {
            if (type === 'pv') {
              accumulator.totalTarget += treatmentLot.percent_target_pv;
              accumulator.totalForecast += treatmentLot.percent_forecast_pv;
              accumulator.totalPerformed += treatmentLot.percent_performed_pv;
            } else {
              accumulator.totalTarget +=
                treatmentLot.average_consumption_target_ms;
              accumulator.totalForecast +=
                treatmentLot.average_consumption_forecast_ms;
              accumulator.totalPerformed +=
                treatmentLot.average_consumption_performed_ms;
            }

            return accumulator;
          },
          {
            totalTarget: 0,
            totalForecast: 0,
            totalPerformed: 0,
          }
        );

        target.push(Number((totalTarget / consumptionLot.length).toFixed(2)));
        forecast.push(
          Number((totalForecast / consumptionLot.length).toFixed(2))
        );
        performed.push(
          Number((totalPerformed / consumptionLot.length).toFixed(2))
        );

        return null;
      });

      const newMaxY = Math.max(...target, ...forecast, ...performed) + 2;

      setDays(newDays);
      setSeries([
        {
          name: 'Meta',
          data: target,
        },
        {
          name: 'Previsto',
          data: forecast,
        },
        {
          name: 'Realizado',
          data: performed,
        },
      ]);
      setMaxY(newMaxY);
    } else {
      setDays([]);
      setSeries([]);
      setDays(0);
    }
  }, [consumptionLots, type]);

  const options = {
    chart: {
      toolbar: {
        show: true,
        tools: {
          zoomin: true,
          zoomout: true,
          zoom: true,
          selection: true,
          reset: false,
          pan: false,
          download: true,
        },
      },
      dropShadow: {
        enabled: true,
        color: colors.black,
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
    },
    noData: {
      text: 'Sem dados',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: colors.grey,
        fontSize: '32px',
        fontFamily: 'Roboto',
      },
    },
    colors: [colors.chartPrimary, colors.chartSecondary, colors.chartThird],
    dataLabels: {
      enabled: showLabel,
    },
    stroke: {
      curve: 'smooth',
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: days,
      title: {
        text: 'Dias',
      },
    },
    yaxis: {
      title: {
        text: type === 'pv' ? 'Peso Vivo (%)' : 'Consumo de MS (Kg/cab)',
      },
      min: 0,
      max: maxY,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  return (
    <>
      <center>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs sm md lg xl>
              <Filter
                setFilters={setFilters}
              />
            </Grid>

            <Grid item xs="auto" sm="auto">
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={consumptionLots && consumptionLots.length === 0}
                    startIcon={<PrintIcon />}
                  >
                    Imprimir
                  </Button>
                )}
                content={() => PrintScreen.current}
                onBeforeGetContent={() => setPrinting(true)}
                onBeforePrint={() => setPrinting(false)}
                onAfterPrint={() => setPrinting(false)}
              />
              <div style={{ marginTop: 10 }}>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Exportar"
                  style={{
                    padding: 0,
                  }}
                />
              </div>
            </Grid>
          </Grid>

          <Grid container justify="space-between">
            <FormControlLabel
              value={type}
              label={type === 'pv' ? '% Peso Vivo' : 'Cosumo de Kg MS'}
              control={<Switch color="primary" />}
              labelPlacement="end"
              onChange={handleChangeType}
            />

            <StyledFormControlLabel
              value={showLabel}
              label="Rótulos"
              control={<Switch color="primary" />}
              labelPlacement="end"
              onChange={handleChangeLabel}
            />
          </Grid>

          {(isLoadingConsumptionLots || isLoadingSetting) ? (
            <Loader />
          ) : (
            <>
              <StyledPaper>
                <Chart
                  options={options}
                  series={series}
                  type="line"
                  height="600"
                />
              </StyledPaper>
              <StyledPaper style={{ marginTop: 20 }}>
                <StyledTableContainer>
                  <Table stickyHeader id="table-to-xls">
                    <StyledTableHead>
                      <TableRow>
                        <TableCell>Piquete</TableCell>
                        <TableCell>Lote</TableCell>
                        <TableCell align="center">Data</TableCell>
                        <TableCell align="right">Qtd. Meta</TableCell>
                        <TableCell align="right">Qtd. Previsto</TableCell>
                        <TableCell align="right">Qtd. Realizado</TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <TableBody>
                      {consumptionLots && settingsData && consumptionLots.map(treatmentLot => (
                        <TableRow key={treatmentLot.secure_id}>
                          <TableCell component="th" scope="row">
                            {treatmentLot.treatment &&
                              formatNamePaddock(
                                settingsData.settings,
                                treatmentLot.treatment.paddock
                              )}
                          </TableCell>
                          <TableCell>{`${treatmentLot.lot && treatmentLot.lot.code
                            }`}</TableCell>
                          <TableCell align="center">
                            {formatDateOnly(treatmentLot.date)}
                          </TableCell>
                          <TableCell align="center">
                            {type === 'pv'
                              ? `${formatNumber(
                                treatmentLot.percent_target_pv,
                                2,
                                2
                              )} %`
                              : formatNumber(
                                treatmentLot.average_consumption_target_ms,
                                2,
                                2
                              )}
                          </TableCell>
                          <TableCell align="right">
                            {type === 'pv'
                              ? `${formatNumber(
                                treatmentLot.percent_forecast_pv,
                                2,
                                2
                              )} %`
                              : formatNumber(
                                treatmentLot.average_consumption_forecast_ms,
                                2,
                                2
                              )}
                          </TableCell>
                          <TableCell align="center">
                            {type === 'pv'
                              ? `${formatNumber(
                                treatmentLot.percent_performed_pv,
                                2,
                                2
                              )} %`
                              : formatNumber(
                                treatmentLot.average_consumption_performed_ms,
                                2,
                                2
                              )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              </StyledPaper>
            </>
          )}
        </Container>
      </center>
      <ContentPrint>
        {printing && (
          <Print
            ref={PrintScreen}
            data={consumptionLots && consumptionLots}
            initialDate={filters.initialDate}
            finalDate={filters.finalDate}
            series={series}
            days={days}
            type={type}
            maxY={maxY}
            settings={settingsData.settings}
          />
        )}
      </ContentPrint>
    </>
  );
}

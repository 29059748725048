/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import {
  TableContainer,
  Table,
  TableCell,
  Typography,
  TableRow,
} from '@material-ui/core';

import {
  Container,
  Header,
  Column,
  StyledTablePaper,
  StyledTableHead,
  StyledTableBody,
  StyledPaper
} from './styles';

import logo from '~/assets/images/logo_black.png';
import colors from '~/styles/colors';

import {
  formatDateOnly,
  formatNumber,
  formatNamePaddock,
} from '~/utils/format';

export default class PrintConsumptionLot extends Component {
  render() {
    const { data, initialDate, finalDate, series, days, type, maxY, settings } = this.props;
    const options = {
      chart: {
        toolbar: {
          show: true,
          tools: {
            zoomin: true,
            zoomout: true,
            zoom: true,
            selection: true,
            reset: false,
            pan: false,
            download: true,
          },
        },
        dropShadow: {
          enabled: true,
          color: colors.black,
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        animations: {
          enabled: true
        }
      },
      colors: [colors.chartPrimary, colors.chartSecondary, colors.chartThird],
      stroke: {
        curve: 'smooth',
      },
      grid: {
        borderColor: '#e7e7e7',
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      xaxis: {
        categories: days,
        title: {
          text: 'Dias',
        },
      },
      yaxis: {
        title: {
          text: type === 'pv' ? 'Peso Vivo (%)' : 'Consumo de MS (Kg/cab)',
        },
        min: 0,
        max: maxY,
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    };

    return (
      <Container>
        <Header>
          <img src={logo} alt="logo" style={{ flexBasis: '10%' }} />
          <div
            style={{
              flexBasis: '80%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h6">Curva de Consumo de Lote</Typography>
            <Column>
              <Typography variant="p">{`De: ${String(
                format(initialDate, 'dd/MM/yyyy')
              )} à ${format(finalDate, 'dd/MM/yyyy')}`}</Typography>
            </Column>
          </div>
        </Header>

        <StyledPaper elevation={0}>
          {series && (
            <Chart
              options={options}
              series={series}
              type="line"
              height={500}
              width={1000}
            />
          )}
        </StyledPaper>

        <StyledTablePaper elevation={0}>
          <TableContainer>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell>Piquete</TableCell>
                  <TableCell>Lote</TableCell>
                  <TableCell align="center">Data</TableCell>
                  <TableCell align="right">Qtd. Meta</TableCell>
                  <TableCell align="right">Qtd. Previsto</TableCell>
                  <TableCell align="right">Qtd. Realizado</TableCell>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody>
                {data && data.map(treatmentLot => (
                  <TableRow key={treatmentLot.secure_id}>
                    <TableCell component="th" scope="row">
                      {treatmentLot.treatment &&
                        formatNamePaddock(
                          settings,
                          treatmentLot.treatment.paddock
                        )}
                    </TableCell>
                    <TableCell>{`${treatmentLot.lot && treatmentLot.lot.code
                      }`}</TableCell>
                    <TableCell align="center">
                      {formatDateOnly(treatmentLot.date)}
                    </TableCell>
                    <TableCell align="center">
                      {type === 'pv'
                        ? `${formatNumber(
                          treatmentLot.percent_target_pv,
                          2,
                          2
                        )} %`
                        : formatNumber(
                          treatmentLot.average_consumption_target_ms,
                          2,
                          2
                        )}
                    </TableCell>
                    <TableCell align="right">
                      {type === 'pv'
                        ? `${formatNumber(
                          treatmentLot.percent_forecast_pv,
                          2,
                          2
                        )} %`
                        : formatNumber(
                          treatmentLot.average_consumption_forecast_ms,
                          2,
                          2
                        )}
                    </TableCell>
                    <TableCell align="center">
                      {type === 'pv'
                        ? `${formatNumber(
                          treatmentLot.percent_performed_pv,
                          2,
                          2
                        )} %`
                        : formatNumber(
                          treatmentLot.average_consumption_performed_ms,
                          2,
                          2
                        )}
                    </TableCell>
                  </TableRow>
                ))}
              </StyledTableBody>
            </Table>
          </TableContainer>
        </StyledTablePaper>
      </Container>
    );
  }
}

PrintConsumptionLot.propTypes = {
  series: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  days: PropTypes.arrayOf(PropTypes.shape('')).isRequired,
};

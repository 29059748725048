import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2"

export async function getUser(secure_id) {
  if (!secure_id) {
    return null
  }

  const { data } = await apiV2.get(`/v1/feedlot/register/user/${secure_id}`)

  return data
}

export function useApiV2SelectUser(secure_id) {
  return useQuery(['users', secure_id], () => getUser(secure_id))
}

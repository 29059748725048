import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Radio,
  InputAdornment
} from '@material-ui/core';

// Query
import { useApiV2GlobalBreeds } from '~/hooks/apiV2/global/breed/useApiV2GlobalBreeds';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';
import { useApiV2EditAnimal } from '~/hooks/apiV2/register/animal/useApiV2EditAnimal';
import { useApiV2SelectAnimal } from '~/hooks/apiV2/register/animal/useApiV2SelectAnimal';

import {
  Container,
  StyledPaper,
  ContentItem,
  Error,
  ContentSubmitButtons,
  StyledSubmitButton,
  StyledRadioGroup,
  StyledTextField
} from './styles';

import history from '~/services/history';
import ButtonSubmit from '~/components/ButtonSubmit';
import Loader from '~/components/Loader';
import { ages, types, gender, bodySizes } from '~/utils/options';
import NumberFormatForm from '~/components/NumberFormatForm';

export default function AnimalsEdit({ match }) {
  const { state: location } = useLocation();

  const lot_id = useMemo(
    () => (location && location.lot_id ? location.lot_id : null),
    [location]
  );

  const schema = Yup.object().shape({
    sisbov: Yup.string().nullable(),
    botton_rfid: Yup.string().nullable(),
    ident_earing: Yup.string().nullable(),
    breed_secure_id: Yup.string()
      .typeError('Seleciona uma raça')
      .required('Selecione uma raça'),
    sex: Yup.string().required('Selecione o sexo'),
    age: Yup.string().required('Selecione a faixa de idade'),
    type: Yup.string().required('Selecione a categoria'),
    weight_forecast: Yup.number().typeError('Insira um número válido').nullable(),
    body_size: Yup.string().required('Selecione o tipo corporal'),
  });

  const secure_id = useMemo(() => match.params.id, [match.params.id]);
  const [settings, setSettings] = useState(null);
  // Query
  const { data: breedsData } = useApiV2GlobalBreeds();
  const { data: settingsData } = useApiV2GlobalSetting();
  const { data: currentAnimalData, isLoading, error } = useApiV2SelectAnimal(secure_id);
  const editAnimal = useApiV2EditAnimal(lot_id);

  function handleSelectBreed(event, handleChange) {
    if (!breedsData) {
      return
    }
    handleChange(event);
  }

  async function handleSubmit(data) {
    await editAnimal.mutateAsync({
      secure_id,
      sisbov: data.sisbov,
      bottonRfid: data.botton_rfid,
      identEaring: data.ident_earing,
      breedSecureId: data.breed_secure_id,
      sex: data.sex,
      age: data.age,
      type: data.type,
      weightForecast: data.weight_forecast,
      bodySize: data.body_size
    })
  }

  function handleBack() {
    history.push('/dashboard/animals', { lot_id });
  }

  useEffect(() => {
    if (settingsData) {
      setSettings(settingsData.settings)
    }
  }, [settingsData]);

  if (secure_id && error) {
    history.push('/dashboard/animals')
    toast.error('Falha ao carregar os dados do animal!');
    return null
  }

  if (isLoading) {
    return (
      <Loader />
    )
  }

  return (
    <center>
      <Container>
        <Formik
          enableReinitialize={secure_id}
          validationSchema={schema}
          initialValues={{
            sisbov: !!currentAnimalData ? currentAnimalData.currentAnimal.sisbov : '',
            botton_rfid: !!currentAnimalData ? currentAnimalData.currentAnimal.botton_rfid : '',
            ident_earing: !!currentAnimalData ? currentAnimalData.currentAnimal.ident_earing : '',
            breed_secure_id: (!!currentAnimalData && !!currentAnimalData?.currentAnimal?.breed?.secure_id) ?
              currentAnimalData.currentAnimal.breed.secure_id : null,
            age: !!currentAnimalData ? currentAnimalData.currentAnimal.age : '',
            type: !!currentAnimalData ? currentAnimalData.currentAnimal.type : '',
            sex: !!currentAnimalData ? currentAnimalData.currentAnimal.sex : '',
            weight_forecast: !!currentAnimalData ? currentAnimalData.currentAnimal.weight_forecast : '',
            body_size: !!currentAnimalData ? currentAnimalData.currentAnimal.body_size : '',
          }}
          onSubmit={handleSubmit}
        >
          {({ handleChange, errors, touched, values, handleBlur, isSubmitting, setFieldValue }) => (
            <Form>
              <ContentItem>
                <StyledPaper>
                  <Grid container spacing={2}>
                    <Grid item sm xs={12}>
                      <StyledTextField
                        fullWidth
                        id="sisbov"
                        name="sisbov"
                        variant="outlined"
                        autoComplete="off"
                        label="SISBOV"
                        required={settings && settings.sisbov_active === 'yes'}
                        size="small"
                        value={values.sisbov}
                        error={!!errors.sisbov && touched.sisbov}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {/* <ErrorMessage name="sisbov" component={Error} /> */}
                      {!!errors.sisbov && touched.sisbov && (
                        <Error>{errors.sisbov}</Error>
                      )}
                    </Grid>
                    <Grid item sm xs={12}>
                      <StyledTextField
                        fullWidth
                        id="botton_rfid"
                        name="botton_rfid"
                        variant="outlined"
                        label="BOTTON RFID"
                        autoComplete="off"
                        required={settings && settings.default_identification === 'botton_rfid'}
                        size="small"
                        value={values.botton_rfid}
                        error={!!errors.botton_rfid && touched.botton_rfid}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {/* <ErrorMessage name="botton_rfid" component={Error} /> */}
                      {!!errors.botton_rfid && touched.botton_rfid && (
                        <Error>{errors.botton_rfid}</Error>
                      )}
                    </Grid>
                    <Grid item sm xs={12}>
                      <StyledTextField
                        fullWidth
                        id="ident_earing"
                        name="ident_earing"
                        required={
                          settings && settings.default_identification === 'ident_earing'
                        }
                        variant="outlined"
                        label="BRINCO"
                        autoComplete="off"
                        size="small"
                        value={values.ident_earing}
                        error={!!errors.ident_earing && touched.ident_earing}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      {/* <ErrorMessage name="ident_earing" component={Error} /> */}
                      {!!errors.ident_earing && touched.ident_earing && (
                        <Error>{errors.ident_earing}</Error>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md>
                      <FormControl
                        size="small"
                        required
                        fullWidth
                        variant="outlined"
                        error={!!errors.breed_secure_id && touched.breed_secure_id}
                      >
                        <InputLabel id="input-breed">Raça</InputLabel>
                        <Select
                          name="breed_secure_id"
                          labelWidth={45}
                          inputProps={{
                            id: 'breed-input',
                          }}
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                          }}
                          value={values.breed_secure_id}
                          onChange={event =>
                            handleSelectBreed(event, handleChange)
                          }
                        >
                          {breedsData && breedsData.breeds.map(item => (
                            <MenuItem
                              key={item.secure_id}
                              value={item.secure_id}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <ErrorMessage name="breed_secure_id" component={Error} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md>
                      <FormControl
                        size="small"
                        required
                        fullWidth
                        variant="outlined"
                        error={!!errors.age && touched.age}
                      >
                        <InputLabel id="input-age">Idade</InputLabel>
                        <Select
                          name="age"
                          labelWidth={50}
                          inputProps={{
                            id: 'age-input',
                          }}
                          value={values.age}
                          onChange={handleChange}
                        >
                          {ages.map(item => (
                            <MenuItem key={item.id} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        <ErrorMessage name="age" component={Error} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md>
                      <FormControl
                        size="small"
                        required
                        fullWidth
                        variant="outlined"
                        error={!!errors.type && touched.type}
                      >
                        <InputLabel id="input-type">Categoria</InputLabel>
                        <Select
                          name="type"
                          labelWidth={83}
                          inputProps={{
                            id: 'type-input',
                          }}
                          value={values.type}
                          onChange={handleChange}
                        >
                          {types
                            .filter(item => {
                              if (values.sex === 'Macho') {
                                return (
                                  item === 'Inteiro' ||
                                  item === 'Castrado' ||
                                  item === 'Bezerro'
                                );
                              }
                              return (
                                item === 'Vaca' ||
                                item === 'Novilha' ||
                                item === 'Bezerra'
                              );
                            })
                            .map(item => (
                              <MenuItem key={item.id} value={item}>
                                {item}
                              </MenuItem>
                            ))}
                        </Select>
                        <ErrorMessage name="type" component={Error} />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12} md>
                      <StyledRadioGroup row defaultValue={values.sex}>
                        {gender.map(sex => (
                          <FormControlLabel
                            key={sex.value}
                            name="sex"
                            value={sex.value}
                            control={
                              <Radio
                                color="primary"
                                size="small"
                                name="sex"
                                onChange={(event) => {
                                  handleChange(event)
                                  setFieldValue('type', '')
                                }}
                              />
                            }
                            label={sex.label}
                          />
                        ))}
                      </StyledRadioGroup>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm={4} xs={12}>
                      <FormControl
                        size="small"
                        required
                        fullWidth
                        variant="outlined"
                        error={!!errors.body_size && touched.body_size}
                      >
                        <InputLabel id="input-body_size">
                          Tamanho Corporal
                        </InputLabel>
                        <Select
                          name="body_size"
                          labelWidth={145}
                          value={values.body_size}
                          onChange={handleChange}
                        >
                          {bodySizes.map(item => (
                            <MenuItem key={item.id} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        <ErrorMessage name="body_size" component={Error} />
                      </FormControl>
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <NumberFormatForm
                        allowedDecimalSeparators={[',']}
                        name="weight_forecast"
                        label="Peso de Abate Previsto"
                        variant="outlined"
                        size="small"
                        fullWidth
                        allowNegative={false}
                        fixedDecimalScale
                        decimalScale={2}
                        value={values.weight_forecast}
                        setFieldValue={setFieldValue}
                        error={
                          !!errors.weight_forecast && touched.weight_forecast
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kg</InputAdornment>
                          ),
                        }}
                      />
                      <ErrorMessage
                        name="weight_forecast"
                        component={Error}
                      />
                    </Grid>
                  </Grid>

                  <ContentSubmitButtons>
                    <StyledSubmitButton
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={handleBack}
                    >
                      Cancelar
                    </StyledSubmitButton>
                    <ButtonSubmit title="Alterar" loading={isSubmitting} />
                  </ContentSubmitButtons>
                </StyledPaper>
              </ContentItem>
            </Form>
          )}
        </Formik>
      </Container>
    </center>
  );
}

AnimalsEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

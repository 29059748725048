import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Backdrop,
  Fade,
  Grid,
  FormControlLabel,
  Button,
  Checkbox,
  Divider,
  Typography,
} from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import CancelIcon from '@material-ui/icons/Cancel';
import PrintIcon from '@material-ui/icons/Print';

// Query
import { useApiV2ShowDataManufacturingFeed } from '~/hooks/apiV2/foodManagement/manufactoringFeed/useApiV2ShowDataManufacturingFeed';

import {
  Container,
  StyledPaper,
  StyledGrid,
  CloseButton,
  ContentPrint,
  ContentButton,
} from './styles';

import Print from './Print';

export default function ModalManufactoringDiet({
  visibleModal, handleModal, currentDiet = null, currentDate
}) {
  const [dataPrint, setDataPrint] = useState([]);

  const { data } = useApiV2ShowDataManufacturingFeed();

  const PrintScreen = useRef();

  function handlePrint() {
    handleModal();
  }

  function handleSelectFeed(feed) {
    const newFeed = dataPrint.find(
      newCurrentFeed => newCurrentFeed.secure_id === feed.secure_id
    );

    if (newFeed) {
      const allFeeds = dataPrint.filter(
        newCurrentFeed => newCurrentFeed.secure_id !== feed.secure_id
      );
      setDataPrint(allFeeds);
    } else {
      setDataPrint([...dataPrint, feed]);
    }
  }

  return (
    <>
      <Modal
        open={visibleModal}
        onClose={handleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={visibleModal}>
          <Container>
            <StyledPaper>
              <Typography variant="h6">Misturas/Rações</Typography>
              {data && data.feeds.map((feed, index) => (
                <>
                  <StyledGrid
                    container
                    alignItems="center"
                    justify="space-between"
                  >
                    <Grid item xs sm>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => handleSelectFeed(feed)}
                            color="primary"
                          />
                        }
                        label={feed.name}
                      />
                    </Grid>
                  </StyledGrid>
                  {data.feeds.length - 1 !== index && <Divider light />}
                </>
              ))}

              <ContentButton>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<PrintIcon />}
                      disabled={dataPrint.length === 0}
                    >
                      Imprimir
                    </Button>
                  )}
                  onBeforeGetContent={handlePrint}
                  content={() => PrintScreen.current}
                />
              </ContentButton>
            </StyledPaper>

            <CloseButton stype="button" onClick={handleModal}>
              <CancelIcon fontSize="large" variant="outlined" />
            </CloseButton>
          </Container>
        </Fade>
      </Modal>

      <ContentPrint>
        <Print
          ref={PrintScreen}
          line={currentDiet}
          date={currentDate}
          data={dataPrint}
        />
      </ContentPrint>
    </>
  );
}

ModalManufactoringDiet.propTypes = {
  visibleModal: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  currentFeed: PropTypes.string.isRequired,
};

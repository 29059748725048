import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Backdrop,
  Fade,
  TextField,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Divider,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

import {
  StyledPaper,
  StyledTitleGrid,
  StyledTitleButton,
  Title,
  StyledGrid,
  StyledGridItem,
  ContentTable,
  StyledTableHead,
  CloseButton,
} from './styles';

import { formatNumber } from '~/utils/format';

export default function ModalMixFoods({
  visibleModal,
  handleModal,
  mix,
  handleRollbackMix,
  enableReturnMix,
}) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalInclMS, setTotalInclMS] = useState(0);
  const [totalInclMO, setTotalInclMO] = useState(0);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  useEffect(() => {
    if (mix) {
      const { inclMS, inclMO } = mix.preMixes.reduce(
        (accumulator, food) => {
          accumulator.inclMS += Number(food.percent_incl_ms_food);
          accumulator.inclMO += Number(food.percent_incl_mo_food);

          return accumulator;
        },
        {
          inclMS: 0,
          inclMO: 0,
        }
      );

      setTotalInclMS(Math.round(inclMS));
      setTotalInclMO(Math.round(inclMO));
    }
  }, [mix]);

  return (
    <Modal
      open={visibleModal}
      onClose={handleModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visibleModal}>
        <StyledPaper>
          <StyledTitleGrid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
          >
            {mix.type === 'mix' && enableReturnMix ? (
              <StyledTitleButton
                variant="contained"
                size="medium"
                color="primary"
                onClick={handleRollbackMix}
              >
                Desfazer
              </StyledTitleButton>
            ) : (
              <div id="empty" />
            )}

            <Title color="primary">{mix.name}</Title>
            <div id="empty" />
          </StyledTitleGrid>

          <Divider light />

          <StyledGrid container justify="space-around" spacing={2}>
            <StyledGridItem item sm xs={12}>
              <TextField
                label="MS (%)"
                size="small"
                disabled
                variant="outlined"
                value={formatNumber(mix.percent_ms, 2, 2)}
              />
            </StyledGridItem>
            <StyledGridItem item sm xs={12}>
              <TextField
                label="Incl MS (%)"
                size="small"
                disabled
                variant="outlined"
                value={formatNumber(mix.dietFoodPivot.percent_incl_ms_food, 2, 2)}
              />
            </StyledGridItem>
            <StyledGridItem item sm xs={12}>
              <TextField
                label="Incl MO (%)"
                size="small"
                disabled
                variant="outlined"
                value={formatNumber(mix.dietFoodPivot.percent_incl_mo_food, 2, 2)}
              />
            </StyledGridItem>
          </StyledGrid>

          <center>
            <ContentTable>
              <TableContainer>
                <Table stickyHeader>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell>Alimento</TableCell>
                      <TableCell align="right">MS (%)</TableCell>
                      <TableCell align="right">Incl MS (%)</TableCell>
                      <TableCell align="right">Incl MO (%)</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {mix.preMixes
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(mixFood => (
                        <TableRow key={mixFood.food.id}>
                          <TableCell component="th" scope="row">
                            {mixFood.food.name}
                          </TableCell>
                          <TableCell align="right">
                            {`${formatNumber(mixFood.percent_ms, 2, 2)}%`}
                          </TableCell>
                          <TableCell align="right">
                            {`${formatNumber(
                              mixFood.percent_incl_ms_food,
                              2,
                              2
                            )}%`}
                          </TableCell>
                          <TableCell align="right">
                            {`${formatNumber(
                              mixFood.percent_incl_mo_food,
                              2,
                              2
                            )}%`}
                          </TableCell>
                        </TableRow>
                      ))}
                    <TableRow>
                      <TableCell align="left">Total</TableCell>
                      <TableCell />
                      <TableCell align="right">{`${formatNumber(
                        Math.round(totalInclMS),
                        4,
                        4
                      )}%`}</TableCell>
                      <TableCell align="right">{`${formatNumber(
                        Math.round(totalInclMO),
                        4,
                        4
                      )}%`}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                labelRowsPerPage="Linhas por pág."
                component="div"
                count={mix.preMixes.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </ContentTable>
          </center>

          <CloseButton stype="button" onClick={handleModal}>
            <CancelIcon fontSize="large" variant="outlined" />
          </CloseButton>
        </StyledPaper>
      </Fade>
    </Modal>
  );
}

ModalMixFoods.propTypes = {
  visibleModal: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  mix: PropTypes.shape({
    name: PropTypes.string,
    percent_ms: PropTypes.number,
    type: PropTypes.string,
    dietFoodPivot: PropTypes.shape({
      percent_incl_ms_food: PropTypes.number,
      percent_incl_mo_food: PropTypes.number,
    }),
    preMixes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  handleRollbackMix: PropTypes.func.isRequired,
  enableReturnMix: PropTypes.bool.isRequired,
};

import styled, { css } from 'styled-components';
import {
  Paper,
  Grid,
  Typography,
  TextField,
  Divider,
  Button,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 960px;
  width: 80%;
  padding: 40px 0px;

  text-align: left;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 22px;
`;

export const StyledGrid = styled(Grid)`
  margin-bottom: 8px;

  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop}px;
    `}
`;

export const StyledGridItem = styled(Grid)`
  margin-top: 32px;
`;

export const StyledTypographyTitle = styled(Typography)`
  margin: 8px 0px 4px;
  color: ${colors.primary};
`;

export const StyledTypography = styled(Typography)`
  margin-bottom: 8px;
  color: ${colors.grey};
`;

export const ContentDivider = styled.div`
  display: flex;
  padding: 0px 8px;
`;

export const StyledDivider = styled(Divider)`
  ${props =>
    props.color &&
    css`
      background: ${props.color};
    `}
`;

export const StyledTextField = styled(TextField)`
  margin-top: 6px;
`;

export const Error = styled.div`
  padding: 6px 8px 0;

  span {
    font-size: 0.75rem;
    min-height: 1em;
    text-align: left;
    font-family: Roboto, 'helvetica';
    font-weight: 400;
    line-height: 1em;
    color: ${colors.error};
  }
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

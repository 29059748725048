import React, { useEffect, useState } from 'react';
import { Grid, TextField, Hidden } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

// Query
import { useApiV2FilterZootechAnalytic } from '~/hooks/apiV2/Report/zootechAnalytic/useApiV2FilterZootechAnalytic';

import {
  Container,
  StyledPaper,
  StyledButtonGenerator,
  StyledButton,
} from './styles';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.value,
});

export default function Filter({ setFilters }) {
  const [selectedLot, setSelectedLot] = useState(null);

  // Query
  const { data } = useApiV2FilterZootechAnalytic();

  function cleanState() {
    setSelectedLot(null);
  }

  async function handleGenarateReport(lot) {
    setFilters({
      lot
    });
  }

  useEffect(() => {
    if (data) {
      cleanState();
    }
  }, [data]);

  return (
    <Container>
      <StyledPaper>
        <Grid container spacing={2} alignItems="center" justify="space-around">
          <Grid item sm={4}>
            <Autocomplete
              size="small"
              noOptionsText="Sem opções"
              filterOptions={filterOptions}
              options={data ? data : []}
              getOptionLabel={option => option.value}
              value={selectedLot}
              onChange={(event, value) => setSelectedLot(value)}
              renderInput={params => (
                <TextField {...params} label="Lote" fullWidth />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Hidden only="xs">
            <Grid item sm lg md />
          </Hidden>

          <Grid item xs={12} sm="auto">
            <Hidden only="xs">
              <StyledButtonGenerator
                variant="contained"
                color="primary"
                fullWidth
                onClick={() =>
                  handleGenarateReport(selectedLot && selectedLot.secure_id)
                }
              >
                Gerar
              </StyledButtonGenerator>
            </Hidden>

            <Hidden smUp>
              <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={() =>
                  handleGenarateReport(selectedLot && selectedLot.secure_id)
                }
              >
                Gerar
              </StyledButton>
            </Hidden>
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

// QUery
import { useApiV2GlobalLots } from '~/hooks/apiV2/global/lot/useApiV2GlobalLots';

import { Container, StyledPaper, StyledIconButton } from './styles';

import { regexDate } from '~/utils/common';
import InputDatePicker from '~/components/InputDatePicker';

const lotDefaultOption = {
  id: null,
  name: 'Todos',
};

export default function TransferLotFilter({ date, setDate, setOriginLot }) {
  const [lots, setLots] = useState([]);

  // Query
  const { data: lotsData } = useApiV2GlobalLots([true]);

  useEffect(() => {
    if (lotsData) {
      setLots(lotsData.lots);
    }
  }, [lotsData]);

  function handleChangeDate(newDate, value) {
    if (value && !regexDate.test(value)) return;

    setDate(newDate);
  }

  function handleChangeLot(event) {
    setOriginLot(event.target.value.secure_id);
  }

  return (
    <Container>
      <StyledPaper>
        <Grid container alignItems="center" justify="space-between" spacing={2}>
          <Grid item sm xs>
            <FormControl size="small" fullWidth>
              <InputLabel>Lote</InputLabel>
              <Select
                defaultValue={lotDefaultOption}
                onChange={handleChangeLot}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value={lotDefaultOption}>
                  {lotDefaultOption.name}
                </MenuItem>
                {lots.map(lot => (
                  <MenuItem
                    style={{ justifyContent: 'space-between' }}
                    key={lot.secure_id}
                    value={lot}
                  >
                    {lot.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item sm xs={12}>
            <InputDatePicker
              label="Data Inicial"
              maxDate={new Date()}
              selectedDate={date}
              handleChangeDate={(currentDate, value) =>
                handleChangeDate(currentDate, value)
              }
              InputProps={{
                startAdornment: (
                  <StyledIconButton onClick={() => setDate(null)}>
                    <ClearIcon />
                  </StyledIconButton>
                ),
              }}
              InputAdornmentProps={{
                position: 'end',
              }}
            />
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

TransferLotFilter.defaultProps = {
  date: null,
};

TransferLotFilter.propTypes = {
  date: PropTypes.string,
  setDate: PropTypes.func.isRequired,
  setOriginLot: PropTypes.func.isRequired,
};

import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import MaterialTable, { MTableToolbar } from 'material-table';
import {
  Modal,
  Backdrop,
  Fade,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import {
  parseISO,
  isSameDay,
  format,
} from 'date-fns';

import { toast } from 'react-toastify';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';

import {
  Container,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';

import colors from '~/styles/colors';
import { ages, types, bodySizes } from '~/utils/options';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function ModalAnimalInput({
  visibleImport,
  animalsImport,
  setVisibleImport,
  setArrayAnimals,
  setAnimalsImport,
  arrayAnimals,
  aparts,
  breeds,
  dateInput
}) {
  const [edit, setEdit] = useState(false);
  const [apart, setApart] = useState(null);
  const [breedId, setBreedId] = useState(null);
  const [age, setAge] = useState(null);
  const [sex, setSex] = useState(null);
  const [type, setType] = useState(null);
  const [bodySize, setBodySize] = useState(null);

  const apartIndex = aparts.map(filterApart => filterApart.apart).filter((item, pos) => {
    return aparts.map(filterApart => filterApart.apart).indexOf(item) === pos;
  });

  const listAparts = {};
  apartIndex.forEach((value, index) => {
    listAparts[index + 1] = value;
  });

  const listBreeds = {};
  breeds.forEach(value => {
    listBreeds[value.secure_id] = value.name;
  });

  const listAges = {};
  ages.forEach(value => {
    listAges[value] = value;
  });

  const listTypes = {};
  types.forEach(value => {
    listTypes[value] = value;
  });

  const listBodySizes = {};
  bodySizes.forEach(value => {
    listBodySizes[value] = value;
  });

  const listGenders = { Macho: 'Macho', Femea: 'Femea' };

  const handleEditAll = () => {
    const animals = animalsImport.map(animal => {
      return {
        ...animal,
        age,
        apart,
        body_size: bodySize,
        breed_id: breedId,
        sex,
        type,
      };
    });
    setAnimalsImport([...animals]);
    setEdit(false);
    setApart(null);
    setAge(null);
    setSex(null);
    setType(null);
    setBodySize(null);
  };

  const handleImportAnimals = () => {
    const errors = animalsImport.reduce((acc, animal) => {
      if (!animal.age) {
        return acc + 1;
      }

      if (!animal.apart) {
        return acc + 1;
      }

      if (!animal.breed_id) {
        return acc + 1;
      }

      if (!animal.sex) {
        return acc + 1;
      }

      if (!animal.type) {
        return acc + 1;
      }

      return acc;
    }, 0);

    const errorOriginApart = [...new Set(animalsImport.map(x => x.apart))].map(apart => {
      const currentApart = aparts.find(apartFind => apartFind.apart === apart);

      if (currentApart && currentApart.date && !isSameDay(parseISO(currentApart.date), dateInput)) {
        return `Aparte ${apart} possui entrada de animais na data ${format(parseISO(currentApart.date), 'dd/MM/yyyy')}`
      } else {
        return null;
      }
    }).filter(isNotNull => isNotNull !== null);

    if (errorOriginApart.length > 0) {
      toast.error(errorOriginApart[0]);
      return;
    }

    if (!errors) {
      setArrayAnimals([...arrayAnimals, ...animalsImport]);
      setVisibleImport(false);
    } else {
      toast.error(`${errors} animais estão com dados incompletos`);
    }
  };

  return (
    <Modal
      open={visibleImport}
      onClose={() => setVisibleImport(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visibleImport}>
        <Container>
          {/* // <StyledPaper /> */}
          <MaterialTable
            title="Animais"
            components={{
              Toolbar: props => {
                if (edit) {
                  return (
                    <div style={{ padding: '0px 10px' }}>
                      <MTableToolbar {...props} />
                      <Grid container spacing={2}>
                        <Grid item sm xs md={2}>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                          >
                            <InputLabel id="input-apart">Aparte</InputLabel>
                            <Select
                              name="apart"
                              labelWidth={50}
                              inputProps={{
                                id: 'apart-input',
                              }}
                              value={apart}
                              onChange={event => setApart(event.target.value)}
                            >
                              {aparts
                                // .filter((item, pos) => {
                                //   return aparts.indexOf(item) === pos;
                                // })
                                .map(item => (
                                  <MenuItem key={item.apart} value={item.apart}>
                                    {item.apart}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item sm xs md={2}>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                          >
                            <InputLabel id="input-breed">Raça</InputLabel>
                            <Select
                              name="breed_id"
                              labelWidth={45}
                              inputProps={{
                                id: 'breed-input',
                              }}
                              MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                              }}
                              // value={breedId}
                              onChange={event => {
                                setBreedId(event.target.value.secure_id);
                              }}
                            >
                              {breeds.map(item => (
                                <MenuItem key={item.secure_id} value={item}>
                                  {item.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item sm xs md={2}>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                          >
                            <InputLabel id="input-age">Idade</InputLabel>
                            <Select
                              name="age"
                              labelWidth={50}
                              inputProps={{
                                id: 'age-input',
                              }}
                              value={age}
                              onChange={event => setAge(event.target.value)}
                            >
                              {ages.map(item => (
                                <MenuItem key={item.id} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item sm xs md={2}>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                          >
                            <InputLabel id="input-sex">Sexo</InputLabel>
                            <Select
                              name="sex"
                              labelWidth={45}
                              inputProps={{
                                id: 'sex-input',
                              }}
                              MenuProps={{
                                getContentAnchorEl: null,
                                anchorOrigin: {
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                },
                              }}
                              value={sex}
                              onChange={event => setSex(event.target.value)}
                            >
                              <MenuItem key="Macho" value="Macho">
                                Macho
                              </MenuItem>
                              <MenuItem key="Femea" value="Femea">
                                Fêmea
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item sm xs md={2}>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                          >
                            <InputLabel id="input-type">Categoria</InputLabel>
                            <Select
                              name="type"
                              labelWidth={83}
                              inputProps={{
                                id: 'type-input',
                              }}
                              value={type}
                              onChange={event => setType(event.target.value)}
                            >
                              {types
                                .filter(item => {
                                  if (sex === 'Macho') {
                                    return (
                                      item === 'Inteiro' ||
                                      item === 'Castrado' ||
                                      item === 'Bezerro'
                                    );
                                  }
                                  return (
                                    item === 'Vaca' ||
                                    item === 'Novilha' ||
                                    item === 'Bezerra'
                                  );
                                })
                                .map(item => (
                                  <MenuItem key={item.id} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item sm xs md={2}>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                          >
                            <InputLabel id="input-body_size">
                              Tam. Corporal
                            </InputLabel>
                            <Select
                              name="body_size"
                              labelWidth={145}
                              value={bodySize}
                              onChange={event =>
                                setBodySize(event.target.value)
                              }
                            >
                              {bodySizes.map(item => (
                                <MenuItem key={item.id} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        spacing={2}
                        container
                        direction="row"
                        justifyContent="flex-end"
                      >
                        <Grid item sm xs md={2} />
                        <Grid item sm xs md={2} />
                        <Grid item sm xs md={2} />
                        <Grid item sm xs md={2} />
                        <Grid item sm xs md={2} />
                        <Grid item sm xs md={2}>
                          <StyledSubmitButton
                            variant="contained"
                            color="#fff"
                            style={{ background: colors.success }}
                            onClick={() => {
                              handleEditAll();
                            }}
                          >
                            Aplicar
                          </StyledSubmitButton>
                        </Grid>
                      </Grid>
                    </div>
                  );
                }
                return <MTableToolbar {...props} />;
              },
            }}
            actions={[
              {
                icon: () => (
                  <StyledSubmitButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setEdit(true);
                    }}
                  >
                    Editar Todos
                  </StyledSubmitButton>
                ),
                isFreeAction: true,
                // onClick: event => alert('You want to add a new row'),
              },
            ]}
            columns={[
              { title: 'Sisbov', field: 'sisbov' },
              { title: 'Botton RFID', field: 'botton_rfid' },
              { title: 'Brinco', field: 'ident_earing' },
              { title: 'Peso', field: 'input_weight' },
              {
                title: 'Aparte',
                field: 'apart',
                lookup: { ...listAparts },
              },
              { title: 'Raça', field: 'breed_id', lookup: { ...listBreeds } },
              { title: 'Idade', field: 'age', lookup: { ...listAges } },
              { title: 'Sexo', field: 'sex', lookup: { ...listGenders } },
              { title: 'Categoria', field: 'type', lookup: { ...listTypes } },
              {
                title: 'Tamanho Corporal',
                field: 'body_size',
                lookup: { ...listBodySizes },
              },
              { title: 'RC de Entrada', field: 'percent_rc' },
              { title: 'Preço M.Animal', field: 'average_price' },
            ]}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const dataUpdate = [...animalsImport];
                  const index = oldData.tableData.id;
                  const newBreed = breeds.filter(
                    data => data.secure_id === newData.breed_id
                  );
                  dataUpdate[index] = { ...newData, breed: newBreed[0] };
                  setAnimalsImport([...dataUpdate]);
                  resolve();
                }),
            }}
            icons={tableIcons}
            localization={{
              pagination: {
                labelRowsSelect: 'animais',
                labelDisplayedRows: '{count} de {from}-{to}',
                firstTooltip: 'Primeira página',
                previousTooltip: 'Página anterior',
                nextTooltip: 'Próxima página',
                lastTooltip: 'Última página',
              },
              toolbar: {
                nRowsSelected: '{0} animais(s) selecionados',
                searchTooltip: 'Localizar Animal',
                searchPlaceholder: 'Localizar Animal',
              },
              header: {
                actions: 'Ações',
              },
              body: {
                emptyDataSourceMessage: 'Nenhum animal foi importado',
                filterRow: {
                  filterTooltip: 'Filtrar',
                },
              },
            }}
            options={{
              paging: true,
              pageSize: 15,
              emptyRowsWhenPaging: true, // to make page size fix in case of less data rows
              pageSizeOptions: [15, 25, 50, 100],
              maxBodyHeight: '400px',
            }}
            data={animalsImport}
          />
          <ContentSubmitButtons>
            <StyledSubmitButton
              variant="contained"
              color="primary"
              onClick={() => handleImportAnimals()}
            >
              Importar
            </StyledSubmitButton>
          </ContentSubmitButtons>
        </Container>
      </Fade>
    </Modal>
  );
}

ModalAnimalInput.propTypes = {
  visibleImport: PropTypes.bool.isRequired,
  animalsImport: PropTypes.arrayOf().isRequired,
  arrayAnimals: PropTypes.arrayOf().isRequired,
  aparts: PropTypes.arrayOf().isRequired,
  breeds: PropTypes.arrayOf().isRequired,
  setVisibleImport: PropTypes.func.isRequired,
  setArrayAnimals: PropTypes.func.isRequired,
  setAnimalsImport: PropTypes.func.isRequired,
  dateInput: PropTypes.string
};

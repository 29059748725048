import styled from 'styled-components';
import {
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  Paper,
  Button,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  max-height: calc(100vh - 255px);
  margin-bottom: 24px;

  thead th {
    background: ${colors.header};
  }
`;

export const TableCellInfo = styled(TableCell)`
  color: ${colors.primary};
  font-size: larger;
`;

export const TableCellHeaderFixed = styled(TableCell)`
  position: sticky;
  z-index: 3;
  left: 0;
`

export const TableCellFixed = styled(TableCell)`
  color: ${colors.primary};
  font-size: 14px;
  //fixed column
  position: sticky;
  z-index: 1;
  left: 0;
  background: #f2f2f2;
`;

export const StyledSubmitButton = styled(Button)`
  margin-top: 24px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

export const Container = styled.div`
  max-width: 1600px;
  width: 100%;
  padding: 40px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 40px;
`;

export const StyledTableBody = styled(TableBody)`
  tr td {
  }
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      font-size: larger;

      color: ${colors.primary};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

export const StyledTableCellEllipsis = styled(TableCell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`;

import {
  Backdrop, Divider, Fade,
  FormControl, Grid, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableRow
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useV2ShowManufactoringDiet } from '~/hooks/apiV2/foodManagement/manufactoringDiet/useV2ManufactoringDiet';
import { formatNumber } from '~/utils/format';
import {
  CloseButton, Container, StyledGrid,
  StyledGridItem, StyledPaper, StyledTableCell, StyledTableHead,
  StyledTextField
} from './styles';

export default function ModalManufactoringDiet({
  visibleModal,
  handleCloseModal,
  secure_id
}) {
  const [totalPriceTon, setTotalPriceTon] = useState(0);

  const { data } = useV2ShowManufactoringDiet(secure_id)

  useEffect(() => {
    if (!!data) {
      const listManufactoringDiet = data.diet.foods

      const newListManufactoringDiets = listManufactoringDiet.map(food => ({
        ...food,
        forecast_ms: Number(food.manufactoreDietFood[0].forecast) * (food.percent_ms / 100),
      }));

      const {
        qtdforecast,
        price,
      } = newListManufactoringDiets.reduce(
        (accumulator, food) => {
          accumulator.qtdforecast += Number(food.manufactoreDietFood[0].forecast);

          if (food.lastMovement) {
            accumulator.price +=
              food.lastMovement.price_ton <= 0
                ? 0
                : (Number(food.manufactoreDietFood[0].forecast) / 1000) *
                Number(food.lastMovement.price_ton);
          } else {
            const priceTon = ((food.foodMovements[0].current_total_price <= 0) && (food.foodMovements[0].current_total_amount_ton <= 0)) ? 0 : food.foodMovements[0].current_total_price / food.foodMovements[0].current_total_amount_ton
            accumulator.price +=
              priceTon <= 0
                ? 0
                : (Number(food.manufactoreDietFood[0].forecast) / 1000) *
                Number(priceTon);
          }

          return accumulator;
        },
        {
          qtdforecast: 0,
          price: 0,
        }
      );

      const priceTon = qtdforecast === 0 ? 0 : price / (qtdforecast / 1000);
      setTotalPriceTon(priceTon);
    } else {
      setTotalPriceTon(0);
    }
  }, [data]);

  return (
    <Modal
      open={visibleModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visibleModal}>
        <Container>
          {!!data && (
            <StyledPaper>
              <StyledGrid container justify="space-between" alignItems="center">
                <StyledGridItem item sm />
                <StyledGridItem item sm={4} xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-diet-input-label">Dieta</InputLabel>
                    <Select
                      labelId="select-diet-label"
                      id="select-diet"
                      value={data.diet}
                      disabled
                      labelWidth={40}
                    >
                      <MenuItem
                        id={String(data.diet.secure_id)}
                        value={data.diet}
                      >
                        {data.diet.name}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
                <StyledGridItem item sm={4} xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-date-input-label">Data</InputLabel>
                    <Select
                      labelId="select-date-label"
                      id="select-date"
                      value={data}
                      disabled
                      labelWidth={35}
                    >
                      <MenuItem
                        id={String(data.secure_id)}
                        value={data}
                      >
                        {format(
                          new Date(data.date),
                          'dd/MM/yyyy'
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
                <StyledGridItem item sm />
              </StyledGrid>

              <StyledGrid container justify="space-between" alignItems="center">
                <StyledGridItem item sm />
                <StyledGridItem item sm={4} xs={12}>
                  <StyledTextField
                    id="total_forecast"
                    label="Total Previsto"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                    value={data.total_forecast}
                  />
                </StyledGridItem>
                <StyledGridItem item sm={4} xs={12}>
                  <StyledTextField
                    id="total_performed"
                    label="Total Realizado"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                    value={data.total_performed}
                  />
                </StyledGridItem>
                <StyledGridItem item sm />
              </StyledGrid>

              <Divider light />

              <StyledGrid container justify="space-between" alignItems="center">
                <TableContainer>
                  <Table stickyHeader>
                    <StyledTableHead>
                      <TableRow>
                        <TableCell>Alimento</TableCell>
                        <TableCell align="right">Quantidade Prevista</TableCell>
                        <TableCell align="right">
                          Quantidade Realizada
                        </TableCell>
                        <TableCell align="right">
                          Desvio (%)
                        </TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <TableBody>
                      {data.diet.foods.map(food => (
                        <TableRow key={food.secure_id}>
                          <TableCell component="th" scope="row">
                            {food.name}
                          </TableCell>
                          <TableCell align="right">
                            <StyledTextField
                              disabled
                              type="number"
                              value={food.manufactoreDietFood[0].forecast}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <StyledTextField
                              disabled
                              type="number"
                              value={food.manufactoreDietFood[0].performed}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <StyledTextField
                              disabled
                              value={food.manufactoreDietFood[0].deviation}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <StyledTableCell align="left">
                          Quantidade
                        </StyledTableCell>
                        <TableCell align="right">
                          {formatNumber(data.qtd_forecast, 0, 0)}
                        </TableCell>
                        <TableCell align="right">
                          {formatNumber(data.qtd_performed, 0, 0)}
                        </TableCell>
                        <TableCell align="right">
                          {formatNumber(data.deviation, 2, 2)}  %
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell align="left">
                          MS na Dieta
                        </StyledTableCell>
                        <TableCell align="right">
                          {' '}
                          {`${formatNumber(data.treatmentLots[0].percent_forecast_ms_diet, 2, 2)} %`}
                        </TableCell>
                        <TableCell align="right">
                          {' '}
                          {`${formatNumber(data.percent_ms_diet, 2, 2)} %`}
                        </TableCell>
                        <TableCell align="right">
                          {' '}
                          {`${formatNumber(data.percent_ms_diet - data.treatmentLots[0].percent_forecast_ms_diet, 2, 2)} %`}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell align="left">Custo/Ton</StyledTableCell>
                        <TableCell align="right">{`R$ ${formatNumber(
                          totalPriceTon,
                          2,
                          2
                        )}`}</TableCell>
                        <TableCell align="right">{`R$ ${formatNumber(
                          data.price_ton,
                          2,
                          2
                        )}`}</TableCell>
                        <TableCell align="right">{`R$ ${formatNumber(
                          data.price_ton - totalPriceTon,
                          2,
                          2
                        )}`}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <StyledGrid container justify="flex-end" spacing={2}>
                  <Grid
                    item
                    sm={3}
                    xs={12}
                    style={{ margin: '6px 16px 0px 0px' }}
                  >
                    Funcionário:
                    {' '}
                    <strong>
                      {data.employee && data.employee.name}
                    </strong>
                  </Grid>
                </StyledGrid>
              </StyledGrid>
            </StyledPaper>
          )}
          <CloseButton stype="button" onClick={handleCloseModal}>
            <CancelIcon fontSize="large" variant="outlined" />
          </CloseButton>
        </Container>
      </Fade>
    </Modal>
  );
}

ModalManufactoringDiet.propTypes = {
  visibleModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import { formatDateOnly } from '~/utils/format';

export async function getData(
  sort,
  page = 1,
  limit = 50,
  feed,
  date
) {
  try {
    const { data } = await apiV2.get(
      `/v1/feedlot/food-management/manufacturingFeed?sort=${sort}`,
      {
        params: { page, limit, feed, date },
      }
    );

    const newData = data.data.map(manufacturingFeed => ({
      ...manufacturingFeed,
      date: manufacturingFeed.date
        ? formatDateOnly(manufacturingFeed.date.replace('Z', ''))
        : '',
    }));

    return {
      manufacturingFeeds: newData,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    };
  } catch(error) {
    console.log(error)
    toast.error('Não foi possível buscar fabricações!');
  }
}

export function useApiV2ManufacturingFeed(sort, page, limit, feed, date) {
  return useQuery(
    ['manufacturingFeed', sort, page, limit, feed, date],
    () => getData(sort, page, limit, feed, date),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  );
}
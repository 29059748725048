import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  Input,
  ListSubheader,
  MenuItem,
  Checkbox,
  ListItemText,
  Hidden,
  Button,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Query
import { useApiV2FilterConsumptionLot } from '~/hooks/apiV2/Report/consumptionLot/useApiV2FilterConsumptionLot';

import { Container, StyledPaper, StyledButton } from './styles';

import DatePicker from '~/components/DatePicker';

export default function Filter({
  setFilters
}) {
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());

  const [lines, setLines] = useState([]);
  const [paddocks, setPaddocks] = useState([]);
  const [lots, setLots] = useState([]);
  const [currentModules, setCurrentModules] = useState([]);
  const [currentLines, setCurrentLines] = useState([]);
  const [currentPaddocks, setCurrentPaddocks] = useState([]);
  const [currentLots, setCurrentLots] = useState([]);

  // Query
  const { data } = useApiV2FilterConsumptionLot();

  function cleanState() {
    setCurrentModules([]);
    setCurrentLines([]);
    setCurrentPaddocks([]);
    setCurrentLots([]);
  }

  function hasItem(list, item) {
    return list.findIndex(newItem => newItem.secure_id === item.secure_id) > -1;
  }

  function handleChangeModule(event) {
    const newLines = [];
    const newListLines = [];

    event.target.value.forEach(selectedModule => {
      if (selectedModule.lines.data.length > 0) {
        newLines.push(selectedModule.lines);
        newListLines.push(...selectedModule.lines.data);
      }
    });

    const newCurrentLines = newListLines.filter(newLine =>
      currentLines.some(currentLine => newLine.secure_id === currentLine.secure_id)
    );

    const newPaddocks = []

    newCurrentLines.forEach(
      currentLine => newPaddocks.push(...currentLine.paddocks.data)
    );

    const newCurrentPaddocks = newPaddocks.filter(newPaddock =>
      currentPaddocks.some(
        currentPaddock => newPaddock.secure_id === currentPaddock.secure_id
      )
    );

    const newLots = []

    newCurrentPaddocks.map(
      currentPaddock => newLots.push(...currentPaddock.lots.data)
    );

    const newCurrentLots = newLots.filter(newLot =>
      currentLots.some(currentLot => newLot.secure_id === currentLot.secure_id)
    );

    setLines(newLines);
    setCurrentModules(event.target.value);
    setCurrentLines(newCurrentLines);
    setCurrentPaddocks(newCurrentPaddocks);
    setCurrentLots(newCurrentLots);
  }

  function handleChangeLine(item) {
    let newLines = [];

    if (hasItem(currentLines, item)) {
      newLines = currentLines.filter(line => line.secure_id !== item.secure_id);
    } else {
      newLines = [...currentLines, item];
    }

    const newPaddocks = [];
    const newListPaddocks = [];

    newLines.forEach(line => {
      if (line.paddocks.data.length > 0) {
        newPaddocks.push(line.paddocks);
        newListPaddocks.push(...line.paddocks.data);
      }
    });

    const newCurrentPaddocks = newListPaddocks.filter(paddock =>
      currentPaddocks.some(currentPaddock => paddock.secure_id === currentPaddock.secure_id)
    );

    const newLots = []

    newCurrentPaddocks.map(
      currentPaddock => newLots.push(...currentPaddock.lots.data)
    );

    const newCurrentLots = newLots.filter(newLot =>
      currentLots.some(currentLot => newLot.secure_id === currentLot.secure_id)
    );

    setPaddocks(newPaddocks);
    setCurrentLines(newLines);
    setCurrentPaddocks(newCurrentPaddocks);
    setCurrentLots(newCurrentLots);
  }

  function handleChangePaddock(item) {
    let newPaddocks = [];

    if (hasItem(currentPaddocks, item)) {
      newPaddocks = currentPaddocks.filter(paddock => paddock.secure_id !== item.secure_id);
    } else {
      newPaddocks = [...currentPaddocks, item];
    }

    const newLots = [];
    const newListLots = [];

    newPaddocks.forEach(paddock => {
      if (paddock.lots.data.length > 0) {
        newLots.push(paddock.lots);
        newListLots.push(...paddock.lots.data);
      }
    });

    const newCurrentLots = newListLots.filter(newLot =>
      currentLots.some(currentLot => newLot.secure_id === currentLot.secure_id)
    );

    setLots(newLots);
    setCurrentPaddocks(newPaddocks);
    setCurrentLots(newCurrentLots);
  }

  function handleChangeLot(item) {
    let newLots = [];

    if (hasItem(currentLots, item)) {
      newLots = currentLots.filter(lot => lot.secure_id !== item.secure_id);
    } else {
      newLots = [...currentLots, item];
    }

    setCurrentLots(newLots);
  }

  async function handleGenarateReport() {
    const newModules = currentModules.map(selectedModule => selectedModule.secure_id);
    const newLines = currentLines.map(line => line.secure_id);
    const newPaddocks = currentPaddocks.map(paddock => paddock.secure_id);
    const newLots = currentLots.map(lot => lot.secure_id);

    setFilters({
      initialDate,
      finalDate,
      modules: newModules,
      lines: newLines,
      paddocks: newPaddocks,
      lots: newLots
    });
  }

  useEffect(() => {
    if (data) {
      cleanState();
    }
  }, [data]);

  return (
    <Container>
      <StyledPaper>
        <Grid container spacing={2} alignItems="center" justify="space-around">
          {/* Modules */}
          <Grid item xs={12} sm>
            <FormControl fullWidth>
              <InputLabel>Módulo</InputLabel>
              <Select
                multiple
                value={currentModules}
                onChange={handleChangeModule}
                input={<Input />}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                renderValue={selected =>
                  selected.map(currentModule => currentModule.name).join(', ')
                }
              >
                {data && data.map(currentModule => (
                  <MenuItem key={currentModule.secure_id} value={currentModule}>
                    <Checkbox
                      color="primary"
                      checked={
                        currentModules.findIndex(
                          item => item.secure_id === currentModule.secure_id
                        ) > -1
                      }
                    />
                    <ListItemText primary={currentModule.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Lines */}
          <Grid item xs={12} sm>
            <FormControl fullWidth>
              <InputLabel>Linha</InputLabel>
              <Select
                multiple
                disabled={currentModules.length === 0}
                value={currentLines}
                input={<Input />}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                renderValue={selected =>
                  selected.map(line => line.name).join(', ')
                }
              >
                {lines.map(line => (
                  <div key={line.secure_id}>
                    <ListSubheader key={line}>{line.title}</ListSubheader>
                    {line.data.map(item => (
                      <MenuItem
                        key={item.secure_id}
                        value={item}
                        onClick={() => handleChangeLine(item)}
                      >
                        <Checkbox
                          color="primary"
                          checked={
                            currentLines.findIndex(
                              currentItem => currentItem.secure_id === item.secure_id
                            ) > -1
                          }
                        />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                  </div>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Paddocks */}
          <Grid item xs={12} sm>
            <FormControl fullWidth>
              <InputLabel>Piquete</InputLabel>
              <Select
                multiple
                disabled={currentLines.length === 0}
                value={currentPaddocks}
                input={<Input />}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                renderValue={selected =>
                  selected.map(paddock => paddock.name).join(', ')
                }
              >
                {paddocks.map(paddock => (
                  <div key={paddock.secure_id}>
                    <ListSubheader>{paddock.title}</ListSubheader>
                    {paddock.data.map(item => (
                      <MenuItem
                        key={item.secure_id}
                        value={item}
                        onClick={() => handleChangePaddock(item)}
                      >
                        <Checkbox
                          color="primary"
                          checked={
                            currentPaddocks.findIndex(
                              newItem => newItem.secure_id === item.secure_id
                            ) > -1
                          }
                        />
                        <ListItemText primary={item.name} />
                      </MenuItem>
                    ))}
                  </div>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {/* Lots */}
          <Grid item xs={12} sm>
            <FormControl fullWidth>
              <InputLabel>Lote</InputLabel>
              <Select
                multiple
                disabled={currentPaddocks.length === 0}
                value={currentLots}
                input={<Input />}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                renderValue={selected =>
                  selected.map(lot => lot.code).join(', ')
                }
              >
                {lots.map(lot => (
                  <div key={lot.secure_id}>
                    <ListSubheader>{lot.title}</ListSubheader>
                    {lot.data.map(item => (
                      <MenuItem
                        key={item.secure_id}
                        value={item}
                        onClick={() => handleChangeLot(item)}
                      >
                        <Checkbox
                          color="primary"
                          checked={
                            currentLots.findIndex(
                              newItem => newItem.secure_id === item.secure_id
                            ) > -1
                          }
                        />
                        <ListItemText primary={item.code} />
                      </MenuItem>
                    ))}
                  </div>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={4} alignItems="center" justify="space-around">
          <Grid item xs={6} sm>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Data Inicial"
                selectedDate={initialDate}
                fullWidth
                handleChangeDate={date => setInitialDate(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} sm>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Data Final"
                selectedDate={finalDate}
                fullWidth
                handleChangeDate={date => setFinalDate(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm>
            <Hidden only="xs">
              <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleGenarateReport}
              >
                Gerar
              </StyledButton>
            </Hidden>

            <Hidden smUp>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleGenarateReport}
              >
                Gerar
              </Button>
            </Hidden>
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

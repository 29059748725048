import styled, { keyframes } from 'styled-components';
import {
  TableCell,
} from '@material-ui/core';

import colors from '~/styles/colors';

const vibrate = keyframes`
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 100%;
  }
  10% {
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    transform: rotate(4deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  90% {
    transform: rotate(2deg);
  }
`;

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

export const StyledTableCellEllipsis = styled(TableCell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
`;

export const Alert = styled.div`
  svg {
    animation: ${vibrate} 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both
      infinite;
    color: ${colors.warning};
  }
`;

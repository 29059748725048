import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
  Fade,
  Button,
  Modal,
} from '@material-ui/core';

import { AiOutlineLoading } from 'react-icons/ai';

import { useApiV2Foods } from '~/hooks/apiV2/register/food/useApiV2Foods';
import { useApiV2RecalculateFood } from '~/hooks/apiV2/register/food/useApiV2RecalculateFood';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

import {
  Recalculation,
  Container,
  StyledGrid,
  StyledPaper,
  ContentButton,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell
} from './styles';

import history from '~/services/history';
import colors from '~/styles/colors';
import Can from '~/components/Can';
import Loader from '~/components/Loader';
import ButtonNew from '~/components/ButtonNew';
import { CardTableFood } from './Components/CardTableFood';

export default function Food() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('name');
  const [direction, setDirection] = useState('asc');

  // query
  const { data, isLoading } = useApiV2Foods('food,mix', `${field}%7C${direction}`, page + 1, rowsPerPage)
  const { data: settingData } = useApiV2GlobalSetting();
  const recalculateFood = useApiV2RecalculateFood()

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  async function handleRecalculation() {
    await recalculateFood.mutateAsync()
  }

  function handleAdd() {
    history.push('/dashboard/food/add');
  }

  return (
    <center>
      {recalculateFood.isLoading ? (
        <Modal open={recalculateFood.isLoading}>
          <Fade in={recalculateFood.isLoading}>
            <Recalculation>
              <h1>Recalculando</h1>
              <AiOutlineLoading size={40} color={colors.light} />
            </Recalculation>
          </Fade>
        </Modal>
      ) : (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <Container>
              <StyledGrid container justify="space-between" spacing={1}>
                <Grid item sm md lg xl>
                  <Can checkRole={['administrator', 'owner', 'manager']}>
                    <ContentButton>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleRecalculation()}
                      >
                        Recalcular
                      </Button>
                    </ContentButton>
                  </Can>
                </Grid>
                <Can
                  checkRole={['administrator', 'owner', 'beefer']}
                  checkPermission={['food_new']}
                >
                  <Grid item sm md lg xl>
                    <ContentButton justifyContent="flex-end">
                      <ButtonNew title="Novo" onClick={handleAdd} />
                    </ContentButton>
                  </Grid>
                </Can>
              </StyledGrid>

              <StyledPaper>
                <StyledTableContainer>
                  <Table stickyHeader>
                    <StyledTableHead>
                      <TableRow>
                        <TableCell align="left">
                          <TableSortLabel
                            active={field === 'name'}
                            direction={field === 'name' ? direction : 'asc'}
                            onClick={() => handleChangeOrder('name')}
                          >
                            Alimento
                          </TableSortLabel>
                        </TableCell>
                        <StyledTableCell align="right">
                          <TableSortLabel
                            active={field === 'percent_ms'}
                            direction={
                              field === 'percent_ms' ? direction : 'asc'
                            }
                            onClick={() => handleChangeOrder('percent_ms')}
                          >
                            % MS
                          </TableSortLabel>
                        </StyledTableCell>
                        <TableCell align="center">
                          <TableSortLabel
                            active={field === 'updated_at'}
                            direction={
                              field === 'updated_at' ? direction : 'asc'
                            }
                            onClick={() => handleChangeOrder('updated_at')}
                          >
                            Última alteração
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                          <TableSortLabel
                            active={field === 'total_amount_ton'}
                            direction={
                              field === 'total_amount_ton' ? direction : 'asc'
                            }
                            onClick={() =>
                              handleChangeOrder('total_amount_ton')
                            }
                          >
                            Estoque atual (ton)
                          </TableSortLabel>
                        </TableCell>
                        {settingData && settingData.settings.enable_min_food_stock ? (
                          <TableCell align="right">
                            <TableSortLabel
                              active={field === 'min_food_stock_ton'}
                              direction={
                                field === 'min_food_stock_ton'
                                  ? direction
                                  : 'asc'
                              }
                              onClick={() =>
                                handleChangeOrder('min_food_stock_ton')
                              }
                            >
                              Estoque mínimo (ton)
                            </TableSortLabel>
                          </TableCell>
                        ) : null}
                        <TableCell align="right">
                          <TableSortLabel
                            active={field === 'total_price'}
                            direction={
                              field === 'total_price' ? direction : 'asc'
                            }
                            onClick={() => handleChangeOrder('total_price')}
                          >
                            Preço/Ton atual
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="right">
                          <TableSortLabel
                            active={field === 'active'}
                            direction={field === 'active' ? direction : 'asc'}
                            onClick={() => handleChangeOrder('active')}
                          >
                            Ativo
                          </TableSortLabel>
                        </TableCell>
                        {settingData && settingData.settings.enable_min_food_stock ? (
                          <TableCell align="center" />
                        ) : null}

                        <Can checkRole={['administrator', 'owner', 'manager']}>
                          <TableCell align="right" />
                        </Can>
                      </TableRow>
                    </StyledTableHead>
                    <TableBody>
                      {data && data.foods
                        .map(food => (
                          <CardTableFood
                            key={food.secure_id}
                            food={food}
                          />
                        ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
                <TablePagination
                  rowsPerPageOptions={[25, 50, 100]}
                  labelRowsPerPage="Linhas por pág."
                  component="div"
                  count={data ? data.total : 0}
                  rowsPerPage={data ? data.perPage : 0}
                  page={data ? data.page - 1 : 0}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </StyledPaper>
            </Container>
          )}
        </>
      )}
    </center>
  );
}

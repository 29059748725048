import {
  Grid,
  Table, TableBody, TableCell, TablePagination, TableRow, TableSortLabel
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { useState } from 'react';
import ButtonNew from '~/components/ButtonNew';
import Can from '~/components/Can';
import Loader from '~/components/Loader';
import { useV2SanitaryCost } from '~/hooks/apiV2/admin/sanitatyCost/useV2SanitaryCost';
import history from '~/services/history';
import Filter from './Components/Filter';
import {
  Container, ContentButton, StyledGrid,
  StyledPaper, StyledTableCell, StyledTableContainer,
  StyledTableHead
} from './styles';

import { CardSanitatyCost } from './Components/CardSanitatyCost'

export default function SanitaryCost() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('initial_date');
  const [direction, setDirection] = useState('desc');
  const [currentDate, setCurrentDate] = useState(null);
  const [_, setDateValue] = useState(null);
  const [activeCost, setActiveCost] = useState(false);

  const { data, isLoading } = useV2SanitaryCost(page < 1 ? 1 : page, rowsPerPage, activeCost, currentDate ? format(currentDate, 'yyyy-MM-dd') : null, direction)

  function handleChangePage(event, newPage) {
    setPage(newPage === 0 ? 1 : newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleAdd() {
    history.push('/dashboard/sanitaryCost/add');
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={1}>
          <Grid item sm md lg xl>
            <Filter
              setCurrentDate={setCurrentDate}
              currentDate={currentDate}
              setActiveCost={setActiveCost}
              activeCost={activeCost}
              setDateValue={setDateValue}
            />
          </Grid>
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['sanitaryCost_new']}
          >
            <Grid item sm="auto">
              <ContentButton>
                <ButtonNew title="Novo" onClick={handleAdd} />
              </ContentButton>
            </Grid>
          </Can>
        </StyledGrid>

        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell align="left">
                      <TableSortLabel
                        active={field === 'initial_date'}
                        direction={field === 'initial_date' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('initial_date')}
                      >
                        Data Inicial
                      </TableSortLabel>
                    </TableCell>
                    <StyledTableCell align="center">
                      <TableSortLabel
                        active={field === 'final_date'}
                        direction={field === 'final_date' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('final_date')}
                      >
                        Data Final
                      </TableSortLabel>
                    </StyledTableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={field === 'cost'}
                        direction={field === 'cost' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('cost')}
                      >
                        Custo/Cab
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'obs'}
                        direction={field === 'obs' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('obs')}
                      >
                        Observação
                      </TableSortLabel>
                    </TableCell>
                    <Can checkRole={['administrator', 'owner', 'manager']}>
                      <TableCell colSpan={2} />
                    </Can>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {!!data && data.sanitatyCost.map(sanitaryCost => (
                    <CardSanitatyCost
                      key={sanitaryCost.secure_id}
                      sanitaryCost={sanitaryCost}
                    />
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={!!data ? data.total : 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

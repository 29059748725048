import React from 'react';
import Swal from 'sweetalert2';
import {
  TableRow,
  TableCell,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

// Query
import { useApiV2RemoveIndividualAnimalDeathOutput } from '~/hooks/apiV2/animalHandling/IndividualAnimalDeathOutput/useApiV2RemoveIndividualAnimalDeathOutput'

import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell
} from './styles';
import ButtonTable from '~/components/ButtonTable';
import ActionLoader from '~/components/ActionLoader';

export function CardTableAnimalDeathOutput({ data, settings }) {
  const removeIndividualAnimalDeathOutput = useApiV2RemoveIndividualAnimalDeathOutput();

  function handleRemove(secure_id) {
    async function performRemoveIndividualAnimalDeathOutput() {
      await removeIndividualAnimalDeathOutput.mutateAsync(secure_id)
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar!',
    }).then(async result => {
      if (result.value) {
        performRemoveIndividualAnimalDeathOutput();
      }
    });
  }

  return (
    <TableRow>
      <TableCell align="center">
        {data.movementHistory[0].animal[settings.default_identification]}
      </TableCell>
      <TableCell align="center">
        {data.originLot?.code}
      </TableCell>
      <TableCell align="center">
        {data.movementHistory[0].animal.output_obs}
      </TableCell>
      <TableCell align="center">
        {data.movementHistory[0].animal.causeOfDeath.name}
      </TableCell>
      <TableCell align="center">
        {data.date}
      </TableCell>
      <Can
        checkRole={['administrator', 'owner', 'beefer']}
        checkPermission={['animalDeathOutput_delete']}
      >
        <StyledTableCell align="right">
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['animalDeathOutput_delete']}
          >
            <ButtonTable
              icon={
                removeIndividualAnimalDeathOutput.isLoading ? (
                  <ActionLoader text="Deletando" />
                ) : (
                  <DeleteIcon
                    color="error"
                  />
                )
              }
              background={colors.error}
              onClick={() =>
                handleRemove(data.secure_id)
              }
            />
          </Can>
        </StyledTableCell>
      </Can>
    </TableRow>
  )
}

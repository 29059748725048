import { useQuery } from "react-query";
import apiV2 from "~/services/apiV2";

export async function getScrapOutput(secure_id) {
  const { data } = await apiV2.get(`/v1/feedlot/animal-handling/scrapOutput/${secure_id}`);

  return data
}

export function useApiV2SelectScrapOutput(secure_id) {
  return useQuery(['animalScrapOutput', secure_id], () => getScrapOutput(secure_id));
}

import React, { useEffect } from 'react';
import history from "~/services/history";

export default function Clear() {
  useEffect(() => {
    localStorage.clear()
    history.push('/')
  }, [])

  return (
    <h1></h1>
  )
}
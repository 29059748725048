import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";
import { formatDateString } from '~/utils/format';

export async function getDiets(active = [true, false], sort, page = 1, limit = 25) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/register/diet?sort=${sort}`, {
      params: {
        active,
        // sort,
        page,
        limit
      }
    })

    const newData = data.data.map(diet => ({
      ...diet,
      updated_at: formatDateString(diet.updated_at),
    }));

    return {
      diets: newData,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    }

  } catch {
    toast.error('Fala ao tentar carregar as dietas!');
  }
}

export function useApiV2Diets(active, sort, page, limit) {
  return useQuery(
    ['diets', active, sort, page, limit],
    () => getDiets(active, sort, page, limit),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

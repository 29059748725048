import { useQuery } from "react-query"
import { toast } from "react-toastify";
import apiV2 from "~/services/apiV2"

export async function getPurchaseFoods(sort, page = 1, limit = 50, foodName = '') {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/admin/purchaseFood?sort=${sort}`, {
      params: {
        page,
        limit,
        foodName
      }
    })

    return {
      shoppings: data.data,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    }
  } catch {
    toast.error('Não foi possível carregar as compras !');
  }
}

export function useApiV2PurchaseFood(sort, page, limit, foodName) {
  return useQuery(
    ['purchaseFoods', sort, page, limit, foodName],
    () => getPurchaseFoods(sort, page, limit, foodName),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

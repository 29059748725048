import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ClearIcon from '@material-ui/icons/Clear';

// Query
import { useApiV2GlobalFood } from '~/hooks/apiV2/global/food/useApiV2GlobalFood';

import {
  StyledPaper,
  StyledGrid,
  StyledFormControl,
  ContentDate,
  StyledIconButton,
  StyledTextField,
} from './styles';

import InputDatePicker from '~/components/InputDatePicker';

export default function Filter({
  totalPerformed,
  totalForecast,
  feedDefaultOption,
  currentFeed,
  currentDate,
  setCurrentFeed,
  setCurrentDate
}) {
  const [deviation, setDeviation] = useState(0);

  const [feeds, setFeeds] = useState([]);

  // Query
  const { data: feedsData } = useApiV2GlobalFood([true], false, 'feed,mix');

  function handleChangeFeed(event) {
    setCurrentFeed(event.target.value);
  }

  function handleChangeDate(date) {
    setCurrentDate(date);
  }

  useEffect(() => {
    if (feedsData) {
      setFeeds(feedsData);
    }
  }, [feedsData]);

  useEffect(() => {
    let totalDeviation = 0;

    if (totalForecast > 0) {
      totalDeviation = Number(
        parseFloat((totalPerformed / totalForecast - 1) * 100).toFixed(2)
      );
    }

    setDeviation(totalDeviation);
  }, [totalForecast, totalPerformed]);

  return (
    <StyledPaper>
      <StyledGrid
        container
        justify="space-around"
        alignItems="center"
        spacing={2}
      >
        <Grid item sm xs={12}>
          <StyledFormControl>
            <InputLabel id="input-diet">Mistura/Ração</InputLabel>
            <Select
              id="currentFeed"
              defaultValue={feedDefaultOption.secure_id}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}
              value={currentFeed || feedDefaultOption.secure_id}
              onChange={handleChangeFeed}
            >
              <MenuItem value={feedDefaultOption.secure_id}>
                {feedDefaultOption.name}
              </MenuItem>
              {feeds.map(item => (
                <MenuItem key={item.secure_id} value={item.secure_id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item sm xs={12}>
          <ContentDate>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <InputDatePicker
                maxDate={new Date()}
                selectedDate={currentDate}
                handleChangeDate={(date, value) =>
                  handleChangeDate(date, value)
                }
                InputProps={{
                  startAdornment: (
                    <StyledIconButton
                      onClick={() => handleChangeDate(null, null)}
                    >
                      <ClearIcon />
                    </StyledIconButton>
                  ),
                }}
                InputAdornmentProps={{
                  position: 'end',
                }}
              />
            </MuiPickersUtilsProvider>
          </ContentDate>
        </Grid>
        <Grid container justify="space-around" alignItems="center" spacing={2}>
          <Grid item sm xs={12}>
            <StyledTextField
              id="total_forecast"
              label="Total Previsto (Kg)"
              variant="outlined"
              size="small"
              fullWidth
              disabled
              type="number"
              value={totalForecast}
            />
          </Grid>
          <Grid item sm xs={12}>
            <StyledTextField
              id="total_performed"
              label="Total Realizado (Kg)"
              variant="outlined"
              size="small"
              fullWidth
              disabled
              type="number"
              value={totalPerformed}
            />
          </Grid>
          <Grid item sm xs={12}>
            <StyledTextField
              id="total_deviation"
              label="Desvio (%)"
              variant="outlined"
              size="small"
              fullWidth
              disabled
              type="number"
              value={deviation}
            />
          </Grid>
        </Grid>
      </StyledGrid>
    </StyledPaper>
  );
}

Filter.propTypes = {
  feedDefaultOption: PropTypes.shape({
    secure_id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  totalPerformed: PropTypes.number.isRequired,
  totalForecast: PropTypes.number.isRequired,
  currentFeed: PropTypes.string.isRequired,
  currentDate: PropTypes.shape({}).isRequired,
  setCurrentFeed: PropTypes.func.isRequired,
  setCurrentDate: PropTypes.func.isRequired
};

import { useQuery } from "react-query";
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2"

export async function getFoods(active = [true], withAdditionalData = false, types = '') {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/global/list/food`, {
      params: {
        active,
        withAdditionalData,
        types
      }
    })

    return data
  } catch {
    toast.error('Falha para carregar os dados dos alimentos cadastrados!');
  }
}

export function useApiV2GlobalFood(active, withAdditionalData, types) {
  return useQuery(
    ['foods', active, withAdditionalData, types],
    () => getFoods(active, withAdditionalData, types),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

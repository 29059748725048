import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import {
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

import { toast } from 'react-toastify';

import BackspaceIcon from '@material-ui/icons/Backspace';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import AutorenewIcon from '@material-ui/icons/Autorenew';

// Query
import { useApiV2Treatment } from '~/hooks/apiV2/foodManagement/treatment/useApiV2Treatment';
import { useApiV2AlterTreatment } from '~/hooks/apiV2/foodManagement/treatment/useApiV2AlterTreatment';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

import {
  Container,
  StyledTableContainer,
  StyledTablePaper,
  StyledTableHead,
  StyledTableCell,
  LoaderTotalPerformed,
  ContentSubmitButtons,
  StyledSubmitButton,
  StyledGridItem,
  StyledFormControl,
  StyledGrid,
} from './styles';

import TextFieldEdit from './Components/TextFieldEdit';
import Filter from './Components/Filter';

import colors from '~/styles/colors';
import Can from '~/components/Can';
import Loader from '~/components/Loader';
import ButtonTable from '~/components/ButtonTable';

import { formatNumber, formatNamePaddock } from '~/utils/format';

const performedDefault = {
  id: 2,
  value: false,
  content: 'Não',
};

export default function Performed() {
  const [field, setField] = useState('paddock');
  const [direction, setDirection] = useState('asc');
  const [currentDiet, setCurrentDiet] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentPerformed, setCurrentPerformed] = useState(performedDefault);
  const [treatyTreatments, setTreatyTreatments] = useState([]);
  const [change, setChange] = useState(false);
  const [treatmentClear, setTreatmentClear] = useState(null);
  const [currentEmployee, setCurrentEmployee] = useState('');
  const [settings, setSettings] = useState({});

  const [enableChange, setEnableChange] = useState(false);
  const [employees, setEmployees] = useState();
  const [treatments, setTreatments] = useState();
  const [treaties, setTreaties] = useState();
  const [currentListTotalPerformed, setCurrentListTotalPerformed] = useState();

  // Query
  const { data, isLoading } = useApiV2Treatment(
    `${field}%7C${direction}`,
    currentDiet && currentDiet.value,
    currentDate,
    currentPerformed.value
  );
  const { data: settingsData } = useApiV2GlobalSetting();
  const alterTreatment = useApiV2AlterTreatment(setTreatyTreatments);

  function calcTreaty(treatment, treaty) {
    const hasTreatmentPerformed = treatment.treatyTreatments.filter(
      tt => tt.performed !== null
    );

    const isCurrentTreatmentPerformed = hasTreatmentPerformed.find(
      item => item.treaty.secure_id === treaty.secure_id
    );

    if (isCurrentTreatmentPerformed) {
      return isCurrentTreatmentPerformed.forecast;
    }

    const totalPerformed = hasTreatmentPerformed.reduce(
      (total, item) => total + item.performed,
      0
    );

    if (treaties.length - hasTreatmentPerformed.length > 0) {
      const totalProportionByTreaty = hasTreatmentPerformed.reduce(
        (total, item) => total + item.treaty.proportion,
        0
      );

      const proportionPerformedByTreaty =
        totalProportionByTreaty /
        (treaties.length - hasTreatmentPerformed.length);

      const currentTotal = treatment.total_forecast - totalPerformed;

      const currentProportion = treaty.proportion + proportionPerformedByTreaty;

      const currentTreaty = Math.round(
        currentTotal * (currentProportion / 100)
      );

      if (currentTreaty < 0) {
        return 0;
      }

      return formatNumber(currentTreaty, 0, 4);
    }

    return 0;
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function hasFiledEditable(secure_id) {
    return currentListTotalPerformed.find(tt => tt.treatment_id === secure_id);
  }

  function setDefaultValue(treatment, treaty) {
    let defaultValue = '';

    if (treatment && treaty && treatment.treatyTreatments.length > 0) {
      defaultValue = treatment.treatyTreatments.find(t => {
        return (
          t.treatment.secure_id === treatment.secure_id &&
          t.treaty.secure_id === treaty.secure_id
        );
      });

      if (defaultValue) {
        return defaultValue.performed;
      }
    }

    return defaultValue;
  }

  function checkPerformed(oldListLength, newListLength, treatyLength) {
    const amountCurrentList = oldListLength + newListLength;

    return amountCurrentList === treatyLength;
  }

  async function handleSubmit() {
    if (!currentEmployee) {
      toast.error('Informe o funcionário responsável!');
      return;
    }

    if (treatyTreatments.length === 0) {
      setChange(false);
      return;
    }

    async function performAlterTreatment(data) {
      await alterTreatment.mutateAsync(data);
    }

    const listTreatments = treatments.map(treatment => {
      const searchTotalPerformed = currentListTotalPerformed.find(
        tp => tp.treatment_id === treatment.secure_id
      );

      const searchTreatment = treatyTreatments.filter(
        tt => tt.treatment_id === treatment.secure_id
      );

      const countOldListTreatyTreatment = treatment.treatyTreatments.filter(
        tt1 =>
          !treatyTreatments.some(
            tt2 =>
              tt1.treatment.secure_id === tt2.treatment_id &&
              tt1.treaty.secure_id === tt2.treaty_id
          ) && tt1.performed !== null
      );
      const treatiesIds = countOldListTreatyTreatment.map(treatyId => {
        return treatyId.treaty.secure_id
      })

      const countPerformedTreatment = treatyTreatments.filter(
        tt => tt.treatment_id === treatment.secure_id && tt.performed !== null && !treatiesIds.includes(tt.treaty_id)
      );

      const performed = checkPerformed(
        countOldListTreatyTreatment.length,
        countPerformedTreatment.length,
        treaties.length
      );

      if (searchTreatment.length > 0) {
        return {
          secureId: treatment.secure_id,
          dietSecureId: treatment.diet.secure_id,
          performed,
          totalPerformed: searchTotalPerformed
            ? searchTotalPerformed.total_performed
            : treatment.total_performed,
          treatyTreatments: searchTreatment.map(treatyTreatment => ({
            forecast: treatyTreatment.forecast,
            performed: treatyTreatment.performed,
            treatySecureId: treatyTreatment.treaty_id
          })),
        }
      }

      return null;
    }).filter(treatmentNotNul => treatmentNotNul !== null);

    const hasPerformed = listTreatments.find(
      treatment => treatment.performed === true
    );

    if (hasPerformed) {
      const result = await Swal.fire({
        title: 'Você tem certeza?',
        text:
          'Está ação não tem volta! Há tratamentos que serão finalizados, deseja continuar?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colors.lightPrimary,
        cancelButtonColor: colors.light,
        confirmButtonText: 'Ok, finalizar tratamentos!',
      })
      if (result.value) {
        performAlterTreatment({
          treatments: listTreatments,
          employeeSecureId: currentEmployee,
          date: currentDate
        });
        setChange(false);
      }
    } else {
      performAlterTreatment({
        treatments: listTreatments,
        employeeSecureId: currentEmployee,
        date: currentDate
      });
      setChange(false);
    }
  }

  function handleClearTreatment({ secure_id }) {
    setTreatmentClear(secure_id);

    const newTreatyTreatments = treaties.map(treaty => ({
      treatment_id: secure_id,
      treaty_id: treaty.secure_id,
      performed: null,
      forecast: 0,
    }));

    const oldTreatyTreatments = treatyTreatments.filter(
      tt => tt.treatment_id !== secure_id
    );

    setTreatyTreatments([...oldTreatyTreatments, ...newTreatyTreatments]);
  }

  function handleCancel() {
    setChange(false);
    setTreatyTreatments([]);
  }

  function renderTotalPerformed(item) {
    let currentTotalForecast = item.total_performed;

    const searchTotalForecast = currentListTotalPerformed.find(
      treatment => treatment.treatment_id === item.secure_id
    );

    if (searchTotalForecast) {
      currentTotalForecast = searchTotalForecast.total_performed;
    }

    return formatNumber(currentTotalForecast, 0, 4);
  }

  useEffect(() => {
    if (data) {
      setTreaties(data.treaties);
      setEmployees(data.employees);
      setEnableChange(data.has_manufactoring_diets);
      setTreatments(data.treatments);
      if (data.treatments.length > 0) {
        if (data.treatments[0].employee) setCurrentEmployee(data.treatments[0].employee.secure_id);
      }
    }

    if (settingsData) {
      setSettings(settingsData.settings);
    }

    setChange(false);

    setCurrentListTotalPerformed([]);
  }, [data, settingsData]);

  function handleChangeEmployee(event) {
    setCurrentEmployee(event.target.value);
  }

  return (
    <center>
      <Container>
        <Filter
          currentDiet={currentDiet}
          setCurrentDiet={setCurrentDiet}
          currentDate={currentDate}
          setCurrentDate={setCurrentDate}
          currentPerformed={currentPerformed}
          setCurrentPerformed={setCurrentPerformed}
        />

        {isLoading ? (
          <Loader />
        ) : (
          <StyledTablePaper>
            <Can checkRole={['administrator', 'owner', 'manager']}>
              <ContentSubmitButtons>
                {!change ? (
                  <StyledSubmitButton
                    variant="contained"
                    color="primary"
                    disabled={(treatments && treatments.length === 0) || enableChange}
                    startIcon={<EditIcon />}
                    onClick={() => setChange(true)}
                  >
                    Alterar
                  </StyledSubmitButton>
                ) : null}
              </ContentSubmitButtons>
            </Can>

            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'paddock'}
                        direction={field === 'paddock' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('paddock')}
                      >
                        Piquete
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      <TableSortLabel
                        active={field === 'diet.name'}
                        direction={field === 'diet.name' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('diet.name')}
                      >
                        Dieta
                      </TableSortLabel>
                    </TableCell>
                    {treaties &&
                      treaties.map(treaty => (
                        <TableCell key={treaty.secure_id} align="center" colSpan={2}>
                          {treaty.name}
                        </TableCell>
                      ))}
                    <Can checkRole={['administrator', 'owner', 'manager']}>
                      <TableCell align="right" />
                    </Can>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {treatments &&
                    treatments.length > 0 &&
                    treatments.map(treatment => (
                      <>
                        <TableRow key={treatment.secure_id}>
                          <TableCell component="th" scope="row">
                            {formatNamePaddock(settings, treatment.paddock)}
                          </TableCell>
                          <TableCell align="left">
                            {treatment.diet.name}
                          </TableCell>
                          {treaties &&
                            treaties.map(treaty => (
                              <>
                                <TableCell
                                  align="right"
                                  key={String(treaty.secure_id + treatment.secure_id)}
                                >
                                  {calcTreaty(treatment, treaty)}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  key={String(treaty.secure_id + treatment.secure_id)}
                                >
                                  <TextFieldEdit
                                    change={change}
                                    defaultValue={setDefaultValue(
                                      treatment,
                                      treaty
                                    )}
                                    treatment={treatment}
                                    treaty={treaty}
                                    treatyTreatments={treatyTreatments}
                                    setTreatyTreatments={setTreatyTreatments}
                                    treatmentClear={treatmentClear}
                                    setTreatmentClear={setTreatmentClear}
                                    treaties={treaties}
                                    currentListTotalPerformed={
                                      currentListTotalPerformed
                                    }
                                    setCurrentListTotalPerformed={
                                      setCurrentListTotalPerformed
                                    }
                                  />
                                </TableCell>
                              </>
                            ))}
                          <StyledTableCell align="right" colSpan={1}>
                            <ButtonTable
                              icon={
                                <BackspaceIcon
                                  color={change ? 'primary' : 'disabled'}
                                />
                              }
                              background={
                                change ? colors.primary : colors.transparent
                              }
                              lighten={0.6}
                              disabled={!change}
                              onClick={() => handleClearTreatment(treatment)}
                            />
                          </StyledTableCell>
                        </TableRow>
                        <TableRow key={`total${treatment.secure_id}`}>
                          <TableCell
                            colSpan={treaties && treaties.length * 2 - 3}
                          />
                          <StyledTableCell align="center" colSpan={2}>
                            Total Previsto
                          </StyledTableCell>
                          <TableCell align="center">
                            {formatNumber(treatment.total_forecast, 0, 4)}
                          </TableCell>
                          <StyledTableCell align="center" colSpan={2}>
                            Total Realizado
                          </StyledTableCell>
                          <TableCell align="center">
                            {alterTreatment.isLoading && hasFiledEditable(treatment.secure_id) ? (
                              <LoaderTotalPerformed>
                                <AutorenewIcon
                                  fontSize="small"
                                  style={{ color: colors.success }}
                                />
                              </LoaderTotalPerformed>
                            ) : (
                              <>{renderTotalPerformed(treatment)}</>
                            )}
                          </TableCell>
                        </TableRow>
                      </>
                    ))}
                </TableBody>
              </Table>
            </StyledTableContainer>

            <Can checkRole={['administrator', 'owner', 'manager']}>
              {!change ? null : (
                <>
                  <StyledGrid container justify="flex-end" spacing={2}>
                    <StyledGridItem item xs={12} sm={3}>
                      <StyledFormControl size="small" fullWidth>
                        <InputLabel id="input-line">Funcionário</InputLabel>
                        <Select
                          id="currentLine"
                          onChange={handleChangeEmployee}
                          value={currentEmployee}
                          MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                          }}
                        >
                          {employees && employees.length
                            ? employees.map(line => {
                              const functions = line.function.split(',');
                              return functions.indexOf('Gerente') > -1 ||
                                functions.indexOf('Veterinário') > -1 ||
                                functions.indexOf('Tratador') > -1 ||
                                functions.indexOf('Fábrica de Ração') > -1 ||
                                functions.indexOf('Folguista') > -1 ||
                                functions.indexOf('Diarista') > -1 ||
                                functions.indexOf('Escritório') > -1 ||
                                functions.indexOf('Boiadeiro') > -1 ? (
                                <MenuItem key={line.secure_id} value={line.secure_id}>
                                  {line.name}
                                </MenuItem>
                              ) : (
                                ''
                              );
                            })
                            : ''}
                        </Select>
                      </StyledFormControl>
                    </StyledGridItem>
                  </StyledGrid>
                  <ContentSubmitButtons>
                    <StyledSubmitButton
                      variant="outlined"
                      color="primary"
                      onClick={handleCancel}
                    >
                      Cancelar
                    </StyledSubmitButton>
                    <StyledSubmitButton
                      variant="contained"
                      color={colors.white}
                      background={colors.success}
                      startIcon={<SaveIcon />}
                      onClick={handleSubmit}
                      disabled={alterTreatment.isLoading}
                    >
                      {alterTreatment.isLoading ? 'Salvando...' : 'Salvar'}
                    </StyledSubmitButton>
                  </ContentSubmitButtons>
                </>
              )}
            </Can>
          </StyledTablePaper>
        )}
      </Container>
    </center>
  );
}

import React from 'react';
import Swal from 'sweetalert2';

import {
  TableCell,
  TableRow,
  Switch,
  Tooltip,
  Fade
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';
import VisibilityIcon from '@material-ui/icons/Visibility';

// Query hooks
import { useApiV2EditDiet } from '~/hooks/apiV2/register/diet/useApiV2EditDiet';
import { useApiV2RemoveDiet } from '~/hooks/apiV2/register/diet/useApiV2RemoveDiet';

import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';
import { formatNumber } from '~/utils/format';
import ActionLoader from '~/components/ActionLoader';

import {
  StyledTableCell,
  StyledTableCellEllipsis
} from './styles';

export function CardTableDiet({ diet }) {
  // Query hooks
  const changeStatusDiet = useApiV2EditDiet()
  const removeDiet = useApiV2RemoveDiet()

  function messageExistTreatment({ has_treatments }) {
    if (has_treatments > 0) {
      return 'Dieta já utilizada em tratamentos';
    }

    return null;
  }

  function hasTreatmentActive({ has_treatments_performed }) {
    return has_treatments_performed > 0;
  }

  async function handleChangeActive({ secure_id, active }) {
    await changeStatusDiet.mutateAsync({ secure_id, active: !active })
  }

  function handleViewDiet({ secure_id }) {
    history.push(`/dashboard/diet/view/${secure_id}`);
  }

  function handleEditDiet({ secure_id }) {
    history.push(`/dashboard/diet/edit/${secure_id}`);
  }

  function handleRemoveDiet({ secure_id }) {
    async function performRemoveDiet() {
      await removeDiet.mutateAsync(secure_id);
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar essa dieta?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar dieta!',
    }).then(result => {
      if (result.value) {
        performRemoveDiet();
      }
    });
  }

  return (
    <TableRow key={diet.id}>
      <TableCell component="th" scope="row">
        {diet.name}
      </TableCell>
      <StyledTableCellEllipsis align="right">{`${formatNumber(
        diet.percent_ms_diet,
        2,
        2
      )}%`}</StyledTableCellEllipsis>
      <TableCell align="center">
        {diet.updated_at}
      </TableCell>
      <Can
        checkRole={['administrator', 'owner', 'beefer']}
        checkPermission={['diet_edit']}
      >
        {can =>
          can ? (
            <TableCell align="center">
              <Switch
                color="primary"
                checked={diet.active}
                disabled={changeStatusDiet.isLoading || hasTreatmentActive(diet)}
                onChange={() => handleChangeActive(diet)}
              />
            </TableCell>
          ) : (
            <TableCell align="center">
              <Switch
                color="primary"
                checked={diet.active}
                disabled
              />
            </TableCell>
          )}
      </Can>

      <StyledTableCell align="right">
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['diet_edit']}
        >
          {can => can ? (
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={messageExistTreatment(diet)}
              disableHoverListener={
                diet.has_treatments === 0
              }
            >
              <span>
                <ButtonTable
                  icon={<EditIcon />}
                  background={colors.primary}
                  lighten={0.6}
                  onClick={() => handleEditDiet(diet)}
                />
              </span>
            </Tooltip>
          ) : (
            <ButtonTable
              icon={<VisibilityIcon />}
              background={colors.dark}
              lighten={0.5}
              onClick={() => handleViewDiet(diet)}
            />
          )}
        </Can>

        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['diet_delete']}
        >
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={messageExistTreatment(diet)}
            disableHoverListener={
              diet.has_treatments === 0
            }
          >
            <span>
              <ButtonTable
                icon={
                  removeDiet.isLoading ? (
                    <ActionLoader text="Deletando" />
                  ) :
                    (
                      <DeleteIcon color={
                        diet.has_treatments > 0 ? "disabled" : "error"
                      } />
                    )

                }
                background={
                  diet.has_treatments > 0
                    ? colors.grey
                    : colors.error
                }
                disabled={diet.has_treatments > 0}
                onClick={() => handleRemoveDiet(diet)}
              />
            </span>
          </Tooltip>
        </Can>
      </StyledTableCell>
    </TableRow>
  )
}

import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Hidden,
  Button,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Query
import { useApiV2GlobalModule } from '~/hooks/apiV2/global/module/useApiV2GlobalModule';

import { Container, StyledPaper, StyledButton } from './styles';

import DatePicker from '~/components/DatePicker';

export default function Filter({
  setFilters
}) {
  const [lines, setLines] = useState([]);
  const [paddocks, setPaddocks] = useState([]);

  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [paddockId, setPaddockId] = useState(null);

  // Query
  const { data: modulesData } = useApiV2GlobalModule();

  function cleanState() {
    setLines([]);
    setPaddocks([]);
    setPaddockId(null);
  }

  function handleChangeModule(event) {
    const currentModule = modulesData.modules.find(selectedModule => selectedModule.secure_id === event.target.value);
    setLines(currentModule.lines);
  }

  function handleChangeLine(event) {
    const currentLine = lines.find(line => line.secure_id === event.target.value);
    setPaddocks(currentLine.paddocks);
  }

  function handleChangePaddock(event) {
    setPaddockId(event.target.value)
  }

  async function handleGenarateReport() {
    setFilters({
      initialDate,
      finalDate,
      paddock: paddockId
    });
  }

  useEffect(() => {
    if (modulesData) {
      cleanState();
    }
  }, [modulesData]);

  return (
    <Container>
      <StyledPaper>
        <Grid container spacing={2} alignItems="center" justify="space-around">
          <Grid item xs={12} sm>
            <FormControl size="small" fullWidth>
              <InputLabel id="input-module">Modulo</InputLabel>
              <Select
                id="selectModule"
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                defaultValue={null}
                onChange={handleChangeModule}
              >
                {modulesData && modulesData.modules.map(currentModule => (
                  <MenuItem key={currentModule.secure_id} value={currentModule.secure_id}>
                    {currentModule.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm>
            <FormControl size="small" fullWidth>
              <InputLabel id="input-line">Linha</InputLabel>
              <Select
                id="selectLine"
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                disabled={lines.length === 0}
                onChange={handleChangeLine}
              >
                {lines.map(line => (
                  <MenuItem key={line.secure_id} value={line.secure_id}>
                    {line.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm>
            <FormControl size="small" fullWidth>
              <InputLabel id="input-paddock">Piquete</InputLabel>
              <Select
                id="selectPaddock"
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                disabled={paddocks.length === 0}
                onChange={handleChangePaddock}
              >
                {paddocks.map(paddock => (
                  <MenuItem key={paddock.secure_id} value={paddock.secure_id}>
                    {paddock.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={4} alignItems="center" justify="space-around">
          <Grid item xs={6} sm>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Data Inicial"
                selectedDate={initialDate}
                maxDate={finalDate}
                fullWidth
                handleChangeDate={date => setInitialDate(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} sm>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Data Final"
                selectedDate={finalDate}
                minDate={initialDate}
                fullWidth
                handleChangeDate={date => setFinalDate(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm>
            <Hidden only="xs">
              <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                disabled={!paddockId}
                onClick={handleGenarateReport}
              >
                Gerar
              </StyledButton>
            </Hidden>

            <Hidden smUp>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={!paddockId}
                onClick={handleGenarateReport}
              >
                Gerar
              </Button>
            </Hidden>
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

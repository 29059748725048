import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2"

export async function getLots(sort, page = 1, limit = 25) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/register/lot?sort=${sort}`, {
      params: {
        page,
        limit
      }
    })

    return {
      lots: data.data,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    }
  } catch {
    toast.error('Falha para carregar os dados dos lotes!');
  }
}

export function useApiV2Lot(sort, page, limit) {
  return useQuery(
    ['lots', sort, page, limit],
    () => getLots(sort, page, limit),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

import {
  Content,
  StyledTablePaper,
  ContentDate,
  StyledGridItem,
  StyledIconButton,
} from './styles';

// Query
import { useApiV2GlobalLots } from '~/hooks/apiV2/global/lot/useApiV2GlobalLots'

import { regexDate } from '~/utils/common';
import InputDatePicker from '~/components/InputDatePicker';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.value,
});

const apartDefaultOption = {
  id: null,
  name: 'Todas',
};

export default function Filter({
  currentDate,
  setCurrentDate,
  setCurrentApart,
  setCurrentLot,
  settings
}) {
  const [listLots, setListLots] = useState(null);
  const [aparts, setAparts] = useState([]);

  const { data: lotsData } = useApiV2GlobalLots([true, false]);

  function handleChangeDate(date, value) {
    if (value && !regexDate.test(value)) {
      setCurrentDate(null);
      return;
    }
    setCurrentDate(date);
  }

  function handleSelectLot(value) {
    if (value) {
      setCurrentLot(value.secure_id);
    } else {
      setCurrentLot(null);
    }
  }

  function handleChangeApart(event) {
    if (typeof event.target.value === 'object') {
      setCurrentApart(null);
    } else {
      setCurrentApart(event.target.value);
    }
  }

  useEffect(() => {
    if (lotsData) {
      const newList = lotsData.lots.map(lot => ({
        secure_id: lot.secure_id,
        value: String(lot.code),
      }));

      setListLots(newList);
    }

  }, [lotsData]);

  useEffect(() => {
    if (settings && settings.apart && settings.apart > 0) {
      // eslint-disable-next-line prefer-const
      let val = [];
      for (let i = 1; i <= settings.apart; i++) {
        val.push(i);
      }
      setAparts(val);
    }
  }, [settings]);

  return (
    <Content>
      <StyledTablePaper>
        <Grid container justify="space-around" spacing={2}>
          <Grid item xs={12} sm style={{ marginTop: 11 }}>
            <ContentDate>
              <InputDatePicker
                label="Data Inicial"
                maxDate={new Date()}
                selectedDate={currentDate}
                handleChangeDate={(date, value) =>
                  handleChangeDate(date, value)
                }
                InputProps={{
                  startAdornment: (
                    <StyledIconButton onClick={() => handleChangeDate(null)}>
                      <ClearIcon />
                    </StyledIconButton>
                  ),
                }}
                InputAdornmentProps={{
                  position: 'end',
                }}
              />
            </ContentDate>
          </Grid>
          <StyledGridItem item xs={12} sm>
            <Autocomplete
              name="lot"
              size="small"
              style={{ marginTop: 11, width: '100%' }}
              options={listLots}
              noOptionsText="Sem opções"
              filterOptions={filterOptions}
              getOptionLabel={option => option.value}
              onChange={(e, value) => {
                handleSelectLot(value);
              }}
              renderInput={params => (
                <TextField {...params} label="Lote" fullWidth />
              )}
            />
          </StyledGridItem>
          <StyledGridItem item xs={12} sm style={{ marginTop: 11 }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="input-diet">Aparte</InputLabel>
              <Select
                id="diet"
                defaultValue={apartDefaultOption}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                onChange={handleChangeApart}
              >
                <MenuItem value={apartDefaultOption}>
                  {apartDefaultOption.name}
                </MenuItem>
                {aparts.map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledGridItem>
        </Grid>
      </StyledTablePaper>
    </Content>
  );
}

import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import {
    Grid,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    FormControlLabel,
    Radio,
    InputAdornment
} from '@material-ui/core';

// Query
import { useApiV2ShowDataEditAnimalsInLot } from '~/hooks/apiV2/register/animal/useApiV2ShowDataEditAnimalsInLot';
import { useApiV2EditAnimalsInLot } from '~/hooks/apiV2/register/animal/useApiV2EditAnimalsInLot';

import {
    Container,
    StyledPaper,
    ContentItem,
    Error,
    ContentSubmitButtons,
    StyledSubmitButton,
    StyledRadioGroup
} from './styles';

import history from '~/services/history';
import ButtonSubmit from '~/components/ButtonSubmit';
import Loader from '~/components/Loader';
import { ages, types, gender, bodySizes } from '~/utils/options';
import NumberFormatForm from '~/components/NumberFormatForm';

export default function AnimalsEdit() {
    const { state: location } = useLocation();

    const lot_id = useMemo(
        () => (location && location.lot_id ? location.lot_id : null),
        [location]
    );

    const schema = Yup.object().shape({
        sex: Yup.string(),
        age: Yup.string(),
        type: Yup.string(),
        body_size: Yup.string(),
        weight_forecast: Yup.number(),
        breed_id: Yup.string(),
        lot_id: Yup.string()
            .typeError('Selecione um lote')
            .required('Selecione um lote'),
    });

    // Query
    const { data, isLoading, error } = useApiV2ShowDataEditAnimalsInLot();

    const editAnimals = useApiV2EditAnimalsInLot();

    async function handleSubmit(data) {
        await editAnimals.mutateAsync({
            sex: data.sex,
            age: data.age,
            type: data.type,
            bodySize: data.body_size,
            weightForecast: data.weight_forecast,
            breedSecureId: data.breed_id,
            secure_id: data.lot_id
        })
    }

    function handleBack() {
        history.push('/dashboard/animals', { lot_id });
    }

    if (error) {
        history.push('/dashboard/animals', { lot_id })
        toast.error('Falha ao carregar os dados!');
        return null
    }

    if (isLoading) {
        return (
            <Loader />
        )
    }

    return (
        <center>
            <Container>
                <Formik
                    validationSchema={schema}
                    initialValues={{
                        lot_id,
                        sex: '',
                        age: '',
                        type: '',
                        body_size: '',
                        weight_forecast: '',
                        breed_id: '',
                    }}
                    onSubmit={handleSubmit}
                >
                    {({ handleChange, errors, touched, values, isSubmitting, setFieldValue }) => (
                        <Form>
                            <ContentItem>
                                <StyledPaper>
                                    <Grid container spacing={2}>
                                        <Grid item sm xs={12}>
                                            <FormControl
                                                size="small"
                                                fullWidth
                                                variant="outlined"
                                                error={(!!errors.lot && touched.lot)}
                                            >
                                                <InputLabel id="lot_id-label">
                                                    Lote
                                                </InputLabel>
                                                <Select
                                                    name="lot_id"
                                                    labelWidth={122}
                                                    value={values.lot_id}
                                                    onChange={handleChange}
                                                >
                                                    {data.optionsLots.map(lot => (
                                                        <MenuItem key={lot.secure_id} value={lot.secure_id}>
                                                            {lot.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <ErrorMessage name="lot_id" component={Error} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item sm xs={12}>
                                            <FormControl
                                                size="small"
                                                fullWidth
                                                variant="outlined"
                                                error={(!!errors.breed && touched.breed)}
                                            >
                                                <InputLabel id="breed_id-label">
                                                    Raça
                                                </InputLabel>
                                                <Select
                                                    name="breed_id"
                                                    labelWidth={122}
                                                    value={values.breed_id}
                                                    onChange={handleChange}
                                                >
                                                    {data.optionsBreeds.map(lot => (
                                                        <MenuItem key={lot.secure_id} value={lot.secure_id}>
                                                            {lot.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <ErrorMessage name="breed_id" component={Error} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md>
                                            <FormControl
                                                size="small"
                                                fullWidth
                                                variant="outlined"
                                                error={!!errors.age && touched.age}
                                            >
                                                <InputLabel id="input-age">Idade</InputLabel>
                                                <Select
                                                    name="age"
                                                    labelWidth={50}
                                                    inputProps={{
                                                        id: 'age-input',
                                                    }}
                                                    value={values.age}
                                                    onChange={handleChange}
                                                >
                                                    {ages.map(item => (
                                                        <MenuItem key={item} value={item}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <ErrorMessage name="age" component={Error} />
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2}>
                                        <Grid item sm xs>
                                            <FormControl
                                                size="small"
                                                fullWidth
                                                variant="outlined"
                                                error={!!errors.body_size && touched.body_size}
                                            >
                                                <InputLabel id="input-body_size">
                                                    Tamanho Corporal
                                                </InputLabel>
                                                <Select
                                                    name="body_size"
                                                    labelWidth={145}
                                                    value={values.body_size}
                                                    onChange={handleChange}
                                                >
                                                    {bodySizes.map(item => (
                                                        <MenuItem key={item} value={item}>
                                                            {item}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                                <ErrorMessage name="body_size" component={Error} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md>
                                            <FormControl
                                                size="small"
                                                fullWidth
                                                variant="outlined"
                                                error={!!errors.type && touched.type}
                                            >
                                                <InputLabel id="input-type">Categoria</InputLabel>
                                                <Select
                                                    name="type"
                                                    labelWidth={83}
                                                    inputProps={{
                                                        id: 'type-input',
                                                    }}
                                                    value={values.type}
                                                    onChange={handleChange}
                                                >
                                                    {values.sex && types
                                                        .filter(item => {
                                                            if (values.sex === 'Macho') {
                                                                return (
                                                                    item === 'Inteiro' ||
                                                                    item === 'Castrado' ||
                                                                    item === 'Bezerro'
                                                                );
                                                            } else if (values.sex === 'Femea') {
                                                                return (
                                                                    item === 'Vaca' ||
                                                                    item === 'Novilha' ||
                                                                    item === 'Bezerra'
                                                                );
                                                            }
                                                        })
                                                        .map(item => (
                                                            <MenuItem key={item} value={item}>
                                                                {item}
                                                            </MenuItem>
                                                        ))}
                                                </Select>
                                                <ErrorMessage name="type" component={Error} />
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md>
                                            <StyledRadioGroup row defaultValue={values.sex}>
                                                {gender.map(sex => (
                                                    <FormControlLabel
                                                        key={sex.value}
                                                        name="sex"
                                                        value={sex.value}
                                                        control={
                                                            <Radio
                                                                color="primary"
                                                                size="small"
                                                                name="sex"
                                                                onChange={(event) => {
                                                                    handleChange(event)
                                                                    setFieldValue('type', '')
                                                                }}
                                                            />
                                                        }
                                                        label={sex.label}
                                                    />
                                                ))}
                                            </StyledRadioGroup>
                                        </Grid>
                                        <Grid item sm={4} xs={12}>
                                            <NumberFormatForm
                                                allowedDecimalSeparators={[',']}
                                                name="weight_forecast"
                                                label="Peso de Abate Previsto"
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                allowNegative={false}
                                                fixedDecimalScale
                                                decimalScale={2}
                                                value={values.weight_forecast}
                                                setFieldValue={setFieldValue}
                                                error={
                                                    !!errors.weight_forecast && touched.weight_forecast
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">Kg</InputAdornment>
                                                    ),
                                                }}
                                            />
                                            <ErrorMessage
                                                name="weight_forecast"
                                                component={Error}
                                            />
                                        </Grid>
                                    </Grid>

                                    <ContentSubmitButtons>
                                        <StyledSubmitButton
                                            variant="outlined"
                                            color="primary"
                                            type="button"
                                            onClick={handleBack}
                                        >
                                            Cancelar
                                        </StyledSubmitButton>
                                        <ButtonSubmit title="Alterar" disabled={!values.lot_id} loading={isSubmitting} />
                                    </ContentSubmitButtons>
                                </StyledPaper>
                            </ContentItem>
                        </Form>
                    )}
                </Formik>
            </Container>
        </center>
    );
}

AnimalsEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
};

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { Formik, Form, ErrorMessage } from 'formik';
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Divider,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// Query
import { useApiV2SelectIndividualTransferApartLot } from '~/hooks/apiV2/animalHandling/IndividualTransferApartLot/useApiV2SelectIndividualTransferApartLot';

import {
  Container,
  Content,
  StyledPaper,
  StyledGrid,
  ContentSubmitButtons,
  StyledSubmitButton,
  Error,
} from './styles';

import history from '~/services/history';
import NumberFormatForm from '~/components/NumberFormatForm';
import Loader from '~/components/Loader';

export default function View({ match }) {
  const secure_id = useMemo(() => match.params.id, [match.params.id]);
  // Query
  const { data, isLoading } = useApiV2SelectIndividualTransferApartLot(secure_id);

  function handleBack() {
    history.goBack();
  }

  return (
    <center>
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          <StyledPaper>
            <Formik
              initialValues={{
                lot_id: data.currentAnimalInputLotApart.lot.secure_id,
                diet_id: data.currentAnimalInputLotApart.diet.secure_id,
                module_id: data.currentAnimalInputLotApart.paddock.line.module.secure_id,
                line_id: data.currentAnimalInputLotApart.paddock.line.secure_id,
                paddock_id: data.currentAnimalInputLotApart.paddock.secure_id,
                apart: data.currentAnimalInputLotApart.apart,
                amount_animals: data.currentAnimalInputLotApart.amount_animals,
                average_weight: data.currentAnimalInputLotApart.average_weight,
                percent_rc: data.currentAnimalInputLotApart.percent_rc,
                average_price: data.currentAnimalInputLotApart.average_price,
                arroba_price: data.currentAnimalInputLotApart.arroba_price,
                date: data.currentAnimalInputLotApart.date,
              }}
            >
              {({ values, errors, touched }) => (
                <Form>
                  <Content>
                    <StyledGrid
                      container
                      justify="space-between"
                      spacing={2}
                      marginBottom={12}
                    >
                      <Grid item sm xs={12}>
                        <FormControl
                          size="small"
                          required
                          fullWidth
                          variant="outlined"
                          error={!!errors.apart && touched.apart}
                        >
                          <InputLabel id="input-apart">Aparte</InputLabel>
                          <Select
                            name="apart"
                            labelWidth={50}
                            inputProps={{
                              id: 'apart-input',
                            }}
                            disabled
                            value={values.apart}
                          >
                            <MenuItem key={data.currentAnimalInputLotApart.apart} value={data.currentAnimalInputLotApart.apart}>
                              {data.currentAnimalInputLotApart.apart}
                            </MenuItem>
                          </Select>
                          <ErrorMessage name="apart" component={Error} />
                        </FormControl>
                      </Grid>
                      <Grid item sm xs={12}>
                        <FormControl
                          size="small"
                          required
                          fullWidth
                          variant="outlined"
                          error={!!errors.lot_id && touched.lot_id}
                        >
                          <InputLabel id="input-lot_id">Lote</InputLabel>
                          <Select
                            name="lot_id"
                            labelWidth={50}
                            inputProps={{
                              id: 'lot_id-input',
                            }}
                            disabled
                            value={values.lot_id}
                          >
                            <MenuItem key={data.currentAnimalInputLotApart.lot.secure_id} value={data.currentAnimalInputLotApart.lot.secure_id}>
                              {data.currentAnimalInputLotApart.lot.code}
                            </MenuItem>
                          </Select>
                          <ErrorMessage name="lot_id" component={Error} />
                        </FormControl>
                      </Grid>
                    </StyledGrid>

                    <Divider light />

                    <StyledGrid container justify="space-between" spacing={2}>
                      <Grid item sm xs={12}>
                        <TextField
                          name="module_id"
                          label="Módulo"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          disabled
                          size="small"
                          InputLabelProps={{
                            shrink: values.module_id,
                          }}
                          error={!!errors.module_id && touched.module_id}
                          value={values.module_id}
                        >
                          <MenuItem key={data.currentAnimalInputLotApart.paddock.line.module.secure_id} value={data.currentAnimalInputLotApart.paddock.line.module.secure_id}>
                            {data.currentAnimalInputLotApart.paddock.line.module.name}
                          </MenuItem>
                        </TextField>
                        <ErrorMessage name="module_id" component={Error} />
                      </Grid>
                      <Grid item sm xs={12}>
                        <TextField
                          name="line_id"
                          label="Linha"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          disabled
                          size="small"
                          InputLabelProps={{
                            shrink: values.line_id,
                          }}
                          error={!!errors.line_id && touched.line_id}
                          value={values.line_id}
                        >
                          <MenuItem key={data.currentAnimalInputLotApart.paddock.line.secure_id} value={data.currentAnimalInputLotApart.paddock.line.secure_id}>
                            {data.currentAnimalInputLotApart.paddock.line.name}
                          </MenuItem>
                        </TextField>
                        <ErrorMessage name="line_id" component={Error} />
                      </Grid>
                      <Grid item sm xs={12}>
                        <TextField
                          name="paddock_id"
                          label="Piquete"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          disabled
                          size="small"
                          InputLabelProps={{
                            shrink: values.paddock_id,
                          }}
                          error={!!errors.paddock_id && touched.paddock_id}
                          value={values.paddock_id}
                        >
                          <MenuItem key={data.currentAnimalInputLotApart.paddock.secure_id} value={data.currentAnimalInputLotApart.paddock.secure_id}>
                            {data.currentAnimalInputLotApart.paddock.name}
                          </MenuItem>
                        </TextField>
                        <ErrorMessage name="paddock_id" component={Error} />
                      </Grid>
                    </StyledGrid>

                    <Divider light />

                    <StyledGrid
                      container
                      justify="space-between"
                      spacing={2}
                      marginTop={12}
                      marginBottom={12}
                    >
                      <Grid sm={8} xs={12}>
                        <StyledGrid
                          container
                          direction="row"
                          justify="space-between"
                          style={{ marginTop: 8, marginBottom: 8 }}
                        >
                          <Grid item sm xs style={{ marginLeft: 8 }}>
                            <TextField
                              name="diet_id"
                              label="Dieta"
                              variant="outlined"
                              fullWidth
                              select
                              required
                              disabled
                              size="small"
                              InputLabelProps={{
                                shrink: values.diet_id,
                              }}
                              error={!!errors.diet_id && touched.diet_id}
                              value={values.diet_id}
                            >
                              <MenuItem
                                key={data.currentAnimalInputLotApart.diet.secure_id}
                                value={data.currentAnimalInputLotApart.diet.secure_id}
                              >
                                {data.currentAnimalInputLotApart.diet.name}
                              </MenuItem>
                            </TextField>
                            <ErrorMessage name="diet_id" component={Error} />
                          </Grid>
                        </StyledGrid>
                      </Grid>
                      <Grid item sm />
                    </StyledGrid>

                    <Divider light />

                    <StyledGrid
                      container
                      justify="space-between"
                      spacing={2}
                      marginTop={12}
                      marginBottom={12}
                    >
                      <Grid item sm xs={12}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={ptBR}
                        >
                          <KeyboardDatePicker
                            name="date"
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            size="small"
                            id="date-picker-inline"
                            label="Data de entrada"
                            inputVariant="outlined"
                            maxDate={new Date()}
                            required
                            disabled
                            fullWidth
                            autoOk
                            style={{ marginTop: 0, marginBottom: 0 }}
                            value={values.date}
                          />
                          <ErrorMessage name="date" component={Error} />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item sm xs={12}>
                        <NumberFormatForm
                          name="amount_animals"
                          label="Quantidade"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          allowNegative={false}
                          autoComplete="nope"
                          disabled
                          decimalScale={0}
                          value={values.amount_animals}
                        />
                      </Grid>
                      <Grid item sm xs={12}>
                        <NumberFormatForm
                          name="average_weight"
                          label="Peso Médio por Animal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          autoComplete="nope"
                          allowNegative={false}
                          decimalScale={2}
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">Kg</InputAdornment>
                            ),
                          }}
                          value={values.average_weight}
                        />
                      </Grid>
                    </StyledGrid>

                    <StyledGrid
                      container
                      justify="space-between"
                      spacing={2}
                      marginBottom={16}
                    >
                      <Grid item sm xs={12}>
                        <NumberFormatForm
                          name="percent_rc"
                          label="RC de Entrada (%)"
                          variant="outlined"
                          size="small"
                          disabled
                          required
                          fullWidth
                          allowNegative={false}
                          decimalScale={2}
                          isAllowed={val => {
                            const { formattedValue, floatValue } = val;
                            return (
                              formattedValue === '' ||
                              formattedValue === '-' ||
                              (floatValue <= 100 && floatValue >= -100)
                            );
                          }}
                          value={values.percent_rc}
                        />
                      </Grid>
                      <Grid item sm xs={12}>
                        <NumberFormatForm
                          allowedDecimalSeparators={[',']}
                          name="average_price"
                          fixedDecimalScale
                          label="Preço Médio por Animal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          decimalScale={2}
                          disabled
                          value={values.average_price}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">R$</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item sm xs={12}>
                        <NumberFormatForm
                          name="arroba_price"
                          label="Preço por Arroba"
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled
                          allowNegative={false}
                          decimalScale={2}
                          value={values.arroba_price}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">R$</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </StyledGrid>
                    <Divider light />

                    <ContentSubmitButtons>
                      <StyledSubmitButton
                        variant="outlined"
                        color="primary"
                        onClick={handleBack}
                      >
                        Voltar
                      </StyledSubmitButton>
                    </ContentSubmitButtons>
                  </Content>
                </Form>
              )}
            </Formik>
          </StyledPaper>
        </Container>
      )}
    </center>
  );
}

View.defaultProps = {
  match: null,
};

View.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export const InputNumber = styled(NumberFormat)`
  min-width: 50px;

  input {
    text-align: right;
  }
`;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

import { Container, StyledPaper } from './styles';

export default function FilterMsBeats({ setItem, data }) {
  const [currentType, setCurrentType] = useState(null);

  async function handleSelectItem(selectedItem) {
    if (selectedItem) {
      if (String(selectedItem.type).toLowerCase() === 'dieta') {
        setItem(selectedItem);
      } else {
        setItem(selectedItem);
      }

      setCurrentType(selectedItem.type);
    } else {
      setItem({});
      setItem({});
      setItem(null);
      setCurrentType(null);
    }
  }

  return (
    <Container>
      <StyledPaper>
        <Grid container>
          <Grid item sm xs>
            <Autocomplete
              name="item"
              size="small"
              noOptionsText="Sem opções"
              options={data}
              getOptionLabel={option => option.value}
              groupBy={option => option.type}
              onChange={(e, value) => {
                handleSelectItem(value);
              }}
              renderInput={params => (
                <TextField {...params} label="Dieta/Ração" fullWidth />
              )}
            />
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

FilterMsBeats.propTypes = {
  setItem: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

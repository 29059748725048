import styled from 'styled-components';
import { FormControlLabel, Paper, Divider } from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 1024px;
  width: 100%;
  padding: 40px;

  text-align: left;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 0px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 18px 16px 16px;
  position: relative;
  margin-top: 22px;

  & + div {
    margin-top: 38px;
  }

  h6 {
    font-weight: bold;
  }

  .MuiTypography-body2 {
    font-weight: 500;
    line-height: 25px;
    margin-right: 5px;

    /* text-transform: capitalize; */
  }
`;

export const Header = styled.div`
  position: absolute;
  top: -22px;
  left: -18px;
  padding: 8px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;

  h4 {
    margin-top: 5px;
    font-size: 15px;
  }

  svg {
    color: ${colors.darker};
  }
`;

export const SubItem = styled.div`
  margin-left: 20px;
`;

export const SubItemRight = styled.div`
  margin-top: 26px;

  @media (max-width: 600px) {
    margin-left: 20px;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 16px 0;
`;

export const ContentChart = styled.div`
  padding-top: 10%;
  height: 100%;

  @media (max-width: 900px) {
    padding-top: 20%;
  }

  @media (max-width: 800px) {
    padding-top: 30%;
  }

  @media (max-width: 600px) {
    padding-top: 15px;
  }
`;

export const ContentPrint = styled.div`
  visibility: hidden;
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: -999;
`;

import React, { useState, useEffect } from 'react';
import {
  Grid,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

// Query
import {
  useApiV2ManufacturingFeed
} from '~/hooks/apiV2/foodManagement/manufactoringFeed/useApiV2ManufacturingFeed';

import {
  Container,
  StyledGrid,
  StyledGridLoader,
  StyledPaper,
  ButtonPrint,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
} from './styles';

import Filter from './Components/Filter';
import ModalPrint from './Components/ModalPrint';

import history from '~/services/history';
import Can from '~/components/Can';
import Loader from '~/components/Loader';
import ButtonNew from '~/components/ButtonNew';

import { CardTableManufactoringFeed } from './Components/CardTableManufactoringFeed';

const feedDefaultOption = {
  secure_id: -1,
  name: 'Todas',
};

export default function ManufactoringFeed() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('date');
  const [direction, setDirection] = useState('desc');
  const [totalForecast, setTotalForecast] = useState(0);
  const [totalPerformed, setTotalPerformed] = useState(0);
  const [visibleModalPrint, setVisibleModalPrint] = useState(false);
  const [currentFeed, setCurrentFeed] = useState();
  const [currentDate, setCurrentDate] = useState();

  // Query
  const { data, isLoading } = useApiV2ManufacturingFeed(
    `${field}%7C${direction}`,
    page + 1,
    rowsPerPage,
    currentFeed,
    currentDate
  );

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleModalPrint() {
    setVisibleModalPrint(!visibleModalPrint);
  }

  function handleAdd() {
    history.push('/dashboard/manufactoringFeed/add');
  }

  useEffect(() => {
    if (data) {
      const sumForecast = Math.round(
        Number(
          data.manufacturingFeeds.reduce((total, feed) => total + Number(feed.total_forecast), 0)
        )
      );

      const sumPerformed = Math.round(
        Number(
          data.manufacturingFeeds.reduce((total, feed) => total + Number(feed.total_performed), 0)
        )
      );

      setTotalForecast(sumForecast);
      setTotalPerformed(sumPerformed);
    }
  }, [data]);

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={2}>
          <Grid item xs={12} sm md lg xl>
            <Filter
              totalForecast={totalForecast}
              totalPerformed={totalPerformed}
              feedDefaultOption={feedDefaultOption}
              currentFeed={currentFeed}
              currentDate={currentDate}
              setCurrentFeed={setCurrentFeed}
              setCurrentDate={setCurrentDate}
            />
          </Grid>
          <Grid item xs="auto" sm="auto">
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['manufactoringFeed_new']}
            >
              <ButtonNew title="Nova" onClick={handleAdd} />
            </Can>
            <ButtonPrint
              color="primary"
              variant="contained"
              startIcon={<PrintIcon />}
              disabled={!currentDate}
              onClick={handleModalPrint}
            >
              Ficha
            </ButtonPrint>
          </Grid>
        </StyledGrid>

        {isLoading ? (
          <StyledGridLoader container justify="center" alignItems="center">
            <Loader />
          </StyledGridLoader>
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'date'}
                        direction={field === 'date' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('date')}
                      >
                        Data
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      Ração
                    </TableCell>
                    <StyledTableCell align="right">
                      <TableSortLabel
                        active={field === 'total_forecast'}
                        direction={
                          field === 'total_forecast' ? direction : 'asc'
                        }
                        onClick={() => handleChangeOrder('total_forecast')}
                      >
                        Previsto (Kg)
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <TableSortLabel
                        active={field === 'total_performed'}
                        direction={
                          field === 'total_performed' ? direction : 'asc'
                        }
                        onClick={() => handleChangeOrder('total_performed')}
                      >
                        Realizado (Kg)
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <TableSortLabel
                        active={field === 'deviation'}
                        direction={field === 'deviation' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('deviation')}
                      >
                        Desvio (%)
                      </TableSortLabel>
                    </StyledTableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={field === 'price_ton'}
                        direction={field === 'price_ton' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('price_ton')}
                      >
                        Custo/Ton
                      </TableSortLabel>
                    </TableCell>
                    <Can checkRole={['administrator', 'owner', 'manager']}>
                      <TableCell align="right" />
                    </Can>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {data && data.manufacturingFeeds
                    .map(feed => (
                      <CardTableManufactoringFeed
                        key={feed.secure_id}
                        feed={feed}
                      />
                    ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}

        {visibleModalPrint && (
          <ModalPrint
            visibleModal={visibleModalPrint}
            handleModal={handleModalPrint}
            currentDate={currentDate}
          // data={listDiets}
          />
        )}
      </Container>
    </center>
  );
}

import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

export async function getApiV2IntermediateWeight(sort, page = 1, limit = 50, date, lot) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/animal-handling/weightCorrection?sort=${sort}`, {
      params: { page, limit, date, lot },
    });

    return {
      intermediateWeight: data.data,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    }
  } catch {
    toast.error('Não foi possível carregar Pesagens!');
  }
}

export function useApiV2IntermediateWeight(sort, page, limit, date, lot) {
  return useQuery(
    ['intermediateWeight', sort, page, limit, date, lot],
    () => getApiV2IntermediateWeight(sort, page, limit, date, lot),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  );
}

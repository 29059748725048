import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Content = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  & + & {
    margin-top: 10px;
  }
`;

export const StyledAddButton = styled(Button)`
  color: ${colors.white};
  background: ${colors.primary};
  transition: background 2s;

  &:hover {
    background: ${darken(0.06, colors.primary)};
  }
`;

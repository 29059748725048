import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2"

export async function getOcurrenceType(secure_id) {
  if (!secure_id) {
    return null
  }

  const { data } = await apiV2.get(`/v1/feedlot/register/ocurrenceType/${secure_id}`)

  return {
    currentOcurrenceType: data
  }
}

export function useApiV2SelectOcurrenceType(secure_id) {
  return useQuery(['ocurrencesType', secure_id], () => getOcurrenceType(secure_id))
}

import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import { toast } from 'react-toastify'

// Query
import { useApiV2SelectFood } from '~/hooks/apiV2/register/food/useApiV2SelectFood';
import { useApiV2AddFood } from '~/hooks/apiV2/register/food/useApiV2AddFood';
import { useApiV2EditFood } from '~/hooks/apiV2/register/food/useApiV2EditFood';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

import {
  Container,
  StyledPaper,
  ContentItem,
  ContentSubmitButtons,
  StyledSubmitButton,
  Error,
} from './styles';

import history from '~/services/history';
import Loader from '~/components/Loader';
import NumberFormatForm from '~/components/NumberFormatForm';
import ButtonSubmit from '~/components/ButtonSubmit';

export default function Register({ closeModal, isModal, match }) {
  const [name, setName] = useState('');
  const [settings, setSettings] = useState({});
  const secure_id = useMemo(() => match && match.params.id, [match]);

  // Current food query
  const { data: currentFoodData, isLoading, error } = useApiV2SelectFood(secure_id)
  const { data: settingData } = useApiV2GlobalSetting();

  // Querys of create and edit food
  const addFood = useApiV2AddFood(closeModal, isModal)
  const editFood = useApiV2EditFood('/dashboard/food')

  const schema = Yup.object().shape({
    name: Yup.string().required('Digite o nome'),
    percent_ms: Yup.number()
      .typeError('Insira um valor válido')
      .max(100, 'O valor deve ser menor que 100%')
      .positive('A porcentagem deve ser positiva')
      .required('Informe a porcentagem da dieta'),
    min_food_stock_ton: Yup.number()
      .typeError('Insira um valor válido')
      .min(0, 'O valor deve ser zero ou positivo')
      .nullable(),
  });

  const handleSubmit = useCallback(
    async (data) => {
      if (secure_id) {
        const newData = {
          secure_id,
          name: data.name ? data.name.toUpperCase() : name.toUpperCase(),
          percentMs: data.percent_ms,
          minFoodStockTon: data.min_food_stock_ton,
          type: data.type
        };

        await editFood.mutateAsync(newData)
      } else {
        const newData = {
          name: data.name ? data.name.toUpperCase() : name.toUpperCase(),
          percentMs: data.percent_ms,
          minFoodStockTon: data.min_food_stock_ton,
          type: data.type
        };
        await addFood.mutateAsync(newData)
      }
    },
    [isModal, closeModal, secure_id, name]
  );

  function handleBack() {
    if (isModal) {
      closeModal();
    } else {
      history.push('/dashboard/food');
    }
  }

  useEffect(() => {
    if (settingData) {
      setSettings(settingData.settings)
    }
  }, [settingData])

  if (secure_id && error) {
    history.push('/dashboard/food');
    toast.error('Falha para carregar os dados do alimento!');

    return (
      <Container>
        <p>Não foi possível carregar o alimento!</p>
      </Container>
    )
  }

  return (
    <center>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            enableReinitialize={secure_id}
            validationSchema={schema}
            initialValues={{
              name: (currentFoodData && currentFoodData.currentFood.name) || '',
              percent_ms:
                currentFoodData &&
                  String(currentFoodData.currentFood.percent_ms) !== ''
                  ? currentFoodData.currentFood.percent_ms
                  : '',
              type: (currentFoodData && currentFoodData.currentFood.type) || 'food',
              min_food_stock_ton:
                currentFoodData &&
                  String(currentFoodData.currentFood.min_food_stock_ton) !== ''
                  ? currentFoodData.currentFood.min_food_stock_ton
                  : '',
            }}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, errors, touched, isSubmitting }) => (
              <Form>
                <ContentItem>
                  <StyledPaper>
                    <Grid container spacing={2} direction="column">
                      {/* <Grid item xs={12} sm>
                        {/* <TextField
                          name="name"
                          variant="outlined"
                          label="Alimento"
                          autoComplete="nope"
                          autoFocus
                          fullWidth
                          size="small"
                          error={!!errors.name && touched.name}
                          value={values.name}
                          onChange={handleChange}
                        /> */}
                      <Grid item xs={12} sm>
                        <TextField
                          label="Nome do alimento"
                          size="small"
                          variant="outlined"
                          name="name"
                          required
                          value={values.name}
                          fullWidth
                          InputProps={{
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                          onChange={e => {
                            setName(e.target.value);
                            setFieldValue('name', e.target.value);
                          }}
                        />
                        {/* <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          options={
                            foodsData && foodsData.length > 0 ? foodsData.map(option => option.name) : []
                          }
                          onChange={(e, value) => {
                            setFieldValue('name', value);
                          }}
                          fullWidth
                          name="name"
                          value={values.name}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Nome do alimento"
                              size="small"
                              variant="outlined"
                              name="name"
                              required
                              value={values.name}
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                form: {
                                  autocomplete: 'off',
                                },
                              }}
                              onChange={e => {
                                setName(e.target.value);
                                setFieldValue('name', e.target.value);
                              }}
                            />
                          )}
                        /> */}
                      </Grid>

                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          name="percent_ms"
                          label="MS (%)"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          autoComplete="nope"
                          disabled={
                            currentFoodData && currentFoodData.currentFood.food_has_diets > 0
                          }
                          decimalScale={2}
                          allowNegative={false}
                          isAllowed={val => {
                            const { formattedValue, floatValue } = val;
                            return formattedValue === '' || floatValue <= 100;
                          }}
                          error={!!errors.percent_ms && touched.percent_ms}
                          value={values.percent_ms}
                          setFieldValue={setFieldValue}
                        />
                        <ErrorMessage name="percent_ms" component={Error} />
                      </Grid>
                      {!!settings && !!settings.enable_min_food_stock && (
                        <Grid item xs={12} sm>
                          <NumberFormatForm
                            name="min_food_stock_ton"
                            label="Estoque mínimo (ton)"
                            variant="outlined"
                            size="small"
                            fullWidth
                            autoComplete="nope"
                            decimalScale={4}
                            allowNegative={false}
                            isAllowed={val => {
                              const { formattedValue, floatValue } = val;
                              return (
                                formattedValue === '' ||
                                floatValue <= 9999999999
                              );
                            }}
                            error={
                              !!errors.min_food_stock_ton &&
                              touched.min_food_stock_ton
                            }
                            value={values.min_food_stock_ton}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage
                            name="min_food_stock_ton"
                            component={Error}
                          />
                        </Grid>
                      )}
                    </Grid>

                    <ContentSubmitButtons>
                      <StyledSubmitButton
                        color="primary"
                        variant="outlined"
                        type="button"
                        onClick={handleBack}
                      >
                        Cancelar
                      </StyledSubmitButton>
                      {secure_id ? (
                        <ButtonSubmit title="Alterar" loading={isSubmitting} />
                      ) : (
                        <ButtonSubmit title="Cadastrar" loading={isSubmitting} />
                      )}
                    </ContentSubmitButtons>
                  </StyledPaper>
                </ContentItem>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </center>
  );
}

Register.defaultProps = {
  closeModal: () => { },
  isModal: false,
};

Register.propTypes = {
  closeModal: PropTypes.func,
  isModal: PropTypes.bool,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

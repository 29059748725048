import { useQuery } from "react-query";
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2"

export async function getStakeholders(types = '') {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/global/list/stakeholder`, {
      params: {
        types
      }
    })

    return data
  } catch {
    toast.error('Falha para carregar os registros cadastrados!');
  }
}

export function useApiV2Stakeholders(types) {
  return useQuery(
    ['stakeholders', types],
    () => getStakeholders(types),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2"

export async function getBreed(secure_id) {
  if (!secure_id) {
    return null
  }

  const { data } = await apiV2.get(`/v1/feedlot/register/breed/${secure_id}`)

  return {
    currentBreed: data
  }
}

export function useApiV2SelectBreed(secure_id) {
  return useQuery(['breeds', secure_id], () => getBreed(secure_id))
}

import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import {
  parseISO,
  isSameDay,
  format,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import {
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
  Divider,
  Hidden,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// Query
import { useApiV2ShowDataTransfer } from '~/hooks/apiV2/animalHandling/TransferLot/useApiV2ShowDataTransfer'
import { useApiV2AddTransferLot } from '~/hooks/apiV2/animalHandling/TransferLot/useApiV2AddTransferLot'

import {
  Container,
  StyledPaper,
  StyledGrid,
  StyledTypography,
  ContentDivider,
  StyledDivider,
  Error,
  StyledDividerTitle,
  WrapperTitle,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';

import { parseNumericUserInput } from '~/utils/format';
import colors from '~/styles/colors';
import history from '~/services/history';
import NumberFormatForm from '~/components/NumberFormatForm';
// import Loader from '~/components/Loader';
import ButtonSubmit from '~/components/ButtonSubmit';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.value,
});

const defaultOptionsLots = {
  id: null,
  secure_id: null,
  value: 'Gerar novo lote',
};

export default function Register() {
  //listas
  const [lots, setLots] = useState([]);
  const [listLots, setListLots] = useState([]);
  const [listLotsDestn, setListLotsDestn] = useState([]);
  const [newListSexs, setNewListSexs] = useState([]);
  const [newListBreeds, setNewListBreeds] = useState([]);
  const [newListAges, setNewListAges] = useState([]);
  const [newListTypes, setNewListTypes] = useState([]);

  const [currentOriginLot, setCurrentOriginLot] = useState(null);
  const [currentDestinationLot, setCurrentDestinationLot] = useState(null);
  const [selectedOriginLot, setSelectedOriginLot] = useState(null);
  const [selectedDestinationLot, setSelectedDestinationLot] = useState(defaultOptionsLots);
  //valores inputs
  const [initOriginAmountLot, setInitOriginAmountLot] = useState(null);
  const [originAmountLot, setOriginAmountLot] = useState(null);
  const [initOriginAmountPaddock, setInitOriginAmountPaddock] = useState(null);
  const [originAmountPaddock, setOriginAmountPaddock] = useState(null);
  const [initDestAmountLot, setInitDestAmountLot] = useState(null);
  const [destAmountLot, setDestAmountLot] = useState(null);
  const [destAmountPaddock, setDestAmountPaddock] = useState(null);
  const [initDestAmountPaddock, setInitDestAmountPaddock] = useState(null);
  const [inputTotalAmount, setInputTotalAmount] = useState(null);
  const [sex, setSex] = useState('');
  const [breed, setBreed] = useState('');
  const [age, setAge] = useState('');
  const [type, setType] = useState('');
  //erros
  const [errorOriginLot, setErrorOriginLot] = useState('');
  const [errorDestinationLot, setErrorDestinationLot] = useState('');

  // Query
  const { data: lotsData } = useApiV2ShowDataTransfer();
  const addAnimalTransferLot = useApiV2AddTransferLot();

  const schema = Yup.object().shape({
    date: Yup.string().required('Necessário informar uma data'),
    amount_animals: Yup.number()
      .max(Number(inputTotalAmount), 'Animais insuficiente para esta raça')
      .required('Necessário informar uma a qtd. de animais'),
    weight: Yup.number().required('Necessário informar uma o peso médio'),
    origin: Yup.string().required('Necessário informar um lote de origem'),
  });

  useEffect(() => {
    if (lotsData) {
      const newLots = lotsData.map(lot => ({
        secure_id: lot.secure_id,
        value: lot.name,
        dateTransfer: lot.dateTransfer,
        paddock_secure_id: lot.paddock.secure_id,
      }));

      setListLots(newLots.filter(data => data.value !== 'Gerar novo lote'));
      newLots.unshift(defaultOptionsLots);
      setListLotsDestn(newLots);

      setLots(lotsData);
    }
  }, [lotsData]);

  const CalcTotalAmount = () => {
    const { values } = useFormikContext();
    setErrorOriginLot('');
    setErrorDestinationLot('');

    useEffect(() => {
      if (values.origin && lots.length > 0) {
        const newLot = lots.find(lot => lot.secure_id === values.origin);
        if (newLot && newLot.dateTransfer && !isSameDay(parseISO(newLot.dateTransfer), values.date)) {
          setErrorOriginLot(`Troca permitida no dia ${format(parseISO(newLot.dateTransfer), 'dd/MM/yyyy')}`)
        } else {
          setErrorOriginLot('');
        }

        if (newLot) {
          let { animals: animalInput } = newLot;

          if (sex) {
            animalInput = animalInput.filter(input => input.sex === sex);
          }

          if (breed) {
            animalInput = animalInput.filter(
              input => input.breed_secure_id === breed
            );
          }

          if (age) {
            animalInput = animalInput.filter(input => input.age === age);
          }

          if (type) {
            animalInput = animalInput.filter(
              input => input.type === type
            );
          }

          const currentLotsPaddock = lots.filter(
            lot => lot.paddock.secure_id === newLot.paddock.secure_id
          );

          const totalAmountPaddock = currentLotsPaddock.reduce(
            (total, lot) => total + lot.animals.length,
            0
          );

          const newInputTotalAmount = animalInput.length;

          let newOriginTotalAmount = 0;
          let newOriginTotalAmountPaddock = totalAmountPaddock;

          newOriginTotalAmount = newInputTotalAmount - values.amount_animals;
          newOriginTotalAmountPaddock =
            totalAmountPaddock - values.amount_animals;

          setInitOriginAmountLot(newLot.animals.length);
          setInitOriginAmountPaddock(totalAmountPaddock);

          setCurrentOriginLot(newLot);
          setInputTotalAmount(newInputTotalAmount);
          setOriginAmountLot(newOriginTotalAmount);
          setOriginAmountPaddock(
            selectedDestinationLot && selectedDestinationLot.secure_id !== null
              ? newOriginTotalAmountPaddock
              : totalAmountPaddock
          );
        }
      } else {
        setCurrentOriginLot(null);
        setInputTotalAmount(null);
        setInitOriginAmountLot(null);
        setInitOriginAmountPaddock(null);
        setOriginAmountLot(null);
        setOriginAmountPaddock(null);
      }

      if (values.destination && lots.length > 0) {
        const newLot = lots.find(lot => lot.secure_id === values.destination);

        if (newLot && newLot.dateTransfer && !isSameDay(parseISO(newLot.dateTransfer), values.date)) {
          setErrorDestinationLot(`Troca permitida no dia ${format(parseISO(newLot.dateTransfer), 'dd/MM/yyyy')}`)
        } else {
          setErrorDestinationLot('');
        }

        const currentLotsPaddock = lots.filter(
          lot => lot.paddock.secure_id === newLot.paddock.secure_id
        );

        const animalInputs = [];

        currentLotsPaddock.map(lot => {
          const { animals: inputs } = lot;
          animalInputs.push(...inputs);
          return lot;
        });
        const totalAmountPaddock = animalInputs.length;
        let totalAmountLot = newLot.animals.length;
        let totalAmountLotPaddock = totalAmountPaddock;
        totalAmountLot += values.amount_animals;
        totalAmountLotPaddock += values.amount_animals;
        setInitDestAmountLot(newLot.animals.length);
        setInitDestAmountPaddock(totalAmountPaddock);

        setCurrentDestinationLot(newLot);

        setDestAmountLot(totalAmountLot);
        setDestAmountPaddock(totalAmountLotPaddock);
      } else if (selectedDestinationLot && selectedDestinationLot.secure_id === null) {
        setCurrentDestinationLot(null);
        setInitDestAmountPaddock(originAmountPaddock);
        setInitDestAmountLot();
        setDestAmountPaddock(originAmountPaddock);
        setDestAmountLot(values.amount_animals);
      } else {
        setCurrentDestinationLot(null);
        setInitDestAmountPaddock(null);
        setInitDestAmountLot();
        setDestAmountPaddock(null);
        setDestAmountLot(null);
      }
    }, [values]);
    return null;
  };

  function handleSelectOriginLot(
    value,
    setFieldValue,
    destinationLotId,
    resetForm
  ) {
    if (value) {
      let filterLots = lots.filter(lot => lot.secure_id !== value.secure_id);

      if (destinationLotId) {
        filterLots = lots.filter(
          lot =>
            lot.secure_id !== value.secure_id &&
            lot.secure_id !== destinationLotId
        );
      }

      const newListLots = filterLots.map(lot => ({
        secure_id: lot.secure_id,
        value: lot.name,
        dateTransfer: lot.dateTransfer,
        paddock_secure_id: lot.paddock.secure_id,
      }));

      setListLots(newListLots.filter(data => data.value !== 'Gerar novo lote'));
      newListLots.unshift(defaultOptionsLots);
      setListLotsDestn(newListLots);
      setSelectedOriginLot(value);

      setFieldValue('origin', value.secure_id);
    } else {
      const filterLots = lots.filter(lot => lot.id !== destinationLotId);

      const newListLots = filterLots.map(lot => ({
        secure_id: lot.secure_id,
        value: lot.name,
        dateTransfer: lot.dateTransfer,
        paddock_secure_id: lot.paddock.secure_id,
      }));

      setListLots(newListLots.filter(data => data.value !== 'Gerar novo lote'));
      newListLots.unshift(defaultOptionsLots);
      setListLotsDestn(newListLots);
      setSelectedOriginLot(null);

      resetForm();
    }
  }

  function handleSelectDestinationLot(value, setFieldValue, originLotId) {
    if (value) {
      let filterLots = lots.filter(lot => lot.secure_id !== value.secure_id);

      if (originLotId) {
        filterLots = lots.filter(
          lot =>
            lot.secure_id !== value.secure_id && lot.secure_id !== originLotId
        );
      }

      const newLots = filterLots.map(lot => ({
        secure_id: lot.secure_id,
        value: lot.name,
        dateTransfer: lot.dateTransfer,
        paddock_secure_id: lot.paddock.secure_id,
      }));

      setListLots(newLots.filter(data => data.value !== 'Gerar novo lote'));
      newLots.unshift(defaultOptionsLots);
      setListLotsDestn(newLots);
      setSelectedDestinationLot(value);

      setFieldValue('destination', value.secure_id);
    } else {
      const filterLots = lots.filter(lot => lot.id !== originLotId);

      const newLots = filterLots.map(lot => ({
        secure_id: lot.secure_id,
        value: lot.name,
        dateTransfer: lot.dateTransfer,
        paddock_secure_id: lot.paddock.secure_id,
      }));

      setListLots(newLots.filter(data => data.value !== 'Gerar novo lote'));
      newLots.unshift(defaultOptionsLots);
      setListLotsDestn(newLots);
      setSelectedDestinationLot(null);

      setFieldValue('destination', null);
    }
  }

  function handleChangeAmount(event, setFieldValue) {
    if (currentOriginLot) {
      const totalWeight = currentOriginLot.animals.reduce((acc, value) => {
        return Number(acc) + Number(value.current_weight);
      }, 0)
      const averageWeight = currentOriginLot.animals.length > 0
        ? totalWeight / currentOriginLot.animals.length
        : 0
      const newWeight =
        currentOriginLot.animals.length > 0
          ? parseNumericUserInput(event.target.value) * averageWeight
          : '';

      setFieldValue('weight', newWeight);
    } else {
      setFieldValue('weight', '');
    }
  }

  async function handleSubmit(data) {
    if (errorOriginLot || errorDestinationLot) {
      return;
    }

    if (!sex) {
      return;
    }
    if (!age) {
      return;
    }
    if (!type) {
      return;
    }
    if (!breed) {
      return;
    }

    const newData = {
      originLotSecureId: data.origin,
      destinLotSecureId: data.destination,
      paddockSecureId: selectedOriginLot.paddock_secure_id,
      amountAnimals: data.amount_animals,
      transferType: 'lot',
      date: new Date(data.date),//formatISO9075(new Date(data.date)),
      animals: {
        sex,
        breedSecureId: breed,
        age,
        type,
      },
    };

    await addAnimalTransferLot.mutateAsync(newData);
  }

  function handleBack() {
    history.goBack();
  }

  const handleChangeSex = data => {
    setSex(data.target.value);
    setBreed('');
    setAge('');
    setType('');
  };

  const handleChangeBreed = data => {
    setBreed(data.target.value);
  };

  const handleChangeAge = data => {
    setAge(data.target.value);
  };

  const handleChangeType = data => {
    setType(data.target.value);
  };

  useEffect(() => {
    if (currentOriginLot) {
      const listSexsNew = [...new Set(currentOriginLot.animals.map(x => x.sex))].map(animal => {
        const amount = currentOriginLot.animals.filter(animalFilter => animalFilter.sex === animal).length
        return {
          sex: animal,
          amount
        }
      })
      setNewListSexs(listSexsNew)

      if (sex) {
        const listBreedsNew = [...new Set(currentOriginLot.animals.map(x => x.breed_secure_id))].map(animal => {
          const amount = currentOriginLot.animals.filter(animalFilter => animalFilter.sex === sex && animalFilter.breed_secure_id === animal).length
          const breedName = currentOriginLot.animals.find(breedAnimal => breedAnimal.breed_secure_id === animal)
          return {
            breed: breedName ? breedName.breed_name : '',
            amount,
            secure_id: animal
          }
        }).filter(greatherThan => greatherThan.amount > 0)
        setNewListBreeds(listBreedsNew)

        if (breed) {
          const listAgesNew = [...new Set(currentOriginLot.animals.map(x => x.age))].map(animal => {
            const amount = currentOriginLot.animals.filter(
              animalFilter => animalFilter.sex === sex && animalFilter.breed_secure_id === breed && animalFilter.age === animal
            ).length
            return {
              amount,
              name: animal
            }
          }).filter(greatherThan => greatherThan.amount > 0)
          setNewListAges(listAgesNew)

          if (age) {
            const typeData = [...new Set(currentOriginLot.animals.map(x => x.type))].map(animal => {
              const amount = currentOriginLot.animals.filter(
                animalFilter => animalFilter.sex === sex && animalFilter.breed_secure_id === breed && animalFilter.age === age && animalFilter.type === animal
              ).length
              return {
                amount,
                name: animal
              }
            }).filter(greatherThan => greatherThan.amount > 0)
            setNewListTypes(typeData)
          }
        }
      }
    } else {
      setNewListAges([]);
      setNewListBreeds([]);
      setNewListTypes([]);
      setNewListSexs([]);
      setBreed('');
      setAge('');
      setType('');
      setSex('');
    }
  }, [sex, breed, age, currentOriginLot])

  return (
    <center>
      <Container>
        <StyledPaper>
          <Formik
            validationSchema={schema}
            initialValues={{
              date: new Date(),
              origin: '',
              destination: '',
              amount_animals: '',
              weight: '',
              sex_destination: '',
              breed_id_destination: '',
              age_destination: '',
              type_destination: '',
              amount_animals_destination: '',
              weight_destination: 0,
            }}
            onSubmit={handleSubmit}
          >
            {({
              handleChange,
              setFieldValue,
              resetForm,
              errors,
              touched,
              values,
              isSubmitting
            }) => (
              <Form>
                <Grid container justify="space-between" spacing={2}>
                  <Grid item xs sm>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                      <KeyboardDatePicker
                        name="date"
                        disableToolbar
                        variant="outline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        autoComplete="nope"
                        size="small"
                        label="Data"
                        fullWidth
                        required
                        maxDate={new Date()}
                        inputVariant="outlined"
                        value={values.date}
                        onChange={date => setFieldValue('date', date)}
                        autoOk
                        error={!!errors.date && touched.date}
                      />
                      <ErrorMessage name="date" component={Error} />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Hidden xsDown>
                    <Grid item sm />
                    <Grid item sm />
                  </Hidden>
                </Grid>

                <StyledGrid container justify="space-between" spacing={2}>
                  <Grid item sm xs={12}>
                    <Autocomplete
                      name="origin"
                      size="small"
                      noOptionsText="Sem opções"
                      filterOptions={filterOptions}
                      options={listLots}
                      getOptionLabel={option => option.value}
                      value={selectedOriginLot}
                      onChange={(event, value) =>
                        handleSelectOriginLot(
                          value,
                          setFieldValue,
                          values.destination,
                          resetForm
                        )
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Lote de Origem"
                          required
                          fullWidth
                          variant="outlined"
                        />
                      )}
                      error={
                        (!!errors.origin && touched.origin) || errorOriginLot
                      }
                    />
                    {errorOriginLot ? (
                      <Error>
                        <span>{errorOriginLot}</span>
                      </Error>
                    ) : (
                      <ErrorMessage name="origin" component={Error} />
                    )}
                  </Grid>
                  <Grid item sm xs={12}>
                    <Autocomplete
                      id="destination-input"
                      name="destination"
                      size="small"
                      noOptionsText="Sem opções"
                      filterOptions={filterOptions}
                      options={listLotsDestn}
                      getOptionLabel={option => option.value}
                      value={selectedDestinationLot}
                      onChange={(event, value) =>
                        handleSelectDestinationLot(
                          value,
                          setFieldValue,
                          values.origin
                        )
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Lote de Destino"
                          required
                          fullWidth
                          variant="outlined"
                        />
                      )}
                      error={(!!errors.destination) || errorDestinationLot}
                    />
                    {errorDestinationLot ? (
                      <Error>
                        <span>{errorDestinationLot}</span>
                      </Error>
                    ) : (
                      <ErrorMessage name="destination" component={Error} />
                    )}
                  </Grid>
                </StyledGrid>

                <WrapperTitle>
                  <span>Origem</span>
                </WrapperTitle>
                <StyledGrid container justify="space-between" spacing={2}>
                  <Grid item xs={12} sm>
                    <TextField
                      name="sex"
                      label="Sexo"
                      variant="outlined"
                      fullWidth
                      select
                      required
                      size="small"
                      error={!!errors.sex && touched.sex}
                      value={sex}
                      onChange={handleChangeSex}
                    >
                      {newListSexs.map(sexL => (
                        <MenuItem key={sexL.sex} value={sexL.sex}>
                          {`${sexL.sex} - Saldo: ${sexL.amount}`}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ErrorMessage name="sex" component={Error} />
                  </Grid>
                  <Grid item xs={12} sm>
                    <TextField
                      name="breed_id"
                      label="Raça"
                      variant="outlined"
                      fullWidth
                      select
                      required
                      size="small"
                      error={!!errors.breed_id && touched.breed_id}
                      value={breed}
                      onChange={handleChangeBreed}
                    >
                      {newListBreeds.map(item =>
                        item && item.secure_id ? (
                          <MenuItem key={item.secure_id} value={item.secure_id}>
                            {`${item.breed} - Saldo: ${item.amount}`}
                          </MenuItem>
                        ) : (
                          ''
                        )
                      )}
                    </TextField>
                    <ErrorMessage name="breed_id" component={Error} />
                  </Grid>
                  <Grid item xs={12} sm>
                    <TextField
                      name="age"
                      label="Idade"
                      variant="outlined"
                      fullWidth
                      select
                      required
                      size="small"
                      error={!!errors.age && touched.age}
                      value={age}
                      onChange={handleChangeAge}
                    >
                      {newListAges.map(ageD =>
                        ageD && ageD.name ? (
                          <MenuItem key={ageD.name} value={ageD.name}>
                            {`${ageD.name} - Saldo: ${ageD.amount}`}
                          </MenuItem>
                        ) : (
                          ''
                        )
                      )}
                    </TextField>
                    <ErrorMessage name="age" component={Error} />
                  </Grid>
                </StyledGrid>

                <StyledGrid container justify="space-between" spacing={2}>
                  <Grid item xs={12} sm>
                    <TextField
                      name="type"
                      label="Categoria"
                      variant="outlined"
                      fullWidth
                      select
                      required
                      size="small"
                      error={!!errors.type && touched.type}
                      value={type}
                      onChange={handleChangeType}
                    >
                      {newListTypes.map(typeD =>
                        typeD && typeD.name ? (
                          <MenuItem key={typeD.name} value={typeD.name}>
                            {`${typeD.name} - Saldo: ${typeD.amount}`}
                          </MenuItem>
                        ) : (
                          ''
                        )
                      )}
                    </TextField>
                    <ErrorMessage name="type" component={Error} />
                  </Grid>
                  <Grid item xs={12} sm>
                    <NumberFormatForm
                      name="amount_animals"
                      label="Qtd. Animais"
                      variant="outlined"
                      size="small"
                      required
                      fullWidth
                      allowNegative={false}
                      autoComplete="nope"
                      decimalScale={0}
                      value={values.amount_animals}
                      setFieldValue={setFieldValue}
                      error={!!errors.amount_animals && touched.amount_animals}
                      onChange={event =>
                        handleChangeAmount(event, setFieldValue)
                      }
                    />

                    <ErrorMessage name="amount_animals" component={Error} />
                  </Grid>
                  <Grid item xs={12} sm>
                    <NumberFormatForm
                      name="weight"
                      label="Peso Médio"
                      decimalScale={2}
                      fixedDecimalScale
                      required
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">Kg</InputAdornment>
                        ),
                      }}
                      error={!!errors.weight && touched.weight}
                      value={values.weight / values.amount_animals}
                    />
                    <ErrorMessage name="weight" component={Error} />
                  </Grid>
                </StyledGrid>

                {selectedDestinationLot === null ||
                  selectedDestinationLot.secure_id === null ? (
                  <>
                    <WrapperTitle>
                      <span>Destino</span>
                    </WrapperTitle>
                    <StyledGrid container justify="space-between" spacing={2}>
                      <Grid item xs={12} sm>
                        <TextField
                          name="sex_destination"
                          label="Sexo"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          disabled
                          error={
                            !!errors.sex_destination && touched.sex_destination
                          }
                          value={sex}
                          onChange={handleChangeSex}
                        >
                          {newListSexs.map(sexL => (
                            <MenuItem key={sexL.sex} value={sexL.sex}>
                              {sexL.sex}
                            </MenuItem>
                          ))}
                        </TextField>
                        <ErrorMessage
                          name="sex_destination"
                          component={Error}
                        />
                      </Grid>
                      <Grid item xs={12} sm>
                        <TextField
                          name="breed_id_destination"
                          label="Raça"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          disabled
                          error={
                            !!errors.breed_id_destination &&
                            touched.breed_id_destination
                          }
                          value={breed}
                          onChange={handleChange}
                        >
                          {newListBreeds.map(item =>
                            item && item.secure_id ? (
                              <MenuItem key={item.secure_id} value={item.secure_id}>
                                {item.breed}
                              </MenuItem>
                            ) : (
                              ''
                            )
                          )}
                        </TextField>
                        <ErrorMessage
                          name="breed_id_destination"
                          component={Error}
                        />
                      </Grid>
                      <Grid item xs={12} sm>
                        <TextField
                          name="age_destination"
                          label="Idade"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          disabled
                          error={
                            !!errors.age_destination && touched.age_destination
                          }
                          value={age}
                          onChange={handleChangeAge}
                        >
                          {newListAges.map(ageD =>
                            ageD && ageD.name ? (
                              <MenuItem key={ageD.name} value={ageD.name}>
                                {ageD.name}
                              </MenuItem>
                            ) : (
                              ''
                            )
                          )}
                        </TextField>
                        <ErrorMessage
                          name="age_destination"
                          component={Error}
                        />
                      </Grid>
                    </StyledGrid>

                    <StyledGrid container justify="space-between" spacing={2}>
                      <Grid item xs={12} sm>
                        <TextField
                          name="type_destination"
                          label="Categoria"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          disabled
                          error={
                            !!errors.type_destination &&
                            touched.type_destination
                          }
                          value={type}
                          onChange={handleChangeType}
                        >
                          {newListTypes.map(typeD =>
                            typeD && typeD.name ? (
                              <MenuItem key={typeD.name} value={typeD.name}>
                                {typeD.name}
                              </MenuItem>
                            ) : (
                              ''
                            )
                          )}
                        </TextField>
                        <ErrorMessage
                          name="type_destination"
                          component={Error}
                        />
                      </Grid>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          name="amount_animals_destination"
                          label="Qtd. Animais"
                          variant="outlined"
                          size="small"
                          disabled
                          autoComplete="nope"
                          required
                          fullWidth
                          allowNegative={false}
                          isAllowed={val => {
                            const { formattedValue, floatValue } = val;
                            return (
                              formattedValue === '' ||
                              floatValue <= Number(inputTotalAmount)
                            );
                          }}
                          error={
                            !!errors.amount_animals_destination &&
                            touched.amount_animals_destination
                          }
                          is
                          value={values.amount_animals}
                          setFieldValue={setFieldValue}
                          onChange={event =>
                            handleChangeAmount(event, setFieldValue)
                          }
                        />
                        <ErrorMessage
                          name="amount_animals_destination"
                          component={Error}
                        />
                      </Grid>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          name="weight_destination"
                          label="Peso Médio Vivo"
                          decimalScale={2}
                          fixedDecimalScale
                          required
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">Kg</InputAdornment>
                            ),
                          }}
                          error={
                            !!errors.weight_destination &&
                            touched.weight_destination
                          }
                          value={
                            values.weight / values.amount_animals_destination
                          }
                        />
                        <ErrorMessage
                          name="weight_destination"
                          component={Error}
                        />
                      </Grid>
                    </StyledGrid>
                  </>
                ) : (
                  ''
                )}

                <StyledDividerTitle light />

                <WrapperTitle>
                  <span>Origem</span>
                </WrapperTitle>
                <StyledGrid
                  container
                  justify="space-between"
                  spacing={1}
                  marginTop={0}
                >
                  <Grid item xs={12} sm>
                    <StyledTypography>Antes</StyledTypography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          label="Qtd. Animais no Lote"
                          disabled
                          value={initOriginAmountLot}
                        />
                      </Grid>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          label="Qtd. Animais no Piquete"
                          disabled
                          value={initOriginAmountPaddock}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <ContentDivider>
                    <StyledDivider light orientation="vertical" flexItem />
                  </ContentDivider>

                  <Grid item xs={12} sm>
                    <StyledTypography>Depois</StyledTypography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          label="Qtd. Animais no Lote"
                          disabled
                          value={originAmountLot}
                        />
                      </Grid>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          label="Qtd. Animais no Piquete"
                          disabled
                          value={originAmountPaddock}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </StyledGrid>
                <StyledGrid container justify="space-between" spacing={2}>
                  <Grid item xs={12} sm>
                    <NumberFormatForm
                      label="Capacidade"
                      disabled
                      InputLabelProps={{
                        shrink:
                          currentOriginLot &&
                          currentOriginLot.paddock &&
                          currentOriginLot.paddock.amount_support !== null,
                      }}
                      value={
                        currentOriginLot &&
                        currentOriginLot.paddock &&
                        currentOriginLot.paddock.amount_support
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm />
                  <ContentDivider>
                    <StyledDivider
                      color={colors.transparent}
                      orientation="vertical"
                      flexItem
                    />
                  </ContentDivider>
                  <Hidden only="xs">
                    <Grid item sm />
                    <Grid item sm />
                  </Hidden>
                </StyledGrid>

                <Divider light />

                <WrapperTitle container>
                  <span>Destino</span>
                </WrapperTitle>

                <StyledGrid
                  container
                  justify="space-between"
                  spacing={1}
                  marginTop={0}
                >
                  <Grid item xs={12} sm>
                    <StyledTypography>Antes</StyledTypography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          label="Qtd. Animais no Lote"
                          disabled
                          value={initDestAmountLot}
                        />
                      </Grid>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          label="Qtd. Animais no Piquete"
                          disabled
                          value={initDestAmountPaddock}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <ContentDivider>
                    <StyledDivider light orientation="vertical" flexItem />
                  </ContentDivider>

                  <Grid item xs={12} sm>
                    <StyledTypography>Depois</StyledTypography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          label="Qtd. Animais no Lote"
                          disabled
                          value={destAmountLot}
                        />
                      </Grid>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          label="Qtd. Animais no Piquete"
                          disabled
                          value={destAmountPaddock}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </StyledGrid>
                <StyledGrid container justify="space-between" spacing={2}>
                  <Grid item xs={12} sm>
                    <NumberFormatForm
                      label="Capacidade"
                      disabled
                      InputLabelProps={{
                        shrink:
                          currentDestinationLot &&
                          currentDestinationLot.paddock &&
                          currentDestinationLot.paddock.amount_support !== null,
                      }}
                      value={
                        currentDestinationLot &&
                        currentDestinationLot.paddock &&
                        currentDestinationLot.paddock.amount_support
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm />
                  <ContentDivider>
                    <StyledDivider
                      color={colors.transparent}
                      orientation="vertical"
                      flexItem
                    />
                  </ContentDivider>
                  <Hidden only="xs">
                    <Grid item sm />
                    <Grid item sm />
                  </Hidden>
                </StyledGrid>

                <ContentSubmitButtons>
                  <ButtonSubmit title="Cadastrar" loading={isSubmitting} />

                  <StyledSubmitButton
                    variant="outlined"
                    color="primary"
                    onClick={handleBack}
                  >
                    Cancelar
                  </StyledSubmitButton>
                </ContentSubmitButtons>

                <CalcTotalAmount />
              </Form>
            )}
          </Formik>
        </StyledPaper>
      </Container>
    </center>
  );
}

Register.defaultProps = {
  match: null,
};

Register.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

export async function getApiV2IndividualIntermediateWeight(page = 1, limit = 50, date, lot, field, direction) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/animal-handling/weightCorrectionIndividual`, {
      params: {
        page,
        limit,
        date,
        lot,
        field,
        direction,
      },
    });

    return {
      intermediateWeight: data.data,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    }
  } catch {
    toast.error('Não foi possível carregar Pesagens!');
  }
}

export function useApiV2IndividualIntermediateWeight(page, limit, date, lot, field, direction) {
  return useQuery(
    ['intermediateWeightIndividual', page, limit, date, lot, field, direction],
    () => getApiV2IndividualIntermediateWeight(page, limit, date, lot, field, direction),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  );
}

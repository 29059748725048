import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Backdrop, Fade } from '@material-ui/core';

import { Container } from './styles';

import FoodRegister from '~/pages/Food/Register';

export default function ModalFood({ visibleModal, handleModal }) {
  return (
    <Modal
      open={visibleModal}
      onClose={handleModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visibleModal}>
        <Container>
          <FoodRegister closeModal={handleModal} isModal />
        </Container>
      </Fade>
    </Modal>
  );
}

ModalFood.propTypes = {
  visibleModal: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Hidden, Button, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

// Query
import { useApiV2GlobalFood } from '~/hooks/apiV2/global/food/useApiV2GlobalFood';

import { Container, StyledPaper, StyledButton } from './styles';

import DatePicker from '~/components/DatePicker';
import SmallLoader from '~/components/SmallLoader';

export default function Filter({
  foodId, foodName, setFilters
}) {
  const [openFood, setOpenFood] = useState(false);
  const [listFoods, setListFoods] = useState([]);

  const [currentFood, setCurrentFood] = useState(null);
  const [initialDate, setInitialDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [finalDate, setFinalDate] = useState(new Date());

  // Query
  const { data: foodsData, isLoading } = useApiV2GlobalFood();

  function handleOpenFood() {
    setOpenFood(true);
  }

  function handleSelectFood(food) {
    setCurrentFood(food);
  }

  function handleGenerate() {
    setFilters({
      initialDate,
      finalDate,
      currentFood
    });
  }

  useEffect(() => {
    if (foodsData && foodsData.length > 0) {
      const newList = foodsData.map(itemFood => ({
        secure_id: itemFood.secure_id,
        value: itemFood.name,
        name: itemFood.name,
      }));

      setListFoods(newList);
    }
  }, [foodsData]);

  useEffect(() => {
    if (foodId && foodName) {
      setCurrentFood({
        secure_id: foodId,
        value: foodName,
        text: foodName,
      });
    }
  }, [foodId, foodName]);

  return (
    <Container>
      <StyledPaper>
        <Grid
          container
          spacing={2}
          justify="space-around"
          alignItems="flex-end"
        >
          <Grid item xs={12} sm style={{ paddingBottom: 16 }}>
            <Autocomplete
              name="food"
              size="small"
              noOptionsText="Sem opções"
              options={listFoods}
              getOptionLabel={option => option.value}
              value={currentFood}
              loading={isLoading}
              open={openFood}
              onOpen={handleOpenFood}
              onClose={() => {
                setOpenFood(false);
              }}
              onChange={(event, value) => handleSelectFood(value)}
              renderInput={params => (
                <TextField
                  {...params}
                  name="food_id"
                  label="Alimento"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading && <SmallLoader />}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm>
            <DatePicker
              label="Data Inicial"
              selectedDate={initialDate}
              maxDate={finalDate}
              fullWidth
              handleChangeDate={date => setInitialDate(date)}
            />
          </Grid>
          <Grid item xs={6} sm>
            <DatePicker
              label="Data Final"
              selectedDate={finalDate}
              minDate={initialDate}
              fullWidth
              handleChangeDate={date => setFinalDate(date)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} alignItems="center" justify="space-around">
          <Hidden only="xs">
            <Grid item sm />
            <Grid item sm />
          </Hidden>
          <Grid item xs={12} sm>
            <Hidden only="xs">
              <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                disabled={!currentFood}
                onClick={handleGenerate}
              >
                Gerar
              </StyledButton>
            </Hidden>

            <Hidden smUp>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={!currentFood}
                onClick={handleGenerate}
              >
                Gerar
              </Button>
            </Hidden>
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

Filter.defaultProps = {
  location: null,
};

Filter.propTypes = {
  foodId: PropTypes.string,
  foodName: PropTypes.string,
  setFilters: PropTypes.shape({
    initialDate: PropTypes.string.isRequired,
    finalDate: PropTypes.string.isRequired,
    currentFood: PropTypes.string.isRequired
  }),
  location: PropTypes.shape({
    state: PropTypes.shape({
      food_name: PropTypes.string.isRequired,
    }),
  }),
};

import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

export async function getData() {
  try {
    const { data } = await apiV2.get('/v1/feedlot/food-management/msBeat');

    return data
  } catch {
    toast.error('Não foi possível carregar os dados!');
  }
}

export function useApiV2MsBeat() {
  return useQuery(['msBeat'],
    () => getData());
}

import styled from 'styled-components';
import {
  Paper,
  Button,
  TableContainer,
  TableHead,
  TableCell,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  padding: 0px 40px 0 40px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 22px;
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

export const ContentAnimals = styled.div`
  width: 100%;
  display: flex;
  margin-top: 16px;

  @media(max-width: 940px) {
    flex-direction: column-reverse;
  }
`;

import React, { useState, useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Query
import { useV2GlobalDiets } from '~/hooks/apiV2/global/diet/useV2GlobalDiets';

import { Container, StyledPaper, StyledButton } from './styles';

import DatePicker from '~/components/DatePicker';

export default function Filter({
  setFilters
}) {
  const [date, setDate] = useState(new Date());
  const [dietId, setDietId] = useState(null);

  // Query
  const { data: dietsData } = useV2GlobalDiets();

  function cleanState() {
    setDietId(null);
  }

  async function handleGenarateReport() {
    setFilters({
      date,
      diet: dietId
    });
  }

  useEffect(() => {
    if (dietsData) {
      cleanState();
    }
  }, [dietsData])

  return (
    <Container>
      <StyledPaper>
        <Grid container spacing={2} justify="space-around">
          <Grid item xs={12} sm>
            <FormControl size="small" fullWidth>
              <InputLabel id="input-module">Dieta</InputLabel>
              <Select
                id="selectDiet"
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                defaultValue={null}
                onChange={e => setDietId(e.target.value)}
              >
                {dietsData && dietsData.diets.map(diet => (
                  <MenuItem key={diet.value} value={diet.value}>
                    {diet.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs sm>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Data"
                selectedDate={date}
                maxDate={new Date()}
                fullWidth
                handleChangeDate={newDate => setDate(newDate)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs sm>
            <StyledButton
              variant="contained"
              color="primary"
              fullWidth
              disabled={!dietId}
              onClick={handleGenarateReport}
            >
              Gerar
            </StyledButton>
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";

export async function getData(secure_id) {
  const { data } = await apiV2.get(`/v1/feedlot/food-management/manufacturingFeed/${secure_id}`);

  return data
}

export function useApiV2SelectManufacturingFeed(secure_id) {
  return useQuery(['manufactoringFeed', secure_id],
    () => getData(secure_id));
}

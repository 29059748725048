import styled, { keyframes, css } from 'styled-components';
import { Paper, List, ListItem, Avatar, Grid } from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 1600px;
  width: 100%;
  padding: 20px 40px 0px;

  height: calc(100vh - 84px);

  text-align: left;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;

  h5 {
    font-weight: 400;
    color: ${colors.darker};
  }
`;

export const Cards = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const scale = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
`;

export const Card = styled(Paper)`
  position: relative;
  height: 130px;
  width: 100%;
  padding: 10px 20px 26px;
  background: ${props => props.background};

  ${props =>
    props.menu &&
    css`
      padding: 10px 20px;
    `}

  display: flex;
  flex-direction: column;
  justify-content: center;

  h6 {
    font-size: 16px;
    font-weight: 400;

    color: ${props => props.color || colors.grey};
  }

  &:hover {
    animation: ${scale} 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
`;

export const RowCard = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  justify-content: space-between;

  h4 {
    font-weight: 400;
    color: ${props => props.color};
  }

  div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: ${props => props.backgroundIcon || colors.primary};

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${props => props.colorIcon || colors.white};
    }
  }
`;
//  height: 279px;
export const StyledPaperMedium = styled(Paper)`
  height: 100%;
  margin-bottom: 16px;
  overflow-y: auto;
`;

export const StyledList = styled(List)`
  padding-bottom: 0px;
`;

export const StyledListItemInfo = styled(ListItem)`
  padding-top: 0px;
  padding-bottom: 0px;
`;

const shake = keyframes`
  0%,
  100% {
    transform: translateY(0) rotate(0);
    transform-origin: 50% 50%;
  }
  15% {
    transform: translateY(-10px) rotate(-6deg);
  }
  30% {
    transform: translateY(5px) rotate(6deg);
  }
  45% {
    transform: translateY(-5px) rotate(-3.6deg);
  }
  60% {
    transform: translateY(4px) rotate(2.4deg);
  }
  75% {
    transform: translateY(-3px) rotate(-1.2deg);
  }
`;

export const Alert = styled.div`
  position: absolute;
  top: 5px;
  left: 67px;

  animation: ${shake} 0.8s infinite both;
`;

export const StyledDivGrid = styled.div`
  height: 200px;
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(6, 1fr);

  .alerts {
    grid-area: span 2 / span 2 / span 2 / span 2;
  }
  .cards {
    grid-column: span 2 / span 2;
  }
  .infos {
    grid-column: span 4 / span 4;
  }
`

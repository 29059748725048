import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

// Query
import { useApiV2GlobalPaddock } from '~/hooks/apiV2/global/paddock/useApiV2GlobalPaddock';

import { Container, StyledPaper, StyledIconButton } from './styles';

import { regexDate } from '~/utils/common';
import { formatNamePaddock } from '~/utils/format';
import InputDatePicker from '~/components/InputDatePicker';

const paddockDefaultOption = {
  id: null,
  name: 'Todos',
};

export default function TransferLotFilter({
  date,
  setDate,
  setOriginPaddock,
  setDestinationPaddock,
  settings
}) {
  const [paddocks, setPaddocks] = useState([]);

  // Query
  const { data: paddocksData } = useApiV2GlobalPaddock();

  function handleChangeDate(newDate, value) {
    if (value && !regexDate.test(value)) return;

    setDate(newDate);
  }

  function handleChangeOriginLot(event) {
    setOriginPaddock(event.target.value.secure_id);
  }

  function handleChangeDestinationLot(event) {
    setDestinationPaddock(event.target.value.secure_id);
  }

  useEffect(() => {
    if (paddocksData) {
      setPaddocks(paddocksData.paddocks);
    }
  }, [paddocksData]);

  return (
    <Container>
      <StyledPaper>
        <Grid container justify="space-between" spacing={2}>
          <Grid item sm xs>
            <FormControl size="small" fullWidth>
              <InputLabel>Piquete de Origem</InputLabel>
              <Select
                defaultValue={paddockDefaultOption}
                onChange={handleChangeOriginLot}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value={paddockDefaultOption}>
                  {paddockDefaultOption.name}
                </MenuItem>
                {paddocks.map(paddock => (
                  <MenuItem key={paddock.secure_id} value={paddock}>
                    {settings && formatNamePaddock(settings, paddock)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item sm xs>
            <FormControl size="small" fullWidth>
              <InputLabel>Piquete de Destino</InputLabel>
              <Select
                defaultValue={paddockDefaultOption}
                onChange={handleChangeDestinationLot}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value={paddockDefaultOption}>
                  {paddockDefaultOption.name}
                </MenuItem>
                {paddocks.map(paddock => (
                  <MenuItem key={paddock.secure_id} value={paddock}>
                    {formatNamePaddock(settings, paddock)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item sm xs={12}>
            <InputDatePicker
              label="Data Inicial"
              maxDate={new Date()}
              selectedDate={date}
              handleChangeDate={(currentDate, value) =>
                handleChangeDate(currentDate, value)
              }
              InputProps={{
                startAdornment: (
                  <StyledIconButton onClick={() => setDate(null)}>
                    <ClearIcon />
                  </StyledIconButton>
                ),
              }}
              InputAdornmentProps={{
                position: 'end',
              }}
            />
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

TransferLotFilter.defaultProps = {
  date: null,
};

TransferLotFilter.propTypes = {
  date: PropTypes.string,
  setDate: PropTypes.func.isRequired,
  setOriginPaddock: PropTypes.func.isRequired,
  setDestinationPaddock: PropTypes.func.isRequired,
  settings: PropTypes.object
};

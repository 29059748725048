import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2"
import { formatDateString } from "~/utils/format";

export async function getFoodCorrections(sort, page = 1, limit = 50, foodSecureId, date) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/admin/foodCorrection?sort=${sort}`, {
      params: {
        page,
        limit,
        foodSecureId,
        date
      }
    })

    const newData = data.data.map(item => ({
      ...item,
      formattedDate: formatDateString(item.date),
    }));

    return {
      foodCorrections: newData,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    }
  } catch {
    toast.error('Falha para carregar os dados das correções de estoque!');
  }
}

export function useApiV2FoodCorrection(sort, page, limit, foodSecureId, date) {
  return useQuery(
    ['foodCorrection', sort, page, limit, foodSecureId, date],
    () => getFoodCorrections(sort, page, limit, foodSecureId, date),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

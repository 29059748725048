import { useQuery } from "react-query";
import apiV2 from '~/services/apiV2';

async function getV2GetDataForPrintManufactoring(currentDiets, currentDate) {
  const { data } = await apiV2.get(`v1/feedlot/food-management/manufacturing-diet-data-print`, {
    params: {
      currentDiets,
      currentDate
    }
  })

  return data
}

export function useV2GetDataForPrintManufactoring(currentDiets, currentDate) {
  return useQuery(
    ['V2GetDataForPrintManufactoring', currentDiets, currentDate],
    () => getV2GetDataForPrintManufactoring(currentDiets, currentDate))
}

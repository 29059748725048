import React from 'react';

import {
  TableCell,
  TableRow
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import ButtonTable from '~/components/ButtonTable';

import ActionLoader from '~/components/ActionLoader';
import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';
import { formatCpf, formatTel } from '~/utils/format';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';

export function CardTableUser({ user }) {
  function handleSelect(secureId) {
    history.push(`/dashboard/users/edit/${secureId}`);
  }

  return (
    <TableRow>
      <TableCell scope="row">{user.name}</TableCell>

      <StyledTableCellEllipsis>
        {user.email}
      </StyledTableCellEllipsis>

      <Can
        checkRole={['administrator', 'owner', 'beefer']}
        checkPermission={['user_edit', 'user_delete']}
      >
        <StyledTableCell align="right">
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['user_edit']}
          >
            <ButtonTable
              icon={<EditIcon color="primary" />}
              background={colors.primary}
              lighten={0.6}
              onClick={() => handleSelect(user.secure_id)}
            />
          </Can>
        </StyledTableCell>
      </Can>
    </TableRow>
  )
}

import React from 'react';
import Swal from 'sweetalert2';

import {
  TableRow,
  TableCell,
  Tooltip,
  Fade
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { useApiV2RemoveModule } from '~/hooks/apiV2/register/module/useApiV2RemoveModule'

import ActionLoader from '~/components/ActionLoader';
import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell,
} from './styles';

export function CardTableModule({ module }) {
  // Query
  const removeModule = useApiV2RemoveModule();

  function hasTreatment({ has_treatments, has_lots, has_trough_readings, has_ocurrences }) {
    return has_treatments > 0 || has_lots > 0 || has_trough_readings > 0 || has_ocurrences > 0
  }

  async function handleEdit({ secure_id }) {
    history.push(`/dashboard/module/edit/${secure_id}`);
  }

  function handleDelete({ secure_id }) {
    async function performRemoveModule() {
      await removeModule.mutateAsync(secure_id)
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar esse módulo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar módulo!',
    }).then(result => {
      if (result.value) {
        performRemoveModule();
      }
    });
  }

  return (
    <TableRow key={module.secure_id}>
      <TableCell component="th" scope="row">
        {module.name}
      </TableCell>
      <TableCell align="right">
        {module.lines_count}
      </TableCell>
      <TableCell align="right">
        {module.paddocks_count}
      </TableCell>
      <Can
        checkRole={['administrator', 'owner', 'beefer']}
        checkPermission={['module_edit', 'module_delete', 'module_view']}
      >
        <StyledTableCell align="right" colSpan={2}>
          {!removeModule.isLoading && (
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['module_edit']}
            >
              {can => can ? (
                <ButtonTable
                  icon={<EditIcon color="primary" />}
                  background={colors.primary}
                  lighten={0.6}
                  onClick={() => handleEdit(module)}
                />
              ) : (
                <ButtonTable
                  icon={<VisibilityIcon />}
                  background={colors.dark}
                  lighten={0.5}
                // onClick={() => handleView(module)}
                />
              )}
            </Can>
          )}
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['module_delete']}
          >
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title="O piquete já foi usado em tratos, lotes ou leituras de cocho"
              disableHoverListener={!hasTreatment(module)}
            >
              <span>
                {removeModule.isLoading ? (
                  <ActionLoader text="Deletando" />
                ) : (
                  <ButtonTable
                    icon={
                      <DeleteIcon
                        color={
                          hasTreatment(module)
                            ? 'inherit'
                            : 'error'
                        }
                      />
                    }
                    background={
                      hasTreatment(module)
                        ? colors.grey
                        : colors.error
                    }
                    disabled={hasTreatment(module)}
                    onClick={() => handleDelete(module)}
                  />
                )}
              </span>
            </Tooltip>
          </Can>
        </StyledTableCell>
      </Can>
    </TableRow>
  )
}

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Fade,
  Badge,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
// import MailIcon from '@material-ui/icons/Mail';

import CheckIcon from '@material-ui/icons/Check';
import { pt } from 'date-fns/locale';

import {
  StyledAppBar,
  StyledToolbar,
  Row,
  StyledButton,
  StyledIconButton,
  WrapperTitle,
  StyledMenu,
  StyledMenuItem,
} from './styles';

import history from '~/services/history';
import Can from '~/components/Can';

// Query
import { useApiV2Notification } from '~/hooks/apiV2/user/notification/useApiV2Notification';
import { useApiV2EditNotification } from '~/hooks/apiV2/user/notification/useApiV2EditNotification';

import { useAuthContext } from '~/contexts/AuthContext';
import { queryClient } from '~/services/queryClient';

const { parseISO, format } = require('date-fns');

function Header({ handleDrawer, visibleDrawer, drawerWidth, location }) {
  const { handleSetFarm, farms, signOut, user } = useAuthContext()

  const splitTitle = location.pathname.split('/');
  const routeMaster = splitTitle[2];
  const routeMasterSubMenu = splitTitle[3];
  const subRoute = splitTitle[splitTitle.length - 2];
  const routeName = splitTitle[splitTitle.length - 1];

  //Query
  const { data: notifications } = useApiV2Notification();
  const editNotification = useApiV2EditNotification();

  const farmData = JSON.parse(localStorage.getItem('@BeefSystem:farm'));

  const [anchorElFarm, setAnchorElFarm] = useState(null);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [title, setTitle] = useState('');

  const menuUser = [
    {
      id: 0,
      content: 'Perfil',
      link: '/dashboard/profile',
      icon: <PersonIcon fontSize="small" />,
      disabled: false,
    },
    {
      id: 1,
      content: 'Configurações',
      link: '/dashboard/setting',
      icon: <SettingsIcon fontSize="small" />,
      disabled: false,
    },
    // {
    //   id: 2,
    //   content: 'Convidar',
    //   link: '/dashboard/invite',
    //   icon: <MailIcon fontSize="small" />,
    //   disabled: false,
    // },
    {
      id: 3,
      content: 'Sair',
      link: '/',
      icon: <ExitToAppIcon fontSize="small" />,
      disabled: false,
    },
  ];

  function handleClickMenuUser(event) {
    setAnchorElUser(event.currentTarget);
  }

  async function handleCloseMenuUser(item) {
    if (item) {
      if (item.id === 3) {
        await signOut()
        queryClient.clear()
      }

      if (item.link) {
        history.push(item.link);
      }
    }

    setAnchorElUser(null);
  }

  function handleClickMenuFarm(event) {
    setAnchorElFarm(event.currentTarget);
  }

  async function handleCloseMenuFarm(farm) {
    if (farm) {
      await handleSetFarm(farm);
    }

    setAnchorElFarm(null);
  }

  function handleClickMenuNotifications(event) {
    setAnchorElNotifications(event.currentTarget);
  }

  function handleCloseMenuNotifications(notificationId, notificationViewed) {
    async function performEditNotification() {
      await editNotification.mutateAsync({ id: notificationId, viewed: true });
    }
    if (notificationId && !notificationViewed) {
      performEditNotification();
    }
    setAnchorElNotifications(null);
  }

  useEffect(() => {
    switch (routeMaster) {
      case 'farm': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Fazenda');
          } else {
            setTitle('Editar Fazenda');
          }
        } else {
          setTitle('Fazendas');
        }

        break;
      }
      case 'animalInput': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Entrada de Animais');
          } else {
            setTitle('Editar Entrada de Animais');
          }
        } else {
          setTitle('Entradas de Animais');
        }

        break;
      }
      case 'animalTransferLot': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Transf. de Animais por Lote');
          } else {
            setTitle('Editar Transf. de Animais por Lote');
          }
        } else {
          setTitle('Transf. de Animais por Lote');
        }

        break;
      }
      case 'animalTransferPaddock': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Transf. de Animais por Piquete');
          } else {
            setTitle('Editar Transf. de Animais por Piquete');
          }
        } else {
          setTitle('Transf. de Animais por Piquete');
        }

        break;
      }
      case 'animalOutput': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Saídas de Animais');
          } else {
            setTitle('Editar Saída de Animais');
          }
        } else {
          setTitle('Saídas de Animais');
        }

        break;
      }
      case 'animalScrapOutput': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Refugo de Cocho');
          } else {
            setTitle('Editar Refugo de Cocho');
          }
        } else {
          setTitle('Refugo de Cocho');
        }

        break;
      }
      case 'animalDeathOutput': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Morte de Animais');
          } else {
            setTitle('Editar Morte de Animais');
          }
        } else {
          setTitle('Morte de Animais');
        }

        break;
      }
      case 'food': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Alimento');
          } else {
            setTitle('Editar Alimento');
          }
        } else {
          setTitle('Alimentos');
        }
        break;
      }
      case 'feed': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Concentrado/Ração');
          } else {
            setTitle('Editar Concentrado/Ração');
          }
        } else {
          setTitle('Concentrados/Rações');
        }

        break;
      }
      case 'manufactoringFeed': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Fabricação de Mistura/Ração');
          } else {
            setTitle('Editar Fabricação de Mistura/Ração');
          }
        } else {
          setTitle('Fabricações de Mistura/Ração');
        }

        break;
      }
      case 'manufactoringDiet': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Fabricação de Dieta');
          } else {
            setTitle('Editar Fabricação de Dieta');
          }
        } else {
          setTitle('Fabricações de Dieta');
        }

        break;
      }
      case 'dietSwitch': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Troca de Dieta');
          } else {
            setTitle('Editar Troca de Dieta');
          }
        } else {
          setTitle('Trocas de Dieta');
        }

        break;
      }
      case 'diet': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Dieta');
          } else if (subRoute === 'edit') {
            setTitle('Editar Dieta');
          } else {
            setTitle('Dieta');
          }
        } else {
          setTitle('Dietas');
        }

        break;
      }
      case 'troughReading': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Leitura de Cocho');
          } else if (routeName === 'dietSwitch') {
            setTitle('Trocas de dieta');
          } else {
            setTitle('Editar Leitura de Cocho');
          }
        } else {
          setTitle('Leituras de Cocho');
        }
        break;
      }
      case 'treatmentForecast': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Previsão de Trato');
          } else {
            setTitle('Editar Previsão de Trato');
          }
        } else {
          setTitle('Previsões de Trato');
        }
        break;
      }
      case 'treatmentPerformed': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Realização de Trato');
          } else {
            setTitle('Editar Realização de Trato');
          }
        } else {
          setTitle('Realizações de Trato');
        }
        break;
      }
      case 'stakeholder': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Cliente/Fornecedor');
          } else {
            setTitle('Editar Cliente/Fornecedor');
          }
        } else {
          setTitle('Clientes/Fornecedores');
        }
        break;
      }
      case 'breed': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Raça');
          } else {
            setTitle('Editar Raça');
          }
        } else {
          setTitle('Raças');
        }
        break;
      }
      case 'module': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Módulo');
          } else {
            setTitle('Editar Módulo');
          }
        } else {
          setTitle('Módulos');
        }
        break;
      }
      case 'msBeat': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Batidas de Dieta/Ração');
          } else {
            setTitle('Editar Batidas de Dieta/Ração');
          }
        } else {
          setTitle('Batidas de Dieta/Ração');
        }
        break;
      }
      case 'msCorrection': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Correção de MS');
          } else {
            setTitle('Editar Correção de MS');
          }
        } else {
          setTitle('Correções de MS');
        }
        break;
      }
      case 'treatySetting': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Distribuição de Trato');
          } else {
            setTitle('Editar Distribuição de Trato');
          }
        } else {
          setTitle('Distribuição de Tratos');
        }
        break;
      }
      case 'troughSetting': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Config. de Leitura de Cocho');
          } else {
            setTitle('Editar Config. de Leitura de Cocho');
          }
        } else {
          setTitle('Config. de Leitura de Cocho');
        }
        break;
      }
      case 'causeOfDeath': {
        if (routeMaster !== routeName) {
          if (routeName === 'register') {
            setTitle('Cadastrar Causa da Morte');
          } else {
            setTitle('Editar Causa da Morte');
          }
        } else {
          setTitle('Causas das Mortes');
        }
        break;
      }
      case 'purchaseFoods': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Compra de Alimentos');
          } else {
            setTitle('Editar Compra de Alimentos');
          }
        } else {
          setTitle('Compras de Alimentos');
        }
        break;
      }
      case 'operationalCost': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Custo Operacional');
          } else {
            setTitle('Editar Custo Operacional');
          }
        } else {
          setTitle('Custos Operacionais');
        }
        break;
      }
      case 'sanitaryCost': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Custo Sanitário');
          } else {
            setTitle('Editar Custo Sanitário');
          }
        } else {
          setTitle('Custos Sanitários');
        }
        break;
      }
      case 'foodCorrection': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Correção de Estoque');
          }
        } else {
          setTitle('Correções de Estoque');
        }
        break;
      }
      case 'employee': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Funcionário');
          } else {
            setTitle('Editar Funcionário');
          }
        } else {
          setTitle('Funcionários');
        }
        break;
      }
      case 'reportActiveLot': {
        setTitle('Lotes Ativos');
        break;
      }
      case 'reportActivePaddock': {
        setTitle('Piquetes Ativos');
        break;
      }
      case 'lots': {
        if (routeMaster !== routeName) {
          if (routeName === 'detail') {
            setTitle('Matriz diária');
          }
        } else {
          setTitle('Lotes');
        }
        break;
      }
      case 'reportActiveLotBoitel': {
        setTitle('Lotes Ativos - Boitel');
        break;
      }
      case 'reportConsumptionLot': {
        setTitle('Curva de Consumo de Lote');
        break;
      }
      case 'reportEfficiencyTreatment': {
        setTitle('Eficiência de Tratos');
        break;
      }
      case 'reportDietManufacturingEfficiency': {
        setTitle('Eficiência de Fabricações de Dieta');
        break;
      }
      case 'reportAnimalOutput': {
        setTitle('Fechamento por Saídas');
        break;
      }
      case 'reportZootechnicalLot': {
        setTitle('Fechamento Zootécnico Analítico');
        break;
      }
      case 'reportEconomicClosing': {
        setTitle('Fechamento Econômico');
        break;
      }
      case 'reportSummaryFood': {
        setTitle('Resumo de Alimentos');
        break;
      }
      case 'reportHistoricFood': {
        setTitle('Histórico de Alimentos');
        break;
      }
      case 'reportInput': {
        setTitle('Estoque de Insumos');
        break;
      }
      case 'profile': {
        setTitle('Perfil');
        break;
      }
      case 'animalInputUnity': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Adicionar animal');
          }
        } else {
          setTitle('Entrada de animais');
        }
        break;
      }
      case 'animalInputApart': {
        setTitle('Formação de lote');
        break;
      }
      case 'setting': {
        setTitle('Configurações');
        break;
      }
      case 'invite': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Convites');
          }
        } else {
          setTitle('Convites');
        }
        break;
      }
      case 'animals': {
        setTitle('Animais');
        break;
      }
      case 'ocurrencesType': {
        setTitle('Tipos de ocorrências');
        break;
      }
      case 'ocurrences': {
        setTitle('Ocorrências');
        break;
      }
      case 'actions_logs_view': {
        setTitle('Log de ações');
        break;
      }
      case 'individualAnimalTransferLot': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Transf. de Animais por lote');
          } else {
            setTitle('Editar Transf. de Animais por lote');
          }
        } else {
          setTitle('Transf. de Animais por lote');
        }

        break;
      }
      case 'individualAnimalOutput': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Saída de Animais');
          } else {
            setTitle('Editar Saída de Animais');
          }
        } else {
          setTitle('Saída de Animais');
        }

        break;
      }
      case 'individualAnimalOutputGta': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Formação de Embarque');
          } else {
            setTitle('Editar Formação de Embarque');
          }
        } else {
          setTitle('Formação de Embarque');
        }

        break;
      }
      case 'individualAnimalScrapOutput': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Refugo de Cocho');
          } else {
            setTitle('Editar Refugo de Cocho');
          }
        } else {
          setTitle('Refugo de Cocho');
        }

        break;
      }
      case 'individualAnimalDeathOutput': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Morte de Animais');
          } else {
            setTitle('Editar Morte de Animais');
          }
        } else {
          setTitle('Morte de Animais');
        }

        break;
      }
      case 'users': {
        if (routeMaster !== routeName) {
          if (routeName === 'add') {
            setTitle('Cadastrar Usuário');
          } else {
            setTitle('Editar Usuário');
          }
        } else {
          setTitle('Usuários');
        }
        break;
      }
      default:
        setTitle('Dashboard');
        break;
    }
  }, [routeMaster, routeMasterSubMenu, routeName, splitTitle, subRoute]);

  // useEffect(() => {
  //   async function farmRequest() {
  //     if (farmData) {
  //       await handleSetFarm(farmData);
  //     }
  //   }
  //   farmRequest()
  // }, []);

  function checkPermissions() {
    let hasPermission = true;
    if (user.roles.includes('user')) {
      hasPermission = false;
    }
    return !hasPermission;
  }

  return (
    <StyledAppBar
      position="fixed"
      drawerwidth={drawerWidth}
      visible={visibleDrawer ? 1 : 0}
      color="primary"
      width={{
        sm: '100%',
        md: visibleDrawer ? `calc(100% - ${drawerWidth}px)` : '100%',
      }}
    >
      <StyledToolbar>
        <Row>
          <StyledIconButton
            color="secondary"
            onClick={handleDrawer}
            edge="start"
            visible={visibleDrawer ? 1 : 0}
          >
            <MenuIcon color="secondary" />
          </StyledIconButton>
          <WrapperTitle>
            <Typography variant="h6" color="secondary" noWrap display="block">
              {title}
            </Typography>
          </WrapperTitle>
        </Row>

        <Row>
          <StyledButton
            aria-controls="fade-menu"
            aria-haspopup="true"
            disabled={subRoute === 'edit'}
            onClick={handleClickMenuFarm}
          >
            {farmData && farmData.name}
          </StyledButton>

          {subRoute !== 'edit' && (
            <Menu
              id="fade-menu"
              anchorEl={anchorElFarm}
              keepMounted
              disableScrollLock
              open={Boolean(anchorElFarm)}
              onClose={() => handleCloseMenuFarm(null)}
              TransitionComponent={Fade}
              elevation={2}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {farms &&
                farms.map(farm => (
                  <MenuItem
                    key={farm.secureId}
                    color="secondary"
                    style={{ maxWidth: 500 }}
                    onClick={() => handleCloseMenuFarm(farm)}
                  >
                    <Typography variant="inherit" noWrap>
                      {farm.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Menu>
          )}

          <IconButton
            color="secondary"
            onClick={handleClickMenuNotifications}
            disabled={subRoute === 'edit'}
          >
            <Badge
              color="error"
              badgeContent={
                notifications &&
                notifications.filter(notification => !notification.viewed)
                  .length
              }
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>

          {subRoute !== 'edit' && (
            <StyledMenu
              id="fade-menu"
              anchorEl={anchorElNotifications}
              keepMounted
              disableScrollLock
              open={Boolean(anchorElNotifications)}
              onClose={() => handleCloseMenuNotifications(null)}
              TransitionComponent={Fade}
              elevation={2}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {notifications &&
                notifications.map((notification, idx) => (
                  <div>
                    <StyledMenuItem
                      key={notification.id}
                      color="secondary"
                      viewed={notification.viewed}
                      onClick={() =>
                        handleCloseMenuNotifications(
                          notification.id,
                          notification.viewed
                        )
                      }
                    >
                      <Typography variant="inherit" component="div" noWrap>
                        {notification.description}
                      </Typography>
                      <br />
                      <div id="footer">
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          {format(
                            parseISO(notification.date),
                            `d 'de' LLLL, yyyy`,
                            { locale: pt }
                          )}
                        </Typography>
                        {notification.viewed ? (
                          <div id="viewed">
                            <CheckIcon />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </StyledMenuItem>
                    {idx < notifications.length - 1 ? <Divider /> : ''}
                  </div>
                ))}
            </StyledMenu>
          )}

          <IconButton
            edge="end"
            aria-label="Conta de usuário"
            aria-haspopup="true"
            onClick={handleClickMenuUser}
            color="secondary"
          >
            <AccountCircle />
          </IconButton>

          <Menu
            id="fade-menu"
            anchorEl={anchorElUser}
            keepMounted
            disableScrollLock
            open={Boolean(anchorElUser)}
            onClose={() => handleCloseMenuUser()}
            TransitionComponent={Fade}
            elevation={2}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {menuUser.map(item => (
              <div key={item.id}>
                {item.id === 2 ? ( // Invites
                  <Can checkRole={['administrator', 'owner', 'manager']}>
                    <MenuItem
                      disabled={item.disabled}
                      onClick={() => handleCloseMenuUser(item)}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.content} color="secondary" />
                    </MenuItem>
                  </Can>
                ) : item.id === 1 ? ( // Settings (only normal user can't access)
                  <Can
                    checkRole={[
                      'administrator',
                      'owner',
                      'manager',
                      'technical',
                    ]}
                  >
                    <MenuItem
                      disabled={checkPermissions()}
                      onClick={() => handleCloseMenuUser(item)}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText primary={item.content} color="secondary" />
                    </MenuItem>
                  </Can>
                ) : (
                  <MenuItem // Always show the rest of the header menu options
                    disabled={item.disabled}
                    onClick={() => handleCloseMenuUser(item)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.content} color="secondary" />
                  </MenuItem>
                )}
              </div>
            ))}
          </Menu>
        </Row>
      </StyledToolbar>
    </StyledAppBar>
  );
}

export default withRouter(Header);

Header.propTypes = {
  handleDrawer: PropTypes.func.isRequired,
  visibleDrawer: PropTypes.bool.isRequired,
  drawerWidth: PropTypes.number.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

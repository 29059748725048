export function userHasPermission({
  user,
  roles,
  permissions
}) {
  if (permissions?.length > 0) {
    if (user.roles) {
      if (user.roles.includes('administrator') || user.roles.includes('owner') || user.roles.includes('beefer')) {
        return true
      }
    }
    const hasPermissions = permissions.some(permissions => {
      return user.permissions.includes(permissions)
    })

    if (!hasPermissions) {
      return false
    }
  }

  if (roles?.length > 0) {
    const hasRoles = roles.some(roles => {
      return user.roles.includes(roles)
    })

    if (!hasRoles) {
      return false
    }
  }
  return true
}

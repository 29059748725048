import { useQuery } from "react-query"
import apiV2 from '~/services/apiV2';

export async function getStakeholder(secure_id) {
  const { data } = await apiV2.get(`/v1/feedlot/register/stakeholder/${secure_id}`)

  return {
    currentStakeholder: data
  }
}

export function useApiV2SelectStakeholder(secure_id) {
  return useQuery(['stakeholders', secure_id], () => getStakeholder(secure_id))
}

import styled from 'styled-components';
import { TableCell } from '@material-ui/core';

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

export const StyledTableCellEllipsis = styled(TableCell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

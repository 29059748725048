import { Grid, TextField } from '@material-ui/core';
import { format } from 'date-fns';
import { ErrorMessage, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import ButtonSubmit from '~/components/ButtonSubmit';
import InputDatePicker from '~/components/InputDatePicker';
import Loader from '~/components/Loader';
import NumberFormatForm from '~/components/NumberFormatForm';
import { useV2GetSanitaryCostShow } from '~/hooks/apiV2/admin/sanitatyCost/useV2GetSanitaryCostShow';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';
import {
  Container, ContentItem,
  ContentSubmitButtons, Error, StyledPaper, StyledSubmitButton
} from './styles';

export default function Register({ match }) {
  const secure_id = useMemo(() => match && match.params.id, [match]);
  const { data, error, isLoading } = useV2GetSanitaryCostShow(secure_id)
  function handleBack() {
    history.push('/dashboard/sanitaryCost');
  }

  const editSanitaryCost = useMutation(async (data) => {
    return await apiV2.put(`v1/feedlot/admin/sanitary-cost/${secure_id}`, data);
  },
    {
      onSuccess: (response) => {
        queryClient.refetchQueries('sanitaryCost');
        toast.success(response.data?.message || 'O custo sanitário foi alterado com sucesso.')
        handleBack()
      },
      onError: (error) => {
        if (!!error.response.data?.type) {
          if (error.response.data?.type === 'warning') {
            toast.warning(error.response.data.message)
          } else {
            toast.error(error.response.data.message)
          }
        } else {
          toast.error('Falha ao tentar alterar o custo sanitário, verifique se preencheu todos os campos corretamente.')
        }
      }
    }
  );

  const schema = Yup.object().shape({
    initial_date: Yup.date()
      .typeError('Insira uma data válida')
      .required('Informe a data inicial'),
    final_date: Yup.date().typeError('Insira uma data válida').nullable(),
    cost: Yup.number()
      .typeError('Insira um custo válido')
      .max(9999999999.99, 'O valor deve ser menor que 9999999999.99')
      .positive('O custo deve ser positivo')
      .required('Informe o custo'),
    obs: Yup.string().nullable(),
  });

  async function handleSubmit(data) {
    const newData = {
      ...data,
      initial_date: format(new Date(data.initial_date), 'yyyy-MM-dd'),
    };
    await editSanitaryCost.mutateAsync(newData)
  }

  return (
    <center>
      <Container>
        {error ? (
          <p>{error?.response?.data?.message || 'Falha para carregar os dados do custo sanitário!'}</p>
        ) : isLoading ? (
          <Loader />
        ) : (
          <Formik
            enableReinitialize={true}
            validationSchema={schema}
            initialValues={{
              initial_date: data ? data.initial_date : new Date(),
              final_date: data ? data.final_date : null,
              cost: data ? data.cost : '',
              obs: data ? data.obs : '',
            }}
            onSubmit={handleSubmit}
          >
            {({ handleChange, setFieldValue, values, errors, touched }) => (
              <Form>
                <ContentItem>
                  <StyledPaper>
                    <Grid container spacing={2} direction="column">
                      <Grid item>
                        <Grid container spacing={2} direction="column">
                          <Grid item xs={12} sm="auto">
                            <InputDatePicker
                              name="initial_date"
                              label="Data Inicial"
                              inputVariant="outlined"
                              disabled={true}
                              selectedDate={values.initial_date}
                              handleChangeDate={() => { }}
                            />
                            <ErrorMessage
                              name="initial_date"
                              component={Error}
                            />
                          </Grid>
                          {secure_id && values.final_date && (
                            <Grid item xs={12} sm="auto">
                              <InputDatePicker
                                name="final_date"
                                label="Data Final"
                                inputVariant="outlined"
                                disabled
                                selectedDate={values.final_date}
                                handleChangeDate={() => { }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Grid container spacing={2} direction="column">
                          <Grid item xs={12} sm={4}>
                            <NumberFormatForm
                              name="cost"
                              label="Custo"
                              variant="outlined"
                              size="small"
                              fullWidth
                              autoComplete="nope"
                              prefix="R$"
                              required
                              autoFocus={!secure_id}
                              disabled={true}
                              fixedDecimalScale
                              decimalScale={2}
                              allowNegative={false}
                              isAllowed={val => {
                                const { formattedValue, floatValue } = val;
                                return (
                                  formattedValue === '' ||
                                  floatValue <= 9999999999.99
                                );
                              }}
                              error={!!errors.cost && touched.cost}
                              value={values.cost}
                              setFieldValue={setFieldValue}
                            />
                            <ErrorMessage name="cost" component={Error} />
                          </Grid>
                          <Grid item xs={12} sm>
                            <TextField
                              name="obs"
                              label="Observação"
                              variant="outlined"
                              size="small"
                              fullWidth
                              value={values.obs}
                              autoFocus={secure_id && values.final_date}
                              autoComplete="nope"
                              onChange={handleChange}
                            />
                            <ErrorMessage name="obs" component={Error} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <ContentSubmitButtons>
                      <ButtonSubmit title="Alterar" loading={editSanitaryCost.isLoading} />
                      <StyledSubmitButton
                        color="primary"
                        variant="outlined"
                        type="button"
                        onClick={handleBack}
                      >
                        Cancelar
                      </StyledSubmitButton>
                    </ContentSubmitButtons>
                  </StyledPaper>
                </ContentItem>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </center>
  );
}

Register.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

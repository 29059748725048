import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

import { formatDateOnly } from '~/utils/format';

export async function getTransferApartLot(sort, page = 1, limit = 50, date, lot, apart) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/animal-handling/individualTransferApartLot?sort=${sort}`, {
      params: { page, limit, date, lot, apart }
    });
    const newData = data.data.map(animalInput => ({
      ...animalInput,
      date: formatDateOnly(animalInput.date),
    }));

    return {
      animalsInputLotApart: newData,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    };
  } catch (error) {
    toast.error('Não foi possível Formação de lote!');
  }
}

export function useApiV2IndividualTransferApartLot(sort, page, limit, date, lot, apart) {
  return useQuery(
    ['animalInputLotApart', sort, page, limit, date, lot, apart],
    () => getTransferApartLot(sort, page, limit, date, lot, apart),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  );
}

import {
  Backdrop, Button,
  Checkbox,
  Divider, Fade, FormControlLabel, Grid, Modal, Typography
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import PrintIcon from '@material-ui/icons/Print';
import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { useV2GetDataForPrintManufactoring } from '~/hooks/apiV2/foodManagement/manufactoringDiet/useV2GetDataForPrintManufactoring';
import { useV2GlobalDiets } from '~/hooks/apiV2/global/diet/useV2GlobalDiets';
import { FormatDateForYearMonthDay } from '~/utils/format';
import { PrintManufactoringDiet } from './Print';

import {
  CloseButton, Container, ContentButton, StyledGrid, StyledPaper, ContentPrint
} from './styles';

export function ModalManufactoringDietPrint({
  visibleModal,
  handleModal,
  currentDate,
}) {
  const [dietSelectedSecureId, setDietSelectedSecureId] = useState([]);
  const { data } = useV2GlobalDiets()
  const { data: dataPrint } = useV2GetDataForPrintManufactoring(dietSelectedSecureId, !!currentDate ? FormatDateForYearMonthDay(currentDate) : null)

  const PrintScreen = useRef();

  function handleSelectDiet(diet) {
    const newDiet = dietSelectedSecureId.find(
      oldCurrentDiet => oldCurrentDiet === diet
    );

    if (!!newDiet) {
      const allDiets = dietSelectedSecureId.filter(
        newCurrentDiet => newCurrentDiet !== diet
      );
      setDietSelectedSecureId(allDiets);
    } else {
      setDietSelectedSecureId([...dietSelectedSecureId, diet]);
    }
  }

  return (
    <>
      <Modal
        open={visibleModal}
        onClose={handleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={visibleModal}>
          <Container>
            <StyledPaper>
              <Typography variant="h6">Dietas</Typography>
              {!!data && data.diets.map((diet, index) => (
                <div key={diet.value}>
                  <StyledGrid
                    container
                    alignItems="center"
                    justify="space-between"
                  >
                    <Grid item xs sm>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={() => handleSelectDiet(diet.value)}
                            color="primary"
                          />
                        }
                        label={diet.label}
                      />
                    </Grid>
                  </StyledGrid>
                  {data.length - 1 !== index && <Divider light />}
                </div>
              ))}

              <ContentButton>
                <ReactToPrint
                  trigger={() => (
                    <Button
                      color="primary"
                      variant="contained"
                      startIcon={<PrintIcon />}
                      disabled={!dataPrint || dataPrint.length === 0}
                    >
                      Imprimir
                    </Button>
                  )}
                  content={() => PrintScreen.current}
                />
              </ContentButton>
            </StyledPaper>

            <CloseButton stype="button" onClick={handleModal}>
              <CancelIcon fontSize="large" variant="outlined" />
            </CloseButton>
          </Container>
        </Fade>
      </Modal>
      <ContentPrint>
        <PrintManufactoringDiet
          ref={PrintScreen}
          date={currentDate}
          data={dataPrint}
        />
      </ContentPrint>
    </>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  align-self: center;
  margin-bottom: 30px;

  label {
    transition: opacity 2s;

    &:hover {
      opacity: 0.7;
    }

    img {
      height: 176px;
      width: 170px;
      border-radius: 50%;
      border: 4px solid rgba(255, 255, 255, 0.1);
      background: #eee;
    }

    .icon {
      flex-direction: column;
      align-items: center;
      p {
        font-size: 15px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.5);
      }
    }

    input {
      display: none;
    }
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Backdrop, Fade } from '@material-ui/core';

import { Container } from './styles';

import StakeholderAdd from '~/pages/Stakeholder/Add';

export default function ModalStakeholder({ visibleModal, handleModal, type }) {
  return (
    <Modal
      open={visibleModal}
      onClose={handleModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visibleModal}>
        <Container>
          <StakeholderAdd closeModal={handleModal} type={type} />
        </Container>
      </Fade>
    </Modal>
  );
}

ModalStakeholder.defaultProps = {
  type: null,
};

ModalStakeholder.propTypes = {
  visibleModal: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  type: PropTypes.string,
};

import React from 'react';
import { TableCell, TableRow } from "@material-ui/core";
import colors from '~/styles/colors';
import ButtonTable from '~/components/ButtonTable';
import ActionLoader from '~/components/ActionLoader';
import { formatNumber } from '~/utils/format';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { StyledTableCell, StyledTableCellEllipsis } from "./styles";
import Can from '~/components/Can';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { useMutation } from 'react-query';
import apiV2 from '~/services/apiV2';

export function CardSanitatyCost({ sanitaryCost }) {
  function handleSelectSanitaryCost({ secure_id }) {
    history.push(`/dashboard/sanitaryCost/edit/${secure_id}`);
  }

  const removeSanitaryCostRequest = useMutation(async () => {
    return await apiV2.delete(`v1/feedlot/admin/sanitary-cost/${sanitaryCost.secure_id}`)
  }, {
    onSuccess: (response) => {
      queryClient.invalidateQueries('sanitaryCost');
      if (response.status === 204) {
        return toast.error('Falha ao tentar apagar custo sanitário.')
      }
      return toast.success(response.data?.message)
    },
    onError: (error) => {
      if (!!error) {
        if (!!error.response.data?.type) {
          if (error.response.data?.type === 'warning') {
            return toast.warning(error.response.data.message)
          }
          return toast.error(error.response.data.message)
        } else {
          return toast.error('Ocorreu um erro ao tentar apagar custo sanitário.')
        }
      } else {
        return toast.error('Ocorreu um erro ao tentar apagar custo sanitário.')
      }
    }
  })

  function handleRemoveSanitaryCost() {
    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar esse custo sanitário?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar custo sanitário!',
    }).then(async result => {
      if (result.value) {
        await removeSanitaryCostRequest.mutateAsync()
      }
    });
  }

  return (
    <TableRow key={sanitaryCost.id}>
      <TableCell component="th" scope="row">
        {`${sanitaryCost.formattedInitialDate}`}
      </TableCell>
      <TableCell align="center">
        {sanitaryCost.formattedFinalDate}
      </TableCell>
      <StyledTableCell align="right">
        {`R$ ${formatNumber(sanitaryCost.cost, 2, 2)}`}
      </StyledTableCell>
      <StyledTableCellEllipsis>
        {sanitaryCost.obs}
      </StyledTableCellEllipsis>
      <StyledTableCell align="right">
        {sanitaryCost.secure_id === 'deleting' ? (
          <ActionLoader text="Deletando" />
        ) : (
          <>
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['sanitaryCost_edit']}
            >
              <ButtonTable
                icon={<EditIcon color="primary" />}
                background={colors.primary}
                lighten={0.6}
                onClick={() =>
                  handleSelectSanitaryCost(sanitaryCost)
                }
              />
            </Can>
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['sanitaryCost_delete']}
            >
              <ButtonTable
                icon={<DeleteIcon color={!sanitaryCost.final_date ? "error" : "disabled"} />}
                disabled={sanitaryCost.final_date}
                onClick={handleRemoveSanitaryCost}
              />
            </Can>
          </>
        )}
      </StyledTableCell>
    </TableRow>
  )
}
import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

export async function getData() {
  try {
    const { data } = await apiV2.get('/v1/feedlot/animal-handling/weightCorrection/showData');

    return data
  } catch {
    toast.error('Não foi possível carregar os dados de lotes!');
  }
}

export function useApiV2ShowDataWeightCorrection() {
  return useQuery(['weightCorrectionShowData'],
    () => getData(),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    });
}

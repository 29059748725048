import { TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { InputNumber } from './styles';

export default function TextFieldEdit({
  defaultValue,
  item,
  newManufactoring,
  setNewManufactoring,
}) {
  const [value, setValue] = useState(null);

  function calcDeviation(performed) {
    let deviation = 0;

    if (item.forecast > 0) {
      deviation = parseFloat((performed / item.forecast - 1) * 100);
    }

    if (deviation) {
      return parseFloat(deviation);
    }

    return Number(0);
  }

  function handleEdit(floatValue) {
    const currentNewManufactoring = newManufactoring.filter(
      food => food.secure_id !== item.secure_id
    );

    const deviation = calcDeviation(floatValue);

    currentNewManufactoring.push({
      ...item,
      performed: typeof floatValue === 'number' ? floatValue : null,
      deviation: floatValue >= 0 ? deviation : null,
    });

    setNewManufactoring(currentNewManufactoring);
    setValue(floatValue);
  }

  return (
    <InputNumber
      id={item.secure_id}
      customInput={TextField}
      max="999999.99"
      min="0.00"
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale={3}
      autoComplete="nope"
      defaultValue={defaultValue}
      value={value === null ? defaultValue : value}
      onValueChange={values => handleEdit(values.floatValue)}
    />
  );
}

import React from 'react';
import Swal from 'sweetalert2';

import {
  TableCell,
  TableRow
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

// Query hooks
import { useApiV2RemoveTransferPaddock } from '~/hooks/apiV2/animalHandling/TransferPaddock/useApiV2RemoveTransferPaddock';

import Can from '~/components/Can';
import colors from '~/styles/colors';
import ActionLoader from '~/components/ActionLoader';

import {
  StyledTableCell,
} from './styles';
import { format } from 'date-fns';
import { formatNamePaddock } from '~/utils/format';

export function CardTableTransferPaddock({ transfer, settings }) {
  // Query
  const removeAnimalTransferPaddock = useApiV2RemoveTransferPaddock()

  function handleRemove(secure_id) {
    async function performRemoveAnimalTransferPaddock() {
      await removeAnimalTransferPaddock.mutateAsync(secure_id)
    }
    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar essa transferência?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar transferência!',
    }).then(async result => {
      if (result.value) {
        performRemoveAnimalTransferPaddock();
      }
    });
  }

  return (
    <TableRow>
      <StyledTableCell component="th" scope="row">
        {transfer.lot && transfer.lot.code}
      </StyledTableCell>
      <TableCell align="left">
        {transfer.originPaddock &&
          formatNamePaddock(settings, transfer.originPaddock)}
      </TableCell>
      <TableCell align="left">
        {transfer.destinationPaddock &&
          formatNamePaddock(
            settings,
            transfer.destinationPaddock
          )}
      </TableCell>
      <TableCell align="center">
        {format(new Date(transfer.date), 'dd/MM/yyyy')}
      </TableCell>
      <StyledTableCell align="right">
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['animalTransferPaddock_delete']}
        >
          <ButtonTable
            icon={
              removeAnimalTransferPaddock.isLoading ? (
                <ActionLoader text="Deletando" />
              ) :
                (
                  <DeleteIcon color="error" />
                )
            }
            background={colors.error}
            onClick={() => handleRemove(transfer.secure_id)}
          />
        </Can>
      </StyledTableCell>
    </TableRow>
  )
}

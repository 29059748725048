import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";

export async function getZootechAnalytic({
  lot
}) {
  const { data } = await apiV2.get('/v1/feedlot/report/zootechAnalytic', {
    params: { lot }
  });

  return data
}

export function useApiV2ZootechAnalytic({ lot }) {
  return useQuery(
    ['zootechAnalytic', { lot }],
    () => getZootechAnalytic({ lot }),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  );
}

import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2AddOcurrenceType() {
  return useMutation(
    async (data) => {
      await apiV2.post('/v1/feedlot/register/ocurrenceType', data)
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries('ocurrencesType');
        toast.success('Tipo criado com sucesso!');

        history.push('/dashboard/ocurrencesType');
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao criar tipo.'
        toast[type](message)
      }
    }
  );
}

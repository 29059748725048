import styled from 'styled-components';
import { darken } from 'polished';
import { Container, Grid, Button } from '@material-ui/core';
import colors from '~/styles/colors';

export const StyledContainer = styled(Container)`
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledGridItem = styled(Grid)`
  width: 100%;
  margin-top: ${props => props.marginTop || 15}px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  background: ${props => props.background};
  color: ${props => props.color};
  transition: background 1s;
  min-width: 250px;
  min-height: 50px;

  &:hover {
    background: ${props => darken(0.06, props.background || colors.darkWhite)};
  }
`;

export const Error = styled.span`
  margin: 8px 14px 0;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

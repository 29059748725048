import React, { useState } from 'react';
import Swal from 'sweetalert2';

import {
  TableCell,
  TableRow
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';

// Query hooks
import { useApiV2RemoveManufacturingFeed } from '~/hooks/apiV2/foodManagement/manufactoringFeed/useApiV2RemoveManufacturingFeed';

import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell,
  StyledTableCellEllipsis
} from './styles';
import ActionLoader from '~/components/ActionLoader';
import { formatNumber } from '~/utils/format';
import ButtonTable from '~/components/ButtonTable';
import { ModalManufactoringFeed } from '../Modal';

export function CardTableManufactoringFeed({ feed }) {
  const [visibleModal, setVisibleModal] = useState(false);
  function handleCloseModal() {
    setVisibleModal(false);
  }

  function handleOpenModal() {
    setVisibleModal(true);
  }
  const removeManufactoringFeed = useApiV2RemoveManufacturingFeed()

  async function handleDelete({ secure_id }) {
    async function performRemoveManufactoringFeed() {
      await removeManufactoringFeed.mutateAsync(secure_id);
    }
    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar essa fabricação de ração?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar fabricação!',
    }).then(result => {
      if (result.value) {
        performRemoveManufactoringFeed();
      }
    });
  }

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          {feed.date}
        </TableCell>
        <StyledTableCellEllipsis component="th" scope="row">
          {feed.feed.name}
        </StyledTableCellEllipsis>
        <TableCell align="right">
          {formatNumber(feed.total_forecast, 0, 4)}
        </TableCell>
        <TableCell align="right">
          {formatNumber(feed.total_performed, 0, 4)}
        </TableCell>
        <TableCell align="right">
          {formatNumber(feed.deviation, 0, 4)}
        </TableCell>
        <StyledTableCell align="right">
          {`R$ ${formatNumber(feed.price_ton, 2, 2)}`}
        </StyledTableCell>
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['manufactoringFeed_edit', 'manufactoringFeed_delete']}
        >
          {can =>
            can ? (
              <StyledTableCell align="right">
                {removeManufactoringFeed.isLoading ? (
                  <ActionLoader text="Deletando" />
                ) : (
                  <>
                    <Can
                      checkRole={['administrator', 'owner', 'beefer']}
                      checkPermission={['manufactoringFeed_edit']}
                    >
                      <ButtonTable
                        icon={<VisibilityIcon />}
                        background={colors.dark}
                        lighten={0.5}
                        onClick={handleOpenModal}
                      />
                    </Can>
                    <Can
                      checkRole={['administrator', 'owner', 'beefer']}
                      checkPermission={['manufactoringFeed_delete']}
                    >
                      <ButtonTable
                        icon={<DeleteIcon color="error" />}
                        background={colors.error}
                        onClick={() => handleDelete(feed)}
                      />
                    </Can>
                  </>
                )}
              </StyledTableCell>
            ) : (
              <StyledTableCell align="right">
                <ButtonTable
                  icon={<VisibilityIcon />}
                  background={colors.dark}
                  lighten={0.5}
                  onClick={handleOpenModal}
                />
              </StyledTableCell>
            )
          }
        </Can>
      </TableRow>
      {visibleModal && (
        <ModalManufactoringFeed
          visibleModal={visibleModal}
          handleCloseModal={handleCloseModal}
          secure_id={feed.secure_id}
        />
      )}
    </>
  )
}

import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination
} from '@material-ui/core';

// Query
import { useApiV2IndividualAnimalInput } from '~/hooks/apiV2/animalHandling/IndividualAnimalInput/useApiV2IndividualAnimalInput'

import {
  Container,
  StyledGrid,
  StyledTablePaper,
  StyledTableContainer,
  StyledTableHead,
} from './styles';

import history from '~/services/history';
import Can from '~/components/Can';
import Loader from '~/components/Loader';
import ButtonNew from '~/components/ButtonNew';

import Filter from './Components/Filter';
import { CardTableAnimalInputUnity } from './Components/CardTableAnimalInputUnity';

export default function Input() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('date');
  const [direction, setDirection] = useState('asc');
  const [currentDate, setCurrentDate] = useState(null);
  const [currentBreed, setCurrentBreed] = useState(null);

  // Query
  const { data, isLoading } = useApiV2IndividualAnimalInput(`${field}%7C${direction}`, page + 1, rowsPerPage, currentDate, currentBreed);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleAddAnimal() {
    history.push('/dashboard/animalInputUnity/add');
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={2}>
          <Grid item xs sm md lg xl>
            <Filter
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              setCurrentBreed={setCurrentBreed}
            />
          </Grid>
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['animalInput_new']}
          >
            <Grid item xs="auto" sm="auto">
              <ButtonNew title="Nova" onClick={handleAddAnimal} />
            </Grid>
          </Can>
        </StyledGrid>

        {isLoading ? (
          <Loader />
        ) : (
          <StyledTablePaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell align="center">
                      <TableSortLabel
                        active={field === 'amount_animals'}
                        direction={
                          field === 'amount_animals' ? direction : 'asc'
                        }
                        onClick={() =>
                          handleChangeOrder('amount_animals')
                        }
                      >
                        Quantidade de animais
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={field === 'date'}
                        direction={field === 'date' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('date')}
                      >
                        Data
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                      Fornecedor
                    </TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={field === 'finished_apart'}
                        direction={field === 'finished_apart' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('finished_apart')}
                      >
                        Manejo Finalizado
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right" />
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {data && data.animalsInput
                    .map(animal => (
                      <CardTableAnimalInputUnity
                        key={animal.secure_id}
                        animal={animal}
                      />
                    ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledTablePaper>
        )}
      </Container>
    </center>
  );
}

import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2"
import { formatDateString } from '~/utils/format';

export async function getFeeds(types, sort, page = 1, limit = 50) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/register/feed?sort=${sort}`, {
      params: {
        types,
        page,
        limit
      }
    })

    const newData = data.data.map(res => ({
      ...res,
      total_percent_incl_mo: res.total_percent_incl_mo_food,
      updated_at: formatDateString(res.updated_at),
    }));

    return {
      feeds: newData,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    }
  } catch {
    toast.error('Falha para carregar os dados das rações cadastradas!');
  }
}

export function useApiV2Feeds(types, sort, page, limit) {
  return useQuery(
    ['feeds', types, sort, page, limit],
    () => getFeeds(types, sort, page, limit),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

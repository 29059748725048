import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import { Grid, Hidden, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Container, StyledPaper, StyledButton } from './styles';

import DatePicker from '~/components/DatePicker';

export default function Filter({ setFilters }) {
  const [date, setDate] = useState(new Date());

  return (
    <Container>
      <StyledPaper>
        <Grid container spacing={4} alignItems="center" justify="space-around">
          <Grid item xs={12} sm>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Data"
                selectedDate={date}
                fullWidth
                maxDate={new Date()}
                handleChangeDate={currentDate => setDate(currentDate)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm>
            <Hidden only="xs">
              <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setFilters({ date })}
              >
                Gerar
              </StyledButton>
            </Hidden>

            <Hidden smUp>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => setFilters({ date })}
              >
                Gerar
              </Button>
            </Hidden>
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

Filter.propTypes = {
  setFilters: PropTypes.func.isRequired,
};

import styled from 'styled-components';
import {
  Paper,
  Button
} from '@material-ui/core';

export const Container = styled.div`
  max-width: 1600px;
  width: 100%;
  padding: 40px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 40px;
`;

export const Header = styled.header`
  display: flex;
  justify-content: 'space-between',
  width: '100%',
  align-items: 'center',
`

export const StyledBackButton = styled(Button)`
  margin-top: 24px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.marginTop || 32}px;
`;

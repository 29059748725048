import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

export async function getSetting() {
  try {
    const { data } = await apiV2.get('/v1/feedlot/global/setting');

    return {
      settings: data
    }
  } catch {
    toast.error('Não foi possível carregar configurações!');
  }
}

export function useApiV2GlobalSetting() {
  return useQuery(
    ['settings'],
    () => getSetting(),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

import styled, { keyframes } from 'styled-components';
import {
  Paper,
  TableContainer,
  TableHead,
  TableCell,
  Button,
  FormControl,
  Grid,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 1600px;
  width: 100%;
  padding: 40px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 30px;
`;

export const StyledTablePaper = styled(Paper)`
  width: 100%;
  padding: 16px;
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoaderTotalPerformed = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    animation: ${rotate} 2s infinite;
  }
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.marginTop || 0}px;
`;

export const StyledSubmitButton = styled(Button)`
  margin: 10px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

export const StyledGridItem = styled(Grid)`
  margin: 0px 10px 24px 0px;
`;

export const StyledFormControl = styled(FormControl)``;

export const StyledGrid = styled(Grid)`
  margin-top: 8px;
`;

import React, { useState, useEffect } from 'react';
import {
  Grid,
  Table,
  TableSortLabel,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core';

import { useApiV2AnimalsOutput } from '~/hooks/apiV2/animalHandling/AnimalOutput/useApiV2AnimalsOutput';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

import {
  Container,
  StyledGrid,
  StyledTablePaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
} from './styles';

import Filter from './Components/Filter';

import history from '~/services/history';
import Can from '~/components/Can';
import ButtonNew from '~/components/ButtonNew';
import Loader from '~/components/Loader';
import { CardTableAnimalOutput } from './Components/CardTableAnimalOutput';

export default function Output() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('date');
  const [direction, setDirection] = useState('desc');
  const [animalOutputs, setAnimalOutputs] = useState([]);
  const [currentDate, setCurrentDate] = useState(null);
  const [currentLot, setCurrentLot] = useState(null);
  const [currentBreed, setCurrentBreed] = useState(null);

  // Query
  const { data, isLoading } = useApiV2AnimalsOutput(`${field}%7C${direction}`, page + 1, rowsPerPage, currentDate, currentLot, currentBreed);

  const { data: settings } = useApiV2GlobalSetting();

  useEffect(() => {
    if (data) {
      setAnimalOutputs(data.animalsOutput);
    }
  }, [data]);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleAdd() {
    history.push('/dashboard/animalOutput/add');
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={2}>
          <Grid item sm md>
            <Filter
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              setCurrentBreed={setCurrentBreed}
              setCurrentLot={setCurrentLot}
            />
          </Grid>
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['animalOutput_new']}
          >
            <Grid item sm="auto">
              <ButtonNew title="Nova" onClick={handleAdd} />
            </Grid>
          </Can>
        </StyledGrid>

        {isLoading ? (
          <Loader />
        ) : (
          <StyledTablePaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'date'}
                        direction={field === 'date' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('date')}
                      >
                        Data de Saída
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                      Lote
                    </TableCell>
                    <TableCell align="center">
                      Sexo
                    </TableCell>
                    <TableCell align="center">
                      Raça
                    </TableCell>
                    <TableCell align="center">
                      Piquete
                    </TableCell>
                    <StyledTableCell align="right">
                      <TableSortLabel
                        active={field === 'amount_animals'}
                        direction={
                          field === 'amount_animals' ? direction : 'asc'
                        }
                        onClick={() => handleChangeOrder('amount_animals')}
                      >
                        Quantidade
                      </TableSortLabel>
                    </StyledTableCell>
                    <Can checkRole={['administrator', 'owner', 'manager']}>
                      <StyledTableCell align="right" colSpan={2} />
                    </Can>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {animalOutputs &&
                    animalOutputs
                      .map(output => (
                        <CardTableAnimalOutput
                          key={output.secure_id}
                          output={output}
                          settings={settings}
                        />
                      ))}
                </TableBody>
              </Table>
            </StyledTableContainer>

            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledTablePaper>
        )}
      </Container>
    </center>
  );
}

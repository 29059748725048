import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";
import { formatDateOnly } from "~/utils/format";

export async function getAnimalsDeathOutput(sort, page = 1, limit = 50, date = null, originLot) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/animal-handling/individualAnimalDeathOutput?sort=${sort}`, {
      params: { page, limit, date, originLot },
    });

    const newData = data.data.map(animalTransfer => ({
      ...animalTransfer,
      date: formatDateOnly(animalTransfer.date),
    }));

    return {
      individualAnimalDeathOutput: newData,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    }
  } catch {
    toast.error('Não foi possível buscar refugos de cocho!');
  }
}

export function useApiV2IndividualAnimalDeathOutput(sort, page, limit, date, originLot) {
  return useQuery(['individualAnimalDeathOutput', sort, page, limit, date, originLot],
    () => getAnimalsDeathOutput(sort, page, limit, date, originLot),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    });
}

import { useQuery } from "react-query";
import apiV2 from '~/services/apiV2';
import { formatDateOnly, formatMoney } from "~/utils/format";

async function getV2SanitaryCost(page, limit, active, date, order) {
  const { data } = await apiV2.get(`v1/feedlot/admin/sanitary-cost`, {
    params: {
      page,
      limit,
      active,
      date,
      order,
    }
  })

  const newData = data.data.map(sanitaryCost => ({
    ...sanitaryCost,
    formattedInitialDate: formatDateOnly(sanitaryCost.initial_date.replace('Z', '')),
    formattedFinalDate: sanitaryCost.final_date
      ? formatDateOnly(sanitaryCost.final_date.replace('Z', ''))
      : '',
    formattedCost: formatMoney(sanitaryCost.cost),
  }));

  return {
    sanitatyCost: newData,
    total: data.meta.total,
    perPage: data.meta.per_page,
    page: data.meta.current_page,
    lastPage: data.meta.last_page,
  }
}

export function useV2SanitaryCost(page, limit, active, date, order) {
  return useQuery(
    ['sanitaryCost', page, limit, active, date, order],
    () => getV2SanitaryCost(page, limit, active, date, order))
}

import React, { useState, useEffect } from 'react';

import {
  Grid,
  TextField,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@material-ui/icons/Delete';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Autocomplete } from '@material-ui/lab';
import { ExcelRenderer } from 'react-excel-renderer';
import ShowAnimal from '../Components/ShowAnimal';
import {
  Container,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
  ButtonNextAnimalContainer,
  ContentAnimals,
  StyledButton,
  StyledSubmitButton,
} from './styles';

// Query
import { useApiV2ShowDataOutput } from '~/hooks/apiV2/animalHandling/IndividualAnimalOutput/useApiV2ShowDataOutput';
import { searchAnimal } from '~/hooks/apiV2/global/searchAnimal/useApiV2SearchAnimal';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';
import { useApiV2AddIndividualAnimalsOutput } from '~/hooks/apiV2/animalHandling/IndividualAnimalOutput/useApiV2AddIndividualAnimalsOutput';

import ButtonSubmit from '~/components/ButtonSubmit';
import Can from '~/components/Can';
import ButtonTable from '~/components/ButtonTable';
import colors from '~/styles/colors';
import InputDatePicker from '~/components/InputDatePicker';
import history from '~/services/history';
import Loader from '~/components/Loader';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.value,
});

const schema = Yup.object().shape({
  // adiconar
});

export default function Register() {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [animals, setAnimals] = useState([]);

  const [viewImport, setViewImport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileImport, setFileImport] = useState([]);

  const [listClients, setListClients] = useState([]);

  // Query
  const { data, isLoading: isLoadingData } = useApiV2ShowDataOutput();
  const {
    data: settings,
    isLoading: isLoadingSettings,
  } = useApiV2GlobalSetting();
  const addIndividualAnimalsOutput = useApiV2AddIndividualAnimalsOutput();

  useEffect(() => {
    if (data) {
      const newClients = data.optionsStakeholders.map(client => ({
        secure_id: client.secure_id,
        value: client.name,
      }));

      setListClients(newClients);
    }
  }, [data]);

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleRemove(secure_id) {
    setAnimals(animals.filter(animal => animal.secure_id !== secure_id));
  }

  const searchAnimalRequest = async (animalId, newAnimals) => {
    const animalSameId = newAnimals.find(
      animalFind =>
        animalFind[settings.settings.default_identification] === animalId
    );

    if (animalSameId) return null;

    const resp = await searchAnimal(
      settings.settings.default_identification,
      animalId
    );

    if (resp) {
      return resp;
    }
    return null;
  };

  const fileHandler = async () => {
    setIsLoading(true);
    ExcelRenderer(fileImport, async (err, resp) => {
      if (err) {
        // console.log(err);
      } else {
        const newAnimals = [];
        const listImportAnimals = resp.rows;
        listImportAnimals.shift();
        for await (const animal of listImportAnimals) {
          if (animal.length > 0) {
            const currentAnimal = await searchAnimalRequest(
              animal[0],
              newAnimals
            );

            if (currentAnimal) {
              newAnimals.push({
                ...currentAnimal,
                arroba_price: animal[2],
                output_at_price: animal[2],
                output_average_carcass_weight: animal[3],
                average_carcass_weight: animal[3],
                carcass_yield: (animal[3] / animal[1]) * 100,
                output_carcass_yield: (animal[3] / animal[1]) * 100,
                output_weight: animal[1],
                secure_id: currentAnimal.secure_id,
              });
            }
          }
        }

        setAnimals([...animals, ...newAnimals]);
        setViewImport(false);
        setIsLoading(false);
      }
    });
  };

  async function handleSubmit(values) {
    if (animals.length <= 0) return;

    const newData = {
      stakeholderSecureId: values.client_id,
      date: values.date,
      animals: animals.map(animal => ({
        animalSecureId: animal.secure_id,
        originLotSecureId: animal.lot.secure_id,
        outputWeight: animal.output_weight,
        outputAtPrice: animal.output_at_price,
        outputAverageCarcassWeight: animal.output_average_carcass_weight,
        outputCarcassYield: animal.output_carcass_yield,
      })),
    };
    await addIndividualAnimalsOutput.mutateAsync(newData);
  }

  return (
    <>
      {isLoadingData || isLoadingSettings ? (
        <Loader />
      ) : (
        <Formik
          validationSchema={schema}
          initialValues={{
            date: new Date(),
            client_id: '',
            client: null,
          }}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, isSubmitting }) => (
            <Form>
              <Container>
                <StyledPaper style={{ marginBottom: 16, marginTop: 16 }}>
                  <Grid container spacing={2}>
                    <Grid item md={4} sm xs={12}>
                      <InputDatePicker
                        name="date"
                        inputVariant="outlined"
                        required
                        maxDate={new Date()}
                        selectedDate={values.date}
                        handleChangeDate={date => {
                          setFieldValue('date', date);
                        }}
                      />
                    </Grid>

                    <Grid item md={4} sm xs={12}>
                      <Autocomplete
                        name="client"
                        size="small"
                        noOptionsText="Sem opções"
                        filterOptions={filterOptions}
                        options={listClients}
                        getOptionLabel={option => option.value}
                        value={values.client}
                        onChange={(_, value) => {
                          if (value) {
                            setFieldValue('client', value);
                            setFieldValue('client_id', value.secure_id);
                          } else {
                            setFieldValue('client', null);
                            setFieldValue('client_id', '');
                          }
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            name="client_id"
                            label="Cliente"
                            required
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                      <ButtonNextAnimalContainer>
                        {viewImport && !isLoading && (
                          <>
                            <input
                              type="file"
                              onChange={event =>
                                setFileImport(event.target.files[0])
                              }
                              style={{ padding: '10px' }}
                            />
                            <StyledButton
                              style={{ background: colors.success }}
                              variant="contained"
                              onClick={() => {
                                fileHandler(fileImport);
                                setViewImport(false);
                                setIsLoading(true);
                              }}
                            >
                              Importar
                            </StyledButton>
                          </>
                        )}
                        {!viewImport && !isLoading && (
                          <StyledButton
                            style={{ background: colors.success }}
                            variant="contained"
                            onClick={() => setViewImport(true)}
                          >
                            Importar de Arquivo
                          </StyledButton>
                        )}
                      </ButtonNextAnimalContainer>
                      {viewImport && !isLoading && (
                        <>
                          <a
                            href="https://beefsystem.com.br/download/planilha-saida"
                            target="_blank"
                          >
                            Clique aqui para baixar a planilha de exemplo
                          </a>
                        </>
                      )}
                      {isLoading && (
                        <>Carregando as informações do arquivo. Aguarde!</>
                      )}
                    </Grid>
                  </Grid>
                </StyledPaper>

                <ContentAnimals>
                  <StyledPaper>
                    <StyledTableContainer>
                      <Table stickyHeader>
                        <StyledTableHead>
                          <TableRow>
                            <TableCell align="center">
                              <TableSortLabel>Identificador</TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                              <TableSortLabel>Peso de saída</TableSortLabel>
                            </TableCell>
                            <TableCell align="center" />
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {animals.length > 0 &&
                            animals
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map(animalData => (
                                <TableRow key={animalData.secure_id}>
                                  <TableCell align="center">
                                    {
                                      animalData[
                                        settings.settings.default_identification
                                      ]
                                    }
                                  </TableCell>
                                  <TableCell align="center">
                                    {animalData.output_weight}
                                  </TableCell>

                                  <Can
                                    checkRole={[
                                      'administrator',
                                      'owner',
                                      'manager',
                                    ]}
                                  >
                                    <StyledTableCell align="right">
                                      <ButtonTable
                                        icon={<DeleteIcon color="error" />}
                                        background={colors.error}
                                        onClick={() =>
                                          handleRemove(animalData.secure_id)
                                        }
                                      />
                                    </StyledTableCell>
                                  </Can>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      labelRowsPerPage="Linhas por pág."
                      component="div"
                      count={animals.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </StyledPaper>
                  <ShowAnimal
                    animals={animals}
                    setAnimals={setAnimals}
                    settings={settings.settings}
                  />
                </ContentAnimals>
                <Grid
                  container
                  justify="flex-end"
                  spacing={2}
                  style={{ marginTop: 12 }}
                >
                  <StyledSubmitButton
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      history.push('/dashboard/individualAnimalOutput')
                    }
                  >
                    Cancelar
                  </StyledSubmitButton>
                  <ButtonSubmit
                    title="Cadastrar"
                    loading={isSubmitting}
                    disabled={animals.length <= 0}
                  />
                </Grid>
              </Container>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}

import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { Grid, Hidden, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Container, StyledPaper, StyledButton } from './styles';

import DatePicker from '~/components/DatePicker';

export default function Filter({ handleGenerateReport, initialDate, setInitialDate, finalDate, setFinalDate }) {

  function handleReport() {
    handleGenerateReport();
  }
  return (
    <Container>
      <StyledPaper>
        <Grid container spacing={4} alignItems="center" justify="space-around">
          <Grid item xs={6} sm>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Data Inicial"
                selectedDate={initialDate}
                maxDate={finalDate}
                fullWidth
                handleChangeDate={date => setInitialDate(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6} sm>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Data Final"
                selectedDate={finalDate}
                minDate={initialDate}
                fullWidth
                handleChangeDate={date => setFinalDate(date)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm>
            <Hidden only="xs">
              <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleReport}
              >
                Gerar
              </StyledButton>
            </Hidden>

            <Hidden smUp>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleReport}
              >
                Gerar
              </Button>
            </Hidden>
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

Filter.propTypes = {
  handleGenerateReport: PropTypes.func.isRequired,
};

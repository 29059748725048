import styled, { keyframes, css } from 'styled-components';
import { Paper, Button } from '@material-ui/core';
import colors from '~/styles/colors';

export const StyledPaper = styled(Paper)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 50%;
  max-width: 960px;
  padding: 10px;
  border-radius: 5px;
  overflow: hideen;
`;

export const Error = styled.span`
  margin: 8px 14px 0px;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  margin-top: 5px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }

  ${props =>
    props.mixing &&
    css`
      svg {
        animation: ${rotate} 2s infinite;
      }
    `}
`;

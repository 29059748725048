import { useQuery } from "react-query";
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';

async function getCauseOfDeaths() {
  try {
    const { data } = await apiV2.get(`v1/feedlot/global/list/causeOfDeath`)
    return data
  } catch {
    toast.error('Falha ao tentar carregar as causas!');
  }
}

export function useV2GlobalCauseOfDeath() {
  return useQuery(
    ['V2GlobalCauseOfDeaths',],
    () => getCauseOfDeaths()
  )
}

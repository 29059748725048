import styled from 'styled-components';
import {
  Paper,
  TableHead,
  TableBody,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  width: 100%;
  padding: 50px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px 50px;
  padding: 10px;

  img {
    width: 180px;
  }
`;

export const Column = styled.div``;

export const StyledTablePaper = styled(Paper)`
  width: 100%;

  .MuiTableCell-root {
    padding: 12px;
  }
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    background: ${colors.header};
    font-size: 1rem;

    th {
      color: ${colors.primary};
      font-size: 1rem;
    }

    td:last-child {
      width: 1%;
      white-space: nowrap;
    }
  }
  tr th {
    padding: 16px 11px;
  }
`;

export const StyledTableBody = styled(TableBody)`
  .MuiTableCell-body {
    font-size: 1rem;

    td:last-child {
      width: 1%;
      white-space: nowrap;
    }
  }
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 18px 16px 16px;
  position: relative;
  margin-top: 22px;

  & + div {
    margin-top: 38px;
  }

  h6 {
    font-weight: bold;
  }

  .MuiTypography-body2 {
    font-weight: 500;
    line-height: 25px;
    margin-right: 5px;
  }
`;

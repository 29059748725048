import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import { queryClient } from '~/services/queryClient';

export function useApiV2EditLot() {
  return useMutation(
    async (data) => {
      const { secure_id, ...newData } = data;

      await apiV2.put(`/v1/feedlot/register/lot/${secure_id}`, newData)
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries('lots');
        toast.success('O lote foi alterado com sucesso!');
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao tentar alterar lote.'
        toast[type](message)
      }
    }
  );
}

import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const StyledButton = styled(Button)`
  color: ${colors.white};
  background: ${colors.success};
  transition: background 2s;

  &:hover {
    background: ${darken(0.06, colors.success)};
  }
`;

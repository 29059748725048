import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { TextFieldNumber } from './styles';

export default function NumberFormatEmpty({ name, setFieldValue, ...props }) {
  return (
    <NumberFormat
      id={name}
      customInput={TextFieldNumber}
      autoComplete="off"
      isNumericString
      variant="outlined"
      size="small"
      isAllowed={val => {
        const { formattedValue, floatValue } = val;
        return (
          formattedValue === '' ||
          formattedValue === '-' ||
          (floatValue <= 9999999999.99 && floatValue >= -9999999999.99)
        );
      }}
      onValueChange={values =>
        setFieldValue(name, values.value !== '' ? values.floatValue : '')
      }
      {...props}
    />
  );
}

NumberFormatEmpty.defaultProps = {
  setFieldValue: () => {},
};

NumberFormatEmpty.propTypes = {
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func,
};

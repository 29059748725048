import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

export async function getFilterAnimalOutput({ date = new Date() }) {
  try {
    const { data } = await apiV2.get('/v1/feedlot/report/animalOutput/filter', {
      params: { date }
    });

    return data
  } catch {
    toast.error('Não foi possível carregar os dados!');
  }
}

export function useApiV2FilterAnimalOutput({ date }) {
  return useQuery(['filterAnimalOutputReport', { date }],
    () => getFilterAnimalOutput({ date }));
}

import React from 'react';
// import Swal from 'sweetalert2';
import { format } from 'date-fns';

import {
  TableRow,
  Tooltip,
  Fade,
  // Button,
  Switch,
  TableCell
} from '@material-ui/core';

// import DeleteIcon from '@material-ui/icons/Delete';
import ListIcon from '@material-ui/icons/List';
import OpenInNew from '@material-ui/icons/OpenInNew';

import { useApiV2EditLot } from '~/hooks/apiV2/register/lot/useApiV2EditLot';
// import { useApiV2RemoveLot } from '~/hooks/apiV2/register/lot/useApiV2RemoveLot';

import { formatNamePaddock } from '~/utils/format';
// import ActionLoader from '~/components/ActionLoader';
import ButtonTable from '~/components/ButtonTable';
import history from '~/services/history';
import Can from '~/components/Can';
// import colors from '~/styles/colors';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';

export function CardTableLot({ lot, settings }) {
  // Query
  const changeStatus = useApiV2EditLot()
  // const removeLot = useApiV2RemoveLot();

  async function handleChangeActive({ secure_id, active }) {
    const newActive = !active;
    await changeStatus.mutateAsync({ secure_id, active: newActive })
  }

  // function handleRemoveLot({ secure_id }) {
  //   async function performRemoveLot() {
  //     await removeLot.mutateAsync(secure_id)
  //   }

  //   Swal.fire({
  //     title: 'Você tem certeza ?',
  //     text: 'Está ação não tem volta! Deseja deletar esse lote?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: colors.lightPrimary,
  //     cancelButtonColor: colors.grey,
  //     confirmButtonText: 'Ok, deletar lote!',
  //   }).then(result => {
  //     if (result.value) {
  //       performRemoveLot();
  //     }
  //   });
  // }

  return (
    <TableRow key={lot.id}>
      <StyledTableCellEllipsis component="th" scope="row">
        {`${lot.code}`}
      </StyledTableCellEllipsis>
      <StyledTableCellEllipsis component="th" scope="row">
        {lot.active
          ? formatNamePaddock(settings, { name: lot.paddock_name, line: { name: lot.line_name, module: { name: lot.module_name } } })
          : ''}
      </StyledTableCellEllipsis>
      <StyledTableCell align="center">
        {`${lot.amount_animals}`}
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title="Ver animais"
        >
          <span style={{ marginLeft: 20 }}>
            <ButtonTable
              icon={<OpenInNew />}
              onClick={() =>
                history.push('/dashboard/animals/', { lot_id: lot.secure_id })
              }
            />
          </span>
        </Tooltip>
        {/* {`${formatNumber(lot.percent_ms, 2, 4)} %`} */}
      </StyledTableCell>
      <StyledTableCell align="center">
        {`${format(
          new Date(lot.created_at),
          'dd/MM/yyyy'
        )}`}
        {/* {`${formatNumber(lot.percent_ms, 2, 4)} %`} */}
      </StyledTableCell>
      <Can
        checkRole={['administrator', 'owner', 'beefer']}
        checkPermission={['lots_edit']}
      >
        {can =>
          can ? (
            <TableCell align="right">
              <Switch
                color="primary"
                checked={lot.active}
                disabled={lot.amount_animals > 0}
                onChange={() => handleChangeActive(lot)}
              />
            </TableCell>
          ) : (
            <TableCell align="right">
              <Switch
                color="primary"
                checked={lot.active}
                disabled={true}
                onChange={() => handleChangeActive(lot)}
              />
            </TableCell>
          )}
      </Can>
      <StyledTableCell
        align="right"
        style={{ position: 'relative' }}
      >
        {/* <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['lots_delete']}
        >
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title="Deletar"
          >
            <span>
              <ButtonTable
                icon={
                  removeLot.isLoading ? (
                    <ActionLoader text="Deletando" />
                  ) :
                    (
                      <DeleteIcon color={
                        lot.amount_animals > 0 ||
                          lot.has_treatment_lots > 0 ? "disabled" : "error"
                      } />
                    )
                }
                disabled={lot.amount_animals > 0 || lot.has_treatment_lots > 0}
                background={
                  lot.amount_animals > 0 ||
                    lot.has_treatment_lots > 0
                    ? colors.disabled
                    : colors.error
                }
                onClick={() => handleRemoveLot(lot)}
              />
            </span>
          </Tooltip>
        </Can> */}
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['lots_view']}
        >
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title="Histórico"
          >
            <span>
              <ButtonTable
                icon={<ListIcon />}
                onClick={() =>
                  history.push(
                    `/dashboard/lots/detail/${lot.secure_id}`
                  )
                }
              />
            </span>
          </Tooltip>
        </Can>
      </StyledTableCell>
    </TableRow>
  )
}

import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

export async function getData(date, currentModule) {
  try {
    const { data } = await apiV2.get('/v1/feedlot/food-management/troughReading/showData', {
      params: { date, currentModule }
    });

    return data
  } catch (error) {
    const type = error.response.data?.type ? error.response.data.type : 'error'
    const message = error.response.data?.message ? error.response.data.message : 'Não foi possível carregar os dados!'
    toast[type](message)
  }
}

export function useApiV2ShowDataTroughReading(date, currentModule) {
  return useQuery(['troughReadingData', date, currentModule],
    () => getData(date, currentModule),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    });
}

import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2AddPurchaseFood() {
  return useMutation(
    async (data) => {
      await apiV2.post('/v1/feedlot/admin/purchaseFood', data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('purchaseFoods');
        toast.success('Compra de alimentos cadastrada com sucesso!');

        history.push('/dashboard/purchaseFoods');
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao cadastrar compra de alimentos.'
        toast[type](message)
      }
    }
  );
}

import React, { useState, useEffect, useMemo } from 'react';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';

// Query
import {
  useApiV2EditManufacturingFeed
} from '~/hooks/apiV2/foodManagement/manufactoringFeed/useApiV2EditManufacturingFeed';
import {
  useApiV2SelectManufacturingFeed
} from '~/hooks/apiV2/foodManagement/manufactoringFeed/useApiV2SelectManufacturingFeed';
import { useV2GlobalEmployees } from '~/hooks/apiV2/global/employees/useV2GlobalEmployees';

import {
  Container,
  StyledGrid,
  StyledPaperTable,
  StyledTableHead,
  ContentSubmitButtons,
  StyledSubmitButton,
  StyledTableCell,
  StyledFormControl,
} from './styles';

import TextFieldEdit from '../Components/TextFieldEdit';
import FilterRegister from '../Components/FilterRegister';

import { formatNumber } from '~/utils/format';
import history from '~/services/history';
import Loader from '~/components/Loader';
import ButtonSubmit from '~/components/ButtonSubmit';
import NumberFormatForm from '~/components/NumberFormatForm';

export default function Register({ match }) {
  const [listFeeds, setListFeeds] = useState([]);
  const [totalForecast, setTotalForecast] = useState('');
  const [totalPerformed, setTotalPerformed] = useState(0);
  const [totalDeviation, setTotalDeviation] = useState(0);
  const [percentMsFeed, setPercentMsFeed] = useState(0);
  const [totalPriceTon, setTotalPriceTon] = useState(0);
  const [errorTotalForecast, setErrorTotalForecast] = useState(null);
  const [currentEmployee, setCurrentEmployee] = useState('');
  const [currentFeed, setCurrentFeed] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());

  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  // Query
  const { data: employeesData } = useV2GlobalEmployees();
  const { data: currentManufactoringFeed, isLoading, error } = useApiV2SelectManufacturingFeed(secure_id);
  const alterManufactoringFeed = useApiV2EditManufacturingFeed(secure_id);

  function calcDeviation(performed, forecast) {
    let deviation = 0;

    if (forecast > 0) {
      deviation = Number((performed / forecast - 1) * 100);
    }

    if (deviation) {
      return Number(deviation);
    }

    return 0;
  }

  function handleTotalForecast(value) {
    let newListFeeds = [];

    if (value > 0) {
      newListFeeds = listFeeds.map(feed => {
        const forecast = (value * feed.percent_incl_mo_food) / 100;
        const deviation = feed.performed
          ? calcDeviation(feed.performed, forecast)
          : null;

        return {
          ...feed,
          forecast,
          deviation,
        };
      });

      setErrorTotalForecast(null);
    } else {
      newListFeeds = listFeeds.map(feed => ({
        ...feed,
        forecast: '',
        deviation: '',
      }));
    }

    setListFeeds(newListFeeds);
    setTotalForecast(value);
  }

  async function handleSubmit() {
    if (!currentEmployee) {
      toast.error('Informe o funcionário responsável!');
      return;
    }

    if (!totalForecast) {
      setErrorTotalForecast('Digite a qtd. prevista');
      return;
    }

    setErrorTotalForecast(null);

    const foods = listFeeds.map(feed => ({
      forecast: feed.forecast,
      performed: typeof feed.performed !== 'number' ? null : feed.performed,
      deviation: typeof feed.deviation !== 'number' ? null : feed.deviation,
      foodSecureId: feed.food.secure_id,
    }));

    const data = {
      totalForecast: totalForecast,
      totalPerformed: totalPerformed,
      priceTon: totalPriceTon,
      deviation: totalDeviation,
      percentMs: percentMsFeed,
      date: parseISO(format(currentDate, 'yyyy-MM-dd HH:mm:ss')),
      foodSecureId: currentFeed.secure_id,
      foods,
      employeeSecureId: currentEmployee,
    };

    const foodsData = foods.filter(
      foodD => foodD.performed === null && typeof foodD.performed !== 'number'
    );
    if (foodsData && foodsData.length) {
      Swal.fire({
        title: 'Ops!',
        text: 'Todos os campos devem ser preenchidos antes de serem salvos.',
        icon: 'warning',
        showConfirmButton: true,
      });
      return;
    }

    // await alterManufactoringFeed.mutateAsync(data);
  }

  function handleBack() {
    history.goBack();
  }

  useEffect(() => {
    const { performed, price } = listFeeds.reduce(
      (accumulator, feedFood) => {
        accumulator.performed += Number(feedFood.performed);

        if (feedFood.food.lastMovement) {
          accumulator.price +=
            (Number(feedFood.performed) / 1000) *
            Number(feedFood.food.lastMovement.current_price_ton);
        } else {
          accumulator.price +=
            feedFood.food.price_ton <= 0
              ? 0
              : (Number(feedFood.performed) / 1000) *
              Number(feedFood.food.price_ton);
        }

        return accumulator;
      },
      {
        performed: 0,
        price: 0,
      }
    );

    if(!!currentManufactoringFeed) {
      setTotalPriceTon(currentManufactoringFeed.price_ton);
    }
    setTotalPerformed(performed);
  }, [listFeeds, secure_id]);

  useEffect(() => {
    if (totalForecast > 0) {
      const totalMsKg = listFeeds.reduce(
        (total, feed) =>
          total + (Number(feed.food.percent_ms) * Number(feed.performed)) / 100,
        0
      );

      const newPercentMsFeed = Number((totalMsKg * 100) / totalForecast);

      setPercentMsFeed(newPercentMsFeed);
    } else {
      setPercentMsFeed(0);
    }
  }, [listFeeds, totalForecast]);

  useEffect(() => {
    let newTotalDeviation = 0;

    if (totalForecast > 0) {
      newTotalDeviation = Number((totalPerformed / totalForecast - 1) * 100);
    }

    setTotalDeviation(newTotalDeviation);
  }, [totalForecast, totalPerformed]);

  useEffect(() => {
    if (secure_id && currentManufactoringFeed && currentManufactoringFeed.secure_id) {
      setTotalForecast(currentManufactoringFeed.total_forecast);
      const newListFeeds = currentManufactoringFeed.feedFoods.map(feed => {
        let percentInclMOFood = 0;

        if (currentManufactoringFeed.feed.type === 'feed') {
          const {
            percent_incl_mo_food,
          } = currentManufactoringFeed.feed.feeds.find(
            cfeed => cfeed.food.secure_id === feed.food.secure_id
          );
          percentInclMOFood = percent_incl_mo_food;
        } else {
          const {
            percent_incl_mo_food,
          } = currentManufactoringFeed.feed.preMixes.find(
            mix => mix.food.secure_id === feed.food.secure_id
          );
          percentInclMOFood = percent_incl_mo_food;
        }

        return {
          secure_id: feed.food.secure_id,
          name: feed.food.name,
          forecast: feed.forecast,
          performed: feed.performed,
          deviation: feed.deviation,
          percent_incl_mo_food: percentInclMOFood,
          food: {
            ...feed.food,
          },
        };
      });

      setListFeeds(newListFeeds);
      setTotalPriceTon(currentManufactoringFeed.price_ton);
    }
  }, [currentFeed, currentManufactoringFeed, secure_id]);

  function handleChangeEmployee(event) {
    setCurrentEmployee(event.target.value);
  }

  useEffect(() => {
    if (currentManufactoringFeed) {
      setCurrentEmployee(currentManufactoringFeed.employee.secure_id);
      setCurrentFeed(currentManufactoringFeed.feed);
      setCurrentDate(currentManufactoringFeed.date)
    }
  }, [currentManufactoringFeed]);

  if (secure_id && error) {
    history.push('/dashboard/manufactoringFeed');
    toast.error('Falha para carregar os dados!');
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="center" spacing={2}>
          <Grid item sm>
            <FilterRegister
              errorTotalForecast={errorTotalForecast}
              totalForecast={totalForecast}
              handleTotalForecast={handleTotalForecast}
              secureId={secure_id}
              currentFeed={currentFeed}
              currentDate={currentDate}
              setCurrentFeed={setCurrentFeed}
              setCurrentDate={setCurrentDate}
              currentManufactoringFeed={currentManufactoringFeed}
              feedSecureId={currentManufactoringFeed.feed.secure_id}
              feeds={[{ secure_id: currentManufactoringFeed.feed.secure_id, name: currentManufactoringFeed.feed.name }]}
            />
          </Grid>
        </StyledGrid>

        <StyledPaperTable>
          <TableContainer>
            <Table stickyHeader>
              <StyledTableHead>
                <TableRow>
                  <TableCell>Alimento</TableCell>
                  <TableCell align="right">Qtd. Prevista</TableCell>
                  <TableCell align="right">Qtd. Realizada</TableCell>
                  <TableCell align="right">Desvio (%)</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {listFeeds.map(feed => (
                  <TableRow key={feed.id}>
                    <TableCell component="th" scope="row">
                      {feed.name}
                    </TableCell>
                    <TableCell align="right">
                      <NumberFormatForm
                        variant="standard"
                        disabled
                        fullWidth={false}
                        fixedDecimalScale
                        decimalScale={0}
                        value={feed.forecast}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextFieldEdit
                        defaultValue={feed.performed}
                        disabled
                        item={feed}
                        listFeeds={listFeeds}
                        setListFeeds={setListFeeds}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <NumberFormatForm
                        variant="standard"
                        fullWidth={false}
                        disabled
                        fixedDecimalScale
                        decimalScale={0}
                        value={feed.deviation}
                      />
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <StyledTableCell notBorderBottom rowSpan={4} colSpan={2} />
                  <StyledTableCell align="left">
                    Total Realizado
                  </StyledTableCell>
                  <TableCell align="right">
                    {formatNumber(totalPerformed, 0, 0)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left">Total Desvio</StyledTableCell>
                  <TableCell align="right">{`${formatNumber(
                    totalDeviation,
                    2,
                    2
                  )} %`}</TableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left">% MS</StyledTableCell>
                  <TableCell align="right">
                    {`${formatNumber(percentMsFeed, 2, 2)} %`}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell align="left">Custo/Ton</StyledTableCell>
                  <TableCell align="right">
                    {`R$ ${formatNumber(totalPriceTon, 2, 2)}`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <StyledGrid container justify="flex-end" spacing={2}>
            <Grid item sm={3} xs={12}>
              <StyledFormControl size="small" fullWidth>
                <InputLabel id="input-line">Funcionário</InputLabel>
                <Select
                  id="currentLine"
                  onChange={handleChangeEmployee}
                  value={currentEmployee}
                  disabled
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                >
                  {employeesData && employeesData.length
                    ? employeesData.map(line => {
                      const functions = line.function.split(',');
                      return functions.indexOf('Gerente') > -1 ||
                        functions.indexOf('Veterinário') > -1 ||
                        functions.indexOf('Tratador') > -1 ||
                        functions.indexOf('Fábrica de Ração') > -1 ||
                        functions.indexOf('Folguista') > -1 ||
                        functions.indexOf('Diarista') > -1 ||
                        functions.indexOf('Escritório') > -1 ||
                        functions.indexOf('Boiadeiro') > -1 ? (
                        <MenuItem key={line.value} value={line.value}>
                          {line.label}
                        </MenuItem>
                      ) : (
                        ''
                      );
                    })
                    : ''}
                </Select>
              </StyledFormControl>
            </Grid>
          </StyledGrid>
          <ContentSubmitButtons>
            <StyledSubmitButton
              variant="outlined"
              color="primary"
              onClick={handleBack}
            >
              Cancelar
            </StyledSubmitButton>
            <ButtonSubmit
              title="Alterar"
              disabled
              loading={alterManufactoringFeed.isLoading}
              onClick={handleSubmit}
            />
          </ContentSubmitButtons>
        </StyledPaperTable>
      </Container>
    </center>
  );
}

Register.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
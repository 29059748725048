import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darken } from 'polished';
import { Grid, Button } from '@material-ui/core';

import colors from '~/styles/colors';

export const Container = styled.div`
  height: 100%;
`;

export const Back = styled(Link)`
  text-decoration: none;
  color: ${colors.white};
  font-size: 0.875rem;
  font-weight: 500;

  position: absolute;
  top: 10px;
  padding: 10px;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  background: ${colors.primary};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
`;

export const StyledGridItem = styled(Grid)`
  width: 100%;
  margin-top: ${props => props.marginTop || 15}px;
`;

export const Error = styled.span`
  margin: 8px 14px 0;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  background: ${props => props.background};
  color: ${props => props.color};
  transition: background 1s;
  min-width: 250px;
  min-height: 50px;

  &:hover {
    background: ${props => darken(0.06, props.background || colors.darkWhite)};
  }
`;

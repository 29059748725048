import React from 'react';
import Swal from 'sweetalert2';

import {
  TableCell,
  TableRow
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ButtonTable from '~/components/ButtonTable';

// Query hooks
import { useApiV2RemoveDeathOutput } from '~/hooks/apiV2/animalHandling/DeathOutput/useApiV2RemoveDeathOutput';

import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell,
  StyledTableCellEllipsis
} from './styles';
import { format } from 'date-fns';
import history from '~/services/history';
import { formatNamePaddock } from '~/utils/format';
import ActionLoader from '~/components/ActionLoader';

export function CardTableDeathOutput({ output, settings }) {
  // Query
  const removeAnimalDeathOutput = useApiV2RemoveDeathOutput()

  function handleEdit(secure_id) {
    history.push(`/dashboard/animalDeathOutput/edit/${secure_id}`);
  }

  function handleRemove(secure_id) {
    async function performRemoveAnimalDeathOutput() {
      await removeAnimalDeathOutput.mutateAsync(secure_id);
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Esta ação não tem volta! Deseja remover essa morte de animal?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, remover esta morte!',
    }).then(async result => {
      if (result.value) {
        performRemoveAnimalDeathOutput();
      }
    });
  }

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {`${output.originLot.code} - ${formatNamePaddock(settings, output.originLot.paddock)}`}
      </TableCell>
      <TableCell align="left">
        {output.movementHistory[0].animal.causeOfDeath && output.movementHistory[0].animal.causeOfDeath.name}
      </TableCell>
      <TableCell align="left">
        {output.amount_animals}
      </TableCell>
      <StyledTableCellEllipsis>
        {output.obs}
      </StyledTableCellEllipsis>
      <TableCell align="center">
        {format(new Date(output.date), 'dd/MM/yyyy')}
      </TableCell>

      <StyledTableCell align="right">
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['animalDeathOutput_edit']}
        >
          <ButtonTable
            icon={<EditIcon color="primary" />}
            background={colors.primary}
            lighten={0.6}
            onClick={() => handleEdit(output.secure_id)}
          />
        </Can>
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['animalDeathOutput_delete']}
        >
          <ButtonTable
            icon={
              removeAnimalDeathOutput.isLoading ? (
                <ActionLoader text="Deletando" />
              ) :
                (
                  <DeleteIcon color="error" />
                )
            }
            background={colors.error}
            onClick={() => handleRemove(output.secure_id)}
          />
        </Can>
      </StyledTableCell>
    </TableRow>
  )
}

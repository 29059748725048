import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

async function getFilterLotEconomicClosing() {
  try {
    const { data } = await apiV2.get('/v1/feedlot/report/economicClossingAnalytic/filter');

    return data
  } catch {
    toast.error('Não foi possível carregar os dados!');
  }
}

export function useApiV2ShowLotEconomicClosing() {
  return useQuery(['filterLotEconomicClosing'],
    () => getFilterLotEconomicClosing());
}

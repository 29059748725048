import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import {
  Container,
  StyledPaper,
  StyledTextField,
  StyledButton,
  AnimalInfos,
  CardTitle,
  CardBody,
  Card,
  FindAnimalContainer,
  WeightContainer,
  StyledGrid,
} from './styles';
import colors from '~/styles/colors';
import InputDatePicker from '~/components/InputDatePicker';

// Query
import { searchAnimal } from '~/hooks/apiV2/global/searchAnimal/useApiV2SearchAnimal';
import { useV2GlobalCauseOfDeath } from '~/hooks/apiV2/global/causeOfDeath/useV2GlobalCauseOfDeath';
import { useDebouncedPromise } from '~/utils/useDebouncedPromise';

export default function ShowAnimal({
  settings,
  animals,
  setAnimals,
  causeOfDeaths
}) {

  const [animalValue, setAnimalValue] = useState('');
  const [animal, setAnimal] = useState({});

  const searchAnimalRequest = async (animalId) => {
    const animalSameId = animals.find(animalFind => animalFind[settings.default_identification] === animalId);

    if (animalSameId) return;

    const resp = await searchAnimal(
      settings.default_identification,
      animalId
    );

    if (resp) {
      setAnimal(resp);
    } else {
      setAnimal({});
    }
  }

  const debounceSearchAnimal = useDebouncedPromise(searchAnimalRequest, 1000);

  const translateInputType = type => {
    let inputType;

    switch (type) {
      case 'purchase':
        inputType = 'Compra';
        break;

      case 'transfer':
        inputType = 'Transferência';
        break;

      case 'boitel':
        inputType = 'Boitel';
        break;

      default:
        inputType = '-';
        break;
    }

    return inputType;
  };

  const sendAnimal = () => {
    if (
      !animal.output_date
      || !animal.output_obs
      || !animal.cause_of_death
    ) {
      toast.error("Há campos não preenchidos!");
      return;
    }

    setAnimals([
      ...animals,
      animal
    ]);
    setAnimal({});
    setAnimalValue('');
  }

  return (
    <Container>
      <StyledPaper>
        <FindAnimalContainer>
          <StyledGrid container justify="start" spacing={2}>
            <Grid item>
              <StyledTextField
                id="animal_id"
                name="animal_id"
                fullWidth
                variant="outlined"
                label="Identificador do animal"
                autoComplete="off"
                size="small"
                value={animalValue}
                onChange={e => {
                  debounceSearchAnimal(e.target.value);
                  setAnimalValue(e.target.value)
                }}
              />
            </Grid>
            {animal && animal.secure_id && (
              <>
                <Grid item>
                  <TextField
                    name="cause_of_death_id"
                    label="Causa da morte"
                    variant="outlined"
                    fullWidth
                    select
                    required
                    size="small"
                    value={animal.cause_of_death ?animal.cause_of_death.secure_id : ''}
                    onChange={event => {
                      const current = causeOfDeaths.find(value => value.secure_id === event.target.value)
                      if (current) {
                        setAnimal({
                          ...animal,
                          cause_of_death: current,
                        });
                      }
                    }}
                  >
                    {causeOfDeaths.map(causeOfDeath => (
                      <MenuItem key={causeOfDeath.secure_id} value={causeOfDeath.secure_id}>
                        {causeOfDeath.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item>
                  <InputDatePicker
                    name="date"
                    fullWidth
                    autoComplete="off"
                    inputVariant="outlined"
                    maxDate={new Date()}
                    selectedDate={animal.output_date}
                    handleChangeDate={date => {
                      setAnimal({
                        ...animal,
                        output_date: date,
                      });
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name="obs"
                    variant="outlined"
                    label="Observação *"
                    autoComplete="off"
                    size="small"
                    fullWidth
                    multiline
                    value={animal.output_obs}
                    onChange={e => {
                      setAnimal({
                        ...animal,
                        output_obs: e.target.value,
                      });
                    }}
                  />
                </Grid>
              </>
            )}
          </StyledGrid>
        </FindAnimalContainer>
        <AnimalInfos container spacing={2}>
          <Card>
            <CardTitle>Data de Entrada</CardTitle>
            <CardBody>
              {animal && animal.input_date
                ? format(new Date(animal.input_date), 'dd/MM/yyyy')
                : '-'}
            </CardBody>
          </Card>
          <Card>
            <CardTitle>Tipo de Entrada</CardTitle>
            <CardBody>
              {animal ? translateInputType(animal.input_type) : '-'}
            </CardBody>
          </Card>
          <Card>
            <CardTitle>Raça</CardTitle>
            <CardBody>{animal?.breed?.name || '-'}</CardBody>
          </Card>
          <Card>
            <CardTitle>Categoria</CardTitle>
            <CardBody>{animal?.type || '-'}</CardBody>
          </Card>
          <Card>
            <CardTitle>Sexo</CardTitle>
            <CardBody>{animal?.sex || '-'}</CardBody>
          </Card>
          <Card>
            <CardTitle>Idade</CardTitle>
            <CardBody>{animal?.age || '-'}</CardBody>
          </Card>
          <Card>
            <CardTitle>Tamanho Corporal</CardTitle>
            <CardBody>{animal?.body_size || '-'}</CardBody>
          </Card>
          <Card>
            <CardTitle>Peso do Animal</CardTitle>
            <CardBody>
              {animal.current_weight ? `${animal.current_weight}kg` : '-'}
            </CardBody>
          </Card>
          <Card>
            <CardTitle>Lote</CardTitle>
            <CardBody>{animal.lot ? animal.lot.code : '-'}</CardBody>
          </Card>
          <Card>
            <CardTitle>Piquete</CardTitle>
            <CardBody>{animal.lot ? animal.lot.paddock?.name : '-'}</CardBody>
          </Card>
        </AnimalInfos>
        <WeightContainer>
          <StyledButton
            style={{ background: colors.success }}
            variant="contained"
            disabled={!animal.secure_id}
            onClick={sendAnimal}
          >
            Adicionar animal
          </StyledButton>
        </WeightContainer>
      </StyledPaper>
    </Container>
  );
}

ShowAnimal.propTypes = {
  settings: PropTypes.shape({
    default_identification: PropTypes.string,
  }).isRequired,
  animals: PropTypes.array.isRequired,
  setAnimals: PropTypes.func.isRequired,
  causeOfDeaths: PropTypes.array.isRequired
};

import { useQuery } from 'react-query';
import apiV2 from '~/services/apiV2';

export async function getReportEconomicClosingResumeV2(initialDate, finalDate, lotsHidden) {
  try {
    const { data } = await apiV2.get(
      `v1/feedlot/report/economicClossingResume`, {
      params: {
        initialDate,
        finalDate,
        lotsHidden
      }
    }
    );

    return data
  } catch {
    return []
  }
}

export function useReportEconomicClosingResumeV2(
  initialDate = '', finalDate = '', lotsHidden = ''
) {
  return useQuery(['reportEconomicClosingResume', initialDate, finalDate, lotsHidden],
    () => getReportEconomicClosingResumeV2(initialDate, finalDate, lotsHidden));
}

/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';

import {
  Container,
  Header,
  Column,
  StyledTablePaper,
  StyledTableHead,
  StyledTableRow,
  StyledTableContainer,
  StyledTableCell,
  StyledTableCellCons,
  StyledTableCellEllipsis,
} from './styles';

import logo from '~/assets/images/logo_black.png';

import colors from '~/styles/colors';
import { formatNumber } from '~/utils/format';

export default class PrintActiveLot extends Component {
  render() {
    const { summaryFood, settings, differenceDays } = this.props;

    return (
      <Container>
        <Header>
          <img src={logo} alt="logo" />
          <Column>
            <Typography variant="h5" align="center">
              Alimentos Resumidos
            </Typography>
          </Column>
          <Column />
        </Header>

        <StyledTablePaper elevation={0}>
          <StyledTableContainer>
            <Table stickyHeader>
              <StyledTableHead>
                <TableRow>
                  <TableCell align="left">Alimento</TableCell>
                  <StyledTableCell align="right">% MS</StyledTableCell>
                  <TableCell align="right">Entrada (ton)</TableCell>
                  <TableCell align="right">Saída (ton)</TableCell>
                  <TableCell align="right">Atual (ton)</TableCell>
                  <StyledTableCellCons align="right">
                    <span>Cons. Médio</span>
                    <span>({differenceDays} dias)</span>
                  </StyledTableCellCons>
                  <TableCell align="right">Previsão (dias)</TableCell>
                  <TableCell align="right">Preço/Ton</TableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {summaryFood.map(food => (
                  <StyledTableRow
                    key={food.secure_id}
                    background={
                      settings.enable_min_food_stock &&
                      food.total_amount_ton < food.min_food_stock_ton &&
                      colors.danger
                    }
                  >
                    <StyledTableCellEllipsis component="th" scope="row">
                      {`${food.name}`}
                    </StyledTableCellEllipsis>
                    <StyledTableCell align="right">
                      {`${formatNumber(food.percent_ms, 2, 4)} %`}
                    </StyledTableCell>
                    <TableCell align="right">
                      {formatNumber(food.total_input_ton, 4, 4)}
                    </TableCell>
                    <TableCell align="right">
                      {formatNumber(food.total_output_ton, 4, 4)}
                    </TableCell>
                    <StyledTableCell align="right">
                      {formatNumber(food.total_amount_ton, 4, 4)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formatNumber(food.average_consumption, 4, 4)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formatNumber(food.expected_days, 0, 0)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {`R$ ${formatNumber(food.price_ton, 2, 2)}`}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </StyledTablePaper>
      </Container>
    );
  }
}

PrintActiveLot.propTypes = {
  differenceDays: PropTypes.number.isRequired,
  summaryFood: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  settings: PropTypes.shape({
    enable_min_food_stock: PropTypes.bool,
  }).isRequired,
};

import styled from 'styled-components';
import {
  Grid,
  Paper,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 1920px;
  width: 100%;
  padding: 40px;

  text-align: left;
`;

export const StyledGrid = styled(Grid)`
  margin-bottom: 16px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
`;

export const StyledTableRow = styled(TableRow)`
  background: ${props => props.background || colors.white};
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const StyledTableCellEllipsis = styled(TableCell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

export const StyledTableCellCons = styled(TableCell)`
  display: flex;
  flex-direction: column;
  min-width: 120px;
`;

export const ContentPrint = styled.div`
  display: none;
`;

import { useQuery } from "react-query";
import apiV2 from "~/services/apiV2";
import { formatDateOnly, formatNumber } from '~/utils/format';

export async function getOperationalCosts(page, limit, active, date, order) {
  const { data } = await apiV2.get(`v1/feedlot/admin/operational-cost`, {
    params: {
      active,
      date,
      order,
      page,
      limit,
    }
  });

  const newData = data.data.map(operationalCost => ({
    ...operationalCost,
    formattedInitialDate: formatDateOnly(operationalCost.initial_date.replace('Z', '')),
    formattedFinalDate: operationalCost.final_date
      ? formatDateOnly(operationalCost.final_date.replace('Z', ''))
      : '',
    formattedCost: formatNumber(operationalCost.cost, 2, 2),
  }));

  return {
    operationalCosts: newData,
    total: data.meta.total,
    perPage: data.meta.per_page,
    page: data.meta.current_page,
    lastPage: data.meta.last_page,
  }
}

export function useV2OperationalCost(page, limit, active, date, order) {
  return useQuery(['operationalCost', page, limit, active, date, order], () => getOperationalCosts(page, limit, active, date, order));
}

import styled, { css } from 'styled-components';
import {
  Grid,
  Paper,
  TableContainer,
  TableHead,
  Button,
  TableCell,
  FormControl,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 1400px;
  width: 100%;
  padding: 40px;
`;

export const StyledGrid = styled(Grid)`
  margin-bottom: 32px;
  padding-right: 16px;
  padding-top: 16px;
`;

export const StyledPaperTable = styled(Paper)`
  width: 100%;
  padding-bottom: 16px;
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  color: ${colors.primary};
  font-weight: bold;
  ${props =>
    props.notBorderBottom &&
    css`
      border-bottom: none;
    `}
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.marginTop || 50}px;
  margin-right: 12px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

export const StyledFormControl = styled(FormControl)``;

import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";

export async function getDietManufacturingEfficiency({ date = new Date(), diet }) {
  const { data } = await apiV2.get('/v1/feedlot/report/dietManufacturingEfficiency', {
    params: { date, diet }
  });

  return data
}

export function useApiV2DietManufacturingEfficiency({ date, diet }) {
  return useQuery(['dietManufacturingEfficiency', { date, diet }],
    () => getDietManufacturingEfficiency({ date, diet }),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  );
}

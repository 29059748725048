import React from 'react';
import Swal from 'sweetalert2';
import PropTypes from 'prop-types';

import {
  TableRow,
  TableCell
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

import { useApiV2RemoveTroughSetting } from '~/hooks/apiV2/register/troughSetting/useApiV2RemoveTroughSetting';

import ActionLoader from '~/components/ActionLoader';
import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';
import { formatNumber } from '~/utils/format';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';

const HandleDataTable = ({ troughSetting, showAllTypes, correctionType }) => {
  if (showAllTypes) {
    return (
      <>
        <StyledTableCell align="center">
          {`${formatNumber(troughSetting.value, 0, 4)}%`}
        </StyledTableCell>
        <StyledTableCell align="center">
          {`${formatNumber(troughSetting.value_kg_ms, 2, 2)}`}
        </StyledTableCell>
      </>
    );
  }
  return correctionType === 'per_cent' ? (
    <StyledTableCell align="center">
      {`${formatNumber(troughSetting.value, 0, 4)}%`}
    </StyledTableCell>
  ) : (
    <StyledTableCell align="center">
      {`${formatNumber(troughSetting.value_kg_ms, 2, 2)}`}
    </StyledTableCell>
  );
};

export function CardTableTroughSetting({ troughSetting, showAllTypes, correctionType }) {
  const removeTroughSetting = useApiV2RemoveTroughSetting();

  function handleEdit({ secure_id }) {
    history.push(`/dashboard/troughSetting/edit/${secure_id}`);
  }

  function handleRemove({ secure_id }) {
    async function performRemoveTroughSetting() {
      await removeTroughSetting.mutateAsync(secure_id)
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar esse configuração?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar configuração!',
    }).then(result => {
      if (result.value) {
        performRemoveTroughSetting();
      }
    });
  }

  return (
    <TableRow>
      <TableCell align="center" component="th" scope="row">
        {troughSetting.note}
      </TableCell>
      <StyledTableCellEllipsis align="center">
        {troughSetting.description}
      </StyledTableCellEllipsis>
      <HandleDataTable
        troughSetting={troughSetting}
        showAllTypes={showAllTypes}
        correctionType={correctionType}
      />
      <StyledTableCell align="right">
        {removeTroughSetting.isLoading ? (
          <ActionLoader text="Deletando" />
        ) : (
          <>
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['troughSetting_edit']}
            >
              <ButtonTable
                icon={<EditIcon color="primary" />}
                background={colors.primary}
                lighten={0.6}
                onClick={() => handleEdit(troughSetting)}
              />
            </Can>
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['troughSetting_delete']}
            >
              <ButtonTable
                icon={
                  <DeleteIcon color={
                    troughSetting.has_trough_readings > 0 ? "disabled" : "error"
                  } />
                }
                disabled={troughSetting.has_trough_readings > 0}
                background={
                  troughSetting.has_trough_readings > 0
                    ? colors.grey
                    : colors.error
                }
                onClick={() => handleRemove(troughSetting)}
              />
            </Can>
          </>
        )}
      </StyledTableCell>
    </TableRow>
  )
}

HandleDataTable.propTypes = {
  troughSetting: PropTypes.objectOf().isRequired,
  showAllTypes: PropTypes.bool.isRequired,
  correctionType: PropTypes.string.isRequired,
};

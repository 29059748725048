import React from 'react';
import Swal from 'sweetalert2';

import {
  TableCell,
  TableRow,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

// Query hooks
import { useApiV2RemoveTroughReading } from '~/hooks/apiV2/foodManagement/troughReading/useApiV2RemoveTroughReading';

import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell,
  CheckedBox
} from './styles';
import { formatNamePaddock } from '~/utils/format';
import ActionLoader from '~/components/ActionLoader';
import { format } from 'date-fns';

export function CardTableTroughReading({ troughReading, settings, allDates }) {
  const removeTroughReading = useApiV2RemoveTroughReading();

  function renderLogTroughReading(troughReading) {
    let cIndex = 0;

    const log = allDates.map(day => {
      if (troughReading.logTroughReadings[cIndex]) {
        const newCurrentDate = format(
          new Date(troughReading.logTroughReadings[cIndex].date),
          'yyyy-MM-dd'
        );

        if (newCurrentDate === day) {
          cIndex += 1;

          return (
            <TableCell align="center">
              {troughReading.logTroughReadings[cIndex - 1].troughSetting.note}
            </TableCell>
          );
        }
      }
      return <TableCell align="right" />;
    });

    cIndex = 0;
    return log;
  }

  function handleRemove(secure_id) {
    async function performDeletion() {
      await removeTroughReading.mutateAsync(secure_id);
    }
    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar a leitura do cocho?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.primary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar leitura!',
    }).then(result => {
      if (result.value) {
        performDeletion();
      }
    });
  }

  return (
    <TableRow key={troughReading.id}>
      <StyledTableCell component="th" scope="row">
        {formatNamePaddock(
          settings,
          troughReading.paddock
        )}
      </StyledTableCell>
      <TableCell>
        <CheckedBox
          backgroundColor="primary"
          textColor="white"
          type="button"
          isReplicate={false}
        >
          <p>{troughReading.troughSetting.note}</p>
        </CheckedBox>
      </TableCell>

      {renderLogTroughReading(troughReading)}

      <Can
        checkRole={[
          'administrator',
          'owner',
          'manager',
        ]}
      >
        <StyledTableCell align="right">

          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['troughReading_delete']}
          >
            <ButtonTable
              icon={
                removeTroughReading.isLoading ? (
                  <ActionLoader text="Deletando" />
                ) :
                  (
                    <DeleteIcon color="error" />
                  )
              }
              background={colors.error}
              onClick={() => handleRemove(troughReading.secure_id)}
            />
          </Can>

        </StyledTableCell>
      </Can>
    </TableRow>
  )
}

import { useQuery } from 'react-query';
import { format } from 'date-fns';
import _ from 'lodash';
import apiV2 from '~/services/apiV2';

export async function getActivePaddock({ date }) {
  const { data } = await apiV2.get(`/v1/feedlot/report/activeLot`, {
    params: { date }
  });

  const groupActiveLot = _.chain(data.lotsActive)
    .groupBy('paddock.line.module.name')
    .map(value => {
      const line = _.chain(value)
        .groupBy('paddock.line.name')
        .map(valueLine => {
          const paddock = _.chain(valueLine).sortBy('paddock.name').value();
          const a = _.chain(paddock)
            .groupBy('paddock.secure_id')
            .map(valuePaddock => {
              const code = valuePaddock
                .map(valueCode => valueCode.code)
                .reduce((acc, value) => {
                  return acc ? `${acc}, ${value}` : `${value}`;
                }, '');
              const breed_names = valuePaddock
                .map(valueBreed => valueBreed.breed_names)
                .reduce((acc, value) => {
                  return acc && !acc.includes(value)
                    ? `${acc}, ${value}`
                    : `${value}`;
                }, '');
              const types = valuePaddock
                .map(valueTypes => valueTypes.types)
                .reduce((acc, value) => {
                  return acc && !acc.includes(value)
                    ? `${acc}, ${value}`
                    : `${value}`;
                }, '');
              const amount_animals = valuePaddock
                .map(valueAmount => valueAmount.amount_animals)
                .reduce((acc, value) => {
                  return acc ? acc + value : value;
                }, 0);
              const date_animal_inputs = valuePaddock
                .map(valueDate => valueDate.date_animal_inputs)
                .reduce((acc, value) => {
                  return acc ? acc + Date.parse(value) : Date.parse(value);
                }, 0);
              const days_active = valuePaddock
                .map(valueDaysActive => valueDaysActive.days_active)
                .reduce((acc, value) => {
                  return acc ? acc + value : value;
                }, 0);
              const days_treatments = valuePaddock
                .map(valueDaysTreatments => valueDaysTreatments.days_treatments)
                .reduce((acc, value) => {
                  return acc ? acc + value : value;
                }, 0);
              const average_weight_input = valuePaddock
                .map(
                  valueAverageWeight => valueAverageWeight.average_weight_input * valueAverageWeight.amount_animals
                )
                .reduce((acc, value) => {
                  return acc ? acc + value : value;
                }, 0);
              const diet_name = valuePaddock
                .map(valueDiet => valueDiet.diet_name)
                .reduce((acc, value) => {
                  return acc && !acc.includes(value)
                    ? `${acc}, ${value}`
                    : `${value}`;
                }, '');
              const dietTarget = valuePaddock
                .map(valueDiet => valueDiet.dietTarget)
                .reduce((acc, value) => {
                  return acc && !acc.includes(value)
                    ? `${acc}, ${value}`
                    : `${value}`;
                }, '');
              const total_average_consumption_performed_mo = valuePaddock
                .map(
                  valueTotalAverageMo =>
                    valueTotalAverageMo.total_average_consumption_performed_mo
                )
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);
              const total_average_consumption_performed_ms = valuePaddock
                .map(
                  valueTotalAverageMs =>
                    valueTotalAverageMs.total_average_consumption_performed_ms
                )
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);
              const total_percent_performed_pv = valuePaddock
                .map(
                  valueTotalPercentPv =>
                    valueTotalPercentPv.total_percent_performed_pv
                )
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);

              const mo0 = valuePaddock
                .map(valueMo0 => valueMo0.mo[0])
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);

              const mo1 = valuePaddock
                .map(valueMo1 => valueMo1.mo[1])
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);

              const mo2 = valuePaddock
                .map(valueMo2 => valueMo2.mo[2])
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);

              const mo = [
                mo0 / valuePaddock.length,
                mo1 / valuePaddock.length,
                mo2 / valuePaddock.length,
              ];

              const ms0 = valuePaddock
                .map(valueMs0 => valueMs0.ms[0])
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);

              const ms1 = valuePaddock
                .map(valueMs1 => valueMs1.ms[1])
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);

              const ms2 = valuePaddock
                .map(valueMs2 => valueMs2.ms[2])
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);

              const ms = [
                ms0 / valuePaddock.length,
                ms1 / valuePaddock.length,
                ms2 / valuePaddock.length,
              ];

              const pv0 = valuePaddock
                .map(valuePv0 => valuePv0.percentPV[0])
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);

              const pv1 = valuePaddock
                .map(valuePv1 => valuePv1.percentPV[1])
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);

              const pv2 = valuePaddock
                .map(valuePv2 => valuePv2.percentPV[2])
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);

              const percentPV = [
                pv0 / valuePaddock.length,
                pv1 / valuePaddock.length,
                pv2 / valuePaddock.length,
              ];

              const price_animal_day = valuePaddock
                .map(valueAnimalDay => valueAnimalDay.price_animal_day)
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);

              const average_current_weight = valuePaddock
                .map(
                  valueCurrentWeight =>
                    valueCurrentWeight.average_current_weight * valueCurrentWeight.amount_animals
                )
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);

              const average_weight_forecast = valuePaddock
                .map(
                  valueWeightForecast =>
                    valueWeightForecast.average_weight_forecast
                )
                .reduce((acc, value) => {
                  return acc ? acc + Number(value) : Number(value);
                }, 0);

              const date_forecast_slaughter_length = valuePaddock.filter(
                valueDate => valueDate.date_forecast_slaughter
              ).length;

              const date_forecast_slaughter = valuePaddock
                .map(valueDate => valueDate.date_forecast_slaughter)
                .reduce((acc, value) => {
                  return value
                    ? acc
                      ? acc + Date.parse(value)
                      : Date.parse(value)
                    : acc + 0;
                }, 0);

              return {
                code,
                breed_names,
                types,
                amount_animals,
                date_animal_inputs: format(
                  new Date(date_animal_inputs / valuePaddock.length),
                  'yyyy-MM-dd'
                ),
                days_active: days_active / valuePaddock.length,
                days_treatments: days_treatments / valuePaddock.length,
                average_weight_input:
                  Number((average_weight_input / amount_animals).toFixed(2)),
                diet_name,
                dietTarget,
                total_average_consumption_performed_mo:
                  total_average_consumption_performed_mo / valuePaddock.length,
                total_average_consumption_performed_ms:
                  total_average_consumption_performed_ms / valuePaddock.length,
                total_percent_performed_pv:
                  total_percent_performed_pv / valuePaddock.length,
                mo,
                ms,
                percentPV,
                price_animal_day: price_animal_day / valuePaddock.length,
                average_current_weight:
                  average_current_weight / amount_animals,
                average_weight_forecast: date_forecast_slaughter_length
                  ? average_weight_forecast / date_forecast_slaughter_length
                  : null,
                date_forecast_slaughter: date_forecast_slaughter
                  ? format(
                    new Date(
                      date_forecast_slaughter / date_forecast_slaughter_length
                    ),
                    'yyyy-MM-dd'
                  )
                  : null,
                paddock: valuePaddock[0].paddock,
              };
            })
            .value();

          return { line: paddock[0].paddock.line.name, paddocks: a };
        })
        .value();
      return {
        module: line[0].paddocks[0].paddock.line.module.name,
        lines: line,
      };
    })
    .value();

  return { activeLots: groupActiveLot, resumeLots: data.resumeLots };
}

export function useApiV2ActivePaddock({ date }) {
  return useQuery(['activePaddock', { date }], () => getActivePaddock({ date }));
}

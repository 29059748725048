import React, { useState } from 'react';
import * as Yup from 'yup';
import DateFnsUtils from '@date-io/date-fns';
import { Formik, Form, ErrorMessage } from 'formik';
import {
  Grid,
  TextField,
  Divider,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { format, isBefore, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Query
import { useApiV2AddMsCorrection } from '~/hooks/apiV2/foodManagement/msCorrection/useApiV2AddMsCorrection';
import { useApiV2ShowDataMsCorrection } from '~/hooks/apiV2/foodManagement/msCorrection/useApiV2ShowDataMsCorrection';

import {
  Container,
  StyledPaper,
  StyledFodGrid,
  StyledGrid,
  StyledGridItem,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';

import history from '~/services/history';
import NumberFormatForm from '~/components/NumberFormatForm';
import ButtonSubmit from '~/components/ButtonSubmit';
import Loader from '~/components/Loader';

const schema = Yup.object().shape({
  percent_ms: Yup.number()
    .typeError('Insira um valor válido')
    .positive('O número deve ser maior que 0')
    .required('Informe a porcentagem de MS'),
  date: Yup.string().required('Necessário informar um data'),
});

export default function Add() {
  const [currentFood, setCurrentFood] = useState(null);

  // Query
  const { data: msCorrectionData, isLoading, error } = useApiV2ShowDataMsCorrection();
  const addMSCorrection = useApiV2AddMsCorrection();

  async function handleSelectFood(value, dateCorrection, setFieldValue) {
    if (value) {
      setCurrentFood(value);

      if (value.lastCorrection) {
        setFieldValue('last_ms_correction', parseISO(value.lastCorrection));

        if (isBefore(new Date(dateCorrection), parseISO(value.lastCorrection))) {
          setFieldValue('date', parseISO(value.lastCorrection));
        }
      }
    } else {
      setFieldValue('last_ms_correction', null);
      setCurrentFood(null);
    }
  }

  function handleChangeEntryDate(date, setFieldValue) {
    setFieldValue('date', date);
  }

  async function handleSubmit(data) {
    const newData = {
      date: data.date,
      percentMs: data.percent_ms,
      oldPercentMs: currentFood.percent_ms,
      foodSecureId: currentFood.secure_id
    };

    await addMSCorrection.mutateAsync(newData)
  }

  function handleBack() {
    history.goBack();
  }

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    handleBack();
    return null;
  }

  return (
    <center>
      <Container>
        <StyledPaper>
          <Formik
            validationSchema={schema}
            initialValues={{
              percent_ms: '',
              date: new Date(),
              last_ms_correction: null
            }}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, errors, touched, isSubmitting }) => (
              <Form>
                <StyledFodGrid container justify="space-between">
                  <Grid sm md lg xl />
                  <Grid item xs sm={6} md lg xl>
                    <Autocomplete
                      id="food-input"
                      name="food"
                      size="small"
                      noOptionsText="Sem opções"
                      options={msCorrectionData.foods}
                      getOptionLabel={option => option.value}
                      onChange={(e, value) => {
                        handleSelectFood(value, values.date, setFieldValue);
                      }}
                      renderInput={params => (
                        <TextField {...params} label="Alimento" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid sm md lg xl />
                </StyledFodGrid>

                <Divider ligth />

                <StyledGrid container justify="center" spacing={2}>
                  <Grid sm={1} />
                  <StyledGridItem item sm xs={12}>
                    <TextField
                      id="current_percent_ms"
                      label="% MS atual"
                      variant="outlined"
                      size="small"
                      disabled
                      fullWidth
                      InputLabelProps={{ shrink: currentFood }}
                      value={(currentFood && currentFood.percent_ms) || ''}
                    />
                  </StyledGridItem>

                  <Grid sm={1} />

                  <StyledGridItem item sm xs={12}>
                    <TextField
                      id="initial_date"
                      label="Data da ultima correção"
                      variant="outlined"
                      size="small"
                      disabled
                      fullWidth
                      InputLabelProps={{
                        shrink: values.last_ms_correction,
                      }}
                      value={
                        (values.last_ms_correction &&
                          format(new Date(values.last_ms_correction), 'dd/MM/yyyy')) ||
                        ''
                      }
                    />
                  </StyledGridItem>
                  <Grid sm={1} />
                </StyledGrid>

                <Divider ligth />

                <StyledGrid container justify="center" spacing={2}>
                  <Grid sm={1} />
                  <StyledGridItem item sm xs={12}>
                    <NumberFormatForm
                      name="percent_ms"
                      label="Nova % MS"
                      variant="outlined"
                      size="small"
                      fullWidth
                      autoComplete="nope"
                      setFieldValue={setFieldValue}
                    />
                  </StyledGridItem>

                  <Grid sm={1} />

                  <StyledGridItem item sm xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                      <DatePicker
                        name="date"
                        disableToolbar
                        variant="inline"
                        inputVariant="outlined"
                        format="dd/MM/yyyy"
                        size="small"
                        label="Data nova correção"
                        autoOk
                        fullWidth
                        maxDate={new Date()}
                        // minDate={
                        //   lastMsCorrection
                        //     ? new Date(lastMsCorrection)
                        //     : new Date('1990-01-01')
                        // }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <EventIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        value={values.date}
                        onChange={date =>
                          handleChangeEntryDate(date, setFieldValue)
                        }
                        style={{ marginTop: 0, marginBottom: 0 }}
                        error={!!errors.date && touched.date}
                      />
                      <ErrorMessage name="date" component={Error} />
                    </MuiPickersUtilsProvider>
                  </StyledGridItem>
                  <Grid sm={1} />
                </StyledGrid>

                <ContentSubmitButtons>
                  <StyledSubmitButton
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={handleBack}
                  >
                    Cancelar
                  </StyledSubmitButton>
                  <ButtonSubmit title="Cadastrar" loading={isSubmitting} />
                </ContentSubmitButtons>
              </Form>
            )}
          </Formik>
        </StyledPaper>
      </Container>
    </center>
  );
}

import styled from 'styled-components';
import { darken } from 'polished';
import {
  Grid,
  Button,
  IconButton,
  RadioGroup,
  TextField,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */

  width: 100%;
  padding: 20px;
  /* max-width: 600px;
  padding: 20px;
  border-radius: 5px; */
  overflow: hideen;

  background: ${colors.white};
`;

export const StyledTextField = styled(TextField)`
  margin: 5px 0px;
`;

export const StyledGrid = styled(Grid)`
  margin-bottom: ${props => props.marginBottom || 16}px;
  margin-top: ${props => props.marginTop || 0}px;
`;

export const StyledGridAndButton = styled(Grid)`
  height: 100%;
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: 5px;
  color: ${colors.white};
  background: ${colors.success};
  transition: background 1s;

  &:hover {
    background: ${darken(0.06, colors.success)};
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
`;

export const Error = styled.span`
  margin: 8px 14px 0;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

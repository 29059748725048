import { useQuery } from "react-query";
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';

export async function getModules() {
  try {
    const { data } = await apiV2.get(`v1/feedlot/global/list/module`)

    return {
      modules: data
    }
  } catch {
    toast.error('Falha ao tentar carregar os módulos!');
  }
}

export function useApiV2GlobalModule() {
  return useQuery(
    ['modules'],
    () => getModules(),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

import React from 'react';
import Swal from 'sweetalert2';

import {
  TableCell,
  TableRow,
  Switch,
  Tooltip,
  Fade
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

// Query hooks
import { useApiV2EditFeed } from '~/hooks/apiV2/register/feed/useApiV2EditFeed';
import { useApiV2RemoveFeed } from '~/hooks/apiV2/register/feed/useApiV2RemoveFeed';

import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';
import { formatNumber } from '~/utils/format';
import ActionLoader from '~/components/ActionLoader';

import {
  StyledTableCell,
  StyledTableCellEllipsis
} from './styles';

export function CardTableFeed({ feed }) {
  // Query
  const chagenStatusFeed = useApiV2EditFeed()
  const removeFeed = useApiV2RemoveFeed();

  function existRelations({ feed_has_treatments, feed_has_diets, feed_has_feed_foods, feed_has_pre_mix_foods }) {
    if (feed_has_treatments > 0 || feed_has_diets > 0 || feed_has_feed_foods > 0 || feed_has_pre_mix_foods > 0) {
      return true;
    }

    return false;
  }

  function messageExistTreatment({ has_treatments }) {
    if (has_treatments > 0) {
      return 'Ração/Mistura já foi utilizada em uma dieta que está relacionado a um tratamento já em uso';
    }

    return null;
  }

  function handleVerifyProductStock({ total_amount_ton }) {
    if (total_amount_ton !== 0) {
      return true;
    }
    return false;
  }

  function handleRemoveFeed({ secure_id }) {
    async function performRemoveFeed() {
      await removeFeed.mutateAsync(secure_id);
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar essa ração?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar ração!',
    }).then(result => {
      if (result.value) {
        performRemoveFeed();
      }
    });
  }

  function handleEditDiet({ secure_id }) {
    history.push(`/dashboard/feed/edit/${secure_id}`);
  }

  async function handleChangeActive({ secure_id, active }) {
    await chagenStatusFeed.mutateAsync({ secure_id, active: !active })
  }

  return (
    <TableRow key={feed.id}>
      <StyledTableCellEllipsis component="th" scope="row">
        {feed.name}
      </StyledTableCellEllipsis>
      <StyledTableCellEllipsis align="left">
        {feed.description}
      </StyledTableCellEllipsis>
      <StyledTableCell align="right">
        {`${formatNumber(feed.percent_ms, 2, 2)} %`}
      </StyledTableCell>
      <StyledTableCell align="right">
        {`${formatNumber(
          feed.total_percent_incl_mo,
          2,
          2
        )} %`}
      </StyledTableCell>
      <TableCell align="center">
        {feed.updated_at}
      </TableCell>
      <Can
        checkRole={['administrator', 'owner', 'beefer']}
        checkPermission={['feed_edit']}
      >
        {can =>
          can ? (
            <TableCell align="right">
              <Switch
                color="primary"
                checked={feed.active}
                disabled={handleVerifyProductStock(feed) || chagenStatusFeed.isLoading}
                onChange={() => handleChangeActive(feed)}
              />
            </TableCell>
          ) : (
            <TableCell align="right">
              <Switch
                color="primary"
                checked={feed.active}
                disabled
              />
            </TableCell>
          )}
      </Can>
      <StyledTableCell align="right">
        <Tooltip
          TransitionComponent={Fade}
          TransitionProps={{ timeout: 600 }}
          title={messageExistTreatment(feed)}
          disableHoverListener={!existRelations(feed)}
        >
          <span>
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['feed_edit']}
            >
              <ButtonTable
                icon={<EditIcon />}
                background={colors.primary}
                lighten={0.6}
                onClick={() => handleEditDiet(feed)}
              />
            </Can>
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['feed_delete']}
            >
              <ButtonTable
                icon={
                  removeFeed.isLoading ? (
                    <ActionLoader text="Deletando" />
                  ) :
                    (
                      <DeleteIcon color={
                        existRelations(feed) ? "disabled" : "error"
                      } />
                    )
                }
                background={
                  existRelations(feed)
                    ? colors.grey
                    : colors.error
                }
                disabled={existRelations(feed)}
                onClick={() => handleRemoveFeed(feed)}
              />
            </Can>
          </span>
        </Tooltip>
      </StyledTableCell>
    </TableRow>
  )
}

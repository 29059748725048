import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import { Grid, TextField, Divider, Switch } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

// Query
import { useApiV2GlobalFood } from '~/hooks/apiV2/global/food/useApiV2GlobalFood';
import { useApiV2AddFoodCorrection } from '~/hooks/apiV2/admin/foodCorrection/useApiV2AddFoodCorrection';

import {
  Container,
  StyledPaper,
  StyledFodGrid,
  Title,
  StyledGrid,
  StyledGridItem,
  StyledFormControlLabel,
  Column,
  ContentSubmitButtons,
  StyledSubmitButton,
  Error,
} from './styles';

import history from '~/services/history';
import NumberFormatForm from '~/components/NumberFormatForm';
import ButtonSubmit from '~/components/ButtonSubmit';
import InputDatePicker from '~/components/InputDatePicker';

export default function Add() {
  const [listFoods, setListFoods] = useState([]);
  const [foodId, setFoodId] = useState(null);
  const [oldTotalAmountTon, setOldTotalAmountTon] = useState(null);
  const [oldPriceTon, setOldPriceTon] = useState(null);
  const [oldTotalPrice, setOldTotalPrice] = useState(null);
  const [activePriceTon, setActivePriceTon] = useState(false);

  // Query
  const { data } = useApiV2GlobalFood([true], 'admin', null);
  const addFoodCorrection = useApiV2AddFoodCorrection();

  const schema = Yup.object().shape({
    date: Yup.date()
      .typeError('Insira uma data válida')
      .required('Informe a data'),
    obs: Yup.string()
      .required('Informe a observação')
      .min(10, 'A observação deve conter no mínimo 10 carcaters')
      .max(60, 'A observação deve conter no máximo 35 carcaters'),
    total_amount_ton: Yup.number()
      .typeError('Insira um valor válido')
      .required('Informe a nova quantidade'),
    price_ton: activePriceTon
      ? Yup.number()
        .typeError('Insira um valor válido')
        .positive('O número deve ser positivo')
        .required('Informe o novo preço/ton')
      : Yup.number().nullable(),
    total_price: Yup.number().nullable(),
  });

  const CalcPrice = () => {
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
      if (!values.total_amount_ton === '') {
        if (activePriceTon) {
          setFieldValue('price_ton', '');
          setFieldValue('total_price', '');
        } else {
          setFieldValue('price_ton', oldPriceTon);
          setFieldValue('total_price', oldTotalPrice);
        }
      } else {
        if (!activePriceTon) {
          setFieldValue(
            'price_ton',
            values.total_amount_ton > 0
              ? oldTotalPrice / values.total_amount_ton
              : 0
          );
        }

        setFieldValue(
          'total_price',
          values.total_amount_ton * values.price_ton
        );
      }
    }, [
      setFieldValue,
      values.price_ton,
      values.total_amount_ton,
      values.total_price,
    ]);

    return null;
  };

  function handleChangeFood(value) {
    if (value) {
      setFoodId(value.secure_id);
      setOldTotalAmountTon(value.total_amount_ton);
      setOldPriceTon(value.total_price / value.total_amount_ton);
      setOldTotalPrice(value.total_price);
    } else {
      setFoodId(null);
      setOldTotalAmountTon(null);
      setOldPriceTon(null);
      setOldTotalPrice(null);
    }
  }

  function handleChangeEnablePriceTon() {
    setActivePriceTon(!activePriceTon);
  }

  async function handleSubmit(values) {
    const data = {
      date: values.date, //format(values.date, "yyyy-MM-dd HH:mm:ss", { locale: ptBR }),
      obs: values.obs,
      previousAmountTon: -51.3224,
      priceTon: values.price_ton,
      foodSecureId: foodId,
      totalAmountTon: values.total_amount_ton,
      totalPrice: values.total_price,
    }

    await addFoodCorrection.mutateAsync(data);
  }

  function handleBack() {
    history.goBack();
  }

  useEffect(() => {
    if (data && data.length > 0) {
      const newList = data.map(food => ({
        secure_id: food.secure_id,
        name: food.name,
        total_amount_ton: food.total_amount_ton,
        price_ton: food.price_ton,
        total_price: food.total_price,
      }));

      setListFoods(newList);
    }
  }, [data]);

  return (
    <center>
      <Container>
        <StyledPaper>
          <Formik
            validationSchema={schema}
            initialValues={{
              date: new Date(),
              obs: '',
              total_amount_ton: '',
              price_ton: '',
              total_price: '',
            }}
            onSubmit={handleSubmit}
          >
            {({ handleChange, setFieldValue, values, errors, touched, isSubmitting }) => (
              <Form>
                <StyledFodGrid container justify="space-between" spacing={2}>
                  <Grid item sm md lg xl>
                    <Autocomplete
                      name="food"
                      size="small"
                      noOptionsText="Sem opções"
                      options={listFoods}
                      getOptionLabel={option => option.name}
                      onChange={(event, value) => handleChangeFood(value)}
                      renderInput={params => (
                        <TextField {...params} label="Alimento" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item sm md lg xl>
                    <InputDatePicker
                      name="date"
                      label="Data"
                      fullWidth
                      selectedDate={values.date}
                      handleChangeDate={date => setFieldValue('date', date)}
                      maxDate={new Date()}
                    />
                    <ErrorMessage name="error" component={Error} />
                  </Grid>
                </StyledFodGrid>

                <Divider ligth />

                <Title>Estoque atual</Title>
                <StyledGrid container spacing={2}>
                  <Grid item sm xs={12}>
                    <NumberFormatForm
                      label="Quantidade (ton)"
                      variant="outlined"
                      size="small"
                      disabled
                      fullWidth
                      fixedDecimalScale
                      decimalScale={4}
                      value={oldTotalAmountTon}
                    />
                  </Grid>
                  <Grid item sm xs={12}>
                    <NumberFormatForm
                      label="Preço/ton"
                      variant="outlined"
                      size="small"
                      prefix="R$ "
                      disabled
                      fullWidth
                      fixedDecimalScale
                      decimalScale={2}
                      value={oldPriceTon}
                    />
                  </Grid>
                  <Grid item sm xs={12}>
                    <NumberFormatForm
                      label="Preço total"
                      variant="outlined"
                      size="small"
                      prefix="R$ "
                      disabled
                      fullWidth
                      fixedDecimalScale
                      decimalScale={2}
                      value={oldTotalPrice}
                    />
                  </Grid>
                </StyledGrid>

                <Divider ligth />

                <Title>Novo estoque</Title>
                <StyledFormControlLabel
                  style={{ marginTop: 8 }}
                  value={activePriceTon}
                  label="Alterar preço/ton"
                  control={<Switch color="primary" />}
                  labelPlacement="end"
                  onChange={() => handleChangeEnablePriceTon(setFieldValue)}
                />
                <StyledGrid container spacing={2}>
                  <StyledGridItem item sm xs={12}>
                    <Column>
                      <NumberFormatForm
                        name="total_amount_ton"
                        label="Quantidade (ton)"
                        variant="outlined"
                        size="small"
                        autoComplete="nope"
                        allowNegative={false}
                        required
                        fullWidth
                        fixedDecimalScale
                        decimalScale={4}
                        setFieldValue={setFieldValue}
                        value={values.total_amount_ton}
                        error={
                          !!errors.total_amount_ton && touched.total_amount_ton
                        }
                      />
                      <ErrorMessage name="total_amount_ton" component={Error} />
                    </Column>
                  </StyledGridItem>
                  <StyledGridItem item sm xs={12}>
                    <Column>
                      <NumberFormatForm
                        name="price_ton"
                        label="Preço/ton"
                        variant="outlined"
                        size="small"
                        autoComplete="nope"
                        prefix="R$ "
                        disabled={!activePriceTon}
                        allowNegative={false}
                        required
                        fullWidth
                        fixedDecimalScale
                        decimalScale={2}
                        setFieldValue={setFieldValue}
                        value={values.price_ton}
                        error={!!errors.price_ton && touched.price_ton}
                      />
                      <ErrorMessage name="price_ton" component={Error} />
                    </Column>
                  </StyledGridItem>
                  <StyledGridItem item sm xs={12}>
                    <NumberFormatForm
                      name="total_price"
                      label="Preço total"
                      variant="outlined"
                      size="small"
                      prefix="R$ "
                      disabled
                      fullWidth
                      fixedDecimalScale
                      decimalScale={2}
                      value={values.total_price}
                      setFieldValue={setFieldValue}
                    />
                  </StyledGridItem>
                </StyledGrid>

                <Grid container>
                  <Grid item sm>
                    <TextField
                      name="obs"
                      variant="outlined"
                      label="Observação *"
                      autoComplete="nope"
                      fullWidth
                      multiline
                      error={!!errors.obs && touched.obs}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="obs" component={Error} />
                  </Grid>
                </Grid>

                <ContentSubmitButtons>
                  <ButtonSubmit title="Cadastrar" loading={isSubmitting} />
                  <StyledSubmitButton
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={handleBack}
                  >
                    Cancelar
                  </StyledSubmitButton>
                </ContentSubmitButtons>

                <CalcPrice />
              </Form>
            )}
          </Formik>
        </StyledPaper>
      </Container>
    </center>
  );
}

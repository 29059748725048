import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import {
  InputAdornment,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

import { formatNumber, roundPrecision } from '~/utils/format';
import {
  ContentCapacity,
  SpaceTranspCapacity,
  ContentNumber,
  StyledTextFieldNumber,
  StyledTableHead,
  TitleTable,
} from './styles';

export default function TablePreMix({ food, setFood }) {
  const [capacity, setCapacity] = useState(null);
  const [totalInclMS, setTotalInclMS] = useState(0);
  const [totalInclMO, setTotalInclMO] = useState(0);
  const [totalCapacity, setTotalCapacity] = useState(0);

  function callBackCapacity(value) {
    if (value) {
      const newFoods = food.preMixes.map(mix => {
        const newCapacity = roundPrecision(
          parseFloat((mix.percent_incl_mo_food / 100) * value)
        ).toFixed(4);

        return {
          ...mix,
          capacity_in_the_wagon: newCapacity,
        };
      });

      console.log({ ...food, preMixes: newFoods })

      setFood({ ...food, preMixes: newFoods });
    } else {
      const newFoods = food.preMixes.map(mix => ({
        ...mix,
        capacity_in_the_wagon: null,
      }));

      setFood({ ...food, preMixes: newFoods });
    }
  }

  function handleChangeCapacity(value) {
    setCapacity(value);

    callBackCapacity(value);
  }

  useEffect(() => {
    if (food.preMixes.length > 0) {
      const newTotalInclMS = food.preMixes.reduce(
        (total, currentFood) =>
          total + parseFloat(currentFood.percent_incl_ms_food.toFixed(4)),
        0
      );

      const newTotalInclMO = food.preMixes.reduce(
        (total, currentFood) =>
          total + parseFloat(currentFood.percent_incl_mo_food.toFixed(4)),
        0
      );

      const totalWagonCapacity = food.preMixes.reduce(
        (total, currentFood) =>
          total + parseFloat(currentFood.capacity_in_the_wagon),
        0
      );

      setTotalInclMS(newTotalInclMS);
      setTotalInclMO(newTotalInclMO);
      setTotalCapacity(totalWagonCapacity);
    }
  }, [food.preMixes]);

  return (
    <>
      <ContentCapacity>
        <SpaceTranspCapacity />
        <ContentNumber>
          <NumberFormat
            id={`capcity-input-${food.secure_id}`}
            customInput={StyledTextFieldNumber}
            label="Capacidade"
            variant="outlined"
            placeholder="0"
            min="0.00"
            decimalScale={0}
            autoComplete="nope"
            size="small"
            thousandSeparator="."
            decimalSeparator=","
            isNumericString
            value={capacity}
            InputProps={{
              endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
            }}
            onValueChange={values => handleChangeCapacity(values.floatValue)}
          />
        </ContentNumber>
      </ContentCapacity>

      <TableContainer style={{ marginBottom: 24 }}>
        <Table stickyHeader>
          <StyledTableHead>
            <TableRow>
              <TableCell colSpan={2}>Mistura</TableCell>
              <TableCell>Alimento</TableCell>
              <TableCell align="right">MS (%)</TableCell>
              <TableCell align="right">Incl. MS (%)</TableCell>
              <TableCell align="right">Incl. MO (%)</TableCell>
              <TableCell align="right">Montagem misturador (Kg)</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2}>{food.name}</TableCell>
            </TableRow>
            {food.preMixes.map(mix => (
              <TableRow key={mix.secure_id}>
                <TableCell colSpan={2} />
                <TableCell component="th" scope="row">
                  {mix.food.name}
                </TableCell>
                <TableCell align="right">{`${formatNumber(
                  mix.food.percent_ms,
                  0,
                  4
                )}%`}</TableCell>
                <TableCell align="right">{`${formatNumber(
                  mix.percent_incl_ms_food,
                  0,
                  4
                )}%`}</TableCell>
                <TableCell align="right">{`${formatNumber(
                  mix.percent_incl_mo_food,
                  0,
                  4
                )}%`}</TableCell>
                <TableCell align="right">
                  {formatNumber(mix.capacity_in_the_wagon, 0, 4)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={2} />
              <TableCell align="left">
                <TitleTable>Total</TitleTable>
              </TableCell>
              <TableCell />
              <TableCell align="right">
                {`${formatNumber(totalInclMS, 0, 4)}%`}
              </TableCell>
              <TableCell align="right">
                {`${formatNumber(Math.round(totalInclMO), 0, 4)}%`}
              </TableCell>
              <TableCell align="right">
                {formatNumber(totalCapacity, 0, 4)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

TablePreMix.propTypes = {
  food: PropTypes.shape({
    secure_id: PropTypes.number,
    name: PropTypes.string,
    preMixes: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  setFood: PropTypes.func.isRequired,
};

import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

export async function getAnimalTransfersLot(sort, page = 1, limit = 50, date = null, originLot = null, destinationLot = null) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/animal-handling/transferLot?sort=${sort}`, {
      params: { page, limit, date, originLot, destinationLot },
    });

    return {
      animalsTransfer: data.data,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    }
  } catch {
    toast.error('Não foi possível carregar as transf. de Animais por Lote!');
  }
}

export function useApiV2TransferLot(sort, page, limit, date, originLot, destinationLot) {
  return useQuery(['animalTransferLot', sort, page, limit, date, originLot, destinationLot],
    () => getAnimalTransfersLot(sort, page, limit, date, originLot, destinationLot),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    });
}

import React, { useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import {
  Grid,
  Button,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import Chart from 'react-apexcharts';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';

//Query
import { useApiV2ZootechAnalytic } from '~/hooks/apiV2/Report/zootechAnalytic/useApiV2ZootechAnalytic'

import {
  Container,
  StyledPaper,
  Header,
  SubHeader,
  StyledTableContainer,
  StyledTableHead,
  SubItem,
  ContentChart,
  ContentPrint,
} from './styles';

import Print from './Print';
import Filter from './Components/Filter';

import { formatNumber } from '~/utils/format';
import Loader from '~/components/Loader';

const optionsChartPie = {
  responsive: [
    {
      breakpoint: 400,
      options: {
        chart: {
          width: 10,
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
  legend: {
    formatter(val, opts) {
      return `${val} - ${Number(
        opts.w.globals.seriesPercent[opts.seriesIndex]
      ).toFixed(2)}%`;
    },
  },
};

const optionsChartBar = {
  chart: {
    height: 1,
  },

  plotOptions: {
    bar: {
      horizontal: true,
      barHeight: '40%',
      columnWidth: '60%',
    },
  },
  dataLabels: {
    enabled: true,
    formatter(val) {
      return `${val}%`;
    },
  },
  yaxis: {
    max: 100,
  },
  xaxis: {
    categories: ['Excessivo', 'Uniforme', 'Medio', 'Escasso', 'Ausente'],
  },
};

const FoodDetails = ({ feeds }) => {
  return (
    <div style={{ padding: 4 }}>
      {feeds.map(feed => (
        <p key={`${feed.name}.${feed.performed}`}>{`${feed.name} - ${feed.performed}`}</p>
      ))}
    </div>
  );
};

FoodDetails.defaultProps = {
  feeds: [],
};

FoodDetails.propTypes = {
  feeds: PropTypes.arrayOf(PropTypes.shape({})),
};

export default function ZootechnicalLot() {
  const [filters, setFilters] = useState({
    lot: '',
  })
  const [openFoodDetails, setOpenFoodDetails] = useState(false);

  const PrintScreen = useRef();

  const { data: reportData, isLoading } = useApiV2ZootechAnalytic(filters)

  const labelsChart = foods => {
    const arryLabel = [];
    foods.map(item => {
      if (item.feeds.length > 0) {
        item.feeds.map(feed => {
          arryLabel.push(feed.name);
          return feed.name;
        });
      } else {
        arryLabel.push(item.food);
        return item.food;
      }
    });
    return arryLabel;
  };

  const seriesChart = foods => {
    const arrayValue = [];
    foods.map(item => {
      if (item.feeds.length > 0) {
        item.feeds.map(feed => {
          arrayValue.push(Number(feed.performed));
          return Number(feed.performed);
        });
      } else {
        arrayValue.push(Number(item.totalMo));
        return Number(item.totalMo);
      }
    });
    return arrayValue;
  };

  return (
    <>
      <center>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs sm md lg xl>
              <Filter
                setFilters={setFilters}
              />
            </Grid>
            <Grid item xs="auto" sm="auto">
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={!(reportData && Object.keys(reportData).length > 0)}
                    startIcon={<PrintIcon />}
                  >
                    Imprimir
                  </Button>
                )}
                content={() => PrintScreen.current}
              />
            </Grid>
          </Grid>

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <StyledPaper>
                <Header>
                  <h3>Indentificação</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Lote:
                      </Typography>
                      <strong>
                        {reportData && reportData.identification &&
                          reportData.identification.lot}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Fornecedor:
                      </Typography>
                      <strong>
                        {reportData && reportData.identification &&
                          reportData.identification.providers
                            .map(provider => provider)
                            .join(', ')}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Cliente:
                      </Typography>
                      <strong>
                        {reportData && reportData.identification &&
                          reportData.identification.customers
                            .map(client => client)
                            .join(', ')}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Tipo de entrada:
                      </Typography>
                      <strong>
                        {reportData && reportData.identification &&
                          reportData.identification.inputsType
                            .map(inputsType => inputsType)
                            .join(', ')}
                      </strong>
                    </Grid>

                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Raça:
                      </Typography>
                      <strong>
                        {reportData && reportData.identification &&
                          reportData.identification.breeds
                            .map(breed => breed)
                            .join(', ')}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Categoria:
                      </Typography>
                      <strong>
                        {reportData && reportData.identification &&
                          reportData.identification.categories
                            .map(categorie => categorie)
                            .join(', ')}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaper>
              <Grid container spacing={2} style={{ marginTop: 20 }}>
                <Grid item xs sm={4}>
                  <StyledPaper style={{ minHeight: 233 }}>
                    <Header>
                      <h3>Dados de Entrada</h3>
                    </Header>
                    <Grid container>
                      <Grid item xs sm>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Data Média de Entrada:
                          </Typography>
                          <strong>
                            {reportData && reportData.inputsData &&
                              reportData.inputsData.average_date}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Quantidade de Animais:
                          </Typography>
                          <strong>
                            {reportData && reportData.inputsData &&
                              reportData.inputsData.totalInputAmountAnimals}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Peso Vivo Total (Kg):
                          </Typography>
                          <strong>
                            {reportData && reportData.inputsData &&
                              formatNumber(
                                reportData.inputsData.totalInputWeight,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Peso Vivo Médio (Kg/Cab):
                          </Typography>
                          <strong>
                            {reportData && reportData.inputsData &&
                              formatNumber(
                                reportData.inputsData.averageInputWeight,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Rendimento de Carcaça (%):
                          </Typography>
                          <strong>
                            {reportData && reportData.inputsData &&
                              formatNumber(
                                reportData.inputsData.averageInputRC,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Grid>
                <Grid item xs sm={4}>
                  <StyledPaper style={{ minHeight: 233 }}>
                    <Header>
                      <h3>Dados de Saída</h3>
                    </Header>
                    <Grid container>
                      <Grid item xs sm>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Data Média de Saída:
                          </Typography>
                          <strong>
                            {reportData && reportData.outputsData &&
                              reportData.outputsData.average_date}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Quantidade de Animais:
                          </Typography>
                          <strong>
                            {reportData && reportData.outputsData &&
                              reportData.outputsData.totalOutputsSale}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Peso Vivo Total (Kg):
                          </Typography>
                          <strong>
                            {reportData && reportData.outputsData &&
                              formatNumber(
                                reportData.outputsData.totalOutputWeight,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Peso Vivo Médio (Kg/Cab):
                          </Typography>
                          <strong>
                            {reportData && reportData.outputsData &&
                              formatNumber(
                                reportData.outputsData.averageOutputWeight,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Rendimento de Carcaça (%):
                          </Typography>
                          <strong>
                            {reportData && reportData.outputsData &&
                              formatNumber(
                                reportData.outputsData.averageOutputRC,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Grid>
                <Grid item xs sm={4}>
                  <StyledPaper style={{ minHeight: 233 }}>
                    <Header>
                      <h3>Resumo de Movimentação</h3>
                    </Header>
                    <Grid container>
                      <Grid item xs sm>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Entradas (Cab):
                          </Typography>
                          <strong>
                            {reportData && reportData.resumeData &&
                              reportData.resumeData.inputs}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Transf. Entrada (Cab):
                          </Typography>
                          <strong>
                            {reportData && reportData.resumeData &&
                              reportData.resumeData.inputsTransfers}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Total Entradas (Cab):
                          </Typography>
                          <strong>
                            {reportData && reportData.resumeData &&
                              reportData.resumeData.inputsTotal}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Saídas (Cab):
                          </Typography>
                          <strong>
                            {reportData && reportData.resumeData &&
                              reportData.resumeData.outputs}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Transf. Saída (Cab):
                          </Typography>
                          <strong>
                            {reportData && reportData.resumeData &&
                              reportData.resumeData.outputsTransfers}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Mortes (Cab):
                          </Typography>
                          <strong>
                            {reportData && reportData.resumeData &&
                              reportData.resumeData.outputsDeaths}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Refugo de Cocho (Cab):
                          </Typography>
                          <strong>
                            {reportData && reportData.resumeData &&
                              reportData.resumeData.outputsScrap}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Total Saídas (Cab):
                          </Typography>
                          <strong>
                            {reportData && reportData.resumeData &&
                              reportData.resumeData.outputsTotal}
                          </strong>
                        </Grid>
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Grid>
              </Grid>

              <StyledPaper style={{ marginTop: 40 }}>
                <Header>
                  <h3>Desempenho Zootécnico</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Dias de Confinamento:
                      </Typography>
                      <strong>
                        {reportData && reportData.zootechPerformance.confinementDays}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Mortalidade (%):
                      </Typography>
                      <strong>{reportData && reportData.zootechPerformance.motality}</strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Refulgo de Cocho (%):
                      </Typography>
                      <strong>{reportData && reportData.zootechPerformance.refuse}</strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        GTP [Kg/cab]:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(reportData.zootechPerformance.gtp, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        GMD [Kg/cab/dia]:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(reportData.zootechPerformance.gmd, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Carcaça de Entrada (Kg/Cab):
                      </Typography>
                      <strong>
                        {reportData && formatNumber(
                          reportData.zootechPerformance.inputCarcass,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        @ de Entrada:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(
                          reportData.zootechPerformance.atInput,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Carcaça de Saída (Kg/Cab):
                      </Typography>
                      <strong>
                        {reportData && formatNumber(
                          reportData.zootechPerformance.outputCarcass,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        @ de Saída:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(
                          reportData.zootechPerformance.atOutput,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        GTC [Kg/cab]:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(reportData.zootechPerformance.gtc, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        GMC [Kg/cab/dia]:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(reportData.zootechPerformance.gmc, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        @ Colocada:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(
                          reportData.zootechPerformance.atProduced,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Rend. ganho [%]:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(
                          reportData.zootechPerformance.yieldGain,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                  </Grid>

                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Total Consumo MO:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(
                          reportData.zootechPerformance.totalConsumptionMo,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Total Consumo MS:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(
                          reportData.zootechPerformance.totalConsumptionMs,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Cons. MO/cab/dia:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(
                          reportData.zootechPerformance
                            .averageDailyConsumptionMo,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Cons. MS/cab/dia:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(
                          reportData.zootechPerformance
                            .averageDailyConsumptionMs,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Conversão Alimentar:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(
                          reportData.zootechPerformance.foodConversion,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Efeciência Aliementar:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(
                          reportData.zootechPerformance.foodEfficiency,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Consumo Médio (%PV):
                      </Typography>
                      <strong>
                        {reportData && formatNumber(
                          reportData.zootechPerformance.averageConsumptionMsPv,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Efeciência Biológica:
                      </Typography>
                      <strong>
                        {reportData && formatNumber(
                          reportData.zootechPerformance.biologicalEfficiency,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs sm>
                  <Grid container alignItems="center">
                    <SubHeader>
                      <MdSubdirectoryArrowRight size={20} />
                      <h4>Acabamento de Carcaça</h4>
                    </SubHeader>
                    <SubItem>
                      <Grid item xs={2} sm>
                        {!!(reportData && Object.keys(reportData).length > 0) && (
                          <ContentChart>
                            <Chart
                              type="bar"
                              options={optionsChartBar}
                              series={[
                                {
                                  name: 'Porcentagem de animais',
                                  data: [
                                    reportData.carcass &&
                                    reportData.carcass.excessive,
                                    reportData.carcass &&
                                    reportData.carcass.uniform,
                                    reportData.carcass &&
                                    reportData.carcass.median,
                                    reportData.carcass &&
                                    reportData.carcass.scarce,
                                    reportData.carcass &&
                                    reportData.carcass.absent,
                                  ],
                                },
                              ]}
                            />
                          </ContentChart>
                        )}
                      </Grid>
                    </SubItem>
                  </Grid>
                </Grid>
              </StyledPaper>

              {/* <StyledPaper>
                <Header>
                  <h3>Dietas</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm />
                  <StyledTableContainer>
                    <MdSubdirectoryArrowRight size={20} />
                    <Table stickyHeader>
                      <StyledTableHead>
                        <TableRow>
                          <TableCell>Dieta</TableCell>
                          <TableCell align="right">Dias</TableCell>
                          <TableCell align="right">Qtd(kgMO)</TableCell>
                          <TableCell align="right">Qtd(kgMS)</TableCell>
                        </TableRow>
                      </StyledTableHead>
                      <TableBody>
                        {reportData && reportData.diets &&
                          reportData.diets.map(item => (
                            <TableRow key={item.secure_id}>
                              <TableCell>{item.diet}</TableCell>
                              <TableCell align="right">{item.days}</TableCell>
                              <TableCell align="right">
                                {item.mo.toFixed(2)}
                              </TableCell>
                              <TableCell align="right">
                                {item.ms.toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                      <StyledTableHead>
                        <TableRow>
                          <TableCell>Total</TableCell>
                          <TableCell align="right" />
                          <TableCell align="right">
                            {reportData && reportData.diets &&
                              reportData.diets
                                .reduce((totalMo, value) => {
                                  return totalMo + value.mo;
                                }, 0)
                                .toFixed(2)}
                          </TableCell>
                          <TableCell align="right">
                            {reportData.diets &&
                              reportData.diets
                                .reduce((totalMo, value) => {
                                  return totalMo + value.ms;
                                }, 0)
                                .toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </StyledTableHead>
                    </Table>
                  </StyledTableContainer>
                </Grid>
              </StyledPaper>

              <StyledPaper>
                <Header>
                  <h3>Alimentos</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm />
                  <StyledTableContainer>
                    <MdSubdirectoryArrowRight size={20} />
                    <Table stickyHeader>
                      <StyledTableHead>
                        <TableRow>
                          <TableCell>Alimento</TableCell>
                          <TableCell align="right">Dias</TableCell>
                          <TableCell align="right">Qt(kgMO)</TableCell>
                          <TableCell align="right">Qtd/cab/dia </TableCell>
                        </TableRow>
                      </StyledTableHead>
                      <TableBody>
                        {reportData && reportData.foods &&
                          reportData.foods.map(item => (
                            <TableRow key={item.secure_id}>
                              <TableCell>
                                {item.feeds.length > 0 && (
                                  <>
                                    {openFoodDetails ? (
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() =>
                                          setOpenFoodDetails(false)
                                        }
                                        size="small"
                                        color="primary"
                                      >
                                        <ArrowUpwardIcon fontSize="inherit" />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => setOpenFoodDetails(true)}
                                        size="small"
                                        color="primary"
                                      >
                                        <ArrowDownwardIcon fontSize="inherit" />
                                      </IconButton>
                                    )}
                                  </>
                                )}
                                {item.food}
                                {item.feeds.length > 0 && (
                                  <>
                                    {openFoodDetails && (
                                      <FoodDetails feeds={item.feeds} />
                                    )}
                                  </>
                                )}
                              </TableCell>
                              <TableCell align="right">{item.days}</TableCell>
                              <TableCell align="right">
                                {item.totalMo.toFixed(2)}
                              </TableCell>
                              <TableCell align="right">
                                {(
                                  item.totalMo /
                                  (reportData.inputsData
                                    ? reportData.inputsData
                                      .totalInputAmountAnimals
                                    : 1) /
                                  item.days
                                ).toFixed(2)}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                      <StyledTableHead>
                        <TableRow>
                          <TableCell>Total</TableCell>
                          <TableCell align="right" />
                          <TableCell align="right">
                            {reportData.foods &&
                              reportData.foods
                                .reduce((totalMo, value) => {
                                  return totalMo + value.totalMo;
                                }, 0)
                                .toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </StyledTableHead>
                    </Table>
                  </StyledTableContainer>
                </Grid>
              </StyledPaper> */}

              <StyledPaper>
                <Header>
                  <h3>Dieta Média</h3>
                </Header>
                <Grid container>
                  <Grid item xs={8} sm>
                    <SubHeader>
                      <MdSubdirectoryArrowRight size={20} />
                      <h4>Gráfico</h4>
                    </SubHeader>
                    <SubItem>
                      <Grid item xs={2} sm>
                        {!!(reportData && Object.keys(reportData).length > 0) && (
                          <ContentChart>
                            <Chart
                              type="pie"
                              options={{
                                ...optionsChartPie,
                                labels: labelsChart(reportData.foods),
                              }}
                              series={seriesChart(reportData.foods)}
                            />
                          </ContentChart>
                        )}
                      </Grid>
                    </SubItem>
                  </Grid>
                </Grid>
              </StyledPaper>
            </>
          )}

          <ContentPrint>
            {!!(reportData && Object.keys(reportData).length > 0) && (
              <Print ref={PrintScreen} zootechnical={reportData} />
            )}
          </ContentPrint>
        </Container>
      </center>
    </>
  );
}

import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2"

function calcStep1(percent_ms, percent_incl_ms_food) {
  const step1 = percent_incl_ms_food * 100;
  const result = step1 / percent_ms;
  return Number(result.toFixed(2));
}

export async function getDiet(secure_id) {
  if (!secure_id) {
    return null
  }

  const { data } = await apiV2.get(`/v1/feedlot/register/diet/${secure_id}`)

  const newFoods = data.foods.map(food => ({
    ...food,
    calc_step_1: calcStep1(food.percent_ms, food.dietFoodPivot.percent_incl_ms_food),
  }));

  const { inclMO, inclMS } = newFoods.reduce(
    (accumulator, food) => {
      accumulator.inclMO += Number(food.dietFoodPivot.percent_incl_mo_food);
      accumulator.inclMS += Number(food.dietFoodPivot.percent_incl_ms_food);

      return accumulator;
    },
    {
      inclMO: 0,
      inclMS: 0,
    }
  );

  const newData = {
    ...data,
    foods: newFoods,
    total_percent_incl_mo_food: inclMO,
    total_percent_incl_ms_food: inclMS,
  };

  return {
    currentDiet: newData
  }
}

export function useApiV2SelectDiet(secure_id) {
  return useQuery(
    ['diets', secure_id],
    () => getDiet(secure_id)
  )
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import {
  Fade,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Divider,
  InputAdornment,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { useFormik } from 'formik';
import {
  parseISO,
  isSameDay,
  format,
} from 'date-fns';

// Query
import { searchAnimal } from '~/hooks/apiV2/global/searchAnimal/useApiV2SearchAnimal'

import NumberFormatForm from '~/components/NumberFormatForm';
import { ages, types, bodySizes, gender } from '~/utils/options';

import { calcArrobaPrice } from '~/utils/calc';
import { parseUserInput } from '~/utils/format';
import ButtonSubmit from '~/components/ButtonSubmit';

import {
  Container,
  StyledGrid,
  StyledRadioGroup,
  Error,
  StyledTextField,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';
import { useDebouncedPromise } from '~/utils/useDebouncedPromise';

export default function AddNewAnimal({
  // nextAnimal,
  settings,
  visibleModal,
  handleModal,
  aparts,
  breeds,
  animalEdit,
  arrayAnimals,
  setArrayAnimals,
  dateInput
}) {
  const [errorApartOrigin, setErrorApartOrigin] = useState('');
  const [hasAnimalSameSisbov, setHasAnimalSameSisbov] = useState('');
  const [hasAnimalSameBottonRfid, setHasAnimalSameBottonRfid] = useState('');
  const [hasAnimalSameIdentEaring, setHasAnimalSameIdentEaring] = useState('');

  const searchAnimalRequest = async (animalId, type) => {
    const animalSameId = arrayAnimals.find(animal => animal[type] === animalId);

    if (animalEdit) {
      if (animalId === animalEdit[type]) {
        return false
      }
    }

    const resp = await searchAnimal(
      type,
      animalId
    );

    const hasAnimalSameId = resp
      ? !!resp
      : animalSameId
        ? !!animalSameId
        : false;

    if (hasAnimalSameId) {
      if (type === 'sisbov') {
        setHasAnimalSameSisbov('Já existe um animal com o SISBOV');
      } else if (type === 'botton_rfid') {
        setHasAnimalSameBottonRfid('Já existe um animal este BOTTON RFID');
      } else {
        setHasAnimalSameIdentEaring('Já existe um animal este BRINCO');
      }
    } else {
      if (type === 'sisbov') {
        setHasAnimalSameSisbov('');
      } else if (type === 'botton_rfid') {
        setHasAnimalSameBottonRfid('');
      } else {
        setHasAnimalSameIdentEaring('');
      }
    }

    return hasAnimalSameId
  }

  const debounceSearchAnimalBySisbov = useDebouncedPromise(searchAnimalRequest, 1000);
  const debounceSearchAnimalByBottonRfid = useDebouncedPromise(searchAnimalRequest, 1000);
  const debounceSearchAnimalByIdentEaring = useDebouncedPromise(searchAnimalRequest, 1000);

  const schema = Yup.object().shape({
    sisbov: settings.sisbov_active === 'yes'
      ? Yup.string()
        .required('SISBOV obrigatório')
      : Yup.string()
        .nullable(),
    botton_rfid: settings.default_identification === 'botton_rfid'
      ? Yup.string()
        .required('BOTTON RFID obrigatório')
      : Yup.string()
        .nullable(),
    ident_earing: settings.default_identification === 'ident_earing'
      ? Yup.string()
        .required('BRINCO obrigatório')
      : Yup.string()
        .nullable(),
    sex: Yup.string().required('Selecione o sexo'),
    age: Yup.string().required('Selecione a faixa de idade'),
    type: Yup.string().required('Selecione a categoria'),
    body_size: Yup.string().required('Selecione o tipo corporal'),
    input_weight: Yup.number()
      .typeError('Insira um valor válido')
      .positive('O número deve ser maior que 0')
      .min(10, 'O peso deve ser maior')
      .required('Informar o peso médio'),
    percent_rc: Yup.number()
      .typeError('Insira um número válido')
      .required('Informe a porcentagem de RC'),
    average_price: Yup.number()
      .typeError('Insira um número válido')
      .positive('O número deve ser maior que 0')
      .required('Informar o preço médio do animal'),
    boitel_day_price: Yup.number()
      .typeError('Insira um número válido')
      .positive('O número deve ser maior que 0'),
    arroba_price: Yup.number().typeError('Insira um número válido').nullable(),
    weight_forecast: Yup.number()
      .typeError('Insira um número válido')
      .nullable(),
    percent_rc_forecast: Yup.number()
      .typeError('Insira um número válido')
      .nullable(),
    output_price_forecast: Yup.number()
      .typeError('Insira um número válido')
      .nullable(),
    breed_id: Yup.string()
      .typeError('Seleciona uma raça')
      .required('Selecione uma raça'),
  });

  const formik = useFormik({
    enableReinitialize: !!animalEdit,
    validationSchema: schema,
    initialValues: {
      sisbov: animalEdit ? animalEdit.sisbov : '',
      botton_rfid: animalEdit ? animalEdit.botton_rfid : '',
      ident_earing: animalEdit ? animalEdit.ident_earing : '',
      sex: animalEdit ? animalEdit.sex : '',
      age: animalEdit ? animalEdit.age : '',
      type: animalEdit ? animalEdit.type : '',
      body_size: animalEdit ? animalEdit.body_size : '',
      apart: animalEdit ? animalEdit.apart : '',
      input_weight: animalEdit ? animalEdit.input_weight : '',
      percent_rc: animalEdit ? animalEdit.percent_rc : '',
      average_price: animalEdit ? animalEdit.average_price : '',
      arroba_price: animalEdit ? animalEdit.arroba_price : '',
      weight_forecast: animalEdit ? animalEdit.weight_forecast : '',
      percent_rc_forecast: animalEdit
        ? animalEdit.percent_rc_forecast
        : '',
      output_price_forecast: animalEdit
        ? animalEdit.output_price_forecast
        : '',
      breed_id: animalEdit ? animalEdit.breed_id : '',
    },
    onSubmit: handleSubmit
  })

  const { setFieldValue, values, errors, touched, handleChange, handleBlur } = formik

  async function handleSubmit(data) {
    if (errorApartOrigin || hasAnimalSameSisbov || hasAnimalSameBottonRfid || hasAnimalSameIdentEaring) return;

    if (animalEdit && animalEdit[settings.default_identification]) {
      const newArrayAnimals = arrayAnimals.map(animal => {
        if (animal[settings.default_identification] === animalEdit[settings.default_identification]) {
          return {
            secure_id: animalEdit.secure_id,
            ...data
          };
        }
        return animal;
      })
      setArrayAnimals(newArrayAnimals);
      handleModal();
      return
    }
    setArrayAnimals([...arrayAnimals, data]);
    handleModal();
    // setFieldValue('sisbov', '');
    // setFieldValue('botton_rfid', '');
    // setFieldValue('ident_earing', '');
    // // setFieldValue('apart', -1);
    // setFieldValue('input_weight', null);
  }

  useEffect(() => {
    if (values.apart && dateInput) {
      const currentApart = aparts.find(apart => apart.apart === values.apart);

      if (currentApart && currentApart.date && !isSameDay(parseISO(currentApart.date), dateInput)) {
        setErrorApartOrigin(`Aparte possui entrada de animais na data ${format(parseISO(currentApart.date), 'dd/MM/yyyy')}`);
      } else {
        setErrorApartOrigin('');
      }
    }
  }, [values.apart, dateInput]);

  return (
    <Fade in={visibleModal}>
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <StyledGrid
            container
            justify="space-between"
            alignItems="center"
            spacing={2}
            marginTop={12}
            marginBottom={12}
          >
            <Grid item sm xs={12}>
              <FormControl
                size="small"
                required
                fullWidth
                variant="outlined"
                error={(!!errors.apart && touched.apart) || errorApartOrigin}
              >
                <InputLabel id="input-apart">Aparte</InputLabel>
                <Select
                  name="apart"
                  labelWidth={50}
                  inputProps={{
                    id: 'apart-input',
                  }}
                  value={values.apart}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {aparts
                    // .filter((item, pos) => {
                    //   return aparts.indexOf(item) === pos;
                    // })
                    .map(item => (
                      <MenuItem key={item.apart} value={item.apart}>
                        {item.apart}
                      </MenuItem>
                    ))}
                </Select>
                {/* <ErrorMessage name="apart" component={Error} /> */}
                {errorApartOrigin && (
                  <Error>{errorApartOrigin}</Error>
                )}
                {!!errors.apart && touched.apart && !errorApartOrigin && (
                  <Error>{errors.apart}</Error>
                )}
              </FormControl>
            </Grid>
            <Grid item sm xs={12}>
              <StyledTextField
                fullWidth
                id="sisbov"
                name="sisbov"
                variant="outlined"
                autoComplete="off"
                label="SISBOV"
                required={settings.sisbov_active === 'yes'}
                size="small"
                value={values.sisbov}
                error={(!!errors.sisbov && touched.sisbov) || hasAnimalSameSisbov}
                onBlur={handleBlur}
                onChange={event => {
                  handleChange(event);
                  debounceSearchAnimalBySisbov(event.target.value, 'sisbov')
                }}
              />
              {/* <ErrorMessage name="sisbov" component={Error} /> */}
              {hasAnimalSameSisbov ? (
                <Error>{hasAnimalSameSisbov}</Error>
              ) : (
                <Error>{errors.sisbov}</Error>
              )}
            </Grid>
          </StyledGrid>
          <StyledGrid
            container
            justify="space-between"
            spacing={2}
            marginTop={12}
            marginBottom={12}
          >
            <Grid item sm xs={12}>
              <StyledTextField
                fullWidth
                id="botton_rfid"
                name="botton_rfid"
                variant="outlined"
                label="BOTTON RFID"
                autoComplete="off"
                required={settings.default_identification === 'botton_rfid'}
                size="small"
                value={values.botton_rfid}
                error={(!!errors.botton_rfid && touched.botton_rfid) || hasAnimalSameBottonRfid}
                onBlur={handleBlur}
                onChange={event => {
                  handleChange(event);
                  debounceSearchAnimalByBottonRfid(event.target.value, 'botton_rfid')
                }}
              />
              {hasAnimalSameBottonRfid ? (
                <Error>{hasAnimalSameBottonRfid}</Error>
              ) : (
                <Error>{errors.botton_rfid}</Error>
              )}
            </Grid>
            <Grid item sm xs={12}>
              <StyledTextField
                fullWidth
                id="ident_earing"
                name="ident_earing"
                required={
                  settings.default_identification === 'ident_earing'
                }
                variant="outlined"
                label="BRINCO"
                autoComplete="off"
                size="small"
                value={values.ident_earing}
                error={(!!errors.ident_earing && touched.ident_earing) || hasAnimalSameIdentEaring}
                onBlur={handleBlur}
                onChange={event => {
                  handleChange(event);
                  debounceSearchAnimalByIdentEaring(event.target.value, 'ident_earing')
                }}
              />
              {hasAnimalSameIdentEaring ? (
                <Error>{hasAnimalSameIdentEaring}</Error>
              ) : (
                <Error>{errors.ident_earing}</Error>
              )}
            </Grid>
          </StyledGrid>
          <StyledGrid
            container
            justify="space-between"
            spacing={2}
            marginTop={12}
            marginBottom={12}
          >
            <Grid item sm xs={12}>
              <FormControl
                size="small"
                required
                fullWidth
                variant="outlined"
                error={!!errors.breed_id && touched.breed_id}
              >
                <InputLabel id="input-breed">Raça</InputLabel>
                <Select
                  name="breed_id"
                  labelWidth={45}
                  inputProps={{
                    id: 'breed-input',
                  }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                  value={values.breed_id}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {breeds.map(item => (
                    <MenuItem key={item.secure_id} value={item.secure_id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {/* <ErrorMessage name="breed_id" component={Error} /> */}
                {!!errors.breed_id && touched.breed_id && (
                  <Error>{errors.breed_id}</Error>
                )}
              </FormControl>
            </Grid>
            <Grid item sm xs={12}>
              <FormControl
                size="small"
                required
                fullWidth
                variant="outlined"
                error={!!errors.age && touched.age}
              >
                <InputLabel id="input-age">Idade</InputLabel>
                <Select
                  name="age"
                  labelWidth={50}
                  inputProps={{
                    id: 'age-input',
                  }}
                  value={values.age}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {ages.map(item => (
                    <MenuItem key={item.id} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                {/* <ErrorMessage name="age" component={Error} /> */}
                {!!errors.age && touched.age && (
                  <Error>{errors.age}</Error>
                )}
              </FormControl>
            </Grid>
          </StyledGrid>
          <StyledGrid container justify="space-between" spacing={2}>
            <Grid item sm xs>
              <StyledRadioGroup
                row
                error={!!errors.sex}
                name="sex"
                onBlur={handleBlur}
                value={values.sex}
              >
                {gender.map(sex => (
                  <FormControlLabel
                    key={sex.value}
                    name="sex"
                    value={sex.value}
                    control={
                      <Radio
                        color="primary"
                        size="small"
                        required
                        name="sex"
                        onChange={handleChange}
                      />
                    }
                    label={sex.label}
                  />
                ))}
                {/* <ErrorMessage name="sex" component={Error} /> */}
                {!!errors.sex && touched.sex && (
                  <Error>{errors.sex}</Error>
                )}
              </StyledRadioGroup>
            </Grid>
            <Grid item sm xs>
              <FormControl
                size="small"
                required
                fullWidth
                variant="outlined"
                error={!!errors.type && touched.type}
              >
                <InputLabel id="input-type">Categoria</InputLabel>
                <Select
                  name="type"
                  labelWidth={83}
                  inputProps={{
                    id: 'type-input',
                  }}
                  value={values.type}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {types
                    .filter(item => {
                      if (values.sex === 'Macho') {
                        return (
                          item === 'Inteiro' ||
                          item === 'Castrado' ||
                          item === 'Bezerro'
                        );
                      }
                      return (
                        item === 'Vaca' ||
                        item === 'Novilha' ||
                        item === 'Bezerra'
                      );
                    })
                    .map(item => (
                      <MenuItem key={item.id} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                </Select>
                {/* <ErrorMessage name="type" component={Error} /> */}
                {!!errors.type && touched.type && (
                  <Error>{errors.type}</Error>
                )}
              </FormControl>
            </Grid>
          </StyledGrid>

          <StyledGrid
            container
            justify="space-between"
            spacing={2}
            marginTop={12}
            marginBottom={12}
          >
            <Grid item sm xs>
              <FormControl
                size="small"
                required
                fullWidth
                variant="outlined"
                error={!!errors.body_size && touched.body_size}
              >
                <InputLabel id="input-body_size">
                  Tamanho Corporal
                </InputLabel>
                <Select
                  name="body_size"
                  labelWidth={145}
                  value={values.body_size}
                  onBlur={handleBlur}
                  onChange={handleChange}
                >
                  {bodySizes.map(item => (
                    <MenuItem key={item.id} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
                {/* <ErrorMessage name="body_size" component={Error} /> */}
                {!!errors.body_size && touched.body_size && (
                  <Error>{errors.body_size}</Error>
                )}
              </FormControl>
            </Grid>
            <Grid item sm xs={12}>
              <NumberFormatForm
                name="input_weight"
                label="Peso Animal"
                variant="outlined"
                size="small"
                required
                fullWidth
                autoComplete="off"
                allowNegative={false}
                decimalScale={2}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Kg</InputAdornment>
                  ),
                }}
                value={values.input_weight}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                onChange={event =>
                  calcArrobaPrice(
                    setFieldValue,
                    values.average_price,
                    parseUserInput(event.target.value),
                    values.percent_rc
                  )
                }
                error={!!errors.input_weight && touched.input_weight}
              />
              {/* <ErrorMessage name="input_weight" component={Error} /> */}
              {!!errors.input_weight && touched.input_weight && (
                <Error>{errors.input_weight}</Error>
              )}
            </Grid>
          </StyledGrid>

          <StyledGrid
            container
            justify="space-between"
            spacing={2}
            marginBottom={16}
          >
            <Grid item sm xs={12}>
              <NumberFormatForm
                name="percent_rc"
                label="RC de Entrada (%)"
                variant="outlined"
                size="small"
                required
                autoComplete="off"
                onBlur={handleBlur}
                fullWidth
                allowNegative={false}
                decimalScale={2}
                isAllowed={val => {
                  const { formattedValue, floatValue } = val;
                  return (
                    formattedValue === '' ||
                    formattedValue === '-' ||
                    (floatValue <= 100 && floatValue >= -100)
                  );
                }}
                value={values.percent_rc}
                setFieldValue={setFieldValue}
                onChange={event =>
                  calcArrobaPrice(
                    setFieldValue,
                    values.average_price,
                    values.input_weight,
                    parseUserInput(event.target.value)
                  )
                }
                error={!!errors.percent_rc && touched.percent_rc}
              />
              {/* <ErrorMessage name="percent_rc" component={Error} /> */}
              {!!errors.percent_rc && touched.percent_rc && (
                <Error>{errors.percent_rc}</Error>
              )}
            </Grid>
            <Grid item sm xs={12}>
              <NumberFormatForm
                name="percent_rc_forecast"
                label="RC de Abate Previsto (%)"
                variant="outlined"
                autoComplete="off"
                onBlur={handleBlur}
                size="small"
                fullWidth
                allowNegative={false}
                decimalScale={2}
                isAllowed={val => {
                  const { formattedValue, floatValue } = val;
                  return formattedValue === '' || floatValue <= 100;
                }}
                value={values.percent_rc_forecast}
                setFieldValue={setFieldValue}
                error={
                  !!errors.percent_rc_forecast &&
                  touched.percent_rc_forecast
                }
              />
              {/* <ErrorMessage
                name="percent_rc_forecast"
                component={Error}
              /> */}
              {!!errors.percent_rc_forecast && touched.percent_rc_forecast && (
                <Error>{errors.percent_rc_forecast}</Error>
              )}
            </Grid>
          </StyledGrid>

          <StyledGrid
            container
            justify="space-between"
            spacing={2}
            marginBottom={16}
          >
            <Grid item sm xs={12}>
              <NumberFormatForm
                allowedDecimalSeparators={[',']}
                name="average_price"
                fixedDecimalScale
                label="Preço Médio por Animal"
                variant="outlined"
                size="small"
                autoComplete="off"
                required
                fullWidth
                onBlur={handleBlur}
                decimalScale={2}
                value={values.average_price}
                setFieldValue={setFieldValue}
                onChange={event =>
                  calcArrobaPrice(
                    setFieldValue,
                    parseUserInput(event.target.value),
                    values.input_weight,
                    values.percent_rc
                  )
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                error={!!errors.average_price && touched.average_price}
              />
              {/* <ErrorMessage name="average_price" component={Error} /> */}
              {!!errors.average_price && touched.average_price && (
                <Error>{errors.average_price}</Error>
              )}
            </Grid>
            <Grid item sm xs={12}>
              <NumberFormatForm
                name="arroba_price"
                label="Preço por Arroba"
                variant="outlined"
                size="small"
                autoComplete="off"
                onBlur={handleBlur}
                fullWidth
                disabled
                allowNegative={false}
                decimalScale={2}
                value={values.arroba_price}
                setFieldValue={setFieldValue}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                error={!!errors.arroba_price && touched.arroba_price}
              />
              {/* <ErrorMessage name="arroba_price" component={Error} /> */}
              {!!errors.arroba_price && touched.arroba_price && (
                <Error>{errors.arroba_price}</Error>
              )}
            </Grid>
          </StyledGrid>

          <StyledGrid container spacing={2} marginBottom={16}>
            <Grid item sm xs={12}>
              <NumberFormatForm
                allowedDecimalSeparators={[',']}
                name="weight_forecast"
                label="Peso de Abate Previsto"
                variant="outlined"
                autoComplete="off"
                size="small"
                onBlur={handleBlur}
                fullWidth
                allowNegative={false}
                decimalScale={2}
                value={values.weight_forecast}
                setFieldValue={setFieldValue}
                error={
                  !!errors.weight_forecast && touched.weight_forecast
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Kg</InputAdornment>
                  ),
                }}
              />
              {/* <ErrorMessage name="weight_forecast" component={Error} /> */}
              {!!errors.weight_forecast && touched.weight_forecast && (
                <Error>{errors.weight_forecast}</Error>
              )}
            </Grid>
            <Grid item sm xs={12}>
              <NumberFormatForm
                allowedDecimalSeparators={[',']}
                name="output_price_forecast"
                label="Valor da Arroba para Saída Previsto"
                variant="outlined"
                size="small"
                autoComplete="off"
                fullWidth
                onBlur={handleBlur}
                allowNegative={false}
                fixedDecimalScale
                decimalScale={2}
                value={values.output_price_forecast}
                setFieldValue={setFieldValue}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                error={
                  !!errors.output_price_forecast &&
                  touched.output_price_forecast
                }
              />
              {/* <ErrorMessage
                name="output_price_forecast"
                component={Error}
              /> */}
              {!!errors.output_price_forecast && touched.output_price_forecast && (
                <Error>{errors.output_price_forecast}</Error>
              )}
            </Grid>
          </StyledGrid>

          <Divider light />
          <StyledGrid container spacing={2} marginBottom={16}>
            <Grid item sm xs md>
              <ContentSubmitButtons>
                <StyledSubmitButton
                  variant="outlined"
                  color="primary"
                  onClick={handleModal}
                >
                  Cancelar
                </StyledSubmitButton>
                <ButtonSubmit
                  title={
                    animalEdit && animalEdit.age ? 'Editar' : 'Adicionar'
                  }
                  disabled={errorApartOrigin || hasAnimalSameSisbov || hasAnimalSameBottonRfid || hasAnimalSameIdentEaring}
                />
              </ContentSubmitButtons>
            </Grid>
          </StyledGrid>
        </form>
      </Container>
    </Fade>
  );
}

AddNewAnimal.propTypes = {
  visibleModal: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  nextAnimal: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    sisbov_active: PropTypes.string,
    default_identification: PropTypes.string,
  }).isRequired,
  aparts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  breeds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  animalEdit: PropTypes.objectOf().isRequired,
  // selectAnimal: PropTypes.func.isRequired,
  arrayAnimals: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setArrayAnimals: PropTypes.func.isRequired,
  dateInput: PropTypes.string
};

import { useMutation } from 'react-query';
import { toast } from 'react-toastify'

import apiV2 from '~/services/apiV2';
// import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2AddTroughReading(closeModal = null, handlePush) {
  return useMutation(
    async (data) => {
      const response = await apiV2.post('/v1/feedlot/food-management/troughReading', data);

      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();

        toast.success('A leitura foi cadastrada com sucesso!');
        if (closeModal) {
          closeModal()
        } else {
          handlePush();
        }
      },
      onError: (error) => {
        if (closeModal) {
          closeModal()
        }

        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao tentar cadastrar a leitura, verifique se preencheu todos os campos corretamente.'
        toast[type](message)
      }
    }
  );
}

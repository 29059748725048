import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';

// Query
import { useApiV2Breed } from '~/hooks/apiV2/register/breed/useApiV2Breed';

import {
  Container,
  StyledGrid,
  StyledPaper,
  ContentButton,
  StyledTableContainer,
  StyledTableHead
} from './styles';

import history from '~/services/history';

import Can from '~/components/Can';
import Loader from '~/components/Loader';
import ButtonNew from '~/components/ButtonNew';
import { CardTableBreed } from './Components/CardTableBreed';

export default function Breed() {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)

  const [field, setField] = useState('name');
  const [direction, setDirection] = useState('asc');

  // Query
  const { data, isLoading } = useApiV2Breed(`${field}%7C${direction}`, page + 1, rowsPerPage);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleAdd() {
    history.push('/dashboard/breed/add');
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={2}>
          <Grid item sm />
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['breed_new']}
          >
            <Grid item sm>
              <ContentButton>
                <ButtonNew title="Novo" onClick={handleAdd} />
              </ContentButton>
            </Grid>
          </Can>
        </StyledGrid>

        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell align="left">
                      <TableSortLabel
                        active={field === 'name'}
                        direction={field === 'name' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('name')}
                      >
                        Raça
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      <TableSortLabel
                        active={field === 'description'}
                        direction={field === 'description' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('description')}
                      >
                        Descrição
                      </TableSortLabel>
                    </TableCell>
                    <Can checkRole={['administrator', 'owner', 'manager']}>
                      <TableCell align="right" />
                    </Can>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {data && data.breeds.map(breed => (
                    <CardTableBreed
                      key={breed.secure_id}
                      breed={breed}
                    />
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

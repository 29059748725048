import styled from 'styled-components';
import { TableHead, TextField } from '@material-ui/core';
import colors from '~/styles/colors';

export const ContentCapacity = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: 8px;
  text-align: left;
`;

export const SpaceTranspCapacity = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

export const ContentNumber = styled.div`
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  background: ${colors.white};
`;

export const StyledTextFieldNumber = styled(TextField)`
  margin: 12px;
  align-self: flex-end;

  label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 22px;
    bottom: 0px;
  }
  .Mui-focused {
    overflow: visible;
    right: 0px;
  }
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const TitleTable = styled.b`
  color: ${colors.primary};
`;

import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2"

export async function getFood(secure_id) {
  if (!secure_id) {
    return null
  }

  const { data } = await apiV2.get(`/v1/feedlot/register/food/${secure_id}`)

  return {
    currentFood: data
  }
}

export function useApiV2SelectFood(secure_id) {
  return useQuery(['foods', secure_id], () => getFood(secure_id))
}

import { useQuery } from "react-query";
import apiV2 from "~/services/apiV2";

export async function getDeathOutput(secure_id) {
  const { data } = await apiV2.get(`/v1/feedlot/animal-handling/deathOutput/${secure_id}`);

  return data
}

export function useApiV2SelectDeathOutput(secure_id) {
  return useQuery(['animalDeathOutput', secure_id], () => getDeathOutput(secure_id));
}

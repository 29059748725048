import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

import { formatDateOnly } from '~/utils/format';

export async function getAnimalsTransfers(sort, page = 1, limit = 50, date, destinationLot, originLot) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/animal-handling/individualAnimalTransfer?sort=${sort}`, {
      params: { page, limit, date, destinationLot, originLot }
    });
    const newData = data.data.map(animalTransfer => ({
      ...animalTransfer,
      date: formatDateOnly(animalTransfer.date),
    }));

    return {
      animalsTransfer: newData,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    };
  } catch (error) {
    toast.error('Não foi possível carregar as transf. de Animais por Lote!');
  }
}

export function useApiV2IndividualAnimalTransfer(sort, page, limit, date, destinationLot, originLot) {
  return useQuery(
    ['animalTransfersIndividual', sort, page, limit, date, destinationLot, originLot],
    () => getAnimalsTransfers(sort, page, limit, date, destinationLot, originLot),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  );
}

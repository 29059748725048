import styled, { css } from 'styled-components';
import {
  Paper,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  width: 100%;
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 50px;
  padding: 10px;

  img {
    height: 70px;
    width: 140px;
  }
`;

export const Column = styled.div``;

export const StyledTablePaper = styled(Paper)`
  width: 100%;
`;

export const StyledTableRow = styled(TableRow)`
  background: ${props => props.background || colors.white};
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
`;

export const StyledTableCellCons = styled(TableCell)`
  display: flex;
  flex-direction: column;
  min-width: 120px;
`;

export const StyledTableCellEllipsis = styled(TableCell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

export const StyledTableCellColor = styled(TableCell)`
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

import { useQuery } from "react-query";
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';

async function getDiets() {
  try {
    const { data } = await apiV2.get(`v1/feedlot/global/list/diet`, {
      params: {
        active: true
      }
    })
    const diets = data.map(diet => {
      return {
        label: diet.name,
        value: diet.secure_id,
      }
    })
    return {
      diets
    }
  } catch {
    toast.error('Falha ao tentar carregar as dietas!');
  }
}

export function useV2GlobalDiets() {
  return useQuery(
    ['V2GlobalDiets',],
    () => getDiets()
  )
}

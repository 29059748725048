import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import apiV2 from '~/services/apiV2';
import { queryClient } from '~/services/queryClient';

export function useApiV2AlterSettingDashboard() {
    return useMutation(
        async (data) => {
            await apiV2.put('/v1/feedlot/dashboard/settingDashboard', data);
        },
        {
            onSuccess: () => {
                queryClient.resetQueries('dashboard');
            },
            onError: () => {
                toast.error('Falha ao carregar o dashboard!')
            }
        }
    );
}

import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2"

export async function getEmploye(secure_id) {
  if (!secure_id) {
    return null
  }

  const { data } = await apiV2.get(`/v1/feedlot/register/employee/${secure_id}`)

  return {
    currentEmployee: data
  }
}

export function useApiV2SelectEmployee(secure_id) {
  return useQuery(['employees', secure_id], () => getEmploye(secure_id))
}

import styled from 'styled-components';
import { Button } from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${colors.white};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  height: 100vh;
  padding: 40px;

  img {
    width: 100%;
  }
`;

export const ButtonBack = styled(Button)`
  align-self: center;
`;

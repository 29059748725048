import React, { useState, useEffect } from 'react';
import {
  Grid,
  Button,
  Checkbox,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';

// Query
import { useApiV2Treatment } from '~/hooks/apiV2/foodManagement/treatment/useApiV2Treatment';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

import {
  Container,
  StyledGrid,
  ContentButton,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
  StyledTableCellEllipsis,
  StyledTableCellNoBorderBottom,
} from './styles';

import Filter from './Components/Filter';
import ModalPrint from './Components/ModalPrint';

import Loader from '~/components/Loader';
import colors from '~/styles/colors';

import { formatNumber, formatNamePaddock } from '~/utils/format';

const defaultPerformed = {
  id: 2,
  value: false,
  content: 'Não',
};

export default function Forecast() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('paddock');
  const [direction, setDirection] = useState('asc');
  const [currentDiet, setCurrentDiet] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentPerformed, setCurrentPerformed] = useState(defaultPerformed);
  const [listTreatments, setListTreatments] = useState([]);
  const [selectedTreatments, setSelectedTreatments] = useState([]);
  const [countTreatments, setCountTreatments] = useState(0);
  const [totalTreaties, setTotalTreaties] = useState([]);
  const [visibleModalPrint, setVisibleModalPrint] = useState(false);
  const [treatments, setTreatments] = useState([]);
  const [treaties, setTreaties] = useState({});

  const [settings, setSettings] = useState({});

  // Query
  const { data, isLoading } = useApiV2Treatment(
    `${field}%7C${direction}`,
    currentDiet && currentDiet.value,
    currentDate,
    currentPerformed.value
  );
  const { data: settingsData } = useApiV2GlobalSetting();

  function calcTreaty(treatment, treaty) {
    const hasTreatmentPerformed = treatment.treatyTreatments.filter(
      tt => tt.performed !== null
    );

    const isCurrentTreatmentPerformed = hasTreatmentPerformed.find(
      item => item.treaty.secure_id === treaty.secure_id
    );

    if (isCurrentTreatmentPerformed) {
      return isCurrentTreatmentPerformed.forecast;
    }

    const totalPerformed = hasTreatmentPerformed.reduce(
      (total, item) => total + item.performed,
      0
    );

    if (treaties.length - hasTreatmentPerformed.length > 0) {
      const totalProportionByTreaty = hasTreatmentPerformed.reduce(
        (total, item) => total + item.treaty.proportion,
        0
      );

      const proportionPerformedByTreaty =
        totalProportionByTreaty /
        (treaties.length - hasTreatmentPerformed.length);

      const currentTotal = treatment.total_forecast - totalPerformed;

      const currentProportion = treaty.proportion + proportionPerformedByTreaty;

      const currentTreaty = Math.round(
        currentTotal * (currentProportion / 100)
      );

      if (currentTreaty < 0) {
        return 0;
      }

      return currentTreaty;
    }

    return 0;
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleModalPrint() {
    setVisibleModalPrint(!visibleModalPrint);
  }

  function verifySelectedAll() {
    return listTreatments.length === countTreatments;
  }

  function countSelected(newListTreatments) {
    return newListTreatments.filter(treatment => treatment.selected === true)
      .length;
  }

  function isSelected(secure_id) {
    return !!listTreatments.find(
      treatment => treatment.secure_id === secure_id && treatment.selected
    );
  }

  function selectAllTreatments() {
    let newListTreatments = [];

    if (verifySelectedAll()) {
      newListTreatments = listTreatments.map(treatment => ({
        ...treatment,
        selected: false,
      }));
    } else {
      newListTreatments = listTreatments.map(treatment => ({
        ...treatment,
        selected: !treatment.performed,
      }));
    }

    setListTreatments(newListTreatments);
    setCountTreatments(countSelected(newListTreatments));
  }

  function handleCheckTreatment(secure_id) {
    let newListTreatments = null;

    if (isSelected(secure_id)) {
      newListTreatments = listTreatments.map(treatment => ({
        ...treatment,
        selected:
          treatment.secure_id === secure_id && !treatment.performed
            ? false
            : treatment.selected,
      }));
    } else {
      newListTreatments = listTreatments.map(treatment => ({
        ...treatment,
        selected:
          (treatment.secure_id === secure_id && !treatment.performed) ||
          treatment.selected,
      }));
    }

    setListTreatments(newListTreatments);
    setCountTreatments(countSelected(newListTreatments));
  }

  useEffect(() => {
    if (treatments && treatments.length > 0) {
      const selectedListTreatments = treatments.map(treatment => ({
        ...treatment,
        selected: !treatment.performed,
      }));

      const newListTreatments = selectedListTreatments.map(treatment => {
        const newTreaties = [];

        if (treatment.performed) {
          treatment.treatyTreatments.map(treaty => {
            newTreaties.push({
              ...treaty,
              forecast: treaty.forecast,
              performed: treaty.performed,
            });

            return null;
          });
        } else {
          treaties.map(treaty => {
            const newforecast = calcTreaty(treatment, treaty);

            const currentTreatyTreatment = treatment.treatyTreatments.find(
              tt =>
                tt.treaty.secure_id === treaty.secure_id && tt.performed !== null
            );

            if (currentTreatyTreatment) {
              newTreaties.push({
                treaty: { secure_id: treaty.secure_id, name: treaty.name },
                forecast: newforecast,
                performed: currentTreatyTreatment.performed,
                treatment: { secure_id: treatment.secure_id }
              });
            } else {
              newTreaties.push({
                treaty: { secure_id: treaty.secure_id, name: treaty.name },
                forecast: newforecast,
                performed: null,
                treatment: { secure_id: treatment.secure_id }
              });
            }

            return null;
          });
        }

        return {
          ...treatment,
          treatyTreatments: newTreaties,
          total_forecast: newTreaties.reduce((accumulator, newTreaty) => {
            const valueToSum = newTreaty.performed
              ? newTreaty.performed
              : newTreaty.forecast
            return accumulator + valueToSum;

          }, 0),
        };
      });

      setListTreatments(newListTreatments);
      setCountTreatments(countSelected(newListTreatments));
    } else {
      setListTreatments([]);
      setCountTreatments(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treaties, treatments]);

  useEffect(() => {
    setSelectedTreatments(
      listTreatments.filter(treatment => treatment.selected)
    );
  }, [listTreatments]);

  useEffect(() => {
    const allTreaties = [];

    listTreatments.map(treatment => {
      allTreaties.push(...treatment.treatyTreatments);

      return null;
    });

    const groupTreaties = allTreaties.reduce(
      (accumulator, { treaty, forecast, performed }) => {
        accumulator[treaty.secure_id] = {
          name: accumulator[treaty.secure_id] ? accumulator[treaty.secure_id].name : treaty.name,
          forecast:
            (accumulator[treaty.secure_id] ? accumulator[treaty.secure_id].forecast : 0) + +forecast,
          performed:
            (accumulator[treaty.secure_id] ? accumulator[treaty.secure_id].performed : 0) + +performed,
        };

        return accumulator;
      },
      {}
    );

    const totalsTreaties = Object.keys(groupTreaties).map(key => ({
      secure_id: key,
      name: groupTreaties[key].name,
      totalForecast: groupTreaties[key].forecast,
      totalPerformed: groupTreaties[key].performed,
    }));

    setTotalTreaties(totalsTreaties.sort((a, b) => (a.name > b.name ? 1 : -1)));
  }, [listTreatments]);

  useEffect(() => {
    if (data) {
      setTreaties(data.treaties);
      setTreatments(data.treatments.filter(value => value.total_forecast > 0));
    }

    if (settingsData) {
      setSettings(settingsData.settings);
    }
  }, [data, settingsData]);

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={2}>
          <Grid item xs sm md lg xl>
            <Filter
              currentDiet={currentDiet}
              setCurrentDiet={setCurrentDiet}
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              currentPerformed={currentPerformed}
              setCurrentPerformed={setCurrentPerformed}
            />
          </Grid>
          <Grid item xs="auto" sm="auto">
            <ContentButton>
              <Button
                variant="contained"
                color="primary"
                type="button"
                startIcon={<PrintIcon />}
                disabled={selectedTreatments.length === 0}
                onClick={handleModalPrint}
              >
                Imprimir
              </Button>
            </ContentButton>
          </Grid>
        </StyledGrid>

        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'paddock'}
                        direction={field === 'paddock' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('paddock')}
                      >
                        Piquete
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'diet.name'}
                        direction={field === 'diet.name' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('diet.name')}
                      >
                        Dieta
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={field === 'performed'}
                        direction={field === 'performed' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('performed')}
                      >
                        Realizado
                      </TableSortLabel>
                    </TableCell>
                    {treaties &&
                      treaties.length > 0 &&
                      treaties.map(treaty => (
                        <StyledTableCell key={treaty.secure_id} align="right">
                          {treaty.name}
                        </StyledTableCell>
                      ))}
                    <TableCell align="right">
                      <TableSortLabel
                        active={field === 'total_forecast'}
                        direction={
                          field === 'total_forecast' ? direction : 'asc'
                        }
                        onClick={() => handleChangeOrder('total_forecast')}
                      >
                        Total Previsto
                      </TableSortLabel>
                    </TableCell>
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          countTreatments > 0 &&
                          countTreatments < listTreatments.length
                        }
                        checked={countTreatments === listTreatments.length}
                        onChange={selectAllTreatments}
                        disabled={countTreatments === 0}
                        inputProps={{
                          'aria-label': 'Selecionar todos tratamentos',
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {listTreatments
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(treatment => (
                      <TableRow
                        key={treatment.secure_id}
                        hover
                        onClick={() => handleCheckTreatment(treatment.secure_id)}
                        role="checkbox"
                        aria-checked={isSelected(treatment.secure_id)}
                        tabIndex={-1}
                        selected={isSelected(treatment.secure_id)}
                      >
                        <StyledTableCell component="th" scope="row">
                          {formatNamePaddock(settings, treatment.paddock)}
                        </StyledTableCell>
                        <StyledTableCellEllipsis align="left">
                          {treatment.diet.name}
                        </StyledTableCellEllipsis>
                        <TableCell align="center">
                          {treatment.performed ? (
                            <DoneIcon style={{ color: colors.success }} />
                          ) : (
                            <CloseIcon color="error" />
                          )}
                        </TableCell>
                        {treatment.treatyTreatments.map(treaty => (
                          <StyledTableCell key={treaty.treaty.secure_id} align="right">
                            {treaty.performed
                              ? formatNumber(treaty.performed, 0, 4)
                              : formatNumber(treaty.forecast, 0, 4)
                            }
                          </StyledTableCell>
                        ))}
                        <StyledTableCell align="right">
                          {formatNumber(treatment.total_forecast, 0, 4)}
                        </StyledTableCell>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            disabled={treatment.performed}
                            checked={isSelected(treatment.secure_id)}
                            inputProps={{ 'aria-labelledby': treatment.secure_id }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  <TableRow>
                    <StyledTableCellNoBorderBottom rowSpan={2} colSpan={3} />
                    {totalTreaties.map(treaty => (
                      <TableCell key={treaty.secure_id} align="right">
                        {treaty.totalPerformed
                          ? treaty.totalPerformed
                          : treaty.totalForecast
                        }
                      </TableCell>
                    ))}
                    <StyledTableCellNoBorderBottom rowSpan={2} colSpan={2} />
                  </TableRow>
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={listTreatments.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}

        {visibleModalPrint && (
          <ModalPrint
            visibleModal={visibleModalPrint}
            handleModal={handleModalPrint}
            data={selectedTreatments}
            currentDiet={currentDiet}
            currentDate={currentDate}
            treaties={treaties}
          />
        )}
      </Container>
    </center>
  )
}

import styled, { keyframes, css } from 'styled-components';
import { Paper, Grid, Button } from '@material-ui/core';

export const Container = styled.div`
  max-width: 1024px;
  width: 100%;
  padding: 40px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 20px;

  text-align: left;
`;

export const StyledGrid = styled(Grid)`
  margin-bottom: 24px;
`;

export const ContentInfo = styled.div`
  max-width: 800px;
  margin-top: 28px;

  text-align: left;
`;

export const StyledGridInfo = styled(Grid)`
  margin-bottom: 16px;
`;

export const StyledGridItem = styled(Grid)`
  margin: 0px 8px;
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.marginTop || 24}px;
`;

export const StyledSubmitButton = styled(Button)`
  color: ${props => props.color};
  background: ${props => props.background};

  &:first-child {
    margin-right: 10px;
  }

  &:hover {
    background: ${props => props.background};
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const ContentIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    ${props =>
      props.loading &&
      css`
        animation: ${rotate} 0.5s infinite;
      `}
  }
`;

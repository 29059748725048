import styled from 'styled-components';
import { TextField } from '@material-ui/core';

export const TextFieldNumber = styled(TextField).attrs({
  autoComplete: 'off',
})`
  text-align: left;

  label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 22px;
    bottom: 0px;
  }

  .Mui-focused {
    overflow: visible;
    right: 0px;
  }
`;

import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2AddStakeholder(closeModal) {
  return useMutation(
    async (data) => {
      await apiV2.post('/v1/feedlot/register/stakeholder', data)
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries('stakeholders');
        toast.success('O registro foi cadastrado com sucesso.')
        if (closeModal) {
          closeModal();
        } else {
          history.push('/dashboard/stakeholder');
        }
      },
      onError: (error) => {
        const hasErrors = error?.response?.data?.errors?.find(error => error.rule === "unique" || error.rule === "email")
        if (hasErrors) {
          toast.error(hasErrors.message)
        } else {
          const type = error.response.data?.type ? error.response.data.type : 'error'
          const message = error.response.data?.message ? error.response.data.message : 'Falha ao cadastrar.'
          toast[type](message)
        }
      }
    }
  );
}

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';

// Query
import { useApiV2ActionsLogs } from '~/hooks/apiV2/admin/actionsLogs/useApiV2ActionsLogs';

import {
  Container,
  StyledGrid,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';

import Filter from './Components/Filter';
import Loader from '~/components/Loader';

export default function OperationalCost() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('created_at');
  const [direction, setDirection] = useState('desc');
  const [initialDate, setInitialDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [finalDate, setFinalDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));

  // Query
  const { data, isLoading } = useApiV2ActionsLogs(`${field}|${direction}`, page + 1, rowsPerPage,initialDate, finalDate);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={1}>
          <Grid item sm md lg xl>
            <Filter
              initialDate={initialDate}
              setInitialDate={setInitialDate}
              finalDate={finalDate}
              setFinalDate={setFinalDate}
            />
          </Grid>
        </StyledGrid>

        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell align="left">
                      Usuário
                    </TableCell>
                    <StyledTableCell align="left">
                      Ação
                    </StyledTableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={field === 'created_at'}
                        direction={field === 'created_at' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('created_at')}
                      >
                        Data da realização
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {data && data.actionsLogs.map(actionLog => (
                      <TableRow key={actionLog.secure_id}>
                        <TableCell component="th" scope="row">
                          {actionLog.user_name}
                        </TableCell>
                        <TableCell>
                          {actionLog.action}
                        </TableCell>
                        <StyledTableCellEllipsis align="right">
                          {actionLog.formattedCreatedAt}
                        </StyledTableCellEllipsis>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

import styled from 'styled-components';
import { Paper, Grid, IconButton } from '@material-ui/core';

export const Content = styled.div`
  max-width: 500px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 8px;
`;

export const StyledGridItem = styled(Grid)`
  margin: 5px 10px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

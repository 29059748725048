import React, { useState, useEffect, useRef } from 'react';
// import PropTypes from 'prop-types';
import { format, subDays } from 'date-fns';
import ReactToPrint from 'react-to-print';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Hidden,
} from '@material-ui/core';
// import SaveIcon from '@material-ui/icons/Save';
// import EditIcon from '@material-ui/icons/Edit';
import PrintIcon from '@material-ui/icons/Print';

// Query
import { useApiV2TroughReading } from '~/hooks/apiV2/foodManagement/troughReading/useApiV2TroughReading';
import { useApiV2GlobalTroughSettings } from '~/hooks/apiV2/global/troughSetting/useApiV2GlobalTroughSettings';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

import {
  Container,
  StyledGrid,
  Empty,
  ButtonPrint,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  ContentPrint,
} from './styles';

import Print from './Print';
import Filter from './Components/Filter';

import history from '~/services/history';
import apiV2 from '~/services/apiV2';
import Loader from '~/components/Loader';
import ButtonNew from '~/components/ButtonNew';
import { CardTableTroughReading } from './Components/CardTableTroughReading';
import Can from '~/components/Can';

export default function TroughReading(props) {
  const [allDates, setAllDates] = useState([]);
  // const [change, setChange] = useState(false);
  const [dataPrint, setDataPrint] = useState([]);

  const extra = props.location ? props.location.extra : '';

  const [filters, setFilters] = useState({
    currentModule: '',
    currentLine: '',
    currentDate: new Date(),
  });

  const { currentModule, currentLine, currentDate } = filters;

  const PrintScreen = useRef();

  // Query
  const { data, isLoading } = useApiV2TroughReading(
    null,
    currentModule.secure_id,
    currentDate
  );
  const { data: troughSettingData } = useApiV2GlobalTroughSettings();
  const { data: settingsData } = useApiV2GlobalSetting();

  useEffect(() => {
    if (extra) {
      setFilters(extra);
    }
  }, [extra]);

  function handleAdd() {
    history.push({
      pathname: '/dashboard/troughReading/add',
      extra: filters,
    });
  }

  useEffect(() => {
    setAllDates([
      format(subDays(currentDate, 1), 'yyyy-MM-dd'),
      format(subDays(currentDate, 2), 'yyyy-MM-dd'),
      format(subDays(currentDate, 3), 'yyyy-MM-dd'),
    ]);
  }, [currentDate]);

  useEffect(() => {
    const print = async () => {
      const formatedDate = format(currentDate, 'yyyy-MM-dd');

      const moduleSelected =
        currentModule && currentModule.id
          ? `&module=${currentModule.id}`
          : '&module=';
      const line =
        currentLine && currentLine.id
          ? `&line=${currentLine.id}`
          : '&line=';

      const { data } = await apiV2.get(
        `/v1/feedlot/food-management/troughReading/print?date=${formatedDate}${moduleSelected}${line}`
      );

      setDataPrint(data);
    };

    print();
  }, [currentDate, currentLine, currentModule]);

  return (
    <>
      <center>
        <Container>
          <StyledGrid container justify="space-between" spacing={2}>
            <Hidden only="xs">
              <Grid item xs="auto" sm="auto">
                <Empty />
              </Grid>
            </Hidden>
            <Grid item xs sm md lg xl>
              <Filter
                filters={filters}
                setFilters={setFilters}
              />
            </Grid>
            <Grid item xs="auto" sm="auto">
              <Can
                checkRole={['administrator', 'owner', 'beefer']}
                checkPermission={['troughReading_new']}
              >
                <ButtonNew title="Nova" onClick={handleAdd} />
              </Can>
              <ReactToPrint
                trigger={() => (
                  <ButtonPrint
                    color="primary"
                    variant="contained"
                    startIcon={<PrintIcon />}
                    disabled={dataPrint.length === 0}
                  >
                    Ficha
                  </ButtonPrint>
                )}
                content={() => PrintScreen.current}
              />
            </Grid>
          </StyledGrid>
          {isLoading ? (
            <Loader />
          ) : (
            <StyledPaper>
              <StyledTableContainer>
                <Table stickyHeader>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell>
                        Piquete
                      </TableCell>
                      <TableCell align="left">
                        Leitura
                      </TableCell>
                      <TableCell align="center">Dia 1</TableCell>
                      <TableCell align="center">Dia 2</TableCell>
                      <TableCell align="center">Dia 3</TableCell>
                      <Can checkRole={['administrator', 'owner', 'manager']}>
                        <TableCell align="right" />
                      </Can>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {settingsData && data && data.troughReadings
                      .map(troughReading => (
                        <CardTableTroughReading
                          key={troughReading.secure_id}
                          troughReading={troughReading}
                          settings={settingsData.settings}
                          allDates={allDates}
                        />
                      ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </StyledPaper>
          )}
        </Container>
      </center>
      {troughSettingData && settingsData && (
        <ContentPrint>
          <Print
            ref={PrintScreen}
            module={currentModule}
            line={currentLine}
            date={currentDate}
            data={dataPrint}
            troughSettings={troughSettingData.troughSetting}
            settings={settingsData.settings}
          />
        </ContentPrint>
      )}

    </>
  );
}

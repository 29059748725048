import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";

export async function getIndividualTransferApartLot(secure_id) {
  const { data } = await apiV2.get(`/v1/feedlot/animal-handling/individualTransferApartLot/${secure_id}`);

  return {
    currentAnimalInputLotApart: data
  };
}

export function useApiV2SelectIndividualTransferApartLot(secure_id) {
  return useQuery(
    ['animalInputLotApart', secure_id],
    () => getIndividualTransferApartLot(secure_id)
  );
}

import React, { useState, useEffect } from 'react';

import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

import { ExcelRenderer } from 'react-excel-renderer';

import {
    Grid,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Divider,
    InputAdornment,
    TextField,
    Table,
    TableRow,
    TableCell,
    TableSortLabel,
    TableBody,
    TablePagination,
} from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {
    Container,
    Content,
    StyledPaper,
    StyledGrid,
    StyledGridAndButton,
    StyledIconButton,
    ContentSubmitButtons,
    StyledSubmitButton,
    Error,
    ButtonNextAnimalContainer,
    StyledButton,
    StyledTableContainer,
    StyledTableHead,
    StyledTableCell,
    ContentAnimals,
    ContentOptions
} from './styles';

// Query
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting'
import { useApiV2ShowDataInput } from '~/hooks/apiV2/animalHandling/IndividualAnimalInput/useApiV2ShowDataInput'
import { useApiV2AddIndividualAnimalInput } from '~/hooks/apiV2/animalHandling/IndividualAnimalInput/useApiV2AddIndividualAnimalInput'

import colors from '~/styles/colors';
import NumberFormatEmpty from '~/components/NumberFormatEmpty';
import NumberFormatForm from '~/components/NumberFormatForm';
import { inputTypes } from '~/utils/options';
import Loader from '~/components/Loading';
import ButtonTable from '~/components/ButtonTable';
import AddNewAnimal from '../Components/AddNewAnimal';
import ModalImport from '../Components/ModalImport';
import ModalStakeholder from '~/components/ModalStakeholder';
import ButtonSubmit from '~/components/ButtonSubmit';
import history from '~/services/history';
import { parseISO } from 'date-fns';

export default function Add() {
    const schema = Yup.object().shape({
        date: Yup.string().required('Informe uma data'),
    });

    const { data: settings } = useApiV2GlobalSetting();
    const { data, isLoading } = useApiV2ShowDataInput();
    const addIndividualAnimalInput = useApiV2AddIndividualAnimalInput();

    const [viewImport, setViewImport] = useState(false);
    const [visibleImport, setVisibleImport] = useState(false);
    const [fileImport, setFileImport] = useState([]);
    const [animalsImport, setAnimalsImport] = useState([]);

    const [visibleModalStakeholder, setVisibleModalStakeholder] = useState(false);
    const [visibleNewAnimal, setVisibleNewAnimal] = useState(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [arrayAnimals, setArrayAnimals] = useState([]);
    const [arrayAnimalsSearch, setArrayAnimalsSearch] = useState([]);
    const [animalEdit, setAnimalEdit] = useState(null);
    const [dateInput, setDateInput] = useState(new Date());

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleModalStakeholder = () => {
        setVisibleModalStakeholder(!visibleModalStakeholder);
    }

    const handleModalNewAnimal = () => {
        setAnimalEdit(null);
        setVisibleNewAnimal(!visibleNewAnimal);
    }

    const handleEditAnimal = animalEditData => {
        setAnimalEdit(animalEditData);
        setVisibleNewAnimal(true);
    };

    const handleRemove = animalId => {
        const newArrayAnimals = arrayAnimals.filter(animal => animal[settings.settings.default_identification] !== animalId);
        setArrayAnimals(newArrayAnimals);
    };

    const handleFinishApart = (setFieldValue, finished_apart) => {
        setFieldValue('finished_apart', !finished_apart)
    };

    const fileHandler = () => {
        ExcelRenderer(fileImport, (err, resp) => {
            if (err) {
                // console.log(err);
            } else {
                const listImportAnimals = resp.rows;
                listImportAnimals.shift();

                const animals = listImportAnimals.filter(animal => !!animal && animal.length > 0).map(animal => {
                    const breed = data.optionsBreeds.find(data => data.name === animal[8]);

                    const rcAverageWeight = ((animal[13] / 100) * animal[5]) / 15;

                    const arrobaPrice = animal[14] / rcAverageWeight;

                    return {
                        age: !!animal[9] ? animal[9].trim() : '',
                        apart: animal[7],
                        arroba_price: arrobaPrice,
                        average_price: animal[14],
                        body_size: String(animal[12]).trim(),
                        botton_rfid: animal[1] && String(animal[1]).trim(),
                        // breed: animal[8], // animal[7]
                        breed_id: breed && breed.secure_id,
                        ident_earing: animal[2] && String(animal[2]).trim(),
                        input_weight: animal[5],
                        output_price_forecast: animal[17],
                        percent_rc: animal[13],
                        percent_rc_forecast: animal[16],
                        sex: !!animal[10] ? animal[10].trim() : '',
                        sisbov: animal[0] && String(animal[0]).trim(),
                        type: !!animal[11] ? animal[11].trim() : '',
                        weight_forecast: animal[15],
                    };
                });

                setAnimalsImport(animals);
                setVisibleImport(true);
                // setArrayAnimals([...arrayAnimals, ...animals]);
            }
        });
    };

    const handleSubmit = async (values) => {
        const newAnimals = arrayAnimals.map(animal => ({
            sisbov: animal.sisbov,
            bottonRfid: animal.botton_rfid,
            identEaring: animal.ident_earing,
            sex: animal.sex,
            bodySize: animal.body_size,
            age: animal.age,
            type: animal.type,
            apart: animal.apart,
            arrobaPrice: animal.arroba_price,
            averagePrice: animal.average_price,
            breedSecureId: animal.breed_id,
            gtaNumber: values.gta_number,
            inputWeight: animal.input_weight,
            invoiceNumber: values.invoice_number,
            percentRc: animal.percent_rc,
            percentRcForecast: animal.percent_rc_forecast,
            outputPriceForecast: animal.output_price_forecast,
            weightForecast: animal.weight_forecast,
            boitelDayPrice: values.boitel_day_price
        }))
        const data = {
            amountAnimals: newAnimals.length,
            date: values.date,
            finishedApart: values.finished_apart,
            inputType: values.input_type,
            invoiceNumber: values.invoice_number,
            stakeholderSecureId: values.stakeholder_id,
            animals: newAnimals
        }
        await addIndividualAnimalInput.mutateAsync(data);
    }

    function handleBack() {
        history.goBack();
    }

    const handleSearch = (name) => {
        setPage(0);
        const animalsSearch = arrayAnimals.filter(value => name ? value[settings.settings.default_identification].includes(name) : value);
        setArrayAnimalsSearch(animalsSearch);
    }

    useEffect(() => {
        setArrayAnimalsSearch(arrayAnimals)
    }, [arrayAnimals]);

    return (
        <center>
            <Container>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <StyledPaper>
                            <Formik
                                validationSchema={schema}
                                initialValues={{
                                    date: data && data.dateEntry ? parseISO(data.dateEntry) : new Date(),
                                    finished_apart: false,
                                    input_type: '',
                                    invoice_number: null,
                                    stakeholder_id: '',
                                }}
                                onSubmit={handleSubmit}
                            >
                                {({
                                    errors,
                                    values,
                                    setFieldValue,
                                    touched,
                                    handleChange,
                                    handleBlur
                                }) => (
                                    <Form>
                                        <Content>
                                            <StyledGrid
                                                container
                                                justify="space-between"
                                                spacing={2}
                                                marginTop={12}
                                                marginBottom={12}
                                            >
                                                <Grid item sm xs={12}>
                                                    <MuiPickersUtilsProvider
                                                        utils={DateFnsUtils}
                                                        locale={ptBR}
                                                    >
                                                        <KeyboardDatePicker
                                                            name="date"
                                                            disableToolbar
                                                            variant="inline"
                                                            format="dd/MM/yyyy"
                                                            margin="normal"
                                                            size="small"
                                                            id="date-picker-inline"
                                                            label="Data de entrada"
                                                            inputVariant="outlined"
                                                            maxDate={
                                                                !!data && data.dateEntry
                                                                    ? parseISO(data.dateEntry)
                                                                    : new Date()
                                                            }
                                                            minDate={
                                                                !!data && data.dateEntry
                                                                    ? parseISO(data.dateEntry)
                                                                    : new Date(null)
                                                            }
                                                            required
                                                            fullWidth
                                                            autoOk
                                                            style={{ marginTop: 0, marginBottom: 0 }}
                                                            value={values.date}
                                                            onChange={date => {
                                                                setFieldValue('date', date)
                                                                setDateInput(date)
                                                            }}
                                                            error={!!errors.date && touched.date}
                                                        />
                                                        <ErrorMessage name="date" component={Error} />
                                                    </MuiPickersUtilsProvider>
                                                </Grid>
                                                <Grid item sm xs={12}>
                                                    <FormControl
                                                        size="small"
                                                        required
                                                        fullWidth
                                                        variant="outlined"
                                                        error={!!errors.input_type && touched.input_type}
                                                    >
                                                        <InputLabel id="input_type-label">
                                                            Tipo de entrada
                                                        </InputLabel>
                                                        <Select
                                                            name="input_type"
                                                            labelWidth={122}
                                                            value={values.input_type}
                                                            onChange={handleChange}
                                                        >
                                                            {inputTypes.map(type => (
                                                                <MenuItem key={type} value={type.value}>
                                                                    {type.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        <ErrorMessage name="input_type" component={Error} />
                                                    </FormControl>
                                                </Grid>
                                                {values.input_type === 'boitel' && (
                                                    <Grid item sm xs={12}>
                                                        <NumberFormatForm
                                                            disabled={values.input_type !== 'boitel'}
                                                            allowedDecimalSeparators={[',']}
                                                            name="boitel_day_price"
                                                            fixedDecimalScale
                                                            label="Valor da Diária Boitel"
                                                            variant="outlined"
                                                            size="small"
                                                            required={!!values.input_type === 'boitel'}
                                                            fullWidth
                                                            decimalScale={2}
                                                            value={values.boitel_day_price}
                                                            setFieldValue={setFieldValue}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        R$
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            error={
                                                                !!errors.boitel_day_price &&
                                                                touched.boitel_day_price
                                                            }
                                                        />
                                                        <ErrorMessage
                                                            name="boitel_day_price"
                                                            component={Error}
                                                        />
                                                    </Grid>
                                                )}
                                            </StyledGrid>
                                            <StyledGrid
                                                container
                                                justify="space-between"
                                                spacing={2}
                                                marginTop={12}
                                                marginBottom={12}
                                            >
                                                <Grid item sm xs={12}>
                                                    <NumberFormatEmpty
                                                        name="invoice_number"
                                                        label="Nota Fiscal"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        allowNegative={false}
                                                        decimalScale={0}
                                                        isAllowed={val => {
                                                            const { value } = val;
                                                            return value.length <= 10;
                                                        }}
                                                        value={values.invoice_number}
                                                        setFieldValue={setFieldValue}
                                                        error={
                                                            !!errors.invoice_number && touched.invoice_number
                                                        }
                                                    />
                                                    <ErrorMessage name="invoice_number" component={Error} />
                                                </Grid>
                                                <Grid item sm xs={12}>
                                                    <NumberFormatEmpty
                                                        name="gta_number"
                                                        label="GTA"
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        allowNegative={false}
                                                        decimalScale={0}
                                                        isAllowed={val => {
                                                            const { value } = val;
                                                            return value.length <= 10;
                                                        }}
                                                        value={values.gta_number}
                                                        setFieldValue={setFieldValue}
                                                        error={!!errors.gta_number && touched.gta_number}
                                                    />
                                                    <ErrorMessage name="gta_number" component={Error} />
                                                </Grid>
                                            </StyledGrid>

                                            <Divider light />

                                            <StyledGrid
                                                container
                                                spacing={2}
                                                justify="space-between"
                                                marginBottom={16}
                                            >
                                                <Grid item sm xs={12}>
                                                    <StyledGridAndButton container direction="row">
                                                        <Grid item sm xs>
                                                            <Autocomplete
                                                                name="stakeholder"
                                                                size="small"
                                                                noOptionsText="Sem opções"
                                                                options={data.optionsStakeholders}
                                                                getOptionLabel={option => option.name}
                                                                value={values.stakeholder}
                                                                onChange={(event, value) => {
                                                                    setFieldValue('stakeholder', value)
                                                                    setFieldValue('stakeholder_id', value.secure_id)
                                                                }}
                                                                renderInput={params => (
                                                                    <TextField
                                                                        {...params}
                                                                        name="stakeholder_id"
                                                                        label="Fornecedor"
                                                                        // required
                                                                        fullWidth
                                                                        variant="outlined"
                                                                        value={values.stakeholder_id}
                                                                        onBlur={handleBlur}
                                                                        error={
                                                                            !!errors.stakeholder_id &&
                                                                            touched.stakeholder_id
                                                                        }
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                name="stakeholder_id"
                                                                component={Error}
                                                            />
                                                        </Grid>
                                                        <Grid item sm="auto" style={{ marginTop: 4 }}>
                                                            <StyledIconButton
                                                                variant="contained"
                                                                size="small"
                                                                onClick={handleModalStakeholder}
                                                            >
                                                                <AddIcon />
                                                            </StyledIconButton>
                                                        </Grid>
                                                    </StyledGridAndButton>
                                                </Grid>
                                                <Grid item sm xs>
                                                    <TextField
                                                        label={
                                                            values.stakeholder &&
                                                                values.stakeholder.doc.length === 14
                                                                ? 'CPF'
                                                                : 'CNPJ'
                                                        }
                                                        variant="outlined"
                                                        size="small"
                                                        fullWidth
                                                        disabled
                                                        value={values.stakeholder && values.stakeholder.doc}
                                                        InputLabelProps={{
                                                            shrink: !!values.stakeholder,
                                                        }}
                                                    />
                                                </Grid>
                                            </StyledGrid>
                                            <ContentOptions>
                                                <TextField
                                                    style={{ background: 'white' }}
                                                    size="small"
                                                    placeholder="Pesquisar por identificador"
                                                    onChange={e => {
                                                        handleSearch(e.target.value);
                                                    }}
                                                    label="Pesquisar"
                                                    variant="outlined"
                                                    autoComplete="nope"
                                                    margin="normal"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                                <div>
                                                    <ButtonNextAnimalContainer>
                                                        {viewImport && (
                                                            <>
                                                                <input
                                                                    type="file"
                                                                    onChange={event =>
                                                                        setFileImport(event.target.files[0])
                                                                    }
                                                                    style={{ padding: '10px' }}
                                                                />
                                                                <StyledButton
                                                                    style={{ background: colors.success }}
                                                                    variant="contained"
                                                                    onClick={() => {
                                                                        fileHandler(fileImport);
                                                                        setViewImport(false);
                                                                    }}
                                                                >
                                                                    Importar
                                                                </StyledButton>
                                                            </>
                                                        )}
                                                        {!viewImport && (
                                                            <StyledButton
                                                                style={{ background: colors.success }}
                                                                variant="contained"
                                                                onClick={() => setViewImport(true)}
                                                            >
                                                                Importar de Arquivo
                                                            </StyledButton>
                                                        )}
                                                        <StyledButton
                                                            style={{ background: colors.success }}
                                                            variant="contained"
                                                            onClick={handleModalNewAnimal}
                                                        >
                                                            Adicionar animal
                                                        </StyledButton>
                                                    </ButtonNextAnimalContainer>
                                                    {viewImport && (
                                                        <>
                                                            <a
                                                                href="https://beefsystem.com.br/download/planilha-entrada"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                Clique aqui para baixar a planilha de exemplo
                                                            </a>
                                                        </>
                                                    )}
                                                </div>
                                            </ContentOptions>
                                            <ContentAnimals>
                                                <StyledPaper style={{ marginTop: 16 }}>
                                                    <StyledTableContainer>
                                                        <Table stickyHeader>
                                                            <StyledTableHead>
                                                                <TableRow>
                                                                    <TableCell align="center">
                                                                        <TableSortLabel>Identificador</TableSortLabel>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <TableSortLabel>Raça</TableSortLabel>
                                                                    </TableCell>
                                                                    {!visibleNewAnimal && (
                                                                        <TableCell align="center">
                                                                            <TableSortLabel>Sexo</TableSortLabel>
                                                                        </TableCell>
                                                                    )}
                                                                    <TableCell align="center">
                                                                        <TableSortLabel>Aparte</TableSortLabel>
                                                                    </TableCell>
                                                                    <TableCell align="center" />
                                                                </TableRow>
                                                            </StyledTableHead>

                                                            <TableBody>
                                                                {arrayAnimalsSearch.length > 0 &&
                                                                    arrayAnimalsSearch
                                                                        .slice(
                                                                            page * rowsPerPage,
                                                                            page * rowsPerPage + rowsPerPage
                                                                        )
                                                                        .map(animalData => (
                                                                            <TableRow key={animalData[settings.settings.default_identification]}>
                                                                                <TableCell align="center">
                                                                                    {animalData[settings.settings.default_identification]}
                                                                                </TableCell>
                                                                                <TableCell align="center">
                                                                                    {data.optionsBreeds.find(
                                                                                        breed => breed.secure_id === animalData.breed_id
                                                                                    ).name ?? ''}
                                                                                </TableCell>
                                                                                {!visibleNewAnimal && (
                                                                                    <TableCell align="center">
                                                                                        {animalData.sex}
                                                                                    </TableCell>
                                                                                )}
                                                                                <TableCell align="center">
                                                                                    {animalData.apart}
                                                                                </TableCell>
                                                                                <StyledTableCell align="right">
                                                                                    <ButtonTable
                                                                                        icon={<EditIcon color="primary" />}
                                                                                        background={colors.primary}
                                                                                        lighten={0.6}
                                                                                        onClick={() =>
                                                                                            handleEditAnimal(animalData)
                                                                                        }
                                                                                    />
                                                                                    <ButtonTable
                                                                                        icon={<DeleteIcon color="error" />}
                                                                                        background={colors.error}
                                                                                        onClick={() =>
                                                                                            handleRemove(animalData[settings.settings.default_identification])
                                                                                        }
                                                                                    />
                                                                                </StyledTableCell>
                                                                            </TableRow>
                                                                        ))}
                                                            </TableBody>
                                                        </Table>
                                                    </StyledTableContainer>
                                                    <TablePagination
                                                        rowsPerPageOptions={[10, 25, 50, 100]}
                                                        labelRowsPerPage="Linhas por pág."
                                                        component="div"
                                                        count={arrayAnimals.length}
                                                        rowsPerPage={rowsPerPage}
                                                        page={page}
                                                        onChangePage={handleChangePage}
                                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                                    />
                                                </StyledPaper>
                                                {visibleNewAnimal && (
                                                    <AddNewAnimal
                                                        visibleModal={visibleNewAnimal}
                                                        handleModal={handleModalNewAnimal}
                                                        settings={settings.settings}
                                                        aparts={data.optionsAparts}
                                                        breeds={data.optionsBreeds}
                                                        // nextAnimal={nextAnimal}
                                                        animalEdit={animalEdit}
                                                        arrayAnimals={arrayAnimals}
                                                        setArrayAnimals={setArrayAnimals}
                                                        dateInput={dateInput}
                                                    />
                                                )}
                                            </ContentAnimals>

                                            <ContentSubmitButtons>
                                                <StyledSubmitButton
                                                    variant="outlined"
                                                    color="primary"
                                                    onClick={handleBack}
                                                >
                                                    Cancelar
                                                </StyledSubmitButton>
                                                <ButtonSubmit
                                                    type="button"
                                                    onClick={() => {
                                                        handleFinishApart(setFieldValue, values.finished_apart);
                                                    }}
                                                    title={
                                                        values &&
                                                            values.finished_apart
                                                            ? 'Reabrir manejo'
                                                            : 'Finalizar manejo'
                                                    }
                                                />
                                                <ButtonSubmit
                                                    title="Cadastrar"
                                                />
                                            </ContentSubmitButtons>
                                        </Content>
                                    </Form>
                                )}
                            </Formik>
                        </StyledPaper>

                        {visibleModalStakeholder && (
                            <ModalStakeholder
                                visibleModal={visibleModalStakeholder}
                                handleModal={handleModalStakeholder}
                                type="provider,both"
                            />
                        )}
                        {visibleImport && (
                            <ModalImport
                                visibleImport={visibleImport}
                                animalsImport={animalsImport}
                                arrayAnimals={arrayAnimals}
                                setVisibleImport={setVisibleImport}
                                setArrayAnimals={setArrayAnimals}
                                setAnimalsImport={setAnimalsImport}
                                aparts={data.optionsAparts}
                                breeds={data.optionsBreeds}
                                dateInput={dateInput}
                            />
                        )}
                    </>
                )}
            </Container>
        </center>
    )
}

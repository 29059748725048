import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

export async function getTreatment(sort, diet, date, performed) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/food-management/treatment?sort=${sort}`, {
      params: { diet, date, performed },
    });

    return {
      ...data
    }
  } catch {
    toast.error('Não foi possível buscar leituras!');
  }
}

export function useApiV2Treatment(sort, diet, date, performed) {
  return useQuery(['treatment', sort, diet, date, performed],
    () => getTreatment(sort, diet, date, performed),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    });
}

import { useQuery } from 'react-query';
import apiV2 from '~/services/apiV2';

export async function getReportEconomicClosingV2(lot) {
  const { data } = await apiV2.get(`v1/feedlot/report/economicClossingAnalytic`, {
    params: {
      lot
    }
  });

  return data
}

export function useReportEconomicClosingV2(lot = '') {
  return useQuery(['reportEconomicClosing', lot], () =>
    getReportEconomicClosingV2(lot)
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { InputAdornment, IconButton } from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';

export default function NewDatePicker({
  selectedDate,
  handleChangeDate,
  disabled = false,
  label,
  ...props
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <DatePicker
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        invalidDateMessage="Data inválida"
        maxDateMessage="A data não deve ser maior que a data máxima"
        minDateMessage="A data não deve ser anterior a data mínima"
        size="small"
        id="date-picker-inline"
        label={label || 'Data'}
        autoOk
        {...props}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton>
                <EventIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        disabled={disabled}
        value={selectedDate}
        onChange={handleChangeDate}
      />
    </MuiPickersUtilsProvider>
  );
}

NewDatePicker.defaultProps = {
  selectedDate: new Date(),
  disabled: false,
  label: null,
};

NewDatePicker.propTypes = {
  label: PropTypes.string,
  selectedDate: PropTypes.instanceOf(Date),
  handleChangeDate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

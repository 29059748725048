import styled from 'styled-components';
import {
  Paper,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  width: fit-content;
  padding: 20px 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px 50px;
  padding: 10px;

  img {
    height: 100px;
    width: 180px;
  }
`;

export const Column = styled.div``;

export const StyledTablePaper = styled(Paper)`
  width: 100%;

  .MuiTableCell-root {
    padding: 12px;
  }
`;

export const StyledTableRow = styled(TableRow)`
  padding: 0px 5px;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    background: ${colors.header};
    font-size: 1rem;

    th {
      color: ${colors.primary};
      font-size: 1rem;
    }

    td:last-child {
      width: 1%;
      white-space: nowrap;
    }
  }
  tr th {
    padding: 16px 11px;
  }
`;

export const StyledTableBody = styled(TableBody)`
  .MuiTableCell-body {
    font-size: 1rem;

    td:last-child {
      width: 1%;
      white-space: nowrap;
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

export const StyledTableCellMin = styled(TableCell)`
  font-size: 1px;
`;

export const StyledTableCellEllipsis = styled(TableCell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: ${props => props.maxWidth || 100}px;
`;

import styled from 'styled-components';
import { Paper, Divider, TableContainer, TableHead } from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  width: 100%;
  padding: 20px;
`;

export const Wrapper = styled.div`
  padding: 0 20px 80px;
`;

export const Header = styled.div`
  position: absolute;
  top: -22px;
  left: -18px;
  padding: 8px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 18px 16px 16px;
  position: relative;
  margin-top: 22px;
  border: 0.5px solid ${colors.lighter};

  & + div {
    margin-top: 38px;
  }

  h6 {
    font-weight: bold;
  }

  .MuiTypography-body2 {
    font-weight: 500;
    line-height: 25px;
    margin-right: 5px;
  }
`;

export const HeaderSection = styled.div`
  position: absolute;
  top: -22px;
  left: -18px;
  padding: 8px;
  border-radius: 5px;
  background: #fff;
  border: 0.5px solid ${colors.lighter};
`;

export const SubHeader = styled.div`
  display: flex;
  align-items: center;

  h4 {
    margin-top: 5px;
    font-size: 15px;
  }

  svg {
    color: ${colors.darker};
  }
`;

export const SubItem = styled.div`
  margin-left: 20px;
`;

export const SubItemRight = styled.div`
  margin-top: 26px;
`;

export const StyledDivider = styled(Divider)`
  margin: 16px 0;
`;

export const ContentGraphic = styled.div`
  margin-top: 18px;
`;

export const ContentChart = styled.div`
  padding-top: 10%;
  height: 100%;

  @media (max-width: 900px) {
    padding-top: 20%;
  }

  @media (max-width: 800px) {
    padding-top: 30%;
  }

  @media (max-width: 600px) {
    padding-top: 15px;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  max-height: calc(100vh - 255px);

  thead th {
    background: ${colors.header};
  }
`;
export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// MUI
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { Grid, TextField, Hidden, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

// Styled Components
import { parseISO } from 'date-fns';
import {
  Container,
  StyledPaper,
  StyledGrid,
  Error,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';

// Query
import { useApiV2ShowDataOutput } from '~/hooks/apiV2/animalHandling/ScrapOutput/useApiV2ShowDataOutput';
import { useApiV2AddScrapOutput } from '~/hooks/apiV2/animalHandling/ScrapOutput/useApiV2AddScrapOutput';

// Locals
import history from '~/services/history';
import Loader from '~/components/Loader';
import InputDatePicker from '~/components/InputDatePicker';
import NumberFormatForm from '~/components/NumberFormatForm';
import ButtonSubmit from '~/components/ButtonSubmit';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.value,
});

export default function Register() {
  const [currentLot, setCurrentLot] = useState(null);
  const [lots, setLots] = useState([]);
  const [listLots, setListLots] = useState([]);

  const [sex, setSex] = useState('');
  const [breed, setBreed] = useState('');
  const [age, setAge] = useState('');
  const [type, setType] = useState('');

  const [newListSexs, setNewListSexs] = useState([]);
  const [newListBreeds, setNewListBreeds] = useState([]);
  const [newListAges, setNewListAges] = useState([]);
  const [newListTypes, setNewListTypes] = useState([]);

  // Query
  const { data: lotsData, isLoading } = useApiV2ShowDataOutput();
  const addAnimalScrapOutput = useApiV2AddScrapOutput();

  useEffect(() => {
    if (lotsData) {
      const newLots = lotsData.map(lot => ({
        secure_id: lot.secure_id,
        value: lot.name,
      }));

      setListLots(newLots);
      setLots(lotsData)
    }
  }, [lotsData]);

  const schema = Yup.object().shape({
    date: Yup.string().required('Necessário informar uma data'),
    amount_animals: currentLot
      ? Yup.number()
        .typeError('Insira um valor válido')
        .max(
          currentLot.animals.length,
          'A qtd. deve ser menor ou igual a qtd. do lote'
        )
        .min(0, 'A qtd. deve ser maior que 0')
        .required('Digite a quantidade')
      : Yup.number()
        .typeError('Insira um valor válido')
        .required('Digite a quantidade'),
    obs: Yup.string().nullable(),
    lot_id: Yup.string().required('Necessário informar um Lote'),
  });

  function handleSelectLot(value, setFieldValue, resetForm) {
    if (value) {
      const lotFind = lots.find(lot => lot.secure_id === value.secure_id)
      if (lotFind) {
        setFieldValue('lot_id', value.secure_id);
        setFieldValue('lot', value);
        setCurrentLot(lotFind);

        if (lotFind.dateScrapOutput) setFieldValue('date', parseISO(lotFind.dateScrapOutput, 'dd/MM/yyyy'))
      } else {
        resetForm();
        setFieldValue('lot_id', '');
        setFieldValue('lot', null);
        setCurrentLot(null);
      }
    } else {
      resetForm();
      setFieldValue('lot_id', '');
      setFieldValue('lot', null);
      setCurrentLot(null);
    }
  }

  async function handleSubmit(data) {
    if (!sex) {
      return;
    }
    if (!age) {
      return;
    }
    if (!type) {
      return;
    }
    if (!breed) {
      return;
    }

    const newData = {
      date: data.date,
      originLotSecureId: data.lot_id,
      amountAnimals: data.amount_animals,
      obs: data.obs,
      animals: {
        type,
        sex,
        age,
        breedSecureId: breed,
      },
    };
    await addAnimalScrapOutput.mutateAsync(newData);
  }

  function handleBack() {
    history.goBack();
  }

  const handleChangeSex = data => {
    setSex(data.target.value);
    setBreed('');
    setAge('');
    setType('');
  };

  const handleChangeBreed = data => {
    setBreed(data.target.value);
  };

  const handleChangeAge = data => {
    setAge(data.target.value);
  };

  const handleChangeType = data => {
    setType(data.target.value);
  };

  useEffect(() => {
    if (currentLot) {
      const listSexsNew = [...new Set(currentLot.animals.map(x => x.sex))].map(animal => {
        const amount = currentLot.animals.filter(animalFilter => animalFilter.sex === animal).length
        return {
          sex: animal,
          amount
        }
      })
      setNewListSexs(listSexsNew)

      if (sex) {
        const listBreedsNew = [...new Set(currentLot.animals.map(x => x.breed.secure_id))].map(animal => {
          const amount = currentLot.animals.filter(animalFilter => animalFilter.sex === sex && animalFilter.breed.secure_id === animal).length
          const breedName = currentLot.animals.find(breedAnimal => breedAnimal.breed.secure_id === animal)
          return {
            breed: breedName ? breedName.breed.name : '',
            amount,
            secure_id: animal
          }
        }).filter(greatherThan => greatherThan.amount > 0)
        setNewListBreeds(listBreedsNew)

        if (breed) {
          const listAgesNew = [...new Set(currentLot.animals.map(x => x.age))].map(animal => {
            const amount = currentLot.animals.filter(
              animalFilter => animalFilter.sex === sex && animalFilter.breed.secure_id === breed && animalFilter.age === animal
            ).length
            return {
              amount,
              name: animal
            }
          }).filter(greatherThan => greatherThan.amount > 0)
          setNewListAges(listAgesNew)

          if (age) {
            const typeData = [...new Set(currentLot.animals.map(x => x.type))].map(animal => {
              const amount = currentLot.animals.filter(
                animalFilter => animalFilter.sex === sex && animalFilter.breed.secure_id === breed && animalFilter.age === age && animalFilter.type === animal
              ).length
              return {
                amount,
                name: animal
              }
            }).filter(greatherThan => greatherThan.amount > 0)
            setNewListTypes(typeData)
          }
        }
      }
    } else {
      setNewListAges([]);
      setNewListBreeds([]);
      setNewListTypes([]);
      setNewListSexs([]);
      setBreed('');
      setAge('');
      setType('');
      setSex('');
    }
  }, [sex, breed, age, currentLot])

  return (
    <center>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <Formik
              validationSchema={schema}
              initialValues={{
                date: new Date(),
                lot: null,
                lot_id: '',
                sex: '',
                breed_id: '',
                age: '',
                type: '',
                amount_animals: '',
                obs: '',
              }}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, resetForm, errors, touched, values, isSubmitting }) => (
                <Form>
                  <StyledGrid container justify="space-around" spacing={2}>
                    <Grid item xs={10} sm>
                      <InputDatePicker
                        name="date"
                        inputVariant="outlined"
                        fullWidth
                        required
                        maxDate={!!currentLot && currentLot.dateScrapOutput ? parseISO(currentLot.dateScrapOutput) : new Date()}
                        minDate={!!currentLot && currentLot.dateScrapOutput ? parseISO(currentLot.dateScrapOutput) : null}
                        selectedDate={values.date}
                        error={!!errors.date && touched.date}
                        handleChangeDate={date => {
                          setFieldValue('date', date);
                        }}
                      />
                      <ErrorMessage name="date" component={Error} />
                    </Grid>
                    <Hidden xsDown>
                      <Grid item sm />
                    </Hidden>
                    <Hidden xsDown>
                      <Grid item sm />
                    </Hidden>
                  </StyledGrid>

                  <StyledGrid container justify="space-around" spacing={2}>
                    <Grid item xs={10} sm>
                      <Autocomplete
                        name="lot"
                        size="small"
                        noOptionsText="Sem opções"
                        filterOptions={filterOptions}
                        options={listLots}
                        value={values.lot}
                        getOptionLabel={option => option.value}
                        onChange={(event, value) =>
                          handleSelectLot(
                            value,
                            setFieldValue,
                            resetForm,
                            values
                          )
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Lote"
                            required
                            fullWidth
                            variant="outlined"
                          />
                        )}
                        error={
                          (!!errors.lot_id && touched.lot_id)
                        }
                      />
                      <ErrorMessage name="lot_id" component={Error} />
                    </Grid>
                    <Grid item xs={10} sm>
                      <TextField
                        name="sex"
                        label="Sexo"
                        variant="outlined"
                        fullWidth
                        select
                        required
                        size="small"
                        InputLabelProps={{
                          shrink: sex,
                        }}
                        error={!!errors.sex && touched.sex}
                        value={sex}
                        onChange={handleChangeSex}
                      >
                        {newListSexs.map(sexL => (
                          <MenuItem key={sexL.sex} value={sexL.sex}>
                            {`${sexL.sex} - Saldo: ${sexL.amount}`}
                          </MenuItem>
                        ))}
                      </TextField>
                      <ErrorMessage name="sex" component={Error} />
                    </Grid>
                    <Grid item xs={10} sm>
                      <TextField
                        name="breed_id"
                        label="Raça"
                        variant="outlined"
                        fullWidth
                        select
                        required
                        size="small"
                        InputLabelProps={{
                          shrink: breed,
                        }}
                        error={!!errors.breed_id && touched.breed_id}
                        value={breed}
                        onChange={handleChangeBreed}
                      >
                        {newListBreeds.map(item =>
                          item && item.secure_id ? (
                            <MenuItem key={item.secure_id} value={item.secure_id}>
                              {`${item.breed} - Saldo: ${item.amount}`}
                            </MenuItem>
                          ) : (
                            ''
                          )
                        )}
                      </TextField>
                      <ErrorMessage name="breed_id" component={Error} />
                    </Grid>
                  </StyledGrid>

                  <StyledGrid container justify="space-around" spacing={2}>
                    <Grid item xs={10} sm>
                      <TextField
                        name="age"
                        label="Idade"
                        variant="outlined"
                        fullWidth
                        select
                        required
                        size="small"
                        InputLabelProps={{
                          shrink: age,
                        }}
                        error={!!errors.age && touched.age}
                        value={age}
                        onChange={handleChangeAge}
                      >
                        {newListAges.map(ageD =>
                          ageD && ageD.name ? (
                            <MenuItem key={ageD.name} value={ageD.name}>
                              {`${ageD.name} - Saldo: ${ageD.amount}`}
                            </MenuItem>
                          ) : (
                            ''
                          )
                        )}
                      </TextField>
                      <ErrorMessage name="sex" component={Error} />
                    </Grid>
                    <Grid item xs={10} sm>
                      <TextField
                        name="type"
                        label="Tipo"
                        variant="outlined"
                        fullWidth
                        select
                        required
                        size="small"
                        InputLabelProps={{
                          shrink: type,
                        }}
                        error={!!errors.type && touched.type}
                        value={type}
                        onChange={handleChangeType}
                      >
                        {newListTypes.map(typeD =>
                          typeD && typeD.name ? (
                            <MenuItem key={typeD.name} value={typeD.name}>
                              {`${typeD.name} - Saldo: ${typeD.amount}`}
                            </MenuItem>
                          ) : (
                            ''
                          )
                        )}
                      </TextField>
                      <ErrorMessage name="type" component={Error} />
                    </Grid>
                    <Grid item xs={10} sm>
                      <NumberFormatForm
                        label="Qtd. no lote"
                        disabled
                        value={currentLot ? currentLot.animals.length : 0}
                      />
                    </Grid>
                  </StyledGrid>

                  <StyledGrid container justify="space-around" spacing={2}>
                    <Grid item sm xs={10}>
                      <NumberFormatForm
                        name="amount_animals"
                        label="Quantidade"
                        variant="outlined"
                        size="small"
                        required
                        fullWidth
                        allowNegative={false}
                        autoComplete="nope"
                        decimalScale={0}
                        value={values.amount_animals}
                        setFieldValue={setFieldValue}
                        error={
                          !!errors.amount_animals && touched.amount_animals
                        }
                      />
                      <ErrorMessage name="amount_animals" component={Error} />
                    </Grid>

                    <Hidden xsDown>
                      <Grid item sm />
                    </Hidden>
                  </StyledGrid>

                  <StyledGrid container justify="space-around" spacing={2}>
                    <Grid item xs={10} sm>
                      <TextField
                        name="obs"
                        variant="outlined"
                        label="Observação"
                        autoComplete="nope"
                        fullWidth
                        size="small"
                        value={values.obs}
                        onChange={e => setFieldValue('obs', e.target.value)}
                      />
                    </Grid>
                  </StyledGrid>

                  <ContentSubmitButtons>
                    <ButtonSubmit title="Cadastrar" loading={isSubmitting} />
                    <StyledSubmitButton
                      variant="outlined"
                      color="primary"
                      onClick={handleBack}
                    >
                      Cancelar
                    </StyledSubmitButton>
                  </ContentSubmitButtons>
                </Form>
              )}
            </Formik>
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

Register.defaultProps = {
  match: null,
};

import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: ${colors.white};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  width: 100%;
  height: 100vh;
  padding: 40px;

  img {
    width: 100%;
  }
`;

export const Text = styled.h1`
  font-size: 38px;
  color: #2e2b2b;
`;


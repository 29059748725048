import React, { useState, useEffect } from 'react';
import AddIcon from '@material-ui/icons/Add';

import {
  Grid,
  Typography,
  Hidden,
  Tooltip,
  Fade,
} from '@material-ui/core';
import IconError from '@material-ui/icons/Error';
import IconWarning from '@material-ui/icons/Warning';

import {
  Container,
  StyledPaper,
  ContentItem,
  ContentSubmitButtons,
  StyledSubmitButton,
  ContentAlerts,
  Alert,
  AlertWarning,
  StyledIconButton,
} from './styles';

import ModalDietSwitch from '../Components/ModalDietSwitch';

import history from '~/services/history';

export default function Add(props) {
  const [currentTreatments, setCurrentTreatments] = useState([]);
  const [currentTreatment, setCurrentTreatment] = useState(null);
  const [visibleModalDiet, setVisibleModalDiet] = useState(false);
  const [changedDiet, setChangedDiet] = useState(false);
  const [modalCallback, setModalCallback] = useState(null);

  const paddocksChangeDiet = props.location ? props.location.paddocksChangeDiet : ''; //eslint-disable-line
  const extra = props.location ? props.location.extra : ''; //eslint-disable-line

  useEffect(() => {
    if (paddocksChangeDiet) {
      setCurrentTreatments(paddocksChangeDiet);
    }
  }, [paddocksChangeDiet]);

  function handleModalDiet(treatment, changed, callback) {
    if (treatment) {
      setCurrentTreatment(treatment);
    }

    if (changed) {
      setChangedDiet(true);
    }

    setModalCallback(callback);
    setVisibleModalDiet(true);
  }

  function closeModalDiet(changed) { //eslint-disable-line
    // if (treatment) {
    //   setCurrentTreatment(treatment);
    // }

    if (changed) {
      setChangedDiet(true);
      const newTreatments = currentTreatments.filter(treatmentFiltered => treatmentFiltered.paddock.secure_id !== currentTreatment.paddock.secure_id)
      setCurrentTreatments(newTreatments);

      if (newTreatments.length === 0) {
        handleBack();
      }
    }

    setVisibleModalDiet(false);
  }

  function handleBack() {
    history.push({
      pathname: '/dashboard/troughReading',
      extra
    });
  }

  useEffect(() => {
    if (!visibleModalDiet && changedDiet) {
      setCurrentTreatment(null);
      setChangedDiet(false);
    }
  }, [changedDiet, visibleModalDiet]);

  return (
    <center>
      <Container>
        <Typography variant="h6" color="primary" noWrap display="block">
          ATENÇÃO!!! Para realizar a os próximos tratos é necessário que troque as dietas dos seguintes piquetes:
        </Typography>
        {currentTreatments.map(treatment => (
          <ContentItem key={treatment.secure_id}>
            <StyledPaper>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm>
                  <Grid container direction="column">
                    <Grid item sm style={{ marginBottom: 5 }}>
                      <Hidden only="xs">
                        <Typography variant="body1" color="primary">
                          Piquete
                        </Typography>
                      </Hidden>
                      <Hidden smUp>
                        <Typography
                          variant="body1"
                          color="primary"
                          align="left"
                        >
                          Piquete
                        </Typography>
                      </Hidden>
                    </Grid>
                    <Grid item sm>
                      <Hidden only="xs">
                        <Typography variant="body2" color="textSecondary">
                          {treatment.paddock.name}
                        </Typography>
                      </Hidden>
                      <Hidden smUp>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          align="left"
                        >
                          {treatment.paddock.name}
                        </Typography>
                      </Hidden>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm>
                  <Grid container direction="column">
                    <Grid item sm style={{ marginBottom: 5 }}>
                      <Hidden only="xs">
                        <Typography variant="body1" color="primary">
                          Dieta
                        </Typography>
                      </Hidden>
                      <Hidden smUp>
                        <Typography
                          variant="body1"
                          color="primary"
                          align="left"
                        >
                          Dieta
                        </Typography>
                      </Hidden>
                    </Grid>
                    <Grid item sm>
                      <Hidden only="xs">
                        <Typography variant="body2" color="textSecondary">
                          {treatment.diet.name}
                        </Typography>
                      </Hidden>
                      <Hidden smUp>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          align="left"
                        >
                          {treatment.diet.name}
                        </Typography>
                      </Hidden>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm>
                  <Hidden only="xs">
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                    >
                      <Typography variant="body1" color="primary">
                        Necessário realizar a troca de dieta
                      </Typography>
                      <Grid item sm={4} style={{ marginTop: 4 }}>
                        <StyledIconButton
                          variant="contained"
                          size="small"
                          onClick={() => handleModalDiet(treatment)}
                        // disabled={secure_id && hasTreatment}
                        >
                          <AddIcon />
                        </StyledIconButton>
                      </Grid>
                    </Grid>
                  </Hidden>
                </Grid>
              </Grid>

              <ContentAlerts changeDiet={treatment.changeDiet}>
                {treatment.changeDiet && (
                  <Alert>
                    <Tooltip
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      title="Necessário realizar a troca de dieta"
                    >
                      <span>
                        <IconError color="error" />
                      </span>
                    </Tooltip>
                  </Alert>
                )}
                {(treatment.changeDeviationUp ||
                  treatment.changeDeviationDown) && (
                    <AlertWarning>
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        title={
                          treatment.changeDeviationUp
                            ? 'Desvio acima do permitido'
                            : 'Desvio abaixo do permitido'
                        }
                      >
                        <span>
                          <IconWarning />
                        </span>
                      </Tooltip>
                    </AlertWarning>
                  )}
              </ContentAlerts>
            </StyledPaper>
          </ContentItem>
        ))}

        <ContentSubmitButtons>
          <StyledSubmitButton
            color="primary"
            variant="outlined"
            onClick={handleBack}
          >
            Voltar
          </StyledSubmitButton>
        </ContentSubmitButtons>

        <ModalDietSwitch
          paddockId={currentTreatment && currentTreatment.paddock.secure_id}
          visible={visibleModalDiet}
          handleModal={handleModalDiet}
          closeModal={closeModalDiet}
          callback={modalCallback}
        />
      </Container>
    </center>
  );
}

import styled from 'styled-components';
import { Paper, TableContainer, TableHead } from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 1024px;
  width: 100%;
  padding: 40px;

  text-align: left;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

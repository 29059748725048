import styled from 'styled-components';
import { Paper, Grid } from '@material-ui/core';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 70%;
  max-width: 960px;
  padding: 20px;
  border-radius: 5px;
  overflow: hideen;

  background: ${colors.darkWhite};
`;

export const StyledPaperLoader = styled(Paper)`
  position: relative;
  padding: 20px;
  width: 100%;
  max-height: 450px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledPaper = styled(Paper)`
  position: relative;
  padding: 20px;
  width: 100%;
`;

export const StyledGrid = styled(Grid)`
  margin: 16px 0px;
`;

export const StyledGridItem = styled(Grid)`
  margin: 8px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -18px;
  right: -16px;
  border: none;
  background: none;

  svg {
    background: ${colors.darkWhite};
    border-radius: 50%;
    overflow: hidden;
    transition: background 2s;

    &:hover {
      background: ${darken(0.1, colors.darkWhite)};
    }
  }
`;

import React from 'react';
import Swal from 'sweetalert2';

import {
  TableRow
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

import { useApiV2RemoveTreaty } from '~/hooks/apiV2/register/treaty/useApiV2RemoveTreaty';

import ActionLoader from '~/components/ActionLoader';
import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';
import { formatNumber } from '~/utils/format';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';

export function CardTableTreaty({ treaty }) {
  const removeTreaty = useApiV2RemoveTreaty()

  function handleEdit({ secure_id }) {
    history.push(`/dashboard/treatySetting/edit/${secure_id}`);
  }

  function handleRemove({ secure_id }) {
    async function performRemoveTreaty() {
      await removeTreaty.mutateAsync(secure_id)
    }
    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar esse trato?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar trato!',
    }).then(result => {
      if (result.value) {
        performRemoveTreaty();
      }
    });
  }

  return (
    <TableRow>
      <StyledTableCellEllipsis component="th" scope="row">
        {treaty.name}
      </StyledTableCellEllipsis>
      <StyledTableCell align="right">
        {`${formatNumber(treaty.proportion, 0, 4)}%`}
      </StyledTableCell>
      <StyledTableCell align="right">
        {removeTreaty.isLoading ? (
          <ActionLoader text="Deletando" />
        ) : (
          <>
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['treatySetting_edit']}
            >
              <ButtonTable
                icon={<EditIcon color="primary" />}
                background={colors.primary}
                lighten={0.6}
                onClick={() => handleEdit(treaty)}
              />
            </Can>

            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['treatySetting_delete']}
            >
              <ButtonTable
                icon={
                  <DeleteIcon color={
                    treaty.has_treaty_treatments > 0 ? "disabled" : "error"
                  } />
                }
                disabled={treaty.has_treaty_treatments > 0}
                background={
                  treaty.has_treaty_treatments > 0
                    ? colors.grey
                    : colors.error
                }
                onClick={() => handleRemove(treaty)}
              />
            </Can>
          </>
        )}
      </StyledTableCell>
    </TableRow>
  )
}

import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";
import { formatDateOnly } from "~/utils/format";

export async function getEfficiencyTreatment({ initialDate = new Date(), finalDate = new Date(), paddock }) {
  const { data } = await apiV2.get('/v1/feedlot/report/efficiencyTreatment', {
    params: { initialDate, finalDate, paddock }
  });

  const newData = data.map(treatment => ({
    ...treatment,
    deviation: Number(
      (
        (treatment.total_performed / treatment.total_forecast - 1) *
        100
      ).toFixed(2)
    ),
    date: formatDateOnly(treatment.date),
  }));

  return newData
}

export function useApiV2EfficiencyTreatment({ initialDate, finalDate, paddock }) {
  return useQuery(['efficiencyTreatment', { initialDate, finalDate, paddock }],
    () => getEfficiencyTreatment({ initialDate, finalDate, paddock }),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  );
}

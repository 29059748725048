import React from 'react';
import Swal from 'sweetalert2';

import {
  TableRow,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

import { useApiV2RemoveCauseOfDeath } from '~/hooks/apiV2/register/causeOfDeath/useApiV2RemoveCauseOfDeath';

import ActionLoader from '~/components/ActionLoader';
import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';

export function CardTableCauseOfDeath({ causeOfDeath }) {
  const removeCauseOfDeath = useApiV2RemoveCauseOfDeath();

  function handleEdit({ secure_id }) {
    history.push(`/dashboard/causeOfDeath/edit/${secure_id}`);
  }

  function handleRemoveCauseOfDeath({ secure_id }) {
    async function performRemoveCauseOfDeath() {
      await removeCauseOfDeath.mutateAsync(secure_id);
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja apagar essa cause da morte?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar causa da morte!',
    }).then(result => {
      if (result.value) {
        performRemoveCauseOfDeath();
      }
    });
  }

  return (
    <TableRow>
      <StyledTableCellEllipsis component="th" scope="row">
        {causeOfDeath.name}
      </StyledTableCellEllipsis>
      <StyledTableCellEllipsis>
        {causeOfDeath.description}
      </StyledTableCellEllipsis>
      <Can checkRole={['administrator', 'owner', 'manager']}>
        <StyledTableCell align="right">
          {removeCauseOfDeath.isLoading ? (
            <ActionLoader text="Deletando" />
          ) : (
            <>
              <Can
                checkRole={['administrator', 'owner', 'beefer']}
                checkPermission={['causeOfDeath_edit']}
              >
                <ButtonTable
                  icon={<EditIcon color="primary" />}
                  background={colors.primary}
                  lighten={0.6}
                  onClick={() => handleEdit(causeOfDeath)}
                />
              </Can>
              <Can
                checkRole={['administrator', 'owner', 'beefer']}
                checkPermission={['causeOfDeath_delete']}
              >
                <ButtonTable
                  icon={
                    <DeleteIcon color={
                      causeOfDeath.has_animals > 0 ? "disabled" : "error"
                    } />
                  }
                  disabled={causeOfDeath.has_animals > 0}
                  background={
                    causeOfDeath.has_animals > 0
                      ? colors.grey
                      : colors.error
                  }
                  onClick={() =>
                    handleRemoveCauseOfDeath(causeOfDeath)
                  }
                />
              </Can>
            </>
          )}
        </StyledTableCell>
      </Can>
    </TableRow>
  )
}

import {
  Fade, TableCell, TableRow, Tooltip
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import ButtonTable from '~/components/ButtonTable';
import Can from '~/components/Can';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';
import colors from '~/styles/colors';
import ModalAnimalInput from '../Modal'
import {
  StyledTableCell
} from './styles';

export function CardLotsEntry({ animal }) {
  const [visibleModal, setVisibleModal] = useState(false)

  const removeAnimalInputLot = useMutation(async () => {
    return await apiV2.delete(`v1/feedlot/animal-handling/lots-entry/${animal.secure_id}`);
  },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('animalInputLot');
        queryClient.invalidateQueries('LotsEntry');

        toast.success(response.data?.message || 'Entrada de lote apagada com sucesso!');
      },
      onError: (error) => {
        if (!!error.response.data?.type) {
          if (error.response.data?.type === 'warning') {
            toast.warning(error.response.data.message)
          } else {
            toast.error(error.response.data.message)
          }
        } else {
          toast.error('Falha ao tentar apagar entrada de lote.')
        }
      },
    }
  );

  function handleSelectAnimalInput() {
    history.push(`/dashboard/animalInput/edit/${animal.secure_id}`);
  }

  function handleRemoveAnimalInput() {
    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar esta entrada de lote?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, apagar entrada de lote!',
    }).then(async result => {
      if (result.value) {
        await removeAnimalInputLot.mutateAsync();
      }
    });
  }

  function handleViewAnimalModal() {
    setVisibleModal(true);
  }

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row">
          {animal.date}
        </TableCell>
        <TableCell align="left">
          {animal.lot}
        </TableCell>
        <TableCell align="left">{animal.breed}</TableCell>
        <TableCell align="left">{animal.sex}</TableCell>
        <TableCell align="left">
          {animal.paddock}
        </TableCell>
        <TableCell align="left">
          {animal.amount_animals}
        </TableCell>
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={[
            'animalInput_edit',
            'animalInput_delete',
          ]}
        >
          {can =>
            can ? (
              <StyledTableCell align="right" colSpan={1}>
                <Can
                  checkRole={[
                    'administrator',
                    'owner',
                    'beefer',
                  ]}
                  checkPermission={['animalInput_edit']}
                >
                  <ButtonTable
                    icon={<EditIcon color="primary" />}
                    background={colors.primary}
                    lighten={0.6}
                    onClick={handleSelectAnimalInput}
                  />
                </Can>
                <Can
                  checkRole={[
                    'administrator',
                    'owner',
                    'beefer',
                  ]}
                  checkPermission={['animalInput_delete']}
                >
                  <Tooltip
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    title="Já existe tratamento realizado para este lote"
                    disableHoverListener={!animal.hasTreatments}
                  >
                    <span>
                      <ButtonTable
                        icon={
                          <DeleteIcon color={animal.hasTreatments ? 'disabled' : 'error'} />
                        }
                        background={colors.error}
                        onClick={handleRemoveAnimalInput}
                        disabled={animal.hasTreatments}
                      />
                    </span>
                  </Tooltip>
                </Can>
              </StyledTableCell>
            ) : (
              <StyledTableCell align="right" colSpan={1}>
                <ButtonTable
                  icon={<VisibilityIcon />}
                  background={colors.dark}
                  lighten={0.5}
                  onClick={handleViewAnimalModal}
                />
              </StyledTableCell>
            )
          }
        </Can>
      </TableRow>
      {!!visibleModal && (
        <ModalAnimalInput
          visibleModal={visibleModal}
          handleCloseModal={() => setVisibleModal(false)}
          secureIdView={animal.secure_id}
        />
      )}
    </>
  )
}
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Query
import { useV2GlobalDiets } from '~/hooks/apiV2/global/diet/useV2GlobalDiets';

import { Content, StyledPaper, StyledFormControl, ContentDate } from './styles';

import DatePicker from '~/components/DatePicker';
import { addDays } from 'date-fns';

const dietDefaultOption = {
  value: null,
  name: 'Todas',
};

const performedOptions = [
  {
    id: 0,
    value: 'all',
    content: 'Todos',
  },
  {
    id: 1,
    value: true,
    content: 'Sim',
  },
  {
    id: 2,
    value: false,
    content: 'Não',
  },
];

export default function Filter({
  currentDiet,
  setCurrentDiet,
  currentDate,
  setCurrentDate,
  setCurrentPerformed,
}) {
  const [diets, setDiets] = useState([]);

  // Query
  const { data: dietsData } = useV2GlobalDiets();

  function handleChangeDiet(event) {
    setCurrentDiet(event.target.value);
  }

  function handleChangeDate(date) {
    setCurrentDate(date);
  }

  function handleChangecurrentPerformed(event) {
    setCurrentPerformed(event.target.value);
  }

  useEffect(() => {
    if (dietsData) {
      setDiets(dietsData.diets)
    }
  }, [dietsData]);

  return (
    <Content>
      <StyledPaper>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={12} sm md lg xl>
            <StyledFormControl size="small" fullWidth>
              <InputLabel id="input-diet">Dieta</InputLabel>
              <Select
                id="currentDiet"
                defaultValue={dietDefaultOption}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                value={currentDiet || dietDefaultOption}
                onChange={handleChangeDiet}
              >
                <MenuItem value={dietDefaultOption}>
                  {dietDefaultOption.name}
                </MenuItem>
                {diets.map(item => (
                  <MenuItem key={item.value} value={item}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} sm md lg xl>
            <ContentDate>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  fullWidth
                  maxDate={addDays(new Date(), 1)}
                  selectedDate={currentDate}
                  handleChangeDate={date => handleChangeDate(date)}
                />
              </MuiPickersUtilsProvider>
            </ContentDate>
          </Grid>

          <Grid item xs={12} sm md lg xl>
            <StyledFormControl size="small" fullWidth>
              <InputLabel id="input-currentPerformed">Realizado</InputLabel>
              <Select
                id="currentPerformed"
                defaultValue={performedOptions[2]}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                value={performedOptions.content}
                onChange={handleChangecurrentPerformed}
              >
                {performedOptions.map(item => (
                  <MenuItem key={item.id} value={item}>
                    {item.content}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Grid>
        </Grid>
      </StyledPaper>
    </Content>
  );
}

Filter.propTypes = {
  currentDiet: PropTypes.shape({}).isRequired,
  setCurrentDiet: PropTypes.func.isRequired,
  currentDate: PropTypes.shape({}).isRequired,
  setCurrentDate: PropTypes.func.isRequired,
  currentPerformed: PropTypes.shape({}).isRequired,
  setCurrentPerformed: PropTypes.func.isRequired,
};

import React from 'react';
import Swal from 'sweetalert2';

import {
  TableCell,
  TableRow
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ButtonTable from '~/components/ButtonTable';

// Query hooks
import { useApiV2RemoveScrapOutput } from '~/hooks/apiV2/animalHandling/ScrapOutput/useApiV2RemoveScrapOutput';

import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell,
  StyledTableCellEllipsis
} from './styles';
import { format } from 'date-fns';
import history from '~/services/history';
import { formatNamePaddock } from '~/utils/format';
import ActionLoader from '~/components/ActionLoader';

export function CardTableScrapOutput({ output, settings }) {
  // Query
  const removeAnimalScrapOutput = useApiV2RemoveScrapOutput()

  function handleEdit(secure_id) {
    history.push(`/dashboard/animalScrapOutput/edit/${secure_id}`);
  }

  function handleRemove(secure_id) {
    async function performRemoveAnimalScrapOutput() {
      await removeAnimalScrapOutput.mutateAsync(secure_id)
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Esta ação não tem volta! Deseja remover essa saída de refugo?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, remover saída de refugo!',
    }).then(async result => {
      if (result.value) {
        performRemoveAnimalScrapOutput();
      }
    });
  }

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {`${output.originLot.code} - ${formatNamePaddock(settings, output.originLot.paddock)}`}
      </TableCell>
      <TableCell align="left">
        {output.amount_animals}
      </TableCell>
      <StyledTableCellEllipsis>
        {output.obs}
      </StyledTableCellEllipsis>
      <TableCell align="center">
        {format(new Date(output.date), 'dd/MM/yyyy')}
      </TableCell>
      <StyledTableCell align="right">
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['animalScrapOutput_edit']}
        >
          <ButtonTable
            icon={<EditIcon color="primary" />}
            background={colors.primary}
            lighten={0.6}
            onClick={() => handleEdit(output.secure_id)}
          />
        </Can>
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['animalScrapOutput_delete']}
        >
          <ButtonTable
            icon={
              removeAnimalScrapOutput.isLoading ? (
                <ActionLoader text="Deletando" />
              ) :
                (
                  <DeleteIcon color="error" />
                )
            }
            background={colors.error}
            onClick={() => handleRemove(output.secure_id)}
          />
        </Can>
      </StyledTableCell>
    </TableRow>
  )
}

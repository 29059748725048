import React, { useState, useRef, useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import {
  Grid,
  Button,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import IconButton from '@material-ui/core/IconButton';
import { MdSubdirectoryArrowRight } from 'react-icons/md';

import {
  Container,
  StyledPaper,
  Header,
  ContentChart,
  ContentPrint,
  StyledTableContainer,
  StyledTableHead,
  SubHeader,
  SubItem,
} from './styles';

import Print from './Print';
import Filter from './Components/Filter';

import { formatNumber } from '~/utils/format';
import Loader from '~/components/Loader';

const optionsChartPie = {
  responsive: [
    {
      breakpoint: 400,
      options: {
        chart: {
          width: 10,
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
  legend: {
    formatter(val, opts) {
      return `${val} - ${Number(
        opts.w.globals.seriesPercent[opts.seriesIndex]
      ).toFixed(2)}%`;
    },
  },
};

const FoodDetails = ({ feeds }) => {
  return (
    <div style={{ padding: 4 }}>
      {feeds.map(feed => (
        <p>{`${feed.name} - ${feed.performed}`}</p>
      ))}
    </div>
  );
};

FoodDetails.defaultProps = {
  feeds: [],
};

FoodDetails.propTypes = {
  feeds: PropTypes.arrayOf(),
};

export default function EconomicClosing() {
  const [date, setDate] = useState(new Date());
  const [openFoodDetails, setOpenFoodDetails] = useState(false);
  const [totalPriceFoods, setTotalPriceFoods] = useState();

  const [economicClosing, setEconomicClosing] = useState([]);

  useEffect(() => {
    if (!!economicClosing && !!economicClosing.foods) {
      setTotalPriceFoods(
        economicClosing.foods.reduce((totalPrice, value) => {
          return totalPrice + value.totalPriceDays;
        }, 0)
      );
    }
  }, [economicClosing]);

  const [loading, setLoading] = useState(false);

  const PrintScreen = useRef();

  return (
    <>
      <center>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs sm md lg xl>
              <Filter
                date={date}
                setDate={setDate}
                setLoading={setLoading}
                setEconomicClosing={setEconomicClosing}
              />
            </Grid>
            <Grid item xs="auto" sm="auto">
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={Object.keys(economicClosing).length === 0}
                    startIcon={<PrintIcon />}
                  >
                    Imprimir
                  </Button>
                )}
                content={() => PrintScreen.current}
              />
            </Grid>
          </Grid>

          {loading && !economicClosing ? (
            <Loader />
          ) : (
            <>
              <StyledPaper>
                <Header>
                  <h3>Indentificação</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Lote:
                      </Typography>
                      <strong>
                        {economicClosing.identification &&
                          economicClosing.identification.lot}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Fornecedor:
                      </Typography>
                      <strong>
                        {economicClosing.identification &&
                          economicClosing.identification.providers
                            .map(provider => provider)
                            .join(', ')}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Cliente:
                      </Typography>
                      <strong>
                        {economicClosing.identification &&
                          economicClosing.identification.customers
                            .map(client => client)
                            .join(', ')}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Tipo de entrada:
                      </Typography>
                      <strong>
                        {economicClosing.identification &&
                          economicClosing.identification.inputsType
                            .map(inputsType => inputsType)
                            .join(', ')}
                      </strong>
                    </Grid>

                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Raça:
                      </Typography>
                      <strong>
                        {economicClosing.identification &&
                          economicClosing.identification.breeds
                            .map(breed => breed)
                            .join(', ')}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Categoria:
                      </Typography>
                      <strong>
                        {economicClosing.identification &&
                          economicClosing.identification.categories
                            .map(categorie => categorie)
                            .join(', ')}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaper>
              <Grid container spacing={2} style={{ marginTop: 20 }}>
                <Grid item xs sm={4}>
                  <StyledPaper style={{ minHeight: 233 }}>
                    <Header>
                      <h3>Dados de Entrada</h3>
                    </Header>
                    <Grid container>
                      <Grid item xs sm>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Data Média de Entrada:
                          </Typography>
                          <strong>
                            {economicClosing.input &&
                              economicClosing.input.averageDate}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Quantidade de Animais:
                          </Typography>
                          <strong>
                            {economicClosing.input &&
                              economicClosing.input.amountAnimals}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Peso Vivo Médio (Kg/Cab):
                          </Typography>
                          <strong>
                            {economicClosing.input &&
                              formatNumber(
                                economicClosing.input.sightWeight,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>

                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Rendimento de Carcaça (%):
                          </Typography>
                          <strong>
                            {economicClosing.input &&
                              formatNumber(
                                economicClosing.input.carcassYield,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            @ Entrada:
                          </Typography>
                          <strong>
                            {economicClosing.input &&
                              formatNumber(
                                economicClosing.input.atInputs,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Total @ Entrada:
                          </Typography>
                          <strong>
                            {economicClosing.input &&
                              formatNumber(
                                economicClosing.input.atTotalInputs,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Grid>
                <Grid item xs sm={4}>
                  <StyledPaper style={{ minHeight: 233 }}>
                    <Header>
                      <h3>Dados de Saída</h3>
                    </Header>
                    <Grid container>
                      <Grid item xs sm>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Data Média de Saída:
                          </Typography>
                          <strong>
                            {economicClosing.output &&
                              economicClosing.output.averageDate}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Quantidade de Animais:
                          </Typography>
                          <strong>
                            {economicClosing.output &&
                              economicClosing.output.amountAnimals}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Peso Vivo Médio (Kg/Cab):
                          </Typography>
                          <strong>
                            {economicClosing.output &&
                              formatNumber(
                                economicClosing.output.sightWeight,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Rendimento de Carcaça (%):
                          </Typography>
                          <strong>
                            {economicClosing.output &&
                              formatNumber(
                                economicClosing.output.carcassYield,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            @ Saida:
                          </Typography>
                          <strong>
                            {economicClosing.output &&
                              formatNumber(
                                economicClosing.output.atOutputs,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Total @ Saida:
                          </Typography>
                          <strong>
                            {economicClosing.output &&
                              formatNumber(
                                economicClosing.output.atTotalOutputs,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Grid>
                <Grid item xs sm={4}>
                  <StyledPaper style={{ minHeight: 233 }}>
                    <Header>
                      <h3>Dados de Desempenho</h3>
                    </Header>
                    <Grid container>
                      <Grid item xs sm>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Dias de Confinamento:
                          </Typography>
                          <strong>
                            {economicClosing.resume &&
                              economicClosing.resume.daysOnFeedlot}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Total de Diarias:
                          </Typography>
                          <strong>
                            {economicClosing.financialAnalysis &&
                              economicClosing.resume.amountDaily}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            GMD:
                          </Typography>
                          <strong>
                            {economicClosing.resume &&
                              formatNumber(
                                economicClosing.resume.dailyWeightGain,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            GMC:
                          </Typography>
                          <strong>
                            {economicClosing.resume &&
                              formatNumber(
                                economicClosing.resume.dailyCarcassGain,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            @ Colocada:
                          </Typography>
                          <strong>
                            {economicClosing.resume &&
                              formatNumber(economicClosing.resume.atGain, 2, 2)}
                          </strong>
                        </Grid>
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Grid>
              </Grid>

              <StyledPaper style={{ marginTop: 40 }}>
                <Header>
                  <h3>Analise Financeira</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm={4}>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Valor @ de Entrada:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.input.valueAt,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Valor / Cabeça de Entrada:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.input.valueHead,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Valor Total de Entrada:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.input.valueTotal,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Valor @ de Venda:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.output.valueAt,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>

                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Receita / Cabeça:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.output
                              .revenueHead,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Receita Total:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.output
                              .revenueTotal,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                  </Grid>
                  <Grid item xs sm={4}>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo Alimentar / Cabeça Dia:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .feedCostHeadDay,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo Alimentar / Cabeça:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .feedCostHead,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo Alimentar / Lote:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .feedCostLot,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo Sanitário / Cabeça Dia:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .sanitaryCostHeadDay,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo Sanitário / Cabeça:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .sanitaryCostHead,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo Sanitário / Lot:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .sanitaryCostLot,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo Operacional / Cabeça Dia:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .operationalCostHeadDay,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo Operacional / Cabeça:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .operationalCostHead,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo Operacional / Lot:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .operationalCostLot,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                  </Grid>
                  <Grid item xs sm={4}>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo Final / Cabeça Dia:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .finalCostHeadDay,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo Final / Cabeça:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .finalCostHead,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo Final / Lote:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .finalCostLot,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        C.Final + C.Compra / Cabeça:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .finalCostInputCostHead,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        C. Final + C. Compra / Lote:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .finalCostInputCostLot,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo @ Colocada:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume.costAtGain,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo @ Produzida:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.resume
                              .costAtProduce,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaper>

              {/* <StyledPaper>
                <Header>
                  <h3>Alimentos</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm />
                  <StyledTableContainer>
                    <MdSubdirectoryArrowRight size={20} />
                    <Table stickyHeader>
                      <StyledTableHead>
                        <TableRow>
                          <TableCell>Alimento</TableCell>
                          <TableCell align="right">Qt(kgMO)</TableCell>
                          <TableCell align="right">Preço médio/ton</TableCell>
                          <TableCell align="right">Custo total</TableCell>
                        </TableRow>
                      </StyledTableHead>
                      <TableBody>
                        {economicClosing.foods &&
                          economicClosing.foods.map((item, index) => (
                            <TableRow key={index.key}>
                              <TableCell>
                                {item.feeds.length > 0 && (
                                  <>
                                    {openFoodDetails ? (
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() =>
                                          setOpenFoodDetails(false)
                                        }
                                        size="small"
                                        color="primary"
                                      >
                                        <ArrowUpwardIcon fontSize="inherit" />
                                      </IconButton>
                                    ) : (
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() => setOpenFoodDetails(true)}
                                        size="small"
                                        color="primary"
                                      >
                                        <ArrowDownwardIcon fontSize="inherit" />
                                      </IconButton>
                                    )}
                                  </>
                                )}
                                {item.food}
                                {item.feeds.length > 0 && (
                                  <>
                                    {openFoodDetails && (
                                      <FoodDetails feeds={item.feeds} />
                                    )}
                                  </>
                                )}
                              </TableCell>
                              <TableCell align="right">
                                {item.totalMo.toFixed(2)}
                              </TableCell>
                              <TableCell align="right">
                                {`R$${item.priceTon.toFixed(2)}`}
                              </TableCell>
                              <TableCell align="right">
                                {item.totalPrice.toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                      <StyledTableHead>
                        <TableRow>
                          <TableCell>Total</TableCell>

                          <TableCell align="right">
                            {economicClosing.foods &&
                              economicClosing.foods
                                .reduce((totalMo, value) => {
                                  return totalMo + value.totalMo;
                                }, 0)
                                .toFixed(2)}
                          </TableCell>
                          <TableCell align="right" />
                          <TableCell align="right">
                            {economicClosing.foods &&
                              economicClosing.foods
                                .reduce((totalPrice, value) => {
                                  return totalPrice + value.totalPrice;
                                }, 0)
                                .toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                          </TableCell>
                        </TableRow>
                      </StyledTableHead>
                    </Table>
                  </StyledTableContainer>
                </Grid>
              </StyledPaper> */}

              <StyledPaper>
                <Header>
                  <h3>Custos alimentares</h3>
                </Header>
                <Grid container>
                  <Grid item xs={8} sm>
                    <SubHeader>
                      <MdSubdirectoryArrowRight size={20} />
                      <h4>Gráfico</h4>
                    </SubHeader>
                    <SubItem>
                      <Grid item xs={2} sm>
                        {economicClosing.foods && totalPriceFoods && (
                          <ContentChart>
                            <Chart
                              type="pie"
                              options={{
                                ...optionsChartPie,
                                labels: economicClosing.foods.map(
                                  item => item.food
                                ),
                              }}
                              series={economicClosing.foods.map(
                                item => item.totalPriceDays / totalPriceFoods
                              )}
                            />
                          </ContentChart>
                        )}
                      </Grid>
                    </SubItem>
                  </Grid>
                </Grid>
              </StyledPaper>

              <Grid container spacing={2} style={{ marginTop: 20 }}>
                <Grid item xs sm={4}>
                  <StyledPaper style={{ minHeight: 233 }}>
                    <Header>
                      <h3>Balanço</h3>
                    </Header>
                    <Grid container>
                      <Grid item xs sm>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Balanço / Cabeça:
                          </Typography>
                          <strong>
                            {' '}
                            R$
                            {economicClosing.financialAnalysis &&
                              formatNumber(
                                economicClosing.financialAnalysis.balance
                                  .balanceAnimal,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Balanço / Lote:
                          </Typography>
                          <strong>
                            {' '}
                            R$
                            {economicClosing.financialAnalysis &&
                              formatNumber(
                                economicClosing.financialAnalysis.balance
                                  .balanceLot,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Lucratividade Total (%):
                          </Typography>
                          <strong>
                            {economicClosing.financialAnalysis &&
                              formatNumber(
                                economicClosing.financialAnalysis.balance
                                  .profitabilityTotal,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>

                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Lucratividade Mensal (%):
                          </Typography>
                          <strong>
                            {economicClosing.financialAnalysis &&
                              formatNumber(
                                economicClosing.financialAnalysis.balance
                                  .profitabilityMonth,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Rentabilidade Total (%):
                          </Typography>
                          <strong>
                            {economicClosing.financialAnalysis &&
                              formatNumber(
                                economicClosing.financialAnalysis.balance
                                  .rentabilityTotal,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                        <Grid container alignItems="center">
                          <Typography variant="body2" component="h5">
                            Rentabilidade Mensal (%):
                          </Typography>
                          <strong>
                            {economicClosing.financialAnalysis &&
                              formatNumber(
                                economicClosing.financialAnalysis.balance
                                  .rentabilityMonth,
                                2,
                                2
                              )}
                          </strong>
                        </Grid>
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Grid>
                <Grid item xs sm={8}>
                  <StyledPaper style={{ minHeight: 233 }}>
                    <Header>
                      <h3>Grafico de Custos</h3>
                    </Header>
                    <Grid container>
                      <Grid item xs sm>
                        {economicClosing.graphicCost && (
                          <ContentChart>
                            <Chart
                              type="pie"
                              options={{
                                ...optionsChartPie,
                                labels: economicClosing.graphicCost.labels,
                              }}
                              series={economicClosing.graphicCost.series}
                            />
                          </ContentChart>
                        )}
                      </Grid>
                    </Grid>
                  </StyledPaper>
                </Grid>
              </Grid>
            </>
          )}

          <ContentPrint>
            <Print ref={PrintScreen} economicClosing={economicClosing} />
          </ContentPrint>
        </Container>
      </center>
    </>
  );
}

/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  TableCell
} from '@material-ui/core';
import { format } from 'date-fns';
import Lodash from 'lodash';

import {
  Container,
  StyledTableCell,
  Header,
  Column,
  StyledTableCellNoBorderBottom,
  ContentTable,
  StyledTableRow
} from './styles';

import logo from '~/assets/images/logo_black.png';
import { formatNamePaddock } from '~/utils/format';

export default class PrintGrouped extends Component {
  render() {
    const {
      diet,
      date,
      treaties,
      settings,
      treatments
    } = this.props;

    const groupedTreatments = Lodash.chain(treatments)
      .groupBy('diet.secure_id')
      .map(dietTreatment => {
        let allTreaties = []

        dietTreatment.forEach(treaty => {
          treaty.treatyTreatments.forEach(t => {
            if (t.selected) {
              allTreaties.push(t)
            }
          })
        })

        const groupTreaties = allTreaties.reduce(
          (accumulator, { treaty, forecast, performed }) => {
            accumulator[treaty.secure_id] = {
              name: accumulator[treaty.secure_id] ? accumulator[treaty.secure_id].name : treaty.name,
              forecast:
                (accumulator[treaty.secure_id] ? accumulator[treaty.secure_id].forecast : 0) + +forecast,
              performed:
                (accumulator[treaty.secure_id] ? accumulator[treaty.secure_id].performed : 0) + +performed,
            };

            return accumulator;
          },
          {}
        );

        const totalsTreaties = Object.keys(groupTreaties).map(key => ({
          secure_id: key,
          name: groupTreaties[key].name,
          totalForecast: groupTreaties[key].forecast,
          totalPerformed: groupTreaties[key].performed,
        }));

        const totals_forecast = totalsTreaties.reduce((acc, { totalForecast, totalPerformed }) => {
          const valueToSum = totalPerformed ? totalPerformed : totalForecast;
          return acc + valueToSum
        }, 0);

        return { name: dietTreatment[0].diet.name, dietTreatment, totalsTreaties, totalsForecast: totals_forecast }
      })
      .value()

    return (
      <Container>
        <Header>
          <img src={logo} alt="logo" />
          <Typography variant="h5">Ficha de Tratos</Typography>
          <Column>
            <Typography variant="subtitle2">{`Dieta: ${diet ? diet.name : 'Todas'
              }`}</Typography>
            <Typography variant="body2">{`Data: ${String(
              format(date, "dd/MM/yyyy 'às' HH:mm'h'")
            )}`}</Typography>
          </Column>
        </Header>

        {groupedTreatments.map(diets => (
          <ContentTable key={diets.name}>
            <TableRow>
              <TableCell colSpan={4}>
                <b>
                  {diets.name}
                </b>
              </TableCell>
              <TableCell colSpan={24} />
            </TableRow>
            <TableContainer>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Piquete</StyledTableCell>
                    {treaties.map(treaty => (
                      <>
                        {treaty.selected ? (
                          <>
                            <StyledTableCell key={treaty.secure_id} align="right">
                              {treaty.name}
                            </StyledTableCell>
                            <StyledTableCell align="right" empty />
                          </>
                        ) : null}
                      </>
                    ))}
                    <StyledTableCell align="right">Total Previsto</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {diets.dietTreatment.map(treatment => (
                    <StyledTableRow key={treatment.secure_id}>
                      <StyledTableCell component="th" scope="row">
                        {formatNamePaddock(settings, treatment.paddock)}
                      </StyledTableCell>
                      {treatment.treatyTreatments.map(treaty => (
                        <>
                          {treaty.selected ? (
                            <>
                              <StyledTableCell key={treaty.treaty.secure_id} align="right">
                                {treaty.forecast}
                              </StyledTableCell>
                              <StyledTableCell align="right" empty>
                                {treaty.performed}
                              </StyledTableCell>
                            </>
                          ) : null}
                        </>
                      ))}
                      <StyledTableCell align="right">
                        {treatment.total_forecast}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}

                  <TableRow>
                    <StyledTableCellNoBorderBottom />
                    {diets.totalsTreaties.map(treaty => (
                      <>
                        <StyledTableCell key={treaty.secure_id} align="right">
                          {treaty.totalForecast}
                        </StyledTableCell>
                        <StyledTableCell align="right" empty>
                          {treaty.totalPerformed || ''}
                        </StyledTableCell>
                      </>
                    ))}
                    <StyledTableCell align="right" empty>
                      {!!diets.totalsForecast ? diets.totalsForecast : ''}
                    </StyledTableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </ContentTable>
        ))}

      </Container>
    );
  }
}

PrintGrouped.propTypes = {
  diet: PropTypes.shape({
    secure_id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  date: PropTypes.string.isRequired,
  treatments: PropTypes.arrayOf(
    PropTypes.shape({
      secure_id: PropTypes.number,
      total_forecast: PropTypes.number,
      date: PropTypes.string,
      module: PropTypes.shape({
        name: PropTypes.string,
      }),
      line: PropTypes.shape({
        name: PropTypes.string,
      }),
      paddock: PropTypes.shape({
        name: PropTypes.string,
      }),
      diet: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ).isRequired,
  treaties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  settings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalsForecast: PropTypes.number.isRequired,
};

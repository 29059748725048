import React from 'react';

import {
  TableCell,
  TableRow
} from '@material-ui/core';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';
import { formatNamePaddock } from '~/utils/format';
import { format } from 'date-fns';

export function CardTableAnimalIndividual({ correction, settings }) {
  return (
    <TableRow>
      <StyledTableCell align="center">
        {`${formatNamePaddock(
          settings,
          correction.animal.lot?.paddock
        )}`}
      </StyledTableCell>
      <StyledTableCell align="center">
        {correction.animal.lot?.code}
      </StyledTableCell>
      <StyledTableCell align="center">
        {correction.old_average_weight}kg
      </StyledTableCell>
      <StyledTableCell align="center">
        {correction.new_average_weight}kg
      </StyledTableCell>
      <StyledTableCellEllipsis component="th" scope="row">
        {correction.obs || ''}
      </StyledTableCellEllipsis>
      <StyledTableCell component="th" scope="row">
        {correction.type === 'inputWeight'
          ? 'Correção peso de entrada'
          : 'Peso inter.'}
      </StyledTableCell>
      <TableCell align="center">
        {correction.date
          ? format(new Date(correction.date), 'dd/MM/yyyy')
          : ''}
      </TableCell>
      <TableCell align="center">
        {correction.created_at
          ? format(new Date(correction.created_at), 'dd/MM/yyyy')
          : ''}
      </TableCell>
    </TableRow>
  )
}

import { useQuery } from "react-query";
import apiV2 from "~/services/apiV2";

export async function getAnimalOutput(secure_id) {
  const { data } = await apiV2.get(`/v1/feedlot/animal-handling/individualAnimalOutput/${secure_id}`);

  return data
}

export function useApiV2SelectIndividualAnimalOutput(secure_id) {
  return useQuery(['individualAnimalOutput', secure_id], () => getAnimalOutput(secure_id));
}

import styled from 'styled-components';
import { darken } from 'polished';
import {
  Paper,
  Grid,
  Button,
  IconButton,
  RadioGroup,
  TableContainer,
  TableHead,
  TextField,
  TableCell,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 1250px;
  width: 100%;
  padding: 40px 40px 20px;

  text-align: left;
`;

export const Content = styled.div`
  width: 100%;
`;

export const ContentOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentAnimals = styled.div`
  width: 100%;
  display: flex;

  @media(max-width: 940px) {
    flex-direction: column;
  }
`;

export const StyledTextField = styled(TextField)`
  margin: 5px 0px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 16px;
  box-shadow: none;
`;

export const StyledGrid = styled(Grid)`
  margin-bottom: ${props => props.marginBottom || 16}px;
  margin-top: ${props => props.marginTop || 0}px;
`;

export const StyledGridAndButton = styled(Grid)`
  height: 100%;
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: 5px;
  color: ${colors.white};
  background: ${colors.success};
  transition: background 1s;

  &:hover {
    background: ${darken(0.06, colors.success)};
  }
`;

export const StyledRadioGroup = styled(RadioGroup)`
  display: flex;
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

export const Error = styled.span`
  margin: 8px 14px 0;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

export const StyledButton = styled(Button)`
  color: ${colors.white};
  margin: 6px 0 0 16px;
  transition: background 2s;

  &:hover {
    background: ${darken(0.06, colors.success)};
  }
`;

export const ButtonNextAnimalContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

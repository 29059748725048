import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";

export async function getData(secure_id) {
  const { data } = await apiV2.get(`/v1/feedlot/animal-handling/individualAnimalTransfer/${secure_id}`);

  return data
}

export function useApiV2SelectIndividualAnimalTransfer(secure_id) {
  return useQuery(['animalTransfersIndividual', secure_id],
    () => getData(secure_id),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    });
}

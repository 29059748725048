import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2"

export async function getTroughSetting(secure_id) {
  if (!secure_id) {
    return null
  }

  const { data } = await apiV2.get(`/v1/feedlot/register/troughSetting/${secure_id}`)

  return {
    currentTroughSetting: data
  }
}

export function useApiV2SelectTroughSetting(secure_id) {
  return useQuery(['troughSettings', secure_id], () => getTroughSetting(secure_id))
}

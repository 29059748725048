import {
  Grid,
  Table, TableBody, TableCell, TablePagination, TableRow, TableSortLabel
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import React, { useState } from 'react';
import Can from '~/components/Can';
import Loader from '~/components/Loader';
import SplitButton from '~/components/SplitButton';
import { useV2ManufactoringDiet } from '~/hooks/apiV2/foodManagement/manufactoringDiet/useV2ManufactoringDiet';
import history from '~/services/history';
import { FormatDateForYearMonthDay } from '~/utils/format';
import { CardTableManufactoringDiet } from './Components/CardTableManufactoringDiet';
import Filter from './Components/Filter';
import { ModalManufactoringDietPrint } from './Components/ModalPrint';
import {
  ButtonPrint, Container, StyledGrid,
  StyledGridLoader,
  StyledPaper, StyledTableCell, StyledTableContainer,
  StyledTableHead
} from './styles';

export default function ManufactoringDiet() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('date');
  const [direction, setDirection] = useState('desc');
  const [currentDiet, setCurrentDiet] = useState('all');
  const [currentDate, setCurrentDate] = useState(null);

  const { data, isLoading } = useV2ManufactoringDiet(page, rowsPerPage, direction, field, currentDiet, !!currentDate ? FormatDateForYearMonthDay(currentDate) : null)

  const [visibleModalPrint, setVisibleModalPrint] = useState(false);

  function handleModalPrint() {
    setVisibleModalPrint(!visibleModalPrint)
  }

  function sumForecast() {
    if (data) {
      return Math.round(
        Number(
          data.data.reduce((total, mdiet) => total + Number(mdiet.total_forecast), 0)
        )
      );
    }
    return 0;
  }

  function sumPerformed() {
    if (data) {
      return Math.round(
        Number(
          data.data.reduce(
            (total, mdiet) => total + Number(mdiet.total_performed),
            0
          )
        )
      );
    }
    return 0;
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleAdd() {
    history.push('/dashboard/manufactoringDiet/add');
  }

  function handleAddPerDay() {
    history.push('/dashboard/manufactoringDiet/addPerDay');
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" alignItems="flex-start" spacing={2}>
          <Grid item xs={12} sm md lg xl>
            <Filter
              totalForecast={sumForecast() || 0}
              totalPerformed={sumPerformed() || 0}
              currentDiet={currentDiet}
              currentDate={currentDate}
              setCurrentDiet={setCurrentDiet}
              setCurrentDate={setCurrentDate}
            />
          </Grid>
          <ButtonPrint
            color="primary"
            variant="contained"
            startIcon={<PrintIcon />}
            disabled={!currentDate || (!!data && data.data.length === 0)}
            onClick={handleModalPrint}
          >
            Ficha
          </ButtonPrint>
          <Grid item xs="auto" sm="auto">
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['manufactoringDiet_new']}
            >
              <SplitButton
                options={[
                  { title: 'Nova dieta', onClick: handleAdd, value: 'individual' },
                  { title: 'Nova dieta p/ dia', onClick: handleAddPerDay, value: 'per_day' }
                ]}
                defaultValue={'individual'}
              />
            </Can>

          </Grid>
        </StyledGrid>
        {isLoading && !visibleModalPrint ? (
          <StyledGridLoader container justify="center" alignItems="center">
            <Loader />
          </StyledGridLoader>
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'date'}
                        direction={field === 'date' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('date')}
                      >
                        Data
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'diet.name'}
                        direction={field === 'diet.name' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('diet.name')}
                      >
                        Dieta
                      </TableSortLabel>
                    </TableCell>
                    <StyledTableCell align="right">
                      <TableSortLabel
                        active={field === 'qtd_forecast'}
                        direction={field === 'qtd_forecast' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('qtd_forecast')}
                      >
                        Previsto (Kg)
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <TableSortLabel
                        active={field === 'qtd_performed'}
                        direction={
                          field === 'qtd_performed' ? direction : 'asc'
                        }
                        onClick={() => handleChangeOrder('qtd_performed')}
                      >
                        Realizado (Kg)
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <TableSortLabel
                        active={field === 'percent_ms_diet'}
                        direction={
                          field === 'percent_ms_diet' ? direction : 'asc'
                        }
                        onClick={() => handleChangeOrder('percent_ms_diet')}
                      >
                        MS (%)
                      </TableSortLabel>
                    </StyledTableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={field === 'price_ton'}
                        direction={field === 'price_ton' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('price_ton')}
                      >
                        Custo/Ton
                      </TableSortLabel>
                    </TableCell>
                    <Can checkRole={['administrator', 'owner', 'manager']}>
                      <TableCell align="right" />
                    </Can>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {!!data && data.data.map(manufactoring => (
                    <CardTableManufactoringDiet
                      key={manufactoring.secure_id}
                      manufactoring={manufactoring}
                    />
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data && data.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}

        {visibleModalPrint && (
          <ModalManufactoringDietPrint
            visibleModal={visibleModalPrint}
            handleModal={handleModalPrint}
            currentDate={currentDate}
          />
        )}
      </Container>
    </center>
  );
}

import { useMutation } from 'react-query';
import { toast } from 'react-toastify'

import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2EditManufacturingFeed(secure_id) {
  return useMutation(
    async (data) => {
      const response = await apiV2.put(`/v1/feedlot/food-management/manufacturingFeed/${secure_id}`, data);

      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();

        toast.success('A fabricação da Mistura/Ração foi editada com sucesso!');
        history.push('/dashboard/manufactoringFeed');
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Não foi possível editar a fabricação!'
        toast[type](message)
      }
    }
  );
}

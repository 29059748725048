import { TableCell, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import ActionLoader from '~/components/ActionLoader';
import ButtonTable from '~/components/ButtonTable';
import Can from '~/components/Can';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';
import colors from '~/styles/colors';
import { formatNumber } from '~/utils/format';
import {
  StyledTableCell,
  StyledTableCellEllipsis
} from './styles';

export function CardOperationalCost({ operationalCost }) {
  function handleSelectOperationalCost() {
    history.push(`/dashboard/operationalCost/edit/${operationalCost.secure_id}`);
  }

  const deleteOperationalCost = useMutation(async () => {
    return await apiV2.delete(`v1/feedlot/admin/operational-cost/${operationalCost.secure_id}`)
  }, {
    onSuccess: (response) => {
      queryClient.invalidateQueries('operationalCost');
      if (response.status === 204) {
        return toast.error('Falha ao tentar apagar custo operacional.')
      }
      return toast.success(response.data?.message)
    },
    onError: (error) => {
      if (!!error) {
        if (!!error.response.data?.type) {
          if (error.response.data?.type === 'warning') {
            return toast.warning(error.response.data.message)
          }
          return toast.error(error.response.data.message)
        } else {
          return toast.error('Ocorreu um erro ao tentar apagar custo operacional.')
        }
      } else {
        return toast.error('Ocorreu um erro ao tentar apagar custo operacional.')
      }
    }
  })

  async function handleRemoveOperationalCost() {
    try {
      Swal.fire({
        title: 'Você tem certeza ?',
        text: 'Está ação não tem volta! Deseja apagar esse custo operacional?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: colors.lightPrimary,
        cancelButtonColor: colors.grey,
        confirmButtonText: 'Ok, deletar custo operacional!',
      }).then(async result => {
        if (result.value) {
          await deleteOperationalCost.mutateAsync()
        }
      });
    } catch { }
  }

  return (
    <TableRow key={operationalCost.id}>
      <TableCell component="th" scope="row">
        {`${operationalCost.formattedInitialDate}`}
      </TableCell>
      <TableCell align="center">
        {operationalCost.formattedFinalDate}
      </TableCell>
      <StyledTableCell align="right">
        {`R$ ${formatNumber(operationalCost.cost, 2, 2)}`}
      </StyledTableCell>
      <StyledTableCellEllipsis>
        {operationalCost.obs}
      </StyledTableCellEllipsis>
      <StyledTableCell align="right">
        {deleteOperationalCost.isLoading ? (
          <ActionLoader text="Deletando" />
        ) : (
          <>
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['operationalCost_edit']}
            >
              <ButtonTable
                icon={<EditIcon color="primary" />}
                background={colors.primary}
                lighten={0.6}
                onClick={handleSelectOperationalCost}
              />
            </Can>
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['operationalCost_delete']}
            >
              <ButtonTable
                icon={<DeleteIcon color={!operationalCost.final_date ? "error" : "disabled"} />}
                disabled={operationalCost.final_date}
                onClick={handleRemoveOperationalCost}
              />
            </Can>
          </>
        )}
      </StyledTableCell>
    </TableRow>
  )
}
import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {
  format,
  isSameDay,
  parseISO,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Grid, TextField, Divider, Hidden } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

// Query
import { useApiV2ShowDataTransfer } from '~/hooks/apiV2/animalHandling/TransferPaddock/useApiV2ShowDataTransfer';
import { useApiV2AddTransferPaddock } from '~/hooks/apiV2/animalHandling/TransferPaddock/useApiV2AddTransferPaddock';

import {
  Container,
  StyledPaper,
  StyledGrid,
  StyledGridItem,
  StyledTypographyTitle,
  StyledTypography,
  ContentDivider,
  StyledDivider,
  StyledTextField,
  Error,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';

import history from '~/services/history';
import Loader from '~/components/Loader';
import ButtonSubmit from '~/components/ButtonSubmit';
import NumberFormatForm from '~/components/NumberFormatForm';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.value,
});

const schema = Yup.object().shape({
  date: Yup.string().required('Necessário informar uma data'),
  obs: Yup.string().nullable(),
  lot_id: Yup.string().required('Necessário informar um Lote'),
  origin: Yup.string().required('Necessário informar um Piquete de origem'),
  destination: Yup.string().required(
    'Necessário informar um Piquete de destino'
  ),
});

export default function Register() {
  const [listLots, setListLots] = useState([]);
  const [listPaddocks, setListPaddocks] = useState([]);
  const [currentLot, setCurrentLot] = useState(null);
  const [selectedLot, setSelectedLot] = useState(null);
  const [originPaddock, setOriginPaddock] = useState(null);
  const [destinationPaddock, setDestinationPaddock] = useState(null);
  const [selectedPadddock, setSelectedPaddock] = useState(null);
  const [originPaddockAmount, setOriginPaddockAmount] = useState(null);
  const [originLotAmount, setOriginLotAmount] = useState(null);
  const [afterOriginPaddockAmount, setAfterOriginPaddockAmount] = useState(
    null
  );

  const [originPaddockCapacity, setOriginPaddockCapacity] = useState(null);
  const [destPaddockAmount, setDestPaddockAmount] = useState(null);
  const [afterDestPaddockAmount, setAfterDestPaddockAmount] = useState(null);
  const [destPaddockCapacity, setDestPaddockCapacity] = useState(null);
  const [errorOriginLot, setErrorOriginLot] = useState('');
  const [errorDestPaddock, setErrorDestPaddock] = useState('');

  const [lots, setLots] = useState([]);
  const [paddocks, setPaddocks] = useState([]);

  // Query
  const { data: lotsData, isLoading } = useApiV2ShowDataTransfer();
  const addAnimalTransferPaddock = useApiV2AddTransferPaddock();

  useEffect(() => {
    if (lotsData) {
      const newLots = lotsData.opstionsLots.map(lot => ({
        secure_id: lot.secure_id,
        value: lot.name,
      }));

      setListLots(newLots);
      setLots(lotsData.opstionsLots);

      const newPaddocks = lotsData.opstionsPaddocks.map(paddock => ({
        secure_id: paddock.secure_id,
        value: paddock.name,
      }));

      setPaddocks(lotsData.opstionsPaddocks);
      setListPaddocks(newPaddocks);
    }
  }, [lotsData]);

  const CalcTotalAmount = () => {
    const { values } = useFormikContext();

    useEffect(() => {
      if (originPaddock && originPaddock.dateTransfer && !isSameDay(parseISO(originPaddock.dateTransfer), values.date)) {
        setErrorOriginLot(`Troca permitida no dia ${format(parseISO(originPaddock.dateTransfer), 'dd/MM/yyyy')}`);
      } else {
        setErrorOriginLot('');
      }

      if (destinationPaddock && destinationPaddock.dateTransfer && !isSameDay(parseISO(destinationPaddock.dateTransfer), values.date)) {
        setErrorDestPaddock(`Troca permitida no dia ${format(parseISO(destinationPaddock.dateTransfer), 'dd/MM/yyyy')}`);
      } else {
        setErrorDestPaddock('');
      }
    }, [values.date]);

    useEffect(() => {
      if (values.origin) {
        const newOriginPaddock = paddocks.find(
          newPaddock => newPaddock.secure_id === values.origin
        );

        setOriginLotAmount(currentLot.total_animals_lot);
        setOriginPaddock(newOriginPaddock);
        setOriginPaddockAmount(newOriginPaddock.total_animals_paddock);
        setOriginPaddockCapacity(newOriginPaddock.amount_support);
        setAfterOriginPaddockAmount(
          newOriginPaddock.total_animals_paddock - currentLot.total_animals_lot
        );
      } else {
        setOriginPaddock(null);
        setOriginPaddockAmount(null);
        setOriginPaddockCapacity(null);
        setAfterOriginPaddockAmount(null);
        setCurrentLot(null);
        setSelectedLot(null);
      }

      if (values.destination) {
        const newDestPaddock = paddocks.find(
          newPaddock => newPaddock.secure_id === values.destination
        );

        setDestinationPaddock(newDestPaddock);
        setDestPaddockAmount(newDestPaddock.total_animals_paddock);
        setDestPaddockCapacity(newDestPaddock.amount_support);
        setAfterDestPaddockAmount(
          newDestPaddock.total_animals_paddock + currentLot.total_animals_lot
        );
      } else {
        setDestinationPaddock(null);
        setDestPaddockAmount(null);
        setDestPaddockCapacity(null);
        setAfterDestPaddockAmount(null);
      }
    }, [values.origin, values.destination]);
    return null;
  };

  function handleSelectLot(value, setFieldValue) {
    if (value) {
      const newCurrentlot = lots.find(newLot => newLot.secure_id === value.secure_id);

      const newListPaddocks = listPaddocks.filter(
        paddock => paddock.secure_id !== newCurrentlot.paddock.secure_id
      );

      setSelectedLot(value);
      setCurrentLot(newCurrentlot);
      setListPaddocks(newListPaddocks);

      setFieldValue('origin', newCurrentlot.paddock.secure_id);
      setFieldValue('lot_id', value.secure_id);
    } else {
      const newPaddocks = paddocks.map(paddock => ({
        secure_id: paddock.secure_id,
        value: paddock.name,
      }));

      setCurrentLot(null);
      setOriginPaddock(null);
      setListPaddocks(newPaddocks);
      setErrorOriginLot('');

      setFieldValue('origin', '');
      setFieldValue('lot_id', '');
    }
  }

  function handleSelectPaddock(value, setFieldValue) {
    if (value) {
      const paddock = paddocks.find(newPaddock => newPaddock.secure_id === value.secure_id);

      setSelectedPaddock(value);
      setDestPaddockAmount(paddock.total_animals_paddock);
      setDestinationPaddock(paddock);

      setFieldValue('destination', value.secure_id);
    } else {
      setSelectedPaddock(null);
      setDestinationPaddock(null);
      setDestPaddockAmount(null);
      setErrorDestPaddock('');

      setFieldValue('destination', '');
    }
  }

  async function handleSubmit(data) {
    if (errorOriginLot || errorDestPaddock) return;

    const newData = {
      date: new Date(data.date),
      lotSecureId: data.lot_id,
      originPaddockSecureId: data.origin,
      destinPaddockSecureId: data.destination,
    };

    await addAnimalTransferPaddock.mutateAsync(newData);
  }

  function handleBack() {
    history.push('/dashboard/animalTransferPaddock');
  }

  return (
    <center>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <Formik
              validationSchema={schema}
              initialValues={{
                date: new Date(),
                origin: '',
                destination: '',
                obs: '',
                lot_id: '',
              }}
              onSubmit={handleSubmit}
            >
              {({ handleChange, setFieldValue, errors, touched, values, isSubmitting }) => (
                <Form>
                  <Grid container justify="space-between" spacing={2}>
                    <Grid item xs sm>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={ptBR}
                      >
                        <KeyboardDatePicker
                          name="date"
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          autoComplete="nope"
                          size="small"
                          label="Data"
                          fullWidth
                          required
                          maxDate={new Date()}
                          inputVariant="outlined"
                          value={values.date}
                          onChange={date => setFieldValue('date', date)}
                          autoOk
                          error={!!errors.date && touched.date}
                        />
                        <ErrorMessage name="date" component={Error} />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Hidden xsDown>
                      <Grid item sm />
                      <Grid item sm />
                    </Hidden>
                  </Grid>

                  <StyledGrid container justify="space-between" spacing={2}>
                    <Grid item sm xs={12}>
                      <Autocomplete
                        name="lot_id"
                        size="small"
                        noOptionsText="Sem opções"
                        filterOptions={filterOptions}
                        options={listLots}
                        getOptionLabel={option => option.value}
                        value={selectedLot}
                        onChange={(event, value) =>
                          handleSelectLot(value, setFieldValue)
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Lote"
                            required
                            fullWidth
                            variant="outlined"
                            error={
                              (!!errors.lot_id && touched.lot_id) ||
                              errorOriginLot
                            }
                          />
                        )}
                      />
                      {errorOriginLot ? (
                        <Error>
                          <span>{errorOriginLot}</span>
                        </Error>
                      ) : (
                        <ErrorMessage name="lot_id" component={Error} />
                      )}
                    </Grid>
                    <Grid item sm xs={12}>
                      <Autocomplete
                        name="destination_paddock_id"
                        size="small"
                        noOptionsText="Sem opções"
                        filterOptions={filterOptions}
                        options={listPaddocks}
                        getOptionLabel={option => option.value}
                        value={selectedPadddock}
                        onChange={(event, value) =>
                          handleSelectPaddock(value, setFieldValue)
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Piquete de Destino"
                            required
                            fullWidth
                            variant="outlined"
                            error={
                              (!!errors.destination_paddock_id &&
                                touched.destination_paddock_id) ||
                              errorDestPaddock
                            }
                          />
                        )}
                      />
                      {errorDestPaddock ? (
                        <Error>
                          <span>{errorDestPaddock}</span>
                        </Error>
                      ) : (
                        <ErrorMessage
                          name="destination_paddock_id"
                          component={Error}
                        />
                      )}
                    </Grid>
                  </StyledGrid>

                  <Divider light />

                  <StyledTypographyTitle color="primary" component="h3">
                    Lote
                  </StyledTypographyTitle>
                  <StyledGrid container justify="space-between" spacing={2}>
                    <Grid item xs={12} sm>
                      <StyledTypography color="secondary">
                        Antes
                      </StyledTypography>
                      <TextField
                        label="Piquete de origem"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: originPaddock,
                        }}
                        value={
                          originPaddock
                            ? originPaddock.name
                            : ''
                        }
                      />
                    </Grid>

                    <Hidden only="xs">
                      <StyledGridItem item xs={12} sm>
                        <NumberFormatForm
                          label="Qtd. Animais no Piquete"
                          disabled
                          value={originPaddockAmount}
                        />
                      </StyledGridItem>
                      <ContentDivider>
                        <StyledDivider light orientation="vertical" flexItem />
                      </ContentDivider>
                    </Hidden>

                    <Hidden smUp>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          label="Qtd. Animais no Piquete"
                          disabled
                          value={originPaddockAmount}
                        />
                      </Grid>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          label="Qtd. Animais no Lote"
                          disabled
                          value={currentLot && currentLot.amount_animals}
                        />
                      </Grid>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          label="Capacidade"
                          disabled
                          value={originPaddockCapacity}
                        />
                      </Grid>
                    </Hidden>

                    <Grid item xs={12} sm>
                      <StyledTypography color="secondary">
                        Depois
                      </StyledTypography>
                      <NumberFormatForm
                        label="Qtd. Animais no Piquete"
                        disabled
                        value={afterOriginPaddockAmount}
                      />
                    </Grid>
                  </StyledGrid>
                  <StyledGrid container justify="space-between" spacing={2}>
                    <Hidden only="xs">
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          label="Qtd. Animais no Lote"
                          disabled
                          value={originLotAmount}
                        />
                      </Grid>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          label="Capacidade"
                          disabled
                          value={originPaddockCapacity}
                        />
                      </Grid>
                      <ContentDivider>
                        <StyledDivider light orientation="vertical" flexItem />
                      </ContentDivider>
                    </Hidden>

                    <Grid item xs={12} sm>
                      <NumberFormatForm
                        label="Qtd. Animais no Lote"
                        disabled
                        value={originLotAmount}
                      />
                    </Grid>
                  </StyledGrid>

                  <StyledTypographyTitle color="primary" component="h3">
                    Piquete
                  </StyledTypographyTitle>
                  <StyledGrid container justify="space-between" spacing={2}>
                    <Grid item xs={12} sm>
                      <StyledTypography color="secondary">
                        Antes
                      </StyledTypography>
                      <NumberFormatForm
                        label="Qtd. Animais no Piquete"
                        disabled
                        value={destPaddockAmount}
                      />
                    </Grid>

                    <Hidden only="xs">
                      <StyledGridItem item xs={12} sm>
                        <NumberFormatForm
                          label="Capacidade"
                          disabled
                          value={destPaddockCapacity}
                        />
                      </StyledGridItem>
                      <ContentDivider>
                        <StyledDivider light orientation="vertical" flexItem />
                      </ContentDivider>
                    </Hidden>

                    <Hidden smUp>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          label="Capacidade"
                          disabled
                          value={destPaddockCapacity}
                        />
                      </Grid>
                    </Hidden>

                    <Grid item xs={12} sm>
                      <StyledTypography color="secondary">
                        Depois
                      </StyledTypography>
                      <NumberFormatForm
                        label="Qtd. Animais no Piquete"
                        variant="outlined"
                        disabled
                        value={afterDestPaddockAmount}
                      />
                    </Grid>
                  </StyledGrid>

                  <Divider light />

                  <StyledGrid container marginTop={12}>
                    <Grid item xs={12} sm>
                      <StyledTextField
                        name="obs"
                        variant="outlined"
                        label="Observação"
                        autoComplete="nope"
                        fullWidth
                        size="small"
                        onChange={handleChange}
                      />
                    </Grid>
                  </StyledGrid>

                  <ContentSubmitButtons>
                    <ButtonSubmit title="Cadastrar" loading={isSubmitting} />
                    <StyledSubmitButton
                      variant="outlined"
                      color="primary"
                      onClick={handleBack}
                    >
                      Cancelar
                    </StyledSubmitButton>
                  </ContentSubmitButtons>

                  <CalcTotalAmount />
                </Form>
              )}
            </Formik>
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

Register.defaultProps = {
  match: null,
};

Register.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

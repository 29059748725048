import styled from 'styled-components';
import { Paper, Grid, IconButton } from '@material-ui/core';

export const Content = styled.div`
  max-width: 800px;
`;

export const StyledTablePaper = styled(Paper)`
  width: 100%;
  padding: 16px;
`;

export const ContentDate = styled.div`
  min-width: 144px;
`;

export const StyledGridItem = styled(Grid)`
  display: flex;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

import styled from 'styled-components';
import { Button, Paper, TextField } from '@material-ui/core';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.div`
  flex: 1;
  height: 100%;
  padding: 0px 40px 0 40px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  height: 100%;
  padding: 22px;
`;

export const StyledTextField = styled(TextField)`
  margin: 5px 0px;
`;

export const TableItem = styled.div`
  height: 400px;
  width: 100%;
`;

export const StyledButtonSave = styled(Button)`
  color: ${colors.white};
  margin-left: 10px;
  transition: background 2s;

  &:hover {
    background: ${darken(0.06, colors.success)};
  }
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 30px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

import DateFnsUtils from '@date-io/date-fns';
import { Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import PropTypes from 'prop-types';
import React from 'react';
import DatePicker from '~/components/DatePicker';
import InputDatePicker from '~/components/InputDatePicker';
import {
  ContentDate, StyledGrid, StyledIconButton, StyledPaper, StyledTextField
} from './styles';

export default function Filter({
  totalForecast,
  totalPerformed,
  isRegister,
  currentDate,
  setCurrentDate,
}) {
  function calcDeviation() {
    const totalDeviation = parseFloat(
      (totalPerformed / totalForecast - 1) * 100
    );

    if (totalDeviation) {
      return totalDeviation.toFixed(2);
    }
    return parseFloat(0).toFixed(2);
  }

  function handleChangeDate(date) {
    setCurrentDate(date);
  }

  return (
    <StyledPaper>
      <StyledGrid container justify="space-around" spacing={2}>
        <Grid item sm xs={12}>
          <ContentDate>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {isRegister ? (
                <DatePicker
                  style={{ marginTop: 0 }}
                  maxDate={new Date()}
                  selectedDate={currentDate}
                  handleChangeDate={(date, value) =>
                    handleChangeDate(date, value)
                  }
                />
              ) : (
                <InputDatePicker
                  maxDate={new Date()}
                  selectedDate={currentDate}
                  handleChangeDate={(date, value) =>
                    handleChangeDate(date, value)
                  }
                  InputProps={{
                    startAdornment: (
                      <StyledIconButton
                        onClick={() => handleChangeDate(null, null)}
                      >
                        <ClearIcon />
                      </StyledIconButton>
                    ),
                  }}
                  InputAdornmentProps={{
                    position: 'end',
                  }}
                />
              )}
            </MuiPickersUtilsProvider>
          </ContentDate>
        </Grid>

        <Grid container justify="space-around" alignItems="center" spacing={2}>
          <Grid item sm xs={12}>
            <StyledTextField
              id="total_forecast"
              label="Total Previsto (Kg)"
              variant="outlined"
              size="small"
              fullWidth
              disabled={true}
              type="number"
              value={totalForecast}
            />
          </Grid>
          <Grid item sm xs={12}>
            <StyledTextField
              id="total_performed"
              label="Total Realizado (Kg)"
              variant="outlined"
              size="small"
              fullWidth
              disabled={true}
              type="number"
              value={totalPerformed}
            />
          </Grid>
          <Grid item sm xs={12}>
            <StyledTextField
              id="total_deviation"
              label="Desvio (%)"
              variant="outlined"
              size="small"
              fullWidth
              disabled={true}
              type="number"
              value={calcDeviation()}
            />
          </Grid>
        </Grid>
      </StyledGrid>
    </StyledPaper>
  );
}

Filter.defaultProps = {
  isRegister: false,
  currentDate: '',
  setCurrentDate: () => { },
};

Filter.propTypes = {
  totalForecast: PropTypes.number.isRequired,
  totalPerformed: PropTypes.number.isRequired,
  isRegister: PropTypes.bool,
  currentDate: PropTypes.string,
  setCurrentDate: PropTypes.func,
};

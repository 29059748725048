import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

import { TextFieldNumber } from './styles';

export default function NumberFormatForm({ name, setFieldValue, ...props }) {
  return (
    <NumberFormat
      id={name}
      customInput={TextFieldNumber}
      min="0.00"
      autoComplete="off"
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      variant="outlined"
      size="small"
      fullWidth
      isAllowed={val => {
        const { formattedValue, floatValue } = val;
        return (
          formattedValue === '' ||
          formattedValue === '-' ||
          (floatValue <= 99999999999.9999 && floatValue >= -9999999999.9999)
        );
      }}
      onValueChange={values => {
        setFieldValue(name, values.value !== '' ? values.floatValue : '');
      }}
      {...props}
    />
  );
}

NumberFormatForm.defaultProps = {
  setFieldValue: () => {},
};

NumberFormatForm.propTypes = {
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func,
};

import { useAuthContext } from "~/contexts/AuthContext"
import { userHasPermission } from "~/utils/userHasPermission"

export function useCan({ permissions, roles }) {
  const { user } = useAuthContext()

  if (!user.isAuthenticated) {
    return false
  }

  const userHasValidPermissions = userHasPermission({
    user,
    permissions,
    roles
  })

  return userHasValidPermissions
}

import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import apiV2 from '~/services/apiV2';
import { queryClient } from '~/services/queryClient';

export function useApiV2AlterTreatment(setTreatyTreatments) {
  return useMutation(
    async (dataRequest) => {
      await apiV2.put(`v1/feedlot/food-management/treatment`, dataRequest);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        setTreatyTreatments([]);
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao tentar alterar o tratamento!'
        toast[type](message)
        setTreatyTreatments([]);
      }
    }
  );
}

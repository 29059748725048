import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { addDays } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Query
import { useApiV2GlobalModule } from '~/hooks/apiV2/global/module/useApiV2GlobalModule';

import {
  Content,
  StyledPaper,
  StyledGridItem,
  StyledFormControl,
  ContentDate,
} from './styles';

import DatePicker from '~/components/DatePicker';

const moduleDefaultOption = {
  secure_id: null,
  name: 'Todas',
};

const lineDefaultOption = {
  secure_id: null,
  name: 'Todas',
};

export default function Filter({ setArrayReading, filters, setFilters }) {
  const [currentListLines, setCurrentListLines] = useState([]);

  const [modules, setModules] = useState()

  const currentModule = filters && filters.currentModule;
  const currentLine = filters && filters.currentLine;
  const currentDate = filters && filters.currentDate;

  // Query
  const { data: modulesData } = useApiV2GlobalModule();

  function handleChangeModule(event) {
    setFilters({
      currentModule: event.target.value,
      currentLine: lineDefaultOption,
      currentDate: currentDate
    })
    if (setArrayReading) setArrayReading([]);
  }

  function handleChangeLine(event) {
    setFilters({
      currentModule: currentModule,
      currentLine: event.target.value,
      currentDate: currentDate
    })
    if (setArrayReading) setArrayReading([]);
  }

  function handleChangeDate(date) {
    setFilters({
      currentModule: currentModule,
      currentLine: currentLine,
      currentDate: date
    })
    if (setArrayReading) setArrayReading([]);
  }

  useEffect(() => {
    if (modulesData) {
      setModules(modulesData.modules);
    }
  }, [modulesData]);

  useEffect(() => {
    if (currentModule && currentModule.lines) {
      setCurrentListLines(currentModule.lines);
    }
  }, [currentModule]);

  return (
    <Content>
      <StyledPaper>
        <Grid container alignItems="center" justify="space-between">
          <StyledGridItem item xs={12} sm>
            <StyledFormControl size="small" fullWidth>
              <InputLabel id="input-module">Módulo *</InputLabel>
              <Select
                id="currentModule"
                defaultValue={moduleDefaultOption}
                value={currentModule || moduleDefaultOption}
                onChange={handleChangeModule}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value={moduleDefaultOption}>
                  {moduleDefaultOption.name}
                </MenuItem>
                {modules && modules.map(item => (
                  <MenuItem key={item.secure_id} value={item}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </StyledGridItem>

          <StyledGridItem item xs={12} sm>
            <StyledFormControl size="small" fullWidth disabled={!currentModule}>
              <InputLabel id="input-line">Linha *</InputLabel>
              <Select
                id="currentLine"
                defaultValue={lineDefaultOption}
                value={currentLine || lineDefaultOption}
                onChange={handleChangeLine}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value={lineDefaultOption}>
                  {lineDefaultOption.name}
                </MenuItem>
                {currentListLines.map(line => (
                  <MenuItem key={line.secure_id} value={line}>
                    {line.name}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </StyledGridItem>

          <StyledGridItem item xs={12} sm>
            <ContentDate>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  fullWidth
                  maxDate={addDays(new Date(), 1)}
                  selectedDate={currentDate}
                  handleChangeDate={date => handleChangeDate(date)}
                />
              </MuiPickersUtilsProvider>
            </ContentDate>
          </StyledGridItem>
        </Grid>
      </StyledPaper>
    </Content>
  );
}

Filter.propTypes = {
  setArrayReading: PropTypes.func,
};

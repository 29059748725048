import styled from 'styled-components';
import { Paper, Grid } from '@material-ui/core';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.div`
  position: absolute;
  top: 25%;
  left: 50%;
  bottom: 0;
  right: 0;
  transform: translateX(-50%);
  z-index: 10000;

  max-width: 960px;
  max-height: 100vh;
  margin: 50px 40px;
  border-radius: 5px;
`;

export const StyledPaper = styled(Paper)`
  padding: 20px;
  overflow: auto;
`;

export const StyledGrid = styled(Grid)`
  margin: 8px 0px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -18px;
  right: -16px;
  border: none;
  background: none;

  svg {
    background: ${colors.darkWhite};
    border-radius: 50%;
    overflow: hidden;
    transition: background 2s;

    &:hover {
      background: ${darken(0.1, colors.darkWhite)};
    }
  }
`;

export const ContentPrint = styled.div`
  display: none;
`;

export const ContentButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 18px;
`;

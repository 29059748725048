import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

export async function getAnimalsOutput(sort, page = 1, limit = 50, date = null, originLot = null, breed = null) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/animal-handling/animalOutput?sort=${sort}`, {
      params: { page, limit, date, originLot, breed },
    });

    return {
      animalsOutput: data.data,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    }
  } catch {
    toast.error('Não foi possível buscar saída de animais!');
  }
}

export function useApiV2AnimalsOutput(sort, page, limit, date, originLot, breed) {
  return useQuery(['animalOutput', sort, page, limit, date, originLot, breed],
    () => getAnimalsOutput(sort, page, limit, date, originLot, breed),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    });
}

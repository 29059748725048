import React, { useState } from 'react';
import { Grid, Hidden, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

// Query

import { getReportEconomicClosingV2 } from '~/hooks/apiV2/Report/economicClosing/getReportEconomicClosingV2';
import {
  Container, StyledButton, StyledButtonGenerator, StyledPaper
} from './styles';
import { useApiV2ShowLotEconomicClosing } from '~/hooks/apiV2/Report/economicClosing/useApiV2ShowLotEconomicClosing';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.value,
});

export default function Filter({ setLoading, setEconomicClosing }) {
  const [selectedLot, setSelectedLot] = useState(null);

  const { data: lotsData } = useApiV2ShowLotEconomicClosing([false]);

  async function handleGenarateReport() {
    setLoading(true);
    const lot = selectedLot && selectedLot.secure_id;

    const reportEconomicClosing = await getReportEconomicClosingV2(lot);
    setEconomicClosing(reportEconomicClosing);
    setLoading(false);
  }

  return (
    <Container>
      <StyledPaper>
        <Grid container spacing={2} alignItems="center" justify="space-around">
          <Grid item sm={4}>
            <Autocomplete
              size="small"
              noOptionsText="Sem opções"
              filterOptions={filterOptions}
              options={!!lotsData ? lotsData : []}
              getOptionLabel={option => option.value}
              value={selectedLot}
              onChange={(event, value) => setSelectedLot(value)}
              renderInput={params => (
                <TextField {...params} label="Lote" fullWidth />
              )}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Hidden only="xs">
            <Grid item sm lg md />
          </Hidden>

          <Grid item xs={12} sm="auto">
            <Hidden only="xs">
              <StyledButtonGenerator
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleGenarateReport}
              >
                Gerar
              </StyledButtonGenerator>
            </Hidden>

            <Hidden smUp>
              <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleGenarateReport}
              >
                Gerar
              </StyledButton>
            </Hidden>
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

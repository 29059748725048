import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2"

export async function getTreaty(secure_id) {
  if (!secure_id) {
    return null
  }

  const { data } = await apiV2.get(`/v1/feedlot/register/treaty/${secure_id}`)

  return {
    currentTreaty: data
  }
}

export function useApiV2SelectTreaty(secure_id) {
  return useQuery(['treaties', secure_id], () => getTreaty(secure_id))
}

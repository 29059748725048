import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2"

export async function getData() {
  const { data } = await apiV2.get(`/v1/feedlot/register/edit-animals-in-lot/showData`)

  return data
}

export function useApiV2ShowDataEditAnimalsInLot() {
  return useQuery(['showDataInLot'], () => getData())
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Hidden,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Input,
} from '@material-ui/core';

import {
  Container,
  StyledPaper,
  StyledButton,
  StyledTextField,
} from './styles';

import { useApiV2GlobalBreeds } from '~/hooks/apiV2/global/breed/useApiV2GlobalBreeds';

export default function Filter({
  handleGenerateReport,
  setBiggerValue,
  setSmallValue,
}) {
  const [currentBreeds, setCurrentBreeds] = useState([]);
  const [currentDay, setCurrentDay] = useState([]);
  const allTypesData = ['Inteiro', 'Castrado', 'Novilha', 'Vaca']
  const [currentTypes, setCurrentTypes] = useState([]);
  const allDays = ['7 dias', '7 - 15 dias', '15 - 30 dias', '> 30 dias']
  const [bigger, setBigger] = useState(0);
  const [small, setSmall] = useState(0);

  const { data: breeds } = useApiV2GlobalBreeds()

  function handleChangeTypes(event) {
    setCurrentTypes(event.target.value);
  }

  function handleChangeBreed(event) {
    setCurrentBreeds(event.target.value);
  }

  function handleChangeDays(event) {
    setCurrentDay(event.target.value);
  }

  return (
    <Container>
      <StyledPaper>
        <Grid container spacing={4} alignItems="center" justify="space-around">
          <Grid item xs={12} sm>
            <FormControl fullWidth>
              <InputLabel>Raça</InputLabel>
              <Select
                multiple
                value={currentBreeds}
                onChange={handleChangeBreed}
                input={<Input />}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                renderValue={selected =>
                  selected.map(module => module).join(', ')
                }
              >
                {!!breeds && breeds.breeds.map(module => (
                  <MenuItem key={module.name} value={module.name}>
                    <Checkbox
                      color="primary"
                      checked={
                        currentBreeds.findIndex(item => item === module.name) >
                        -1
                      }
                    />
                    <ListItemText primary={module.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm>
            <FormControl fullWidth>
              <InputLabel>Categoria</InputLabel>
              <Select
                multiple
                value={currentTypes}
                onChange={handleChangeTypes}
                input={<Input />}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                renderValue={selected =>
                  selected.map(module => module).join(', ')
                }
              >
                {allTypesData.map(module => (
                  <MenuItem key={module} value={module}>
                    <Checkbox
                      color="primary"
                      checked={
                        currentTypes.findIndex(item => item === module) > -1
                      }
                    />
                    <ListItemText primary={module} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container xs={12} sm={12} spacing={2}>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                id="name"
                name="name"
                variant="outlined"
                label="Dias de Conf. maior que:"
                autoComplete="nope"
                size="small"
                required
                type="numeric"
                value={bigger || ''}
                onChange={e => {
                  setBigger(e.target.value);
                  setBiggerValue(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledTextField
                id="name"
                name="name"
                variant="outlined"
                label="Dias de Conf. menor que:"
                autoComplete="nope"
                type="numeric"
                size="small"
                value={small || ''}
                required
                onChange={e => {
                  setSmall(e.target.value);
                  setSmallValue(e.target.value);
                }}
              />
            </Grid>
          </Grid>

          <Grid
            container
            xs={12}
            sm={12}
            spacing={2}
            style={{ width: '100%', marginTop: 8, marginBottom: 16 }}
          >
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth style={{ margin: '0 8px', width: '95%' }}>
                <InputLabel>Agrupar em:</InputLabel>
                <Select
                  value={currentDay}
                  onChange={handleChangeDays}
                  input={<Input />}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                  }}
                >
                  {allDays.map(module => (
                    <MenuItem key={module} value={module}>
                      <ListItemText primary={module} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Hidden only="xs">
                <StyledButton
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleGenerateReport}
                >
                  Gerar
                </StyledButton>
              </Hidden>

              <Hidden smUp>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleGenerateReport}
                >
                  Gerar
                </Button>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

export const ages = ['até 12', '13 a 24', '25 a 36', '+ de 36'];

export const types = [
  'Inteiro',
  'Castrado',
  'Novilha',
  'Vaca',
  'Bezerro',
  'Bezerra',
];

export const bodySizes = ['3', '4', '5', '6', '7', '8', '9'];

export const inputTypes = [
  { value: 'purchase', label: 'Compra' },
  { value: 'transfer', label: 'Transferência' },
  { value: 'boitel', label: 'Boitel' },
];

export const gender = [
  { value: 'Macho', label: 'Macho' },
  { value: 'Femea', label: 'Fêmea' },
];

export const states = [
  { id: 1, label: 'Acre (AC)', value: 'AC' },
  { id: 2, label: 'Alagoas (AL)', value: 'AL' },
  { id: 3, label: 'Amapá (AP)', value: 'AP' },
  { id: 4, label: 'Amazonas (AM)', value: 'AM' },
  { id: 5, label: 'Bahia (BA)', value: 'BA' },
  { id: 6, label: 'Ceará (CE)', value: 'CE' },
  { id: 7, label: 'Distrito Federal (DF)', value: 'DF' },
  { id: 8, label: 'Espírito Santo (ES)', value: 'ES' },
  { id: 9, label: 'Goiás (GO)', value: 'GO' },
  { id: 10, label: 'Maranhão (MA)', value: 'MA' },
  { id: 11, label: 'Mato Grosso (MT)', value: 'MT' },
  { id: 12, label: 'Mato Grosso do Sul (MS)', value: 'MS' },
  { id: 13, label: 'Minas Gerais (MG)', value: 'MG' },
  { id: 14, label: 'Pará (PA)', value: 'PA' },
  { id: 15, label: 'Paraíba (PB)', value: 'PB' },
  { id: 16, label: 'Paraná (PR)', value: 'PR' },
  { id: 17, label: 'Pernambuco (PE)', value: 'PE' },
  { id: 18, label: 'Piauí (PI)', value: 'PI' },
  { id: 19, label: 'Rio de Janeiro (RJ)', value: 'RJ' },
  { id: 20, label: 'Rio Grande do Norte (RN)', value: 'RN' },
  { id: 21, label: 'Rio Grande do Sul (RS)', value: 'RS' },
  { id: 22, label: 'Rondônia (RO)', value: 'RO' },
  { id: 23, label: 'Roraima (RR)', value: 'RR' },
  { id: 24, label: 'Santa Catarina (SC)', value: 'SC' },
  { id: 25, label: 'São Paulo (SP)', value: 'SP' },
  { id: 26, label: 'Sergipe (SE)', value: 'SE' },
  { id: 27, label: 'Tocantins (TO)', value: 'TO' },
];

export const funcsPersonal = [
  { id: 0, value: 'client', label: 'Cliente' },
  { id: 1, value: 'provider', label: 'Fornecedor' },
];

export const typesPersonal = [
  { id: 0, value: 'individual', label: 'Pessoa Física' },
  { id: 1, value: 'legal_entity', label: 'Pessoa Jurídica' },
];

export const funcsEmployee = [
  { id: 0, label: 'Gerente', value: 'Gerente' },
  { id: 1, label: 'Veterinário', value: 'Veterinário' },
  { id: 2, label: 'Tratador', value: 'Tratador' },
  { id: 3, label: 'Boiadeiro', value: 'Boiadeiro' },
  { id: 4, label: 'Fábrica de Ração', value: 'Fábrica de Ração' },
  { id: 5, label: 'Escritório', value: 'Escritório' },
  { id: 6, label: 'Diarista', value: 'Diarista' },
  { id: 7, label: 'Folguista', value: 'Folguista' },
];

export const roles = [
  { slug: 'manager', text: 'Gerente' },
  { slug: 'technical', text: 'Técnico' },
  { slug: 'user', text: 'Usuário' },
];

export const optionsStatus = [
  { id: 0, value: 'default', label: 'Todos' },
  { id: 1, value: true, label: 'Ativo' },
  { id: 2, value: false, label: 'Inativo' },
];

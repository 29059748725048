import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { format } from 'date-fns';
import {
  Container,
  StyledPaper,
  StyledTextField,
  StyledButton,
  AnimalInfos,
  CardTitle,
  CardBody,
  Card,
  FindAnimalContainer,
  WeightContainer,
  StyledGrid,
} from './styles';
import colors from '~/styles/colors';

// Query
import { searchAnimal } from '~/hooks/apiV2/global/searchAnimal/useApiV2SearchAnimal';
import { useDebouncedPromise } from '~/utils/useDebouncedPromise';

export default function ShowAnimal({
  settings,
  animals,
  setAnimals,
}) {
  const [animalValue, setAnimalValue] = useState('');
  const [animal, setAnimal] = useState({});

  const searchAnimalRequest = async (animalId) => {
    const animalSameId = animals.find(animalFind => animalFind[settings.default_identification] === animalId);

    if (animalSameId) return;

    const resp = await searchAnimal(
      settings.default_identification,
      animalId
    );

    if (resp) {
      setAnimal(resp);
    } else {
      setAnimal({});
    }
  }

  const debounceSearchAnimal = useDebouncedPromise(searchAnimalRequest, 1000);

  return (
    <Container>
      <StyledPaper>
        <FindAnimalContainer>
          <StyledGrid container justify="start" spacing={2}>
            <Grid item>
              <StyledTextField
                id="animal_id"
                name="animal_id"
                fullWidth
                variant="outlined"
                label="Identificador do animal"
                autoComplete="off"
                size="small"
                value={animalValue}
                onChange={e => {
                  debounceSearchAnimal(e.target.value);
                  setAnimalValue(e.target.value)
                }}
              />
            </Grid>
          </StyledGrid>
        </FindAnimalContainer>
        <AnimalInfos container spacing={2}>
          <Card>
            <CardTitle>Data de Entrada</CardTitle>
            <CardBody>
              {animal && animal.input_date
                ? format(new Date(animal.input_date), 'dd/MM/yyyy')
                : '-'}
            </CardBody>
          </Card>
          <Card>
            <CardTitle>Raça</CardTitle>
            <CardBody>{animal?.breed?.name || '-'}</CardBody>
          </Card>
          <Card>
            <CardTitle>Categoria</CardTitle>
            <CardBody>{animal?.type || '-'}</CardBody>
          </Card>
          <Card>
            <CardTitle>Sexo</CardTitle>
            <CardBody>{animal?.sex || '-'}</CardBody>
          </Card>
          <Card>
            <CardTitle>Idade</CardTitle>
            <CardBody>{animal?.age || '-'}</CardBody>
          </Card>
          <Card>
            <CardTitle>Tamanho Corporal</CardTitle>
            <CardBody>{animal?.body_size || '-'}</CardBody>
          </Card>
          <Card>
            <CardTitle>Peso do Animal</CardTitle>
            <CardBody>
              {animal.current_weight ? `${animal.current_weight}kg` : '-'}
            </CardBody>
          </Card>
          <Card>
            <CardTitle>Lote</CardTitle>
            <CardBody>{animal.lot ? animal.lot.code : '-'}</CardBody>
          </Card>
          <Card>
            <CardTitle>Piquete</CardTitle>
            <CardBody>{animal.lot ? animal.lot.paddock?.name : '-'}</CardBody>
          </Card>
        </AnimalInfos>
        <WeightContainer>
          <StyledButton
            style={{ background: colors.success }}
            variant="contained"
            disabled={!animal.secure_id}
            onClick={() => {
              setAnimals([...animals, animal]);
              setAnimal({});
              setAnimalValue('');
            }}
          >
            Adicionar animal
          </StyledButton>
        </WeightContainer>
      </StyledPaper>
    </Container>
  );
}

ShowAnimal.propTypes = {
  settings: PropTypes.shape({
    default_identification: PropTypes.string,
  }).isRequired,
  animals: PropTypes.array.isRequired,
  setAnimals: PropTypes.func.isRequired,
};

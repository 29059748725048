import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';

// Query
import { useApiV2DietSwitch } from '~/hooks/apiV2/foodManagement/dietSwitch/useApiV2DietSwitch';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

import {
  Container,
  StyledGrid,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
} from './styles';

import Filter from './Components/Filter';

import history from '~/services/history';
import ButtonNew from '~/components/ButtonNew';
import Loader from '~/components/Loader';
import Can from '~/components/Can';
import { CardTableDietSwitch } from './Components/CardTableDietSwitch';

export default function DietSwitch() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('final_date_diet');
  const [direction, setDirection] = useState('asc');
  const [currentDiet, setCurrentDiet] = useState(null);
  const [currentPaddock, setCurrentPaddock] = useState(null);

  // Query
  const { data, isLoading } = useApiV2DietSwitch(`${field}%7C${direction}`, page + 1, rowsPerPage, currentDiet, currentPaddock);
  const { data: settings } = useApiV2GlobalSetting();

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleAddDietSwitch() {
    history.push('/dashboard/dietSwitch/add');
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={2}>
          <Grid item xs sm md lg xl>
            {settings && (
              <Filter
                setCurrentDiet={setCurrentDiet}
                setCurrentPaddock={setCurrentPaddock}
                settings={settings.settings}
              />
            )}
          </Grid>
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['dietSwitch_new']}
          >
            <Grid item xs="auto" sm="auto">
              <ButtonNew title="Nova" onClick={handleAddDietSwitch} />
            </Grid>
          </Can>
        </StyledGrid>

        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                      Piquete
                    </TableCell>
                    <TableCell>
                      Lote
                    </TableCell>
                    <TableCell>
                      Dieta Anterior
                    </TableCell>
                    <TableCell>
                      Dieta Atual
                    </TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={field === 'initial_date_diet'}
                        direction={
                          field === 'initial_date_diet' ? direction : 'asc'
                        }
                        onClick={() => handleChangeOrder('initial_date_diet')}
                      >
                        Data Inicial
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={field === 'final_date_diet'}
                        direction={
                          field === 'final_date_diet' ? direction : 'asc'
                        }
                        onClick={() => handleChangeOrder('final_date_diet')}
                      >
                        Data Final
                      </TableSortLabel>
                    </TableCell>
                    <StyledTableCell colSpan={2} align="right" />
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {settings && data && data.dietSwitch
                    .map(dSwitch => (
                      <CardTableDietSwitch
                        key={dSwitch.secure_id}
                        dSwitch={dSwitch}
                        settings={settings.settings}
                      />
                    ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

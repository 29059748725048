/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import Chart from 'react-apexcharts';

import {
  Container,
  Wrapper,
  HeaderSection,
  StyledPaper,
  Header,
  ContentChart,
} from './styles';

import { formatNumber } from '~/utils/format';
import HeaderPrint from '~/components/HeaderPrint';

export default class PrinteconomicClosing extends Component {
  render() {
    const { economicClosing } = this.props;
    console.log(economicClosing)

    const optionsChartPie = {
      responsive: [
        {
          breakpoint: 400,
          options: {
            chart: {
              width: 10,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      legend: {
        formatter(val, opts) {
          return `${val} - ${Number(
            opts.w.globals.seriesPercent[opts.seriesIndex]
          ).toFixed(2)}%`;
        },
      },
    };

    return (
      <Container>
        <HeaderPrint
          title="Fechamento Econômico"
          filters={[
            `Data: ${String(format(new Date(), 'dd/MM/yyyy HH:mm:ss'))}`,
          ]}
        />

        <Wrapper>
          <StyledPaper elevation={0}>
            <HeaderSection>
              <h3>Indentificação</h3>
            </HeaderSection>
            <Grid container>
              <Grid item xs sm>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Lote:
                  </Typography>
                  {economicClosing.identification &&
                    economicClosing.identification.lot}
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Fornecedor:
                  </Typography>
                  <strong>
                    {economicClosing.identification &&
                      economicClosing.identification.providers
                        .map(provider => provider)
                        .join(', ')}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Cliente:
                  </Typography>
                  <strong>
                    {economicClosing.identification &&
                      economicClosing.identification.inputsType
                        .map(inputsType => inputsType)
                        .join(', ')}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Tipo de entrada:
                  </Typography>
                  <strong>
                    {economicClosing.identification &&
                      economicClosing.identification.categories
                        .map(categorie => categorie)
                        .join(', ')}
                  </strong>
                </Grid>

                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Raça:
                  </Typography>
                  <strong>
                    {economicClosing.identification &&
                      economicClosing.identification.breeds
                        .map(breed => breed)
                        .join(', ')}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Categoria:
                  </Typography>
                  <strong>
                    {economicClosing.identification &&
                      economicClosing.identification.categories
                        .map(categorie => categorie)
                        .join(', ')}
                  </strong>
                </Grid>
              </Grid>
            </Grid>
          </StyledPaper>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs sm={4}>
              <StyledPaper style={{ minHeight: 233 }}>
                <Header>
                  <h3>Dados de Entrada</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Data Média de Entrada:
                      </Typography>
                      <strong>
                        {economicClosing.input &&
                          economicClosing.input.averageDate}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Quantidade de Animais:
                      </Typography>
                      <strong>
                        {economicClosing.input &&
                          economicClosing.input.amountAnimals}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso Vivo Médio (Kg/Cab):
                      </Typography>
                      <strong>
                        {economicClosing.input &&
                          formatNumber(economicClosing.input.sightWeight, 2, 2)}
                      </strong>
                    </Grid>

                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Rendimento de Carcaça (%):
                      </Typography>
                      <strong>
                        {economicClosing.input &&
                          formatNumber(
                            economicClosing.input.carcassYield,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        @ Entrada:
                      </Typography>
                      <strong>
                        {economicClosing.input &&
                          formatNumber(economicClosing.input.atInputs, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Total @ Entrada:
                      </Typography>
                      <strong>
                        {economicClosing.input &&
                          formatNumber(
                            economicClosing.input.atTotalInputs,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>
            <Grid item xs sm={4}>
              <StyledPaper style={{ minHeight: 233 }}>
                <Header>
                  <h3>Dados de Saída</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Data Média de Saída:
                      </Typography>
                      <strong>
                        {economicClosing.output &&
                          economicClosing.output.averageDate}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Quantidade de Animais:
                      </Typography>
                      <strong>
                        {economicClosing.output &&
                          economicClosing.output.amountAnimals}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso Vivo Médio (Kg/Cab):
                      </Typography>
                      <strong>
                        {economicClosing.output &&
                          formatNumber(
                            economicClosing.output.sightWeight,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Rendimento de Carcaça (%):
                      </Typography>
                      <strong>
                        {economicClosing.output &&
                          formatNumber(
                            economicClosing.output.carcassYield,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        @ Saida:
                      </Typography>
                      <strong>
                        {economicClosing.output &&
                          formatNumber(economicClosing.output.atOutputs, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Total @ Saida:
                      </Typography>
                      <strong>
                        {economicClosing.output &&
                          formatNumber(
                            economicClosing.output.atTotalOutputs,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>
            <Grid item xs sm={4}>
              <StyledPaper style={{ minHeight: 233 }}>
                <Header>
                  <h3>Dados de Desempenho</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Dias de Confinamento:
                      </Typography>
                      <strong>
                        {economicClosing.resume &&
                          economicClosing.resume.daysOnFeedlot}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Total de Diarias:
                      </Typography>
                      <strong>
                        {economicClosing.financialAnalysis &&
                          economicClosing.resume.amountDaily}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        GMD:
                      </Typography>
                      <strong>
                        {economicClosing.resume &&
                          formatNumber(
                            economicClosing.resume.dailyWeightGain,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        GMC:
                      </Typography>
                      <strong>
                        {economicClosing.resume &&
                          formatNumber(
                            economicClosing.resume.dailyCarcassGain,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        @ Colocada:
                      </Typography>
                      <strong>
                        {economicClosing.resume &&
                          formatNumber(economicClosing.resume.atGain, 2, 2)}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>
          </Grid>
          {/* <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p> */}
          <StyledPaper style={{ marginTop: 40 }}>
            <Header>
              <h3>Analise Financeira</h3>
            </Header>
            <Grid container>
              <Grid item xs sm={4}>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Valor @ de Entrada:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.input.valueAt,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Valor / Cabeça de Entrada:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.input.valueHead,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Valor Total de Entrada:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.input.valueTotal,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Valor @ de Venda:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.output.valueAt,
                        2,
                        2
                      )}
                  </strong>
                </Grid>

                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Receita / Cabeça:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.output.revenueHead,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Receita Total:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.output.revenueTotal,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
              </Grid>
              <Grid item xs sm={4}>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Custo Alimentar / Cabeça Dia:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume
                          .feedCostHeadDay,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Custo Alimentar / Cabeça:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume.feedCostHead,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Custo Alimentar / Lote:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume.feedCostLot,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Custo Sanitário / Cabeça Dia:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume
                          .sanitaryCostHeadDay,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Custo Sanitário / Cabeça:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume
                          .sanitaryCostHead,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Custo Sanitário / Lot:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume
                          .sanitaryCostLot,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Custo Operacional / Cabeça Dia:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume
                          .operationalCostHeadDay,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Custo Operacional / Cabeça:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume
                          .operationalCostHead,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Custo Operacional / Lot:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume
                          .operationalCostLot,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
              </Grid>
              <Grid item xs sm={4}>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Custo Final / Cabeça Dia:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume
                          .finalCostHeadDay,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Custo Final / Cabeça:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume.finalCostHead,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Custo Final / Lote:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume.finalCostLot,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    C.Final + C.Compra / Cabeça:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume
                          .finalCostInputCostHead,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    C. Final + C. Compra / Lote:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume
                          .finalCostInputCostLot,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Custo @ Colocada:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume.costAtGain,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Custo @ Produzida:
                  </Typography>
                  <strong>
                    {' '}
                    R$
                    {economicClosing.financialAnalysis &&
                      formatNumber(
                        economicClosing.financialAnalysis.resume.costAtProduce,
                        2,
                        2
                      )}
                  </strong>
                </Grid>
              </Grid>
            </Grid>
          </StyledPaper>
          <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
          <Grid container spacing={2} style={{ marginTop: 20 }}>
            <Grid item xs sm={4}>
              <StyledPaper style={{ minHeight: 233 }}>
                <Header>
                  <h3>Balanço</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Balanço / Cabeça:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.balance
                              .balanceAnimal,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Balanço / Lote:
                      </Typography>
                      <strong>
                        {' '}
                        R$
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.balance
                              .balanceLot,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Lucratividade Total (%):
                      </Typography>
                      <strong>
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.balance
                              .profitabilityTotal,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>

                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Lucratividade Mensal (%):
                      </Typography>
                      <strong>
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.balance
                              .profitabilityMonth,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Rentabilidade Total (%):
                      </Typography>
                      <strong>
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.balance
                              .rentabilityTotal,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Rentabilidade Mensal (%):
                      </Typography>
                      <strong>
                        {economicClosing.financialAnalysis &&
                          formatNumber(
                            economicClosing.financialAnalysis.balance
                              .rentabilityMonth,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>
            <Grid item xs sm={8}>
              <StyledPaper style={{ minHeight: 233 }}>
                <Header>
                  <h3>Grafico de Custos</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    {economicClosing.graphicCost && (
                      <ContentChart>
                        <Chart
                          type="pie"
                          options={{
                            ...optionsChartPie,
                            labels: economicClosing.graphicCost.labels,
                          }}
                          series={economicClosing.graphicCost.series}
                        />
                      </ContentChart>
                    )}
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>
          </Grid>
        </Wrapper>
      </Container>
    );
  }
}

PrinteconomicClosing.propTypes = {
  economicClosing: PropTypes.shape().isRequired,
};

import { Paper, TableContainer, TableHead, TextField } from '@material-ui/core';
import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 50px;
  padding: 10px;

  img {
    height: 70px;
    width: 140px;
  }
`;

export const StyledPaperTable = styled(Paper)`
  width: 100%;
  padding-bottom: 16px;
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const StyledTextFieldForecast = styled(TextField)`
  input {
    text-align: right;
  }
`;

export const Column = styled.div``;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

import { useQuery } from "react-query";
import apiV2 from '~/services/apiV2';

async function getTreatmentsForCreatePerDaysManufactoringDiet(currentDate) {
  const { data } = await apiV2.get(`v1/feedlot/food-management/manufacturing-diet-per-day-data-create`, {
    params: {
      currentDate
    }
  })

  return data
}

export function useV2GetTreatmentsForCreatePerDaysManufactoringDiet(currentDate) {
  return useQuery(
    ['getTreatmentsForCreatePerDaysManufactoringDiet', currentDate],
    () => getTreatmentsForCreatePerDaysManufactoringDiet(currentDate))
}

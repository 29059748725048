import styled, { css } from 'styled-components';
import { Paper, Grid, Button } from '@material-ui/core';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 800px;
  width: 80%;
  padding: 40px 0px;

  text-align: left;
`;

export const StyledPaper = styled(Paper)`
  padding: 22px;
`;

export const StyledFodGrid = styled(Grid)`
  margin-bottom: 24px;
`;

export const StyledGrid = styled(Grid)`
  margin: 20px 0px;
`;

export const StyledGridItem = styled(Grid)`
  display: flex;
  justify-content: center;
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.marginTop || 50}px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${props => props.color};
  background: ${props => props.background};

  ${props =>
    props.background &&
    css`
      &:hover {
        background: ${darken(0.06, props.background)};
      }
    `}
`;

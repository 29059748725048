import styled from 'styled-components';
import { Button } from '@material-ui/core';
import colors from '~/styles/colors';

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${colors.white};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";

export async function getAnimalsInputUnity(secure_id) {
  const { data } = await apiV2.get(`/v1/feedlot/animal-handling/individualAnimalInput/${secure_id}`);

  return {
    currentAnimalInput: data
  };
}

export function useApiV2SelectIndividualAnimalInput(secure_id) {
  return useQuery(
    ['animalInputUnity', secure_id],
    () => getAnimalsInputUnity(secure_id)
  );
}

import styled from 'styled-components';
import { Paper, Button, TextField } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledTextField = styled(TextField)`
  margin: 8px 8px 0 8px;
  width: 95%;
`;

export const StyledPaper = styled(Paper)`
  padding: 10px;
  max-width: 600px;
  width: 100%;
  margin-bottom: 28px;
`;

export const StyledButton = styled(Button)`
  margin: 8px 8px 0 8px;
  width: 95%;
`;

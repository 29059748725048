import React from 'react';
import PropTypes from 'prop-types';

import { ListItemIcon, ListItemText } from '@material-ui/core';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import RemoveIcon from '@material-ui/icons/Remove';

import { StyledListItem } from './styles';

import history from '~/services/history';

export default function ItemAlert({ alert }) {
  function renderIcon({ type }) {
    switch (type) {
      case 'diet_switch':
        return <SwapHorizIcon />;
      case 'food':
        return <LocalShippingIcon />;
      case 'historic_food':
        return <RemoveIcon color="error" />;
      default:
        return null;
    }
  }

  function handleSelect({ type, secure_id, subtitle }) {
    switch (type) {
      case 'diet_switch':
        history.push({
          pathname: '/dashboard/dietSwitch/add',
          state: { paddock_id: secure_id },
        });
        break;
      case 'food':
        history.push('/dashboard/food');
        break;
      case 'historic_food':
        history.push({
          pathname: '/dashboard/reportHistoricFood',
          state: { food_id: secure_id, food_name: subtitle },
        });
        break;
      default:
        break;
    }
  }

  return (
    <StyledListItem key={alert.secure_id} button onClick={() => handleSelect(alert)}>
      <ListItemIcon>{renderIcon(alert)}</ListItemIcon>
      <ListItemText
        primary={alert.title}
        secondary={alert.subtitle}
        secondaryTypographyProps={{
          noWrap: true,
        }}
      />
    </StyledListItem>
  );
}

ItemAlert.propTypes = {
  alert: PropTypes.shape({
    secure_id: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  }).isRequired,
};

import React, { useEffect, useState } from 'react';

import {
  TableRow
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import ButtonTable from '~/components/ButtonTable';

import { useAuthContext } from '~/contexts/AuthContext';

import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';

export function CardTableAnimal({ animal, setIdentificationType, lot_id = null }) {
  const { farm } = useAuthContext();
  const { powerUps } = farm;

  const [settings, setSettings] = useState({});

  const { data: settingsData } = useApiV2GlobalSetting();

  function handleEdit({ secure_id }) {
    history.push(`/dashboard/animals/edit/${secure_id}`, { lot_id });
  }

  function formatNameLot(animal) {
    if (animal && animal.lot_code) {
      if (animal.paddock_name) {
        if (animal.line_name) {
          return `${animal.lot_code} / ${animal.line_name} - ${animal.paddock_name}`
        }
        return `${animal.lot_code} / ${animal.paddock_name}`
      }
      return `${animal.lot_code}`
    }
  }

  useEffect(() => {
    if (settingsData) {
      setSettings(settingsData.settings);
      setIdentificationType(settingsData.settings.default_identification);
    }
  }, [settingsData]);

  return (
    <TableRow key={animal.secure_id}>
      {!powerUps || powerUps.length <= 0 ? (
        <>
          <StyledTableCellEllipsis component="th" scope="row">
            {animal.sisbov}
          </StyledTableCellEllipsis>
          <StyledTableCellEllipsis component="th" scope="row">
            {animal.botton_rfid}
          </StyledTableCellEllipsis>
          <StyledTableCellEllipsis component="th" scope="row">
            {animal.ident_earing}
          </StyledTableCellEllipsis>
        </>
      ) : (
        <StyledTableCellEllipsis component="th" scope="row">
          {animal[settings.default_identification]}
        </StyledTableCellEllipsis>
      )}
      <StyledTableCellEllipsis component="th" scope="row">
        {animal.breed_name}
      </StyledTableCellEllipsis>
      <StyledTableCellEllipsis>
        {formatNameLot(animal)}
      </StyledTableCellEllipsis>
      <StyledTableCellEllipsis>
        {animal.active ? 'Ativo' : 'Inativo'}
      </StyledTableCellEllipsis>
      <Can
        checkRole={['administrator', 'owner', 'beefer']}
        checkPermission={['animals_edit', 'animals_delete']}
      >
        <StyledTableCell align="right">
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['animals_edit']}
          >
            <ButtonTable
              icon={<EditIcon color="primary" />}
              background={colors.primary}
              lighten={0.6}
              onClick={() => handleEdit(animal)}
            />
          </Can>
        </StyledTableCell>
      </Can>
    </TableRow>
  )
}

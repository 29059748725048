import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

import { InputNumber } from './styles';

export default function TextFieldEdit({
  disabled,
  defaultValue,
  item,
  listFeeds,
  setListFeeds,
}) {
  const [value, setValue] = useState('');

  function calcDeviation(performed, forecast) {
    let deviation = 0;

    if (forecast > 0) {
      deviation = Number((performed / forecast - 1) * 100);
    }

    if (deviation) {
      return Number(deviation);
    }

    if (performed === undefined) {
      return '';
    }

    return 0;
  }

  function handleEdit(floatValue) {
    const newListFeeds = [...listFeeds];

    const feedIndex = listFeeds.findIndex(
      manufactoring => manufactoring.secure_id === item.secure_id
    );

    const deviation = calcDeviation(floatValue, item.forecast);

    const newFood = {
      ...item,
      performed: typeof floatValue === 'number' ? floatValue : null,
      deviation: floatValue >= 0 ? deviation : null,
    };

    newListFeeds.splice(feedIndex, 1, newFood);

    setListFeeds(newListFeeds);
    setValue(floatValue);
  }

  return (
    <InputNumber
      id={item.secure_id}
      customInput={TextField}
      max="999999.99"
      min="0.00"
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      decimalScale={3}
      autoComplete="nope"
      allowNegative={false}
      disabled={disabled}
      value={value === '' ? defaultValue : value}
      onValueChange={values => handleEdit(values.floatValue)}
    />
  );
}

TextFieldEdit.propTypes = {
  disabled: PropTypes.bool.isRequired,
  defaultValue: PropTypes.number.isRequired,
  item: PropTypes.shape({
    secure_id: PropTypes.any,
    forecast: PropTypes.number,
  }).isRequired,
  listFeeds: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setListFeeds: PropTypes.func.isRequired,
};

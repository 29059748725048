import styled from 'styled-components';
import {
  Paper,
  Button,
  FormControl,
  Divider,
  Grid,
  IconButton,
} from '@material-ui/core';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 960px;
  width: 80%;
  padding: 40px 0px;

  text-align: left;
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: 5px;
  color: ${colors.white};
  background: ${colors.success};
  transition: background 1s;

  &:hover {
    background: ${darken(0.06, colors.success)};
  }
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 20px;

  text-align: left;
`;

export const StyledFormControl = styled(FormControl)`
  margin-top: 8px;
`;

export const ContentItem = styled.div`
  width: 100%;
  margin-top: 20px;
  h3 {
    color: ${colors.primary};
    font-weight: 400;
    text-align: center;
    margin-bottom: 16px;
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 24px 0px;
`;

export const StyledGrid = styled(Grid)`
  margin-top: 16px;
`;

export const Error = styled.span`
  margin: 8px 14px 0;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.marginTop || 42}px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 8px;
  margin-left: 12px;
  background: ${props => props.background};
  width: 150px;

  &:hover {
    background: ${props => props.background};
  }
  span {
    color: ${props => props.spanColor || colors.white};
  }
`;

export const StyledButtonClose = styled(Button)`
  margin: 0px;
  color: ${colors.error};

  &:hover {
    background: ${props => props.background};
  }
`;

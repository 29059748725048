import { useQuery } from "react-query";
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';

export async function getLots(active = [true, true]) {
  try {
    const { data } = await apiV2.get(`v1/feedlot/global/list/lot`, {
      params: {
        active
      }
    })

    return {
      lots: data
    }
  } catch {
    toast.error('Falha ao tentar carregar os lotes!');
  }
}

export function useApiV2GlobalLots(active) {
  return useQuery(
    ['lots', active],
    () => getLots(active),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 20px;
    height: 20px;
  }

  svg {
    color: ${props => props.colorIcon};
  }
`;

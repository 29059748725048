import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

export async function getTroughReadings(sort, module, date) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/food-management/troughReading?sort=${sort}`, {
      params: { sort, module, date },
    });

    return {
      troughReadings: data,
    }
  } catch {
    toast.error('Não foi possível buscar leituras!');
  }
}

export function useApiV2TroughReading(sort, module, date) {
  return useQuery(['troughReading', sort, module, date],
    () => getTroughReadings(sort, module, date),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    });
}

import React, { useState, useMemo } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Grid, TextField, InputAdornment, MenuItem } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import {
  Container,
  StyledIconButton,
  ContentItem,
  StyledPaper,
  StyledDivider,
  StyledGrid,
  Error,
  ContentSubmitButtons,
  StyledSubmitButton,
  StyledButton,
  StyledButtonClose,
} from './styles';

import { formatDateOnly, parseUserInput } from '~/utils/format';
import colors from '~/styles/colors';
import history from '~/services/history';
import Loader from '~/components/Loader';
import NumberFormatForm from '~/components/NumberFormatForm';
import ModalStakeholder from '~/components/ModalStakeholder';
import ButtonSubmit from '~/components/ButtonSubmit';

// Query
import { useApiV2EditAnimalOutput } from '~/hooks/apiV2/animalHandling/AnimalOutput/useApiV2EditAnimalOutput';
import { useApiV2SelectAnimalOutput } from '~/hooks/apiV2/animalHandling/AnimalOutput/useApiV2SelectAnimalOutput';

export default function Edit({ match }) {
  const [visibleModalStakeholder, setVisibleModalStakeholder] = useState(false);
  const [showOtherInformation, setShowOtherInformation] = useState(false);

  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  // Query
  const editAnimalOutput = useApiV2EditAnimalOutput();
  const { data: currentAnimalOutput, isLoading } = useApiV2SelectAnimalOutput(secure_id);

  const schema = Yup.object().shape({
    amount_animals: Yup.number()
      // .max(totalAmount, 'Qtd. deve ser menor que a qtd disponível')
      .typeError('Insira um valor válido')
      .required('Informe uma quantidade de saída'),
    output_weight: Yup.number()
      .typeError('Insira um valor válido')
      .required('Informe um peso de saída'),
    output_average_carcass_weight: Yup.number()
      .typeError('Insira um valor válido')
      .nullable(),
    output_carcass_yield: Yup.number().typeError('Insira um valor válido').nullable(),
    client_id: Yup.string()
      .typeError('Selecione um cliente válido')
      .required('Selecione um cliente'),
    absent: Yup.number().typeError('Insira um valor válido').nullable(),
    scarce: Yup.number().typeError('Insira um valor válido').nullable(),
    median: Yup.number().typeError('Insira um valor válido').nullable(),
    uniform: Yup.number().typeError('Insira um valor válido').nullable(),
    excessivo: Yup.number().typeError('Insira um valor válido').nullable(),
  });

  function handleModalStakeholder() {
    setVisibleModalStakeholder(!visibleModalStakeholder);
  }

  function changeCarcassYield(event, values, setFieldValue, typeCarcass) {
    if (event.target.value && values.output_weight) {
      const newCarcassYield =
        (parseUserInput(event.target.value) / Number(values.output_weight)) *
        100;
      setFieldValue('output_carcass_yield', newCarcassYield);
    } else {
      setFieldValue('output_carcass_yield', '');
    }
  }

  async function handleSubmit(values) {
    const data = {
      stakeholderSecureId: values.client_id,
      outputAtPrice: values.output_at_price,
      outputAverageCarcassWeight: values.output_average_carcass_weight,
      outputCarcassYield: values.output_carcass_yield,
      outputWeight: values.output_weight,
    }

    await editAnimalOutput.mutateAsync({ secure_id, ...data });
  }

  function handleBack() {
    history.goBack();
  }

  return (
    <center>
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          <Formik
            enableReinitialize
            validationSchema={schema}
            initialValues={{
              amount_animals: currentAnimalOutput.amount_animals,
              output_weight:
                currentAnimalOutput && currentAnimalOutput.movementHistory
                  ? currentAnimalOutput.movementHistory[0].animal.output_weight
                  : '',
              age:
                currentAnimalOutput && currentAnimalOutput.movementHistory
                  ? currentAnimalOutput.movementHistory[0].animal.age
                  : '',
              sex:
                currentAnimalOutput && currentAnimalOutput.movementHistory
                  ? currentAnimalOutput.movementHistory[0].animal.sex
                  : '',
              type:
                currentAnimalOutput && currentAnimalOutput.movementHistory
                  ? currentAnimalOutput.movementHistory[0].animal.type
                  : '',
              date: currentAnimalOutput.date,
              output_average_carcass_weight:
                currentAnimalOutput && currentAnimalOutput.movementHistory
                  ? currentAnimalOutput.movementHistory[0].animal
                    .output_average_carcass_weight
                  : '',
              output_carcass_yield:
                currentAnimalOutput && currentAnimalOutput.movementHistory
                  ? currentAnimalOutput.movementHistory[0].animal
                    .output_carcass_yield
                  : '',
              output_at_price:
                currentAnimalOutput && currentAnimalOutput.movementHistory
                  ? currentAnimalOutput.movementHistory[0].animal
                    .output_at_price
                  : '',
              absent: currentAnimalOutput.absent,
              scarce: currentAnimalOutput.scarce,
              median: currentAnimalOutput.median,
              uniform: currentAnimalOutput.uniform,
              excessivo: currentAnimalOutput.excessivo,
              breed_id:
                currentAnimalOutput && currentAnimalOutput.movementHistory
                  ? currentAnimalOutput.movementHistory[0].animal.breed
                    .secure_id
                  : '',
              breed_name:
                currentAnimalOutput && currentAnimalOutput.movementHistory
                  ? currentAnimalOutput.movementHistory[0].animal.breed.name
                  : '',
              client_id: currentAnimalOutput.client.secure_id
            }}
            onSubmit={handleSubmit}
          >
            {({ handleBlur, setFieldValue, errors, touched, values, isSubmitting }) => (
              <Form>
                <ContentItem>
                  <StyledPaper>
                    <Grid container justify="center" spacing={2}>
                      <Grid item xs={12} sm="auto">
                        <NumberFormatForm
                          label="Qtd. Disponível"
                          decimalScale={0}
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                          value={currentAnimalOutput.amount_animals_active}
                        />
                      </Grid>

                      <Grid item xs={12} sm="auto">
                        <NumberFormatForm
                          label="Peso Médio Atual"
                          decimalScale={2}
                          disabled
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">Kg</InputAdornment>
                            ),
                          }}
                          value={currentAnimalOutput.current_average_weight}
                        />
                      </Grid>
                    </Grid>

                    <StyledDivider />
                    <h3>Informações de Saída</h3>

                    <Grid container justify="space-around" spacing={2}>
                      <Grid item sm xs={12}>
                        <TextField
                          name="date"
                          label="Data"
                          variant="outlined"
                          size="small"
                          fullWidth
                          required
                          disabled
                          InputLabelProps={{
                            shrink: values.date,
                          }}
                          value={values.date && formatDateOnly(values.date)}
                        />
                      </Grid>
                      <Grid item sm xs={12}>
                        <TextField
                          name="sex"
                          label="Sexo"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          InputLabelProps={{
                            shrink: values.sex,
                          }}
                          disabled
                          value={values.sex}
                        >
                          <MenuItem value={values.sex}>{values.sex}</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item sm xs={12}>
                        <TextField
                          name="breed_id"
                          label="Raça"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          InputLabelProps={{
                            shrink: values.breed_id,
                          }}
                          disabled
                          value={values.breed_name}
                        >
                          <MenuItem value={values.breed_name}>
                            {values.breed_name}
                          </MenuItem>
                        </TextField>
                      </Grid>
                    </Grid>

                    <StyledGrid container justify="space-around" spacing={2}>
                      <Grid item sm xs={12}>
                        <TextField
                          name="age"
                          label="Idade"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          InputLabelProps={{
                            shrink: values.age,
                          }}
                          disabled
                          value={values.age}
                        >
                          <MenuItem value={values.age}>{values.age}</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item sm xs={12}>
                        <TextField
                          name="type"
                          label="Tipo"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          InputLabelProps={{
                            shrink: values.type,
                          }}
                          disabled
                          value={values.type}
                        >
                          <MenuItem value={values.type}>{values.type}</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm>
                        <NumberFormatForm
                          name="amount_animals"
                          label="Qtd. de Saída"
                          variant="outlined"
                          size="small"
                          fullWidth
                          autoComplete="nope"
                          decimalScale={0}
                          defaultValue={values.amount_animals}
                          value={values.amount_animals}
                          onBlur={handleBlur}
                          error={
                            !!errors.amount_animals && touched.amount_animals
                          }
                          disabled
                        />
                        <ErrorMessage name="amount_animals" component={Error} />
                      </Grid>
                    </StyledGrid>

                    <StyledGrid container justify="space-around" spacing={2}>
                      <Grid item md={4} xs={12} sm>
                        <NumberFormatForm
                          name="output_weight"
                          label="Peso de Saída"
                          variant="outlined"
                          size="small"
                          fullWidth
                          autoComplete="nope"
                          decimalScale={2}
                          onBlur={handleBlur}
                          error={
                            !!errors.output_weight && touched.output_weight
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">Kg</InputAdornment>
                            ),
                          }}
                          disabled
                          value={values.output_weight}
                        />
                        <ErrorMessage name="output_weight" component={Error} />
                      </Grid>

                      <Grid item md={4} xs={12} sm>
                        <TextField
                          name="client_id"
                          label="Cliente"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          InputLabelProps={{
                            shrink: values.age,
                          }}
                          disabled
                          value={values.client_id}
                        >
                          <MenuItem value={currentAnimalOutput.client.secure_id}>{currentAnimalOutput.client.name}</MenuItem>
                        </TextField>
                        <ErrorMessage name="client_id" component={Error} />
                      </Grid>
                      <Grid item xs sm style={{ marginTop: 4 }}>
                        <StyledIconButton
                          variant="contained"
                          size="small"
                          onClick={handleModalStakeholder}
                        >
                          <AddIcon />
                        </StyledIconButton>
                      </Grid>
                    </StyledGrid>

                    <ContentSubmitButtons>
                      <StyledSubmitButton
                        variant="outlined"
                        color="primary"
                        type="button"
                        spanColor={colors.primary}
                        onClick={handleBack}
                      >
                        Cancelar
                      </StyledSubmitButton>
                      <ButtonSubmit title="Alterar" loading={isSubmitting} />
                    </ContentSubmitButtons>
                  </StyledPaper>
                  {!showOtherInformation && (
                    <center style={{ marginTop: '24px' }}>
                      <StyledButton
                        variant="contained"
                        type="button"
                        background={colors.success}
                        onClick={() => setShowOtherInformation(true)}
                        style={{ width: '300px', margin: 0 }}
                      >
                        <span>Adicionar Informações de Abate</span>
                      </StyledButton>
                    </center>
                  )}
                  {showOtherInformation && (
                    <StyledPaper style={{ marginTop: 16 }}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <StyledButtonClose
                          onClick={() => setShowOtherInformation(false)}
                        >
                          Ocultar
                        </StyledButtonClose>
                      </div>
                      <h3>Informações de Abate</h3>
                      <StyledGrid container justify="space-around" spacing={2}>
                        <Grid item xs={12} sm>
                          <NumberFormatForm
                            name="output_average_carcass_weight"
                            label="Peso Médio da carcaça"
                            variant="outlined"
                            size="small"
                            fullWidth
                            autoComplete="nope"
                            decimalScale={2}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  Kg
                                </InputAdornment>
                              ),
                            }}
                            isAllowed={val => {
                              const { formattedValue, floatValue } = val;
                              return (
                                formattedValue === '' ||
                                floatValue <= values.output_weight
                              );
                            }}
                            value={values.output_average_carcass_weight}
                            setFieldValue={setFieldValue}
                            onChange={event =>
                              changeCarcassYield(
                                event,
                                values,
                                setFieldValue
                              )
                            }
                            onBlur={handleBlur}
                            error={
                              !!errors.output_average_carcass_weight &&
                              touched.output_average_carcass_weight
                            }
                          />
                          <ErrorMessage
                            name="output_average_carcass_weight"
                            component={Error}
                          />
                        </Grid>
                        <Grid item xs={12} sm>
                          <NumberFormatForm
                            name="output_carcass_yield"
                            label="Rendimento da carcaça (%)"
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled
                            fixedDecimalScale
                            autoComplete="nope"
                            decimalScale={2}
                            value={values.output_carcass_yield}
                            setFieldValue={setFieldValue}
                            error={
                              !!errors.output_carcass_yield && touched.output_carcass_yield
                            }
                          />
                          <ErrorMessage
                            name="output_carcass_yield"
                            component={Error}
                          />
                        </Grid>
                        <Grid item xs={12} sm>
                          <NumberFormatForm
                            name="output_at_price"
                            label="Preço da arroba"
                            size="small"
                            variant="outlined"
                            min="0"
                            decimalScale={2}
                            fixedDecimalScale
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              ),
                            }}
                            isAllowed={val => {
                              const { formattedValue, floatValue } = val;
                              return (
                                formattedValue === '' ||
                                floatValue <= 9999999999
                              );
                            }}
                            error={
                              !!errors.output_at_price && touched.output_at_price
                            }
                            value={values.output_at_price}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage name="output_at_price" component={Error} />
                        </Grid>
                      </StyledGrid>
                      <StyledDivider />
                      <StyledGrid container justify="space-around" spacing={2}>
                        <Grid item xs={12} sm>
                          <NumberFormatForm
                            name="absent"
                            label="Qtd. Ausente"
                            variant="outlined"
                            size="small"
                            fullWidth
                            autoComplete="nope"
                            decimalScale={0}
                            defaultValue={values.absent}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            error={!!errors.absent && touched.absent}
                          />
                          <ErrorMessage name="absent" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm>
                          <NumberFormatForm
                            name="scarce"
                            label="Qtd. Escasso"
                            variant="outlined"
                            size="small"
                            fullWidth
                            autoComplete="nope"
                            decimalScale={0}
                            defaultValue={values.scarce}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            error={!!errors.scarce && touched.scarce}
                          />
                          <ErrorMessage name="scarce" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm>
                          <NumberFormatForm
                            name="median"
                            label="Qtd. Mediana"
                            variant="outlined"
                            size="small"
                            fullWidth
                            autoComplete="nope"
                            decimalScale={0}
                            defaultValue={values.median}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            error={!!errors.median && touched.median}
                          />
                          <ErrorMessage name="median" component={Error} />
                        </Grid>
                      </StyledGrid>
                      <StyledGrid container justify="space-around" spacing={2}>
                        <Grid item xs={12} sm>
                          <NumberFormatForm
                            name="uniform"
                            label="Qtd. Uniforme"
                            variant="outlined"
                            size="small"
                            fullWidth
                            autoComplete="nope"
                            decimalScale={0}
                            defaultValue={values.uniform}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            error={!!errors.uniform && touched.uniform}
                          />
                          <ErrorMessage name="uniform" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm>
                          <NumberFormatForm
                            name="excessivo"
                            label="Qtd. Excesso"
                            variant="outlined"
                            size="small"
                            fullWidth
                            autoComplete="nope"
                            decimalScale={0}
                            defaultValue={values.excessivo}
                            setFieldValue={setFieldValue}
                            onBlur={handleBlur}
                            error={!!errors.excessivo && touched.excessivo}
                          />
                          <ErrorMessage name="excessivo" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm />
                      </StyledGrid>
                    </StyledPaper>
                  )}
                </ContentItem>
              </Form>
            )}
          </Formik>

          {visibleModalStakeholder && (
            <ModalStakeholder
              visibleModal={visibleModalStakeholder}
              handleModal={handleModalStakeholder}
              type="client,both"
            />
          )}
        </Container>
      )}
    </center>
  );
}

Edit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core';

// Query
import {
  useApiV2IndividualAnimalsOutput
} from '~/hooks/apiV2/animalHandling/IndividualAnimalOutput/useApiV2IndividualAnimalsOutput';

import {
  Container,
  StyledGrid,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledButton,
} from './styles';

import history from '~/services/history';
import Can from '~/components/Can';
import ButtonNew from '~/components/ButtonNew';
import Loader from '~/components/Loader';

import Filter from './Components/Filter';
import { CardTableAnimalOutput } from './Components/CardTableAnimalOutput';

export default function TransferLot() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [date, setDate] = useState(
  );

  // Query
  const { data, isLoading } = useApiV2IndividualAnimalsOutput('', page + 1, rowsPerPage, date);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleAdd() {
    history.push('/dashboard/individualAnimalOutput/add');
  }
  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={2}>
          <Grid item xs sm md lg xl>
            <Filter date={date} setDate={setDate} />
          </Grid>
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['animalOutput_new']}
          >
            <StyledButton container justify="center" spacing={2}>
              <Grid item>
                <ButtonNew title="Nova saída " onClick={handleAdd} />
              </Grid>
            </StyledButton>
          </Can>
        </StyledGrid>

        <StyledPaper>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <StyledTableContainer>
                <Table stickyHeader>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell align="center">
                        Quantidade de animais
                      </TableCell>
                      <TableCell align="center">Cliente</TableCell>
                      <TableCell align="center">Data de saída</TableCell>
                      <Can checkRole={['administrator', 'owner', 'manager']}>
                        <TableCell align="right" />
                      </Can>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {data && data.animalsOutput.map(data => (
                      <CardTableAnimalOutput
                        key={data.secure_id}
                        data={data}
                      />
                    ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                labelRowsPerPage="Linhas por pág."
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={data ? data.perPage : 0}
                page={data ? data.page - 1 : 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
        </StyledPaper>
      </Container>
    </center>
  );
}

import styled, { css } from 'styled-components';
import { 
   AppBar,
   Toolbar,
   Button,
   IconButton,
   Menu,
   MenuItem,
} from '@material-ui/core';
import { width, breakpoints } from '@material-ui/system';
import colors from '~/styles/colors';

export const StyledAppBar = styled(AppBar)`
  ${breakpoints(width)};

  transition: width 0.5s;
`;

export const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  width: 100%;
`;

export const StyledMenu = styled(Menu)`
  
`;

export const StyledMenuItem = styled(MenuItem)`
  max-width: 400px;
  height: 100px;
  background: ${props => props.viewed || colors.lightSecondary};
  
  margin: 10px 10px;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  flex-grow: 1;
  #footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  #viewed {
    color: green;
    width: 30px;
    height: 30px;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  margin: 0px 5px;
  cursor: pointer;

  span {
    color: ${colors.secondary};
  }

  @media (max-width: 600px) {
    width: 100px;

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.8rem;
    }
  }

  @media (max-width: 450px) {
    span {
      font-size: 0.7rem;
    }
  }

  @media (max-width: 400px) {
    max-width: 100%;

    span {
      white-space: nowrap;
      overflow: visible;
      text-overflow: clip;
      font-size: 0.7rem;
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  margin-right: 8px;

  ${props =>
    props.visible &&
    css`
      display: none;
    `}
`;

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    h6 {
      font-size: 1rem;
    }
  }

  @media (max-width: 450px) {
    h6 {
      font-size: 0.8rem;
    }
  }

  @media (max-width: 400px) {
    max-width: 0;
  }
`;

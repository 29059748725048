import React from 'react';
import Swal from 'sweetalert2';

import {
  TableCell,
  TableRow
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

// Query hooks
import { useApiV2RemoveDietSwitch } from '~/hooks/apiV2/foodManagement/dietSwitch/useApiV2RemoveDietSwitch';

import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell,
  StyledTableCellEllipsis
} from './styles';
import { formatNamePaddock } from '~/utils/format';
import ActionLoader from '~/components/ActionLoader';

export function CardTableDietSwitch({ dSwitch, settings }) {
  // Query
  const removeDietSwitch = useApiV2RemoveDietSwitch()

  function handleRemoveDietSwitch(secure_id) {
    async function performRemoveDietSwitch() {
      await removeDietSwitch.mutateAsync(secure_id)
    }
    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar essa troca de dietas?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar troca de dietas!',
    }).then(result => {
      if (result.value) {
        performRemoveDietSwitch();
      }
    });
  }

  return (
    <TableRow>
      <StyledTableCell>
        {dSwitch.lot &&
          dSwitch.lot.paddock &&
          formatNamePaddock(settings, dSwitch.lot.paddock)}
      </StyledTableCell>
      <TableCell component="th" scope="row">
        {dSwitch.lot.code}
      </TableCell>
      <StyledTableCell>
        {dSwitch.oldDiet && dSwitch.oldDiet.name}
      </StyledTableCell>
      <StyledTableCell>
        {dSwitch.diet.name}
      </StyledTableCell>
      <TableCell align="center">
        {dSwitch.initial_date_diet}
      </TableCell>
      <TableCell align="center">
        {dSwitch.final_date_diet}
      </TableCell>
      <StyledTableCell colSpan={2} align="right">
      <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['dietSwitch_delete']}
        >
          <ButtonTable
            icon={
              removeDietSwitch.isLoading ? (
                <ActionLoader text="Deletando" />
              ) :
                (
                  <DeleteIcon color="error" />
                )
            }
            background={colors.error}
            onClick={() => handleRemoveDietSwitch(dSwitch.secure_id)}
          />
        </Can>
      </StyledTableCell>
    </TableRow>
  )
}

import React, { createContext, useCallback, useContext, useEffect, useState } from "react"
import { toast } from 'react-toastify';

import apiV2 from "~/services/apiV2";
import history from "~/services/history";
import { queryClient } from '~/services/queryClient';

const AuthContext = createContext({})

export function AuthProvider({ children }) {
  const [user, setUser] = useState({
    name: '',
    email: '',
    permissions: localStorage.getItem('@BeefSystem:user') ? JSON.parse(localStorage.getItem('@BeefSystem:user')).permissions : [],
    roles: localStorage.getItem('@BeefSystem:user') ? JSON.parse(localStorage.getItem('@BeefSystem:user')).roles : [],
    isAuthenticated: !!(localStorage.getItem('@BeefSystem:token'))
  });
  const [farm, setFarm] = useState({});
  const [farms, setFarms] = useState([]);

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('@BeefSystem:token'))

    if (token) {
      handleLoadUser()
    }
  }, [])

  const handleLoadUser = useCallback(async () => {
    apiV2.get('/v1/feedlot/user/me').then(response => {
      const { data } = response
      setFarms(data.farms.map(farm => ({
        name: farm.name,
        secureId: farm.secure_id
      })))

      setUser({
        name: data.name,
        email: data.email,
        permissions: data.permissions,
        roles: data.roles,
        isAuthenticated: true
      })

      setFarm({
        id: data.farm.id,
        name: data.farm.name,
        secureId: data.farm.secure_id,
        powerUps: data.farm.powerUps
      })

      localStorage.setItem(
        '@BeefSystem:farm',
        JSON.stringify({
          id: data.farm.id,
          name: data.farm.name,
          secureId: data.farm.secure_id,
          powerUps: data.farm.powerUps
        })
      )

      localStorage.setItem(
        '@BeefSystem:user',
        JSON.stringify({
          permissions: data.permissions,
          roles: data.roles,
        })
      )
    }).catch(error => {
      signOut()
    })
  }, [])

  const handleSetFarm = useCallback(async (farmSelected) => {
    try {
      apiV2.defaults.headers.farmSecureId = farmSelected.secureId

      const { data } = await apiV2.get('v1/feedlot/user/me')

      setUser({
        name: data.name,
        email: data.email,
        permissions: data.permissions,
        roles: data.roles,
        isAuthenticated: true
      })

      setFarm({
        id: data.farm.id,
        name: data.farm.name,
        secureId: data.farm.secure_id,
        powerUps: data.farm.powerUps
      })

      localStorage.setItem(
        '@BeefSystem:farm',
        JSON.stringify({
          id: data.farm.id,
          name: data.farm.name,
          secureId: data.farm.secure_id,
          powerUps: data.farm.powerUps
        })
      )

      localStorage.setItem(
        '@BeefSystem:user',
        JSON.stringify({
          permissions: data.permissions,
          roles: data.roles,
        })
      )

      queryClient.resetQueries()
    } catch (err) {
      apiV2.defaults.headers.farmSecureId = farm.secureId

      const message = err.response.data?.message ? err.response.data.message : 'Erro inesperado ao trocar de fazenda.'
      toast.error(message)
    }
  }, [])

  const signIn = useCallback(async (email, password) => {
    try {
      const { data } = await apiV2.post('v1/feedlot/auth', {
        email,
        password
      })

      setFarms(data.farms.map(farm => ({
        name: farm.name,
        secureId: farm.secure_id,
        powerUps: farm.powerUps
      })))

      setUser({
        name: data.name,
        email: data.email,
        permissions: data.permissions,
        roles: data.roles,
        isAuthenticated: true
      })

      setFarm({
        id: data.farm.id,
        name: data.farm.name,
        secureId: data.farm.secure_id,
        powerUps: data.farm.powerUps
      })

      localStorage.setItem(
        '@BeefSystem:farm',
        JSON.stringify({
          id: data.farm.id,
          name: data.farm.name,
          secureId: data.farm.secure_id,
          powerUps: data.farm.powerUps
        })
      )

      localStorage.setItem(
        '@BeefSystem:user',
        JSON.stringify({
          permissions: data.permissions,
          roles: data.roles,
        })
      )

      localStorage.setItem(
        '@BeefSystem:token',
        JSON.stringify(data.token.token)
      )

      apiV2.defaults.headers['Authorization'] = `Bearer ${data.token.token}`
      apiV2.defaults.headers['farmSecureId'] = data.farm.secure_id

      history.push('/dashboard');
    } catch (err) {
      const message = err.response.data?.message ? err.response.data.message : 'Erro inesperado ao fazer login.'
      toast.error(message)
    }
  }, [])

  const signOut = useCallback(async () => {
    const token = JSON.parse(localStorage.getItem('@BeefSystem:token'))

    try {
      if (token) {
        await apiV2.delete('v1/feedlot/auth')
      }
    } catch { }

    localStorage.removeItem('@BeefSystem:farm')

    localStorage.removeItem('@BeefSystem:token')

    apiV2.defaults.headers['Authorization'] = ''
    apiV2.defaults.headers['farmSecureId'] = ''

    setUser({
      name: '',
      email: '',
      permissions: [],
      roles: [],
      isAuthenticated: false
    })
    setFarm({})
    setFarms([])

    history.push('/')
  }, [])

  return (
    <AuthContext.Provider value={{ user, handleSetFarm, farm, farms, signIn, signOut, handleLoadUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuthContext() {
  return useContext(AuthContext)
}

import {
  FormControl, Grid, InputLabel, MenuItem, Select
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';
import React from 'react';
import InputDatePicker from '~/components/InputDatePicker';
import { useApiV2GlobalBreeds } from '~/hooks/apiV2/global/breed/useApiV2GlobalBreeds';
import { useApiV2GlobalLots } from '~/hooks/apiV2/global/lot/useApiV2GlobalLots';
import { regexDate } from '~/utils/common';
import {
  Content, ContentDate,
  StyledGridItem,
  StyledIconButton, StyledTablePaper
} from './styles';

export default function Filter({
  currentDate,
  setCurrentDate,
  setCurrentBreed,
  setCurrentLot,
}) {
  const { data: breeds } = useApiV2GlobalBreeds()
  const { data: lots } = useApiV2GlobalLots()

  function handleChangeDate(date, value) {
    if (value && !regexDate.test(value)) return;

    setCurrentDate(date);
  }

  function handleSelectLot(event) {
    if (event.target.value === 'default') {
      setCurrentLot(null);
    } else {
      setCurrentLot(event.target.value);
    }
  }

  function handleChangeBreed(event) {
    if (event.target.value === 'default') {
      setCurrentBreed(null);
    } else if (event.target.value) {
      setCurrentBreed(event.target.value);
    }
  }

  return (
    <Content>
      <StyledTablePaper>
        <Grid container justify="space-around" spacing={2}>
          <Grid item xs={12} sm>
            <ContentDate>
              <InputDatePicker
                label="Data Inicial"
                maxDate={new Date()}
                selectedDate={currentDate}
                handleChangeDate={(date, value) =>
                  handleChangeDate(date, value)
                }
                InputProps={{
                  startAdornment: (
                    <StyledIconButton onClick={() => handleChangeDate(null)}>
                      <ClearIcon />
                    </StyledIconButton>
                  ),
                }}
                InputAdornmentProps={{
                  position: 'end',
                }}
              />
            </ContentDate>
          </Grid>
          <StyledGridItem item xs={12} sm>
            <FormControl size="small" fullWidth>
              <InputLabel id="input-lot">Lot</InputLabel>
              <Select
                id="lot"
                defaultValue={'default'}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                onChange={handleSelectLot}
              >
                <MenuItem value={'default'}>
                  Todos
                </MenuItem>
                {!!lots && lots.lots.map(item => (
                  <MenuItem key={item.secure_id} value={item.secure_id}>
                    {item.code}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledGridItem>
          <StyledGridItem item xs={12} sm>
            <FormControl size="small" fullWidth>
              <InputLabel id="input-breed">Raça</InputLabel>
              <Select
                id="breed"
                defaultValue={'default'}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                onChange={handleChangeBreed}
              >
                <MenuItem value={'default'}>
                  Todas
                </MenuItem>
                {!!breeds && breeds.breeds.map(item => (
                  <MenuItem key={item.secure_id} value={item.secure_id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledGridItem>
        </Grid>
      </StyledTablePaper>
    </Content>
  );
}

Filter.defaultProps = {
  currentDate: null,
};

Filter.propTypes = {
  currentDate: PropTypes.string,
  setCurrentDate: PropTypes.func.isRequired,
  setCurrentBreed: PropTypes.func.isRequired,
  setCurrentLot: PropTypes.func.isRequired,
};

import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";

export async function getDashboard() {
  const { data } = await apiV2.get(`/v1/feedlot/dashboard`);

  return data
}

export function useApiV2Dashboard() {
  return useQuery(['dashboard'],
    () => getDashboard(),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    });
}

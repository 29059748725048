import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";

export async function getZootechResume({
  initialDate = new Date(), finalDate = new Date(), lotsHidden = []
}) {
  const { data } = await apiV2.get('/v1/feedlot/report/zootechResume', {
    params: { initialDate, finalDate, lotsHidden }
  });

  return data
}

export function useApiV2ZootechResume({ initialDate, finalDate, lotsHidden }) {
  return useQuery(
    ['zootechResume', { initialDate, finalDate, lotsHidden }],
    () => getZootechResume({ initialDate, finalDate, lotsHidden }),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  );
}

import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

// Query
import { useApiV2AddOcurrence } from '~/hooks/apiV2/admin/ocurrence/useApiV2AddOcurrence'
import { useApiV2GlobalOcurrencesTypes } from '~/hooks/apiV2/global/ocurrencesType/useApiV2GlobalOcurrencesTypes';
import { useApiV2GlobalModule } from '~/hooks/apiV2/global/module/useApiV2GlobalModule';

import {
  Container,
  StyledPaper,
  ContentItem,
  Error,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';

import history from '~/services/history';
import ButtonSubmit from '~/components/ButtonSubmit';

const schema = Yup.object().shape({
  date: Yup.string().required('Informe a data'),
  type: Yup.string().required('Informe o tipo'),
  description: Yup.string().required('Informe a descrição'),
});

const moduleDefaultOption = {
  id: 'default',
  name: 'Todos'
}

const lineDefaultOption = {
  id: 'default',
  name: 'Todas'
}

const paddockDefaultOption = {
  id: 'default',
  name: 'Todos'
}

export default function AddOcurrences() {
  const [listLine, setListLine] = useState([]);
  const [listPaddock, setListPaddock] = useState([]);

  // Query
  const { data: ocurrencesTypeData } = useApiV2GlobalOcurrencesTypes();
  const { data: modulesData } = useApiV2GlobalModule();
  const addOcurrences = useApiV2AddOcurrence();

  function handleSelectModule(event, setFieldValue) {
    if (!modulesData) {
      return
    }
    const currentModule = modulesData.modules.find(
      module => module.secure_id === event.target.value
    );

    setFieldValue('module_id', event.target.value);
    setFieldValue('line_id', '');
    setFieldValue('paddock_id', '');
    if (currentModule) {
      setListLine(currentModule.lines);
      setListPaddock([]);
    } else {
      setListLine([]);
      setListPaddock([]);
    }
  }

  function handleSelectLine(event, setFieldValue) {
    const currentLine = listLine.find(
      line => line.secure_id === event.target.value
    );

    setFieldValue('line_id', event.target.value);
    setFieldValue('paddock_id', '');
    if (currentLine) {
      setListPaddock(currentLine.paddocks);
    } else {
      setListPaddock([]);
    }
  }

  function handleSelectPaddock(event, setFieldValue) {
    setFieldValue('paddock_id', event.target.value);
  }

  function handleSetDescription(event, setFieldValue) {
    if (ocurrencesTypeData) {
      const description = ocurrencesTypeData.ocurrencesType.filter(
        ocurrence => ocurrence.secure_id === event.target.value
      )
      if (description.length > 0) {
        setFieldValue('description', description[0].description);
      }
    }
  }

  async function handleSubmit(data) {
    const newData = {
      date: data.date,
      type: data.type,
      description: data.description,
      moduleSecureId: data.module_id,
      lineSecureId: data.line_id,
      paddockSecureId: data.paddock_id,
    }
    await addOcurrences.mutateAsync(newData)
  }

  function handleBack() {
    history.goBack();
  }

  return (
    <center>
      <Container>
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            description: '',
            type: '',
            date: new Date(),
          }}
        >
          {({ handleChange, errors, touched, setFieldValue, values, isSubmitting }) => (
            <Form>
              <ContentItem>
                <StyledPaper>
                  <Grid container spacing={2}>
                    <Grid item sm xs={12}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={ptBR}
                      >
                        <KeyboardDatePicker
                          name="date"
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          size="small"
                          id="date-picker-inline"
                          label="Data"
                          inputVariant="outlined"
                          maxDate={new Date()}
                          required
                          fullWidth
                          autoOk
                          defaultValue={new Date()}
                          style={{ marginTop: 0, marginBottom: 0 }}
                          value={values.date}
                          error={(!!errors.date && touched.date)}
                          onChange={date => setFieldValue('date', new Date(date))}
                        />
                        <ErrorMessage name="date" component={Error} />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md>
                      <FormControl
                        size="small"
                        required
                        fullWidth
                        variant="outlined"
                        error={!!errors.type && touched.type}
                      >
                        <InputLabel id="input-type">Tipo</InputLabel>
                        <Select
                          name="type"
                          labelWidth={50}
                          inputProps={{
                            id: 'type-input',
                          }}
                          onChange={event => {
                            handleSetDescription(event, setFieldValue)
                            handleChange(event)
                          }}
                        >
                          {ocurrencesTypeData && ocurrencesTypeData.ocurrencesType.map(item => (
                            <MenuItem key={item.secure_id} value={item.secure_id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <ErrorMessage name="type" component={Error} />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md>
                      <TextField
                        name="description"
                        variant="outlined"
                        label="Descrição *"
                        autoComplete="off"
                        fullWidth
                        multiline
                        error={!!errors.description && touched.description}
                        onChange={handleChange}
                        value={values.description}
                      />
                      <ErrorMessage name="description" component={Error} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm xs={12}>
                      <TextField
                        name="module_id"
                        label="Módulo"
                        variant="outlined"
                        fullWidth
                        select
                        size="small"
                        InputLabelProps={{
                          shrink: values.module_id,
                        }}
                        defaultValue={moduleDefaultOption.id}
                        error={!!errors.module_id && touched.module_id}
                        value={values.module_id}
                        onChange={event =>
                          handleSelectModule(event, setFieldValue)
                        }
                      >
                        <MenuItem value={moduleDefaultOption.id}>
                          {moduleDefaultOption.name}
                        </MenuItem>
                        {modulesData && modulesData.modules.map(item => (
                          <MenuItem
                            key={item.secure_id}
                            value={item.secure_id}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <ErrorMessage name="module_id" component={Error} />
                    </Grid>
                    <Grid item sm xs={12}>
                      <TextField
                        name="line_id"
                        label="Linha"
                        variant="outlined"
                        fullWidth
                        select
                        size="small"
                        InputLabelProps={{
                          shrink: values.line_id,
                        }}
                        disabled={listLine.length <= 0}
                        error={!!errors.line_id && touched.line_id}
                        value={values.line_id}
                        onChange={event =>
                          handleSelectLine(event, setFieldValue)
                        }
                      >
                        {listLine.length > 0 && (
                          <MenuItem value={lineDefaultOption.id}>
                            {lineDefaultOption.name}
                          </MenuItem>
                        )}
                        {listLine.map(item => (
                          <MenuItem
                            key={item.secure_id}
                            value={item.secure_id}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <ErrorMessage name="line_id" component={Error} />
                    </Grid>
                    <Grid item sm xs={12}>
                      <TextField
                        name="paddock_id"
                        label="Piquete"
                        variant="outlined"
                        fullWidth
                        select
                        size="small"
                        InputLabelProps={{
                          shrink: values.paddock_id,
                        }}
                        disabled={listPaddock.length <= 0}
                        error={!!errors.paddock_id && touched.paddock_id}
                        value={values.paddock_id}
                        onChange={event => {
                          handleSelectPaddock(event, setFieldValue);
                        }}
                      >
                        {listPaddock.length > 0 && (
                          <MenuItem value={paddockDefaultOption.id}>
                            {paddockDefaultOption.name}
                          </MenuItem>
                        )}
                        {listPaddock.map(item => (
                          <MenuItem
                            key={item.secure_id}
                            value={item.secure_id}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <ErrorMessage name="paddock_id" component={Error} />
                    </Grid>
                  </Grid>

                  <ContentSubmitButtons>
                    <ButtonSubmit title="Cadastrar" loading={isSubmitting} />
                    <StyledSubmitButton
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={handleBack}
                    >
                      Cancelar
                    </StyledSubmitButton>
                  </ContentSubmitButtons>
                </StyledPaper>
              </ContentItem>
            </Form>
          )}
        </Formik>
      </Container>
    </center>
  );
}

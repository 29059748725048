import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function formatNumber(number, minDecimalScale, maxDecimalScale) {
  const formattedNumber = Number(number);

  if (isNaN(formattedNumber)) return null;

  return formattedNumber.toLocaleString(ptBR, {
    minimumFractionDigits: minDecimalScale || 0,
    maximumFractionDigits: maxDecimalScale || 0,
  });
}

export function formatDateString(date) {
  const parsedDate = parseISO(date);

  return format(parsedDate, "dd/MM/yyyy 'às' HH:mm'h'");
}

export function formatDateOnly(date) {
  const parsedDate = new Date(date);

  return format(parsedDate, 'dd/MM/yyyy');
}

export function FormatDateForYearMonthDay(date) {
  return format(new Date(date), "yyyy-MM-dd")
}

export function formatDateOnlyYY(date) {
  const parsedDate = parseISO(date);

  return format(parsedDate, 'dd/MM/yy');
}

export function parseNumericUserInput(string) {
  if (string === null || string === undefined) return null;
  return Number(string.replace('R$', '').replace(/\./g, '').replace(',', '.'));
}

export function parseUserInput(value) {
  if (value === null || value === undefined) return null;
  return Number(String(value).replace(/\./g, '').replace(',', '.'));
}

export function formatCpf(cpf) {
  cpf = cpf.replace(/[^\d]/g, '');
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}

export function formatTel(tel) {
  tel = tel.replace(/[^\d]/g, '');
  return tel.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
}

export function roundPrecision(num) {
  const newNum = num - Math.floor(num);

  if (newNum <= 0.25) {
    return Math.round(num);
  }

  if (newNum > 0.25 && newNum <= 0.75) {
    return Math.floor(num) + 0.5;
  }

  if (newNum > 0.75) {
    return Math.ceil(num);
  }

  return 0;
}

export function formatNamePaddock(setting, paddock) {
  if (paddock) {
    if (
      setting.visible_module === 1 &&
      setting.visible_line === 1 &&
      setting.visible_paddock === 1
    ) {
      return `${paddock.line.module.name} - ${paddock.line.name} - ${paddock.name}`;
    }
    if (setting.visible_module === 1 && setting.visible_line === 1) {
      return `${paddock.line.module.name} - ${paddock.line.name}`;
    }
    if (setting.visible_module === 1 && setting.visible_paddock === 1) {
      return `${paddock.line.module.name} - ${paddock.name}`;
    }
    if (setting.visible_module === 1) {
      return `${paddock.line.module.name}`;
    }
    if (setting.visible_line === 1 && setting.visible_paddock === 1) {
      return `${paddock.line.name} - ${paddock.name}`;
    }
    if (setting.visible_line === 1) {
      return `${paddock.line.name}`;
    }
    return `${paddock.name}`;
  }

  return null;
}

export function getIdentificationTyp(default_identification) {
  if (default_identification === 'sisbov') {
    return 'sisbov';
  }
  if (default_identification === 'bottom') {
    return 'botton_rfid';
  }
  if (default_identification === 'ident_earing') {
    return 'ident_earing';
  }
  return null;
}

export function formatMoney(price) {
  if (price < 0) {
    return `-R$ ${formatNumber(Math.abs(price), 2, 2)}`;
  }

  return `R$ ${formatNumber(price, 2, 2)}`;
}

import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import ReactToPrint from 'react-to-print';
import {
  Grid,
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

// Query
import { useApiV2HistoricFood } from '~/hooks/apiV2/Report/historicFood/useApiV2HistoricFood';

import {
  Container,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
  StyledTableCellColor,
  ContentPrint,
} from './styles';

import Print from './Print';
import Filter from './Components/Filter';

import colors from '~/styles/colors';
import { formatDateOnly, formatNumber, formatMoney } from '~/utils/format';
import Loader from '~/components/Loader';

export default function HistoricFood({ location }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [filters, setFilters] = useState({
    initialDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    finalDate: new Date(),
    currentFood: {
      secure_id: '',
      value: '',
      name: '',
    }
  });

  const food_id = useMemo(
    () => (location && location.state ? location.state.food_id : null),
    [location]
  );
  const food_name = useMemo(
    () => (location && location.state ? location.state.food_name : null),
    [location]
  );

  const PrintScreen = useRef();

  const { data, isLoading } = useApiV2HistoricFood({
    page: page + 1,
    limit: rowsPerPage,
    initialDate: filters.initialDate,
    finalDate: filters.finalDate,
    food: filters.currentFood.secure_id
  });

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function defineTypeMovement({ type }) {
    switch (type) {
      case 'purchase_food':
        return 'Compra';
      case 'manufactoring_diet':
        return 'Fabricação Dieta';
      case 'manufactoring_feed':
        return 'Fabricação de Mistura/Ração';
      case 'correction':
        return 'Correção';
      case 'system':
        return 'Sistema';
      default:
        return null;
    }
  }

  function defineColor({ amount_ton, type, type_movement }) {
    if (amount_ton !== 0 && type !== 'correction') {
      if (type_movement === 'input' || type === 'purchase_food') {
        return colors.success;
      }

      return colors.error;
    }
    return null;
  }

  function definePrefix({ amount_ton, type, type_movement }) {
    if (
      amount_ton !== 0 &&
      type !== 'correction' &&
      type_movement === 'input'
    ) {
      return '+';
    }

    return '';
  }

  useEffect(() => {
    if (food_id && food_name) {
      setFilters({
        ...filters,
        currentFood: {
          secure_id: food_id,
          value: food_name,
          text: food_name,
        }
      });
    }
  }, [food_id, food_name]);

  return (
    <center>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs sm md lg xl>
            <Filter
              foodId={food_id}
              foodName={food_name}
              setFilters={setFilters}
            />
          </Grid>
          <Grid item xs="auto" sm="auto">
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!data}
                  startIcon={<PrintIcon />}
                >
                  Imprimir
                </Button>
              )}
              content={() => PrintScreen.current}
            />
            <div style={{ marginTop: 10 }}>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Exportar"
                style={{
                  padding: 0,
                }}
              />
            </div>
          </Grid>
        </Grid>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <StyledPaper>
              <StyledTableContainer>
                <Table stickyHeader id="table-to-xls">
                  <StyledTableHead>
                    <TableRow>
                      <TableCell>Data</TableCell>
                      <TableCell align="left">Tipo</TableCell>
                      <TableCell>Nome</TableCell>
                      <StyledTableCell align="right">
                        Quantidade (ton)
                      </StyledTableCell>
                      <StyledTableCell align="right">Preço/Ton</StyledTableCell>
                      <StyledTableCell align="right">Preço</StyledTableCell>
                      <StyledTableCell align="right">
                        Estoque Atual (ton)
                      </StyledTableCell>
                      <TableCell align="right">Preço/Ton Atual</TableCell>
                      <TableCell align="right">Preço Atual</TableCell>
                    </TableRow>
                  </StyledTableHead>

                  <TableBody>
                    {data && data.historic
                      .map(movement => (
                        <TableRow key={movement.secure_id}>
                          <TableCell component="th" scope="row">
                            {formatDateOnly(movement.date)}
                          </TableCell>
                          <TableCell align="left">
                            {defineTypeMovement(movement)}
                          </TableCell>
                          <TableCell>{movement.food.name}</TableCell>
                          <StyledTableCellColor
                            align="right"
                            color={defineColor(movement)}
                          >
                            {`${definePrefix(movement)} ${formatNumber(
                              movement.amount_ton,
                              4,
                              4
                            )}`}
                          </StyledTableCellColor>
                          <StyledTableCell align="right">
                            {`R$ ${formatNumber(
                              movement.price_ton_freight,
                              2,
                              2
                            )}`}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatMoney(movement.total_price)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(
                              movement.current_total_amount_ton,
                              4,
                              4
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatMoney(movement.current_price_ton)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatMoney(movement.current_total_price)}
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                labelRowsPerPage="Linhas por pág."
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={data ? data.perPage : 0}
                page={data ? data.page - 1 : 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </StyledPaper>

            <ContentPrint>
              {data && (
                <Print
                  ref={PrintScreen}
                  list={data.historic}
                  currentFood={filters.currentFood}
                  initialDate={filters.initialDate}
                  finalDate={filters.finalDate}
                />
              )}
            </ContentPrint>
          </>
        )}
      </Container>
    </center>
  );
}

HistoricFood.defaultProps = {
  location: null,
};

HistoricFood.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      food_id: PropTypes.string,
      food_name: PropTypes.string,
      dateEnd: PropTypes.string,
    }),
  }),
};

import { useQuery } from "react-query";
import apiV2 from '~/services/apiV2';

async function getV2GetLastSanitaryCost() {
  const { data } = await apiV2.get(`v1/feedlot/admin/last-sanitary-cost`)

  return data
}

export function useV2GetLastSanitaryCost() {
  return useQuery(
    ['V2GetLastSanitaryCost'],
    () => getV2GetLastSanitaryCost())
}

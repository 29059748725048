/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Typography, Grid } from '@material-ui/core';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import Chart from 'react-apexcharts';

import {
  Container,
  Wrapper,
  HeaderSection,
  StyledPaper,
  SubHeader,
  SubItem,
  SubItemRight,
  StyledDivider,
  ContentGraphic,
} from './styles';

import { formatNumber } from '~/utils/format';
import HeaderPrint from '~/components/HeaderPrint';

export default class PrintAnimalOutput extends Component {
  render() {
    const { animalOutput } = this.props;

    const labels = ['Compra', 'Operacional', 'Sanitário', 'Alimentação'];

    const optionsChart = {
      labels,
      colors: ['#14a36c', '#205678', '#edab47', '#782050'],
      animations: {
        enabled: false,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        formatter(val, opts) {
          return `${val} - ${Number(
            opts.w.globals.seriesPercent[opts.seriesIndex]
          ).toFixed(2)}%`;
        },
      },
    };

    return (
      <Container>
        <HeaderPrint
          title="Relatório Zootécnico - Resumo"
          filters={[
            `Data: ${String(format(new Date(), 'dd/MM/yyyy HH:mm:ss'))}`,
          ]}
        />

        <Wrapper>
          <StyledPaper elevation={0}>
            <HeaderSection>
              <h3>Indentificação</h3>
            </HeaderSection>
            <Grid container>
              <Grid item xs sm>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Data de entrada:
                  </Typography>
                  <strong>{animalOutput.input_date}</strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Fornecedor:
                  </Typography>
                  <strong>
                    {animalOutput.providers &&
                      animalOutput.providers
                        .map(provider => provider)
                        .join(', ')}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Qtd. de animais:
                  </Typography>
                  <strong>{animalOutput.amount_animals}</strong>
                </Grid>
              </Grid>

              <Grid item xs sm>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Data de abate:
                  </Typography>
                  <strong>{animalOutput.output_date}</strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Clientes:
                  </Typography>
                  <strong>
                    {animalOutput.clients &&
                      animalOutput.clients.map(client => client).join(', ')}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Dias de conf.:
                  </Typography>
                  <strong>{formatNumber(animalOutput.total_day)}</strong>
                </Grid>
              </Grid>
            </Grid>
          </StyledPaper>

          <StyledPaper elevation={0}>
            <HeaderSection>
              <h3>Desempenho</h3>
            </HeaderSection>
            <Grid container>
              <Grid item xs sm>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Peso de entrada [Kg/cab]:
                  </Typography>
                  <strong>
                    {formatNumber(animalOutput.average_input_weight, 0, 4)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Peso de saída [Kg/cab]:
                  </Typography>
                  <strong>
                    {formatNumber(animalOutput.average_output_weight, 0, 4)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    GPT [Kg/cab]:
                  </Typography>
                  <strong>
                    {formatNumber(animalOutput.total_weight_gain, 0, 4)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    GMD [Kg/cab/dia]:
                  </Typography>
                  <strong>
                    {formatNumber(animalOutput.average_daily_weight_gain, 0, 4)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Peso de carcaça [Kg/cab]:
                  </Typography>
                  <strong>
                    {formatNumber(animalOutput.average_carcass_weight, 0, 4)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    RC [%]:
                  </Typography>
                  <strong>
                    {formatNumber(animalOutput.average_carcass_yield, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    GTC [Kg/cab]:
                  </Typography>
                  <strong>
                    {formatNumber(animalOutput.total_carcass_gain, 0, 4)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    GMC [Kg/cab/dia]:
                  </Typography>
                  <strong>
                    {formatNumber(
                      animalOutput.average_daily_carcass_gain,
                      0,
                      4
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Rend. ganho [%]:
                  </Typography>
                  <strong>{formatNumber(animalOutput.gain_yield, 2, 2)}</strong>
                </Grid>
              </Grid>

              <Grid item xs sm>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Cons. MO [Kg]:
                  </Typography>
                  <strong>
                    {formatNumber(animalOutput.total_consumption_mo, 0, 4)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Cons. MS [Kg]:
                  </Typography>
                  <strong>
                    {formatNumber(animalOutput.total_consumption_ms, 0, 4)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Cons. MO/dia [Kg/cab]:
                  </Typography>
                  <strong>
                    {formatNumber(
                      animalOutput.average_daily_consumption_mo,
                      0,
                      4
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Cons. MS/dia [Kg/cab]:
                  </Typography>
                  <strong>
                    {formatNumber(
                      animalOutput.average_daily_consumption_ms,
                      0,
                      4
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Conv. Alimentar [KgMS/KgPV]:
                  </Typography>
                  <strong>
                    {formatNumber(animalOutput.food_conversion, 0, 4)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Efec. Aliementar [KgPV/KgMS]:
                  </Typography>
                  <strong>
                    {formatNumber(animalOutput.food_efficiency, 0, 4)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Cons. MS em PV [%]:
                  </Typography>
                  <strong>
                    {formatNumber(animalOutput.average_pv_consumption_ms, 0, 4)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Efec. Biológica [KgMS/@]:
                  </Typography>
                  <strong>
                    {formatNumber(animalOutput.biological_efficiency, 0, 4)}
                  </strong>
                </Grid>
              </Grid>
            </Grid>
          </StyledPaper>

          <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>

          <StyledPaper elevation={0}>
            <HeaderSection>
              <h3>Análise Financeira</h3>
            </HeaderSection>
            <Grid container>
              <Grid item xs sm>
                <SubHeader>
                  <MdSubdirectoryArrowRight size={20} />
                  <h4>Custos</h4>
                </SubHeader>
                <SubItem>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Compra [R$]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.total_input_price, 2, 2)}
                    </strong>
                  </Grid>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Compra por animal [R$/cab]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.average_input_price, 2, 2)}
                    </strong>
                  </Grid>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Operacional [R$]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.total_operational_costs, 2, 2)}
                    </strong>
                  </Grid>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Operacional por animal [R$/cab]:
                    </Typography>
                    <strong>
                      {formatNumber(
                        animalOutput.average_operational_costs,
                        2,
                        2
                      )}
                    </strong>
                  </Grid>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Sanitário [R$]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.total_sanitary_costs, 2, 2)}
                    </strong>
                  </Grid>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Sanitário por animal [R$/cab]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.average_sanitary_costs, 2, 2)}
                    </strong>
                  </Grid>
                </SubItem>
              </Grid>
              <Grid item xs sm>
                <SubItemRight>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Alimentação total [R$]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.total_feed_costs, 2, 2)}
                    </strong>
                  </Grid>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Alimentação por animal [R$/cab]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.average_feed_costs, 2, 2)}
                    </strong>
                  </Grid>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      @ colocada [R$]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.costs_arroba_placed, 2, 2)}
                    </strong>
                  </Grid>
                  <Grid container>
                    <Typography variant="body2" component="h5">
                      @ produzida [R$]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.costs_arroba_produced, 2, 2)}
                    </strong>
                  </Grid>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Custo total [R$]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.total_costs, 2, 2)}
                    </strong>
                  </Grid>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Custo por animal [R$/cab]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.average_costs, 2, 2)}
                    </strong>
                  </Grid>
                </SubItemRight>
              </Grid>
            </Grid>

            <StyledDivider light />

            <Grid container>
              <Grid item xs sm>
                <SubHeader>
                  <MdSubdirectoryArrowRight size={20} />
                  <h4>Receita</h4>
                </SubHeader>
                <SubItem>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Qtd. de venda [@/cab]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.average_arroba_animal, 2, 2)}
                    </strong>
                  </Grid>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Venda [R$/@]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.real_arroba_price, 2, 2)}
                    </strong>
                  </Grid>
                </SubItem>
              </Grid>
              <Grid item xs sm>
                <SubItemRight>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Receita total [R$]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.total_income, 2, 2)}
                    </strong>
                  </Grid>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Receita por animal [R$/cab]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.average_income, 2, 2)}
                    </strong>
                  </Grid>
                </SubItemRight>
              </Grid>
            </Grid>

            <StyledDivider light />

            <Grid container>
              <Grid item xs sm>
                <SubHeader>
                  <MdSubdirectoryArrowRight size={20} />
                  <h4>Balanço</h4>
                </SubHeader>
                <SubItem>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Rent. no período [%]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.period_yield, 2, 2)}
                    </strong>
                  </Grid>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Rent. no mês [%]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.month_yield, 2, 2)}
                    </strong>
                  </Grid>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Total diárias:
                    </Typography>
                    <strong>{formatNumber(animalOutput.daily, 2, 2)}</strong>
                  </Grid>
                </SubItem>
              </Grid>
              <Grid item xs sm>
                <SubItemRight>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Balanço total [R$]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.total_balance, 2, 2)}
                    </strong>
                  </Grid>
                  <Grid container alignItems="center">
                    <Typography variant="body2" component="h5">
                      Balanço por animal [R$/cab]:
                    </Typography>
                    <strong>
                      {formatNumber(animalOutput.average_balance, 2, 2)}
                    </strong>
                  </Grid>
                </SubItemRight>
              </Grid>
            </Grid>
          </StyledPaper>

          <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
        </Wrapper>
        <Wrapper>
          <StyledPaper elevation={0}>
            <HeaderSection>
              <h3>Gráfico de Custos</h3>
            </HeaderSection>
            <ContentGraphic>
              {Object.keys(animalOutput).length > 0 && (
                <Chart
                  width={500}
                  type="pie"
                  options={optionsChart}
                  series={[
                    animalOutput.total_input_price,
                    animalOutput.total_operational_costs,
                    animalOutput.total_sanitary_costs,
                    animalOutput.total_feed_costs,
                  ]}
                />
              )}
            </ContentGraphic>
          </StyledPaper>
        </Wrapper>
      </Container>
    );
  }
}

PrintAnimalOutput.propTypes = {
  animalOutput: PropTypes.shape({
    providers: PropTypes.arrayOf(PropTypes.string).isRequired,
    clients: PropTypes.arrayOf(PropTypes.string).isRequired,
    input_date: PropTypes.string.isRequired,
    output_date: PropTypes.string.isRequired,
    amount_animals: PropTypes.number.isRequired,
    average_input_weight: PropTypes.number.isRequired,
    average_output_weight: PropTypes.number.isRequired,
    total_weight_gain: PropTypes.number.isRequired,
    average_daily_weight_gain: PropTypes.number.isRequired,
    average_carcass_weight: PropTypes.number.isRequired,
    average_carcass_yield: PropTypes.number.isRequired,
    total_carcass_gain: PropTypes.number.isRequired,
    average_daily_carcass_gain: PropTypes.number.isRequired,
    gain_yield: PropTypes.number.isRequired,
    total_consumption_mo: PropTypes.number.isRequired,
    total_consumption_ms: PropTypes.number.isRequired,
    average_daily_consumption_mo: PropTypes.number.isRequired,
    average_daily_consumption_ms: PropTypes.number.isRequired,
    food_conversion: PropTypes.number.isRequired,
    food_efficiency: PropTypes.number.isRequired,
    average_pv_consumption_ms: PropTypes.number.isRequired,
    biological_efficiency: PropTypes.number.isRequired,
    total_input_price: PropTypes.number.isRequired,
    average_input_price: PropTypes.number.isRequired,
    total_operational_costs: PropTypes.number.isRequired,
    average_operational_costs: PropTypes.number.isRequired,
    total_sanitary_costs: PropTypes.number.isRequired,
    average_sanitary_costs: PropTypes.number.isRequired,
    total_feed_costs: PropTypes.number.isRequired,
    average_feed_costs: PropTypes.number.isRequired,
    total_costs: PropTypes.number.isRequired,
    average_costs: PropTypes.number.isRequired,
    average_arroba_animal: PropTypes.number.isRequired,
    costs_arroba_placed: PropTypes.number.isRequired,
    costs_arroba_produced: PropTypes.number.isRequired,
    average_arroba_price: PropTypes.number.isRequired,
    real_arroba_price: PropTypes.number.isRequired,
    average_income: PropTypes.number.isRequired,
    total_income: PropTypes.number.isRequired,
    total_balance: PropTypes.number.isRequired,
    average_balance: PropTypes.number.isRequired,
    period_yield: PropTypes.number.isRequired,
    month_yield: PropTypes.number.isRequired,
    total_day: PropTypes.number.isRequired,
    daily: PropTypes.number.isRequired,
  }).isRequired,
};

import React from 'react';
import Swal from 'sweetalert2';
import {
  TableRow,
  TableCell,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';

// Query
import { useApiV2RemoveIndividualAnimalScrapOutput } from '~/hooks/apiV2/animalHandling/IndividualAnimalScrapOutput/useApiV2RemoveIndividualAnimalScrapOutput'

import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell
} from './styles';
import ButtonTable from '~/components/ButtonTable';
import ActionLoader from '~/components/ActionLoader';

export function CardTableAnimalScrapOutput({ data, settings }) {
  const removeIndividualAnimalScrapOutput = useApiV2RemoveIndividualAnimalScrapOutput();

  function handleRemove(secure_id) {
    async function performRemoveIndividualAnimalScrapOutput() {
      await removeIndividualAnimalScrapOutput.mutateAsync(secure_id)
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar esse refugo de cocho?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar refugo de cocho!',
    }).then(async result => {
      if (result.value) {
        performRemoveIndividualAnimalScrapOutput();
      }
    });
  }

  return (
    <TableRow key={data.secure_id}>
      <TableCell align="center">
        {
          data.movementHistory[0].animal[settings.default_identification]
        }
      </TableCell>
      <TableCell align="center">
        {data.originLot.code}
      </TableCell>
      <TableCell align="center">
        {data.movementHistory[0].animal.output_obs}
      </TableCell>
      <TableCell align="center">
        {data.date}
      </TableCell>
      <Can
        checkRole={[
          'administrator',
          'owner',
          'beefer',
        ]}
        checkPermission={['animalScrapOutput_delete']}
      >
        <StyledTableCell align="right">
          <Can
            checkRole={[
              'administrator',
              'owner',
              'beefer',
            ]}
            checkPermission={[
              'animalScrapOutput_delete',
            ]}
          >
            <ButtonTable
              icon={
                removeIndividualAnimalScrapOutput.isLoading ? (
                  <ActionLoader text="Deletando" />
                ) : (
                  <DeleteIcon
                    color="error"
                  />
                )
              }
              background={colors.error}
              onClick={() =>
                handleRemove(
                  data.secure_id
                )
              }
            />
          </Can>
        </StyledTableCell>
      </Can>
    </TableRow>
  )
}

import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";
import { formatDateOnly } from "~/utils/format";

export async function getAnimalsOutput(sort, page = 1, limit = 50, date = null) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/animal-handling/individualAnimalOutput?sort=${sort}`, {
      params: { page, limit, date },
    });

    const newData = data.data.map(animalTransfer => ({
      ...animalTransfer,
      date: formatDateOnly(animalTransfer.date),
    }));

    return {
      animalsOutput: newData,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    }
  } catch {
    toast.error('Não foi possível buscar saída de animais!');
  }
}

export function useApiV2IndividualAnimalsOutput(sort, page, limit, date) {
  return useQuery(['individualAnimalOutput', sort, page, limit, date],
    () => getAnimalsOutput(sort, page, limit, date),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    });
}

import styled from 'styled-components';
import {
  Paper,
  Grid,
  Button,
  TableContainer,
  TableHead,
  TableCell,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 40px;
`;

export const StyledGrid = styled(Grid)`
  margin-bottom: 20px;
`;

export const Empty = styled.div`
  width: 98px;
`;

export const ButtonPrint = styled(Button)`
  margin-top: 10px;
  width: 94px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

export const ContentPrint = styled.div`
  display: none;
`;

export const CheckedBox = styled.div`
  border-radius: 8px;
  border: 2px solid
    ${props => (props.isReplicate ? colors.darkSecondary : colors.primary)};
  background-color: ${props =>
    props.isReplicate ? colors.secondary : colors[props.backgroundColor]};
  width: 58px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;

  p {
    margin: 0;
    text-align: center;
    font-weight: 500;
    color: ${props =>
      props.isReplicate ? colors.darkSecondary : colors[props.textColor]};
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

import {
  Grid,
  Table, TableBody, TableCell, TablePagination, TableRow, TableSortLabel
} from '@material-ui/core';
import React, { useState } from 'react';
import ButtonNew from '~/components/ButtonNew';
import Can from '~/components/Can';
import Loader from '~/components/Loader';
import { useV2GetLotsEntry } from '~/hooks/apiV2/animalHandling/LotsEntry/useV2GetLotsEntry';
import history from '~/services/history';
import { FormatDateForYearMonthDay } from '~/utils/format';
import { CardLotsEntry } from './Components/CardLotsEntry';
import Filter from './Components/Filter';
import {
  Container,
  StyledGrid, StyledTableContainer,
  StyledTableHead, StyledTablePaper
} from './styles';

export default function Input() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('date');
  const [direction, setDirection] = useState('desc');
  const [currentDate, setCurrentDate] = useState(null);
  const [currentLot, setCurrentLot] = useState(null);
  const [currentBreed, setCurrentBreed] = useState(null);

  const { data, isLoading } = useV2GetLotsEntry(page === 0 ? 1 : page, rowsPerPage, !!currentDate ? FormatDateForYearMonthDay(currentDate) : null, direction, field, currentBreed, currentLot)

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleAddAnimalInput() {
    history.push('/dashboard/animalInput/add');
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={2}>
          <Grid item xs sm md lg xl>
            <Filter
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              setCurrentBreed={setCurrentBreed}
              setCurrentLot={setCurrentLot}
            />
          </Grid>
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['animalInput_new']}
          >
            <Grid item xs="auto" sm="auto">
              <ButtonNew title="Nova" onClick={handleAddAnimalInput} />
            </Grid>
          </Can>
        </StyledGrid>

        {isLoading ? (
          <Loader />
        ) : (
          <StyledTablePaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'date'}
                        direction={field === 'date' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('date')}
                      >
                        Data
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      <TableSortLabel>
                        Lote
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      <TableSortLabel>
                        Raça
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      <TableSortLabel>
                        Sexo
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      <TableSortLabel>
                        Piquete
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      <TableSortLabel
                        active={field === 'amount_animals'}
                        direction={field === 'amount_animals' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('amount_animals')}
                      >
                        Quantidade
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right" />
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {!!data && data.lotsEntry.map(animal => (
                    <CardLotsEntry
                      key={animal.secure_id}
                      animal={animal}
                    />
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data && data.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledTablePaper>
        )}
      </Container>
    </center>
  );
}

import { compareAsc, parseISO, startOfDay } from "date-fns";
import { useQuery } from "react-query";
import apiV2 from '~/services/apiV2';
import { formatDateOnly } from '~/utils/format'

async function getV2GetLotsEntry(page, limit, date, order, field, breed, lot) {
  const { data } = await apiV2.get(`v1/feedlot/animal-handling/lots-entry`, {
    params: {
      page,
      limit,
      date,
      order,
      field,
      breed,
      lot,
    }
  })

  const lotsEntry = data.data.map(entry => ({
    secure_id: entry.secure_id,
    date: !!entry.date ? formatDateOnly(entry.date) : null,
    amount_animals: entry.amount_animals,
    lot: entry.originLot ? entry.originLot.code : '',
    breed: entry.firstAnimal.breed.name,
    sex: entry.firstAnimal.sex,
    paddock: entry.originPaddock ? entry.originPaddock.name : '',
    hasTreatments: !!entry.firstAnimal.lot.paddock.treatments[0]
      ? ((compareAsc(startOfDay(parseISO(entry.firstAnimal.lot.paddock.treatments[0].date)), startOfDay(parseISO(String(entry.date)))) === 1)
        || (compareAsc(startOfDay(parseISO(entry.firstAnimal.lot.paddock.treatments[0].date)), startOfDay(parseISO(String(entry.date)))) === 0
          && entry.firstAnimal.lot.paddock.treatments[0].performed)
      )
      : false
  }))

  return {
    lotsEntry,
    total: data.meta.total,
    perPage: data.meta.per_page,
    page: data.meta.current_page,
    lastPage: data.meta.last_page,
  }
}

export function useV2GetLotsEntry(page, limit, date, order, field, breed, lot) {
  return useQuery(
    ['LotsEntry', page, limit, date, order, field, breed, lot],
    () => getV2GetLotsEntry(page, limit, date, order, field, breed, lot))
}

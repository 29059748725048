import { useQuery } from "react-query";
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';

async function getShowManufactoringDiet(secure_id) {
  try {
    const { data } = await apiV2.get(`v1/feedlot/food-management/manufacturing-diet/${secure_id}`)

    return data
  } catch {
    toast.error('Falha ao tentar carregar dados da fabricação de dieta');
  }
}

async function getManufactoringDiet(page, rowsPerPage, direction, field, currentDiet, currentDate) {
  const { data } = await apiV2.get(`v1/feedlot/food-management/manufacturing-diet`, {
    params: {
      page,
      limit: rowsPerPage,
      direction,
      field,
      currentDiet,
      currentDate,
    }
  })

  return {
    data: data.data,
    total: data.meta.total,
    perPage: data.meta.per_page,
    page: data.meta.current_page,
    lastPage: data.meta.last_page,
  }
}

export function useV2ShowManufactoringDiet(secure_id) {
  return useQuery(
    ['V2ShowManufactoringDiet', secure_id],
    () => getShowManufactoringDiet(secure_id)
  )
}

export function useV2ManufactoringDiet(page, rowsPerPage, direction, field, currentDiet, currentDate) {
  return useQuery(
    ['manufactoringDiet', page, rowsPerPage, direction, field, currentDiet, currentDate],
    () => getManufactoringDiet(page, rowsPerPage, direction, field, currentDiet, currentDate)
  )
}

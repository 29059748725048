/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  TableCell,
  Table,
  TableBody,
  TableRow,
} from '@material-ui/core';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import Chart from 'react-apexcharts';

import {
  Container,
  Wrapper,
  Header,
  StyledPaper,
  SubHeader,
  SubItem,
  ContentChart,
  StyledTableContainer,
  StyledTableHead,
} from './styles';

import { formatNumber } from '~/utils/format';
import HeaderPrint from '~/components/HeaderPrint';

export default class PrintZootechnical extends Component {
  render() {
    const { zootechnical } = this.props;

    const optionsChartPie = {
      responsive: [
        {
          breakpoint: 400,
          options: {
            chart: {
              width: 10,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      legend: {
        formatter(val, opts) {
          return `${val} - ${Number(
            opts.w.globals.seriesPercent[opts.seriesIndex]
          ).toFixed(2)}%`;
        },
      },
    };

    const optionsChartBar = {
      chart: {
        height: 1,
      },

      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: '40%',
          columnWidth: '60%',
        },
      },
      dataLabels: {
        enabled: true,
        formatter(val) {
          return `${val}%`;
        },
      },
      xaxis: {
        categories: ['Excessivo', 'Uniforme', 'Medio', 'Escasso', 'Ausente'],
      },
    };

    return (
      <Container>
        <HeaderPrint
          title="Relatório Zootécnico de Lote - Analítico"
          filters={[
            `Data: ${String(format(new Date(), 'dd/MM/yyyy HH:mm:ss'))}`,
          ]}
        />

        <Wrapper>
          <StyledPaper>
            <Header>
              <h3>Indentificação</h3>
            </Header>
            <Grid container>
              <Grid item xs sm>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Lote:
                  </Typography>
                  <strong>
                    {zootechnical.identification &&
                      zootechnical.identification.lot}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Fornecedor:
                  </Typography>
                  <strong>
                    {zootechnical.identification &&
                      zootechnical.identification.providers
                        .map(provider => provider)
                        .join(', ')}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Cliente:
                  </Typography>
                  <strong>
                    {zootechnical.identification &&
                      zootechnical.identification.customers
                        .map(client => client)
                        .join(', ')}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Tipo de entrada:
                  </Typography>
                  <strong>
                    {zootechnical.identification &&
                      zootechnical.identification.inputsType
                        .map(inputsType => inputsType)
                        .join(', ')}
                  </strong>
                </Grid>

                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Raça:
                  </Typography>
                  <strong>
                    {zootechnical.identification &&
                      zootechnical.identification.breeds
                        .map(breed => breed)
                        .join(', ')}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Categoria:
                  </Typography>
                  <strong>
                    {zootechnical.identification &&
                      zootechnical.identification.categories
                        .map(categorie => categorie)
                        .join(', ')}
                  </strong>
                </Grid>
              </Grid>
            </Grid>
          </StyledPaper>
          <Grid container spacing={2}>
            <Grid item xs sm={4}>
              <StyledPaper style={{ minHeight: 260 }}>
                <Header>
                  <h3>Dados de Entrada</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Data Média de Entrada:
                      </Typography>
                      <strong>
                        {zootechnical.inputsData &&
                          zootechnical.inputsData.average_date}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Quantidade de Animais:
                      </Typography>
                      <strong>
                        {zootechnical.inputsData &&
                          zootechnical.inputsData.totalInputAmountAnimals}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso Vivo Total (Kg):
                      </Typography>
                      <strong>
                        {zootechnical.inputsData &&
                          formatNumber(
                            zootechnical.inputsData.totalInputWeight,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso Vivo Médio (Kg/Cab):
                      </Typography>
                      <strong>
                        {zootechnical.inputsData &&
                          formatNumber(
                            zootechnical.inputsData.averageInputWeight,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Rendimento de Carcaça (%):
                      </Typography>
                      <strong>
                        {zootechnical.inputsData &&
                          formatNumber(
                            zootechnical.inputsData.averageInputRC,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>
            <Grid item xs sm={4}>
              <StyledPaper style={{ minHeight: 260 }}>
                <Header>
                  <h3>Dados de Saída</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Data Média de Saída:
                      </Typography>
                      <strong>
                        {zootechnical.outputsData &&
                          zootechnical.outputsData.average_date}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Quantidade de Animais:
                      </Typography>
                      <strong>
                        {zootechnical.outputsData &&
                          zootechnical.outputsData.totalOutputAmountAnimals}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso Vivo Total (Kg):
                      </Typography>
                      <strong>
                        {zootechnical.outputsData &&
                          formatNumber(
                            zootechnical.outputsData.totalOutputWeight,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso Vivo Médio (Kg/Cab):
                      </Typography>
                      <strong>
                        {zootechnical.outputsData &&
                          formatNumber(
                            zootechnical.outputsData.averageOutputWeight,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Rendimento de Carcaça (%):
                      </Typography>
                      <strong>
                        {zootechnical.outputsData &&
                          formatNumber(
                            zootechnical.outputsData.averageOutputRC,
                            2,
                            2
                          )}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>
            <Grid item xs sm={4}>
              <StyledPaper style={{ minHeight: 260 }}>
                <Header>
                  <h3>Resumo de Movimentação</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Entradas (Cab):
                      </Typography>
                      <strong>
                        {zootechnical.resumeData &&
                          zootechnical.resumeData.inputs}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Transf. Entrada (Cab):
                      </Typography>
                      <strong>
                        {zootechnical.resumeData &&
                          zootechnical.resumeData.inputsTransfers}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Total Entradas (Cab):
                      </Typography>
                      <strong>
                        {zootechnical.resumeData &&
                          zootechnical.resumeData.inputs}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Saídas (Cab):
                      </Typography>
                      <strong>
                        {zootechnical.resumeData &&
                          zootechnical.resumeData.outputs}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Transf. Saída (Cab):
                      </Typography>
                      <strong>
                        {zootechnical.resumeData &&
                          zootechnical.resumeData.outputsTransfers}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Mortes (Cab):
                      </Typography>
                      <strong>
                        {zootechnical.resumeData &&
                          zootechnical.resumeData.outputsDeaths}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Refugo de Cocho (Cab):
                      </Typography>
                      <strong>
                        {zootechnical.resumeData &&
                          zootechnical.resumeData.outputsScrap}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Total Saídas (Cab):
                      </Typography>
                      <strong>
                        {zootechnical.resumeData &&
                          zootechnical.resumeData.outputsTotal}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaper>
            </Grid>
          </Grid>

          <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>

          <StyledPaper style={{ marginTop: 20 }}>
            <Header>
              <h3>Desempenho Zootécnico</h3>
            </Header>
            <Grid container>
              <Grid item xs sm>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Dias de Confinamento:
                  </Typography>
                  <strong>
                    {zootechnical.zootechPerformance.confinementDays}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Mortalidade (%):
                  </Typography>
                  <strong>{zootechnical.zootechPerformance.motality}</strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Refulgo de Cocho (%):
                  </Typography>
                  <strong>{zootechnical.zootechPerformance.refuse}</strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    GTP [Kg/cab]:
                  </Typography>
                  <strong>
                    {formatNumber(zootechnical.zootechPerformance.gtp, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    GMD [Kg/cab/dia]:
                  </Typography>
                  <strong>
                    {formatNumber(zootechnical.zootechPerformance.gmd, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Carcaça de Entrada (Kg/Cab):
                  </Typography>
                  <strong>
                    {formatNumber(
                      zootechnical.zootechPerformance.inputCarcass,
                      2,
                      2
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    @ de Entrada:
                  </Typography>
                  <strong>
                    {formatNumber(
                      zootechnical.zootechPerformance.atInput,
                      2,
                      2
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Carcaça de Saída (Kg/Cab):
                  </Typography>
                  <strong>
                    {formatNumber(
                      zootechnical.zootechPerformance.outputCarcass,
                      2,
                      2
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    @ de Saída:
                  </Typography>
                  <strong>
                    {formatNumber(
                      zootechnical.zootechPerformance.atOutput,
                      2,
                      2
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    GTC [Kg/cab]:
                  </Typography>
                  <strong>
                    {formatNumber(zootechnical.zootechPerformance.gtc, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    GMC [Kg/cab/dia]:
                  </Typography>
                  <strong>
                    {formatNumber(zootechnical.zootechPerformance.gmc, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    @ Colocada:
                  </Typography>
                  <strong>
                    {formatNumber(
                      zootechnical.zootechPerformance.atProduced,
                      2,
                      2
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Rend. ganho [%]:
                  </Typography>
                  <strong>
                    {formatNumber(
                      zootechnical.zootechPerformance.yieldGain,
                      2,
                      2
                    )}
                  </strong>
                </Grid>
              </Grid>

              <Grid item xs sm>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Total Consumo MO:
                  </Typography>
                  <strong>
                    {formatNumber(
                      zootechnical.zootechPerformance.totalConsumptionMo,
                      2,
                      2
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Total Consumo MS:
                  </Typography>
                  <strong>
                    {formatNumber(
                      zootechnical.zootechPerformance.totalConsumptionMs,
                      2,
                      2
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Cons. MO/cab/dia:
                  </Typography>
                  <strong>
                    {formatNumber(
                      zootechnical.zootechPerformance.averageDailyConsumptionMo,
                      2,
                      2
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Cons. MS/cab/dia:
                  </Typography>
                  <strong>
                    {formatNumber(
                      zootechnical.zootechPerformance.averageDailyConsumptionMs,
                      2,
                      2
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Conversão Alimentar:
                  </Typography>
                  <strong>
                    {formatNumber(
                      zootechnical.zootechPerformance.foodConversion,
                      2,
                      2
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Efeciência Aliementar:
                  </Typography>
                  <strong>
                    {formatNumber(
                      zootechnical.zootechPerformance.foodEfficiency,
                      2,
                      2
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo Médio (%PV):
                  </Typography>
                  <strong>
                    {formatNumber(
                      zootechnical.zootechPerformance.averageConsumptionMsPv,
                      2,
                      2
                    )}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Efeciência Biológica:
                  </Typography>
                  <strong>
                    {formatNumber(
                      zootechnical.zootechPerformance.biologicalEfficiency,
                      2,
                      2
                    )}
                  </strong>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs sm>
              <Grid container alignItems="center">
                <SubHeader>
                  <MdSubdirectoryArrowRight size={20} />
                  <h4>Acabamento de Carcaça</h4>
                </SubHeader>
                <SubItem>
                  <Grid item xs={2} sm>
                    {Object.keys(zootechnical).length > 0 && (
                      <ContentChart>
                        <Chart
                          type="bar"
                          options={optionsChartBar}
                          series={[
                            {
                              name: 'Porcentagem de animais',
                              data: [
                                zootechnical.carcass &&
                                  zootechnical.carcass.excessive,
                                zootechnical.carcass &&
                                  zootechnical.carcass.uniform,
                                zootechnical.carcass &&
                                  zootechnical.carcass.median,
                                zootechnical.carcass &&
                                  zootechnical.carcass.scarce,
                                zootechnical.carcass &&
                                  zootechnical.carcass.absent,
                              ],
                            },
                          ]}
                        />
                      </ContentChart>
                    )}
                  </Grid>
                </SubItem>
              </Grid>
            </Grid>
          </StyledPaper>

          {/* <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p> */}
          {/* <StyledPaper>
            <Header>
              <h3>Dietas</h3>
            </Header>
            <Grid container>
              <Grid item xs sm />
              <StyledTableContainer>
                <MdSubdirectoryArrowRight size={20} />
                <Table stickyHeader>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell>Dieta</TableCell>
                      <TableCell align="right">Dias</TableCell>
                      <TableCell align="right">Qt(kgMO)</TableCell>
                      <TableCell align="right">Qt(kgMS)</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {zootechnical.diets &&
                      zootechnical.diets.map(item => (
                        <TableRow key={item.secure_id}>
                          <TableCell>{item.diet}</TableCell>
                          <TableCell align="right">{item.days}</TableCell>
                          <TableCell align="right">
                            {item.mo.toFixed(2)}
                          </TableCell>
                          <TableCell align="right">
                            {item.ms.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell>Total</TableCell>
                      <TableCell align="right" />
                      <TableCell align="right">
                        {zootechnical.diets &&
                          zootechnical.diets
                            .reduce((totalMo, value) => {
                              return totalMo + value.mo;
                            }, 0)
                            .toFixed(2)}
                      </TableCell>
                      <TableCell align="right">
                        {zootechnical.diets &&
                          zootechnical.diets
                            .reduce((totalMo, value) => {
                              return totalMo + value.ms;
                            }, 0)
                            .toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </StyledTableHead>
                </Table>
              </StyledTableContainer>
            </Grid>
          </StyledPaper>
          <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
          <StyledPaper style={{ marginTop: 50 }}>
            <Header>
              <h3>Alimentos</h3>
            </Header>
            <Grid container>
              <Grid item xs sm />
              <StyledTableContainer>
                <MdSubdirectoryArrowRight size={20} />
                <Table stickyHeader>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell>Alimento</TableCell>
                      <TableCell align="right">Dias</TableCell>
                      <TableCell align="right">Qt(kgMO)</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {zootechnical.foods &&
                      zootechnical.foods.map(item => (
                        <TableRow key={item.secure_id}>
                          <TableCell>{item.food}</TableCell>
                          <TableCell align="right">{item.days}</TableCell>
                          <TableCell align="right">
                            {item.totalMo.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell>Total</TableCell>
                      <TableCell align="right" />
                      <TableCell align="right">
                        {zootechnical.foods &&
                          zootechnical.foods
                            .reduce((totalMo, value) => {
                              return totalMo + value.totalMo;
                            }, 0)
                            .toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </StyledTableHead>
                </Table>
              </StyledTableContainer>
            </Grid>
          </StyledPaper> */}
          {/* <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p> */}

          <StyledPaper>
            <Header>
              <h3>Dieta Média</h3>
            </Header>
            <Grid container>
              <Grid item xs={8} sm>
                <SubHeader>
                  <MdSubdirectoryArrowRight size={20} />
                  <h4>Gráfico</h4>
                </SubHeader>
                <SubItem>
                  <Grid item xs={2} sm>
                    {zootechnical.foods && (
                      <ContentChart>
                        <Chart
                          type="pie"
                          options={{
                            ...optionsChartPie,
                            labels: zootechnical.foods.map(item => {
                              return item.food;
                            }),
                          }}
                          series={zootechnical.foods.map(item => {
                            return item.totalMo;
                          })}
                        />
                      </ContentChart>
                    )}
                  </Grid>
                </SubItem>
              </Grid>
            </Grid>
          </StyledPaper>
        </Wrapper>
      </Container>
    );
  }
}

PrintZootechnical.propTypes = {
  zootechnical: PropTypes.shape().isRequired,
};

import React from 'react';
import Swal from 'sweetalert2';

import {
  TableRow
} from '@material-ui/core';

import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

import { useApiV2RemoveOcurrence } from '~/hooks/apiV2/admin/ocurrence/useApiV2RemoveOcurrence';

import ActionLoader from '~/components/ActionLoader';
import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';
import { format } from 'date-fns';

export function CardTableOcurrence({ ocurrence }) {
  // Query
  const removeOcurrence = useApiV2RemoveOcurrence();

  function handleSelect({ secure_id }) {
    history.push(`/dashboard/ocurrences/view/${secure_id}`);
  }

  function handleRemoveOcurrence({ secure_id }) {
    async function performRemoveOcurrence() {
      await removeOcurrence.mutateAsync(secure_id)
    }
    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja apagar essa ocorrência?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar ocorrência!',
    }).then(result => {
      if (result.value) {
        performRemoveOcurrence();
      }
    });
  }

  function formatLocalOcurrence({ module_name, line_name, paddock_name }) {
    if (module_name) {
      if (line_name) {
        if (paddock_name) {
          return `${module_name} - ${line_name} - ${paddock_name}`
        }

        return `${module_name} - ${line_name}`
      }

      return module_name
    }
    return 'Todos os Módulos'
  }

  return (
    <TableRow>
      <StyledTableCellEllipsis component="th" scope="row">
        {format(new Date(ocurrence.date), 'dd/MM/yyyy')}
      </StyledTableCellEllipsis>
      <StyledTableCellEllipsis>
        {ocurrence.ocurrence_type_name}
      </StyledTableCellEllipsis>
      <StyledTableCellEllipsis>
        {formatLocalOcurrence(ocurrence)}
      </StyledTableCellEllipsis>
      <Can checkRole={['administrator', 'owner', 'manager']}>
        <StyledTableCell align="right">
          {removeOcurrence.isLoading ? (
            <ActionLoader text="Deletando" />
          ) : (
            <>
              <Can
                checkRole={['administrator', 'owner', 'beefer']}
                checkPermission={['ocurrences_view']}
              >
                <ButtonTable
                  icon={<VisibilityIcon />}
                  background={colors.dark}
                  lighten={0.5}
                  onClick={() => handleSelect(ocurrence)}
                />
              </Can>
              <Can
                checkRole={['administrator', 'owner', 'beefer']}
                checkPermission={['ocurrences_delete']}
              >
                <ButtonTable
                  icon={<DeleteIcon color="error" />}
                  background={colors.error}
                  onClick={() =>
                    handleRemoveOcurrence(ocurrence)
                  }
                />
              </Can>
            </>
          )}
        </StyledTableCell>
      </Can>
    </TableRow>
  )
}

/* eslint-disable array-callback-return */
import React,
{
  // useState,
  //  useEffect,
  //   useCallback
} from 'react';
import {
  Grid,
  //  TableCell
} from '@material-ui/core';
// import {
//   addDays, isAfter, isSameDay, isWithinInterval, parseISO,
//   startOfDay, subDays
// } from 'date-fns';
// import {
//   cleanState, loadSlaughterForecastRequest
// } from '~/store/modules/reports/actions';

import {
  Container
} from './styles';

import Filter from './Components/Filter';

import Loader from '~/components/Loader';
// import {
//   formatNumber
// } from '~/utils/format';

export default function SlaughterForecast() {
  // const [date] = useState(new Date('2020/09/01'));
  // const [allDates, setAllDates] = useState([]);
  // const [allBreeds, setAllBreeds] = useState([]);
  // const [allBreedsFiltered, setAllBreedsFiltered] = useState([]);
  // const [allTypesFiltered, setAllTypesFiltered] = useState([]);
  // const [biggerFiltered, setBiggerFiltered] = useState(0);
  // const [smallFiltered, setSmallFiltered] = useState(0);
  // const [daysFiltered, setDaysFiltered] = useState('');
  // const [activeLotsFiltered, setActiveLotsFiltered] = useState([]);

  // const activeLots = useSelector(state => state.reports.data.slaughterForecast)
  // const loading = useSelector(state => state.reports.loading);
  const loading = false

  // const dispatch = useDispatch();

  // const getColorColumn = useCallback((pvd, met) => {
  //   const tenPerCent = (formatNumber(met, 2, 2) * 10) / 100;
  //   if (formatNumber(pvd, 2, 2) >= formatNumber(met, 2, 2)) {
  //     // const value = green + 1;
  //     // setGreen(value);
  //     return 'green';
  //   }
  //   if (
  //     formatNumber(pvd, 2, 2) < formatNumber(met, 2, 2) &&
  //     formatNumber(pvd, 2, 2) >= formatNumber(met, 2, 2) - tenPerCent
  //   ) {
  //     // const value = orange + 1;
  //     // setOrange(value);
  //     return 'orange';
  //   }
  //   if (formatNumber(pvd, 2, 2) < formatNumber(met, 2, 2) - tenPerCent) {
  //     // const value = red + 1;
  //     // setRed(value);
  //     return 'red';
  //   }
  //   return 'black';
  // }, []);

  // function renderLogPV({ logs, lot_id }) {
  //   const log = allDates.map(day => {
  //     const newCurrentLot = logs.find(
  //       lot =>
  //         lot.lot_id === lot_id &&
  //         isSameDay(startOfDay(parseISO(lot.date)), startOfDay(day))
  //     );

  //     if (newCurrentLot) {
  //       return (
  //         <TableCell
  //           align="right"
  //           style={{
  //             color: getColorColumn(
  //               newCurrentLot.percent_performed_pv,
  //               newCurrentLot.percent_target_pv
  //             ),
  //           }}
  //         >
  //           {newCurrentLot.percent_performed_pv}
  //         </TableCell>
  //       );
  //     }
  //     return <TableCell align="right" />;
  //   });

  //   return log;
  // }

  function handleGenerateReport() {
    console.log('inativo')
    // setAllDates([subDays(date, 2), subDays(date, 3)]);
    // dispatch(
    //   loadSlaughterForecastRequest(
    //     allBreedsFiltered,
    //     allTypesFiltered,
    //     date,
    //     daysFiltered
    //   )
    // );
  }

  // useEffect(() => {
  //   setAllDates([subDays(date, 2), subDays(date, 3)]);
  //   dispatch(
  //     loadSlaughterForecastRequest(
  //       allBreedsFiltered,
  //       allTypesFiltered,
  //       date,
  //       daysFiltered
  //     )
  //   );
  // }, [dispatch, date, allBreedsFiltered, allTypesFiltered, daysFiltered]);

  // useEffect(() => {
  //   return () => {
  //     dispatch(cleanState());
  //   };
  // }, [dispatch]);

  // useEffect(() => {
  //   // eslint-disable-next-line prefer-const
  //   let valuesWithFilter = [];
  //   if (activeLots && activeLots.length && (biggerFiltered || smallFiltered)) {
  //     activeLots.map(activity => {
  //       if (biggerFiltered && !smallFiltered) {
  //         if (activity.days_active >= biggerFiltered) {
  //           valuesWithFilter.push(activity);
  //         }
  //       } else if (!biggerFiltered && smallFiltered) {
  //         if (activity.days_active <= smallFiltered) {
  //           valuesWithFilter.push(activity);
  //         }
  //       } else if (biggerFiltered && smallFiltered) {
  //         if (
  //           activity.days_active >= biggerFiltered &&
  //           activity.days_active <= smallFiltered
  //         ) {
  //           valuesWithFilter.push(activity);
  //         }
  //       }
  //     });
  //     setActiveLotsFiltered(valuesWithFilter);
  //   } else {
  //     setActiveLotsFiltered(activeLots);
  //   }
  // }, [activeLots, biggerFiltered, smallFiltered]);

  // useEffect(() => {
  //   // eslint-disable-next-line prefer-const
  //   let valuesWithFilter = [];
  //   if (activeLots && activeLots.length && daysFiltered) {
  //     activeLots.map(activy => {
  //       if (daysFiltered === '7 dias') {
  //         if (isWithinInterval(date, { start: date, end: addDays(date, 7) })) {
  //           valuesWithFilter.push(activy);
  //         }
  //       } else if (daysFiltered === '7 - 15 dias') {
  //         if (
  //           isWithinInterval(date, {
  //             start: addDays(date, 8),
  //             end: addDays(date, 15),
  //           })
  //         ) {
  //           valuesWithFilter.push(activy);
  //         }
  //       } else if (daysFiltered === '15 - 30 dias') {
  //         if (
  //           isWithinInterval(date, {
  //             start: addDays(date, 16),
  //             end: addDays(date, 30),
  //           })
  //         ) {
  //           valuesWithFilter.push(activy);
  //         }
  //       } else if (isAfter(new Date(activy.days_active), addDays(date, 30))) {
  //         valuesWithFilter.push(activy);
  //       }
  //     });
  //     setActiveLotsFiltered(valuesWithFilter);
  //   } else {
  //     setActiveLotsFiltered(activeLots);
  //   }
  // }, [activeLots, daysFiltered, date]);

  // useEffect(() => {
  //   if (activeLots && activeLots.length) {
  //     const breedsAll = [];
  //     activeLots.map(activeLot => {
  //       const breeds = activeLot.breed_names.split(',');
  //       if (breeds) {
  //         breeds.map(breed => {
  //           const breedFind = breedsAll.find(a => a === breed.trim());
  //           if (!breedFind) {
  //             breedsAll.push(breed.trim());
  //           }
  //         });
  //       } else if (!breedsAll.find(a => a === activeLot.breed_names)) {
  //         breedsAll.push(activeLot.breed_names);
  //       }
  //     });
  //     setAllBreeds(breedsAll);
  //   }
  // }, [activeLots]);

  return (
    <>
      <center>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs sm md lg xl>
              <Filter
                // date={date}
                // allBreeds={allBreeds}
                // setAllBreeds={setAllBreedsFiltered}
                // setAllTypes={setAllTypesFiltered}
                handleGenerateReport={handleGenerateReport}
                // setBiggerValue={setBiggerFiltered}
                // setSmallValue={setSmallFiltered}
                // setDays={setDaysFiltered}
              />
            </Grid>
            {/* <Grid item xs="auto" sm="auto">
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={activeLots.length === 0}
                    startIcon={<PrintIcon />}
                  >
                    Imprimir
                  </Button>
                )}
                content={() => PrintScreen.current}
              />
            </Grid> */}
          </Grid>

          {loading ? (
            <Loader />
          ) : (
            <>
              {/* <StyledPaper>
                <StyledTableContainer>
                  <Table stickyHeader>
                    <StyledTableHead>
                      <TableRow>
                        <TableCell>Lote</TableCell>
                        <TableCell>Piquete</TableCell>
                        <TableCell>Raça</TableCell>
                        <TableCell>Categoria</TableCell>
                        <TableCell>Qtd</TableCell>
                        <TableCell align="right">Conf(dias)</TableCell>
                        <TableCell align="right">Peso atual</TableCell>
                        <StyledTableCell align="center">
                          % PV/D1
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          % PV/D2
                        </StyledTableCell>
                        <TableCell align="right">Prev.(abate)</TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <StyledTableBody>
                      {activeLotsFiltered.map(lot =>
                        lot.breed_names && lot.types ? (
                          <TableRow key={lot.secure_id}>
                            <TableCell>{lot.code}</TableCell>
                            <TableCell>{`${formatNamePaddock(
                              settings,
                              lot.treatment.paddock
                            )}`}</TableCell>
                            <StyledTableCellEllipsis>
                              {lot.breed_names}
                            </StyledTableCellEllipsis>
                            <StyledTableCellEllipsis>
                              {lot.types}
                            </StyledTableCellEllipsis>
                            <TableCell align="right">
                              {lot.amount_animals}
                            </TableCell>
                            <TableCell align="right">
                              {lot.days_active}
                            </TableCell>
                            <TableCell align="right">
                              {formatNumber(lot.average_weight, 2, 2)}
                            </TableCell>
                            {renderLogPV(lot)}
                            <TableCell align="center">
                              {lot.date_forecast_slaughter &&
                                formatDateOnly(lot.date_forecast_slaughter)}
                            </TableCell>
                          </TableRow>
                        ) : (
                          ''
                        )
                      )}
                    </StyledTableBody>
                  </Table>
                </StyledTableContainer>
              </StyledPaper> */}
            </>
          )}
        </Container>
      </center>
      {/* <ContentPrint>
        <Print
          ref={PrintScreen}
          activeLots={activeLots}
          date={date}
          allDates={allDates}
          settings={settings}
        />
      </ContentPrint> */}
    </>
  );
}

import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { lighten } from 'polished';

export const StyledIconButton = styled(IconButton)`
  color: ${props => props.background};

  &:hover {
    background: ${props => lighten(props.lighten || 0.34, props.background)};
  }
`;

import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";

import { chain } from 'lodash';

export async function getActiveLot({
  date = new Date()
}) {
  const { data } = await apiV2.get('/v1/feedlot/report/activeLot', {
    params: { date }
  });

  const groupActiveLot = chain(data.lotsActive)
    .groupBy('paddock.line.module.name')
    .map(value => {
      const line = chain(value)
        .groupBy('paddock.line.name')
        .map(valueLine => {
          const paddock = chain(valueLine).sortBy('paddock.name').value();

          return { line: paddock[0].paddock.line.name, paddocks: paddock };
        })
        .value();

      return {
        module: line[0].paddocks[0].paddock.line.module.name,
        lines: line,
      };
    })
    .value();

  return { activeLots: groupActiveLot, resumeLots: data.resumeLots };

}

export function useApiV2ActiveLot({ date }) {
  return useQuery(
    ['activeLot', date],
    () => getActiveLot({ date }),
  );
}

/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState, forwardRef, useMemo } from 'react';
import { Grid, TextField } from '@material-ui/core';
import MaterialTable, { MTableToolbar } from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import {
  Container,
  StyledButtonSave,
  StyledPaper,
  StyledSubmitButton,
} from './styles';
import colors from '~/styles/colors';
import history from '~/services/history';

// Query
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';
import { useApiV2SelectIndividualAnimalOutput } from '~/hooks/apiV2/animalHandling/IndividualAnimalOutput/useApiV2SelectIndividualAnimalOutput';
import { useApiV2EditIndividualAnimalOutput } from '~/hooks/apiV2/animalHandling/IndividualAnimalOutput/useApiV2EditIndividualAnimalOutput';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const listAnimalType = {
  '': 'Não informar',
  absent: 'Ausente',
  scarce: 'Escasso',
  median: 'Mediano',
  uniform: 'Uniforme',
  excessivo: 'Excessiva',
};

export default function EditIndividualAnimalsInfos({ match }) {
  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  const [animals, setAnimals] = useState([]);
  const [editAll, setEditAll] = useState(false);
  const [animalType, setAnimalType] = useState(null);
  const [arrobaPrice, setArrobaPrice] = useState(null);
  const [averageCarcassWeight, setAverageCarcassWeight] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [calcCarcass, setCalcCarcass] = useState(false);
  const [carcassYield, setCarcassYield] = useState(null);

  // Query
  const { data: settingsData } = useApiV2GlobalSetting();
  const { data: currentData } = useApiV2SelectIndividualAnimalOutput(secure_id);
  const editIndividualAnimalInfos = useApiV2EditIndividualAnimalOutput(
    setIsSubmitting,
    secure_id
  );

  async function handleSubmit() {
    const newAnimals = animals.map(dataAnimal => ({
      animalSecureId: dataAnimal.secure_id,
      outputWeight: dataAnimal.output_weight,
      outputAtPrice: dataAnimal.arroba_price,
      outputAverageCarcassWeight: dataAnimal.average_carcass_weight,
      outputCarcassYield: dataAnimal.carcass_yield,
    }));

    await editIndividualAnimalInfos.mutateAsync({ animals: newAnimals });
  }

  function handleEditAllAnimals() {
    setAnimals(
      animals.map(dataAnimal => ({
        ...dataAnimal,
        arroba_price: arrobaPrice,
        average_carcass_weight: averageCarcassWeight,
        animal_type: animalType,
        carcass_yield: averageCarcassWeight
          ? ((averageCarcassWeight / dataAnimal.output_weight) * 100).toFixed(2)
          : null,
      }))
    );
    setAnimalType(null);
    setArrobaPrice(null);
    setAverageCarcassWeight(null);
    setEditAll(false);
  }

  function handleCalcAverageCarcassWeight() {
    setAnimals(
      animals.map(dataAnimal => ({
        ...dataAnimal,
        average_carcass_weight: (
          (carcassYield / 100) *
          dataAnimal.output_weight
        ).toFixed(2),
        carcass_yield: carcassYield,
      }))
    );
    setCarcassYield(null);
    setCalcCarcass(false);
  }

  useEffect(() => {
    if (currentData && settingsData) {
      setAnimals(
        currentData.movementHistory.map(data => ({
          secure_id: data.animal.secure_id,
          id:
            data.animal[settingsData.settings.default_identification],
          output_weight: data.animal.output_weight,
          arroba_price: data.animal.output_at_price || '',
          average_carcass_weight:
            data.animal.output_average_carcass_weight || '',
          carcass_yield: data.animal.output_carcass_yield || '',
          animal_type: data.animal_type || '',
        }))
      );
    }
  }, [currentData, settingsData, secure_id]);

  return (
    <Container>
      {editAll && (
        <StyledPaper style={{ marginBottom: 16, marginTop: 16 }}>
          <div w="100%" style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label htmlFor="animal_type">Acabamento de carcaça</label>
                <select
                  id="animal_type"
                  name="animal_type"
                  onChange={e => setAnimalType(e.target.value)}
                >
                  <option value="null">Não informar</option>
                  <option value="absent">Ausente</option>
                  <option value="scarce">Escasso</option>
                  <option value="median">Mediano</option>
                  <option value="uniform">Uniforme</option>
                  <option value="excessivo">Excessiva</option>
                </select>
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label htmlFor="arroba_price">Preço da arroba</label>
                <input
                  id="arroba_price"
                  name="arroba_price"
                  type="number"
                  style={{ width: 100 }}
                  onChange={e => setArrobaPrice(e.target.value)}
                />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label htmlFor="average_carcass_weight">Peso da carcaça</label>
                <input
                  id="average_carcass_weight"
                  name="average_carcass_weight"
                  type="number"
                  style={{ width: 100 }}
                  onChange={e => setAverageCarcassWeight(e.target.value)}
                />
              </div>
            </div>
            <StyledButtonSave
              style={{ background: colors.blue }}
              variant="contained"
              onClick={() => handleEditAllAnimals()}
            >
              Aplicar
            </StyledButtonSave>
          </div>
        </StyledPaper>
      )}
      {calcCarcass && (
        <StyledPaper style={{ marginBottom: 16, marginTop: 16 }}>
          <div w="100%" style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-evenly',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <h3 style={{ marginRight: 10 }}>
                  Digite o rendimento da carcaça que será aplicado em todos os
                  animais:
                </h3>
                <TextField
                  id="carcass_yield"
                  name="carcass_yield"
                  variant="outlined"
                  label="Rendimento da Carcaça"
                  autoComplete="off"
                  size="small"
                  value={carcassYield}
                  onChange={e => setCarcassYield(e.target.value)}
                />
              </div>
            </div>
            <StyledButtonSave
              style={{ background: colors.blue }}
              variant="contained"
              onClick={() => handleCalcAverageCarcassWeight()}
            >
              Aplicar
            </StyledButtonSave>
          </div>
        </StyledPaper>
      )}
      <StyledPaper style={{ marginBottom: 16, marginTop: 16 }}>
        <MaterialTable
          title="Animais"
          components={{
            Toolbar: props => {
              return <MTableToolbar {...props} />;
            },
          }}
          columns={[
            { title: 'Identificador', field: 'id', editable: 'never' },
            { title: 'Peso de Saída', field: 'output_weight' },
            {
              title: 'Acab. de Carcaça:',
              field: 'animal_type',
              lookup: { ...listAnimalType },
            },
            { title: 'Preço da @ R$', field: 'arroba_price' },
            { title: 'Peso da Carcaça Kg', field: 'average_carcass_weight' },
            {
              title: 'Rend. da Carcaça %',
              field: 'carcass_yield',
              editable: 'never',
            },
          ]}
          editable={{
            onRowUpdate: (newData, oldData) =>
              new Promise((resolve, reject) => {
                const dataUpdate = [...animals];
                const index = oldData.tableData.id;
                dataUpdate[index] = {
                  ...newData,
                  carcass_yield: (
                    (newData.average_carcass_weight / newData.output_weight) *
                    100
                  ).toFixed(2),
                };
                setAnimals([...dataUpdate]);
                resolve();
              }),
          }}
          icons={tableIcons}
          localization={{
            pagination: {
              labelRowsSelect: 'animais',
              labelDisplayedRows: '{count} de {from}-{to}',
              firstTooltip: 'Primeira página',
              previousTooltip: 'Página anterior',
              nextTooltip: 'Próxima página',
              lastTooltip: 'Última página',
            },
            toolbar: {
              nRowsSelected: '{0} animais(s) selecionados',
              searchTooltip: 'Localizar Animal',
              searchPlaceholder: 'Localizar Animal',
            },
            header: {
              actions: 'Ações',
            },
            body: {
              emptyDataSourceMessage: 'Nenhum animal foi importado',
              filterRow: {
                filterTooltip: 'Filtrar',
              },
            },
          }}
          options={{
            paging: true,
            pageSize: 15,
            emptyRowsWhenPaging: true, // to make page size fix in case of less data rows
            pageSizeOptions: [15, 25, 50, 100],
            maxBodyHeight: '400px',
          }}
          data={animals}
        />

        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: 12, textAlign: 'right' }}
        >
          <StyledButtonSave
            style={{ background: '#2c86e3' }}
            variant="contained"
            // color="primary"
            onClick={() => setCalcCarcass(!editAll)}
          >
            Calc. Peso Carcaça
          </StyledButtonSave>
          <StyledButtonSave
            style={{ background: '#2c86e3' }}
            variant="contained"
            onClick={() => setEditAll(!editAll)}
          >
            {editAll ? 'Não editar todos' : 'Editar todos'}
          </StyledButtonSave>
          <StyledSubmitButton
            color="primary"
            variant="outlined"
            type="button"
            onClick={() => history.goBack()}
          >
            Cancelar
          </StyledSubmitButton>
          <StyledButtonSave
            style={{ background: colors.success }}
            variant="contained"
            disabled={isSubmitting}
            onClick={() => handleSubmit()}
          >
            Salvar
          </StyledButtonSave>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

EditIndividualAnimalsInfos.propTypes = {};

EditIndividualAnimalsInfos.defaultProps = {};

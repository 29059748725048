import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

export default function CheckBox({
  add,
  troughReading,
  treatment,
  troughSettings,
  arrayReading,
  setArrayReading,
  disabled,
}) {
  const [currentSetting, setCurrentSetting] = useState(null);

  function handleChangeSetting(setting) {
    const newReading = {
      paddock_id: treatment.paddock.secure_id,
      trough_setting_id: setting,
      treatment_id: treatment.secure_id,
    };

    const searchReading = arrayReading.find(
      read => read.treatment_id === treatment.secure_id
    );

    if (searchReading) {
      const oldArrayReading = arrayReading.filter(
        read => read.treatment_id !== treatment.secure_id
      );

      setArrayReading([...oldArrayReading, newReading]);
    } else {
      setArrayReading([...arrayReading, newReading]);
    }

    setCurrentSetting(setting);
  }

  useEffect(() => {
    if (troughReading) {
      if (arrayReading.length === 0) {
        setCurrentSetting(troughReading.trough_setting_id);
      }
    }
  }, [arrayReading.length, troughReading]);

  useEffect(() => {
    if (add) {
      const hasInArrayReading = arrayReading.find(
        read => read.treatment_id === treatment.secure_id
      );

      if (!hasInArrayReading) {
        setCurrentSetting(null);
      }
    }
  }, [add, arrayReading, treatment.secure_id]);

  return (
    <RadioGroup
      name="radio-group-settings"
      value={currentSetting}
      onChange={event => handleChangeSetting(event.target.value)}
      row
    >
      {troughSettings.filter(setting => (setting.note !== 'REPLY' && setting.note !== 'REPLAY')).map(setting => (
        <FormControlLabel
          key={setting.secure_id}
          disabled={treatment.changeDiet || treatment.replicate || disabled}
          value={setting.secure_id}
          control={<Radio color="primary" size="small" />}
          label={setting.note}
        />
      ))}
    </RadioGroup>
  );
}

CheckBox.propTypes = {
  add: PropTypes.bool.isRequired,
  troughReading: PropTypes.shape({
    secure_id: PropTypes.string,
    trough_setting_id: PropTypes.number,
  }).isRequired,
  treatment: PropTypes.shape({
    id: PropTypes.number,
    changeDeviationUp: PropTypes.bool,
    changeDeviationDown: PropTypes.bool,
    changeDiet: PropTypes.bool,
    replicate: PropTypes.bool,
    paddock: PropTypes.shape({
      id: PropTypes.number,
      line: PropTypes.shape({
        id: PropTypes.number,
        module: PropTypes.shape({
          id: PropTypes.number,
        }),
      }),
    }),
  }).isRequired,
  troughSettings: PropTypes.shape({
    map: PropTypes.func,
  }).isRequired,
  currentDate: PropTypes.string.isRequired,
  arrayReading: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setArrayReading: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

/* eslint-disable array-callback-return */
import React, { useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import {
  Grid,
  Button,
  Table,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

// Query
import { useApiV2ZootechResume } from '~/hooks/apiV2/Report/zootechResume/useApiV2ZootechResume'

import {
  Container,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableBody,
  ContentPrint,
  Header,
  StyledPaperTwo,
  StyledButton,
} from './styles';

import Print from './Print';
import Filter from './Components/Filter';

import { formatNumber } from '~/utils/format';
import Loader from '~/components/Loader';

export default function ZootechnicalLotResume() {
  const [filters, setFilters] = useState({
    initialDate: new Date(),
    finalDate: new Date()
  })
  const [lotsHidden, setLotsHidden] = useState([]);

  //Query
  const { data, isLoading } = useApiV2ZootechResume({ ...filters, lotsHidden });

  const PrintScreen = useRef();

  return (
    <>
      <center>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs sm md lg xl>
              <Filter
                setFilters={setFilters}
              />
            </Grid>
            <Grid item xs="auto" sm="auto">
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={data && data.zootechnicalLots.length === 0}
                    startIcon={<PrintIcon />}
                  >
                    Imprimir
                  </Button>
                )}
                content={() => PrintScreen.current}
              />
              <div style={{ marginTop: 10 }}>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Exportar"
                  style={{
                    padding: 0,
                  }}
                />
              </div>
            </Grid>
          </Grid>

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <StyledPaper>
                {lotsHidden && lotsHidden.length > 0 && (
                  <Grid item xs={12} sm>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        setLotsHidden([])
                      }
                    >
                      Mostrar lotes
                    </StyledButton>
                  </Grid>
                )}
                <StyledTableContainer>
                  <Table stickyHeader id="table-to-xls">
                    <StyledTableHead>
                      <TableRow>
                        <TableCell>Lote</TableCell>
                        <TableCell colSpan="4">Entrada</TableCell>
                        <TableCell colSpan="5">Saída</TableCell>
                        <TableCell colSpan="2">Movimentação</TableCell>
                        <TableCell colSpan="11">
                          Desempenho zootécnico
                        </TableCell>
                        <TableCell colSpan="5">Acabamento de Carcaça</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Ocultar Lote</TableCell>
                        <TableCell>Lote</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Qtd</TableCell>
                        <TableCell>Peso vivo/an</TableCell>
                        <TableCell>Peso car/an</TableCell>
                        <TableCell>Data</TableCell>
                        <TableCell>Qtd</TableCell>
                        <TableCell>Peso vivo/an</TableCell>
                        <TableCell>Peso car/an</TableCell>
                        <TableCell>% RC</TableCell>
                        <TableCell>Mortes</TableCell>
                        <TableCell>Refugo do cocho</TableCell>
                        <TableCell>Dias de confinamento</TableCell>
                        <TableCell>GMD</TableCell>
                        <TableCell>GMC</TableCell>
                        <TableCell>@ produzidas</TableCell>
                        <TableCell>Rendimento do ganho</TableCell>
                        <TableCell>Cons. MO/dia</TableCell>
                        <TableCell>Cons. MS/dia</TableCell>
                        <TableCell>Conversão alimentar</TableCell>
                        <TableCell>Eficiência alimentar</TableCell>
                        <TableCell>Consumo médio (%PV)</TableCell>
                        <TableCell>Eficiencia biológica</TableCell>
                        <TableCell>Ausente</TableCell>
                        <TableCell>Escasso</TableCell>
                        <TableCell>Mediano</TableCell>
                        <TableCell>Uniforme</TableCell>
                        <TableCell>Excessivo</TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <StyledTableBody>
                      {data && data.zootechnicalLots.map(resum => (
                        <TableRow key={resum.lot_secure_id}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              defaultChecked={false}
                              onClick={e => {
                                if (e.target.checked === false) {
                                  setLotsHidden(
                                    lotsHidden.filter(
                                      value => value !== resum.lot_secure_id
                                    )
                                  );
                                } else {
                                  setLotsHidden([
                                    ...lotsHidden,
                                    resum.lot_secure_id,
                                  ]);
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell>{resum.lot_code}</TableCell>
                          <TableCell>{resum.input_media_date}</TableCell>
                          <TableCell>
                            {resum.input_amount_animals}
                          </TableCell>
                          <TableCell>
                            {formatNumber(resum.input_average_weight, 2, 2)}
                          </TableCell>
                          <TableCell>
                            {formatNumber(resum.input_carcass, 2, 2)}
                          </TableCell>
                          <TableCell>{resum.output_media_date}</TableCell>
                          <TableCell>
                            {resum.output_amount_animals}
                          </TableCell>
                          <TableCell>
                            {formatNumber(
                              resum.output_average_weight,
                              2,
                              2
                            )}
                          </TableCell>
                          <TableCell>
                            {formatNumber(
                              resum.output_animal_carcass_weight,
                              2,
                              2
                            )}
                          </TableCell>
                          <TableCell>
                            {formatNumber(resum.output_carcass_yield, 2, 2)}
                          </TableCell>
                          <TableCell>{resum.deaths}</TableCell>
                          <TableCell>{resum.trough_refuse}</TableCell>
                          <TableCell>{resum.confinament_days}</TableCell>
                          <TableCell>
                            {formatNumber(resum.gmd, 2, 2)}
                          </TableCell>
                          <TableCell>
                            {formatNumber(resum.gmc, 2, 2)}
                          </TableCell>
                          <TableCell>
                            {formatNumber(resum.arroba_produced, 2, 2)}
                          </TableCell>
                          <TableCell>
                            {formatNumber(resum.yield_gain, 2, 2)}
                          </TableCell>
                          <TableCell>
                            {formatNumber(
                              resum.consumption_mo / resum.confinament_days,
                              2,
                              2
                            )}
                          </TableCell>
                          <TableCell>
                            {formatNumber(
                              resum.consumption_ms / resum.confinament_days,
                              2,
                              2
                            )}
                          </TableCell>
                          <TableCell>
                            {formatNumber(resum.food_conversion, 2, 2)}
                          </TableCell>
                          <TableCell>
                            {formatNumber(resum.food_efficiency, 2, 2)}
                          </TableCell>
                          <TableCell>
                            {formatNumber(
                              resum.average_pv_consumption_ms,
                              2,
                              2
                            )}
                          </TableCell>
                          <TableCell>
                            {formatNumber(
                              resum.biological_efficiency,
                              2,
                              2
                            )}
                          </TableCell>
                          <TableCell>
                            {formatNumber(resum.absent, 2, 2)}
                          </TableCell>
                          <TableCell>
                            {formatNumber(resum.scarce, 2, 2)}
                          </TableCell>
                          <TableCell>
                            {formatNumber(resum.median, 2, 2)}
                          </TableCell>
                          <TableCell>
                            {formatNumber(resum.uniform, 2, 2)}
                          </TableCell>
                          <TableCell>
                            {formatNumber(resum.excessivo, 2, 2)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </StyledTableBody>
                  </Table>
                </StyledTableContainer>
              </StyledPaper>
              <StyledPaperTwo>
                <Header>
                  <h3>Resumo</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Total de lotes encerrados:
                      </Typography>
                      <strong>{data && data.zootechnicalLots.length}</strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Entrada de Animais (total):
                      </Typography>
                      <strong>{data && data.zootechnicalResume.inputAnimalsTotal}</strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Animais abatidos:
                      </Typography>
                      <strong>{data && data.zootechnicalResume.outputAnimalsTotal}</strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Mortes:
                      </Typography>
                      <strong>{data && data.zootechnicalResume.deaths}</strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Mortalidade (%):
                      </Typography>
                      <strong>{data && data.zootechnicalResume.deathPerCent}</strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography ypography variant="body2" component="h5">
                        Refugo do cocho:
                      </Typography>
                      <strong>{data && data.zootechnicalResume.troughRefuse}</strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography ypography variant="body2" component="h5">
                        Refugo do cocho (%):
                      </Typography>
                      <strong>{data && data.zootechnicalResume.troughRefusePerCent}</strong>
                    </Grid>
                  </Grid>
                  <Grid item xs ms>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso vivo de entrada (kgPV/an):
                      </Typography>
                      <strong>
                        {data && formatNumber(data.zootechnicalResume.averageWeightInput, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso vivo de entrada (@/an):
                      </Typography>
                      <strong>
                        {data && formatNumber(
                          data.zootechnicalResume.averageCarcassInputWeightPerArroba,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso carcaça entrada (kgPV/an):
                      </Typography>
                      <strong>
                        {data && formatNumber(
                          data.zootechnicalResume.averageCarcassInputWeight,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginTop: 24 }}
                    >
                      <Typography variant="body2" component="h5">
                        Peso vivo de saída (kgPV/an):
                      </Typography>
                      <strong>
                        {data && formatNumber(data.zootechnicalResume.averageWeightOutput, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso de carcaça (Kg/an):
                      </Typography>
                      <strong>
                        {data && formatNumber(data.zootechnicalResume.averageCarcassOutputWeight, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso de carcaça (@/an):
                      </Typography>
                      <strong>
                        {data && formatNumber(
                          data.zootechnicalResume.averageCarcassOutputWeightPerArroba,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginTop: 24 }}
                    >
                      <Typography variant="body2" component="h5">
                        Dias médio de confinamento:
                      </Typography>
                      <strong>
                        {data && formatNumber(
                          data.zootechnicalResume.averageConfinementDays,
                          0,
                          0
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Ganho médio diário:
                      </Typography>
                      <strong>
                        {data && formatNumber(data.zootechnicalResume.averageGmd, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Ganho médio de carcaça:
                      </Typography>
                      <strong>
                        {data && formatNumber(data.zootechnicalResume.averageGmc, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Rendimento de carcaça (%):
                      </Typography>
                      <strong>
                        {data && formatNumber(
                          data.zootechnicalResume.carcassYield,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>

                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Ganho (@/an):
                      </Typography>
                      <strong>
                        {data && formatNumber(
                          data.zootechnicalResume.gain,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Rendimento do ganho (%)
                      </Typography>
                      <strong>
                        {data && formatNumber(
                          data.zootechnicalResume.earningIncome,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                  </Grid>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Consumo MO/an/dia:
                      </Typography>
                      <strong>
                        {data && formatNumber(data.zootechnicalResume.consumeMoPerDay, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Consumo MS/an/dia:
                      </Typography>
                      <strong>
                        {data && formatNumber(data.zootechnicalResume.consumeMsPerDay, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Consumo médio (%pv):
                      </Typography>
                      <strong>
                        {data && formatNumber(data.zootechnicalResume.averageConsumption, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Conversão alimentar (kgMs/kgPv):
                      </Typography>
                      <strong>
                        {data && formatNumber(
                          data.zootechnicalResume.averageFoodConversion,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Eficiência biológica (kgMs/@):
                      </Typography>
                      <strong>
                        {data && formatNumber(
                          data.zootechnicalResume.averageEfficiencyBiological,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography ypography variant="body2" component="h5">
                        Acabamento de carcaça (Ausente):
                      </Typography>
                      <strong>
                        {data && formatNumber(data.zootechnicalResume.ausentCarcass / data.zootechnicalResume.outputAnimalsTotal, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography ypography variant="body2" component="h5">
                        Acabamento de carcaça (Escasso):
                      </Typography>
                      <strong>
                        {data && formatNumber(data.zootechnicalResume.escassCarcass / data.zootechnicalResume.outputAnimalsTotal, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography ypography variant="body2" component="h5">
                        Acabamento de carcaça (Mediano):
                      </Typography>
                      <strong>
                        {data && formatNumber(data.zootechnicalResume.medianCarcass / data.zootechnicalResume.outputAnimalsTotal, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography ypography variant="body2" component="h5">
                        Acabamento de carcaça (Uniforme):
                      </Typography>
                      <strong>
                        {data && formatNumber(
                          data.zootechnicalResume.uniformCarcass / data.zootechnicalResume.outputAnimalsTotal,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography ypography variant="body2" component="h5">
                        Acabamento de carcaça (Excessivo):
                      </Typography>
                      <strong>
                        {data && formatNumber(
                          data.zootechnicalResume.excessiveCarcass / data.zootechnicalResume.outputAnimalsTotal,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaperTwo>
            </>
          )}
        </Container>
      </center>
      {data && (
        <ContentPrint>
          <Print
            ref={PrintScreen}
            date={new Date()}
            data={data}
            initialDate={filters.initialDate}
            finalDate={filters.finalDate}
          />
        </ContentPrint>
      )}
    </>
  );
}

import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import { queryClient } from '~/services/queryClient';

export function useApiV2EditSetting() {
  return useMutation(
    async (data) => {
      await apiV2.put(`/v1/feedlot/user/setting`, data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        toast.success('Configurações alteradas com sucesso!');
      },
      onError: (error) => {
        const hasErrors = error?.response?.data?.errors?.find(error => error.rule === "required")
        if (hasErrors) {
          toast.error(hasErrors.message)
        } else {
          const type = error.response.data?.type ? error.response.data.type : 'error'
          const message = error.response.data?.message ? error.response.data.message : 'Falha ao tentar alterar configurações.'
          toast[type](message)
        }
      }
    }
  );
}

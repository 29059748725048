import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { Grid, TextField } from '@material-ui/core';

// Query
import { useApiV2SelectOcurrenceType } from '~/hooks/apiV2/register/ocurrenceType/useApiV2SelectOcurrenceType'
import { useApiV2EditOcurrenceType } from '~/hooks/apiV2/register/ocurrenceType/useApiV2EditOcurrenceType'

import {
  Container,
  StyledPaper,
  ContentItem,
  Error,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';

import history from '~/services/history';
import ButtonSubmit from '~/components/ButtonSubmit';
import Loader from '~/components/Loader';

const schema = Yup.object().shape({
  name: Yup.string().required('Informe o nome'),
  description: Yup.string().required('Informe a descrição'),
});

export default function EditOcurrencesType({ match }) {
  const secure_id = useMemo(() => match && match.params.id, [match]);

  // Query
  const { data, isLoading, error } = useApiV2SelectOcurrenceType(secure_id);
  const editcurrencesType = useApiV2EditOcurrenceType();

  async function handleSubmit(data) {
    await editcurrencesType.mutateAsync(data)
  }

  function handleBack() {
    history.goBack();
  }

  if (error) {
    history.push('/dashboard/ocurrencesType');
    toast.error('Falha para carregar os dados do tipo!');

    return (
      <Container>
        <p>Não foi possível carregar o tipo!</p>
      </Container>
    )
  }

  return (
    <center>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            validationSchema={schema}
            onSubmit={handleSubmit}
            initialValues={{
              secure_id: secure_id,
              name: (data && data.currentOcurrenceType) ? data.currentOcurrenceType.name : '',
              description: (data && data.currentOcurrenceType) ? data.currentOcurrenceType.description : '',
            }}
          >
            {({ handleChange, errors, touched, isSubmitting, values }) => (
              <Form>
                <ContentItem>
                  <StyledPaper>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md>
                        <TextField
                          name="name"
                          variant="outlined"
                          label="Nome *"
                          autoComplete="off"
                          size="small"
                          fullWidth
                          autoFocus
                          value={values.name}
                          error={!!errors.name && touched.name}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="name" component={Error} />
                      </Grid>
                      <Grid item xs={12} sm={12} md>
                        <TextField
                          name="description"
                          variant="outlined"
                          label="Descrição *"
                          autoComplete="off"
                          size="small"
                          fullWidth
                          value={values.description}
                          error={!!errors.description && touched.description}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="description" component={Error} />
                      </Grid>
                    </Grid>

                    <ContentSubmitButtons>
                      <ButtonSubmit title="Cadastrar" loading={isSubmitting} />
                      <StyledSubmitButton
                        variant="outlined"
                        color="primary"
                        type="button"
                        onClick={handleBack}
                      >
                        Cancelar
                      </StyledSubmitButton>
                    </ContentSubmitButtons>
                  </StyledPaper>
                </ContentItem>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </center >
  );
}

import styled from 'styled-components';
import { Paper, Button } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledPaper = styled(Paper)`
  padding: 10px;
  max-width: 800px;
  width: 100%;
  margin-bottom: 14px;

  .MuiFormControl-marginNormal {
    margin: 0px;
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 18px;
`;

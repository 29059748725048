import styled from 'styled-components';
import { Paper, Grid, FormControl, Button } from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 960px;
  width: 100%;
  padding: 40px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 20px;

  text-align: left;
`;

export const StyledGridItem = styled(Grid)`
  margin: 0 10px;
  display: flex;
  text-align: center;
  flex-direction: column;
  white-space: nowrap;
`;

export const StyledFormControl = styled(FormControl)`
  margin-top: 8px;
`;

export const ContentItem = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.marginTop || 24}px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

export const Error = styled.span`
  margin: 8px 14px 0;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

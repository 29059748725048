import styled, { css } from 'styled-components';
import {
  Paper,
  TableHead,
  TableCell,
  Grid,
  Divider,
  Button,
  IconButton,
} from '@material-ui/core';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 1300px;
  width: 90%;
  padding: 40px 0px;
`;

export const GridHeader = styled(Grid)`
  margin-bottom: 30px;
  width: 100%;

  h1 {
    color: ${colors.primary};
    font-size: 20px;
    margin-bottom: 20px;
  }
`;

export const HeaderPaper = styled(Paper)`
  padding: 15px 0;
  margin: auto;
  width: 100%;
`;

export const StyledGrid = styled(Grid)`
  text-align: left;

  margin-bottom: ${props => props.marginBottom || 16}px;
  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop || 0}px;
    `}
`;

export const ShowInfo = styled.div`
  max-width: 1000px;
  padding: 4px;
  text-align: left;
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: 5px;
  color: ${colors.white};
  background: ${colors.success};
  transition: background 1s;

  &:hover {
    background: ${darken(0.06, colors.success)};
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 0px 16px;
`;

export const ContentForm = styled.div`
  max-width: 1000px;
  padding: 4px;
  text-align: left;

  h3 {
    color: ${colors.primary};
    font-weight: 400;
    text-align: center;
    margin-bottom: 16px;
  }
`;

export const Error = styled.span`
  margin: 8px 14px 0;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

export const ContentButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 32px 16px 16px;
`;

export const StyledButton = styled(Button)`
  margin-top: 8px;
  margin-left: 12px;
  background: ${props => props.background};
  width: 150px;

  &:hover {
    background: ${props => props.background};
  }
  span {
    color: ${props => props.spanColor || colors.white};
  }
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: ${colors.primary};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

export const StyledButtonClose = styled(Button)`
  margin: 0px;
  color: ${colors.error};

  &:hover {
    background: ${props => props.background};
  }
`;

import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import Can from "~/components/Can";
import Filter from './Components/Filter';
import {
  Container,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
} from './styles';
import SplitButton from '~/components/SplitButton';
import history from '~/services/history';
import { useApiV2IndividualIntermediateWeight } from '~/hooks/apiV2/animalHandling/IntermediateWeightIndividual/useApiV2IndividualIntermediateWeight';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';
import Loader from '~/components/Loader';
import { CardTableAnimalIndividual } from './Components/CardTableAnimalIndividual';

export default function IntermediateWeightAnimal() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('date');
  const [direction, setDirection] = useState('desc');
  const [date, setDate] = useState(null);
  const [lot, setLot] = useState(null);

  const { data, isLoading } = useApiV2IndividualIntermediateWeight(page + 1, rowsPerPage, date, lot, field, direction)
  const { data: settings } = useApiV2GlobalSetting();

  function handleAdd() {
    history.push('/dashboard/intermediateWeightAnimal/add');
  }

  function handleAddInputWeightCorrection() {
    history.push('/dashboard/intermediateWeightAnimal/addInputWeightCorrectionAnimal');
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  return (
    <center>
      <Container>
        <Grid container spacing={2} style={{ marginBottom: 30 }}>
          <Grid item sm>
            <Filter
              date={date}
              setDate={setDate}
              setOriginLot={setLot}
            />
          </Grid>
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['intermediateWeight_new']}
          >
            <Grid item sm="auto" xs="auto">
              <SplitButton
                options={[
                  { title: 'Nova pesagem inter.', onClick: handleAdd, value: 'intermediateWeightAnimal' },
                  { title: 'Nova correção de peso de entrada', onClick: handleAddInputWeightCorrection, value: 'inputWeightCorrectionAnimal' }
                ]}
              />
            </Grid>
          </Can>
        </Grid>
        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell align="center">
                      Piquete
                    </TableCell>
                    <TableCell align="center">
                      Lote
                    </TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={field === 'old_average_weight'}
                        direction={field === 'old_average_weight' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('old_average_weight')}
                      >
                        Peso Vivo Anterior
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={field === 'new_average_weight'}
                        direction={field === 'new_average_weight' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('new_average_weight')}
                      >
                        Peso Vivo Novo
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'obs'}
                        direction={field === 'obs' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('obs')}
                      >
                        Observação
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'type'}
                        direction={field === 'type' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('type')}
                      >
                        Tipo
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={field === 'date'}
                        direction={field === 'date' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('date')}
                      >
                        Data da pesagem
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={field === 'created_at'}
                        direction={field === 'created_at' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('created_at')}
                      >
                        Data da correção
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {settings && data &&
                    data.intermediateWeight
                      .map(correction => (
                        <CardTableAnimalIndividual
                          key={correction.secure_id}
                          correction={correction}
                          settings={settings}
                        />
                      ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}
      </Container>
    </center>
  )
}
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import RouteWrapper from './Route';
import SignIn from '~/pages/SignIn';
import Forgot from '~/pages/Forgot';
import ResetPassword from '~/pages/ResetPassword';
import DashboardRoutes from './dashboard';
import NotFound from '~/pages/NotFound';
import Maintenance from '~/pages/Maintenance';
import Clear from '~/pages/Clear';

export default function Routes() {

  if(process.env.REACT_APP_IS_MAINTENANCE === 'verdade') {
    return (
      <Switch>
        <Route path="/" component={Maintenance} />
      </Switch>
    );
  }

  return (
    <Switch>
      <RouteWrapper path="/" exact component={SignIn} />
      <Route path="/dashboard" component={DashboardRoutes} />
      <RouteWrapper path="/forgot" exact component={Forgot} />
      <RouteWrapper
        path="/resetPassword/:token"
        exact
        component={ResetPassword}
      />
      <Route path="/clear" exact component={Clear} />
      <Route path="/" component={NotFound} />
    </Switch>
  );
}

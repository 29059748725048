import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2"

export async function getOcurrences(sort, page = 1, limit = 50) {
  const { data } = await apiV2.get(`/v1/feedlot/admin/ocurrence?sort=${sort}`, {
    params: {
      page,
      limit
    }
  })

  return {
    ocurrences: data.data,
    total: data.meta.total,
    perPage: data.meta.per_page,
    page: data.meta.current_page,
    lastPage: data.meta.last_page,
  }
}

export function useApiV2Ocurrence(sort, page, limit) {
  return useQuery(
    ['ocurrences', sort, page, limit],
    () => getOcurrences(sort, page, limit),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

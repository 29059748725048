import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2AddBreed(closeModal) {
  return useMutation(
    async (data) => {
      await apiV2.post('/v1/feedlot/register/breed', data)
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries('breeds');
        toast.success('A raça foi cadastrada com sucesso!');

        if (closeModal) {
          closeModal();
        } else {
          history.push('/dashboard/breed');
        }
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao tentar cadastrar a raça.'
        toast[type](message)
      }
    }
  );
}

import { format } from 'date-fns';

export function handleSortArray(
  fieldOrder,
  list,
  setList,
  field,
  setField,
  direction,
  setDirection
) {
  const listSort = [...list];

  if (field === fieldOrder) {
    if (direction === 'asc') {
      setDirection('desc');
    } else {
      setDirection('asc');
    }

    listSort.sort((current, old) => {
      const newCurrent =
        typeof current === 'string'
          ? current[fieldOrder].toLowerCase()
          : current;
      const newOld =
        typeof old === 'string' ? old[fieldOrder].toLowerCase() : old;

      if (direction === 'asc') {
        return newCurrent < newOld ? 1 : -1;
      }

      return newCurrent > newOld ? 1 : -1;
    });
  } else {
    listSort.sort((current, old) => {
      const newCurrent =
        typeof current === 'string'
          ? current[fieldOrder].toLowerCase()
          : current;
      const newOld =
        typeof old === 'string' ? old[fieldOrder].toLowerCase() : old;

      return newCurrent > newOld ? 1 : -1;
    });

    setField(fieldOrder);
    setDirection('asc');
  }

  setList(listSort);
}

export const groupByDay = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = format(new Date(obj[key]), 'yyyy-MM-dd');
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

export const regexDate = new RegExp(
  String.raw`^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec)))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)(?:0?2|(?:Feb))\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$`
);

import { useQuery } from "react-query";
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';

export async function getBreeds() {
  try {
    const { data } = await apiV2.get(`v1/feedlot/global/list/breed`)

    return {
      breeds: data
    }
  } catch {
    toast.error('Falha ao tentar carregar as raças!');
  }
}

export function useApiV2GlobalBreeds() {
  return useQuery(
    ['breeds',],
    () => getBreeds(),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';

// Query
import { useApiV2MsCorrection } from '~/hooks/apiV2/foodManagement/msCorrection/useApiV2MsCorrection';

import {
  Container,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
} from './styles';

import history from '~/services/history';
import ButtonNew from '~/components/ButtonNew';
import Loader from '~/components/Loader';

import Filter from './Components/Filter';
import Can from '~/components/Can';
import { CardTableMSCorrection } from './Components/CardTableMSCorrection';

export default function MSCorrection() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('date');
  const [direction, setDirection] = useState('desc');
  const [date, setDate] = useState(null);
  const [food, setFood] = useState(null);

  // Query
  const { data, isLoading } = useApiV2MsCorrection(`${field}%7C${direction}`, page + 1, rowsPerPage, food, date);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleChangeDate(newDate, value) {
    const isDate = !value || value.split('_').length === 1;

    if (isDate) {
      setDate(newDate);
    }
  }

  function handleAdd() {
    history.push('/dashboard/msCorrection/add');
  }

  return (
    <center>
      <Container>
        <Grid container spacing={2}>
          <Grid item sm xs={8}>
            <Filter
              setFood={setFood}
              date={date}
              handleChangeDate={handleChangeDate}
            />
          </Grid>
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['msCorrection_new']}
          >
            <Grid item sm="auto" xs="auto">
              <ButtonNew title="Nova" onClick={handleAdd} />
            </Grid>
          </Can>
        </Grid>

        {isLoading  ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'food.name'}
                        direction={field === 'food.name' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('food.name')}
                      >
                        Alimento
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={field === 'percent_ms'}
                        direction={field === 'percent_ms' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('percent_ms')}
                      >
                        % MS
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={field === 'date'}
                        direction={field === 'date' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('date')}
                      >
                        Data da alteração
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {data && data.msCorrections
                    .map(msCorrection => (
                      <CardTableMSCorrection
                        key={msCorrection.secure_id}
                        msCorrection={msCorrection}
                      />
                    ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';

import {
  Container,
  Header,
  Column,
  StyledTablePaper,
  StyledTableHead,
  StyledTableCell,
  StyledTableCellColor,
} from './styles';

import { formatNumber, formatDateOnly, formatMoney } from '~/utils/format';
import colors from '~/styles/colors';
import logo from '~/assets/images/logo_black.png';

export default class PrintActiveLot extends Component {
  render() {
    const { list, currentFood, initialDate, finalDate } = this.props;

    function defineTypeMovement({ type }) {
      switch (type) {
        case 'purchase_food':
          return 'Compra';
        case 'manufactoring_diet':
          return 'Fabricação Dieta';
        case 'manufactoring_feed':
          return 'Fabricação de Mistura/Ração';
        case 'correction':
          return 'Correção';
        case 'system':
          return 'Sistema';
        default:
          return null;
      }
    }

    function defineColor({ amount_ton, type, type_movement }) {
      if (amount_ton !== 0 && type !== 'correction') {
        if (type_movement === 'input') {
          return colors.success;
        }

        return colors.error;
      }

      return null;
    }

    function definePrefix({ amount_ton, type, type_movement }) {
      if (amount_ton !== 0 && type !== 'correction') {
        if (type_movement === 'input') {
          return '+';
        }

        return '-';
      }

      return '';
    }

    return (
      <Container>
        <Header>
          <img src={logo} alt="logo" />
          <Column>
            <Typography variant="h5" align="center">
              Estoque de Alimento
            </Typography>
            <Typography variant="h6" align="center">
              {currentFood && currentFood.name}
            </Typography>
          </Column>
          <Column>
            <Typography variant="subtitle2">{`Data: ${String(
              format(new Date(), 'dd/MM/yyyy HH:mm:ss')
            )}`}</Typography>
            <Typography variant="subtitle2">{`Data Inicial: ${String(
              format(initialDate, 'dd/MM/yyyy')
            )}`}</Typography>
            <Typography variant="subtitle2">{`Data Final: ${String(
              format(finalDate, 'dd/MM/yyyy')
            )}`}</Typography>
          </Column>
        </Header>

        <StyledTablePaper elevation={0}>
          <TableContainer>
            <Table stickyHeader>
              <StyledTableHead>
                <TableRow>
                  <TableCell>Data</TableCell>
                  <TableCell align="left">Tipo</TableCell>
                  <TableCell>Nome</TableCell>
                  <StyledTableCell align="right">Qtd. (ton)</StyledTableCell>
                  <StyledTableCell align="right">Preço/Ton</StyledTableCell>
                  <StyledTableCell align="right">Preço</StyledTableCell>
                  <StyledTableCell align="right">
                    Qtd. Atual (ton)
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    Preço/Ton Atual
                  </StyledTableCell>
                  <StyledTableCell align="right">Preço Atual</StyledTableCell>
                </TableRow>
              </StyledTableHead>

              <TableBody>
                {list.map(movement => (
                  <TableRow key={movement.secure_id}>
                    <TableCell component="th" scope="row">
                      {formatDateOnly(movement.date)}
                    </TableCell>
                    <TableCell align="left">
                      {defineTypeMovement(movement)}
                    </TableCell>
                    <TableCell>{movement.food.name}</TableCell>
                    <StyledTableCellColor
                      align="right"
                      color={defineColor(movement)}
                    >
                      {`${definePrefix(movement)} ${formatNumber(
                        movement.amount_ton,
                        4,
                        4
                      )}`}
                    </StyledTableCellColor>
                    <StyledTableCell align="right">
                      {`R$ ${formatNumber(movement.price_ton_freight, 2, 2)}`}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formatMoney(movement.total_price)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formatNumber(movement.current_total_amount_ton, 4, 4)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formatMoney(movement.current_price_ton)}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      {formatMoney(movement.current_total_price)}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </StyledTablePaper>
      </Container>
    );
  }
}

PrintActiveLot.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentFood: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
  initialDate: PropTypes.string.isRequired,
  finalDate: PropTypes.string.isRequired,
};

import { useMutation } from 'react-query';
import { toast } from 'react-toastify'

import apiV2 from '~/services/apiV2';
import { queryClient } from '~/services/queryClient';

export function useApiV2RemoveIndividualTransferApartLot() {
  return useMutation(
    async (secure_id) => {
      const response = await apiV2.delete(`/v1/feedlot/animal-handling/individualTransferApartLot/${secure_id}`);

      return response;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();

        toast.success('Formação de lote deletado com sucesso!');
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Não foi possível apagar formação de lote!'
        toast[type](message)
      }
    }
  );
}

import React from 'react';
import Swal from 'sweetalert2';

import {
  TableCell,
  TableRow
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

import { useApiV2RemoveStakeholder } from '~/hooks/apiV2/register/stakeholder/useApiV2RemoveStakeholder';

import ActionLoader from '~/components/ActionLoader';
import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';

export function CardTableStakeholder({ stakeholder }) {
  const removeStakeholder = useApiV2RemoveStakeholder()

  function handleRemoveClient(secure_id) {
    async function performRemoveStakeholder() {
      await removeStakeholder.mutateAsync(secure_id);
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar esse registro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar registro!',
    }).then(result => {
      if (result.value) {
        performRemoveStakeholder()
      }
    });
  }

  function handleSelectStakeholder(secure_id) {
    history.push(`/dashboard/stakeholder/edit/${secure_id}`);
  }

  return (
    <TableRow>
      <StyledTableCellEllipsis component="th" scope="row">
        {stakeholder.name}
      </StyledTableCellEllipsis>
      <StyledTableCellEllipsis>
        {stakeholder.fantasy_name || ''}
      </StyledTableCellEllipsis>
      <StyledTableCell>{stakeholder.doc}</StyledTableCell>
      <StyledTableCell>
        {stakeholder.telephone}
      </StyledTableCell>
      <StyledTableCellEllipsis>
        {stakeholder.email}
      </StyledTableCellEllipsis>
      <TableCell>{stakeholder.contact_name}</TableCell>
      <TableCell>
        {stakeholder.type === 'both' && 'Cliente/Fornecedor'}
        {stakeholder.type === 'client' && 'Cliente'}
        {stakeholder.type === 'provider' && 'Fornecedor'}
      </TableCell>
      <StyledTableCell align="right" colSpan={2}>
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['stakeholder_edit']}
        >
          <ButtonTable
            icon={<EditIcon color="primary" />}
            lighten={0.6}
            background={colors.primary}
            onClick={() =>
              handleSelectStakeholder(stakeholder.secure_id)
            }
          />
        </Can>
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['stakeholder_delete']}
        >
          <ButtonTable
            icon={
              removeStakeholder.isLoading ? (
                <ActionLoader text="Deletando" />
              ) :
                (
                  <DeleteIcon color={
                    stakeholder.has_animals_input > 0 ||
                      stakeholder.has_animals_output > 0 ||
                      stakeholder.has_purchase_foods > 0 ? "disabled" : "error"
                  } />
                )
            }
            background={
              stakeholder.has_animals_input > 0 ||
                stakeholder.has_animals_output > 0 ||
                stakeholder.has_purchase_foods > 0
                ? colors.grey
                : colors.error
            }
            disabled={
              stakeholder.has_animals_input > 0 ||
              stakeholder.has_animals_output > 0 ||
              stakeholder.has_purchase_foods > 0
            }
            onClick={() =>
              handleRemoveClient(stakeholder.secure_id)
            }
          />
        </Can>
      </StyledTableCell>
    </TableRow>
  )
}

import React from 'react';
import PropTypes from 'prop-types';
import { Grid, FormControlLabel, Switch } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import {
  Content,
  StyledPaper,
  StyledGridItem,
  StyledIconButton,
} from './styles';

import { regexDate } from '~/utils/common';
import InputDatePicker from '~/components/InputDatePicker';

export default function Filter({
  setCurrentDate,
  currentDate,
  setActiveCost,
  activeCost,
  setDateValue,
}) {
  function handleDate(date, value) {
    setDateValue(value);

    if (value && !regexDate.test(value)) return;
    setCurrentDate(date);
  }

  return (
    <Content>
      <StyledPaper>
        <Grid container alignItems="center" justify="space-between">
          <StyledGridItem item sm xs={12}>
            <FormControlLabel
              style={{ marginTop: 15 }}
              value={activeCost}
              label="Custo Ativo"
              control={<Switch color="primary" />}
              labelPlacement="end"
              onChange={() => setActiveCost(!activeCost)}
            />
          </StyledGridItem>
          <StyledGridItem item sm xs={12}>
            <InputDatePicker
              label="Data Inicial"
              maxDate={new Date()}
              selectedDate={currentDate}
              handleChangeDate={(date, value) => handleDate(date, value)}
              InputProps={{
                startAdornment: (
                  <StyledIconButton onClick={() => setCurrentDate(null)}>
                    <ClearIcon />
                  </StyledIconButton>
                ),
              }}
              InputAdornmentProps={{
                position: 'end',
              }}
            />
          </StyledGridItem>
        </Grid>
      </StyledPaper>
    </Content>
  );
}

Filter.defaultProps = {
  currentDate: null,
};

Filter.propTypes = {
  setCurrentDate: PropTypes.func.isRequired,
  currentDate: PropTypes.string,
  setActiveCost: PropTypes.func.isRequired,
  activeCost: PropTypes.string.isRequired,
  setDateValue: PropTypes.string.isRequired,
};

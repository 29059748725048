import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import { queryClient } from '~/services/queryClient';

export function useApiV2RecalculateFood() {
  return useMutation(
    async () => {
      await apiV2.put(`/v1/feedlot/register/food/recalculate/foods`)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
        toast.success('Recálculo feito com sucesso!');
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao tentar realizar o recálculo.'
        toast[type](message)
      }
    }
  );
}

import React, { useState } from 'react';

// MUI
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';

// Icons
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import ButtonTable from '~/components/ButtonTable';

// Styled Components
import {
  Container,
  StyledGrid,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
  StyledTableCellEllipsis,
  ContentButton,
  StyledAddButton,
} from './styles';

// Local
import Loader from '~/components/Loader';
import history from '~/services/history';
import colors from '~/styles/colors';
import Can from '~/components/Can';

import { useApiV2User } from '~/hooks/apiV2/register/user/useApiV2User';
import { CardTableUser } from './Components/CardTableUser';

export default function Employee() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('name');
  const [direction, setDirection] = useState('asc');

  const { data: users, isLoading } = useApiV2User(`${field}%7C${direction}`, page + 1, rowsPerPage);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleAdd() {
    history.push('/dashboard/users/add');
  }

  // function handleRemoveEmployee(id) {
  //   Swal.fire({
  //     title: 'Você tem certeza ?',
  //     text: 'Está ação não tem volta! Deseja remover este funcionário?',
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: colors.lightPrimary,
  //     cancelButtonColor: colors.grey,
  //     confirmButtonText: 'Ok, remover funcionário!',
  //   }).then(result => {
  //     if (result.value) {
  //       dispatch(removeEmployee(id));
  //     }
  //   });
  // }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={1}>
          <Grid item sm md lg xl />

          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['user_new']}
          >
            <Grid item xs={12} sm={5} md={5} lg={3} xl>
              <ContentButton>
                <StyledAddButton
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAdd}
                >
                  Novo
                </StyledAddButton>
              </ContentButton>
            </Grid>
          </Can>
        </StyledGrid>
        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'name'}
                        direction={field === 'name' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('name')}
                      >
                        Nome
                      </TableSortLabel>
                    </TableCell>

                    <TableCell>
                      <TableSortLabel
                        active={field === 'email'}
                        direction={field === 'email' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('email')}
                      >
                        Email
                      </TableSortLabel>
                    </TableCell>

                    <Can checkRole={['administrator', 'owner', 'manager']}>
                      <TableCell align="right" />
                    </Can>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {users &&
                    users.users
                      .map(user => (
                        <CardTableUser
                          key={user.secure_id}
                          user={user}
                        />
                      ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={users ? users.total : 0}
              rowsPerPage={users ? users.perPage : 0}
              page={users ? users.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem, Fade } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { Container } from './styles';

import colors from '~/styles/colors';

export default function HeaderMenu({
  title,
  colorIcon,
  list,
  onClick,
  anchorElMenu,
  setAnchorElMenu,
  handleChange,
  noMenu,
}) {
  return (
    <Container colorIcon={colorIcon}>
      <span>{title}</span>
      {noMenu ? null : (
        <>
          <IconButton onClick={onClick} edge="end" aria-haspopup="true">
            <KeyboardArrowDownIcon />
          </IconButton>
          <Menu
            anchorEl={anchorElMenu}
            keepMounted
            open={Boolean(anchorElMenu)}
            onClose={() => setAnchorElMenu(null)}
            TransitionComponent={Fade}
            elevation={2}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            PaperProps={{
              style: {
                maxHeight: 190,
              },
            }}
          >
            {list.map(option => {
              if (option.visible) {
                return (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    onClick={handleChange}
                  >
                    {option.label}
                  </MenuItem>
                );
              }

              return null;
            })}
          </Menu>
        </>
      )}
    </Container>
  );
}

HeaderMenu.defaultProps = {
  title: '',
  colorIcon: colors.primary,
  noMenu: false,
};

HeaderMenu.propTypes = {
  title: PropTypes.string,
  colorIcon: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClick: PropTypes.func.isRequired,
  anchorElMenu: PropTypes.bool.isRequired,
  setAnchorElMenu: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  noMenu: PropTypes.bool,
};

import { useQuery } from "react-query";
import apiV2 from '~/services/apiV2';

async function getV2GetDataCreateLotsEntry() {
  const { data } = await apiV2.get(`v1/feedlot/animal-handling/data-create-lots-entry`)

  return data
}

export function useV2GetDataCreateLotsEntry() {
  return useQuery(
    ['GetDataCreateLotsEntry'],
    () => getV2GetDataCreateLotsEntry())
}

import React, { useEffect, useState } from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import history from '~/services/history';
import Loader from '~/components/Loader';

// Query
import { useApiV2SelectIndividualAnimalTransfer } from '~/hooks/apiV2/animalHandling/IndividualAnimalTransfer/useApiV2SelectIndividualAnimalTransfer'

import {
    Container,
    StyledPaper,
    Header,
    StyledBackButton,
    ContentSubmitButtons
} from './styles';

const DetailLot = ({ match }) => {
    const secure_id = match.params.id;

    const {
        data: animalTransfer,
        error: errorAnimalTransfer,
        isLoading: isLoadingAnimalTransfer
    } = useApiV2SelectIndividualAnimalTransfer(secure_id);

    if (errorAnimalTransfer) {
        history.push('/dashboard/individualAnimalTransferLot');
        toast.error('Falha para carregar os dados da transferência!');

        return (
            <p>Falha para carregar os dados da transferência!</p>
        )
    }

    return (
        <center>
            <>
                {isLoadingAnimalTransfer ? (
                    <Loader />
                ) : (
                    <Container>
                        <StyledPaper>
                            <Header>
                                <h1>
                                    Transferência do dia {animalTransfer[0] ? animalTransfer[0].date : ''}
                                </h1>
                            </Header>
                            <TableContainer>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Identificador</TableCell>
                                            <TableCell align="left">Raça</TableCell>
                                            <TableCell align="left">Peso atual</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {animalTransfer.map(animal => (
                                            <TableRow key={animal.secure_id}>
                                                <TableCell align="left">{animal.identifier}</TableCell>
                                                <TableCell align="left">{animal.breed}</TableCell>
                                                <TableCell align="left">{animal.current_weight}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <ContentSubmitButtons>
                                <StyledBackButton
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => history.goBack()}
                                    style={{
                                        marginLeft: 16,
                                    }}
                                >
                                    Voltar
                                </StyledBackButton>
                            </ContentSubmitButtons>
                        </StyledPaper>
                    </Container>
                )}
            </>
        </center>
    );
};

DetailLot.propTypes = {
    match: PropTypes.isRequired,
};

export default DetailLot;

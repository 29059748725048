import React from 'react';
import PropTypes from 'prop-types';

import { StyledSubmitButton } from './styles';

import colors from '~/styles/colors';
import SmallLoader from '../SmallLoader';

export default function ButtonSubmit({ background, title, loading, ...rest }) {
  return (
    <StyledSubmitButton
      type="submit"
      variant="contained"
      disabled={loading}
      background={background}
      // startIcon={!loading ? <SaveIcon /> : null}
      {...rest}
    >
      <div>
        <span>{!loading && title}</span>
        {loading && <SmallLoader color={colors.white} />}
      </div>
    </StyledSubmitButton>
  );
}

ButtonSubmit.defaultProps = {
  background: colors.success,
};

ButtonSubmit.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  background: PropTypes.string,
};

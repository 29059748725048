import React from 'react';

import {
  TableCell,
  TableRow
} from '@material-ui/core';

import {
  StyledTableCell,
  StyledTableCellEllipsis
} from './styles';

export function CardTableMSCorrection({ msCorrection }) {
  return (
    <TableRow>
      <StyledTableCellEllipsis component="th" scope="row">
        {msCorrection.food.name}
      </StyledTableCellEllipsis>
      <StyledTableCell align="right">
        {msCorrection.percent_ms}
      </StyledTableCell>
      <TableCell align="center">
        {msCorrection.date}
      </TableCell>
    </TableRow>
  )
}

import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  format,
  parseISO,
  differenceInDays,
  addDays,
  startOfDay,
  endOfDay,
} from 'date-fns';
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  InputAdornment,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import AutorenewIcon from '@material-ui/icons/Autorenew';

// Query
import { useApiV2AddDietSwitch } from '~/hooks/apiV2/foodManagement/dietSwitch/useApiV2AddDietSwitch';
import { useApiV2ShowDataDietSwitch } from '~/hooks/apiV2/foodManagement/dietSwitch/useApiV2ShowDataDietSwitch';

import {
  Container,
  StyledPaper,
  StyledGrid,
  ContentInfo,
  StyledGridInfo,
  ContentSubmitButtons,
  StyledSubmitButton,
  ContentIcon,
} from './styles';

import { formatNumber } from '~/utils/format';
import colors from '~/styles/colors';
import history from '~/services/history';
import Loader from '~/components/Loader';

const dietDefaultOption = {
  id: null,
  name: 'Selecione a dieta',
};

export default function Register({ paddockId, closeModal, isModal, location }) {
  const paddock_id = useMemo(
    () => (location && location.state ? location.state.paddock_id : null),
    [location]
  );

  const [selectedPaddock, setSelectedPaddock] = useState(null);
  const [selectedDiet, setSelectedDiet] = useState(null);

  const [listPaddocks, setListPaddocks] = useState([]);
  const [diets, setDiets] = useState();

  const [amountAnimals, setAmountAnimals] = useState(null);
  const [finalDateDiet, setFinalDateDiet] = useState(null);
  const [daysOfUse, setDaysOfUse] = useState(null);
  const [averageWeight, setAverageWeight] = useState(null);

  const [adding, setAdding] = useState(false);

  // Query
  const { data, isLoading } = useApiV2ShowDataDietSwitch();

  const addDietSwitch = useApiV2AddDietSwitch(closeModal, setAdding);

  useEffect(() => {
    if (data) {
      const newPaddocks = data.optionsPaddocks.map(paddock => ({
        secure_id: paddock.secure_id,
        value: paddock.name,
        ...paddock,
      }));

      setListPaddocks(newPaddocks);
      setDiets(data.optionsDiets);

      if (paddockId) {
        const paddockSwitch = newPaddocks.find(
          value => value.secure_id === paddockId
        );

        if (paddockSwitch) setSelectedPaddock(paddockSwitch);
        handleChangePaddock(paddockSwitch);
      }

      if (paddock_id) {
        const paddockSwitch = newPaddocks.find(
          value => value.secure_id === paddock_id
        );

        if (paddockSwitch) setSelectedPaddock(paddockSwitch);
        handleChangePaddock(paddockSwitch);
      }
    }
  }, [data, paddockId, paddock_id]);

  function handleChangeDiet(event) {
    if (!selectedPaddock || !event.target.value.secure_id) {
      setFinalDateDiet('');
      setSelectedDiet(null);
      return;
    }

    setSelectedDiet(event.target.value);

    const finalDate = addDays(
      parseISO(selectedPaddock.dateSwitch.replace('Z', '')),
      event.target.value.days_consumption
    );

    setFinalDateDiet(finalDate || '');
  }

  function handleChangePaddock(value) {
    if (value) {
      const days = differenceInDays(
        endOfDay(parseISO(value.dateSwitch)),
        startOfDay(parseISO(value.initial_date_diet))
      );

      setDaysOfUse(days);
      setAmountAnimals(value.amount_animals);
      setAverageWeight(value.average_weight);
      setSelectedPaddock(value);
    } else {
      setDaysOfUse(null);
      setAmountAnimals(null);
      setAverageWeight(null);
      setSelectedPaddock(null);
    }
  }

  async function handleSubmit() {
    if (closeModal) closeModal();
    await addDietSwitch.mutateAsync({
      newDietSecureId: selectedDiet.secure_id,
      paddockSecureId: selectedPaddock.secure_id,
      date: selectedPaddock.dateSwitch.replace('Z', ''),
    });
  }

  function handleBack() {
    if (isModal) {
      closeModal();
    } else {
      history.goBack();
    }
  }
  return (
    <center>
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          <StyledPaper>
            <StyledGrid container justify="space-around" spacing={2}>
              <Grid item xs={12} sm>
                <Autocomplete
                  name="paddock"
                  size="small"
                  noOptionsText="Sem opções"
                  disabled={isModal}
                  options={listPaddocks}
                  getOptionLabel={option => option.value}
                  value={selectedPaddock}
                  onChange={(event, value) => {
                    handleChangePaddock(value);
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Piquete"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm>
                <FormControl size="small" fullWidth variant="outlined">
                  <InputLabel id="input-diet">Nova Dieta</InputLabel>
                  <Select
                    id="selectedDiet"
                    labelWidth={80}
                    defaultValue={dietDefaultOption}
                    onChange={handleChangeDiet}
                  >
                    <MenuItem value={dietDefaultOption}>
                      {dietDefaultOption.name}
                    </MenuItem>
                    {diets &&
                      diets.map(diet => (
                        <MenuItem key={diet.secure_id} value={diet}>
                          {diet.name}{' '}{selectedPaddock && selectedPaddock.diet.secure_id === diet.secure_id && <strong>&nbsp; - &nbsp;Manter esta Dieta Atual</strong>}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </StyledGrid>

            <Divider light />
            <center>
              <ContentInfo>
                <StyledGridInfo container justify="space-around" spacing={2}>
                  <Grid item xs={12} sm>
                    <TextField
                      id="current_diet"
                      label="Dieta atual"
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: selectedPaddock && !!selectedPaddock.dateSwitch,
                      }}
                      value={
                        (selectedPaddock &&
                          selectedPaddock.diet &&
                          selectedPaddock.diet.name) ||
                        ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm>
                    <TextField
                      id="initial_date_diet"
                      label="Dias de uso"
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: daysOfUse !== null,
                      }}
                      value={daysOfUse >= 0 ? daysOfUse : ''}
                    />
                  </Grid>
                  <Grid item xs={12} sm>
                    <TextField
                      id="final_date_diet"
                      label="Data da Troca"
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: selectedPaddock && selectedPaddock.dateSwitch,
                      }}
                      value={
                        (selectedPaddock &&
                          selectedPaddock.dateSwitch &&
                          format(
                            parseISO(
                              selectedPaddock.dateSwitch.replace('Z', '')
                            ),
                            'dd/MM/yyyy'
                          )) ||
                        ''
                      }
                    />
                  </Grid>
                </StyledGridInfo>

                <StyledGridInfo
                  container
                  justify="space-around"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} sm>
                    <TextField
                      id="averrage_weight"
                      label="Peso Médio"
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      InputLabelProps={{
                        shrink: averageWeight,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">Kg</InputAdornment>
                        ),
                      }}
                      value={
                        averageWeight && averageWeight !== 0
                          ? formatNumber(averageWeight, 4, 4)
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm>
                    <TextField
                      id="amount_animals"
                      label="Quantidade"
                      variant="outlined"
                      size="small"
                      fullWidth
                      disabled
                      type="number"
                      InputLabelProps={{ shrink: amountAnimals }}
                      value={
                        amountAnimals !== 0
                          ? formatNumber(amountAnimals, 0, 0)
                          : ''
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm>
                    <Grid item xs={12} sm>
                      <TextField
                        label="Próxima Troca"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                        InputLabelProps={{ shrink: finalDateDiet }}
                        value={
                          finalDateDiet
                            ? format(finalDateDiet, 'dd/MM/yyyy')
                            : ''
                        }
                      />
                    </Grid>
                  </Grid>
                </StyledGridInfo>
              </ContentInfo>
            </center>

            <ContentSubmitButtons>
              <StyledSubmitButton
                variant="contained"
                color={colors.white}
                background={colors.success}
                disabled={
                  adding ||
                  !selectedPaddock ||
                  !selectedDiet ||
                  (selectedDiet && !selectedDiet.secure_id)
                }
                endIcon={
                  <ContentIcon loading={adding}>
                    <AutorenewIcon />
                  </ContentIcon>
                }
                onClick={handleSubmit}
              >
                {adding ? 'Trocando' : 'Trocar'}
              </StyledSubmitButton>
              <StyledSubmitButton
                color="primary"
                variant="outlined"
                onClick={handleBack}
              >
                Cancelar
              </StyledSubmitButton>
            </ContentSubmitButtons>
          </StyledPaper>
        </Container>
      )}
    </center>
  );
}

Register.defaultProps = {
  paddockId: null,
  closeModal: null,
  isModal: false,
  location: null,
  istroughReading: false,
};

Register.propTypes = {
  paddockId: PropTypes.string,
  closeModal: PropTypes.func,
  isModal: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      paddock_id: PropTypes.string,
    }),
  }),
  istroughReading: PropTypes.bool,
};
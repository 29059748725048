import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineLoading } from 'react-icons/ai';

import { Container } from './styles';

import colors from '~/styles/colors';

export default function SmallLoader({ size, color }) {
  return (
    <Container>
      <AiOutlineLoading size={size} color={color} />
    </Container>
  );
}

SmallLoader.defaultProps = {
  size: 17,
  color: colors.grey,
};

SmallLoader.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, Select, MenuItem } from '@material-ui/core';

import { StyledPaperFilter, StyledFormControl } from './styles';

export default function Filter({ setType }) {
  function handleChangeType(type) {
    setType(type);
  }

  return (
    <StyledPaperFilter>
      <StyledFormControl size="small" fullWidth>
        <InputLabel id="input-module">Tipo</InputLabel>
        <Select
          id="currentType"
          onChange={event => handleChangeType(event.target.value)}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
        >
          <MenuItem value="all">Todos</MenuItem>
          <MenuItem value="client,both">Cliente</MenuItem>
          <MenuItem value="provider,both">Fornecedor</MenuItem>
        </Select>
      </StyledFormControl>
    </StyledPaperFilter>
  );
}

Filter.propTypes = {
  setType: PropTypes.func.isRequired,
};

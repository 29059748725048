import axios from 'axios';

require('dotenv/config');

const apiV2 = axios.create({
  baseURL: process.env.REACT_APP_URL_SERVER_V2,
  headers: {
    Authorization: `Bearer ${JSON.parse(localStorage.getItem('@BeefSystem:token'))}`,
    farmSecureId: JSON.parse(localStorage.getItem('@BeefSystem:farm'))
      && JSON.parse(localStorage.getItem('@BeefSystem:farm')).secureId
        ? JSON.parse(localStorage.getItem('@BeefSystem:farm')).secureId
        : ''
  }
});

export default apiV2;

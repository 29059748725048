import styled from 'styled-components';
import { Grid, Paper, FormControl } from '@material-ui/core';

export const Content = styled.div`
  max-width: 800px;
  margin-bottom: 20px;
`;

export const StyledTablePaper = styled(Paper)`
  width: 100%;
  padding: 8px;
`;

export const StyledGridItem = styled(Grid)`
  margin: 5px 10px;
`;

export const StyledFormControl = styled(FormControl)`
  margin-top: 8px;
`;

export const ContentDate = styled.div`
  min-width: 144px;
`;

import React, { useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';

// Query
import { useApiV2SelectOcurrence } from '~/hooks/apiV2/admin/ocurrence/useApiV2SelectOcurrence'
import { useApiV2GlobalOcurrencesTypes } from '~/hooks/apiV2/global/ocurrencesType/useApiV2GlobalOcurrencesTypes'

import {
  Container,
  StyledPaper,
  ContentItem,
  Error,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';

import history from '~/services/history';
import Loader from '~/components/Loader';
import { toast } from 'react-toastify';

const schema = Yup.object().shape({
  date: Yup.string().required('Informe a data'),
  type: Yup.string().required('Informe o tipo'),
  description: Yup.string().required('Informe a descrição'),
});

const moduleDefaultOption = {
  id: 'default',
  name: 'Todos'
}

export default function ViewOcurrence({ match }) {
  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  // Variables of function
  const [listLine, setListLine] = useState([]);
  const [listPaddock, setListPaddock] = useState([]);

  // Query
  const { data: currentOcurrenceData, isLoading, error } = useApiV2SelectOcurrence(secure_id);
  const { data: ocurrencesTypeData } = useApiV2GlobalOcurrencesTypes();

  function handleBack() {
    history.goBack();
  }

  useEffect(() => {
    if (currentOcurrenceData && currentOcurrenceData.currentOcurrence.line) {
      setListLine([currentOcurrenceData.currentOcurrence.line])
    }
    if (currentOcurrenceData && currentOcurrenceData.currentOcurrence.paddock) {
      setListPaddock([currentOcurrenceData.currentOcurrence.paddock])
    }
  }, [currentOcurrenceData])

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    history.push('/dashboard/ocurrences');
    toast.error('Falha para carregar os dados da ocorrência!');

    return (
      <Container>
        <p>Não foi possível carregar a ocorrência!</p>
      </Container>
    )
  }

  return (
    <center>
      <Container>
        <Formik
          validationSchema={schema}
          initialValues={{
            description: currentOcurrenceData && currentOcurrenceData.currentOcurrence.description,
            type: currentOcurrenceData ? currentOcurrenceData.currentOcurrence.ocurrencesType.secure_id : '',
            date: currentOcurrenceData && currentOcurrenceData.currentOcurrence.date,
            module_id: currentOcurrenceData && currentOcurrenceData.currentOcurrence.module
              ? currentOcurrenceData.currentOcurrence.module.secure_id
              : moduleDefaultOption.id,
            line_id: currentOcurrenceData && currentOcurrenceData.currentOcurrence.line
              ? currentOcurrenceData.currentOcurrence.line.secure_id
              : '',
            paddock_id: currentOcurrenceData && currentOcurrenceData.currentOcurrence.paddock
              ? currentOcurrenceData.currentOcurrence.paddock.secure_id
              : ''
          }}
        >
          {({ errors, touched, values }) => (
            <Form>
              <ContentItem>
                <StyledPaper>
                  <Grid container spacing={2}>
                    <Grid item sm xs={12}>
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={ptBR}
                      >
                        <KeyboardDatePicker
                          name="date"
                          disableToolbar
                          variant="inline"
                          format="dd/MM/yyyy"
                          margin="normal"
                          size="small"
                          id="date-picker-inline"
                          label="Data"
                          inputVariant="outlined"
                          maxDate={new Date()}
                          required
                          fullWidth
                          autoOk
                          defaultValue={new Date()}
                          style={{ marginTop: 0, marginBottom: 0 }}
                          value={values.date}
                          error={(!!errors.date && touched.date)}
                          disabled
                        // onChange={date => setFieldValue('date', new Date(date))}
                        />
                        <ErrorMessage name="date" component={Error} />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} md>
                      <FormControl
                        size="small"
                        required
                        fullWidth
                        variant="outlined"
                        error={!!errors.type && touched.type}
                      >
                        <InputLabel id="input-type">Tipo</InputLabel>
                        <Select
                          name="type"
                          labelWidth={50}
                          inputProps={{
                            id: 'type-input',
                          }}
                          value={values.type}
                          disabled
                        // onChange={event => {
                        //   handleSetDescription(event, setFieldValue)
                        //   handleChange(event)
                        // }}
                        >
                          {ocurrencesTypeData && ocurrencesTypeData.ocurrencesType.map(item => (
                            <MenuItem key={item.secure_id} value={item.secure_id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        <ErrorMessage name="type" component={Error} />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md>
                      <TextField
                        name="description"
                        variant="outlined"
                        label="Descrição *"
                        autoComplete="off"
                        fullWidth
                        multiline
                        disabled
                        error={!!errors.description && touched.description}
                        // onChange={handleChange}
                        value={values.description}
                      />
                      <ErrorMessage name="description" component={Error} />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item sm xs={12}>
                      <TextField
                        name="module_id"
                        label="Módulo"
                        variant="outlined"
                        fullWidth
                        select
                        size="small"
                        InputLabelProps={{
                          shrink: values.module_id,
                        }}
                        error={!!errors.module_id && touched.module_id}
                        value={values.module_id}
                        disabled
                      // onChange={event =>
                      //   handleSelectModule(event, setFieldValue)
                      // }
                      >
                        <MenuItem
                          key={moduleDefaultOption.id}
                          value={moduleDefaultOption.id}
                        >
                          {moduleDefaultOption.name}
                        </MenuItem>
                        {currentOcurrenceData
                          && currentOcurrenceData.currentOcurrence.module
                          && Array(currentOcurrenceData.currentOcurrence.module).map(item => (
                            <MenuItem
                              key={item.secure_id}
                              value={item.secure_id}
                            >
                              {item.name}
                            </MenuItem>
                          ))}
                      </TextField>
                      <ErrorMessage name="module_id" component={Error} />
                    </Grid>
                    <Grid item sm xs={12}>
                      <TextField
                        name="line_id"
                        label="Linha"
                        variant="outlined"
                        fullWidth
                        select
                        size="small"
                        InputLabelProps={{
                          shrink: values.line_id,
                        }}
                        error={!!errors.line_id && touched.line_id}
                        disabled
                        value={values.line_id}
                      // onChange={event =>
                      //   handleSelectLine(event, setFieldValue)
                      // }
                      >
                        {listLine.map(item => (
                          <MenuItem
                            key={item.secure_id}
                            value={item.secure_id}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <ErrorMessage name="line_id" component={Error} />
                    </Grid>
                    <Grid item sm xs={12}>
                      <TextField
                        name="paddock_id"
                        label="Piquete"
                        variant="outlined"
                        fullWidth
                        select
                        size="small"
                        InputLabelProps={{
                          shrink: values.paddock_id,
                        }}
                        error={!!errors.paddock_id && touched.paddock_id}
                        disabled
                        value={values.paddock_id}
                      // onChange={event => {
                      //   handleSelectPaddock(event, setFieldValue);
                      // }}
                      >
                        {listPaddock.map(item => (
                          <MenuItem
                            key={item.secure_id}
                            value={item.secure_id}
                          >
                            {item.name}
                          </MenuItem>
                        ))}
                      </TextField>
                      <ErrorMessage name="paddock_id" component={Error} />
                    </Grid>
                  </Grid>

                  <ContentSubmitButtons>
                    <StyledSubmitButton
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={handleBack}
                    >
                      Voltar
                    </StyledSubmitButton>
                  </ContentSubmitButtons>
                </StyledPaper>
              </ContentItem>
            </Form>
          )}
        </Formik>
      </Container>
    </center>
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Hidden, IconButton, Divider, List } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { StyledDrawer, Row, Logo } from './styles';

import logo from '~/assets/images/logo_white.png';
import dashboardIcon from '~/assets/icons/dashboard-icon.png';
import cadastrosIcon from '~/assets/icons/cadastros-icon.png';
import adminIcon from '~/assets/icons/admin-icon.png';
import manejoIcon from '~/assets/icons/manejo-icon.png';
import manejoAlimentacaoIcon from '~/assets/icons/manejo-alimentacao-icon.png';
import relatoriosIcon from '~/assets/icons/relatorios-icon.png';
import Item from './Components/Item';
import { useAuthContext } from '~/contexts/AuthContext';

function DrawerMenu({ handleDrawer, visibleDrawer, drawerWidth, location }) {
  const { farm } = useAuthContext();

  const [listMenu, setListMenu] = useState([]);
  const [itemMenu, setItemMenu] = useState(0);
  const [itemSubMenu, setItemSubMenu] = useState(null);
  const { powerUps } = farm;

  const currentLink = location.pathname.substr(location.pathname.indexOf('/'));

  const currentLinkSplit = location.pathname
    .substr(location.pathname.indexOf('/'))
    .split('/');

  function checkPowerUp(powerUpName, disabled) {
    if (powerUps && powerUps.length > 0) {
      const powerUpsActives = powerUps.map(powerUp => powerUp.name);
      const status = powerUpsActives.includes(powerUpName);
      return !disabled && status ? false : disabled;
    }
    return true;
  }

  function checkSelected(itemLink) {
    if (itemLink) {
      if (currentLinkSplit[currentLinkSplit.length - 1] === 'add') {
        const newCurrentLink = currentLink.substr(
          0,
          currentLink.length -
            currentLinkSplit[currentLinkSplit.length - 1].length -
            1
        );

        return itemLink === newCurrentLink;
      }

      if (currentLinkSplit[currentLinkSplit.length - 2] === 'edit') {
        const newCurrentLink = currentLink.substr(
          0,
          currentLink.length -
            (currentLinkSplit[currentLinkSplit.length - 2].length +
              currentLinkSplit[currentLinkSplit.length - 1].length +
              2)
        );

        return itemLink === newCurrentLink;
      }

      return itemLink === currentLink;
    }

    return false;
  }

  function ItemsDrawer() {
    return (
      <>
        <Row>
          <Logo>
            <img src={logo} alt="logo" />
          </Logo>
          <IconButton onClick={handleDrawer}>
            <ChevronLeftIcon color="secondary" />
          </IconButton>
        </Row>
        <Divider />

        <List>
          {listMenu.map(item => (
            <Item
              key={item.id}
              item={item}
              checkSelected={checkSelected}
              itemMenu={itemMenu}
              setItemMenu={setItemMenu}
              itemSubMenu={itemSubMenu}
              setItemSubMenu={setItemSubMenu}
              icon={item.icon}
            />
          ))}
        </List>
      </>
    );
  }

  useEffect(() => {
    const listMenu = [
      {
        id: 1,
        name: 'Dashboard',
        link: '/dashboard',
        icon: dashboardIcon,
      },
      {
        id: 2,
        name: 'Cadastros',
        icon: cadastrosIcon,
        itens: [
          {
            id: 1,
            name: 'Clientes/Fornecedores',
            link: '/dashboard/stakeholder',
            slugPermission: 'stakeholder_view',
          },
          {
            id: 2,
            name: 'Funcionários',
            link: '/dashboard/employee',
            slugPermission: 'employee_view',
          },
          {
            id: 3,
            name: 'Usuários',
            link: '/dashboard/users',
            slugPermission: 'user_view',
          },
          {
            id: 4,
            name: 'Alimentos',
            link: '/dashboard/food',
            slugPermission: 'food_view',
          },
          {
            id: 5,
            name: 'Dietas',
            link: '/dashboard/diet',
            slugPermission: 'diet_view',
          },
          {
            id: 6,
            name: 'Concentrados/Rações',
            link: '/dashboard/feed',
            slugPermission: 'feed_view',
          },
          {
            id: 7,
            name: 'Distribuição de Tratos',
            link: '/dashboard/treatySetting',
            slugPermission: 'treatySetting_view',
          },
          {
            id: 8,
            name: 'Raças',
            link: '/dashboard/breed',
            slugPermission: 'breed_view',
          },
          {
            id: 9,
            name: 'Lotes',
            link: '/dashboard/lots',
            slugPermission: 'lots_view',
          },
          {
            id: 10,
            name: 'Animais',
            link: '/dashboard/animals',
            slugPermission: 'animals_view',
          },
          // {
          //   id: 7,
          //   name: 'Animais',
          //   link: '/dashboard/animals',
          //   slugPermission: checkPowerUp('Individual', false),
          // },
          {
            id: 11,
            name: 'Módulo - Linha - Piquete',
            link: '/dashboard/module',
            slugPermission: 'module_view',
          },
          {
            id: 12,
            name: 'Config. de Leitura de Cocho',
            link: '/dashboard/troughSetting',
            slugPermission: 'troughSetting_view',
          },
          {
            id: 13,
            name: 'Causas das Mortes',
            link: '/dashboard/causeOfDeath',
            slugPermission: 'causeOfDeath_view',
          },
          {
            id: 14,
            name: 'Tipos de ocorrência',
            link: '/dashboard/ocurrencesType',
            slugPermission: 'ocurrences_view',
          },
        ],
      },
      {
        id: 3,
        name: 'Administrativo',
        icon: adminIcon,
        itens: [
          {
            id: 1,
            name: 'Compra de Alimentos',
            link: '/dashboard/purchaseFoods',
            slugPermission: 'purchaseFoods_view',
          },
          {
            id: 2,
            name: 'Custos Operacionais',
            link: '/dashboard/operationalCost',
            slugPermission: 'operationalCost_view',
          },
          {
            id: 3,
            name: 'Custos Sanitários',
            link: '/dashboard/sanitaryCost',
            slugPermission: 'sanitaryCost_view',
          },
          {
            id: 4,
            name: 'Correções de Estoque',
            link: '/dashboard/foodCorrection',
            slugPermission: 'foodCorrection_view',
          },
          {
            id: 5,
            name: 'Ocorrências',
            link: '/dashboard/ocurrences',
            slugPermission: 'ocurrences_view',
          },
          {
            id: 5,
            name: 'Log de ações',
            link: '/dashboard/actionsLogs',
            slugPermission: null,
            isActionsLogs: true
          },
        ],
      },
      {
        id: 4,
        name: 'Manejo de Animais',
        disabled: checkPowerUp('Individual', false),
        icon: manejoIcon,
        itens: [],
      },
      // {
      //   id: 5,
      //   name: 'Animais',
      //   link: '/dashboard/animalInputUnity',
      //   disabled: true,
      // },
      {
        id: 5,
        name: 'Manejo Alimentar',
        icon: manejoAlimentacaoIcon,
        itens: [
          {
            id: 1,
            name: 'Trocas de Dieta',
            link: '/dashboard/dietSwitch',
            slugPermission: 'dietSwitch_view',
          },
          {
            id: 2,
            name: 'Leituras de Cocho',
            link: '/dashboard/troughReading',
            slugPermission: 'troughReading_view',
          },
          {
            id: 3,
            name: 'Previsões de Trato',
            link: '/dashboard/treatmentForecast',
            slugPermission: 'treatmentForecast_view',
          },
          {
            id: 4,
            name: 'Realizações de Trato',
            link: '/dashboard/treatmentPerformed',
            slugPermission: 'treatmentPerformed_view',
          },
          {
            id: 5,
            name: 'Fab. de Dieta',
            link: '/dashboard/manufactoringDiet',
            slugPermission: 'manufactoringDiet_view',
          },
          {
            id: 5,
            name: 'Fab. de Mistura/Ração',
            link: '/dashboard/manufactoringFeed',
            slugPermission: 'manufactoringFeed_view',
          },
          {
            id: 6,
            name: 'Batidas de Dieta/Ração',
            link: '/dashboard/msBeat',
            slugPermission: 'msBeat_view',
          },
          {
            id: 7,
            name: 'Correções de MS',
            link: '/dashboard/msCorrection',
            slugPermission: 'msCorrection_view',
          },
        ],
      },
      {
        id: 6,
        name: 'Relatórios',
        icon: relatoriosIcon,
        itens: [
          {
            id: 1,
            name: 'Lotes Ativos',
            link: '/dashboard/reportActiveLot',
            slugPermission: 'reportActiveLot_view',
          },
          {
            id: 2,
            name: 'Piquetes Ativos',
            link: '/dashboard/reportActivePaddock',
            slugPermission: 'reportActiveLot_view',
          },
          // {
          //   id: 3,
          //   name: 'Lotes Ativos - Boitel',
          //   link: '/dashboard/reportActiveLotBoitel',
          //   slugPermission: !(TestPermission({ permissions: ['reportActiveLotBoitel_view'] })),
          // },
          {
            id: 4,
            name: 'Curva de Consumo de Lote',
            link: '/dashboard/reportConsumptionLot',
            slugPermission: 'reportConsumptionLot_view',
          },
          {
            id: 5,
            name: 'Eficiência de Tratos',
            link: '/dashboard/reportEfficiencyTreatment',
            slugPermission: 'reportEfficiencyTreatment_view',
          },
          {
            id: 6,
            name: 'Eficiência de Fab. de Dietas',
            link: '/dashboard/reportDietManufacturingEfficiency',
            slugPermission: 'reportDietManufacturingEfficiency_view',
          },
          {
            id: 7,
            name: 'Fechamento por Saída',
            link: '/dashboard/reportAnimalOutput',
            slugPermission: 'reportAnimalOutput_view',
          },
          {
            id: 8,
            name: 'Fechamento Zootécnico',
            slugPermission: 'reportZootechnicalLot_view',
            itens: [
              {
                id: 12,
                name: 'Analítico',
                link: '/dashboard/reportZootechnicalLot',
                slugPermission: 'reportZootechnicalLot_view',
              },
              {
                id: 13,
                name: 'Resumido',
                link: '/dashboard/reportZootechnicalLotResume',
                slugPermission: 'reportZootechnicalLot_view',
              },
            ],
          },
          {
            id: 9,
            name: 'Fechamento Econômico',
            slugPermission: 'reportEconomicClosing_view',
            itens: [
              {
                id: 14,
                name: 'Analítico',
                link: '/dashboard/reportEconomicClosing',
                slugPermission: 'reportEconomicClosing_view',
              },
              {
                id: 15,
                name: 'Resumido',
                link: '/dashboard/reportEconomicClosingResume',
                slugPermission: 'reportEconomicClosing_view',
              },
            ],
          },
          {
            id: 10,
            name: 'Resumo de Alimentos',
            link: '/dashboard/reportSummaryFood',
            slugPermission: 'reportSummaryFood_view',
          },
          {
            id: 11,
            name: 'Histórico de Alimentos',
            link: '/dashboard/reportHistoricFood',
            slugPermission: 'reportHistoricFood_view',
          },
          // {
          //   id: 12,
          //   name: 'Previsão de abate',
          //   link: '/dashboard/slaughterForecast',
          //   slugPermission: !(TestPermission({ permissions: ['slaughterForecast_view'] })),
          // },
          // {
          //   id: 10,
          //   name: 'Estoque de Insumos',
          //   link: '/dashboard/reportInput',
          //   slugPermission: true,
          // },
        ],
      },
    ];

    if (!checkPowerUp('Individual', false)) {
      listMenu[3].itens = [
        {
          id: 1,
          name: 'Entrada Individual',
          link: '/dashboard/animalInputUnity',
          slugPermission: 'animalInput_view',
        },
        {
          id: 3,
          name: 'Formação de lote',
          link: '/dashboard/animalInputApart',
          slugPermission: 'animalInput_view',
        },
        {
          id: 4,
          name: 'Transf. Animal',
          link: '/dashboard/individualAnimalTransferLot',
          slugPermission: 'animalTransferLot_view',
        },
        {
          id: 11,
          name: 'Transf. Piquete',
          link: '/dashboard/animalTransferPaddock',
          slugPermission: 'animalTransferPaddock_view',
        },
        {
          id: 5,
          name: 'Saída de Animal',
          link: '/dashboard/individualAnimalOutput',
          slugPermission: 'animalOutput_view',
        },
        // {
        //   id: 6,
        //   name: 'Formação de embarque',
        //   link: '/dashboard/individualAnimalOutputGta',
        //   slugPermission: animalOutputView,
        // },
        {
          id: 7,
          name: 'Refugo de Cocho',
          link: '/dashboard/individualAnimalScrapOutput',
          slugPermission: 'animalScrapOutput_view',
        },
        {
          id: 8,
          name: 'Morte de Animais',
          link: '/dashboard/individualAnimalDeathOutput',
          slugPermission: 'animalDeathOutput_view',
        },
        {
          id: 15,
          name: 'Pesagem Intermediária',
          link: '/dashboard/intermediateWeightAnimal',
          slugPermission: 'intermediateWeight_view',
        },
      ];
    } else {
      listMenu[3].itens = [
        {
          id: 9,
          name: 'Entrada Lote',
          link: '/dashboard/animalInput',
          slugPermission: 'animalInput_view',
        },
        {
          id: 10,
          name: 'Transf. Lote',
          link: '/dashboard/animalTransferLot',
          slugPermission: 'animalTransferLot_view',
        },
        {
          id: 11,
          name: 'Transf. Piquete',
          link: '/dashboard/animalTransferPaddock',
          slugPermission: 'animalTransferPaddock_view',
        },
        {
          id: 12,
          name: 'Saídas de Animais',
          link: '/dashboard/animalOutput',
          slugPermission: 'animalOutput_view',
        },
        {
          id: 13,
          name: 'Refugo de Cocho',
          link: '/dashboard/animalScrapOutput',
          slugPermission: 'animalScrapOutput_view',
        },
        {
          id: 14,
          name: 'Morte de Animais',
          link: '/dashboard/animalDeathOutput',
          slugPermission: 'animalDeathOutput_view',
        },
        {
          id: 15,
          name: 'Pesagem Intermediária',
          link: '/dashboard/intermediateWeight',
          slugPermission: 'intermediateWeight_view',
        },
      ];
    }

    setListMenu(listMenu);
  }, [farm]);

  return (
    <>
      <Hidden smDown>
        <StyledDrawer
          variant="persistent"
          anchor="left"
          ModalProps={{
            disableScrollLock: true,
          }}
          drawerwidth={drawerWidth}
          open={visibleDrawer}
        >
          {ItemsDrawer()}
        </StyledDrawer>
      </Hidden>

      <Hidden mdUp>
        <StyledDrawer
          variant="temporary"
          anchor="left"
          drawerwidth={drawerWidth}
          open={visibleDrawer}
          onClose={handleDrawer}
          ModalProps={{
            keepMounted: true,
            disableScrollLock: true,
          }}
        >
          {ItemsDrawer()}
        </StyledDrawer>
      </Hidden>
    </>
  );
}

export default withRouter(DrawerMenu);

DrawerMenu.propTypes = {
  handleDrawer: PropTypes.func.isRequired,
  visibleDrawer: PropTypes.bool.isRequired,
  drawerWidth: PropTypes.number.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

import React, { useState, useEffect } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';

// Query
import { useApiV2TransferLot } from '~/hooks/apiV2/animalHandling/TransferLot/useApiV2TransferLot'

import {
  Container,
  StyledGrid,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
} from './styles';

import history from '~/services/history';
import Can from '~/components/Can';
import ButtonNew from '~/components/ButtonNew';
import Loader from '~/components/Loader';

import Filter from './Components/Filter';
import { CardTableTransferLot } from './Components/CardTableTransferLot';

export default function TransferLot() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('date');
  const [direction, setDirection] = useState('desc');
  const [date, setDate] = useState(null);
  const [originLot, setOriginLot] = useState(null);
  const [destinationLot, setDestinationLot] = useState(null);
  const [animalTransfers, setAnimalTransfers] = useState([]);

  // Query
  const { data, isLoading } = useApiV2TransferLot(`${field}%7C${direction}`, page + 1, rowsPerPage, date, originLot, destinationLot);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleAdd() {
    history.push('/dashboard/animalTransferLot/add');
  }

  useEffect(() => {
    if (data) {
      setAnimalTransfers(data.animalsTransfer);
    }
  }, [data]);

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={2}>
          <Grid item xs sm md lg xl>
            <Filter
              date={date}
              setDate={setDate}
              setOriginLot={setOriginLot}
              setDestinationLot={setDestinationLot}
            />
          </Grid>
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['animalTransferLot_new']}
          >
            <Grid item xs="auto" sm="auto">
              <ButtonNew title="Nova" onClick={handleAdd} />
            </Grid>
          </Can>
        </StyledGrid>

        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                        Lote de Origem
                    </TableCell>
                    <TableCell align="left">
                        Lote de Destino
                    </TableCell>
                    <TableCell align="center">Quantidade de animais</TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={field === 'date'}
                        direction={field === 'date' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('date')}
                      >
                        Data da Transferência
                      </TableSortLabel>
                    </TableCell>
                    <Can checkRole={['administrator', 'owner', 'manager']}>
                      <TableCell align="right" />
                    </Can>
                  </TableRow>
                </StyledTableHead>

                <TableBody>
                  {animalTransfers &&
                    animalTransfers.length > 0 &&
                    animalTransfers
                      .map(transfer => (
                        <CardTableTransferLot
                          key={transfer.secure_id}
                          transfer={transfer}
                        />
                      ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

import styled, { keyframes } from 'styled-components';
import {
  Paper,
  Grid,
  FormControl,
  TableContainer,
  TableHead,
  TableCell,
} from '@material-ui/core';
import colors from '~/styles/colors';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Recalculation = styled.div`
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  h1 {
    text-align: center;
    color: ${colors.light};
    margin-right: 12px;
  }

  svg {
    animation: ${rotate} 0.5s infinite;
  }
`;

export const Container = styled.div`
  max-width: 1600px;
  width: 100%;
  padding: 40px;
`;

export const StyledGrid = styled(Grid)`
  margin-bottom: 20px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
`;

export const StyledGridItem = styled(Grid)`
  margin: 5px 10px;
`;

export const ContentAddButton = styled.div`
  max-width: 500px;
  margin-bottom: 15px;
`;

export const ContentButton = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  flex-wrap: wrap;
`;

export const StyledFormControl = styled(FormControl)`
  margin-top: 8px;
  text-align: left;
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

const vibrate = keyframes`
  0%,
  100% {
    transform: rotate(0deg);
    transform-origin: 50% 100%;
  }
  10% {
    transform: rotate(2deg);
  }
  20%,
  40%,
  60% {
    transform: rotate(-4deg);
  }
  30%,
  50%,
  70% {
    transform: rotate(4deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  90% {
    transform: rotate(2deg);
  }
`;

export const Alert = styled.div`
  svg {
    animation: ${vibrate} 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both
      infinite;
    color: ${colors.warning};
  }
`;

import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

export async function getDeathOutput(sort, page = 1, limit = 50, date = null, originLot = null) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/animal-handling/deathOutput?sort=${sort}`, {
      params: { page, limit, date, originLot },
    });

    return {
      animalsDeathOutput: data.data,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    }
  } catch {
    toast.error('Não foi possível buscar saída de animais!');
  }
}

export function useApiV2DeathOutput(sort, page, limit, date, originLot) {
  return useQuery(['animalDeathOutput', sort, page, limit, date, originLot],
    () => getDeathOutput(sort, page, limit, date, originLot),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    });
}

import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2"

export async function getTreaties(sort) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/register/treaty?sort=${sort}`)

    const totalProportion = data.reduce(
      (accumulator, treaty) => accumulator + treaty.proportion,
      0
    );

    return {
      treaties: data,
      totalProportion
    }
  } catch {
    toast.error('Falha para carregar os dados da Distribuição de Tratos!');
  }
}

export function useApiV2Treaty(sort) {
  return useQuery(
    ['treaties', sort],
    () => getTreaties(sort),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

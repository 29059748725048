import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2AddFoodCorrection() {
  return useMutation(
    async (data) => {
      await apiV2.post('/v1/feedlot/admin/foodCorrection', data)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('foodCorrection');
        queryClient.invalidateQueries('foods');
        toast.success('Correção de estoque feita com sucesso!');

        history.push('/dashboard/foodCorrection');
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao realizar correção.'
        toast[type](message)
      }
    }
  );
}

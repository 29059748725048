import { styled, TableCell } from '@material-ui/core';

export const StyledTableCellEllipsis = styled(TableCell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

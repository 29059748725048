import React, { useState } from 'react';

import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@material-ui/icons/Delete';
import ShowAnimal from '../Components/ShowAnimal';
import {
  Container,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
  ContentAnimals,
  StyledSubmitButton,
} from './styles';

// Query
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';
import {
  useApiV2AddIndividualAnimalDeathOutput
} from '~/hooks/apiV2/animalHandling/IndividualAnimalDeathOutput/useApiV2AddIndividualAnimalDeathOutput';
import { useV2GlobalCauseOfDeath } from '~/hooks/apiV2/global/causeOfDeath/useV2GlobalCauseOfDeath';

import ButtonSubmit from '~/components/ButtonSubmit';
import Can from '~/components/Can';
import ButtonTable from '~/components/ButtonTable';
import colors from '~/styles/colors';
import history from '~/services/history';
import Loader from '~/components/Loader';

const schema = Yup.object().shape({
  // adiconar
});

export default function Register() {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const [animals, setAnimals] = useState([]);

  // Query
  const { data: causeOfDeaths, isLoading: isLoadingCauseOfDeath } = useV2GlobalCauseOfDeath();
  const { data: settings, isLoading: isLoadingSettings } = useApiV2GlobalSetting();
  const addIndividualAnimalDeathOutput = useApiV2AddIndividualAnimalDeathOutput();

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleRemove(secure_id) {
    setAnimals(animals.filter(animal => animal.secure_id !== secure_id));
  }

  async function handleSubmit() {
    if (animals.length <= 0) return;

    const newData = {
      animals: animals.map(animal => ({
        animalSecureId: animal.secure_id,
        originLotSecureId: animal.lot.secure_id,
        date: animal.output_date,
        obs: animal.output_obs,
        causeOfDeathSecureId: animal.cause_of_death.secure_id
      }))
    }
    await addIndividualAnimalDeathOutput.mutateAsync(newData);
  }

  return (
    <>
      {isLoadingSettings || isLoadingCauseOfDeath ? (
        <Loader />
      ) : (
        <Formik
          validationSchema={schema}
          initialValues={{
            sending: null,
          }}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Container>
                <ContentAnimals>
                  <StyledPaper>
                    <StyledTableContainer>
                      <Table stickyHeader>
                        <StyledTableHead>
                          <TableRow>
                            <TableCell align="center">
                              <TableSortLabel>Identificador</TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                              <TableSortLabel>Causa da morte</TableSortLabel>
                            </TableCell>
                            <TableCell align="center">
                              <TableSortLabel>Observação</TableSortLabel>
                            </TableCell>
                            <TableCell align="center" />
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {animals.length > 0 &&
                            animals
                              .slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              .map(animalData => (
                                <TableRow key={animalData.secure_id}>
                                  <TableCell align="center">
                                    {animalData[settings.settings.default_identification]}
                                  </TableCell>
                                  <TableCell align="center">
                                    {animalData.cause_of_death.name}
                                  </TableCell>
                                  <TableCell align="center">
                                    {animalData.output_obs}
                                  </TableCell>

                                  <Can
                                    checkRole={[
                                      'administrator',
                                      'owner',
                                      'manager',
                                    ]}
                                  >
                                    <StyledTableCell align="right">
                                      <ButtonTable
                                        icon={<DeleteIcon color="error" />}
                                        background={colors.error}
                                        onClick={() =>
                                          handleRemove(animalData.secure_id)
                                        }
                                      />
                                    </StyledTableCell>
                                  </Can>
                                </TableRow>
                              ))}
                        </TableBody>
                      </Table>
                    </StyledTableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 50, 100]}
                      labelRowsPerPage="Linhas por pág."
                      component="div"
                      count={animals.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </StyledPaper>
                  <ShowAnimal
                    animals={animals}
                    setAnimals={setAnimals}
                    settings={settings.settings}
                    causeOfDeaths={causeOfDeaths}
                  />
                </ContentAnimals>
                <Grid
                  container
                  justify="flex-end"
                  spacing={2}
                  style={{ marginTop: 12 }}
                >
                  <StyledSubmitButton
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                      history.push('/dashboard/individualAnimalDeathOutput')
                    }
                  >
                    Cancelar
                  </StyledSubmitButton>
                  <ButtonSubmit title="Cadastrar" loading={isSubmitting} disabled={animals.length <= 0} />
                </Grid>
              </Container>
            </Form>
          )}
        </Formik>
      )
      }
    </>
  );
}

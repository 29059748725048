import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2EditIndividualAnimalOutput(setIsSubmitting, secure_id) {
  return useMutation(
    async (data) => {
      setIsSubmitting(true);
      const response = await apiV2.put(`/v1/feedlot/animal-handling/individualAnimalOutput/${secure_id}`, data);

      return response.data;
    },
    {
      onSuccess: () => {
        setIsSubmitting(false);
        queryClient.invalidateQueries();

        history.push('/dashboard/individualAnimalOutput');

        toast.success('Saída de Animais editada com sucesso!');
      },
      onError: () => {
        setIsSubmitting(false);
        toast.error('Não foi possível editar saída de Animais!');
      }
    }
  );
}

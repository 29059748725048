import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2EditAnimalsInLot() {
  return useMutation(
    async (data) => {
      const { secure_id, ...newData } = data;

      await apiV2.put(`/v1/feedlot/register/edit-animals-in-lot/${secure_id}`, newData)

      return secure_id
    },
    {
      onSuccess: (response) => {
        queryClient.refetchQueries();
        toast.success('Animais do lote alterados com sucesso!');
        history.push('/dashboard/animals', { lot_id: response });
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao tentar alterar os animais.'
        toast[type](message)
      }
    }
  );
}

import { useQuery } from "react-query";
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';

export async function getPaddocks() {
  try {
    const { data } = await apiV2.get(`v1/feedlot/global/list/paddock`)

    return {
      paddocks: data
    }
  } catch {
    toast.error('Falha ao tentar carregar os piquetes!');
  }
}

export function useApiV2GlobalPaddock() {
  return useQuery(
    ['paddocks'],
    () => getPaddocks(),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

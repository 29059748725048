import React from 'react';
import Swal from 'sweetalert2';

import {
  TableCell,
  TableRow
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ButtonTable from '~/components/ButtonTable';

// Query hooks
import { useApiV2RemoveAnimalsOutput } from '~/hooks/apiV2/animalHandling/AnimalOutput/useApiV2RemoveAnimalsOutput';

import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell,
} from './styles';
import { format } from 'date-fns';
import history from '~/services/history';
import { formatNamePaddock, formatNumber } from '~/utils/format';
import ActionLoader from '~/components/ActionLoader';

export function CardTableAnimalOutput({ output, settings }) {
  // Query
  const removeAnimalAnimalsOutput = useApiV2RemoveAnimalsOutput()

  function handleEdit({ secure_id }) {
    history.push(`/dashboard/animalOutput/edit/${secure_id}`);
  }

  function handleDelete(secure_id) {
    async function performRemoveAnimalAnimalsOutput() {
      await removeAnimalAnimalsOutput.mutateAsync(secure_id)
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar essa saída?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar saída!',
    }).then(result => {
      if (result.value) {
        performRemoveAnimalAnimalsOutput();
      }
    });
  }

  return (
    <TableRow>
      <TableCell>
        {format(new Date(output.date), 'dd/MM/yyyy')}
      </TableCell>
      <TableCell align="center">
        {output.originLot
          ? output.originLot.code
          : ''}
      </TableCell>
      <TableCell align="center">
        {output.movementHistory && output.movementHistory[0]
          ? output.movementHistory[0].animal.sex
          : ''}
      </TableCell>
      <TableCell align="center">
        {output.movementHistory && output.movementHistory[0]
          ? output.movementHistory[0].animal?.breed?.name
          : ''}
      </TableCell>
      <TableCell align="center">
        {`${formatNamePaddock(
          settings,
          output.originLot
            ? output.originLot.paddock
            : ''
        )}`}
      </TableCell>
      <TableCell align="right">
        {formatNumber(output.amount_animals, 0, 4)}
      </TableCell>
      <StyledTableCell align="right" colSpan={2}>
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['animalOutput_edit']}
        >
          <ButtonTable
            icon={<EditIcon color="primary" />}
            background={colors.primary}
            lighten={0.6}
            onClick={() => handleEdit(output)}
          />
        </Can>
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['animalOutput_delete']}
        >
          <ButtonTable
            icon={
              removeAnimalAnimalsOutput.isLoading ? (
                <ActionLoader text="Deletando" />
              ) :
                (
                  <DeleteIcon color="error" />
                )
            }
            background={colors.error}
            onClick={() => handleDelete(output.secure_id)}
          />
        </Can>
      </StyledTableCell>
    </TableRow>
  )
}

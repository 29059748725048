import styled, { css } from 'styled-components';
import { Paper, Grid, Typography, Divider, Button } from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 960px;
  width: 80%;
  padding: 40px 0px;

  text-align: left;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 22px;
`;

export const StyledGrid = styled(Grid)`
  margin-top: ${props => (props.marginTop >= 0 ? props.marginTop : 6)}px;
  margin-bottom: 6px;
`;

export const StyledTypography = styled(Typography)`
  margin: 0px 0px 12px;
  color: ${colors.grey};
`;

export const ContentDivider = styled.div`
  margin: 0px 12px 8px;
  width: 1px;
  background: ${colors.lighter};
`;

export const StyledDivider = styled(Divider)`
  ${props =>
    props.color &&
    css`
      background: ${props.color};
    `}
`;

export const Error = styled.span`
  margin: 8px 14px 0;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

export const StyledDividerTitle = styled(Divider)`
  margin-top: 8px;
`;

export const WrapperTitle = styled.div`
  display: flex;
  flex: 1;

  margin: 12px 0px 8px;

  span {
    color: ${colors.primary};
    text-align: center;
    font-size: 15px;
  }
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2EditAnimalOutput() {
  return useMutation(
    async (data) => {
      const { secure_id, ...newData } = data

      const response = await apiV2.put(`/v1/feedlot/animal-handling/animalOutput/${secure_id}`, newData);

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();

        history.push('/dashboard/animalOutput');

        toast.success('Saída de Animais por Lote editada com sucesso!');
      },
      onError: () => {
        toast.error('Não foi possível editar saída de Animais por Lote!');
      }
    }
  );
}

import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import { queryClient } from '~/services/queryClient';
import { differenceInDays, parseISO, startOfDay, endOfDay } from 'date-fns'
import Swal from 'sweetalert2';

export function useApiV2RemovePurchaseFood() {
  return useMutation(
    async (secure_id, date) => {
      let accepted = true;
      const daysDifference = differenceInDays(endOfDay(new Date()), startOfDay(parseISO(date)))
      let oldPurchase = false;
      if (daysDifference >= 30) {
        accepted = false;
        const result = await Swal.fire({
          title: 'Você tem certeza ?',
          text: 'A compra será removida ' + daysDifference + ' dias atrás. Você será avisado quando o sistema terminar de processar.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#4c5993',
          cancelButtonColor: '#aaa',
          confirmButtonText: `Ok, continuar remoção!`,
        });
        if (result.value) {
          accepted = true;
          oldPurchase = true;
        }
      }

      if (accepted) {
        await apiV2.delete(`/v1/feedlot/admin/purchaseFood/${secure_id}`)
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('purchaseFoods');
        toast.success('Compra de alimentos apagada com sucesso!')
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Não foi possível apagar compra de alimentos.'
        toast[type](message)
      }
    }
  );
}

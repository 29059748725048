import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';

import { useApiV2GlobalFood } from '~/hooks/apiV2/global/food/useApiV2GlobalFood';

import {
  Container,
  StyledPaper,
  ContentDate,
  StyledIconButton,
} from './styles';

import InputDatePicker from '~/components/InputDatePicker';
import SmallLoader from '~/components/SmallLoader';

export default function FilterMsBeats({
  setFood,
  food,
  date,
  handleChangeDate,
}) {
  const [openFood, setOpenFood] = useState(false);
  const [listFoods, setListFoods] = useState([]);

  const { data, isLoading } = useApiV2GlobalFood();

  function handleSelectFood(currentFood) {
    setFood(currentFood || {});
  }

  function handleOpenFood() {
    setOpenFood(true);
  }

  useEffect(() => {
    if (data && data.length > 0) {
      const newList = data.map(currentFood => ({
        secure_id: currentFood.secure_id,
        value: currentFood.name,
        text: currentFood.name,
      }));

      setListFoods(newList);
    }
  }, [data]);

  return (
    <Container>
      <StyledPaper>
        <Grid container spacing={2} justify="space-between">
          <Grid item sm xs>
            <Autocomplete
              name="food"
              size="small"
              noOptionsText="Sem opções"
              options={listFoods}
              getOptionLabel={option => option.value}
              value={food}
              loading={isLoading}
              open={openFood}
              onOpen={handleOpenFood}
              onClose={() => {
                setOpenFood(false);
              }}
              onChange={(event, value) => handleSelectFood(value)}
              renderInput={params => (
                <TextField
                  {...params}
                  name="food_id"
                  label="Alimento"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {isLoading && <SmallLoader />}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm="auto">
            <ContentDate>
              <InputDatePicker
                label="Data"
                maxDate={new Date()}
                selectedDate={date}
                handleChangeDate={(day, value) => handleChangeDate(day, value)}
                InputProps={{
                  startAdornment: (
                    <StyledIconButton onClick={() => handleChangeDate()}>
                      <ClearIcon />
                    </StyledIconButton>
                  ),
                }}
                InputAdornmentProps={{
                  position: 'end',
                }}
              />
            </ContentDate>
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

FilterMsBeats.defaultProps = {
  date: null,
};

FilterMsBeats.propTypes = {
  setFood: PropTypes.func.isRequired,
  food: PropTypes.shape({}).isRequired,
  date: PropTypes.string,
  handleChangeDate: PropTypes.func.isRequired,
};

import styled from 'styled-components';
import colors from '~/styles/colors';
import bg from '~/assets/images/bgLogin.jpg';

export const Wrapper = styled.div`
  height: 100vh;
  background: ${colors.white};

  display: flex;
  justify-content: left;
`;
export const Content = styled.div`
  flex: 1;
  min-width: 500px;
  max-width: 600px;
`;

export const Image = styled.div`
  height: 100%;
  display: flex;
  flex: 2;
  background-image: url(${bg});
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: cover;
`;

import React from 'react';
import ButtonTable from '~/components/ButtonTable';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import colors from '~/styles/colors';

export function ButtonsChangeOrder({ setListFood, order, maxOrder, type = 'diet' }) {

	const changePosition = ({ from, to }) => {
		setListFood(arr => {
			const aux = arr[to]

			arr[to] = arr[from]
			arr[from] = aux

			// arr.splice(to, 0, arr.splice(from, 1)[0])

			//// Aviso! Caso esse componente seja usado futuramente em outros
			//// lugares, seria interessante criar um próprio para cada página onde é utilizado.
			//// Atualmente é usado no crud de dietas e rações.
			if (type === 'feed') {
				arr[to].order = to
				arr[from].order = from
			} else {
				arr[to].dietFoodPivot.order = to
				arr[from].dietFoodPivot.order = from
			}

			return [...arr]
		})
	}

	return (
		<>
			<ButtonTable
				icon={<ArrowUpwardIcon />}
				background={colors.dark}
				lighten={0.5}
				disabled={order === 0}
				onClick={() => changePosition({ from: order, to: order - 1 })}
			/>
			<ButtonTable
				icon={<ArrowDownwardIcon />}
				background={colors.dark}
				lighten={0.5}
				disabled={order === maxOrder}
				onClick={() => changePosition({ from: order, to: order + 1 })}
			/>
		</>
	)
}

import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 24px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 0px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
`;

export const ContentTitle = styled.div`
  display: flex;
  padding: 6px 0px 0px 12px;

  h4 {
    font-size: 20px;
    color: ${colors.black};
  }

  h5 {
    font-size: 16px;
    font-weight: 400;
    color: ${colors.black};

    &:first-child {
      font-weight: bold;
      margin-right: 6px;
    }
  }
`;

export const Logo = styled.div`
  img {
    height: 52px;
    width: 112px;

    margin: 0px 18px 18px;
  }
`;

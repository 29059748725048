import React, { useState } from 'react';
import Swal from 'sweetalert2';

import {
  TableRow,
  TableCell,
  Tooltip,
  Fade
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

import { useApiV2RemovePurchaseFood } from '~/hooks/apiV2/admin/purchaseFood/useApiV2RemovePurchaseFood';

import ActionLoader from '~/components/ActionLoader';
import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';
import { formatDateOnly, formatNumber } from '~/utils/format';
import { ModalPurchaseFoods } from '../Modal';

export function CardTablePurchaseFood({ shopping }) {
  const [visibleModal, setVisibleModal] = useState(false);
  function handleCloseModal() {
    setVisibleModal(false);
  }

  function handleOpenModal() {
    setVisibleModal(true);
  }
  const removePurchaseFood = useApiV2RemovePurchaseFood()

  function handleSelectShopping(secureId) {
    history.push(`/dashboard/purchaseFoods/edit/${secureId}`);
  }

  function handleRemoveShopping(id, date) {
    async function performRemovePurchaseFood() {
      await removePurchaseFood.mutateAsync(id, date)
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar essa compra?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar compra!',
    }).then(result => {
      if (result.value) {
        performRemovePurchaseFood();
      }
    });
  }

  const returnName = (str1, str2) => {
    if (str1) {
      return `${str1} - ${str2}`;
    }
    return `${str2}`;
  };

  return (
    <>
      <TableRow>
        <StyledTableCellEllipsis scope="row">
          {shopping.provider &&
            returnName(
              shopping.provider.fantasy_name,
              shopping.provider.name
            )}
        </StyledTableCellEllipsis>
        <TableCell align="center">
          {formatDateOnly(shopping.date)}
        </TableCell>
        <StyledTableCellEllipsis align="left">
          {shopping.purchaseFoodItems &&
            shopping.purchaseFoodItems
              .map(
                foodItem => foodItem.food && foodItem.food.name
              )
              .join(', ')}
        </StyledTableCellEllipsis>
        <StyledTableCell align="right">
          {`R$ ${formatNumber(shopping.total_price, 2, 4)}`}
        </StyledTableCell>
        <StyledTableCell align="right">
          {`R$ ${formatNumber(shopping.total_freight, 2, 4)}`}
        </StyledTableCell>
        <StyledTableCell align="right">
          {`R$ ${formatNumber(
            shopping.total_price + shopping.total_freight,
            2,
            4
          )}`}
        </StyledTableCell>
        <StyledTableCell colSpan={2} align="right">
          {removePurchaseFood.isLoading ? (
            <ActionLoader text="Deletando" />
          ) : (
            <>
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title="Compra com mais de 30 dias, entre em contato com o suporte para realizar a atualização."
                disableHoverListener={
                  shopping.daysDifference < 30
                }
              >
                <span>
                  {shopping.daysDifference >= 30 ? (
                    <Can
                      checkRole={['administrator']}
                    >
                      {can => can ? (
                        <ButtonTable
                          icon={<EditIcon color="primary" />}
                          background={colors.primary}
                          lighten={0.6}
                          onClick={() =>
                            handleSelectShopping(shopping.secure_id)
                          }
                        />
                      ) : (
                        <ButtonTable
                          icon={<VisibilityIcon />}
                          background={colors.dark}
                          lighten={0.5}
                          onClick={handleOpenModal}
                        />
                      )}
                    </Can>
                  ) : (
                    <Can
                      checkRole={['administrator', 'owner', 'beefer']}
                      checkPermission={['purchaseFoods_edit']}
                    >
                      <ButtonTable
                        icon={<EditIcon color="primary" />}
                        background={colors.primary}
                        lighten={0.6}
                        onClick={() =>
                          handleSelectShopping(shopping.secure_id)
                        }
                      />
                    </Can>
                  )}
                </span>
              </Tooltip>
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title="Compra com mais de 30 dias, entre em contato com o suporte para realizar a ação."
                disableHoverListener={
                  shopping.daysDifference < 30
                }
              >
                <span>
                  {shopping.daysDifference >= 30 ? (
                    <Can
                      checkRole={['administrator']}
                    >
                      {can => can ? (
                        <ButtonTable
                          icon={<DeleteIcon color="error" />}
                          background={colors.error}
                          onClick={() =>
                            handleRemoveShopping(shopping.secure_id, shopping.date)
                          }
                        />
                      ) : (
                        <ButtonTable
                          icon={<DeleteIcon color="disabled" />}
                          background={colors.disabled}
                          disabled
                        />
                      )}
                    </Can>
                  ) : (
                    <Can
                      checkRole={['administrator', 'owner', 'beefer']}
                      checkPermission={['purchaseFoods_edit']}
                    >
                      <ButtonTable
                        icon={<DeleteIcon color="error" />}
                        background={colors.error}
                        onClick={() =>
                          handleRemoveShopping(shopping.secure_id, shopping.date)
                        }
                      />
                    </Can>
                  )}
                </span>
              </Tooltip>
            </>
          )}
        </StyledTableCell>
      </TableRow>
      {visibleModal && (
        <ModalPurchaseFoods
          visibleModal={visibleModal}
          handleCloseModal={handleCloseModal}
          secure_id={shopping.secure_id}
        />
      )}
    </>
  )
}

import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2EditFeed(url = null) {
  return useMutation(
    async (data) => {
      const { secure_id, ...newData } = data;

      await apiV2.put(`/v1/feedlot/register/feed/${secure_id}`, newData)
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries('feeds');
        queryClient.refetchQueries('foods');
        queryClient.refetchQueries('diets');
        toast.success('A ração foi alterada com sucesso!');
        if (url) history.push(url);
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao tentar alterar a ração.'
        toast[type](message)
      }
    }
  );
}

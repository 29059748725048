import styled from 'styled-components';
import { Paper, IconButton } from '@material-ui/core';

export const Container = styled.div`
  max-width: 600px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 16px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

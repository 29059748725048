import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Backdrop, Fade } from '@material-ui/core';

import { Container } from './styles';

import DietSwitchRegister from '~/pages/DietSwitch/Register';

export default function ModalDietSwitch({
  paddockId,
  visible,
  handleModal,
  closeModal,
  callback,
}) {
  return (
    <Modal
      open={visible}
      onClose={() => {
        // callback();
        closeModal(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Container>
          <DietSwitchRegister
            paddockId={paddockId}
            closeModal={closeModal}
            isModal
            istroughReading
          />
        </Container>
      </Fade>
    </Modal>
  );
}

ModalDietSwitch.propTypes = {
  paddockId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

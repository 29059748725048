/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  Table,
  TableCell,
  Typography,
  TableRow,
  Grid,
} from '@material-ui/core';

import {
  Container,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableBody,
  Header,
  Column,
  StyledPaperTwo,
} from './styles';

import logo from '~/assets/images/logo_black.png';
import { formatNumber } from '~/utils/format';

export default class PrintActiveLot extends Component {
  render() {
    const {
      date,
      data,
      initialDate,
      finalDate,
    } = this.props;
    return (
      <Container>
        <Header>
          <img src={logo} alt="logo" />
          <div>
            <Typography variant="h2">Relatório zootécnico resumido</Typography>
            <Typography
              variant="h4"
              style={{ width: '100%', textAlign: 'center' }}
            >{`${format(new Date(initialDate), 'dd/MM/yyyy')} - ${format(
              new Date(finalDate),
              'dd/MM/yyyy'
            )}`}</Typography>
          </div>
          <Column>
            <Typography variant="h3">{`Data: ${String(
              format(date, 'dd/MM/yyyy')
            )}`}</Typography>
          </Column>
        </Header>

        <StyledPaper>
          <StyledTableContainer>
            <Table stickyHeader>
              <StyledTableHead>
                <TableRow>
                  <TableCell>Lote</TableCell>
                  <TableCell colSpan="4">Entrada</TableCell>
                  <TableCell colSpan="4">Saída</TableCell>
                  <TableCell colSpan="2">Movimentação</TableCell>
                  <TableCell colSpan="11">Desempenho zootécnico</TableCell>
                  <TableCell colSpan="5">Acabamento de Carcaça</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Lote</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Qtd</TableCell>
                  <TableCell>Peso vivo/an</TableCell>
                  <TableCell>Peso car/an</TableCell>
                  <TableCell>Data</TableCell>
                  <TableCell>Qtd</TableCell>
                  <TableCell>Peso vivo/an</TableCell>
                  <TableCell>Peso car/an</TableCell>
                  <TableCell>% RC</TableCell>
                  <TableCell>Mortes</TableCell>
                  <TableCell>Refugo do cocho</TableCell>
                  <TableCell>Dias de confinamento</TableCell>
                  <TableCell>GMD</TableCell>
                  <TableCell>GMC</TableCell>
                  <TableCell>@ produzidas</TableCell>
                  <TableCell>Rendimento do ganho</TableCell>
                  <TableCell>Cons. MO/dia</TableCell>
                  <TableCell>Cons. MS/dia</TableCell>
                  <TableCell>Conversão alimentar</TableCell>
                  <TableCell>Eficiência alimentar</TableCell>
                  <TableCell>Consumo médio (%PV)</TableCell>
                  <TableCell>Eficiencia biológica</TableCell>
                  <TableCell>Ausente</TableCell>
                  <TableCell>Escasso</TableCell>
                  <TableCell>Mediano</TableCell>
                  <TableCell>Uniforme</TableCell>
                  <TableCell>Excessivo</TableCell>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody>
                {data.zootechnicalLots.map(resum => (
                  <TableRow key={resum.lot_code}>
                    <TableCell>{resum.lot_code}</TableCell>
                    <TableCell>{resum.input_media_date}</TableCell>
                    <TableCell>{resum.input_amount_animals}</TableCell>
                    <TableCell>
                      {formatNumber(resum.input_average_weight, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.input_carcass, 2, 2)}
                    </TableCell>
                    <TableCell>{resum.input_media_date}</TableCell>
                    <TableCell>{resum.output_amount_animals}</TableCell>
                    <TableCell>
                      {formatNumber(resum.output_average_weight, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.output_carcass, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.output_carcass_yield, 2, 2)}
                    </TableCell>
                    <TableCell>{resum.deaths}</TableCell>
                    <TableCell>{resum.trough_refuse}</TableCell>
                    <TableCell>{resum.confinament_days}</TableCell>
                    <TableCell>{formatNumber(resum.gmd, 2, 2)}</TableCell>
                    <TableCell>{formatNumber(resum.gmc, 2, 2)}</TableCell>
                    <TableCell>
                      {formatNumber(resum.arroba_produced, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.yield_gain, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(
                        resum.consumption_mo / resum.confinament_days,
                        2,
                        2
                      )}
                    </TableCell>
                    <TableCell>
                      {formatNumber(
                        resum.consumption_ms / resum.confinament_days,
                        2,
                        2
                      )}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.food_conversion, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.food_efficiency, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.average_pv_consumption_ms, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.biological_efficiency, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.absent, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.scarce, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.median, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.uniform, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.excessivo, 2, 2)}
                    </TableCell>
                  </TableRow>
                ))}
              </StyledTableBody>
            </Table>
          </StyledTableContainer>
        </StyledPaper>
        <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
        <StyledPaperTwo>
          <Header>
            <h3>Resumo</h3>
          </Header>
          <Grid container>
            <Grid item xs sm>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Total de lotes encerrados:
                </Typography>
                <strong>{data.zootechnicalLots.length}</strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Entrada de Animais (total):
                </Typography>
                <strong>{data.zootechnicalResume.inputAnimalsTotal}</strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Animais abatidos:
                </Typography>
                <strong>{data.zootechnicalResume.outputAnimalsTotal}</strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Mortes:
                </Typography>
                <strong>{data.zootechnicalResume.deaths}</strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Mortalidade (%):
                </Typography>
                <strong>{data.zootechnicalResume.deathPerCent}</strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography ypography variant="body2" component="h5">
                  Refugo do cocho:
                </Typography>
                <strong>{data.zootechnicalResume.troughRefuse}</strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography ypography variant="body2" component="h5">
                  Refugo do cocho (%):
                </Typography>
                <strong>{data.zootechnicalResume.troughRefusePerCent}</strong>
              </Grid>
            </Grid>
            <Grid item xs ms>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Peso vivo de entrada (kgPV/an):
                </Typography>
                <strong>
                  {formatNumber(data.zootechnicalResume.averageWeightInput, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Peso vivo de entrada (@/an):
                </Typography>
                <strong>
                  {formatNumber(
                    data.zootechnicalResume.averageCarcassInputWeightPerArroba,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Peso carcaça entrada (kgPV/an):
                </Typography>
                <strong>
                  {formatNumber(
                    data.zootechnicalResume.averageCarcassInputWeight,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center" style={{ marginTop: 24 }}>
                <Typography variant="body2" component="h3">
                  Peso vivo de saída (kgPV/an):
                </Typography>
                <strong>
                  {formatNumber(data.zootechnicalResume.averageWeightOutput, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Peso de carcaça (Kg/an):
                </Typography>
                <strong>
                  {formatNumber(data.zootechnicalResume.averageCarcassOutputWeight, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Peso de carcaça (@/an):
                </Typography>
                <strong>
                  {formatNumber(
                    data.zootechnicalResume.averageCarcassOutputWeightPerArroba,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center" style={{ marginTop: 24 }}>
                <Typography variant="body2" component="h3">
                  Dias médio de confinamento:
                </Typography>
                <strong>
                  {formatNumber(
                    data.zootechnicalResume.averageConfinementDays,
                    0,
                    0
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Ganho médio diário:
                </Typography>
                <strong>
                  {formatNumber(data.zootechnicalResume.averageGmd, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Ganho médio de carcaça:
                </Typography>
                <strong>
                  {formatNumber(data.zootechnicalResume.averageGmc, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Rendimento de carcaça (%):
                </Typography>
                <strong>
                  {formatNumber(
                    data.zootechnicalResume.carcassYield,
                    2,
                    2
                  )}
                </strong>
              </Grid>

              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Ganho (@/an):
                </Typography>
                <strong>
                  {formatNumber(
                    data.zootechnicalResume.gain,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Rendimento do ganho (%)
                </Typography>
                <strong>
                  {formatNumber(
                    data.zootechnicalResume.earningIncome,
                    2,
                    2
                  )}
                </strong>
              </Grid>
            </Grid>
            <Grid item xs sm>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Consumo MO/an/dia:
                </Typography>
                <strong>
                  {formatNumber(data.zootechnicalResume.consumeMoPerDay, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Consumo MS/an/dia:
                </Typography>
                <strong>
                  {formatNumber(data.zootechnicalResume.consumeMsPerDay, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Consumo médio (%pv):
                </Typography>
                <strong>
                  {formatNumber(data.zootechnicalResume.averageConsumption, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Conversão alimentar (kgMs/kgPv):
                </Typography>
                <strong>
                  {formatNumber(
                    data.zootechnicalResume.averageFoodConversion,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Eficiência biológica (kgMs/@):
                </Typography>
                <strong>
                  {formatNumber(
                    data.zootechnicalResume.averageEfficiencyBiological,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography ypography variant="body2" component="h5">
                  Acabamento de carcaça (Ausente):
                </Typography>
                <strong>
                  {formatNumber(data.zootechnicalResume.ausentCarcass / data.zootechnicalResume.outputAnimalsTotal, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography ypography variant="body2" component="h5">
                  Acabamento de carcaça (Escasso):
                </Typography>
                <strong>
                  {formatNumber(data.zootechnicalResume.escassCarcass / data.zootechnicalResume.outputAnimalsTotal, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography ypography variant="body2" component="h5">
                  Acabamento de carcaça (Mediano):
                </Typography>
                <strong>
                  {formatNumber(data.zootechnicalResume.medianCarcass / data.zootechnicalResume.outputAnimalsTotal, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography ypography variant="body2" component="h5">
                  Acabamento de carcaça (Uniforme):
                </Typography>
                <strong>
                  {formatNumber(
                    data.zootechnicalResume.uniformCarcass / data.zootechnicalResume.outputAnimalsTotal,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography ypography variant="body2" component="h5">
                  Acabamento de carcaça (Excessivo):
                </Typography>
                <strong>
                  {formatNumber(
                    data.zootechnicalResume.excessiveCarcass / data.zootechnicalResume.outputAnimalsTotal,
                    2,
                    2
                  )}
                </strong>
              </Grid>
            </Grid>
          </Grid>
        </StyledPaperTwo>
      </Container>
    );
  }
}

PrintActiveLot.propTypes = {
  date: PropTypes.string.isRequired,
  initialDate: PropTypes.string.isRequired,
  finalDate: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
};

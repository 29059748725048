import styled from 'styled-components';
import { Paper, Button, Typography, Grid, TableHead } from '@material-ui/core';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const StyledPaper = styled(Paper)`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 80%;
  max-width: 960px;
  padding: 10px;
  border-radius: 5px;
  overflow: hideen;
`;

export const StyledTitleGrid = styled(Grid)`
  margin-bottom: 12px;
  margin-top: 4px;

  #empty {
    width: 112px;
  }
`;

export const StyledTitleButton = styled(Button)`
  margin-left: 10px;
`;

export const Title = styled(Typography)`
  text-transform: capitalize;
  text-align: center;
`;

export const StyledGrid = styled(Grid)`
  margin-top: 15px;
  margin-bottom: 30px;
`;

export const StyledGridItem = styled(Grid)`
  display: flex;
  justify-content: center;
`;

export const ContentTable = styled.div`
  max-width: 760px;

  align-self: center;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -18px;
  right: -16px;
  border: none;
  background: none;

  svg {
    background: ${colors.white};
    border-radius: 50%;
    overflow: hidden;
    transition: background 2s;

    &:hover {
      background: ${darken(0.08, colors.white)};
    }
  }
`;

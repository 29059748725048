import React, { useState, useRef } from 'react';

import ReactToPrint from 'react-to-print';
import {
  Grid,
  Button,
  Table,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import {
  Container,
  ContentPrint,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
  StyledTableCellEllipsis,
  StyledTableBody,
  Header,
  StyledPaperTwo,
  StyledTableCellSticky,
  TableCellFixed,
} from './styles';

import Print from './Print';
import Filter from './Components/Filter';

import { formatDateOnly, formatNumber } from '~/utils/format';
import Loader from '~/components/Loader';
import { useApiV2ActivePaddock } from '~/hooks/apiV2/Report/activePaddock/useApiV2ActivePaddock';

export default function ActivePaddock() {
  const [filters, setFilters] = useState({
    date: new Date()
  })
  const { data, isLoading } = useApiV2ActivePaddock(filters);

  const PrintScreen = useRef();

  const validatePV = (valuePV, dietTarget) => {
    const result = (Number(valuePV) * 100) / Number(dietTarget);
    if (result < 90) {
      return 'red';
    }
    if (result >= 90 && result < 100) {
      return 'orange';
    }
    if (result >= 100 && result < 120) {
      return 'green';
    }
    return 'blue ';
  };

  return (
    <>
      <center>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs sm md lg xl>
              <Filter
                setFilters={setFilters}
              />
            </Grid>
            <Grid item xs="auto" sm="auto">
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={data && data.activeLots.length === 0}
                    startIcon={<PrintIcon />}
                  >
                    Imprimir
                  </Button>
                )}
                content={() => PrintScreen.current}
              />
              <div style={{ marginTop: 10 }}>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Exportar"
                  style={{
                    padding: 0,
                  }}
                />
              </div>
            </Grid>
          </Grid>

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <StyledPaper>
                <StyledTableContainer>
                  <Table stickyHeader id="table-to-xls">
                    <StyledTableHead>
                      <TableRow>
                        <StyledTableCellSticky>Piquete</StyledTableCellSticky>
                        <StyledTableCellSticky>Lote</StyledTableCellSticky>
                        <TableCell>Raças</TableCell>
                        <TableCell>Categoria</TableCell>
                        <TableCell>Qtd</TableCell>
                        <TableCell align="center">Entrada</TableCell>
                        <TableCell align="right">Conf(dias)</TableCell>
                        <TableCell align="right">Trato(dias)</TableCell>
                        <TableCell align="right">Peso(ent.)</TableCell>
                        <TableCell>Dieta</TableCell>
                        <TableCell align="right">MO</TableCell>
                        <TableCell align="right">MS</TableCell>
                        <TableCell align="right">% PV</TableCell>
                        <TableCell align="center">MO/D1</TableCell>
                        <TableCell align="center">MO/D2</TableCell>
                        <TableCell align="center">MO/D3</TableCell>
                        <TableCell align="center">MS/D1</TableCell>
                        <TableCell align="center">MS/D2</TableCell>
                        <TableCell align="center">MS/D3</TableCell>
                        <StyledTableCell align="center">
                          % PV/D1
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          % PV/D2
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          % PV/D3
                        </StyledTableCell>
                        <TableCell align="right">R$/cab/dia</TableCell>
                        <TableCell align="right">Peso(atual)</TableCell>
                        <TableCell align="right">Peso(abate)</TableCell>
                        <TableCell align="right">Data(abate)</TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <StyledTableBody>
                      {data.activeLots &&
                        data.activeLots.length &&
                        data.activeLots.map(module => {
                          return (
                            <>
                              {module.lines.map(line => {
                                return (
                                  <>
                                    <TableRow key={module.module}>
                                      <TableCell colSpan={4}>
                                        <b>
                                          Module: {module.module} - Linha:{' '}
                                          {line.line}
                                        </b>
                                      </TableCell>
                                      <TableCell colSpan={24} />
                                    </TableRow>
                                    {line.paddocks.map(lot => {
                                      const colorPV1 = validatePV(
                                        lot.percentPV[0],
                                        lot.dietTarget
                                      );
                                      const colorPV2 = validatePV(
                                        lot.percentPV[1],
                                        lot.dietTarget
                                      );
                                      const colorPV3 = validatePV(
                                        lot.percentPV[2],
                                        lot.dietTarget
                                      );
                                      return (
                                        <TableRow key={lot.code}>
                                          <TableCellFixed>
                                            {lot.paddock.name}
                                          </TableCellFixed>
                                          <TableCell>{lot.code}</TableCell>

                                          <StyledTableCellEllipsis>
                                            {lot.breed_names}
                                          </StyledTableCellEllipsis>
                                          <StyledTableCellEllipsis>
                                            {lot.types}
                                          </StyledTableCellEllipsis>
                                          <TableCell align="right">
                                            {lot.amount_animals}
                                          </TableCell>
                                          <TableCell align="center">
                                            {formatDateOnly(
                                              lot.date_animal_inputs
                                            )}
                                          </TableCell>
                                          <TableCell align="right">
                                            {lot.days_active.toFixed(0)}
                                          </TableCell>
                                          <TableCell align="right">
                                            {lot.days_treatments.toFixed(0)}
                                          </TableCell>
                                          <TableCell align="right">
                                            {formatNumber(
                                              lot.average_weight_input,
                                              0,
                                              2
                                            )}
                                          </TableCell>
                                          <TableCell>
                                            {lot.diet_name}
                                          </TableCell>
                                          <TableCell align="right">
                                            {formatNumber(
                                              lot.total_average_consumption_performed_mo,
                                              2,
                                              2
                                            )}
                                          </TableCell>
                                          <TableCell align="right">
                                            {formatNumber(
                                              lot.total_average_consumption_performed_ms,
                                              2,
                                              2
                                            )}
                                          </TableCell>
                                          <TableCell align="right">
                                            {formatNumber(
                                              lot.total_percent_performed_pv,
                                              2,
                                              2
                                            )}
                                          </TableCell>
                                          <TableCell align="right">
                                            {lot.mo[0] &&
                                              formatNumber(lot.mo[0], 2, 2)}
                                          </TableCell>
                                          <TableCell align="right">
                                            {lot.mo[1] &&
                                              formatNumber(lot.mo[1], 2, 2)}
                                          </TableCell>
                                          <TableCell align="right">
                                            {lot.mo[2] &&
                                              formatNumber(lot.mo[2], 2, 2)}
                                          </TableCell>

                                          <TableCell align="right">
                                            {lot.ms[0] &&
                                              formatNumber(lot.ms[0], 2, 2)}
                                          </TableCell>
                                          <TableCell align="right">
                                            {lot.ms[1] &&
                                              formatNumber(lot.ms[1], 2, 2)}
                                          </TableCell>
                                          <TableCell align="right">
                                            {lot.ms[2] &&
                                              formatNumber(lot.ms[2], 2, 2)}
                                          </TableCell>
                                          <TableCell align="right">
                                            <p style={{ color: colorPV1 }}>
                                              {lot.percentPV[0] &&
                                                formatNumber(
                                                  lot.percentPV[0],
                                                  2,
                                                  2
                                                )}
                                            </p>
                                          </TableCell>
                                          <TableCell align="right">
                                            <p style={{ color: colorPV2 }}>
                                              {lot.percentPV[1] &&
                                                formatNumber(
                                                  lot.percentPV[1],
                                                  2,
                                                  2
                                                )}
                                            </p>
                                          </TableCell>
                                          <TableCell align="right">
                                            <p style={{ color: colorPV3 }}>
                                              {lot.percentPV[2] &&
                                                formatNumber(
                                                  lot.percentPV[2],
                                                  2,
                                                  2
                                                )}
                                            </p>
                                          </TableCell>
                                          <TableCell align="right">
                                            {formatNumber(
                                              lot.price_animal_day,
                                              2,
                                              2
                                            )}
                                          </TableCell>
                                          <TableCell align="right">
                                            {formatNumber(
                                              lot.average_current_weight,
                                              2,
                                              2
                                            )}
                                          </TableCell>
                                          <TableCell align="right">
                                            {formatNumber(
                                              lot.average_weight_forecast,
                                              2,
                                              2
                                            )}
                                          </TableCell>
                                          <TableCell align="center">
                                            {lot.date_forecast_slaughter &&
                                              formatDateOnly(
                                                lot.date_forecast_slaughter
                                              )}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </>
                                );
                              })}
                            </>
                          );
                        })}
                    </StyledTableBody>
                  </Table>
                </StyledTableContainer>
              </StyledPaper>
              {data.activeLots && data.activeLots.length ? (
                <StyledPaperTwo>
                  <Header>
                    <h3>Resumo</h3>
                  </Header>
                  <Grid container>
                    <Grid item xs sm>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Total de lotes ativos:
                        </Typography>
                        <strong>{data.resumeLots.totalLots}</strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Total de animais:
                        </Typography>
                        <strong>{data.resumeLots.totalAnimals} </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Peso de entrada:
                        </Typography>
                        <strong>
                          {formatNumber(data.resumeLots.totalInputWeight, 2, 2)}{' '}
                          kg
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Peso atual:
                        </Typography>
                        <strong>
                          {formatNumber(
                            data.resumeLots.totalCurrentWeight,
                            2,
                            2
                          )}{' '}
                          kg
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Consumo Verde:
                        </Typography>
                        <strong>
                          {data.resumeLots.consumptionGreen} lotes (
                          {formatNumber(
                            (data.resumeLots.consumptionGreen * 100) /
                            (data.resumeLots.consumptionGreen +
                              data.resumeLots.consumptionOrange +
                              data.resumeLots.consumptionRed),
                            2,
                            2
                          )}
                          %) - referência % PV D1
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Consumo Laranja:
                        </Typography>
                        <strong>
                          {data.resumeLots.consumptionOrange} lotes (
                          {formatNumber(
                            (data.resumeLots.consumptionOrange * 100) /
                            (data.resumeLots.consumptionGreen +
                              data.resumeLots.consumptionOrange +
                              data.resumeLots.consumptionRed),
                            2,
                            2
                          )}
                          %) - referência % PV D1
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography ypography variant="body2" component="h5">
                          Consumo Vermelho:
                        </Typography>
                        <strong>
                          {data.resumeLots.consumptionRed} lotes (
                          {formatNumber(
                            (data.resumeLots.consumptionRed * 100) /
                            (data.resumeLots.consumptionGreen +
                              data.resumeLots.consumptionOrange +
                              data.resumeLots.consumptionRed),
                            2,
                            2
                          )}
                          %) - referência % PV D1
                        </strong>
                      </Grid>
                    </Grid>
                    <Grid item xs ms>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Consumo médio geral (mo/cab/dia):
                        </Typography>
                        <strong>
                          {formatNumber(
                            data.resumeLots.consumptionAverageMo,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Consumo médio geral (ms/cab/dia):
                        </Typography>
                        <strong>
                          {formatNumber(
                            data.resumeLots.consumptionAverageMs,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Consumo médio geral (%pv):
                        </Typography>
                        <strong>
                          {formatNumber(
                            data.resumeLots.consumptionAveragePV,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Consumo médio geral D1 (mo/cab/dia):
                        </Typography>
                        <strong>
                          {formatNumber(
                            data.resumeLots.consumptionAverageMoD1,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Consumo médio geral D2 (mo/cab/dia):
                        </Typography>
                        <strong>
                          {formatNumber(
                            data.resumeLots.consumptionAverageMoD2,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Consumo médio geral D3 (mo/cab/dia):
                        </Typography>
                        <strong>
                          {formatNumber(
                            data.resumeLots.consumptionAverageMoD3,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Consumo médio geral D1 (ms/cab/dia):
                        </Typography>
                        <strong>
                          {formatNumber(
                            data.resumeLots.consumptionAverageMsD1,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Consumo médio geral D2 (ms/cab/dia):
                        </Typography>
                        <strong>
                          {formatNumber(
                            data.resumeLots.consumptionAverageMsD2,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Consumo médio geral D3 (ms/cab/dia):
                        </Typography>
                        <strong>
                          {formatNumber(
                            data.resumeLots.consumptionAverageMsD3,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Consumo médio geral D1 (%pv):
                        </Typography>
                        <strong>
                          {formatNumber(
                            data.resumeLots.consumptionAveragePVD1,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Consumo médio geral D2 (%pv):
                        </Typography>
                        <strong>
                          {formatNumber(
                            data.resumeLots.consumptionAveragePVD2,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Consumo médio geral D3 (%pv):
                        </Typography>
                        <strong>
                          {formatNumber(
                            data.resumeLots.consumptionAveragePVD3,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                    </Grid>
                  </Grid>
                </StyledPaperTwo>
              ) : (
                ''
              )}
            </>
          )}
        </Container>
      </center>
      <ContentPrint>
        <Print ref={PrintScreen} data={data && data} date={filters.date} />
      </ContentPrint>
    </>
  );
}

import { useQuery } from "react-query";
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';

export async function getTroughSettings() {
  try {
    const { data } = await apiV2.get(`v1/feedlot/global/list/troughSetting`)

    return {
      troughSetting: data
    }
  } catch {
    toast.error('Falha ao tentar carregar as notas!');
  }
}

export function useApiV2GlobalTroughSettings() {
  return useQuery(
    ['troughSetting'],
    () => getTroughSettings(),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

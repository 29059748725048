import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { Grid, TextField, InputAdornment, IconButton } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { StyledContainer, StyledGridItem, StyledSubmitButton, Error } from './styles';

import logo from '~/assets/images/logo_black.png';
import history from '~/services/history';
import colors from '~/styles/colors';
import { useAuthContext } from '~/contexts/AuthContext';

const schema = Yup.object().shape({
  email: Yup.string().required('Informe o e-mail'),
  password: Yup.string().required('Informe a senha'),
});

export default function SignIn() {
  const { signIn } = useAuthContext()

  const [visiblePassword, setVisiblePassword] = useState(false);

  function handleClickShowPassword() {
    setVisiblePassword(!visiblePassword);
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  }

  function handleForgotPassword() {
    history.push('/forgot');
  }

  async function handleLogin({ email, password }) {
    await signIn(email, password)
  }

  return (
    <StyledContainer maxWidth="sm">
      <Formik
        validationSchema={schema}
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={handleLogin}
      >
        {({ handleChange, isSubmitting, errors, touched }) => (
          <Form style={{ width: '100%' }}>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <center>
                <StyledGridItem item marginTop={-30}>
                  <img src={logo} width="300" alt="Beef System" />
                </StyledGridItem>
              </center>

              <StyledGridItem item sm marginTop={30}>
                <TextField
                  id="email"
                  name="email"
                  variant="outlined"
                  fullWidth
                  autoFocus
                  disabled={false}
                  type="email"
                  label="E-mail"
                  error={!!errors.email && touched.email}
                  onChange={handleChange}
                />
                <ErrorMessage name="email" component={Error} />
              </StyledGridItem>
              <StyledGridItem item sm>
                <TextField
                  id="password"
                  name="password"
                  variant="outlined"
                  fullWidth
                  disabled={false}
                  type={visiblePassword ? 'text' : 'password'}
                  label="Senha"
                  error={!!errors.password && touched.password}
                  onChange={handleChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {visiblePassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage name="password" component={Error} />
              </StyledGridItem>

              <center>
                <StyledGridItem item marginTop={70}>
                  <StyledSubmitButton
                    variant="contained"
                    background={colors.success}
                    color={colors.white}
                    type="submit"
                  >
                    {isSubmitting ? 'Entrando...' : 'Entrar'}
                  </StyledSubmitButton>
                </StyledGridItem>
                <StyledGridItem item>
                  <StyledSubmitButton
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={handleForgotPassword}
                  >
                    Perdeu a senha ?
                  </StyledSubmitButton>
                </StyledGridItem>
              </center>
            </Grid>
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
}

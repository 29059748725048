import React, { useEffect, useState, useRef } from 'react';
import { Table, TableRow, TableCell, TableBody, Grid, Button } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Chart from 'react-apexcharts';
import ReactToPrint from 'react-to-print';

//Query
import { useApiV2DietManufacturingEfficiency } from '~/hooks/apiV2/Report/dietManufacturingEfficiency/useApiV2DietManufacturingEfficiency';

import Print from './Print'

import {
  Container,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  ContentPrint
} from './styles';

import Filter from './Components/Filter';

import colors from '~/styles/colors';
import Loader from '~/components/Loader';

export default function DietManufacturingEfficiency() {
  const [foods, setFoods] = useState([]);
  const [series, setSeries] = useState([]);

  const [filters, setFilters] = useState({
    date: new Date(),
    diet: ''
  });

  const PrintScreen = useRef();

  //Query
  const { data, isLoading } = useApiV2DietManufacturingEfficiency(filters);

  useEffect(() => {
    if (data && data.length > 0) {
      const newEfficiencies = data.map(
        efficiency => efficiency.deviation
      );

      const newFoods = data.map(
        efficiency => efficiency.name
      );

      setFoods(newFoods);
      setSeries([
        {
          name: 'Desvio',
          data: newEfficiencies,
        },
      ]);
    } else {
      setFoods([]);
      setSeries([]);
    }
  }, [data]);

  const options = {
    chart: {
      toolbar: {
        show: true,
        tools: {
          zoomin: true,
          zoomout: true,
          zoom: true,
          selection: true,
          reset: false,
          pan: false,
          download: true,
        },
      },
    },
    noData: {
      text: 'Sem dados',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: colors.grey,
        fontSize: '32px',
        fontFamily: 'Roboto',
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    colors: [colors.chartSecondary],
    dataLabels: {
      enabled: true,
      style: {
        colors: [colors.darker],
      },
    },
    tooltip: {
      x: {
        formatter(val) {
          return val;
        },
      },
      y: {
        formatter(val) {
          return `${Math.abs(val)}%`;
        },
      },
    },
    yaxis: {
      title: {
        text: 'Alimentos',
      },
      labels: {
        show: series.length > 0,
      },
    },
    xaxis: {
      title: {
        text: 'Desvio (%)',
      },
      categories: foods,
    },
  };

  return (
    <>
      <center>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs sm md lg xl>
              <Filter
                setFilters={setFilters}
              />
            </Grid>
            <Grid item xs="auto" sm="auto">
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={data && data.length === 0}
                    startIcon={<PrintIcon />}
                  >
                    Imprimir
                  </Button>
                )}
                content={() => PrintScreen.current}
              />
              <div style={{ marginTop: 10 }}>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Exportar"
                  style={{
                    padding: 0,
                  }}
                />
              </div>
            </Grid>
          </Grid>

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <StyledPaper style={{ marginTop: 20, padding: 10 }}>
                <Chart
                  options={options}
                  series={series}
                  type="bar"
                  height="600"
                />
              </StyledPaper>
              <StyledPaper style={{ marginTop: 20 }}>
                <StyledTableContainer>
                  <Table stickyHeader id="table-to-xls">
                    <StyledTableHead>
                      <TableRow>
                        <TableCell>Alimento</TableCell>
                        <TableCell align="right">Qtd.Previsto</TableCell>
                        <TableCell align="center">Qtd. Realizado</TableCell>
                        <TableCell align="center">Desvio (%)</TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <TableBody>
                      {data && data.map(efficiency => (
                        <TableRow key={efficiency.secure_id}>
                          <TableCell component="th" scope="row">
                            {efficiency.name}
                          </TableCell>
                          <TableCell align="right">
                            {efficiency.forecast}
                          </TableCell>
                          <TableCell align="center">
                            {efficiency.performed}
                          </TableCell>
                          <TableCell align="center">
                            {`${efficiency.deviation} %`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              </StyledPaper>
            </>
          )}
        </Container>
      </center>
      <ContentPrint>
        {data && (
          <Print
            ref={PrintScreen}
            data={data}
            date={filters.date}
            series={series}
            foods={foods}
          />
        )}
      </ContentPrint>
    </>
  );
}

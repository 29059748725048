import React, { useState } from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';

import {
  Container,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
} from './styles';

import Can from '~/components/Can';
import Loader from '~/components/Loader';

// Query
import { useApiV2Lot } from '~/hooks/apiV2/register/lot/useApiV2Lot';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';
import { CardTableLot } from './Components/CardTableLot';

export default function Lots() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('lots.code');
  const [direction, setDirection] = useState('asc');

  // Query
  const { data, isLoading } = useApiV2Lot(`${field}%7C${direction}`, page + 1, rowsPerPage)
  const { data: settingsData, isLoading: isLoadingSettings } = useApiV2GlobalSetting();

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  return (
    <center>
      <>
        {isLoading || isLoadingSettings ? (
          <Loader />
        ) : (
          <Container>
            <StyledPaper>
              <StyledTableContainer>
                <Table stickyHeader>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell align="left">
                        <TableSortLabel
                          active={field === 'code'}
                          direction={field === 'lots.code' ? direction : 'asc'}
                          onClick={() => handleChangeOrder('lots.code')}
                        >
                          Código
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={field === 'paddock_name'}
                          direction={field === 'paddock_name' ? direction : 'asc'}
                          onClick={() => handleChangeOrder('paddock_name')}
                        >
                          Piquete
                        </TableSortLabel>
                      </TableCell>
                      <StyledTableCell align="center">
                        <TableSortLabel
                          active={field === 'amount_animals'}
                          direction={field === 'amount_animals' ? direction : 'asc'}
                          onClick={() => handleChangeOrder('amount_animals')}
                        >
                          Quantidade de animais
                        </TableSortLabel>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <TableSortLabel
                          active={field === 'created_at'}
                          direction={
                            field === 'created_at' ? direction : 'asc'
                          }
                          onClick={() => handleChangeOrder('created_at')}
                        >
                          Data de criação
                        </TableSortLabel>
                      </StyledTableCell>
                      <TableCell align="right">
                        <TableSortLabel
                          active={field === 'active'}
                          direction={field === 'active' ? direction : 'asc'}
                          onClick={() => handleChangeOrder('active')}
                        >
                          Ativo
                        </TableSortLabel>
                      </TableCell>
                      <Can checkRole={['administrator', 'owner', 'manager']}>
                        <TableCell align="right" />
                      </Can>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {settingsData && data &&
                      data.lots
                        .map(lot => (
                          <CardTableLot
                            key={lot.secure_id}
                            lot={lot}
                            settings={settingsData.settings}
                          />
                        )
                        )}
                  </TableBody>
                </Table>
              </StyledTableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                labelRowsPerPage="Linhas por pág."
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={data ? data.perPage : 0}
                page={data ? data.page - 1 : 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </StyledPaper>
          </Container>
        )}
      </>
    </center>
  );
}

export function calcPercentMsDiet(foods) {
  const totalInclMS = foods.reduce(
    (total, food) => total + Number(food.dietFoodPivot.percent_incl_ms_food),
    0
  );

  const totalInclMSByPercentFood = foods.reduce(
    (total, food) =>
      total + food.dietFoodPivot.percent_incl_ms_food / (food.percent_ms / 100),
    0
  );

  const totalMSDiet = parseFloat(
    ((totalInclMS / totalInclMSByPercentFood) * 100).toFixed(4)
  );

  return totalMSDiet;
}

export function calcPercentMsPreMix(foods) {
  const { inclMO, percentMS } = foods.reduce(
    (accumulator, food) => {
      accumulator.inclMO += Number(food.pivot.percent_incl_mo_food);
      accumulator.percentMS +=
        (Number(food.percent_ms) * Number(food.pivot.percent_incl_ms_food)) /
        100;

      return accumulator;
    },
    {
      inclMO: 0,
      percentMS: 0,
    }
  );

  const percentMSPreMix = parseFloat((percentMS / inclMO) * 100);

  return percentMSPreMix;
}

export function calcArrobaPrice(
  setFieldValue,
  average_price,
  average_weight,
  percent_rc
) {
  if (average_price && average_weight && percent_rc) {
    const rcAverageWeight = ((percent_rc / 100) * average_weight) / 15;

    const arrobaPrice = average_price / rcAverageWeight;

    setFieldValue('arroba_price', arrobaPrice);
  } else {
    setFieldValue('arroba_price', '');
  }
}

export function calcTreatmentTotalForecast(
  averageWeight,
  amountAnimals,
  percentPvFirstTreaty,
  percentMsDiet
) {
  const msEntry = averageWeight * (percentPvFirstTreaty / 100);
  const totalForecastTreatment =
    (msEntry / (percentMsDiet / 100)) * amountAnimals;

  return parseFloat(totalForecastTreatment.toFixed(2));
}

export function calcTreatmentTotalForecastToughReading(
  performedMs,
  newAnimalAmount,
  valueCorrection,
  percentMsDiet,
  isKgMs = true
) {
  if (!isKgMs) {
    const correctionMs = performedMs * (valueCorrection / 100);
    const newForecastMs = performedMs + correctionMs;
    const forecastMO = newForecastMs / (percentMsDiet / 100);
    const totalForecastTreatment = forecastMO * newAnimalAmount;

    return totalForecastTreatment;
  }
  const newForecastMs = performedMs + valueCorrection;
  const forecastMO = newForecastMs / (percentMsDiet / 100);
  const totalForecastTreatment = forecastMO * newAnimalAmount;

  return totalForecastTreatment;
}

export function calcTreatmentTotalForecastDietSwitch(
  totalPerformed,
  oldAnimalAmount,
  newAnimalAmount,
  percentMsManufactoringDiet,
  percentMsDiet
) {
  const performedMS =
    (totalPerformed * percentMsManufactoringDiet) / oldAnimalAmount;
  const forecastMO = performedMS / percentMsDiet;
  const totalForecastTreatment = forecastMO * newAnimalAmount;

  return totalForecastTreatment;
}

export function calcAverageWeight(
  amountAnimals,
  oldAmountAnimals,
  newAmountAnimals,
  oldAverageWeight,
  newAverageWeight
) {
  const oldWeight = Number(oldAverageWeight) * Number(oldAmountAnimals);
  const newWeight = Number(newAverageWeight) * Number(newAmountAnimals);

  const averageWeight = (oldWeight + newWeight) / Number(amountAnimals);

  return averageWeight;
}

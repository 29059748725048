import styled from 'styled-components';
import { Paper, IconButton } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledPaper = styled(Paper)`
  padding: 12px;
  max-width: 600px;
  width: 100%;
  margin-bottom: 28px;
`;

export const ContentDate = styled.div`
  min-width: 144px;

  input {
    padding-top: 6px;
    padding-bottom: 4px;
  }
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  margin-right: 8px;
`;

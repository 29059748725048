import React, { useState, useEffect } from 'react';

import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';

// Query
import { useApiV2TroughSetting } from '~/hooks/apiV2/register/troughSetting/useApiV2TroughSetting';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

import {
  Container,
  StyledGrid,
  StyledPaper,
  ContentButton,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell
} from './styles';

import history from '~/services/history';
import Can from '~/components/Can';
import Loader from '~/components/Loader';
import ButtonNew from '~/components/ButtonNew';
import { CardTableTroughSetting } from './Components/CardTableTroughSetting';

export default function TroughSetting() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [field, setField] = useState('note');
  const [direction, setDirection] = useState('asc');
  const [correctionType, setCorrectionType] = useState('per_cent');
  const { state: location } = useLocation();
  const [showAllTypes, setShowAllTypes] = useState(false);

  //Query
  const { data, isLoading } = useApiV2TroughSetting(`${field}%7C${direction}`, page + 1, rowsPerPage);
  const { data: settingsData } = useApiV2GlobalSetting();

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleAdd() {
    history.push('/dashboard/troughSetting/add');
  }

  const HandleTitlesTable = () => {
    if (showAllTypes) {
      return (
        <>
          <StyledTableCell align="center">
            <TableSortLabel
              active={field === 'value'}
              direction={field === 'value' ? direction : 'asc'}
              onClick={() => handleChangeOrder('value')}
            >
              Correção (%)
            </TableSortLabel>
          </StyledTableCell>
          <StyledTableCell align="center">
            <TableSortLabel
              active={field === 'value_kg_ms'}
              direction={field === 'value_kg_ms' ? direction : 'asc'}
              onClick={() => handleChangeOrder('value_kg_ms')}
            >
              Correção (Kg/MS)
            </TableSortLabel>
          </StyledTableCell>
        </>
      );
    }
    return correctionType === 'per_cent' ? (
      <StyledTableCell align="center">
        <TableSortLabel
          active={field === 'value'}
          direction={field === 'value' ? direction : 'asc'}
          onClick={() => handleChangeOrder('value')}
        >
          Correção (%)
        </TableSortLabel>
      </StyledTableCell>
    ) : (
      <StyledTableCell align="center">
        <TableSortLabel
          active={field === 'value_kg_ms'}
          direction={field === 'value_kg_ms' ? direction : 'asc'}
          onClick={() => handleChangeOrder('value_kg_ms')}
        >
          Correção (Kg/MS)
        </TableSortLabel>
      </StyledTableCell>
    );
  };

  useEffect(() => {
    if (location && location.showAllTypes === true) {
      setShowAllTypes(true);
    }
  }, [location]);

  useEffect(() => {
    if (settingsData && settingsData.settings.correction_type !== null) {
      setCorrectionType(settingsData.settings.correction_type);
    }
  }, [settingsData]);

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={1}>
          <Grid item sm md lg xl />
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['troughSetting_new']}
          >
            <Grid item sm md lg xl>
              <ContentButton>
                <ButtonNew title="Novo" onClick={handleAdd} />
              </ContentButton>
            </Grid>
          </Can>
        </StyledGrid>

        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell align="center">
                      <TableSortLabel
                        active={field === 'note'}
                        direction={field === 'note' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('note')}
                      >
                        Nota
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">
                      <TableSortLabel
                        active={field === 'description'}
                        direction={field === 'description' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('description')}
                      >
                        Descrição
                      </TableSortLabel>
                    </TableCell>
                    <HandleTitlesTable />
                    <Can checkRole={['administrator', 'owner', 'manager']}>
                      <StyledTableCell align="right" />
                    </Can>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {data && data.troughSettings.map(troughSetting => {
                    if (
                      troughSetting.note !== 'REPLY' &&
                      troughSetting.note !== 'REPLAY'
                    ) {
                      return (
                        <CardTableTroughSetting
                          key={troughSetting.secure_id}
                          troughSetting={troughSetting}
                          showAllTypes={showAllTypes}
                          correctionType={correctionType}
                        />
                      );
                    }
                    return null;
                  })}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

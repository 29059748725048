import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from '@material-ui/core';

// Query
import { useApiV2IndividualAnimalScrapOutput } from '~/hooks/apiV2/animalHandling/IndividualAnimalScrapOutput/useApiV2IndividualAnimalScrapOutput';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

import {
  Container,
  StyledGrid,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledButton,
} from './styles';

import history from '~/services/history';
import Can from '~/components/Can';
import ButtonNew from '~/components/ButtonNew';
import Loader from '~/components/Loader';

import Filter from './Components/Filter';
import { CardTableAnimalScrapOutput } from './Components/CardTableAnimalScrapOutput';

export default function TransferLot() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [date, setDate] = useState(null);
  const [originLot, setOriginLot] = useState(null);

  // Query
  const { data, isLoading } = useApiV2IndividualAnimalScrapOutput('', page + 1, rowsPerPage, date, originLot);
  const { data: settings, isLoading: isLoadingSettings } = useApiV2GlobalSetting();

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={2}>
          <Grid item xs sm md lg xl>
            <Filter
              date={date}
              setDate={setDate}
              originLot={originLot}
              setOriginLot={setOriginLot}
            />
          </Grid>
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['animalScrapOutput_new']}
          >
            <StyledButton container justify="center" spacing={2}>
              <Grid item>
                <ButtonNew
                  title="Novo"
                  onClick={() =>
                    history.push('/dashboard/individualAnimalScrapOutput/add')
                  }
                />
              </Grid>
            </StyledButton>
          </Can>
        </StyledGrid>

        <StyledPaper>
          {isLoading && isLoadingSettings === 0 ? (
            <Loader />
          ) : (
            <>
              <StyledTableContainer>
                <Table stickyHeader>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell align="center">
                        Identificador do animal
                      </TableCell>
                      <TableCell align="center">Lote de origem</TableCell>
                      <TableCell align="center">Observação</TableCell>
                      <TableCell align="center">Data de saída</TableCell>
                      <Can checkRole={['administrator', 'owner', 'manager']}>
                        <TableCell align="right" />
                      </Can>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {settings && data && data.individualAnimalScrapOutput
                      .map(newData => (
                        <CardTableAnimalScrapOutput
                          key={newData.secure_id}
                          data={newData}
                          settings={settings.settings}
                        />
                      ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                labelRowsPerPage="Linhas por pág."
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={data ? data.perPage : 0}
                page={data ? data.page - 1 : 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
        </StyledPaper>
      </Container>
    </center>
  );
}

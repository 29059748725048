import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2AddEmployee() {
  return useMutation(
    async (data) => {
      await apiV2.post('/v1/feedlot/register/employee', data)
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries('employees');
        toast.success('O Funcionário foi cadastrado com sucesso.');
        history.push('/dashboard/employee');
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao tentar cadastrar o funcionário.'
        toast[type](message)
      }
    }
  );
}

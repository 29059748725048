/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import {
  TableContainer,
  Table,
  TableCell,
  Typography,
  TableRow,
} from '@material-ui/core';

import {
  Container,
  Header,
  Column,
  StyledTablePaper,
  StyledTableHead,
  StyledTableBody,
  StyledPaper,
} from './styles';

import logo from '~/assets/images/logo_black.png';
import colors from '~/styles/colors';

export default class PrintDietManufacturingEfficiency extends Component {
  render() {
    const { data, date, series, foods } = this.props;
    const options = {
      chart: {
        toolbar: {
          show: true,
          tools: {
            zoomin: true,
            zoomout: true,
            zoom: true,
            selection: true,
            reset: false,
            pan: false,
            download: true,
          },
        },
        animations: {
          enabled: true
        }
      },
      noData: {
        text: 'Sem dados',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
          color: colors.grey,
          fontSize: '32px',
          fontFamily: 'Roboto',
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors: [colors.chartSecondary],
      dataLabels: {
        enabled: true,
        style: {
          colors: [colors.darker],
        },
      },
      tooltip: {
        x: {
          formatter(val) {
            return val;
          },
        },
        y: {
          formatter(val) {
            return `${Math.abs(val)}%`;
          },
        },
      },
      yaxis: {
        title: {
          text: 'Alimentos',
        },
        labels: {
          show: series.length > 0,
        },
      },
      xaxis: {
        title: {
          text: 'Desvio (%)',
        },
        categories: foods,
      },
    };

    return (
      <Container>
        <Header>
          <img src={logo} alt="logo" style={{ flexBasis: '10%' }} />
          <div
            style={{
              flexBasis: '80%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h6" align="center">Eficiência de Fabricações <br /> de Dieta</Typography>
            <Column>
              <Typography variant="body2">{`Data: ${String(
                format(date, 'dd/MM/yyyy')
              )}`}</Typography>
            </Column>
          </div>
        </Header>

        <StyledPaper elevation={0}>
          {series && (
            <Chart
              options={options}
              series={series}
              type="bar"
              height={350}
              width={700}
            />
          )}
        </StyledPaper>

        <StyledTablePaper elevation={0}>
          <TableContainer>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell>Alimento</TableCell>
                  <TableCell align="right">Qtd.Previsto</TableCell>
                  <TableCell align="center">Qtd. Realizado</TableCell>
                  <TableCell align="center">Desvio (%)</TableCell>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody>
                {data && data.map(efficiency => (
                  <TableRow key={efficiency.secure_id}>
                    <TableCell component="th" scope="row">
                      {efficiency.name}
                    </TableCell>
                    <TableCell align="right">
                      {efficiency.forecast}
                    </TableCell>
                    <TableCell align="center">
                      {efficiency.performed}
                    </TableCell>
                    <TableCell align="center">
                      {`${efficiency.deviation} %`}
                    </TableCell>
                  </TableRow>
                ))}
              </StyledTableBody>
            </Table>
          </TableContainer>
        </StyledTablePaper>
      </Container>
    );
  }
}

PrintDietManufacturingEfficiency.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  series: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  foods: PropTypes.arrayOf(PropTypes.string).isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
};

import React, { useState, useEffect, useRef } from 'react';
import NumberFormat from 'react-number-format';
import ReactToPrint from 'react-to-print';
import {
  Grid,
  Button,
  InputAdornment,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

// Query
import { useApiV2MsBeat } from '~/hooks/apiV2/foodManagement/msBeat/useApiV2MsBeat';

import {
  Container,
  StyledTextFieldNumber,
  StyledTablePaper,
  SpaceTranspCapacity,
  ContentNumber,
  ContentCapacity,
  StyledTableHead,
  StyleTableCell,
  StyledTablePaperMix,
  Title,
  TitleTable,
  ContentPrint,
} from './styles';

import Print from './Print';
import Filter from './Components/Filter';
import TablePreMix from './Components/TablePreMix';
import Loader from '~/components/Loader';
import { formatNumber, roundPrecision } from '~/utils/format';

export default function MSBeats() {
  const [item, setItem] = useState(null);
  const [foods, setFoods] = useState([]);
  const [preMixes, setPreMixes] = useState([]);
  const [capacity, setCapacity] = useState(null);
  const [totalInclMS, setTotalInclMS] = useState(0);
  const [totalInclMO, setTotalInclMO] = useState(0);

  //Query
  const { data, isLoading } = useApiV2MsBeat();

  const PrintScreen = useRef();

  function callBackCapacity(value) {
    if (value) {
      const newFoods = foods.map(food => {
        const newCapacity = roundPrecision(
          parseFloat((food.pivot.percent_incl_mo_food / 100) * value)
        ).toFixed(4);

        return {
          ...food,
          capacity_in_the_wagon: newCapacity,
        };
      });

      setFoods(newFoods);
    } else {
      const newFoods = foods.map(food => ({
        ...food,
        capacity_in_the_wagon: null,
      }));

      setFoods(newFoods);
    }
  }

  function handleChangeCapacity(value) {
    setCapacity(value);

    callBackCapacity(value);
  }

  function setNewPremixes(mix) {
    const newPreMixes = [...preMixes];

    const indexPreMix = newPreMixes.findIndex(item => item.secure_id === mix.secure_id);

    newPreMixes.splice(indexPreMix, 1, mix);

    setPreMixes(newPreMixes);
  }

  useEffect(() => {
    if (item) {
      const allPreMixes = item.foods.filter(food => food.type === 'mix');
      setPreMixes(allPreMixes);
      setFoods(item.foods);
    } else {
      setFoods([]);
      setPreMixes([]);
      setTotalInclMS(0);
      setTotalInclMO(0);
    }

    setCapacity(null);
  }, [item]);

  useEffect(() => {
    if (foods.length > 0) {
      const newTotalInclMS = foods.reduce(
        (total, food) =>
          total + parseFloat(food.pivot.percent_incl_ms_food.toFixed(4)),
        0
      );

      const newTotalInclMO = foods.reduce(
        (total, food) =>
          total + parseFloat(food.pivot.percent_incl_mo_food.toFixed(4)),
        0
      );

      setTotalInclMS(newTotalInclMS.toFixed(4));
      setTotalInclMO(Math.round(newTotalInclMO).toFixed(4));
    }
  }, [foods]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <center>
      <Container>
        <Grid container>
          <Grid xs sm md lg xl>
            <Filter setItem={setItem} data={data} />
          </Grid>
          <Grid item xs="auto" sm="auto">
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!item}
                  startIcon={<PrintIcon />}
                >
                  Imprimir
                </Button>
              )}
              content={() => PrintScreen.current}
            />
          </Grid>
        </Grid>

        <StyledTablePaper>
          <ContentCapacity>
            <SpaceTranspCapacity />
            <ContentNumber>
              <NumberFormat
                id="capcity-input"
                customInput={StyledTextFieldNumber}
                label="Capacidade"
                variant="outlined"
                placeholder="0"
                min="0.00"
                decimalScale={0}
                autoComplete="nope"
                size="small"
                thousandSeparator="."
                decimalSeparator=","
                isNumericString
                value={capacity}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Kg</InputAdornment>
                  ),
                }}
                onValueChange={values =>
                  handleChangeCapacity(values.floatValue)
                }
              />
            </ContentNumber>
          </ContentCapacity>

          <TableContainer>
            <Table stickyHeader>
              <StyledTableHead>
                <TableRow>
                  <TableCell>Alimento</TableCell>
                  <StyleTableCell align="right">MS (%)</StyleTableCell>
                  {item && item.type === 'Ração' ? null : (
                    <StyleTableCell align="right">
                      Incl. MS (%)
                    </StyleTableCell>
                  )}
                  <StyleTableCell align="right">
                    Incl. MO (%)
                  </StyleTableCell>
                  <StyleTableCell align="right">
                    Montagem vagão (Kg)
                  </StyleTableCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {foods.map(food => (
                  <TableRow key={food.secure_id}>
                    <TableCell component="th" scope="row">
                      {food.name}
                    </TableCell>
                    <TableCell align="right">{`${formatNumber(
                      food.percent_ms,
                      0,
                      4
                    )}%`}</TableCell>
                    {item && item.type === 'Ração' ? null : (
                      <TableCell align="right">{`${formatNumber(
                        food.pivot.percent_incl_ms_food,
                        0,
                        4
                      )}%`}</TableCell>
                    )}
                    <TableCell align="right">{`${formatNumber(
                      food.pivot.percent_incl_mo_food,
                      0,
                      4
                    )}%`}</TableCell>
                    <TableCell align="right">
                      {formatNumber(food.capacity_in_the_wagon, 0, 4)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="left">
                    <TitleTable>Total</TitleTable>
                  </TableCell>
                  <TableCell align="right" />
                  {item && item.type === 'Ração' ? null : (
                    <TableCell align="right">{`${formatNumber(
                      totalInclMS,
                      0,
                      4
                    )}%`}</TableCell>
                  )}
                  <TableCell align="right">{`${formatNumber(
                    totalInclMO,
                    0,
                    4
                  )}%`}</TableCell>
                  <TableCell align="right">
                    {formatNumber(capacity, 0, 4)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </StyledTablePaper>

        <br />

        {preMixes.length > 0 && (
          <StyledTablePaperMix>
            <Title color="primary">Pré Misturas</Title>
            <Divider lighth />

            {preMixes.map(food => (
              <TablePreMix
                key={food.secure_id}
                food={food}
                setFood={mix => setNewPremixes(mix)}
              />
            ))}
          </StyledTablePaperMix>
        )}

        <ContentPrint>
          <Print
            ref={PrintScreen}
            item={item}
            foods={foods}
            preMixes={preMixes}
            capacity={capacity}
            totalInclMS={totalInclMS}
            totalInclMO={totalInclMO}
          />
        </ContentPrint>
      </Container>
    </center>
  );
}

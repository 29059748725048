import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { Grid, TextField } from '@material-ui/core';
import { toast } from 'react-toastify';

// Query
import { useApiV2SelectTroughSetting } from '~/hooks/apiV2/register/troughSetting/useApiV2SelectTroughSetting';
import { useApiV2AddTroughSetting } from '~/hooks/apiV2/register/troughSetting/useApiV2AddTroughSetting';
import { useApiV2EditTroughSetting } from '~/hooks/apiV2/register/troughSetting/useApiV2EditTroughSetting';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

import {
  Container,
  StyledPaper,
  StyledGrid,
  ContentItem,
  Error,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';

import history from '~/services/history';
import Loader from '~/components/Loader';
import NumberFormatPercent from '~/components/NumberFormatPercent';
import ButtonSubmit from '~/components/ButtonSubmit';

export default function Register({ match }) {
  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  const [correctionType, setCorrectionType] = useState('per_cent');

  // Query
  const { data: currentTroughSetting, isLoading, error } = useApiV2SelectTroughSetting(secure_id);
  const { data: settingsData } = useApiV2GlobalSetting();
  const addTroughSetting = useApiV2AddTroughSetting();
  const editTroughSetting = useApiV2EditTroughSetting();

  const schema = Yup.object().shape({
    note: Yup.string().required('Informe uma nota'),
    description: Yup.string().nullable(),
  });

  async function handleSubmit(data) {
    if (!data.value_kg_ms && !data.value) {
      toast.warning('Digite um valor.');
      return;
    }
    if (secure_id) {
      await editTroughSetting.mutateAsync(data)
    } else {
      await addTroughSetting.mutateAsync(data)
    }
  }

  function handleBack() {
    history.goBack();
  }

  useEffect(() => {
    if (settingsData && settingsData.settings.correction_type !== null) {
      setCorrectionType(settingsData.settings.correction_type);
    }
  }, [settingsData]);

  if (secure_id && error) {
    history.push('/dashboard/troughSetting');
    toast.error('Falha para carregar os dados da nota!');

    return (
      <center>
        <p>Não foi possível carregar a nota!</p>
      </center>
    )
  }

  return (
    <center>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            enableReinitialize={secure_id}
            validationSchema={schema}
            initialValues={{
              secure_id: currentTroughSetting ? currentTroughSetting.currentTroughSetting.secure_id : null,
              note: currentTroughSetting ? currentTroughSetting.currentTroughSetting.note : '',
              value: currentTroughSetting
                ? String(currentTroughSetting.currentTroughSetting.value) !== ''
                  ? currentTroughSetting.currentTroughSetting.value : ''
                : '',
              description: currentTroughSetting ? currentTroughSetting.currentTroughSetting.description : '',
              value_kg_ms: currentTroughSetting
                ? String(currentTroughSetting.currentTroughSetting.value_kg_ms) !== ''
                  ? currentTroughSetting.currentTroughSetting.value_kg_ms : ''
                : ''
            }}
            onSubmit={handleSubmit}
          >
            {({ handleChange, setFieldValue, errors, touched, values, isSubmitting }) => (
              <Form>
                <ContentItem>
                  <StyledPaper>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={3}>
                        <TextField
                          name="note"
                          variant="outlined"
                          label="Nota *"
                          autoComplete="off"
                          size="small"
                          autoFocus
                          fullWidth
                          value={values.note}
                          error={!!errors.note && touched.note}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="note" component={Error} />
                      </Grid>
                      {correctionType === 'per_cent' ? (
                        <Grid item xs={12} sm={3}>
                          <NumberFormatPercent
                            name="value"
                            label="Correção (%) *"
                            fullWidth
                            value={values.value}
                            error={!!errors.value && touched.value}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage name="value" component={Error} />
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={3}>
                          <NumberFormatPercent
                            name="value_kg_ms"
                            label="Correção (Kg/MS) *"
                            fullWidth
                            value={values.value_kg_ms}
                            error={!!errors.value_kg_ms && touched.value_kg_ms}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage name="value_kg_ms" component={Error} />
                        </Grid>
                      )}
                    </Grid>

                    <StyledGrid container spacing={2}>
                      <Grid item xs={12} sm>
                        <TextField
                          name="description"
                          variant="outlined"
                          label="Descrição"
                          autoComplete="off"
                          size="small"
                          fullWidth
                          multiline
                          value={values.description}
                          error={!!errors.description && touched.description}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="description" component={Error} />
                      </Grid>
                    </StyledGrid>

                    <ContentSubmitButtons>
                      {secure_id ? (
                        <ButtonSubmit title="Alterar" loading={isSubmitting} />
                      ) : (
                        <ButtonSubmit title="Cadastrar" loading={isSubmitting} />
                      )}
                      <StyledSubmitButton
                        variant="outlined"
                        color="primary"
                        type="button"
                        onClick={handleBack}
                      >
                        Cancelar
                      </StyledSubmitButton>
                    </ContentSubmitButtons>
                  </StyledPaper>
                </ContentItem>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </center>
  );
}

Register.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

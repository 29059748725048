import styled from 'styled-components';
import {
  Paper,
  Grid,
  FormControl,
  TextField,
  IconButton,
} from '@material-ui/core';

export const StyledPaper = styled(Paper)`
  max-width: 800px;
  padding: 12px;
`;

export const StyledGrid = styled(Grid)`
  width: 100%;
`;

export const StyledFormControl = styled(FormControl)`
  margin-top: 4px;
  max-width: 194px;
  width: 100%;
`;

export const ContentDate = styled.div`
  max-width: 194px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

export const StyledTextField = styled(TextField)`
  margin: 8px 0px;
  text-align: center;

  input {
    text-align: right;
  }
`;

import React, { useState, useEffect, useMemo } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  TextField,
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { toast } from 'react-toastify'

// Query
import { useApiV2SelectFeed } from '~/hooks/apiV2/register/feed/useApiV2SelectFeed'
import { useApiV2AddFeed } from '~/hooks/apiV2/register/feed/useApiV2AddFeed'
import { useApiV2EditFeed } from '~/hooks/apiV2/register/feed/useApiV2EditFeed'
import { useApiV2GlobalFood } from '~/hooks/apiV2/global/food/useApiV2GlobalFood';

import {
  Container,
  StyledPaper,
  ContentItem,
  ContentSubmitButtons,
  StyledSubmitButton,
  StyledGridItem,
  StyledTableHead,
  FoodName,
  StyledGridFood,
  StyledIconButton,
  StyledButton,
  StyledDivider,
  StyledTableCell,
  TitleTable,
  Error,
  TableCellStyled,
} from './styles';

import { formatNumber } from '~/utils/format';
import history from '~/services/history';
import colors from '~/styles/colors';
import ButtonTable from '~/components/ButtonTable';
import ButtonSubmit from '~/components/ButtonSubmit';
import ModalFood from '~/components/ModalFood';
import Loader from '~/components/Loader';
import NumberFormatPercent from '~/components/NumberFormatPercent';
import { ButtonsChangeOrder } from '~/components/ButtonsChangeOrder'

export default function Register({ match }) {
  const [listFeedFood, setListFeedFood] = useState([]);
  const [totalInclMO, setTotalInclMO] = useState(0);
  const [lackOfTotal, setlackOfTotal] = useState(100);
  const [percentMSFeed, setPercentMSFeed] = useState(0);
  const [messageErroDiet, setMessageErroFeed] = useState('');
  const [messageErro, setMessageErro] = useState('');
  const [currentFood, setCurrentFood] = useState('');
  const [inclPercentMoFood, setInclPercentMoFood] = useState(null);
  const [visibleModalFood, setVisibleModalFood] = useState(false);
  const [hasTreatment, setHasTreatment] = useState();
  const [isManuFeed, setIsManuFeed] = useState(false);

  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  //Query
  const { data: currentFeedData, isLoading, error } = useApiV2SelectFeed(secure_id)
  const { data: foodsData } = useApiV2GlobalFood([true], 'register', null);
  const editFeed = useApiV2EditFeed('/dashboard/feed')

  const schema = Yup.object().shape({
    name: Yup.string().required('Digite o nome'),
    description: Yup.string().nullable(),
  });

  function handleModalFood() {
    setVisibleModalFood(!visibleModalFood);
  }

  function handleAddFoodToList() {
    if (!inclPercentMoFood) {
      setMessageErro('Informe um valor válido!');
      return;
    }

    const existFood = listFeedFood.find(
      feedFood => feedFood.secure_id === currentFood.secure_id
    );

    if (!existFood && Object.values(currentFood).length > 0) {
      const inclMOFood = parseFloat(inclPercentMoFood.toFixed(4));

      if (inclMOFood > 100) {
        setMessageErro('A porcentagem máxima do alimento é 100%');
        return;
      }

      if (inclMOFood < 0) {
        setMessageErro('A porcentagem mínima do alimento é 0%');
        return;
      }

      const totalInclMOFood = parseFloat(
        listFeedFood.reduce(
          (total, food) =>
            total + parseFloat(food.percent_incl_mo_food.toFixed(4)),
          0
        )
      );

      const newTotalInclMO = parseFloat(
        (totalInclMOFood + inclMOFood).toFixed(4)
      );

      // Validation
      if (newTotalInclMO > 100) {
        setMessageErro('A soma de Incl. MO é maior que 100%');
        return;
      }

      if (newTotalInclMO > 0) {
        const lackOfTotalData = newTotalInclMO - 100;
        setlackOfTotal(lackOfTotalData);
      }

      const newFood = {
        secure_id: currentFood.secure_id,
        percent_incl_mo_food: inclMOFood,
        order: listFeedFood.length,
        food: {
          secure_id: currentFood.secure_id,
          name: currentFood.name,
          percent_ms: currentFood.percent_ms,
          type: currentFood.type
        }
      };

      // Calc the new percent MO, with the new total MO for old foods in the list
      const oldFoods = listFeedFood.map((food, index) => ({
        ...food,
        percent_incl_mo_food: food.percent_incl_mo_food,
        order: index
      }));

      const newlistFeedFood = [...oldFoods, newFood];

      setMessageErro('');
      setCurrentFood('');
      setInclPercentMoFood(null);
      setListFeedFood(newlistFeedFood);
    } else if (Object.values(currentFood).length === 0) {
      setMessageErro('Necessário selecionar um alimento!');
    } else {
      setMessageErro('Alimento já inserido na ração.');
    }
  }

  function handleRemoveFoodToList(indexFood) {
    const newlistFeedFood = listFeedFood.filter(
      (food, index) => index !== indexFood
    );

    // Alter all porcents of MO old with the new total MO
    const alterDataFood = newlistFeedFood.map((food, index) => ({
      ...food,
      percent_incl_mo_food: food.percent_incl_mo_food,
      order: index
    }));

    const listTotalInclMO = newlistFeedFood.reduce(
      (total, food) => total + Number(food.percent_incl_mo_food),
      0
    );

    const newLackValue = listTotalInclMO - 100;

    setListFeedFood(alterDataFood);
    setlackOfTotal(newLackValue);
  }

  function handleChangeFood(event) {
    setCurrentFood(event.target.value);
  }

  async function handleSubmit(data) {
    if (totalInclMO !== 100) {
      toast.warning('A porcentagem de Incl. de MO total deve ser 100%');
      return;
    }

    const newFoods = listFeedFood.map(feedFood => ({
      secureId: feedFood.food.secure_id,
      percentInclMoFood: feedFood.percent_incl_mo_food,
      order: feedFood.order
    }));

    const newData = {
      ...data,
      type: 'feed',
      percentMs: Number(percentMSFeed),
      foods: newFoods,
    };

    await editFeed.mutateAsync({
      ...newData,
      secure_id
    })

    setMessageErroFeed('');
  }

  function handleBack() {
    history.push('/dashboard/feed');
  }

  useEffect(() => {
    if (secure_id && currentFeedData && currentFeedData.currentFeed.feeds) {
      setListFeedFood(currentFeedData.currentFeed.feeds);
      setHasTreatment(currentFeedData.currentFeed.hasDiets)
      setIsManuFeed(currentFeedData.currentFeed.hasManufactoringFeeds)
    }
  }, [currentFeedData, secure_id]);

  useEffect(() => {
    const { inclMO, msFeed } = listFeedFood.reduce(
      (accumulator, feedFood) => {
        accumulator.inclMO += Number(feedFood.percent_incl_mo_food);
        accumulator.msFeed +=
          (Number(feedFood.food.percent_ms) *
            Number(feedFood.percent_incl_mo_food)) /
          100;

        // if (Number(feedFood.food.total_amount_ton) > 0) {
        //   accumulator.priceTon +=
        //     (Number(feedFood.food.total_price) /
        //       Number(feedFood.food.total_amount_ton)) *
        //     (Number(feedFood.percent_incl_mo_food) / 100);
        // }

        return accumulator;
      },
      {
        inclMO: 0,
        msFeed: 0,
        priceTon: 0,
      }
    );

    setTotalInclMO(Math.round(inclMO));
    setPercentMSFeed(msFeed);
    // setTotalPriceTon(priceTon);
  }, [listFeedFood]);

  if (secure_id && error) {
    history.push('/dashboard/feed');
    toast.error('Falha para carregar os dados da ração!');

    return (
      <Container>
        <p>Não foi possível carregar a ração!</p>
      </Container>
    )
  }

  return (
    <center>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            enableReinitialize={secure_id}
            validationSchema={schema}
            initialValues={{
              name: (currentFeedData && currentFeedData.currentFeed.name) || '',
              description: (currentFeedData && currentFeedData.currentFeed.description) || '',
            }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              handleSubmit(values, setSubmitting).then(res => {
                setSubmitting(false);
              });
            }}
          >
            {({
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleBlur,
              values,
              setSubmitting,
              isSubmitting,
            }) => (
              <Form>
                <ContentItem>
                  <StyledPaper>
                    <Grid container justify="space-around" spacing={2}>
                      <StyledGridItem item xs={12} sm>
                        <TextField
                          name="name"
                          variant="outlined"
                          label="Ração"
                          autoComplete="nope"
                          autoFocus
                          fullWidth
                          required
                          size="small"
                          placeholder="Digite o nome da ração"
                          value={values.name}
                          error={
                            (!!errors.name && touched.name) ||
                            messageErroDiet !== ''
                          }
                          onChange={handleChange}
                        />
                        {messageErroDiet !== '' ? (
                          <Error>{messageErroDiet}</Error>
                        ) : (
                          <ErrorMessage name="name" component={Error} />
                        )}
                      </StyledGridItem>
                    </Grid>

                    <Grid container justify="space-around" spacing={2}>
                      <StyledGridItem item xs={12} sm>
                        <TextField
                          name="description"
                          variant="outlined"
                          label="Descrição"
                          autoComplete="nope"
                          fullWidth
                          disabled={secure_id && hasTreatment}
                          size="small"
                          placeholder="Digite o nome a descrição"
                          value={values.description}
                          error={!!errors.description && touched.description}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="description" component={Error} />
                      </StyledGridItem>
                    </Grid>

                    <StyledDivider light />

                    <StyledGridFood
                      container
                      direction="row"
                      spacing={2}
                      justify="space-around"
                    >
                      {!isManuFeed ? (
                        <>
                          <Grid item sm xs={9}>
                            <FormControl
                              variant="outlined"
                              size="small"
                              fullWidth
                            >
                              <InputLabel id="food-label">Alimento</InputLabel>
                              <Select
                                labelId="food-label"
                                labelWidth={65}
                                id="food-input"
                                name="food"
                                size="small"
                                disabled={isManuFeed}
                                value={currentFood}
                                onChange={handleChangeFood}
                              >
                                {foodsData && foodsData.map(food => (
                                  <MenuItem
                                    key={food.id}
                                    value={food}
                                    size="small"
                                  >
                                    {food.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Grid>

                          <Grid item sm="auto" xs="auto">
                            <StyledIconButton
                              variant="contained"
                              size="small"
                              onClick={handleModalFood}
                              disabled={isManuFeed}
                            >
                              <AddIcon />
                            </StyledIconButton>
                          </Grid>

                          <Grid item sm xs={7}>
                            <NumberFormatPercent
                              label="Incl. MO (%)"
                              placeholder="0,0000"
                              allowNegative={false}
                              decimalScale={4}
                              onBlur={handleBlur}
                              value={inclPercentMoFood}
                              disabled={isManuFeed}
                              onValueChange={val =>
                                setInclPercentMoFood(val.floatValue)
                              }
                              error={
                                (!!errors.percent_incl_ms_food &&
                                  touched.percent_incl_ms_food) ||
                                messageErro !== ''
                              }
                            />
                            {messageErro !== '' ? (
                              <Error>{messageErro}</Error>
                            ) : null}
                          </Grid>

                          <Grid item sm="auto" xs="auto">
                            <StyledButton
                              variant="contained"
                              color={colors.white}
                              background={colors.success}
                              onClick={handleAddFoodToList}
                              disabled={isManuFeed}
                            >
                              Adicionar
                            </StyledButton>
                          </Grid>
                        </>
                      ) : (
                        <Error>
                          Concentrado/Ração em tratamento, não é permitido
                          editar os alimentos *
                        </Error>
                      )}
                    </StyledGridFood>

                    <TableContainer>
                      <Table stickyHeader>
                        <StyledTableHead>
                          <TableRow>
                            <TableCell align="left">Alimento</TableCell>
                            <TableCell align="right">MS (%)</TableCell>
                            <TableCell align="right">Incl. MO (%)</TableCell>
                            <TableCell align="right" />
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {listFeedFood.map((feed, index) => (
                            <TableRow key={feed.secure_id}>
                              <TableCell component="th" scope="row">
                                <FoodName>
                                  <span>{feed.food.name}</span>
                                </FoodName>
                              </TableCell>
                              <TableCell align="right">
                                {`${formatNumber(
                                  feed.food && feed.food.percent_ms,
                                  2,
                                  2
                                )} %`}
                              </TableCell>
                              <TableCell align="right">
                                {`${formatNumber(
                                  feed.percent_incl_mo_food,
                                  4,
                                  4
                                )} %`}
                              </TableCell>
                              <TableCell align="right">
                                {!isManuFeed && (
                                  <ButtonTable
                                    disabled={isManuFeed}
                                    icon={<DeleteIcon color="error" />}
                                    background={colors.error}
                                    onClick={() =>
                                      handleRemoveFoodToList(index)
                                    }
                                  />
                                )}
                                <ButtonsChangeOrder
                                  setListFood={setListFeedFood}
                                  order={feed.order}
                                  maxOrder={listFeedFood.length - 1}
                                  type="feed"
                                />
                              </TableCell>
                            </TableRow>
                          ))}

                          <TableRow>
                            <TableCell component="th" scope="row" align="left">
                              <TitleTable>Total</TitleTable>
                            </TableCell>
                            <TableCell />
                            <TableCell align="right">
                              {`${formatNumber(totalInclMO, 4, 4)} %`}
                            </TableCell>
                            <TableCell />
                          </TableRow>

                          <TableRow>
                            <StyledTableCell colSpan={2} />
                            {lackOfTotal >= 0 ||
                              (lackOfTotal < 100 && (
                                <>
                                  <TableCell align="left" colSpan={1}>
                                    <TitleTable>% Incompleto</TitleTable>
                                  </TableCell>
                                  <TableCellStyled align="right" size="small">
                                    {`${formatNumber(lackOfTotal, 4, 4)} %`}
                                  </TableCellStyled>
                                </>
                              ))}
                          </TableRow>

                          <TableRow>
                            <StyledTableCell colSpan={2} />
                            <TableCell align="left">
                              <TitleTable>% MS na ração</TitleTable>
                            </TableCell>
                            <TableCell align="right" colSpan={2}>
                              {`${formatNumber(percentMSFeed, 4, 4)} %`}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <ContentSubmitButtons>
                      <StyledSubmitButton
                        variant="outlined"
                        color="primary"
                        onClick={handleBack}
                      >
                        Cancelar
                      </StyledSubmitButton>
                      <ButtonSubmit
                        title="Alterar"
                        loading={isSubmitting}
                        disabled={isSubmitting}
                      />
                    </ContentSubmitButtons>
                  </StyledPaper>
                </ContentItem>
              </Form>
            )}
          </Formik>
        )}

        {visibleModalFood && (
          <ModalFood
            visibleModal={visibleModalFood}
            handleModal={handleModalFood}
          />
        )}
      </Container>
    </center>
  );
}

Register.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import {
  StylesProvider,
  MuiThemeProvider,
  withStyles,
} from '@material-ui/core/styles';
import { theme, global } from '~/styles/styles';

import { queryClient } from '~/services/queryClient';

import Routes from './routes';
import history from './services/history';

// AuthContext for permissions
import { AppProvider } from '~/contexts';

function App() {
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <Router history={history}>
            <CssBaseline />
            <AppProvider>
              <Routes />
            </AppProvider>
            <ToastContainer autoClose={3000} />
            <ReactQueryDevtools />
          </Router>
        </QueryClientProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
}

export default withStyles(global)(App);

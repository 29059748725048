import styled from 'styled-components';
import {
  TableCell,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

export const CheckedBox = styled.div`
  border-radius: 8px;
  border: 2px solid
    ${props => (props.isReplicate ? colors.darkSecondary : colors.primary)};
  background-color: ${props =>
    props.isReplicate ? colors.secondary : colors[props.backgroundColor]};
  width: 58px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;

  p {
    margin: 0;
    text-align: center;
    font-weight: 500;
    color: ${props =>
    props.isReplicate ? colors.darkSecondary : colors[props.textColor]};
  }
`;

import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import Box from '@material-ui/core/Box';
import {
  Grid,
  Divider,
  Typography,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

// Query
import { useApiV2Setting } from '~/hooks/apiV2/user/setting/useApiV2Setting';
import { useApiV2EditSetting } from '~/hooks/apiV2/user/setting/useApiV2EditSetting';

import { useAuthContext } from '~/contexts/AuthContext'

import {
  Container,
  StyledPaper,
  StyledGrid,
  Content,
  ContentSubmitButtons,
  StyledSubmitButton,
  Error,
} from './styles';

import history from '~/services/history';
import Loader from '~/components/Loader';
import NumberFormatForm from '~/components/NumberFormatForm';
import NumberFormatFormNegative from '~/components/NumberFormatFormNegative';

import ButtonSubmit from '~/components/ButtonSubmit';

const schema = Yup.object().shape({
  hasIndividual: Yup.bool(),
  enable_max_deviation: Yup.bool(),
  min_deviation_allow: Yup.number()
    .when('enable_max_deviation', {
      is: true,
      then: fieldSchema => fieldSchema.required('Informe o desvio mínimo'),
    })
    .typeError('Valor inválido'),
  max_deviation_allow: Yup.number()
    .when('enable_max_deviation', {
      is: true,
      then: fieldSchema => fieldSchema.required('Informe o desvio máximo'),
    })
    .typeError('Valor inválido'),
  enable_min_food_stock: Yup.bool(),
  visible_module: Yup.bool(),
  visible_line: Yup.bool(),
  visible_paddock: Yup.bool(),
  // apart: Yup.number().typeError('Valor invÃ¡lido'),
  apart: Yup.mixed()
    .nullable()
    .when('hasIndividual', {
      is: false,
      then: Yup.number()
        .required('Aparte é obrigatório')
        .min(1, 'Aparte deve ser maior que 0')
        .typeError('Preencha o campo com um número'),
    }),
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.shape().isRequired,
  value: PropTypes.shape().isRequired,
};

export default function Setting() {
  const { farm } = useAuthContext()

  const [correctionType, setCorrectionType] = useState('');
  const [dietManufacturingType, setDietManufacturingType] = useState('');
  const [defaultIdentification, setDefaultIdentification] = useState('');
  const [sisbovActive, setSisbovActive] = useState('');
  const { powerUps } = farm;

  // Query
  const { data: settings, isLoading } = useApiV2Setting();
  const alterSettings = useApiV2EditSetting();

  function checkPowerUp(powerUpName, disabled) {
    if (powerUps && powerUps.length > 0) {
      const powerUpsActives = powerUps.map(powerUp => powerUp.name);
      const status = powerUpsActives.includes(powerUpName);
      return !disabled && status ? false : disabled;
    }
    return true;
  }

  const Role = () => {
    const { values, setFieldValue } = useFormikContext();

    useEffect(() => {
      if (!values.enable_max_deviation) {
        setFieldValue('max_deviation_allow', '');
        setFieldValue('min_deviation_allow', '');
      }
    }, [setFieldValue, values]);
    return null;
  };

  async function handleSubmit(values, valueType) {
    if (!values.apart && !checkPowerUp('Individual', false)) {
      toast.error('Necessário informar o Aparte na aba CONTROLE INDIVIDUAL.');
    } else if (!sisbovActive && !checkPowerUp('Individual', false)) {
      toast.error('Necessário informar o controle do SISBOV na aba CONTROLE INDIVIDUAL.');
    } else {
      const data = {
        apart: values.apart ? Number(values.apart) : null,
        correctionType:
          valueType && typeof valueType === 'string'
            ? valueType
            : correctionType,
        defaultIdentification,
        dietManufacturingType,
        enableMaxDeviation: values.enable_max_deviation,
        enableMinFoodStock: values.enable_min_food_stock,
        maxDeviationAllow: values.maxDeviationAllow,
        minDeviationAllow: values.minDeviationAllow,
        sisbovActive,
        visibleLine: values.visible_line,
        visibleModule: values.visible_module,
        visiblePaddock: values.visible_paddock,
        outputInfos: values.output_infos
      }

      await alterSettings.mutateAsync(data);
    }
  }

  function handleBack() {
    history.goBack();
  }

  useEffect(() => {
    if (settings && settings.correction_type) {
      setCorrectionType(settings.correction_type);
    }
    if (settings && settings.default_identification) {
      setDefaultIdentification(settings.default_identification);
    }
    if (settings && settings.sisbov_active) {
      setSisbovActive(settings.sisbov_active);
    }
    if (settings && settings.diet_manufacturing_type) {
      setDietManufacturingType(settings.diet_manufacturing_type);
    }
  }, [settings]);

  const handleCorretionType = (values, valueType) => {
    if (settings && settings.trough_setting_cout > 0) {
      Swal.fire({
        title:
          'Você tem certeza que deseja mudar a correção de leitura de cocho?',
        text: 'Já existem config. de leitura de cocho gravadas',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ok',
      }).then(result => {
        if (result.value) {
          setCorrectionType(valueType);
          handleSubmit(values, valueType);
          // history.push('/dashboard/troughSetting', { showAllTypes: true });
        }
      });
    } else {
      setCorrectionType(valueType);
    }
  };

  const handleChangeManufactoringDietType = (value) => {
    setDietManufacturingType(value)
  };

  return (
    <center>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            validationSchema={schema}
            initialValues={{
              visible_line: settings && settings.visible_line,
              visible_module: settings && settings.visible_module,
              visible_paddock: settings && settings.visible_paddock,
              ...settings,
              hasIndividual: checkPowerUp('Individual', false),
            }}
            onSubmit={handleSubmit}
          >
            {({ handleChange, setFieldValue, errors, touched, values, isSubmitting }) => (
              <Form>
                <TabPanel value={0} index={0}>
                  <div style={{ display: 'flex' }}>
                    <StyledPaper>
                      <StyledGrid
                        container
                        direction="column"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <Typography variant="h6">Desvio de trato</Typography>
                        <Grid item xs="auto" sm="auto">
                          <FormControlLabel
                            name="enable_max_deviation"
                            label="Habilitar"
                            control={
                              <Checkbox
                                checked={values.enable_max_deviation}
                                onChange={handleChange}
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                        <Grid item xs="auto" sm="auto">
                          <Grid container spacing={2}>
                            <Grid item xs sm>
                              <Content>
                                <NumberFormatFormNegative
                                  format="####"
                                  name="min_deviation_allow"
                                  label="Desvio mínimo (%)"
                                  variant="outlined"
                                  size="small"
                                  autoComplete="nope"
                                  decimalScale={4}
                                  disabled={!values.enable_max_deviation}
                                  value={values.min_deviation_allow}
                                  setFieldValue={setFieldValue}
                                  error={
                                    !!errors.min_deviation_allow &&
                                    touched.min_deviation_allow
                                  }
                                  isAllowed={val => {
                                    const { formattedValue, floatValue } = val;
                                    return (
                                      formattedValue === '' ||
                                      formattedValue === '-' ||
                                      (floatValue <= 100 && floatValue >= -100)
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name="min_deviation_allow"
                                  component={Error}
                                />
                              </Content>
                            </Grid>
                            <Grid item xs sm>
                              <Content>
                                <NumberFormatForm
                                  name="max_deviation_allow"
                                  label="Desvio máximo (%)"
                                  variant="outlined"
                                  size="small"
                                  autoComplete="nope"
                                  decimalScale={4}
                                  disabled={!values.enable_max_deviation}
                                  value={values.max_deviation_allow}
                                  setFieldValue={setFieldValue}
                                  error={
                                    !!errors.max_deviation_allow &&
                                    touched.max_deviation_allow
                                  }
                                  isAllowed={val => {
                                    const { formattedValue, floatValue } = val;
                                    return (
                                      formattedValue === '' ||
                                      formattedValue === '-' ||
                                      (floatValue <= 100 && floatValue >= -100)
                                    );
                                  }}
                                />
                                <ErrorMessage
                                  name="max_deviation_allow"
                                  component={Error}
                                />
                              </Content>
                            </Grid>
                          </Grid>
                        </Grid>
                      </StyledGrid>

                      <Divider light />

                      <StyledGrid
                        container
                        direction="column"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <Typography variant="h6">
                          Alerta de estoque mínimo
                        </Typography>
                        <Grid item xs="auto" sm="auto">
                          <FormControlLabel
                            name="enable_min_food_stock"
                            label="Habilitar"
                            control={
                              <Checkbox
                                checked={values.enable_min_food_stock}
                                onChange={handleChange}
                                color="primary"
                              />
                            }
                          />
                        </Grid>
                      </StyledGrid>

                      <Divider light />

                      <StyledGrid
                        container
                        direction="column"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <Typography variant="h6">
                          Visualização do piquete
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs="auto" sm="auto">
                            <FormControlLabel
                              name="visible_module"
                              label="Módulo"
                              control={
                                <Checkbox
                                  checked={values.visible_module}
                                  onChange={handleChange}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs="auto" sm="auto">
                            <FormControlLabel
                              label="Linha"
                              name="visible_line"
                              control={
                                <Checkbox
                                  checked={values.visible_line}
                                  onChange={handleChange}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                          <Grid item xs="auto" sm="auto">
                            <FormControlLabel
                              label="Piquete"
                              name="visible_paddock"
                              control={
                                <Checkbox
                                  checked={values.visible_paddock}
                                  onChange={handleChange}
                                  color="primary"
                                />
                              }
                            />
                          </Grid>
                        </Grid>
                      </StyledGrid>

                      <Divider light />

                      <StyledGrid
                        container
                        direction="column"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <Typography variant="h6">
                          Correção de leitura de cocho
                        </Typography>
                        <Grid item xs="auto" sm="auto">
                          <Select
                            id="correction_type"
                            style={{ minWidth: 250 }}
                            fullWidth
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                            }}
                            value={correctionType}
                            onChange={event =>
                              handleCorretionType(values, event.target.value)
                            }
                          >
                            <MenuItem value="per_cent">
                              Correção em % (porcentagem)
                            </MenuItem>
                            <MenuItem value="kg_ms">Correção em Kg/MS</MenuItem>
                          </Select>
                        </Grid>
                      </StyledGrid>

                      <StyledGrid
                        container
                        direction="column"
                        alignItems="flex-start"
                        spacing={1}
                      >
                        <Typography variant="h6">
                          Fabricação de dieta
                        </Typography>
                        <Grid item xs="auto" sm="auto">
                          <Select
                            id="selectModule"
                            style={{ minWidth: 250 }}
                            fullWidth
                            MenuProps={{
                              getContentAnchorEl: null,
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                            }}
                            value={dietManufacturingType}
                            onChange={event =>
                              handleChangeManufactoringDietType(event.target.value)
                            }
                          >
                            <MenuItem value="individual">
                              Individual
                            </MenuItem>
                            <MenuItem value="per_day">Por dia</MenuItem>
                          </Select>
                        </Grid>
                      </StyledGrid>
                    </StyledPaper>
                    {!checkPowerUp('Individual', false) && (
                      <StyledPaper>
                        <Grid item xs="auto" sm="auto">
                          <Content>
                            <StyledGrid
                              container
                              direction="column"
                              alignItems="flex-start"
                              spacing={1}
                            // style={{ maxWidth: 280 }}
                            >
                              <NumberFormatFormNegative
                                format="####"
                                name="apart"
                                label="Aparte"
                                variant="outlined"
                                size="small"
                                autoComplete="nope"
                                decimalScale={4}
                                value={values.apart}
                                onChange={handleChange}
                                error={!!errors.apart && touched.apart}
                              />
                              <ErrorMessage name="apart" component={Error} />
                            </StyledGrid>{' '}
                            <StyledGrid
                              container
                              direction="column"
                              alignItems="flex-start"
                              spacing={1}
                            >
                              <Typography variant="h6">
                                Informações de abate na saída
                              </Typography>
                              <Grid item xs="auto" sm="auto" style={{ width: '100%' }}>
                                <Select
                                  id="output_infos"
                                  name="output_infos"
                                  fullWidth
                                  MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                  }}
                                  value={values.output_infos || 'indidivual'}
                                  onChange={handleChange}
                                >
                                  <MenuItem value="indidivual">Individual</MenuItem>
                                  <MenuItem value="all_animals">Por abate</MenuItem>
                                </Select>
                              </Grid>
                            </StyledGrid>
                            <StyledGrid
                              container
                              direction="column"
                              alignItems="flex-start"
                              spacing={1}
                            >
                              <Typography variant="h6">
                                Utilizar controle SISBOV
                              </Typography>
                              <Grid item xs="auto" sm="auto" style={{ width: '100%' }}>
                                <Select
                                  id="sisbov_active"
                                  fullWidth
                                  MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                  }}
                                  value={sisbovActive}
                                  onChange={event =>
                                    setSisbovActive(event.target.value)
                                  }
                                >
                                  <MenuItem value="yes">SIM</MenuItem>
                                  <MenuItem value="no">NÃO</MenuItem>
                                </Select>
                              </Grid>
                            </StyledGrid>
                            <StyledGrid
                              container
                              direction="column"
                              alignItems="flex-start"
                              spacing={1}
                            >
                              <Typography variant="h6">
                                Identificação Padrão
                              </Typography>
                              <Grid item xs="auto" sm="auto" style={{ width: '100%' }}>
                                <Select
                                  id="default_identification"
                                  fullWidth
                                  MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                  }}
                                  value={defaultIdentification}
                                  onChange={event =>
                                    setDefaultIdentification(event.target.value)
                                  }
                                >
                                  <MenuItem value="sisbov">SISBOV</MenuItem>
                                  <MenuItem value="bottom">BOTTON RFID</MenuItem>
                                  <MenuItem value="ident_earing">BRINCO</MenuItem>
                                </Select>
                              </Grid>
                            </StyledGrid>
                          </Content>
                        </Grid>
                      </StyledPaper>
                    )}
                  </div>
                  <ContentSubmitButtons>
                    <StyledSubmitButton
                      color="primary"
                      variant="outlined"
                      type="button"
                      onClick={handleBack}
                    >
                      Cancelar
                    </StyledSubmitButton>
                    <ButtonSubmit title="Alterar" loading={isSubmitting} />
                  </ContentSubmitButtons>
                </TabPanel>
                <Role />
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </center>
  );
}

import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";

export async function getFilterConsumptionLot() {
  try {
    const { data } = await apiV2.get('/v1/feedlot/report/consumptionLot/filter');

    return data
  } catch {
    toast.error('Não foi possível carregar os dados!');
  }
}

export function useApiV2FilterConsumptionLot() {
  return useQuery(['filterConsumptionLot'],
    () => getFilterConsumptionLot());
}

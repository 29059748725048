import React from 'react';
import Swal from 'sweetalert2';

import {
  TableRow,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

import { useApiV2RemoveOcurrenceType } from '~/hooks/apiV2/register/ocurrenceType/useApiV2RemoveOcurrenceType';

import ActionLoader from '~/components/ActionLoader';
import Can from '~/components/Can';
import history from '~/services/history';
import colors from '~/styles/colors';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';

export function CardTableOcurrenceType({ ocurrenceType }) {
  const removeOcurrenceType = useApiV2RemoveOcurrenceType();

  function handleSelect({ secure_id }) {
    history.push(`/dashboard/ocurrencesType/edit/${secure_id}`);
  }

  function handleRemoveOcurrenceType({ secure_id }) {
    async function performRemoveOcurrenceType() {
      await removeOcurrenceType.mutateAsync(secure_id);
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja apagar esse tipo de ocorrência?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar!',
    }).then(async result => {
      if (result.value) {
        performRemoveOcurrenceType();
      }
    });
  }

  return (
    <TableRow>
      <StyledTableCellEllipsis component="th" scope="row">
        {ocurrenceType.name}
      </StyledTableCellEllipsis>
      <StyledTableCellEllipsis>
        {ocurrenceType.description}
      </StyledTableCellEllipsis>
      <StyledTableCell align="right">
        {removeOcurrenceType.isLoading ? (
          <ActionLoader text="Deletando" />
        ) : (
          <>
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['ocurrences_edit']}
            >
              <ButtonTable
                icon={<EditIcon color="primary" />}
                background={colors.primary}
                lighten={0.6}
                onClick={() => handleSelect(ocurrenceType)}
              />
            </Can>
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['ocurrences_delete']}
            >
              <ButtonTable
                icon={
                  <DeleteIcon
                    color={
                      ocurrenceType.has_ocurrences > 0
                        ? 'inherit'
                        : 'error'
                    }
                  />
                }
                background={
                  ocurrenceType.has_ocurrences > 0
                    ? colors.grey
                    : colors.error
                }
                disabled={ocurrenceType.has_ocurrences > 0}
                onClick={() => handleRemoveOcurrenceType(ocurrenceType)}
              />
            </Can>
          </>
        )}
      </StyledTableCell>
    </TableRow>
  )
}

import React from 'react';
import Swal from 'sweetalert2';
import {
  TableRow,
  TableCell,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

// Query
import { useApiV2RemoveIndividualAnimalsOutput } from '~/hooks/apiV2/animalHandling/IndividualAnimalOutput/useApiV2RemoveIndividualAnimalsOutput'

import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell
} from './styles';
import ButtonTable from '~/components/ButtonTable';
import ActionLoader from '~/components/ActionLoader';
import history from '~/services/history';

export function CardTableAnimalOutput({ data }) {
  const removeIndividualAnimalsOutput = useApiV2RemoveIndividualAnimalsOutput();

  function handleEdit({ secure_id }) {
    history.push(`/dashboard/individualAnimalOutput/edit/${secure_id}`);
  }

  function handleRemove({ secure_id }) {
    async function performRemoveIndividualAnimalTransfer() {
      await removeIndividualAnimalsOutput.mutateAsync(secure_id)
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar essa saída?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar saída!',
    }).then(async result => {
      if (result.value) {
        performRemoveIndividualAnimalTransfer();
      }
    });
  }

  return (
    <TableRow>
      <TableCell align="center">
        {data?.amount_animals}
      </TableCell>
      <TableCell align="center">
        {data?.client?.name}
      </TableCell>
      <TableCell align="center">
        {data?.date}
      </TableCell>
      <Can
        checkRole={['administrator', 'owner', 'manager']}
      >
        <StyledTableCell align="right">
          <ButtonTable
            icon={<EditIcon color="primary" />}
            background={colors.primary}
            lighten={0.6}
            onClick={() => handleEdit(data)}
          />
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['animalOutput_delete']}
          >
            <ButtonTable
              icon={
                removeIndividualAnimalsOutput.isLoading ? (
                  <ActionLoader text="Deletando" />
                ) : (
                  <DeleteIcon
                    color="error"
                  />
                )
              }
              background={colors.error}
              onClick={() =>
                handleRemove(
                  data
                )
              }
            />
          </Can>
        </StyledTableCell>
      </Can>
    </TableRow>
  )
}

import React, { useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import {
  Grid,
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Hidden,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { differenceInDays, subDays } from 'date-fns';

// Query
import { useApiV2SummaryFood } from '~/hooks/apiV2/Report/summaryFood/useApiV2SummaryFood';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

import {
  Container,
  StyledGrid,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableRow,
  StyledTableCell,
  StyledTableCellCons,
  StyledTableCellEllipsis,
  ContentPrint,
} from './styles';

import Print from './Print';

import colors from '~/styles/colors';
import { formatNumber } from '~/utils/format';
import Loader from '~/components/Loader';
import Filter from './Components/Filter';

export default function SummaryFood() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [initialDate, setInitialDate] = useState(subDays(new Date(), 15));
  const [finalDate, setFinalDate] = useState(new Date());

  const PrintScreen = useRef();

  // Query
  const { data, isLoading } = useApiV2SummaryFood(initialDate, finalDate);
  const { data: settingsData, isLoading: isLoadingSettings } = useApiV2GlobalSetting();

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="flex-end">
          <Grid item xs sm md lg xl>
            <Filter
              initialDate={initialDate}
              finalDate={finalDate}
              setInitialDate={setInitialDate}
              setFinalDate={setFinalDate}
            />
          </Grid>
          <Grid item xs="auto" sm="auto">
            <ReactToPrint
              trigger={() => (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={data && data.length === 0}
                  startIcon={<PrintIcon />}
                >
                  Imprimir
                </Button>
              )}
              content={() => PrintScreen.current}
            />
            <div style={{ marginTop: 10 }}>
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button"
                table="table-to-xls"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Exportar"
                style={{
                  padding: 0,
                }}
              />
            </div>
          </Grid>
        </StyledGrid>

        {isLoading || isLoadingSettings ? (
          <Loader />
        ) : (
          <>
            <StyledPaper>
              <StyledTableContainer>
                <Table stickyHeader id="table-to-xls">
                  <StyledTableHead>
                    <TableRow>
                      <TableCell align="left">Alimento</TableCell>
                      <StyledTableCell align="right">% MS</StyledTableCell>
                      <TableCell align="right">Entrada (ton)</TableCell>
                      <TableCell align="right">Saída (ton)</TableCell>
                      <TableCell align="right">Atual (ton)</TableCell>
                      {settingsData && settingsData.settings.enable_min_food_stock ? (
                        <TableCell align="right">Mínimo (ton)</TableCell>
                      ) : null}
                      <StyledTableCellCons align="right">
                        <Hidden smDown>Cons. Médio ({differenceInDays(finalDate, initialDate)} dias)</Hidden>
                        <Hidden mdUp>
                          <span>Cons. Médio</span>
                          <span>(15 dias)</span>
                        </Hidden>
                      </StyledTableCellCons>
                      <TableCell align="right">Previsão (dias)</TableCell>
                      <TableCell align="right">Preço/Ton</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {data && data
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map(food => (
                        <StyledTableRow
                          key={food.secure_id}
                          background={
                            settingsData &&
                            settingsData.settings.enable_min_food_stock &&
                            food.total_amount_ton < food.min_food_stock_ton &&
                            colors.danger
                          }
                        >
                          <StyledTableCellEllipsis component="th" scope="row">
                            {`${food.name}`}
                          </StyledTableCellEllipsis>
                          <StyledTableCell align="right">
                            {`${formatNumber(food.percent_ms, 2, 4)} %`}
                          </StyledTableCell>
                          <TableCell align="right">
                            {formatNumber(food.total_input_ton, 4, 4)}
                          </TableCell>
                          <TableCell align="right">
                            {formatNumber(food.total_output_ton, 4, 4)}
                          </TableCell>
                          <StyledTableCell align="right">
                            {formatNumber(food.total_amount_ton, 4, 4)}
                          </StyledTableCell>
                          {settingsData && settingsData.settings.enable_min_food_stock ? (
                            <StyledTableCell align="right">
                              {formatNumber(food.min_food_stock_ton, 4, 4)}
                            </StyledTableCell>
                          ) : null}
                          <StyledTableCell align="right">
                            {formatNumber(food.average_consumption, 4, 4)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {formatNumber(food.expected_days, 0, 0)}
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            {`R$ ${formatNumber(food.price_ton, 2, 2)}`}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                labelRowsPerPage="Linhas por pág."
                component="div"
                count={data ? data.length : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </StyledPaper>

            <ContentPrint>
              {settingsData && data && (
                <Print
                  ref={PrintScreen}
                  summaryFood={data}
                  settings={settingsData.settings}
                  differenceDays={differenceInDays(finalDate, initialDate)}
                />
              )}
            </ContentPrint>
          </>
        )}
      </Container>
    </center>
  );
}

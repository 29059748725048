import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import { queryClient } from '~/services/queryClient';

export function useApiV2RemoveTroughReading() {
  return useMutation(
    async (secure_id) => {

      const { data: response } = await apiV2.delete(
        `/v1/feedlot/food-management/troughReading/${secure_id}`
      );

      return response
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();

        toast.success('Leitura apagada com sucesso!');
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Não foi possível apagar leitura!'
        toast[type](message)
      }
    }
  );
}

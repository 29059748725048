import styled from 'styled-components';
import { Paper, Grid, Button, TextField } from '@material-ui/core';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.div`
  /* padding: 16px 0px 15px 0px; */
  width: 100%;
  height: 100%;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 22px;
`;

export const StyledTextField = styled(TextField)`
  margin-right: 18px;
  margin-bottom: 18px;

  @media (max-width: 600px) {
    margin-top: 18px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
`;

export const StyledButton = styled(Button)`
  color: ${colors.white};
  margin: 6px 0 0 16px;
  transition: background 2s;

  &:hover {
    background: ${darken(0.06, colors.success)};
  }
`;

export const AnimalInfos = styled(Grid)`
  margin: 16px 0 16px 0;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  padding-right: 16px;
`;

export const StyledGrid = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`;

export const CardTitle = styled.p`
  color: ${colors.primary};
  text-align: center;
  font-size: 12px;
  font-weight: 500;
`;

export const CardBody = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`;

export const Card = styled(Grid)`
  background-color: #f9f9f9;
  padding: 8px;
  border-radius: 8px;
`;

export const FindAnimalContainer = styled.div`
  display: flex;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const WeightContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Error = styled.span`
  /* margin: 0px 14px 0px; */
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

import React, { useState, useEffect } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';
import PropTypes from 'prop-types';

// Query
import { useApiV2GlobalLots } from '~/hooks/apiV2/global/lot/useApiV2GlobalLots';
import { useApiV2GlobalBreeds } from '~/hooks/apiV2/global/breed/useApiV2GlobalBreeds';

import {
  Content,
  StyledTablePaper,
  ContentDate,
  StyledGridItem,
  StyledIconButton,
} from './styles';

import { regexDate } from '~/utils/common';
import InputDatePicker from '~/components/InputDatePicker';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.value,
});

const breedDefaultOption = {
  secure_id: null,
  name: 'Todas',
};

export default function Filter({
  currentDate,
  setCurrentDate,
  setCurrentBreed,
  setCurrentLot,
}) {
  const [listLots, setListLots] = useState(null);
  const [lots, setLots] = useState([]);
  const [breeds, setBreeds] = useState([]);

  // Query
  const { data: lotsData } = useApiV2GlobalLots([true, false]);
  const { data: breedsData } = useApiV2GlobalBreeds();

  useEffect(() => {
    if (lotsData) {
      setLots(lotsData.lots);
    }

    if (breedsData) {
      setBreeds(breedsData.breeds);
    }
  }, [lotsData, breedsData]);

  function handleChangeDate(date, value) {
    if (value && !regexDate.test(value)) return;

    setCurrentDate(date);
  }

  function handleSelectLot(value) {
    if (value) {
      setCurrentLot(value.secuere_id);
    } else {
      setCurrentLot(null);
    }
  }

  function handleChangeBreed(event) {
    if (event.target.value) {
      setCurrentBreed(event.target.value.secure_id);
    }
  }

  useEffect(() => {
    const newList = lots.map(lot => ({
      secuere_id: lot.secure_id,
      value: String(lot.code),
    }));

    setListLots(newList);
  }, [lots]);

  return (
    <Content>
      <StyledTablePaper>
        <Grid container justify="space-around" spacing={2}>
          <Grid item xs={12} sm>
            <ContentDate>
              <InputDatePicker
                label="Data Inicial"
                maxDate={new Date()}
                selectedDate={currentDate}
                handleChangeDate={(date, value) =>
                  handleChangeDate(date, value)
                }
                InputProps={{
                  startAdornment: (
                    <StyledIconButton onClick={() => handleChangeDate(null)}>
                      <ClearIcon />
                    </StyledIconButton>
                  ),
                }}
                InputAdornmentProps={{
                  position: 'end',
                }}
              />
            </ContentDate>
          </Grid>
          <StyledGridItem item xs={12} sm>
            <Autocomplete
              name="lot"
              size="small"
              style={{ height: 38, width: '100%' }}
              options={listLots}
              noOptionsText="Sem opções"
              filterOptions={filterOptions}
              getOptionLabel={option => option.value}
              onChange={(e, value) => {
                handleSelectLot(value);
              }}
              renderInput={params => (
                <TextField {...params} label="Lote" fullWidth />
              )}
            />
          </StyledGridItem>
          <StyledGridItem item xs={12} sm>
            <FormControl size="small" fullWidth>
              <InputLabel id="input-diet">Raça</InputLabel>
              <Select
                id="diet"
                defaultValue={breedDefaultOption}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                onChange={handleChangeBreed}
              >
                <MenuItem value={breedDefaultOption}>
                  {breedDefaultOption.name}
                </MenuItem>
                {breeds.map(item => (
                  <MenuItem key={item.secure_id} value={item}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledGridItem>
        </Grid>
      </StyledTablePaper>
    </Content>
  );
}

Filter.defaultProps = {
  currentDate: null,
};

Filter.propTypes = {
  currentDate: PropTypes.string,
  setCurrentDate: PropTypes.func.isRequired,
  setCurrentBreed: PropTypes.func.isRequired,
  setCurrentLot: PropTypes.func.isRequired,
};

import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination
} from '@material-ui/core';

// Query
import { useApiV2IndividualTransferApartLot } from '~/hooks/apiV2/animalHandling/IndividualTransferApartLot/useApiV2IndividualTransferApartLot';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

import {
  Container,
  StyledGrid,
  StyledTablePaper,
  StyledTableContainer,
  StyledTableHead,
} from './styles';

import history from '~/services/history';
import Can from '~/components/Can';
import Loader from '~/components/Loader';
import ButtonNew from '~/components/ButtonNew';

import Filter from './Components/Filter';
import { CardAnimalInputLotApart } from './Components/CardAnimalInputLotApart';

export default function AnimalInputLotApart() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [field, setField] = useState('lot_id');
  const [direction, setDirection] = useState('asc');
  const [currentLot, setCurrentLot] = useState(null);
  const [currentDate, setCurrentDate] = useState(null);
  const [currentApart, setCurrentApart] = useState(null);

  const { data, isLoading } = useApiV2IndividualTransferApartLot(`${field}%7C${direction}`, page + 1, rowsPerPage, currentDate, currentLot, currentApart);
  const { data: settings } = useApiV2GlobalSetting();

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleAddAnimal() {
    history.push('/dashboard/animalInputApart/add');
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={2}>
          <Grid item xs sm md lg xl>
            <Filter
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              setCurrentApart={setCurrentApart}
              setCurrentLot={setCurrentLot}
              settings={settings && settings.settings}
            />
          </Grid>
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['animalInput_new']}
          >
            <Grid item xs="auto" sm="auto">
              <ButtonNew title="Nova" onClick={handleAddAnimal} />
            </Grid>
          </Can>
        </StyledGrid>

        {isLoading ? (
          <Loader />
        ) : (
          <StyledTablePaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'apart'}
                        direction={field === 'apart' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('apart')}
                      >
                        Aparte
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'date'}
                        direction={field === 'date' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('date')}
                      >
                        Data
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      Lote
                    </TableCell>
                    <TableCell align="right">
                      <TableSortLabel
                        active={field === 'amount_animals'}
                        direction={
                          field === 'amount_animals' ? direction : 'asc'
                        }
                        onClick={() => handleChangeOrder('amount_animals')}
                      >
                        Quantidade
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="right" />
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {settings && data
                    && data.animalsInputLotApart
                      .map(animal => (
                        <CardAnimalInputLotApart
                          key={animal.secure_id}
                          animal={animal}
                          settings={settings.settings}
                        />
                      ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledTablePaper>
        )}
      </Container>
    </center>
  );
}

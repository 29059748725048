import styled from 'styled-components';
import {
  Paper,
  TableHead,
  TableCell,
  TextField,
  Typography,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 1280px;
  width: 100%;
  padding: 40px;
`;

export const StyledTablePaper = styled(Paper)`
  width: 100%;
  text-align: left;
`;

export const ContentCapacity = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SpaceTranspCapacity = styled.div`
  position: relative;
  display: flex;
  flex: 1;
`;

export const ContentNumber = styled.div`
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;

  background: ${colors.white};
`;

export const StyledTextFieldNumber = styled(TextField)`
  margin: 12px;

  align-self: flex-end;

  label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 22px;
    bottom: 0px;
  }

  .Mui-focused {
    overflow: visible;
    right: 0px;
  }
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const StyleTableCell = styled(TableCell)`
  white-space: nowrap;
`;

export const StyledTablePaperMix = styled(Paper)`
  width: 100%;
`;

export const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 400;
  text-align: center;

  padding: 18px 6px 8px;
`;

export const TitleTable = styled.b`
  color: ${colors.primary};
`;

export const ContentPrint = styled.div`
  display: none;
`;

import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function NewDatePicker({
  selectedDate,
  handleChangeDate,
  disabled = false,
  label,
  ...props
}) {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        margin="normal"
        size="small"
        label={label || 'Data'}
        invalidDateMessage="Data inválida"
        maxDateMessage="A data não deve ser posterior à data máxima"
        minDateMessage="A data não deve ser anterior à data mínima"
        autoComplete="nope"
        autoOk
        clearable
        {...props}
        disabled={disabled}
        value={selectedDate}
        onChange={handleChangeDate}
        style={{ marginTop: 0, marginBottom: 0 }}
      />
    </MuiPickersUtilsProvider>
  );
}

NewDatePicker.defaultProps = {
  selectedDate: null,
  label: '',
  disabled: false,
};

NewDatePicker.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
  handleChangeDate: PropTypes.func.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

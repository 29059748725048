/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, InputLabel, Select, MenuItem } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ClearIcon from '@material-ui/icons/Clear';

import {
  StyledPaper,
  StyledGrid,
  StyledFormControl,
  ContentDate,
  StyledIconButton,
  StyledTextField,
} from './styles';

import InputDatePicker from '~/components/InputDatePicker';
import DatePicker from '~/components/DatePicker';
import { useV2GlobalDiets } from '~/hooks/apiV2/global/diet/useV2GlobalDiets';

export default function Filter({
  totalForecast,
  totalPerformed,
  disabled,
  isRegister,
  currentDiet,
  currentDate,
  setCurrentDiet,
  setCurrentDate,
}) {
  const { data } = useV2GlobalDiets()

  const totalDeviationValue = !!((totalPerformed / totalForecast - 1) * 100) ? ((totalPerformed / totalForecast - 1) * 100).toFixed(2) : (0).toFixed(2)

  function handleChangeDiet(event) {
    setCurrentDiet(event.target.value);
  }

  function handleChangeDate(date) {
    setCurrentDate(date);
  }

  return (
    <StyledPaper>
      <StyledGrid container justify="space-around" spacing={2}>
        <Grid item sm xs={12}>
          <StyledFormControl disabled={!!disabled}>
            <InputLabel id="input-diet">Dieta</InputLabel>
            <Select
              id="currentDiet"
              defaultValue={isRegister ? 'none' : 'all'}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}
              value={currentDiet || (isRegister ? 'none' : 'all')}
              onChange={handleChangeDiet}
            >
              <MenuItem value={isRegister ? 'none' : 'all'}>
                {isRegister ? 'Nenhuma' : 'Todas'}
              </MenuItem>
              {!!data?.diets && data.diets.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item sm xs={12}>
          <ContentDate>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {isRegister ? (
                <DatePicker
                  style={{ marginTop: 0 }}
                  maxDate={new Date()}
                  selectedDate={currentDate}
                  disabled={!!disabled}
                  handleChangeDate={(date, value) =>
                    handleChangeDate(date)
                  }
                />
              ) : (
                <InputDatePicker
                  maxDate={new Date()}
                  selectedDate={currentDate}
                  handleChangeDate={(date, value) =>
                    handleChangeDate(date)
                  }
                  InputProps={{
                    startAdornment: (
                      <StyledIconButton
                        onClick={() => handleChangeDate(null, null)}
                      >
                        <ClearIcon />
                      </StyledIconButton>
                    ),
                  }}
                  InputAdornmentProps={{
                    position: 'end',
                  }}
                />
              )}
            </MuiPickersUtilsProvider>
          </ContentDate>
        </Grid>

        <Grid container justify="space-around" alignItems="center" spacing={2}>
          <Grid item sm xs={12}>
            <StyledTextField
              id="total_forecast"
              label="Total Previsto (Kg)"
              variant="outlined"
              size="small"
              fullWidth
              disabled={true}
              type="number"
              value={totalForecast}
            />
          </Grid>
          <Grid item sm xs={12}>
            <StyledTextField
              id="total_performed"
              label="Total Realizado (Kg)"
              variant="outlined"
              size="small"
              fullWidth
              disabled={true}
              type="number"
              value={totalPerformed}
            />
          </Grid>
          <Grid item sm xs={12}>
            <StyledTextField
              id="total_deviation"
              label="Desvio (%)"
              variant="outlined"
              size="small"
              fullWidth
              disabled={true}
              type="number"
              value={totalDeviationValue}
            />
          </Grid>
        </Grid>
      </StyledGrid>
    </StyledPaper>
  );
}

Filter.defaultProps = {
  disabled: '',
  isRegister: false,
  currentDiet: '',
  currentDate: '',
  setCurrentDiet: () => { },
  setCurrentDate: () => { },
};

Filter.propTypes = {
  dietDefaultOption: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  totalForecast: PropTypes.number.isRequired,
  totalPerformed: PropTypes.number.isRequired,
  disabled: PropTypes.string,
  isRegister: PropTypes.bool,
  currentDiet: PropTypes.string,
  currentDate: PropTypes.string,
  setCurrentDiet: PropTypes.func,
  setCurrentDate: PropTypes.func,
};

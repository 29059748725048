import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { Modal, Backdrop, Fade, Grid, TextField } from '@material-ui/core';
import AutorenewIcon from '@material-ui/icons/Autorenew';

import { StyledPaper, Error, StyledSubmitButton } from './styles';

import colors from '~/styles/colors';

const schema = Yup.object().shape({
  name: Yup.string().required('Informe o nome da pré mistura'),
});

export default function ModalMix({
  visibleModal,
  handleModal,
  handleGenerateMix,
  errorName,
}) {
  return (
    <Modal
      open={visibleModal}
      onClose={handleModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visibleModal}>
        <StyledPaper>
          <Formik
            validationSchema={schema}
            initialValues={{
              name: '',
            }}
            onSubmit={handleGenerateMix}
          >
            {({ errors, touched, handleChange }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item sm>
                    <TextField
                      name="name"
                      label="Nome"
                      variant="outlined"
                      placeholder="Insira o nome da pré mistura"
                      fullWidth
                      autoComplete="nope"
                      autoFocus
                      onChange={handleChange}
                      error={
                        (!!errors.name && touched.name) || errorName !== ''
                      }
                    />
                    {errorName !== '' ? (
                      <Error>{errorName}</Error>
                    ) : (
                      <ErrorMessage name="name" component={Error} />
                    )}
                  </Grid>
                  <Grid item sm="auto">
                    <StyledSubmitButton
                      variant="contained"
                      color={colors.white}
                      background={colors.success}
                      size="large"
                      type="submit"
                      startIcon={<AutorenewIcon />}
                    >
                      Misturar
                    </StyledSubmitButton>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </StyledPaper>
      </Fade>
    </Modal>
  );
}

ModalMix.propTypes = {
  visibleModal: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
  handleGenerateMix: PropTypes.func.isRequired,
  errorName: PropTypes.string.isRequired,
};

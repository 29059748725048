import DateFnsUtils from '@date-io/date-fns';
import {
  Divider, FormControl,
  FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Radio, Select, TextField
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { formatISO9075, parseISO, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ErrorMessage, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import ButtonSubmit from '~/components/ButtonSubmit';
import Loader from '~/components/Loader';
import ModalStakeholder from '~/components/ModalStakeholder';
import NumberFormatEmpty from '~/components/NumberFormatEmpty';
import NumberFormatForm from '~/components/NumberFormatForm';
import { useV2GetDataCreateLotsEntry } from '~/hooks/apiV2/animalHandling/LotsEntry/useV2GetDataCreateLotsEntry';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';
import { calcArrobaPrice } from '~/utils/calc';
import { parseUserInput } from '~/utils/format';
import { ages, bodySizes, gender, inputTypes, types } from '~/utils/options';
import ModalBreed from '../Components/ModalBreed';
import ModalDiet from '../Components/ModalDiet';
import {
  Container,
  Content, ContentSubmitButtons, Error, StyledGrid,
  StyledGridAndButton,
  StyledIconButton, StyledPaper, StyledRadioGroup, StyledSubmitButton
} from './styles';

const schema = Yup.object().shape({
  diet_id: Yup.string().required('Selecione uma dieta'),
  sex: Yup.string().required('Selecione o sexo'),
  age: Yup.string().required('Selecione a faixa de idade'),
  type: Yup.string().required('Selecione a categoria'),
  body_size: Yup.string().required('Selecione o tipo corporal'),
  amount_animals: Yup.number()
    .typeError('Insira um valor válido')
    .positive('O número deve ser maior que 0')
    .required('Informe a quantidade'),
  average_weight: Yup.number()
    .typeError('Insira um valor válido')
    .positive('O número deve ser maior que 0')
    .min(10, 'O peso deve ser maior')
    .required('Informar o peso médio'),
  date: Yup.string().required('Informe uma data'),
  input_type: Yup.string().required('Informe o tipo de entrada'),
  percent_rc: Yup.number()
    .typeError('Insira um número válido')
    .required('Informe a porcentagem de RC'),
  average_price: Yup.number()
    .typeError('Insira um número válido')
    .positive('O número deve ser maior que 0')
    .required('Informar o preço médio do animal'),
  boitel_day_price: Yup.number()
    .typeError('Insira um número válido')
    .positive('O número deve ser maior que 0'),
  arroba_price: Yup.number().typeError('Insira um número válido').nullable(),
  weight_forecast: Yup.number().typeError('Insira um número válido').nullable(),
  percent_rc_forecast: Yup.number()
    .typeError('Insira um número válido')
    .nullable(),
  output_price_forecast: Yup.number()
    .typeError('Insira um número válido')
    .nullable(),
  invoice_number: Yup.number().typeError('Insira um número válido').nullable(),
  gta_number: Yup.number().typeError('Insira um número válido').nullable(),
  breed_id: Yup.string()
    .typeError('Seleciona uma raça')
    .required('Selecione uma raça'),
  module_id: Yup.string()
    .typeError('Seleciona um módulo')
    .required('Selecione um módulo'),
  line_id: Yup.string()
    .typeError('Seleciona uma linha')
    .required('Selecione uma linha'),
  paddock_id: Yup.string()
    .typeError('Seleciona um piquete')
    .required('Selecione um piquete'),
});

export default function Register() {
  function handleBack() {
    history.goBack();
  }

  const { data, isLoading } = useV2GetDataCreateLotsEntry();
  const [lotsOptions, setLotsOptions] = useState([]);
  const [disabledDietOptions, setDisabledDietOptions] = useState(false);
  const [disabledModuleOptions, setDisabledModuleOptions] = useState(false);

  useEffect(() => {
    if (!!data) {
      setLotsOptions(data.optionsLots)
    }
  }, [data])

  const [visibleModalDiet, setVisibleModalDiet] = useState(false);
  const [visibleModalStakeholder, setVisibleModalStakeholder] = useState(false);
  const [visibleModalBreed, setVisibleModalBreed] = useState(false);

  const addAnimalInputLot = useMutation(async (data) => {
    return await apiV2.post('v1/feedlot/animal-handling/lots-entry', data);
  },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries();
        toast.success(response.data?.message || 'A entrada de animais foi cadastrado com sucesso.')
        handleBack()
      },
      onError: (error) => {
        if (!!error.response.data?.type) {
          if (error.response.data?.type === 'warning') {
            toast.warning(error.response.data.message)
          } else {
            toast.error(error.response.data.message)
          }
        } else {
          toast.error('Falha ao tentar cadastrar a entrada de animais')
        }
      }
    }
  );

  function handleModalDiet() {
    setVisibleModalDiet(!visibleModalDiet);
  }

  function handleModalStakeholder() {
    setVisibleModalStakeholder(!visibleModalStakeholder);
  }

  function handleModalBreed() {
    setVisibleModalBreed(!visibleModalBreed);
  }

  function handleSelectLot(event, setFieldValue) {
    const findLot = data.optionsLots.find(lot => lot.value === event.target.value)
    setFieldValue('lot_id', event.target.value)
    if (findLot) {
      if (findLot.dateEntry) {
        setFieldValue('date', parseISO(findLot.dateEntry));
      } else {
        setFieldValue('date', new Date());
      }
      if (findLot.paddock) {
        setFieldValue('module_id', findLot.paddock.line.module.secure_id);
        setFieldValue('line_id', findLot.paddock.line.secure_id);
        setFieldValue('paddock_id', findLot.paddock.secure_id);
        setDisabledModuleOptions(true);
      } else {
        setFieldValue('module_id', '');
        setFieldValue('line_id', '');
        setFieldValue('paddock_id', '');
        setDisabledModuleOptions(false);
      }
      if (findLot.diet) {
        setFieldValue('diet_id', findLot.diet.secure_id);
        setDisabledDietOptions(true);
      } else {
        setFieldValue('diet_id', '');
        setDisabledDietOptions(false);
      }
    }
  }

  async function handleSubmit(data) {
    try {
      const { date } = data;

      const newData = {
        lotSecureId: data.lot_id,
        paddockId: data.paddock_id,
        dietId: data.diet_id,
        stakeholderSecureId: data.stakeholder,
        date: formatISO9075(new Date(date)),
        inputType: data.input_type,
        gtaNumber: data.gta_number,
        invoiceNumber: data.invoice_number,
        inputWeight: data.average_weight,
        type: data.type,
        sex: data.sex,
        bodySize: data.body_size,
        age: data.age,
        breedId: data.breed_id,
        percentRc: data.percent_rc,
        averagePrice: data.average_price,
        arrobaPrice: data.arroba_price,
        weightForecast: data.weight_forecast ? data.weight_forecast : null,
        percentRcForecast: data.percent_rc_forecast
          ? data.percent_rc_forecast
          : null,
        outputPriceForecast: data.output_price_forecast
          ? data.output_price_forecast
          : null,
        boitelDayPrice: data.boitel_day_price
          ? data.boitel_day_price
          : null,
        amountAnimals: data.amount_animals,
      };
      await addAnimalInputLot.mutateAsync(newData);
    } catch { }
  }

  return (
    <center>
      {
        isLoading ? (
          <Loader />
        ) : (
          <Container>
            <StyledPaper>
              <Formik
                enableReinitialize={false}
                validationSchema={schema}
                initialValues={{
                  lot_id: 'new',
                  date: !!data && data.optionsLots.find(lot => lot.value === 'new').dateEntry
                    ? parseISO(data.optionsLots.find(lot => lot.value === 'new').dateEntry)
                    : new Date(),
                  input_type: null,
                  sex: 'Macho',
                  diet_id: null,
                  age: '',
                  type: '',
                  body_size: '',
                  amount_animals: '',
                  average_weight: '',
                  boitel_day_price: '',
                  percent_rc: '',
                  average_price: '',
                  arroba_price: '',
                  weight_forecast: '',
                  percent_rc_forecast: '',
                  output_price_forecast:
                    '',
                  invoice_number: '',
                  gta_number: '',
                  stakeholder: '',
                  breed_id: null,
                  module_id: '',
                  line_id: '',
                  paddock_id: '',
                }}
                onSubmit={handleSubmit}
              >
                {({
                  handleChange,
                  setFieldValue,
                  values,
                  errors,
                  touched,
                }) => (
                  <Form>
                    <Content>
                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginBottom={12}
                      >
                        <Grid item sm xs={12}>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                            error={(!!errors.lot && touched.lot)}
                          >
                            <InputLabel id="lot_id-label">
                              Lote
                            </InputLabel>
                            <Select
                              name="lot_id"
                              labelWidth={122}
                              value={values.lot_id}
                              onChange={(event) => handleSelectLot(event, setFieldValue)}
                            >
                              {lotsOptions.map(lot => (
                                <MenuItem key={lot} value={lot.value}>
                                  {lot.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage name="lot_id" component={Error} />
                          </FormControl>
                        </Grid>
                        <Grid item sm xs={12}>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={ptBR}
                          >
                            <KeyboardDatePicker
                              name="date"
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              size="small"
                              id="date-picker-inline"
                              label="Data de entrada"
                              inputVariant="outlined"
                              maxDate={
                                !!data && data.optionsLots.find(lot => lot.value === values.lot_id) && data.optionsLots.find(lot => lot.value === values.lot_id).dateEntry
                                  ? parseISO(data.optionsLots.find(lot => lot.value === values.lot_id).dateEntry)
                                  : new Date()
                              }
                              minDate={
                                !!data && data.optionsLots.find(lot => lot.value === values.lot_id) && data.optionsLots.find(lot => lot.value === values.lot_id).dateEntry
                                  ? parseISO(data.optionsLots.find(lot => lot.value === values.lot_id).dateEntry)
                                  : new Date(null)
                              }
                              required
                              fullWidth
                              autoOk
                              style={{ marginTop: 0, marginBottom: 0 }}
                              value={values.date}
                              onChange={date => {
                                setFieldValue('date', date);
                              }}
                              error={!!errors.date && touched.date}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item sm xs={12}>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                            error={!!errors.input_type && touched.input_type}
                          >
                            <InputLabel id="input_type-label">
                              Tipo de entrada
                            </InputLabel>
                            <Select
                              name="input_type"
                              labelWidth={122}
                              value={values.input_type}
                              onChange={handleChange}
                            >
                              {inputTypes.map(type => (
                                <MenuItem key={type} value={type.value}>
                                  {type.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage name="input_type" component={Error} />
                          </FormControl>
                        </Grid>
                      </StyledGrid>
                      <StyledGrid
                        container
                        justify="flex-start"
                        spacing={2}
                        marginBottom={12}
                      >
                        {values.input_type === inputTypes[2].value && (
                          <Grid item sm={4} xs={12}>
                            <NumberFormatForm
                              disabled={values.input_type !== inputTypes[2].value}
                              allowedDecimalSeparators={[',']}
                              name="boitel_day_price"
                              fixedDecimalScale
                              label="Valor da Diária Boitel"
                              variant="outlined"
                              size="small"
                              required
                              fullWidth
                              decimalScale={2}
                              value={
                                values.input_type === inputTypes[2].value
                                  ? values.boitel_day_price
                                  : ''
                              }
                              setFieldValue={setFieldValue}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                !!errors.boitel_day_price &&
                                touched.boitel_day_price
                              }
                            />
                            <ErrorMessage
                              name="boitel_day_price"
                              component={Error}
                            />
                          </Grid>
                        )}
                        <Grid item sm={4} xs={12}>
                          <NumberFormatEmpty
                            name="invoice_number"
                            label="Nota Fiscal"
                            variant="outlined"
                            size="small"
                            fullWidth
                            allowNegative={false}
                            decimalScale={0}
                            isAllowed={val => {
                              const { value } = val;
                              return value.length <= 10;
                            }}
                            value={values.invoice_number}
                            setFieldValue={setFieldValue}
                            error={
                              !!errors.invoice_number && touched.invoice_number
                            }
                          />
                          <ErrorMessage name="invoice_number" component={Error} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <NumberFormatEmpty
                            name="gta_number"
                            label="GTA"
                            variant="outlined"
                            size="small"
                            fullWidth
                            allowNegative={false}
                            decimalScale={0}
                            isAllowed={val => {
                              const { value } = val;
                              return value.length <= 10;
                            }}
                            value={values.gta_number}
                            setFieldValue={setFieldValue}
                            error={!!errors.gta_number && touched.gta_number}
                          />
                          <ErrorMessage name="gta_number" component={Error} />
                        </Grid>
                      </StyledGrid>
                      <StyledGrid
                        container
                        spacing={2}
                        justify="space-between"
                        marginBottom={16}
                      >
                        <Grid item sm xs={12}>
                          <StyledGridAndButton container direction="row">
                            <Grid item sm xs>
                              <FormControl
                                size="small"
                                required
                                fullWidth
                                variant="outlined"
                                error={!!errors.stakeholder && touched.stakeholder_doc}
                              >
                                <InputLabel id="stakeholder-label">
                                  Fornecedor
                                </InputLabel>
                                <Select
                                  name="stakeholder"
                                  labelWidth={122}
                                  value={values.stakeholder}
                                  onChange={(event) => {
                                    setFieldValue('stakeholder', event.target.value)
                                    if (!!data) {
                                      setFieldValue('stakeholder_doc', data.optionsStakeholders.find(stakeHolder => stakeHolder.value === event.target.value)?.doc)
                                    }
                                  }}
                                >
                                  {!!data && data.optionsStakeholders.map(stakeHolder => (
                                    <MenuItem key={stakeHolder.value} value={stakeHolder.value}>
                                      {stakeHolder.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            <Grid item sm={4} style={{ marginTop: 4 }}>
                              <StyledIconButton
                                variant="contained"
                                size="small"
                                onClick={handleModalStakeholder}
                              >
                                <AddIcon />
                              </StyledIconButton>
                            </Grid>
                          </StyledGridAndButton>
                        </Grid>
                        <Grid item sm xs>
                          <TextField
                            label={
                              values.stakeholder_doc &&
                                values.stakeholder_doc.length === 14
                                ? 'CPF'
                                : 'CNPJ'
                            }
                            name="stakeholder_doc"
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled
                            value={values.stakeholder_doc}
                            InputLabelProps={{
                              shrink: !!values.stakeholder_doc,
                            }}
                          />
                        </Grid>
                      </StyledGrid>

                      <Divider light />

                      <Divider light />
                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginTop={16}
                      >
                        <Grid item sm xs={12}>
                          <StyledGridAndButton container direction="row">
                            <Grid item sm xs>
                              <FormControl
                                size="small"
                                required
                                fullWidth
                                variant="outlined"
                                error={!!errors.breed_id && touched.breed_id}
                              >
                                <InputLabel id="input-breed">Raça</InputLabel>
                                <Select
                                  name="breed_id"
                                  labelWidth={45}
                                  inputProps={{
                                    id: 'breed-input',
                                  }}
                                  MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                  }}
                                  value={values.breed_id}
                                  onChange={event => {
                                    setFieldValue('breed_id', event.target.value)
                                    setFieldValue('description_breed', data.optionsBreeds.find(breed => breed.value === event.target.value)?.description)
                                  }}
                                >
                                  {!!data &&
                                    data.optionsBreeds.map(item => (
                                      <MenuItem
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                </Select>
                                <ErrorMessage name="breed_id" component={Error} />
                              </FormControl>
                            </Grid>
                            <Grid item sm={4} style={{ marginTop: 4 }}>
                              <StyledIconButton
                                variant="contained"
                                size="small"
                                onClick={handleModalBreed}
                              >
                                <AddIcon />
                              </StyledIconButton>
                            </Grid>
                          </StyledGridAndButton>
                        </Grid>
                        <Grid item sm xs={12}>
                          <TextField
                            label="Descrição"
                            name="description_breed"
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled
                            value={values.description_breed}
                            InputLabelProps={{
                              shrink: !!values.description_breed,
                            }}
                          />
                        </Grid>
                      </StyledGrid>
                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginTop={12}
                        marginBottom={12}
                      >
                        <Grid item sm={4} xs={12}>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                            error={!!errors.age && touched.age}
                          >
                            <InputLabel id="input-age">Idade</InputLabel>
                            <Select
                              name="age"
                              labelWidth={50}
                              inputProps={{
                                id: 'age-input',
                              }}
                              value={values.age}
                              onChange={handleChange}
                            >
                              {ages.map(item => (
                                <MenuItem key={item.id} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage name="age" component={Error} />
                          </FormControl>
                        </Grid>
                        <Grid item sm xs>
                          <StyledRadioGroup row defaultValue={values.sex}>
                            {gender.map(sex => (
                              <FormControlLabel
                                key={sex.label}
                                name="sex"
                                value={sex.label}
                                control={
                                  <Radio
                                    color="primary"
                                    size="small"
                                    name="sex"
                                    onChange={handleChange}
                                  />
                                }
                                label={sex.label}
                              />
                            ))}
                          </StyledRadioGroup>
                        </Grid>
                      </StyledGrid>
                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginTop={12}
                        marginBottom={12}
                      >
                        <Grid item sm xs={12}>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                            error={!!errors.type && touched.type}
                          >
                            <InputLabel id="input-type">Categoria</InputLabel>
                            <Select
                              name="type"
                              labelWidth={83}
                              inputProps={{
                                id: 'type-input',
                              }}
                              value={values.type}
                              onChange={handleChange}
                            >
                              {types
                                .filter(item => {
                                  if (values.sex === 'Macho') {
                                    return (
                                      item === 'Inteiro' ||
                                      item === 'Castrado' ||
                                      item === 'Bezerro'
                                    );
                                  }
                                  return (
                                    item === 'Vaca' ||
                                    item === 'Novilha' ||
                                    item === 'Bezerra'
                                  );
                                })
                                .map(item => (
                                  <MenuItem key={item.id} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                            </Select>
                            <ErrorMessage name="type" component={Error} />
                          </FormControl>
                        </Grid>
                        <Grid item sm xs>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                            error={!!errors.body_size && touched.body_size}
                          >
                            <InputLabel id="input-body_size">
                              Tamanho Corporal
                            </InputLabel>
                            <Select
                              name="body_size"
                              labelWidth={145}
                              value={values.body_size}
                              onChange={handleChange}
                            >
                              {bodySizes.map(item => (
                                <MenuItem key={item.id} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage name="body_size" component={Error} />
                          </FormControl>
                        </Grid>
                        <Grid item sm xs={12}>
                          <NumberFormatForm
                            name="amount_animals"
                            label="Quantidade"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            allowNegative={false}
                            autoComplete="nope"
                            decimalScale={0}
                            value={values.amount_animals}
                            setFieldValue={setFieldValue}
                            error={
                              !!errors.amount_animals && touched.amount_animals
                            }
                          />
                          <ErrorMessage name="amount_animals" component={Error} />
                        </Grid>
                      </StyledGrid>

                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginBottom={16}
                      >
                        <Grid item sm xs={12}>
                          <NumberFormatForm
                            name="average_weight"
                            label="Peso Médio por Animal"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            autoComplete="nope"
                            allowNegative={false}
                            decimalScale={2}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">Kg</InputAdornment>
                              ),
                            }}
                            value={values.average_weight}
                            setFieldValue={setFieldValue}
                            onChange={event =>
                              calcArrobaPrice(
                                setFieldValue,
                                values.average_price,
                                parseUserInput(event.target.value),
                                values.percent_rc
                              )
                            }
                            error={
                              !!errors.average_weight && touched.average_weight
                            }
                          />
                          <ErrorMessage name="average_weight" component={Error} />
                        </Grid>

                        <Grid item sm xs={12}>
                          <NumberFormatForm
                            name="percent_rc"
                            label="RC de Entrada (%)"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            allowNegative={false}
                            decimalScale={2}
                            isAllowed={val => {
                              const { formattedValue, floatValue } = val;
                              return (
                                formattedValue === '' ||
                                formattedValue === '-' ||
                                (floatValue <= 100 && floatValue >= -100)
                              );
                            }}
                            value={values.percent_rc}
                            setFieldValue={setFieldValue}
                            onChange={event =>
                              calcArrobaPrice(
                                setFieldValue,
                                values.average_price,
                                values.average_weight,
                                parseUserInput(event.target.value)
                              )
                            }
                            error={!!errors.percent_rc && touched.percent_rc}
                          />
                          <ErrorMessage name="percent_rc" component={Error} />
                        </Grid>
                        <Grid item sm xs={12}>
                          <NumberFormatForm
                            name="percent_rc_forecast"
                            label="RC de Abate Previsto (%)"
                            variant="outlined"
                            size="small"
                            fullWidth
                            allowNegative={false}
                            decimalScale={2}
                            isAllowed={val => {
                              const { formattedValue, floatValue } = val;
                              return formattedValue === '' || floatValue <= 100;
                            }}
                            value={values.percent_rc_forecast}
                            setFieldValue={setFieldValue}
                            error={
                              !!errors.percent_rc_forecast &&
                              touched.percent_rc_forecast
                            }
                          />
                          <ErrorMessage
                            name="percent_rc_forecast"
                            component={Error}
                          />
                        </Grid>
                      </StyledGrid>
                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginBottom={16}
                      >
                        <Grid item sm xs={12}>
                          <NumberFormatForm
                            allowedDecimalSeparators={[',']}
                            name="average_price"
                            fixedDecimalScale
                            label="Preço Médio por Animal"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            decimalScale={2}
                            value={values.average_price}
                            setFieldValue={setFieldValue}
                            onChange={event =>
                              calcArrobaPrice(
                                setFieldValue,
                                parseUserInput(event.target.value),
                                values.average_weight,
                                values.percent_rc
                              )
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              ),
                            }}
                            error={
                              !!errors.average_price && touched.average_price
                            }
                          />
                          <ErrorMessage name="average_price" component={Error} />
                        </Grid>
                        <Grid item sm xs={12}>
                          <NumberFormatForm
                            name="arroba_price"
                            label="Preço por Arroba"
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled
                            allowNegative={false}
                            decimalScale={2}
                            value={values.arroba_price}
                            setFieldValue={setFieldValue}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              ),
                            }}
                            error={!!errors.arroba_price && touched.arroba_price}
                          />
                          <ErrorMessage name="arroba_price" component={Error} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <NumberFormatForm
                            allowedDecimalSeparators={[',']}
                            name="weight_forecast"
                            label="Peso de Abate Previsto"
                            variant="outlined"
                            size="small"
                            fullWidth
                            allowNegative={false}
                            fixedDecimalScale
                            decimalScale={2}
                            value={values.weight_forecast}
                            setFieldValue={setFieldValue}
                            error={
                              !!errors.weight_forecast && touched.weight_forecast
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">Kg</InputAdornment>
                              ),
                            }}
                          />
                          <ErrorMessage
                            name="weight_forecast"
                            component={Error}
                          />
                        </Grid>
                      </StyledGrid>

                      <StyledGrid
                        container
                        justify="flex-start"
                        spacing={2}
                        marginBottom={16}
                      >
                        <Grid item sm={4} xs={12}>
                          <NumberFormatForm
                            allowedDecimalSeparators={[',']}
                            name="output_price_forecast"
                            label="Valor da Arroba para Saída Previsto"
                            variant="outlined"
                            size="small"
                            fullWidth
                            allowNegative={false}
                            fixedDecimalScale
                            decimalScale={2}
                            value={values.output_price_forecast}
                            setFieldValue={setFieldValue}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              ),
                            }}
                            error={
                              !!errors.output_price_forecast &&
                              touched.output_price_forecast
                            }
                          />
                          <ErrorMessage
                            name="output_price_forecast"
                            component={Error}
                          />
                        </Grid>
                      </StyledGrid>

                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginBottom={16}
                      />

                      <Divider light />

                      <Divider light />
                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginTop={16}
                      >
                        <Grid item sm xs={12}>
                          <TextField
                            name="module_id"
                            label="Módulo"
                            variant="outlined"
                            fullWidth
                            select
                            required
                            disabled={disabledModuleOptions}
                            size="small"
                            InputLabelProps={{
                              shrink: values.module_id,
                            }}
                            error={!!errors.module_id && touched.module_id}
                            value={values.module_id}
                            onChange={handleChange}
                          >
                            {!!data &&
                              data.optionsModules.map(item => (
                                <MenuItem
                                  key={item.value}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                          </TextField>
                          <ErrorMessage name="module_id" component={Error} />
                        </Grid>
                        <Grid item sm xs={12}>
                          <TextField
                            name="line_id"
                            label="Linha"
                            variant="outlined"
                            fullWidth
                            select
                            required
                            disabled={disabledModuleOptions}
                            size="small"
                            InputLabelProps={{
                              shrink: values.line_id,
                            }}
                            error={!!errors.line_id && touched.line_id}
                            value={values.line_id}
                            onChange={handleChange}
                          >
                            {!!data
                              && !!data.optionsModules.find(module => module.value === values.module_id)
                              && data.optionsModules.find(module => module.value === values.module_id).optionsLines.map(item => (
                                <MenuItem
                                  key={item.value}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                          </TextField>
                          <ErrorMessage name="line_id" component={Error} />
                        </Grid>
                        <Grid item sm xs={12}>
                          <TextField
                            name="paddock_id"
                            label="Piquete"
                            variant="outlined"
                            fullWidth
                            select
                            required
                            disabled={disabledModuleOptions}
                            size="small"
                            InputLabelProps={{
                              shrink: values.paddock_id,
                            }}
                            error={!!errors.paddock_id && touched.paddock_id}
                            value={values.paddock_id}
                            onChange={event => {
                              setFieldValue('paddock_id', event.target.value)
                              if (!!data) {
                                const diet = data.optionsModules.find(module => module.value === values.module_id).optionsLines
                                  .find(line => line.value === values.line_id).optionsPaddocks.find(paddock => paddock.value === event.target.value).diet
                                if (diet) {
                                  setFieldValue('diet_id', diet.value)
                                  setDisabledDietOptions(true)
                                } else {
                                  setDisabledDietOptions(false)
                                }
                              }
                            }}
                          >
                            {!!data
                              && !!data.optionsModules.find(module => module.value === values.module_id)
                              && data.optionsModules.find(module => module.value === values.module_id).optionsLines.find(line => line.value === values.line_id)
                              && data.optionsModules.find(module => module.value === values.module_id).optionsLines.find(line => line.value === values.line_id).optionsPaddocks
                                .map(item => (
                                  <MenuItem
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {item.label}
                                  </MenuItem>
                                ))}
                          </TextField>
                          <ErrorMessage name="paddock_id" component={Error} />
                        </Grid>
                      </StyledGrid>
                      <StyledGrid
                        container
                        justify="flex-start"
                        spacing={2}
                        marginBottom={12}
                      >
                        <Grid item sm xs={12}>
                          <StyledGridAndButton
                            container
                            direction="row"
                            justify="space-between"
                            style={{ marginTop: 8, marginBottom: 8 }}
                          >
                            <Grid item sm xs>
                              <TextField
                                name="diet_id"
                                label="Dieta"
                                variant="outlined"
                                fullWidth
                                select
                                required
                                size="small"
                                InputLabelProps={{
                                  shrink: values.diet_id,
                                }}
                                disabled={disabledDietOptions}
                                error={!!errors.diet_id && touched.diet_id}
                                value={values.diet_id}
                                onChange={handleChange}
                              >
                                {!!data &&
                                  data.optionsDiets.map(item => (
                                    <MenuItem
                                      key={item.value}
                                      value={item.value}
                                    >
                                      {item.label}
                                    </MenuItem>
                                  ))}
                              </TextField>
                              <ErrorMessage name="diet_id" component={Error} />
                            </Grid>
                            <Grid item sm={4} style={{ marginTop: 4 }}>
                              <StyledIconButton
                                variant="contained"
                                size="small"
                                onClick={handleModalDiet}
                              >
                                <AddIcon />
                              </StyledIconButton>
                            </Grid>
                          </StyledGridAndButton>
                        </Grid>
                        <Grid item sm />
                      </StyledGrid>

                      <ContentSubmitButtons>
                        <StyledSubmitButton
                          variant="outlined"
                          color="primary"
                          onClick={handleBack}
                        >
                          Cancelar
                        </StyledSubmitButton>
                        <ButtonSubmit
                          title="Cadastrar"
                          loading={addAnimalInputLot.isLoading}
                        />
                      </ContentSubmitButtons>
                    </Content>
                  </Form>
                )}
              </Formik>
            </StyledPaper>

            {visibleModalDiet && (
              <ModalDiet
                visibleModal={visibleModalDiet}
                handleModal={handleModalDiet}
              />
            )}

            {visibleModalStakeholder && (
              <ModalStakeholder
                visibleModal={visibleModalStakeholder}
                handleModal={handleModalStakeholder}
                type="provider,both"
              />
            )}

            {visibleModalBreed && (
              <ModalBreed
                visibleModal={visibleModalBreed}
                handleModal={handleModalBreed}
              />
            )}
          </Container>
        )}
    </center>
  );
}

Register.defaultProps = {
  match: null,
};

Register.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

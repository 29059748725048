import { useQuery } from "react-query";
import apiV2 from '~/services/apiV2';

async function getV2GetSanitaryCostShow(secure_id) {
  const { data } = await apiV2.get(`v1/feedlot/admin/sanitary-cost/${secure_id}`)

  return data
}

export function useV2GetSanitaryCostShow(secure_id) {
  return useQuery(
    ['sanitaryCostShow', secure_id],
    () => getV2GetSanitaryCostShow(secure_id))
}

import React from 'react';
import Swal from 'sweetalert2';

import {
  TableCell,
  TableRow
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

import { useApiV2RemoveFoodCorrection } from '~/hooks/apiV2/admin/foodCorrection/useApiV2RemoveFoodCorrection';

import ActionLoader from '~/components/ActionLoader';
import Can from '~/components/Can';
import colors from '~/styles/colors';
import { formatNumber } from '~/utils/format';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';

export function CardTableFoodCorrection({ correction }) {
  const removeFoodCorrection = useApiV2RemoveFoodCorrection()

  function handleFoodCorrectionRequest({ secure_id }) {
    async function performRemoveFoodCorrection() {
      await removeFoodCorrection.mutateAsync(secure_id)
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar esta correção?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar correção!',
    }).then(result => {
      if (result.value) {
        performRemoveFoodCorrection();
      }
    });
  }

  return (
    <TableRow>
      <StyledTableCellEllipsis component="th" scope="row">
        {correction.food_name}
      </StyledTableCellEllipsis>
      <StyledTableCell align="right">
        {formatNumber(correction.amount_ton, 4, 4)}
      </StyledTableCell>
      <StyledTableCell align="right">
        {formatNumber(correction.price_ton, 2, 2)}
      </StyledTableCell>
      <StyledTableCellEllipsis component="th" scope="row">
        {correction.obs}
      </StyledTableCellEllipsis>
      <TableCell align="center">
        {correction.formattedDate}
      </TableCell>
      <Can checkRole={['administrator', 'owner', 'manager']}>
        <StyledTableCell align="right">
          {removeFoodCorrection.isLoading ? (
            <ActionLoader text="Deletando" />
          ) : (

            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['foodCorrection_delete']}
            >
              <ButtonTable
                icon={<DeleteIcon color="error" />}
                background={colors.error}
                onClick={() =>
                  handleFoodCorrectionRequest(correction)
                }
              />
            </Can>

          )}
        </StyledTableCell>
      </Can>
    </TableRow>
  )
}

import DateFnsUtils from '@date-io/date-fns';
import {
  Divider, FormControl,
  FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Radio, Select, TextField
} from '@material-ui/core';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import { ptBR } from 'date-fns/locale';
import { ErrorMessage, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import ButtonSubmit from '~/components/ButtonSubmit';
import Loader from '~/components/Loader';
import NumberFormatEmpty from '~/components/NumberFormatEmpty';
import NumberFormatForm from '~/components/NumberFormatForm';
import { useV2GetLotEntry } from '~/hooks/apiV2/animalHandling/LotsEntry/useV2GetLotEntry';
import { useApiV2GlobalBreeds } from '~/hooks/apiV2/global/breed/useApiV2GlobalBreeds';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';
import { calcArrobaPrice } from '~/utils/calc';
import { parseUserInput } from '~/utils/format';
import { ages, bodySizes, gender, inputTypes, types } from '~/utils/options';
import {
  Container,
  Content, ContentSubmitButtons, Error, StyledGrid,
  StyledGridAndButton, StyledPaper, StyledRadioGroup, StyledSubmitButton
} from './styles';

const schema = Yup.object().shape({
  average_price: Yup.number()
    .typeError('Insira um número válido')
    .positive('O número deve ser maior que 0')
    .required('Informar o preço médio do animal'),
  arroba_price: Yup.number().typeError('Insira um número válido').nullable(),
  weight_forecast: Yup.number().typeError('Insira um número válido').nullable(),
  percent_rc_forecast: Yup.number()
    .typeError('Insira um número válido')
    .nullable(),
  output_price_forecast: Yup.number()
    .typeError('Insira um número válido')
    .nullable(),
});

export default function Edit({ match }) {
  const secure_id = useMemo(() => match.params.id, [match.params.id]);
  function handleBack() {
    history.goBack();
  }

  const { data: defaultValuesLotEntry, isLoading: isLoadingDefaultValuesLotEntry } = useV2GetLotEntry(secure_id)
  const { data: optionsBreeds } = useApiV2GlobalBreeds();

  const editAnimalInputLot = useMutation(async (data) => {
    return await apiV2.put(`v1/feedlot/animal-handling/lots-entry/${secure_id}`, data);
  },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries();
        toast.success(response.data?.message || 'A entrada de animais foi atualizada com sucesso.')
        handleBack()
      },
      onError: (error) => {
        if (!!error.response.data?.type) {
          if (error.response.data?.type === 'warning') {
            toast.warning(error.response.data.message)
          } else {
            toast.error(error.response.data.message)
          }
        } else {
          toast.error('Falha ao tentar atualizar a entrada de animais')
        }
      }
    }
  );

  async function handleSubmit(data) {
    try {
      const newData = {
        averagePrice: data.average_price,
        weightForecast: data.weight_forecast,
        percentRcForecast: data.percent_rc_forecast,
        outputPriceForecast: data.output_price_forecast,
        arrobaPrice: data.arroba_price,
        breedId: data.breed_id,
        age: data.age,
        sex: data.sex,
        type: data.type,
        bodySize: data.body_size,
      };
      await editAnimalInputLot.mutateAsync(newData);
    } catch { }
  }

  return (
    <center>
      {
        (isLoadingDefaultValuesLotEntry) ? (
          <Loader />
        ) : (
          <Container>
            <StyledPaper>
              <Formik
                enableReinitialize={false}
                validationSchema={schema}
                initialValues={{
                  lot_id: !!defaultValuesLotEntry && defaultValuesLotEntry.originLot && defaultValuesLotEntry.originLot.secure_id,
                  date: !!defaultValuesLotEntry && defaultValuesLotEntry.date,
                  input_type: !!defaultValuesLotEntry && defaultValuesLotEntry.input_type,
                  sex: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.sex,
                  age: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.age,
                  type: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.type,
                  body_size: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.body_size,
                  amount_animals: !!defaultValuesLotEntry && defaultValuesLotEntry.amount_animals,
                  average_weight: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.input_weight,
                  boitel_day_price: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.boitel_day_price,
                  percent_rc: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.percent_rc,
                  average_price: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.average_price,
                  arroba_price: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.arroba_price,
                  weight_forecast: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.weight_forecast,
                  percent_rc_forecast: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.percent_rc_forecast,
                  output_price_forecast: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.output_price_forecast,
                  invoice_number: !!defaultValuesLotEntry && defaultValuesLotEntry.invoice_number,
                  gta_number: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.gta_number,
                  stakeholder: !!defaultValuesLotEntry && defaultValuesLotEntry.stakeholder.secure_id,
                  stakeholder_doc: !!defaultValuesLotEntry && defaultValuesLotEntry.stakeholder.doc,
                  breed_id: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.breed.secure_id,
                  description_breed: !!defaultValuesLotEntry && defaultValuesLotEntry.firstAnimal.breed.description,
                  module_id: !!defaultValuesLotEntry && defaultValuesLotEntry.originPaddock && defaultValuesLotEntry.originPaddock.line.module.secure_id,
                  line_id: !!defaultValuesLotEntry && defaultValuesLotEntry.originPaddock && defaultValuesLotEntry.originPaddock.line.secure_id,
                  paddock_id: !!defaultValuesLotEntry && defaultValuesLotEntry.originPaddock && defaultValuesLotEntry.originPaddock.secure_id,
                  diet_id: !!defaultValuesLotEntry && defaultValuesLotEntry.originLot && defaultValuesLotEntry.originLot.diet.secure_id,
                }}
                onSubmit={handleSubmit}
              >
                {({
                  handleChange,
                  setFieldValue,
                  values,
                  errors,
                  touched,
                }) => (
                  <Form>
                    <Content>
                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginBottom={12}
                      >
                        <Grid item sm xs={12}>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                            error={(!!errors.lot && touched.lot)}
                          >
                            <InputLabel id="lot_id-label">
                              Lote
                            </InputLabel>
                            <Select
                              name="lot_id"
                              labelWidth={122}
                              value={values.lot_id}
                              disabled
                            >
                              {defaultValuesLotEntry.originLot && (
                                <MenuItem key={defaultValuesLotEntry.originLot.secure_id} value={defaultValuesLotEntry.originLot.secure_id}>
                                  {defaultValuesLotEntry.originLot.code}
                                </MenuItem>
                              )}
                            </Select>
                            <ErrorMessage name="lot_id" component={Error} />
                          </FormControl>
                        </Grid>
                        <Grid item sm xs={12}>
                          <MuiPickersUtilsProvider
                            utils={DateFnsUtils}
                            locale={ptBR}
                          >
                            <KeyboardDatePicker
                              name="date"
                              disableToolbar
                              variant="inline"
                              format="dd/MM/yyyy"
                              margin="normal"
                              disabled={true}
                              size="small"
                              id="date-picker-inline"
                              label="Data de entrada"
                              inputVariant="outlined"
                              // maxDate={values.date}
                              // minDate={values.date}
                              required
                              fullWidth
                              autoOk
                              style={{ marginTop: 0, marginBottom: 0 }}
                              value={values.date}
                              error={!!errors.date && touched.date}
                            />
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item sm xs={12}>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                            error={!!errors.input_type && touched.input_type}
                          >
                            <InputLabel id="input_type-label">
                              Tipo de entrada
                            </InputLabel>
                            <Select
                              name="input_type"
                              labelWidth={122}
                              value={values.input_type}
                              disabled={true}
                              onChange={handleChange}
                            >
                              {inputTypes.map(type => (
                                <MenuItem key={type} value={type.value}>
                                  {type.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage name="input_type" component={Error} />
                          </FormControl>
                        </Grid>
                      </StyledGrid>
                      <StyledGrid
                        container
                        justify="flex-start"
                        spacing={2}
                        marginBottom={12}
                      >
                        {values.input_type === inputTypes[2].value && (
                          <Grid item sm={4} xs={12}>
                            <NumberFormatForm
                              disabled={true}
                              allowedDecimalSeparators={[',']}
                              name="boitel_day_price"
                              fixedDecimalScale
                              label="Valor da Diária Boitel"
                              variant="outlined"
                              size="small"
                              required
                              fullWidth
                              decimalScale={2}
                              value={
                                values.input_type === inputTypes[2].value
                                  ? values.boitel_day_price
                                  : ''
                              }
                              setFieldValue={setFieldValue}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                ),
                              }}
                              error={
                                !!errors.boitel_day_price &&
                                touched.boitel_day_price
                              }
                            />
                            <ErrorMessage
                              name="boitel_day_price"
                              component={Error}
                            />
                          </Grid>
                        )}
                        <Grid item sm={4} xs={12}>
                          <NumberFormatEmpty
                            name="invoice_number"
                            label="Nota Fiscal"
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled={true}
                            allowNegative={false}
                            decimalScale={0}
                            isAllowed={val => {
                              const { value } = val;
                              return value.length <= 10;
                            }}
                            value={values.invoice_number}
                            setFieldValue={setFieldValue}
                            error={
                              !!errors.invoice_number && touched.invoice_number
                            }
                          />
                          <ErrorMessage name="invoice_number" component={Error} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <NumberFormatEmpty
                            name="gta_number"
                            label="GTA"
                            disabled={true}
                            variant="outlined"
                            size="small"
                            fullWidth
                            allowNegative={false}
                            decimalScale={0}
                            isAllowed={val => {
                              const { value } = val;
                              return value.length <= 10;
                            }}
                            value={values.gta_number}
                            setFieldValue={setFieldValue}
                            error={!!errors.gta_number && touched.gta_number}
                          />
                          <ErrorMessage name="gta_number" component={Error} />
                        </Grid>
                      </StyledGrid>
                      <StyledGrid
                        container
                        spacing={2}
                        justify="space-between"
                        marginBottom={16}
                      >
                        <Grid item sm xs={12}>
                          <StyledGridAndButton container direction="row">
                            <Grid item sm xs>
                              <FormControl
                                size="small"
                                required
                                fullWidth
                                variant="outlined"
                                error={!!errors.stakeholder && touched.stakeholder_doc}
                              >
                                <InputLabel id="stakeholder-label">
                                  Fornecedor
                                </InputLabel>
                                <Select
                                  name="stakeholder"
                                  labelWidth={122}
                                  value={values.stakeholder}
                                  disabled
                                >
                                  <MenuItem key={defaultValuesLotEntry.stakeholder.secure_id} value={defaultValuesLotEntry.stakeholder.secure_id}>
                                    {defaultValuesLotEntry.stakeholder.name}
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>
                          </StyledGridAndButton>
                        </Grid>
                        <Grid item sm xs>
                          <TextField
                            label={
                              values.stakeholder_doc &&
                                values.stakeholder_doc.length === 14
                                ? 'CPF'
                                : 'CNPJ'
                            }
                            name="stakeholder_doc"
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled
                            value={values.stakeholder_doc}
                            InputLabelProps={{
                              shrink: !!values.stakeholder_doc,
                            }}
                          />
                        </Grid>
                      </StyledGrid>

                      <Divider light />

                      <Divider light />
                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginTop={16}
                      >
                        <Grid item sm xs={12}>
                          <StyledGridAndButton container direction="row">
                            <Grid item sm xs>
                              <FormControl
                                size="small"
                                required
                                fullWidth
                                variant="outlined"
                                error={!!errors.breed_id && touched.breed_id}
                              >
                                <InputLabel id="input-breed">Raça</InputLabel>
                                <Select
                                  name="breed_id"
                                  labelWidth={45}
                                  inputProps={{
                                    id: 'breed-input',
                                  }}
                                  MenuProps={{
                                    getContentAnchorEl: null,
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                  }}
                                  value={values.breed_id}
                                  onChange={handleChange}
                                >
                                  {optionsBreeds && optionsBreeds.breeds.map(breed => (
                                    <MenuItem
                                      key={breed.secure_id}
                                      value={breed.secure_id}
                                    >
                                      {breed.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <ErrorMessage name="breed_id" component={Error} />
                              </FormControl>
                            </Grid>
                          </StyledGridAndButton>
                        </Grid>
                        <Grid item sm xs={12}>
                          <TextField
                            label="Descrição"
                            name="description_breed"
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled
                            value={values.description_breed}
                            InputLabelProps={{
                              shrink: !!values.description_breed,
                            }}
                          />
                        </Grid>
                      </StyledGrid>
                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginTop={12}
                        marginBottom={12}
                      >
                        <Grid item sm={4} xs={12}>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                            error={!!errors.age && touched.age}
                          >
                            <InputLabel id="input-age">Idade</InputLabel>
                            <Select
                              name="age"
                              labelWidth={50}
                              inputProps={{
                                id: 'age-input',
                              }}
                              value={values.age}
                              onChange={handleChange}
                            >
                              {ages.map(item => (
                                <MenuItem key={item.id} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage name="age" component={Error} />
                          </FormControl>
                        </Grid>
                        <Grid item sm xs>
                          <StyledRadioGroup row defaultValue={values.sex}>
                            {gender.map(sex => (
                              <FormControlLabel
                                key={sex.value}
                                name="sex"
                                value={sex.value}
                                control={
                                  <Radio
                                    color="primary"
                                    size="small"
                                    name="sex"
                                    onChange={handleChange}
                                  />
                                }
                                label={sex.label}
                              />
                            ))}
                          </StyledRadioGroup>
                        </Grid>
                      </StyledGrid>
                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginTop={12}
                        marginBottom={12}
                      >
                        <Grid item sm xs={12}>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                            error={!!errors.type && touched.type}
                          >
                            <InputLabel id="input-type">Categoria</InputLabel>
                            <Select
                              name="type"
                              labelWidth={83}
                              inputProps={{
                                id: 'type-input',
                              }}
                              value={values.type}
                              onChange={handleChange}
                            >
                              {types
                                .filter(item => {
                                  if (values.sex === 'Macho') {
                                    return (
                                      item === 'Inteiro' ||
                                      item === 'Castrado' ||
                                      item === 'Bezerro'
                                    );
                                  }
                                  return (
                                    item === 'Vaca' ||
                                    item === 'Novilha' ||
                                    item === 'Bezerra'
                                  );
                                })
                                .map(item => (
                                  <MenuItem key={item.id} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                            </Select>
                            <ErrorMessage name="type" component={Error} />
                          </FormControl>
                        </Grid>
                        <Grid item sm xs>
                          <FormControl
                            size="small"
                            required
                            fullWidth
                            variant="outlined"
                            error={!!errors.body_size && touched.body_size}
                          >
                            <InputLabel id="input-body_size">
                              Tamanho Corporal
                            </InputLabel>
                            <Select
                              name="body_size"
                              labelWidth={145}
                              value={values.body_size}
                              onChange={handleChange}
                            >
                              {bodySizes.map(item => (
                                <MenuItem key={item.id} value={item}>
                                  {item}
                                </MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage name="body_size" component={Error} />
                          </FormControl>
                        </Grid>
                        <Grid item sm xs={12}>
                          <NumberFormatForm
                            name="amount_animals"
                            label="Quantidade"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            disabled={true}
                            allowNegative={false}
                            autoComplete="nope"
                            decimalScale={0}
                            value={values.amount_animals}
                            setFieldValue={setFieldValue}
                            error={
                              !!errors.amount_animals && touched.amount_animals
                            }
                          />
                          <ErrorMessage name="amount_animals" component={Error} />
                        </Grid>
                      </StyledGrid>

                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginBottom={16}
                      >
                        <Grid item sm xs={12}>
                          <NumberFormatForm
                            name="average_weight"
                            label="Peso Médio por Animal"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            autoComplete="nope"
                            allowNegative={false}
                            disabled={true}
                            decimalScale={2}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">Kg</InputAdornment>
                              ),
                            }}
                            value={values.average_weight}
                            setFieldValue={setFieldValue}
                            onChange={event =>
                              calcArrobaPrice(
                                setFieldValue,
                                values.average_price,
                                parseUserInput(event.target.value),
                                values.percent_rc
                              )
                            }
                            error={
                              !!errors.average_weight && touched.average_weight
                            }
                          />
                          <ErrorMessage name="average_weight" component={Error} />
                        </Grid>

                        <Grid item sm xs={12}>
                          <NumberFormatForm
                            name="percent_rc"
                            label="RC de Entrada (%)"
                            variant="outlined"
                            size="small"
                            required
                            disabled={true}
                            fullWidth
                            allowNegative={false}
                            decimalScale={2}
                            isAllowed={val => {
                              const { formattedValue, floatValue } = val;
                              return (
                                formattedValue === '' ||
                                formattedValue === '-' ||
                                (floatValue <= 100 && floatValue >= -100)
                              );
                            }}
                            value={values.percent_rc}
                            setFieldValue={setFieldValue}
                            onChange={event =>
                              calcArrobaPrice(
                                setFieldValue,
                                values.average_price,
                                values.average_weight,
                                parseUserInput(event.target.value)
                              )
                            }
                            error={!!errors.percent_rc && touched.percent_rc}
                          />
                          <ErrorMessage name="percent_rc" component={Error} />
                        </Grid>
                        <Grid item sm xs={12}>
                          <NumberFormatForm
                            name="percent_rc_forecast"
                            label="RC de Abate Previsto (%)"
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled={true}
                            allowNegative={false}
                            decimalScale={2}
                            isAllowed={val => {
                              const { formattedValue, floatValue } = val;
                              return formattedValue === '' || floatValue <= 100;
                            }}
                            value={values.percent_rc_forecast}
                            setFieldValue={setFieldValue}
                            error={
                              !!errors.percent_rc_forecast &&
                              touched.percent_rc_forecast
                            }
                          />
                          <ErrorMessage
                            name="percent_rc_forecast"
                            component={Error}
                          />
                        </Grid>
                      </StyledGrid>
                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginBottom={16}
                      >
                        <Grid item sm xs={12}>
                          <NumberFormatForm
                            allowedDecimalSeparators={[',']}
                            name="average_price"
                            fixedDecimalScale
                            label="Preço Médio por Animal"
                            variant="outlined"
                            size="small"
                            required
                            fullWidth
                            decimalScale={2}
                            value={values.average_price}
                            setFieldValue={setFieldValue}
                            onChange={event =>
                              calcArrobaPrice(
                                setFieldValue,
                                parseUserInput(event.target.value),
                                values.average_weight,
                                values.percent_rc
                              )
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              ),
                            }}
                            error={
                              !!errors.average_price && touched.average_price
                            }
                          />
                          <ErrorMessage name="average_price" component={Error} />
                        </Grid>
                        <Grid item sm xs={12}>
                          <NumberFormatForm
                            name="arroba_price"
                            label="Preço por Arroba"
                            variant="outlined"
                            size="small"
                            fullWidth
                            disabled
                            allowNegative={false}
                            decimalScale={2}
                            value={values.arroba_price}
                            setFieldValue={setFieldValue}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              ),
                            }}
                            error={!!errors.arroba_price && touched.arroba_price}
                          />
                          <ErrorMessage name="arroba_price" component={Error} />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <NumberFormatForm
                            allowedDecimalSeparators={[',']}
                            name="weight_forecast"
                            label="Peso de Abate Previsto"
                            variant="outlined"
                            size="small"
                            disabled={true}
                            fullWidth
                            allowNegative={false}
                            fixedDecimalScale
                            decimalScale={2}
                            value={values.weight_forecast}
                            setFieldValue={setFieldValue}
                            error={
                              !!errors.weight_forecast && touched.weight_forecast
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">Kg</InputAdornment>
                              ),
                            }}
                          />
                          <ErrorMessage
                            name="weight_forecast"
                            component={Error}
                          />
                        </Grid>
                      </StyledGrid>

                      <StyledGrid
                        container
                        justify="flex-start"
                        spacing={2}
                        marginBottom={16}
                      >
                        <Grid item sm={4} xs={12}>
                          <NumberFormatForm
                            allowedDecimalSeparators={[',']}
                            name="output_price_forecast"
                            label="Valor da Arroba para Saída Previsto"
                            variant="outlined"
                            size="small"
                            disabled={true}
                            fullWidth
                            allowNegative={false}
                            fixedDecimalScale
                            decimalScale={2}
                            value={values.output_price_forecast}
                            setFieldValue={setFieldValue}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              ),
                            }}
                            error={
                              !!errors.output_price_forecast &&
                              touched.output_price_forecast
                            }
                          />
                          <ErrorMessage
                            name="output_price_forecast"
                            component={Error}
                          />
                        </Grid>
                      </StyledGrid>

                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginBottom={16}
                      />

                      <Divider light />

                      <Divider light />
                      <StyledGrid
                        container
                        justify="space-between"
                        spacing={2}
                        marginTop={16}
                      >
                        <Grid item sm xs={12}>
                          <TextField
                            name="module_id"
                            label="Módulo"
                            variant="outlined"
                            fullWidth
                            disabled
                            select
                            required
                            size="small"
                            InputLabelProps={{
                              shrink: values.module_id,
                            }}
                            error={!!errors.module_id && touched.module_id}
                            value={values.module_id}
                          >
                            {defaultValuesLotEntry.originPaddock && (
                              <MenuItem
                                key={defaultValuesLotEntry.originPaddock.line.module.secure_id}
                                value={defaultValuesLotEntry.originPaddock.line.module.secure_id}
                              >
                                {defaultValuesLotEntry.originPaddock.line.module.name}
                              </MenuItem>
                            )}
                          </TextField>
                          <ErrorMessage name="module_id" component={Error} />
                        </Grid>
                        <Grid item sm xs={12}>
                          <TextField
                            name="line_id"
                            label="Linha"
                            variant="outlined"
                            fullWidth
                            select
                            disabled
                            required
                            size="small"
                            InputLabelProps={{
                              shrink: values.line_id,
                            }}
                            error={!!errors.line_id && touched.line_id}
                            value={values.line_id}
                          >
                            {defaultValuesLotEntry.originPaddock && (
                              <MenuItem
                                key={defaultValuesLotEntry.originPaddock.line.secure_id}
                                value={defaultValuesLotEntry.originPaddock.line.secure_id}
                              >
                                {defaultValuesLotEntry.originPaddock.line.name}
                              </MenuItem>
                            )}
                          </TextField>
                          <ErrorMessage name="line_id" component={Error} />
                        </Grid>
                        <Grid item sm xs={12}>
                          <TextField
                            name="paddock_id"
                            label="Piquete"
                            variant="outlined"
                            fullWidth
                            disabled={true}
                            select
                            required
                            size="small"
                            InputLabelProps={{
                              shrink: values.paddock_id,
                            }}
                            error={!!errors.paddock_id && touched.paddock_id}
                            value={values.paddock_id}
                          >
                            {defaultValuesLotEntry.originPaddock && (
                              <MenuItem
                                key={defaultValuesLotEntry.originPaddock.secure_id}
                                value={defaultValuesLotEntry.originPaddock.secure_id}
                              >
                                {defaultValuesLotEntry.originPaddock.name}
                              </MenuItem>
                            )}
                          </TextField>
                          <ErrorMessage name="paddock_id" component={Error} />
                        </Grid>
                      </StyledGrid>
                      <StyledGrid
                        container
                        justify="flex-start"
                        spacing={2}
                        marginBottom={12}
                      >
                        <Grid item sm xs={12}>
                          <StyledGridAndButton
                            container
                            direction="row"
                            justify="space-between"
                            style={{ marginTop: 8, marginBottom: 8 }}
                          >
                            <Grid item sm xs>
                              <TextField
                                name="diet_id"
                                label="Dieta"
                                variant="outlined"
                                fullWidth
                                select
                                disabled
                                required
                                size="small"
                                InputLabelProps={{
                                  shrink: values.diet_id,
                                }}
                                error={!!errors.diet_id && touched.diet_id}
                                value={values.diet_id}
                              >
                                {defaultValuesLotEntry.originLot && (
                                  <MenuItem
                                    key={defaultValuesLotEntry.originLot.diet.secure_id}
                                    value={defaultValuesLotEntry.originLot.diet.secure_id}
                                  >
                                    {defaultValuesLotEntry.originLot.diet.name}
                                  </MenuItem>
                                )}
                              </TextField>
                              <ErrorMessage name="diet_id" component={Error} />
                            </Grid>
                          </StyledGridAndButton>
                        </Grid>
                        <Grid item sm />
                      </StyledGrid>

                      <ContentSubmitButtons>
                        <StyledSubmitButton
                          variant="outlined"
                          color="primary"
                          onClick={handleBack}
                        >
                          Cancelar
                        </StyledSubmitButton>
                        <ButtonSubmit
                          title="Atualizar"
                          loading={editAnimalInputLot.isLoading}
                        />
                      </ContentSubmitButtons>
                    </Content>
                  </Form>
                )}
              </Formik>
            </StyledPaper>
          </Container>
        )}
    </center>
  );
}

Edit.defaultProps = {
  match: null,
};

Edit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

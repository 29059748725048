import styled from 'styled-components';
import { Paper, Grid, FormControl } from '@material-ui/core';

export const Content = styled.div`
  max-width: 800px;
`;

export const StyledPaper = styled(Paper)`
  position: relative;
  width: 100%;
  padding: 1px 6px;
`;

export const StyledGridItem = styled(Grid)`
  margin: 4px 8px;
`;

export const StyledFormControl = styled(FormControl)`
  margin-top: 8px;
`;

export const ContentDate = styled.div`
  min-width: 144px;
`;

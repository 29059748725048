import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { parseISO } from 'date-fns';
import { Formik, Form, ErrorMessage } from 'formik';
import {
  Grid,
  TextField,
  InputAdornment,
  MenuItem,
  Divider,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';

// Query
import { useApiV2ShowDataOutput } from '~/hooks/apiV2/animalHandling/AnimalOutput/useApiV2ShowDataOutput';
import { useApiV2AddAnimalsOutput } from '~/hooks/apiV2/animalHandling/AnimalOutput/useApiV2AddAnimalsOutput';

import {
  Container,
  HeaderPaper,
  StyledGrid,
  ShowInfo,
  StyledIconButton,
  ContentForm,
  Error,
  ContentButtons,
  StyledButton,
  StyledButtonClose,
} from './styles';

import {
  formatDateOnly,
  parseUserInput,
} from '~/utils/format';
import colors from '~/styles/colors';
import history from '~/services/history';
import NumberFormatForm from '~/components/NumberFormatForm';
import InputDatePicker from '~/components/InputDatePicker';
import ModalStakeholder from '~/components/ModalStakeholder';
import ButtonSubmit from '~/components/ButtonSubmit';
import { toast } from 'react-toastify';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.value,
});

export default function Add() {
  const [currentLot, setCurrentLot] = useState(null);
  const [listLots, setListLots] = useState([]);
  const [lots, setLots] = useState([]);

  const [listClients, setListClients] = useState([]);

  const [visibleModalStakeholder, setVisibleModalStakeholder] = useState(false);

  const [sex, setSex] = useState('');
  const [breed, setBreed] = useState('');
  const [age, setAge] = useState('');
  const [type, setType] = useState('');

  const [newListSexs, setNewListSexs] = useState([]);
  const [newListBreeds, setNewListBreeds] = useState([]);
  const [newListAges, setNewListAges] = useState([]);
  const [newListTypes, setNewListTypes] = useState([]);

  const [showOtherInformation, setShowOtherInformation] = useState(false);

  // Query
  const { data: lotsData } = useApiV2ShowDataOutput('client,both');

  const addAnimalOutput = useApiV2AddAnimalsOutput();

  useEffect(() => {
    if (lotsData) {
      const newLots = lotsData.optionsLots.map(lot => ({
        secure_id: lot.secure_id,
        value: lot.name,
      }));
      setListLots(newLots);
      setLots(lotsData.optionsLots);

      const newClients = lotsData.optionsStakeholders.map(client => ({
        secure_id: client.secure_id,
        value: client.name,
      }));

      setListClients(newClients);
    }
  }, [lotsData]);

  const schema = Yup.object().shape({
    date: Yup.date()
      .typeError('Insira uma data válida')
      .required('Digite a data de saída'),
    amount_animals: currentLot
      ? Yup.number()
        .typeError('Insira um valor válido')
        .max(
          currentLot.animals.length,
          'A qtd. deve ser menor ou igual a qtd. do lote'
        )
        .min(0, 'A qtd. deve ser maior que 0')
        .required('Digite a quantidade')
      : Yup.number()
        .typeError('Insira um valor válido')
        .required('Digite a quantidade'),
    output_weight: currentLot
      ? Yup.number()
        .typeError('Insira um valor válido')
        .min(0.01, 'O peso deve ser maior que 0')
        .required('Digite o peso')
      : Yup.number()
        .typeError('Insira um valor válido')
        .required('Digite o peso'),
    output_average_carcass_weight: Yup.number()
      .typeError('Insira um valor válido')
      .nullable(),
    output_carcass_yield: Yup.number().typeError('Insira um valor válido').nullable(),
    output_at_price: Yup.number().typeError('Insira um valor válido').nullable(),
    client_id: Yup.string()
      .typeError('Selecione um cliente válido')
      .required('Selecione um cliente'),
    absent: Yup.number().typeError('Insira um valor válido').nullable(),
    scarce: Yup.number().typeError('Insira um valor válido').nullable(),
    median: Yup.number().typeError('Insira um valor válido').nullable(),
    uniform: Yup.number().typeError('Insira um valor válido').nullable(),
    excessivo: Yup.number().typeError('Insira um valor válido').nullable(),
  });

  function changeCarcassYield(event, values, setFieldValue, typeCarcass) {
    if (
      typeCarcass === 'output_weight' &&
      parseUserInput(event.target.value) > 0
    ) {
      const newCarcassYield =
        (values.output_average_carcass_weight / parseUserInput(event.target.value)) *
        100;

      setFieldValue('output_carcass_yield', newCarcassYield);
    } else if (
      typeCarcass === 'output_average_carcass_weight' &&
      values.output_weight > 0
    ) {
      const newCarcassYield =
        (parseUserInput(event.target.value) / Number(values.output_weight)) *
        100;

      setFieldValue('output_carcass_yield', newCarcassYield);
    } else {
      setFieldValue('output_carcass_yield', '');
    }
  }

  function handleModalStakeholder() {
    setVisibleModalStakeholder(!visibleModalStakeholder);
  }

  function handleSelectLot(value, setFieldValue) {
    if (value) {
      const lotFind = lots.find(lot => lot.secure_id === value.secure_id)
      if (lotFind) {
        setFieldValue('lot_id', value.secure_id);
        setFieldValue('lot', value);
        setCurrentLot(lotFind);

        if (lotFind.dateOutput) setFieldValue('date', parseISO(lotFind.dateOutput, 'dd/MM/yyyy'))
      } else {
        setFieldValue('lot_id', '');
        setFieldValue('lot', null);
        setCurrentLot(null);
      }
    } else {
      setFieldValue('lot_id', '');
      setFieldValue('lot', null);
      setCurrentLot(null);
    }
  }

  function handleSelectClient(value, setFieldValue) {
    if (value) {
      setFieldValue('client', value);
      setFieldValue('client_id', value.secure_id);
    } else {
      setFieldValue('client', null);
      setFieldValue('client_id', '');
    }
  }

  const handleChangeSex = data => {
    setSex(data.target.value);
    setBreed('');
    setAge('');
    setType('');
  };

  const handleChangeBreed = data => {
    setBreed(data.target.value);
  };

  const handleChangeAge = data => {
    setAge(data.target.value);
  };

  const handleChangeType = data => {
    setType(data.target.value);
  };

  async function handleSubmit(values) {
    const sumQtd =
      Number(values.absent) +
      Number(values.scarce) +
      Number(values.median) +
      Number(values.uniform) +
      Number(values.excessivo);

    if (sumQtd > values.amount_animals) {
      toast.error(
        'A quantidade somada deve ser menor que a quantidade de saída!'
      );
      return;
    }

    const data = {
      amountAnimals: values.amount_animals,
      date: values.date,
      originLotSecureId: values.lot_id,
      stakeholderSecureId: values.client_id,
      // obs: values.obs,
      animals: {
        age: age,
        sex: sex,
        type: type,
        breedSecureId: breed,
        outputAtPrice: values.output_at_price,
        outputAverageCarcassWeight: values.output_average_carcass_weight,
        outputCarcassYield: values.output_carcass_yield,
        outputWeight: values.output_weight,
      }
    }
    await addAnimalOutput.mutateAsync(data)
  }

  function handleBack() {
    history.goBack();
  }

  useEffect(() => {
    if (currentLot) {
      const listSexsNew = [...new Set(currentLot.animals.map(x => x.sex))].map(animal => {
        const amount = currentLot.animals.filter(animalFilter => animalFilter.sex === animal).length
        return {
          sex: animal,
          amount
        }
      })
      setNewListSexs(listSexsNew)

      if (sex) {
        const listBreedsNew = [...new Set(currentLot.animals.map(x => x.breed.secure_id))].map(animal => {
          const amount = currentLot.animals.filter(animalFilter => animalFilter.sex === sex && animalFilter.breed.secure_id === animal).length
          const breedName = currentLot.animals.find(breedAnimal => breedAnimal.breed.secure_id === animal)
          return {
            breed: breedName ? breedName.breed.name : '',
            amount,
            secure_id: animal
          }
        }).filter(greatherThan => greatherThan.amount > 0)
        setNewListBreeds(listBreedsNew)

        if (breed) {
          const listAgesNew = [...new Set(currentLot.animals.map(x => x.age))].map(animal => {
            const amount = currentLot.animals.filter(
              animalFilter => animalFilter.sex === sex && animalFilter.breed.secure_id === breed && animalFilter.age === animal
            ).length
            return {
              amount,
              name: animal
            }
          }).filter(greatherThan => greatherThan.amount > 0)
          setNewListAges(listAgesNew)

          if (age) {
            const typeData = [...new Set(currentLot.animals.map(x => x.type))].map(animal => {
              const amount = currentLot.animals.filter(
                animalFilter => animalFilter.sex === sex && animalFilter.breed.secure_id === breed && animalFilter.age === age && animalFilter.type === animal
              ).length
              return {
                amount,
                name: animal
              }
            }).filter(greatherThan => greatherThan.amount > 0)
            setNewListTypes(typeData)
          }
        }
      }
    } else {
      setNewListAges([]);
      setNewListBreeds([]);
      setNewListTypes([]);
      setNewListSexs([]);
      setBreed('');
      setAge('');
      setType('');
      setSex('');
    }
  }, [sex, breed, age, currentLot])

  return (
    <center>
      <Container>
        <Formik
          validationSchema={schema}
          initialValues={{
            lot: null,
            lot_id: '',
            date: new Date(),
            breed_id: '',
            sex: '',
            age: '',
            type: '',
            amount_animals: '',
            output_weight: '',
            output_average_carcass_weight: '',
            output_carcass_yield: '',
            output_at_price: '',
            absent: '',
            scarce: '',
            median: '',
            uniform: '',
            excessivo: '',
            client: null,
            client_id: '',
          }}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, errors, touched, isSubmitting }) => (
            <Form>
              <HeaderPaper>
                <StyledGrid container justify="space-around" spacing={2}>
                  <Grid item xs={1} sm md lg />
                  <Grid item xs={10} sm>
                    <Autocomplete
                      name="lot"
                      size="small"
                      noOptionsText="Sem opções"
                      filterOptions={filterOptions}
                      options={listLots}
                      getOptionLabel={option => option.value}
                      value={values.lot}
                      onChange={(event, value) => handleSelectLot(value, setFieldValue)}
                      error={
                        (!!errors.lot_id && touched.lot_id)
                      }
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Lote"
                          required
                          fullWidth
                          variant="outlined"
                        />
                      )}
                    />
                    <ErrorMessage name="lot_id" component={Error} />
                  </Grid>
                  <Grid item xs={1} sm md lg />
                </StyledGrid>

                <ShowInfo>
                  <StyledGrid container justify="space-around" spacing={2}>
                    <Grid item xs={10} sm>
                      <TextField
                        label="Data Entrada"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: currentLot,
                        }}
                        value={
                          currentLot
                            ? formatDateOnly(currentLot.initial_date_diet)
                            : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={10} sm>
                      <NumberFormatForm
                        label="Quantidade"
                        decimalScale={0}
                        disabled
                        InputLabelProps={{
                          shrink: currentLot && currentLot.animals.length >= 0,
                        }}
                        value={currentLot ? currentLot.animals.length : ''}
                      />
                    </Grid>
                    <Grid item xs={10} sm>
                      <NumberFormatForm
                        label="Peso Médio Atual"
                        decimalScale={2}
                        disabled
                        InputLabelProps={{
                          shrink: currentLot && currentLot.animals.length >= 0,
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kg</InputAdornment>
                          ),
                        }}
                        value={
                          currentLot && currentLot.average_weight > 0
                            ? currentLot.average_weight
                            : ''
                        }
                      />
                    </Grid>
                  </StyledGrid>

                  <StyledGrid
                    container
                    justify="space-around"
                    spacing={2}
                    marginBottom={8}
                  >
                    <Grid item xs={10} sm>
                      <TextField
                        label="Módulo"
                        variant="outlined"
                        size="small"
                        disabled
                        fullWidth
                        InputLabelProps={{
                          shrink: currentLot,
                        }}
                        value={
                          currentLot ? currentLot.paddock.line.module.name : ''
                        }
                      />
                    </Grid>
                    <Grid item xs={10} sm>
                      <TextField
                        label="Linha"
                        variant="outlined"
                        size="small"
                        disabled
                        fullWidth
                        InputLabelProps={{
                          shrink: currentLot,
                        }}
                        value={currentLot ? currentLot.paddock.line.name : ''}
                      />
                    </Grid>

                    <Grid item xs={10} sm>
                      <TextField
                        label="Piquete"
                        variant="outlined"
                        size="small"
                        disabled
                        fullWidth
                        InputLabelProps={{
                          shrink: currentLot,
                        }}
                        value={currentLot ? currentLot.paddock.name : ''}
                      />
                    </Grid>
                  </StyledGrid>
                </ShowInfo>
              </HeaderPaper>
              <HeaderPaper style={{ marginTop: '24px' }}>
                <ContentForm>
                  <h3>Informações de Saída</h3>
                  <StyledGrid container justify="space-around" spacing={2}>
                    <Grid item xs={10} sm>
                      <InputDatePicker
                        name="date"
                        inputVariant="outlined"
                        fullWidth
                        required
                        maxDate={!!currentLot && currentLot.dateOutput ? parseISO(currentLot.dateOutput) : new Date()}
                        minDate={!!currentLot && currentLot.dateOutput ? parseISO(currentLot.dateOutput) : null}
                        selectedDate={values.date}
                        error={
                          (!!errors.date && touched.date)
                        }
                        handleChangeDate={date => {
                          setFieldValue('date', date);
                        }}
                      />
                      <ErrorMessage name="date" component={Error} />
                    </Grid>
                    <Grid item xs={10} sm>
                      <TextField
                        name="sex"
                        label="Sexo"
                        variant="outlined"
                        fullWidth
                        select
                        required
                        size="small"
                        InputLabelProps={{
                          shrink: sex,
                        }}
                        error={(!!errors.sex && touched.sex)}
                        value={sex}
                        onChange={handleChangeSex}
                      >
                        {newListSexs.map(sexL => (
                          <MenuItem key={sexL.sex} value={sexL.sex}>
                            {`${sexL.sex} - Saldo: ${sexL.amount}`}
                          </MenuItem>
                        ))}
                      </TextField>
                      <ErrorMessage name="sex" component={Error} />
                    </Grid>
                    <Grid item xs={10} sm>
                      <TextField
                        name="breed_id"
                        label="Raça"
                        variant="outlined"
                        fullWidth
                        select
                        required
                        size="small"
                        InputLabelProps={{
                          shrink: breed,
                        }}
                        error={
                          (!!errors.breed_id && touched.breed_id)
                        }
                        value={breed}
                        onChange={handleChangeBreed}
                      >
                        {newListBreeds.map(item => {
                          return item && item.secure_id ? (
                            <MenuItem key={item.secure_id} value={item.secure_id}>
                              {`${item.breed} - Saldo: ${item.amount}`}
                            </MenuItem>
                          ) : (
                            ''
                          );
                        })}
                      </TextField>
                      <ErrorMessage name="breed_id" component={Error} />
                    </Grid>
                  </StyledGrid>

                  <StyledGrid container justify="space-around" spacing={2}>
                    <Grid item xs={10} sm>
                      <TextField
                        name="age"
                        label="Idade"
                        variant="outlined"
                        fullWidth
                        select
                        required
                        size="small"
                        InputLabelProps={{
                          shrink: age,
                        }}
                        error={(!!errors.age && touched.age)}
                        value={age}
                        onChange={handleChangeAge}
                      >
                        {newListAges.map(ageD =>
                          ageD && ageD.name ? (
                            <MenuItem key={ageD.name} value={ageD.name}>
                              {`${ageD.name} - Saldo: ${ageD.amount}`}
                            </MenuItem>
                          ) : (
                            ''
                          )
                        )}
                      </TextField>
                      <ErrorMessage name="age" component={Error} />
                    </Grid>
                    <Grid item xs={10} sm>
                      <TextField
                        name="type"
                        label="Tipo"
                        variant="outlined"
                        fullWidth
                        select
                        required
                        size="small"
                        InputLabelProps={{
                          shrink: type,
                        }}
                        error={(!!errors.type && touched.type)}
                        value={type}
                        onChange={handleChangeType}
                      >
                        {newListTypes.map(typeD =>
                          typeD && typeD.name ? (
                            <MenuItem key={typeD.name} value={typeD.name}>
                              {`${typeD.name} - Saldo: ${typeD.amount}`}
                            </MenuItem>
                          ) : (
                            ''
                          )
                        )}
                      </TextField>
                      <ErrorMessage name="type" component={Error} />
                    </Grid>
                    <Grid item xs={10} sm>
                      <NumberFormatForm
                        name="amount_animals"
                        label="Qtd. de Saída"
                        variant="outlined"
                        size="small"
                        min="0"
                        decimalScale={0}
                        fullWidth
                        required
                        isAllowed={val => {
                          const { formattedValue, floatValue } = val;
                          return (
                            formattedValue === '' || floatValue <= 9999999999
                          );
                        }}
                        error={
                          (!!errors.amount_animals &&
                            touched.amount_animals)
                        }
                        value={values.amount_animals}
                        setFieldValue={setFieldValue}
                      />
                      <ErrorMessage
                        name="amount_animals"
                        component={Error}
                      />
                    </Grid>
                  </StyledGrid>
                  <StyledGrid justify="space-around" container spacing={2}>
                    <Grid item xs={10} sm>
                      <NumberFormatForm
                        name="output_weight"
                        label="Peso Médio de Saída"
                        size="small"
                        variant="outlined"
                        min="0"
                        decimalScale={2}
                        fullWidth
                        required
                        isAllowed={val => {
                          const { formattedValue, floatValue } = val;
                          return (
                            formattedValue === '' || floatValue <= 9999999999
                          );
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">Kg</InputAdornment>
                          ),
                        }}
                        error={
                          !!errors.output_weight && touched.output_weight
                        }
                        value={values.output_weight}
                        setFieldValue={setFieldValue}
                        onChange={event =>
                          changeCarcassYield(
                            event,
                            values,
                            setFieldValue,
                            'output_weight'
                          )
                        }
                      />
                      <ErrorMessage name="output_weight" component={Error} />
                    </Grid>
                    <Grid item xs={10} sm>
                      <Autocomplete
                        name="client"
                        size="small"
                        noOptionsText="Sem opções"
                        filterOptions={filterOptions}
                        options={listClients}
                        getOptionLabel={option => option.value}
                        value={values.client}
                        onChange={(event, value) =>
                          handleSelectClient(value, setFieldValue)
                        }
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Cliente"
                            error={!!errors.client_id && touched.client_id}
                            required
                            fullWidth
                            variant="outlined"
                          />
                        )}
                      />
                      <ErrorMessage name="client" component={Error} />
                    </Grid>
                    <Grid item xs={10} sm style={{ marginTop: 4 }}>
                      <StyledIconButton
                        variant="contained"
                        size="small"
                        onClick={handleModalStakeholder}
                      >
                        <AddIcon />
                      </StyledIconButton>
                    </Grid>
                  </StyledGrid>
                  <>
                  </>
                </ContentForm>
                {!showOtherInformation && (
                  <center style={{ marginTop: '24px' }}>
                    <StyledButton
                      variant="contained"
                      type="button"
                      background={colors.success}
                      onClick={() => setShowOtherInformation(true)}
                      style={{ width: '300px', margin: 0 }}
                    >
                      <span>Adicionar Informações de Abate</span>
                    </StyledButton>
                  </center>
                )}
                {showOtherInformation && (
                  <>
                    <ContentForm>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <StyledButtonClose
                          onClick={() => setShowOtherInformation(false)}
                        >
                          Ocultar
                        </StyledButtonClose>
                      </div>
                      <h3>Informações de Abate</h3>
                      <StyledGrid
                        container
                        justify="space-around"
                        spacing={2}
                      >
                        <Grid item xs={10} sm>
                          <NumberFormatForm
                            name="output_average_carcass_weight"
                            label="Peso Médio da Carcaça"
                            size="small"
                            variant="outlined"
                            decimalScale={2}
                            fullWidth
                            isAllowed={val => {
                              const { formattedValue, floatValue } = val;
                              return (
                                formattedValue === '' ||
                                floatValue <= 9999999999
                              );
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  Kg
                                </InputAdornment>
                              ),
                            }}
                            error={
                              !!errors.output_average_carcass_weight &&
                              touched.output_average_carcass_weight
                            }
                            value={values.output_average_carcass_weight}
                            setFieldValue={setFieldValue}
                            onChange={event =>
                              changeCarcassYield(
                                event,
                                values,
                                setFieldValue,
                                'output_average_carcass_weight'
                              )
                            }
                          />
                          <ErrorMessage
                            name="output_average_carcass_weight"
                            component={Error}
                          />
                        </Grid>
                        <Grid item xs={10} sm>
                          <NumberFormatForm
                            name="output_carcass_yield"
                            label="Rendimento da Carcaça (%)"
                            size="small"
                            variant="outlined"
                            decimalScale={2}
                            fixedDecimalScale
                            fullWidth
                            disabled
                            error={
                              !!errors.output_carcass_yield && touched.output_carcass_yield
                            }
                            value={values.output_carcass_yield}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage
                            name="output_carcass_yield"
                            component={Error}
                          />
                        </Grid>
                        <Grid item md={4} xs={10} sm>
                          <NumberFormatForm
                            name="output_at_price"
                            label="Preço da arroba"
                            size="small"
                            variant="outlined"
                            min="0"
                            decimalScale={2}
                            fixedDecimalScale
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              ),
                            }}
                            isAllowed={val => {
                              const { formattedValue, floatValue } = val;
                              return (
                                formattedValue === '' ||
                                floatValue <= 9999999999
                              );
                            }}
                            error={
                              !!errors.output_at_price && touched.output_at_price
                            }
                            value={values.output_at_price}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage
                            name="output_at_price"
                            component={Error}
                          />
                        </Grid>
                      </StyledGrid>

                      <Divider light />

                      <StyledGrid
                        container
                        justify="space-around"
                        spacing={2}
                        marginTop={14}
                      >
                        <Grid item xs={10} sm>
                          <NumberFormatForm
                            name="absent"
                            label="Qtd. Ausente"
                            variant="outlined"
                            size="small"
                            min="0"
                            decimalScale={0}
                            fullWidth
                            error={
                              (!!errors.absent && touched.absent)
                            }
                            value={values.absent}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage name="absent" component={Error} />
                        </Grid>
                        <Grid item xs={10} sm>
                          <NumberFormatForm
                            name="scarce"
                            label="Qtd. Escasso"
                            variant="outlined"
                            size="small"
                            min="0"
                            decimalScale={0}
                            fullWidth
                            error={
                              (!!errors.scarce && touched.scarce)
                            }
                            value={values.scarce}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage name="scarce" component={Error} />
                        </Grid>
                        <Grid item xs={10} sm>
                          <NumberFormatForm
                            name="median"
                            label="Qtd. Mediano"
                            variant="outlined"
                            size="small"
                            min="0"
                            decimalScale={0}
                            fullWidth
                            error={
                              (!!errors.median && touched.median)
                            }
                            value={values.median}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage name="median" component={Error} />
                        </Grid>
                      </StyledGrid>

                      <StyledGrid
                        container
                        justify="space-around"
                        spacing={2}
                      >
                        <Grid item xs={10} sm>
                          <NumberFormatForm
                            name="uniform"
                            label="Qtd. Uniforme"
                            variant="outlined"
                            size="small"
                            min="0"
                            decimalScale={0}
                            fullWidth
                            error={
                              (!!errors.uniform && touched.uniform)
                            }
                            value={values.uniform}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage name="uniform" component={Error} />
                        </Grid>
                        <Grid item xs={10} sm>
                          <NumberFormatForm
                            name="excessivo"
                            label="Qtd. Excessiva"
                            variant="outlined"
                            size="small"
                            min="0"
                            decimalScale={0}
                            fullWidth
                            error={
                              (!!errors.excessivo && touched.excessivo)
                            }
                            value={values.excessivo}
                            setFieldValue={setFieldValue}
                          />
                          <ErrorMessage name="excessivo" component={Error} />
                        </Grid>
                        <Grid item xs={10} sm />
                      </StyledGrid>
                    </ContentForm>
                  </>
                )}
              </HeaderPaper>
              <ContentButtons>
                <StyledButton
                  color="primary"
                  spanColor={colors.primary}
                  variant="outlined"
                  onClick={handleBack}
                >
                  Voltar
                </StyledButton>
                <ButtonSubmit
                  title="Cadastrar"
                  loading={isSubmitting}
                />
              </ContentButtons>
            </Form>
          )}
        </Formik>

        {visibleModalStakeholder && (
          <ModalStakeholder
            visibleModal={visibleModalStakeholder}
            handleModal={handleModalStakeholder}
            type="client,both"
          />
        )}
      </Container>
    </center>
  );
}

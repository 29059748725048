import React from 'react';
import { toast } from 'react-toastify'
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { TextField } from '@material-ui/core';

import apiV2 from '~/services/apiV2';
import history from '~/services/history';

import {
  Container,
  Content,
  Back,
  StyledGridItem,
  Error,
  StyledSubmitButton,
} from './styles';

import logo from '~/assets/images/logo_black.png';
import colors from '~/styles/colors';

const schema = Yup.object().shape({
  email: Yup.string().email('E-mail inválido').required('E-mail obrigatório'),
});

export default function Forgot() {
  const handleForgotPassword = async ({ email }) => {
    try {
      await apiV2.post('/v1/feedlot/auth/forgotPassword', {
        email: email,
        redirectUrl: `${process.env.REACT_APP_URL_FORGOT_PASSWORD}`
      })

      toast.success('E-mail de recuperação enviado!');
      history.push('/');
    } catch (error) {
      const type = error.response.data?.type ? error.response.data.type : 'error'
      const message = error.response.data?.message ? error.response.data.message : 'Ocorreu um erro ao enviar e-mail.'
      toast[type](message)
    }
  }

  return (
    <Container>
      <Back to="/">Voltar</Back>
      <Content>
        <img src={logo} alt="logo" />
        <Formik
          validationSchema={schema}
          initialValues={{ email: '' }}
          onSubmit={handleForgotPassword}
        >
          {({ handleChange, errors, touched, isSubmitting }) => (
            <Form style={{ width: '90%' }}>
              <StyledGridItem item marginTop={50}>
                <TextField
                  name="email"
                  label="E-mail"
                  variant="outlined"
                  autoFocus
                  fullWidth
                  error={!!errors.email && touched.email}
                  onChange={handleChange}
                />
                <ErrorMessage name="email" component={Error} />
              </StyledGridItem>

              <center>
                <StyledGridItem item marginTop={50}>
                  <StyledSubmitButton
                    variant="contained"
                    background={colors.success}
                    color={colors.white}
                    type="submit"
                  >
                    {isSubmitting ? 'Enviando...' : 'Enviar'}
                  </StyledSubmitButton>
                </StyledGridItem>
              </center>
            </Form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

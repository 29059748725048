import React, { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { formatNumber } from '~/utils/format';
export default function TextFieldEdit({
  change,
  defaultValue,
  treatment,
  treaty,
  treatyTreatments,
  setTreatyTreatments,
  treatmentClear,
  setTreatmentClear,
  treaties, //eslint-disable-line
  currentListTotalPerformed, //eslint-disable-line
  setCurrentListTotalPerformed, //eslint-disable-line
}) {
  const [value, setValue] = useState('');

  function calcTreaty() {
    const hasTreatmentPerformed = treatment.treatyTreatments.filter(
      tt => tt.performed !== null
    );

    const isCurrentTreatmentPerformed = hasTreatmentPerformed.find(
      item => item.treaty.secure_id === treaty.secure_id
    );

    if (isCurrentTreatmentPerformed) {
      return isCurrentTreatmentPerformed.forecast;
    }

    const totalPerformed = hasTreatmentPerformed.reduce(
      (total, item) => total + item.performed,
      0
    );

    if (treaties.length - hasTreatmentPerformed.length > 0) {
      const totalProportionByTreaty = hasTreatmentPerformed.reduce(
        (total, item) => total + item.treaty.proportion,
        0
      );

      const proportionPerformedByTreaty =
        totalProportionByTreaty /
        (treaties.length - hasTreatmentPerformed.length);

      const currentTotal = treatment.total_forecast - totalPerformed;

      const currentProportion = treaty.proportion + proportionPerformedByTreaty;

      const currentTreaty = Math.round(
        currentTotal * (currentProportion / 100)
      );

      if (currentTreaty < 0) {
        return 0;
      }

      return formatNumber(currentTreaty, 0, 4);
    }

    return 0;
  }

  function calcTotalPerformed(treatmentId, currentListTreatyTreatment) {
    const newListTreatyTreatment = currentListTreatyTreatment.filter(
      tt => tt.treatment_id === treatmentId
    );
    const oldListTreatyTreatment = treatment.treatyTreatments.filter(
      tt1 => !newListTreatyTreatment.some(tt2 => tt1.treaty.secure_id === tt2.treaty_id)
    );

    const oldTotalPerformed = oldListTreatyTreatment.reduce(
      (total, item) => total + Number(item.performed),
      0
    );

    const currentTotalPerformed = newListTreatyTreatment.reduce(
      (total, item) => total + Number(item.performed),
      0
    );

    return Math.round(oldTotalPerformed + currentTotalPerformed);
  }

  function handleEdit(floatValue, treatmentId, treatyId) {
    if (change) {
      setValue(floatValue);
    }

    // Treaty treatment
    let currentListTreatyTreatment = null;
    const forecast = calcTreaty();

    const hasTreatyTreatment = treatyTreatments.find(
      tt => tt.treatment_id === treatmentId && tt.treaty_id === treatyId
    );

    const newTreatyTreatment = {
      forecast,
      performed: floatValue === undefined ? null : floatValue,
      treatment_id: treatmentId,
      treaty_id: treatyId,
    };

    if (hasTreatyTreatment) {
      const oldTreatyTreatment = treatyTreatments.filter(
        tt => tt.treatment_id !== treatmentId || tt.treaty_id !== treatyId
      );

      currentListTreatyTreatment = [...oldTreatyTreatment, newTreatyTreatment];
    } else {
      currentListTreatyTreatment = [...treatyTreatments, newTreatyTreatment];
    }

    setTreatyTreatments(currentListTreatyTreatment);

    // Total Performed
    let newCurrentListTotalPerformed = null;

    const totalPerformed = calcTotalPerformed(
      treatmentId,
      currentListTreatyTreatment
    );
    const newTotalPerformed = {
      treatment_id: treatmentId,
      total_performed: totalPerformed,
    };

    const hasTotalPerformed = currentListTotalPerformed.find( //eslint-disable-line
      tp => tp.treatment_id === treatmentId
    );

    if (hasTotalPerformed) {
      const oldTotalPerformed = currentListTotalPerformed.filter( //eslint-disable-line
        tp => tp.treatment_id !== treatmentId
      );

      newCurrentListTotalPerformed = [...oldTotalPerformed, newTotalPerformed];
    } else {
      newCurrentListTotalPerformed = [
        ...currentListTotalPerformed,
        newTotalPerformed,
      ];
    }

    setCurrentListTotalPerformed(newCurrentListTotalPerformed);
  }

  useEffect(() => {
    if (treatmentClear === treatment.secure_id) {
      setValue('');
      setTreatmentClear(null);
    }
  }, [setTreatmentClear, treatment.secure_id, treatmentClear]);

  useEffect(() => {
    if (!change) {
      setValue(defaultValue);
    }
  }, [
    change,
    currentListTotalPerformed,
    defaultValue,
    treatment.secure_id,
    treaty.secure_id,
  ]);

  return (
    <NumberFormat
      id="number-format"
      customInput={TextField}
      max="999999.99"
      min="0.00"
      thousandSeparator="."
      decimalSeparator=","
      isNumericString
      allowNegative={false}
      decimalScale={0}
      autoComplete="nope"
      defaultValue={defaultValue}
      style={{ minWidth: 50 }}
      disabled={!change}
      value={value}
      onValueChange={values =>
        handleEdit(values.floatValue, treatment.secure_id, treaty.secure_id)
      }
    />
  );
}

TextFieldEdit.defaultProps = {
  treatmentClear: null,
};

TextFieldEdit.propTypes = {
  change: PropTypes.bool.isRequired,
  defaultValue: PropTypes.number.isRequired,
  treatment: PropTypes.shape({
    secure_id: PropTypes.string,
    total_forecast: PropTypes.number,
    treaties: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  treaty: PropTypes.shape({
    secure_id: PropTypes.number,
    proportion: PropTypes.number,
  }).isRequired,
  treatyTreatments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setTreatyTreatments: PropTypes.func.isRequired,
  treatmentClear: PropTypes.number,
  setTreatmentClear: PropTypes.func.isRequired,
};

import React, { useState, useEffect } from 'react';
import {
  parseISO
} from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { Formik, Form, ErrorMessage } from 'formik';
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Divider,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

// Query
import { useApiV2ShowDataApartLot } from '~/hooks/apiV2/animalHandling/IndividualTransferApartLot/useApiV2ShowDataApartLot';
import { useApiV2AddIndividualTransferApartLot } from '~/hooks/apiV2/animalHandling/IndividualTransferApartLot/useApiV2AddIndividualTransferApartLot';

import {
  Container,
  Content,
  StyledPaper,
  StyledGrid,
  ContentSubmitButtons,
  StyledSubmitButton,
  Error,
} from './styles';

import history from '~/services/history';
import NumberFormatForm from '~/components/NumberFormatForm';
import ButtonSubmit from '~/components/ButtonSubmit';
import Loader from '~/components/Loader';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.value,
});

export default function Register() {
  const [disabledDietOptions, setDisabledDietOptions] = useState(false);
  const [disabledModuleOptions, setDisabledModuleOptions] = useState(false);

  const [listLots, setListLots] = useState([]);
  const [currentLot, setCurrentLot] = useState(null);
  const [listLine, setListLine] = useState([]);
  const [listPaddock, setListPaddock] = useState([]);

  const [aparts, setAparts] = useState([]);
  const [lots, setLots] = useState([]);
  const [diets, setDiets] = useState([]);
  const [modules, setModules] = useState([]);

  // Query
  const { data, isLoading } = useApiV2ShowDataApartLot();
  const addAnimalInputLotApart = useApiV2AddIndividualTransferApartLot();

  useEffect(() => {
    if (data) {
      const newLots = data.optionsLots.map(lot => ({
        secure_id: lot.secure_id,
        value: lot.name
      }));

      setLots(data.optionsLots);
      setDiets(data.optionsDiets);
      setModules(data.optionsModules);
      setListLots(newLots);
      setAparts(data.optionsAparts);
    }
  }, [data])

  function handleSelectModule(event, setFieldValue) {
    const currentModule = modules.find(
      module => module.secure_id === event.target.value
    );

    if (currentModule) {
      setFieldValue('module_id', currentModule.secure_id);
      setFieldValue('line_id', '');
      setFieldValue('paddock_id', '');
      setListLine(currentModule.lines);
      setFieldValue('diet_id', '');
      setDisabledDietOptions(false);
    } else {
      setFieldValue('module_id', '');
      setFieldValue('line_id', '');
      setFieldValue('paddock_id', '');
      setListLine([]);
      setFieldValue('diet_id', '');
      setDisabledDietOptions(false);
    }
  }

  function handleSelectLine(event, setFieldValue) {
    const currentLine = listLine.find(
      line => line.secure_id === event.target.value
    );

    if (currentLine) {
      setFieldValue('line_id', currentLine.secure_id);
      setFieldValue('paddock_id', '');
      setListPaddock(currentLine.paddocks);
      setFieldValue('diet_id', '');
      setDisabledDietOptions(false);
    } else {
      setFieldValue('line_id', '');
      setFieldValue('paddock_id', '');
      setListPaddock([]);
      setFieldValue('diet_id', '');
      setDisabledDietOptions(false);
    }
  }

  function handleSelectPaddock(event, setFieldValue) {
    const currentPaddock = listPaddock.find(
      paddock => paddock.secure_id === event.target.value
    );

    if (currentPaddock) {
      setFieldValue('paddock_id', currentPaddock.secure_id);

      if (currentPaddock.diet) {
        setFieldValue('diet_id', currentPaddock.diet.secure_id);
        setDisabledDietOptions(true);
      } else {
        setFieldValue('diet_id', '');
        setDisabledDietOptions(false);
      }
    } else {
      setFieldValue('paddock_id', '');
      setFieldValue('diet_id', '');
      setDisabledDietOptions(false);
    }
  }

  function handleSelectLot(value, setFieldValue, apartSelected) {
    const selectedLot = value && lots.find(lot => lot.secure_id === value.secure_id);

    if (selectedLot) {
      setCurrentLot(selectedLot);

      setFieldValue('destination_lot', value);
      setFieldValue('destination_lot_id', selectedLot.secure_id);

      if (selectedLot.paddock) {
        setFieldValue('module_id', selectedLot.paddock.line.module.secure_id);
        setFieldValue('line_id', selectedLot.paddock.line.secure_id);
        setFieldValue('paddock_id', selectedLot.paddock.secure_id);
        setDisabledModuleOptions(true);

        setListLine([selectedLot.paddock.line]);
        setListPaddock([selectedLot.paddock]);
      } else {
        setFieldValue('module_id', '');
        setFieldValue('line_id', '');
        setFieldValue('paddock_id', '');
        setDisabledModuleOptions(false);

        setListLine([]);
        setListPaddock([]);
      }

      if (selectedLot.diet) {
        setFieldValue('diet_id', selectedLot.diet.secure_id);
        setDisabledDietOptions(true);
      } else {
        setFieldValue('diet_id', '');
        setDisabledDietOptions(false);
      }

      if (selectedLot.dateEntry) {
        setFieldValue('date', parseISO(selectedLot.dateEntry));
      } else if (apartSelected) {
        const currentApart = aparts.find(apart => apart.apart === apartSelected)
        if (currentApart) {
          setFieldValue('date', parseISO(currentApart.date));
        }

      } else {
        setFieldValue('date', new Date());
      }
    } else {
      setCurrentLot(null);

      setFieldValue('destination_lot', null);
      setFieldValue('destination_lot_id', '');
      setFieldValue('module_id', '');
      setFieldValue('line_id', '');
      setFieldValue('paddock_id', '');
      setFieldValue('diet_id', '');

      setDisabledModuleOptions(false);
      setDisabledDietOptions(true);
    }
  }

  async function handleSubmit(values) {
    const data = {
      amountAnimals: values.amount_animals,
      averageWeight: values.average_weight,
      dietSecureId: values.diet_id,
      paddockSecureId: values.paddock_id,
      lotSecureId: values.lot_id !== 'new' ? values.lot_id : null,
      percentRc: values.percent_rc,
      averagePrice: values.average_price,
      arrobaPrice: values.arroba_price,
      apart: values.apart,
      date: values.date,
    }
    await addAnimalInputLotApart.mutateAsync(data);
  }

  function handleBack() {
    history.goBack();
  }

  function handleSelectApart(setFieldValue, value) {
    const currentApart = aparts.find(apart => apart.apart === value)

    if (currentApart) {
      setFieldValue('apart', currentApart.apart);
      setFieldValue('amount_animals', currentApart.animalAmount);
      setFieldValue('average_weight', currentApart.weightTotal);
      setFieldValue('percent_rc', currentApart.rcInputTotal);
      setFieldValue('average_price', currentApart.priceAnimalTotal);
      setFieldValue('arroba_price', currentApart.priceArrobalTotal);

      if (!currentLot) {
        setFieldValue('date', parseISO(currentApart.date));
      } else if (currentLot && !currentLot.dateEntry) {
        setFieldValue('date', parseISO(currentApart.date));
      }
    } else {
      setFieldValue('apart', '');
      setFieldValue('date', '');
      setFieldValue('amount_animals', 0);
      setFieldValue('average_weight', 0);
      setFieldValue('percent_rc', 0);
      setFieldValue('average_price', 0);
      setFieldValue('arroba_price', 0);
    }
  }

  return (
    <center>
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          <StyledPaper>
            <Formik
              initialValues={{
                lot: listLots ? listLots[0] : null,
                lot_id: 'new',
                diet_id: '',
                module_id: '',
                line_id: '',
                paddock_id: '',
                apart: '',
                amount_animals: 0,
                average_weight: 0,
                percent_rc: 0,
                average_price: 0,
                arroba_price: 0,
                date: new Date(),
              }}
              onSubmit={handleSubmit}
            >
              {({ handleChange, setFieldValue, values, errors, touched, isSubmitting }) => (
                <Form>
                  <Content>
                    <StyledGrid
                      container
                      justify="space-between"
                      spacing={2}
                      marginBottom={12}
                    >
                      <Grid item sm xs={12}>
                        <FormControl
                          size="small"
                          required
                          fullWidth
                          variant="outlined"
                          error={!!errors.apart && touched.apart}
                        >
                          <InputLabel id="input-apart">Aparte</InputLabel>
                          <Select
                            name="apart"
                            labelWidth={50}
                            inputProps={{
                              id: 'apart-input',
                            }}
                            value={values.apart}
                            onChange={event => handleSelectApart(setFieldValue, event.target.value)}
                          >
                            {aparts.map(item => (
                              <MenuItem key={item.apart} value={item.apart}>
                                {item.apart}
                              </MenuItem>
                            ))}
                          </Select>
                          <ErrorMessage name="apart" component={Error} />
                        </FormControl>
                      </Grid>
                      <Grid item sm xs={12}>
                        <Autocomplete
                          name="lot"
                          size="small"
                          noOptionsText="Sem opções"
                          filterOptions={filterOptions}
                          options={listLots}
                          getOptionLabel={option => option.value}
                          value={values.lot}
                          onChange={(event, value) =>
                            handleSelectLot(value, setFieldValue, values.apart)
                          }
                          renderInput={params => (
                            <TextField
                              {...params}
                              name="lot_id"
                              label="Lote"
                              required
                              fullWidth
                              variant="outlined"
                            />
                          )}
                        />
                      </Grid>
                    </StyledGrid>

                    <Divider light />

                    <StyledGrid container justify="space-between" spacing={2}>
                      <Grid item sm xs={12}>
                        <TextField
                          name="module_id"
                          label="Módulo"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          InputLabelProps={{
                            shrink: values.module_id,
                          }}
                          disabled={disabledModuleOptions}
                          error={!!errors.module_id && touched.module_id}
                          value={values.module_id}
                          onChange={event =>
                            handleSelectModule(event, setFieldValue)
                          }
                        >
                          {modules.map(item => (
                            <MenuItem key={item.secure_id} value={item.secure_id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <ErrorMessage name="module_id" component={Error} />
                      </Grid>
                      <Grid item sm xs={12}>
                        <TextField
                          name="line_id"
                          label="Linha"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          InputLabelProps={{
                            shrink: values.line_id,
                          }}
                          disabled={disabledModuleOptions}
                          error={!!errors.line_id && touched.line_id}
                          value={values.line_id}
                          onChange={event =>
                            handleSelectLine(event, setFieldValue)
                          }
                        >
                          {listLine.map(item => (
                            <MenuItem key={item.secure_id} value={item.secure_id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <ErrorMessage name="line_id" component={Error} />
                      </Grid>
                      <Grid item sm xs={12}>
                        <TextField
                          name="paddock_id"
                          label="Piquete"
                          variant="outlined"
                          fullWidth
                          select
                          required
                          size="small"
                          InputLabelProps={{
                            shrink: values.paddock_id,
                          }}
                          disabled={disabledModuleOptions}
                          error={!!errors.paddock_id && touched.paddock_id}
                          value={values.paddock_id}
                          onChange={event => {
                            handleSelectPaddock(event, setFieldValue);
                          }}
                        >
                          {listPaddock.map(item => (
                            <MenuItem key={item.secure_id} value={item.secure_id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </TextField>
                        <ErrorMessage name="paddock_id" component={Error} />
                      </Grid>
                    </StyledGrid>

                    <Divider light />

                    <StyledGrid
                      container
                      justify="space-between"
                      spacing={2}
                      marginTop={12}
                      marginBottom={12}
                    >
                      <Grid sm={8} xs={12}>
                        <StyledGrid
                          container
                          direction="row"
                          justify="space-between"
                          style={{ marginTop: 8, marginBottom: 8 }}
                        >
                          <Grid item sm xs style={{ marginLeft: 8 }}>
                            <TextField
                              name="diet_id"
                              label="Dieta"
                              variant="outlined"
                              fullWidth
                              select
                              required
                              disabled={disabledModuleOptions || disabledDietOptions}
                              size="small"
                              InputLabelProps={{
                                shrink: values.diet_id,
                              }}
                              error={!!errors.diet_id && touched.diet_id}
                              value={values.diet_id}
                              onChange={handleChange}
                            >
                              {diets.map(item => (
                                <MenuItem
                                  key={item.secure_id}
                                  value={item.secure_id}
                                >
                                  {item.name}
                                </MenuItem>
                              ))}
                            </TextField>
                            <ErrorMessage name="diet_id" component={Error} />
                          </Grid>
                        </StyledGrid>
                      </Grid>
                      <Grid item sm />
                    </StyledGrid>

                    <Divider light />

                    <StyledGrid
                      container
                      justify="space-between"
                      spacing={2}
                      marginTop={12}
                      marginBottom={12}
                    >
                      <Grid item sm xs={12}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={ptBR}
                        >
                          <KeyboardDatePicker
                            name="date"
                            disableToolbar
                            variant="inline"
                            format="dd/MM/yyyy"
                            margin="normal"
                            size="small"
                            id="date-picker-inline"
                            label="Data de entrada"
                            inputVariant="outlined"
                            maxDate={new Date()}
                            required
                            disabled
                            fullWidth
                            autoOk
                            style={{ marginTop: 0, marginBottom: 0 }}
                            value={values.date}
                          />
                          <ErrorMessage name="date" component={Error} />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item sm xs={12}>
                        <NumberFormatForm
                          name="amount_animals"
                          label="Quantidade"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          allowNegative={false}
                          autoComplete="nope"
                          disabled
                          decimalScale={0}
                          value={values.amount_animals}
                        />
                      </Grid>
                      <Grid item sm xs={12}>
                        <NumberFormatForm
                          name="average_weight"
                          label="Peso Médio por Animal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          autoComplete="nope"
                          allowNegative={false}
                          decimalScale={2}
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">Kg</InputAdornment>
                            ),
                          }}
                          value={values.average_weight}
                        />
                      </Grid>
                    </StyledGrid>

                    <StyledGrid
                      container
                      justify="space-between"
                      spacing={2}
                      marginBottom={16}
                    >
                      <Grid item sm xs={12}>
                        <NumberFormatForm
                          name="percent_rc"
                          label="RC de Entrada (%)"
                          variant="outlined"
                          size="small"
                          disabled
                          required
                          fullWidth
                          allowNegative={false}
                          decimalScale={2}
                          isAllowed={val => {
                            const { formattedValue, floatValue } = val;
                            return (
                              formattedValue === '' ||
                              formattedValue === '-' ||
                              (floatValue <= 100 && floatValue >= -100)
                            );
                          }}
                          value={values.percent_rc}
                        />
                      </Grid>
                      <Grid item sm xs={12}>
                        <NumberFormatForm
                          allowedDecimalSeparators={[',']}
                          name="average_price"
                          fixedDecimalScale
                          label="Preço Médio por Animal"
                          variant="outlined"
                          size="small"
                          required
                          fullWidth
                          decimalScale={2}
                          disabled
                          value={values.average_price}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">R$</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item sm xs={12}>
                        <NumberFormatForm
                          name="arroba_price"
                          label="Preço por Arroba"
                          variant="outlined"
                          size="small"
                          fullWidth
                          disabled
                          allowNegative={false}
                          decimalScale={2}
                          value={values.arroba_price}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">R$</InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    </StyledGrid>
                    <Divider light />

                    <ContentSubmitButtons>
                      <ButtonSubmit title="Cadastrar" loading={isSubmitting} />
                      <StyledSubmitButton
                        variant="outlined"
                        color="primary"
                        onClick={handleBack}
                      >
                        Cancelar
                      </StyledSubmitButton>
                    </ContentSubmitButtons>
                  </Content>
                </Form>
              )}
            </Formik>
          </StyledPaper>
        </Container>
      )}
    </center>
  );
}

import React, { useState } from 'react';

// MUI
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';

// Icons
import AddIcon from '@material-ui/icons/Add';

// Query
import { useApiV2Employees } from '~/hooks/apiV2/register/employee/useApiV2Employees';

// Styled Components
import {
  Container,
  StyledGrid,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  ContentButton,
  StyledAddButton,
} from './styles';

// Local
import Loader from '~/components/Loader';
import history from '~/services/history';
import Can from '~/components/Can';
import { CardTableEmployee } from './Components/CardTableEmployee';

export default function Employee() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('name');
  const [direction, setDirection] = useState('asc');

  const { data, isLoading } = useApiV2Employees(`${field}%7C${direction}`, page + 1, rowsPerPage)

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleAdd() {
    history.push('/dashboard/employee/add');
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={1}>
          <Grid item sm md lg xl />

          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['employee_new']}
          >
            <Grid item xs={12} sm={5} md={5} lg={3} xl>
              <ContentButton>
                <StyledAddButton
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAdd}
                >
                  Novo
                </StyledAddButton>
              </ContentButton>
            </Grid>
          </Can>
        </StyledGrid>
        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'name'}
                        direction={field === 'name' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('name')}
                      >
                        Nome
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'cpf'}
                        direction={field === 'cpf' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('cpf')}
                      >
                        CPF
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'function'}
                        direction={field === 'function' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('function')}
                      >
                        Função
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'email'}
                        direction={field === 'email' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('email')}
                      >
                        Email
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={field === 'telephone'}
                        direction={field === 'telephone' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('telephone')}
                      >
                        Telefone
                      </TableSortLabel>
                    </TableCell>
                    <Can checkRole={['administrator', 'owner', 'manager']}>
                      <TableCell align="right" />
                    </Can>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {data && data.loadedEmployees
                    .map(employee => (
                      <CardTableEmployee
                        key={employee.secure_id}
                        employee={employee}
                      />
                    ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

import {
  Box,
  CircularProgress,
  Grid, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableRow, Typography
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import ButtonSubmit from '~/components/ButtonSubmit';
import Loader from '~/components/Loader';
import { useV2GetTreatmentsForCreatePerDaysManufactoringDiet } from '~/hooks/apiV2/foodManagement/manufactoringDiet/useV2GetTreatmentsForCreatePerDaysManufactoringDiet';
import { useV2GlobalEmployees } from '~/hooks/apiV2/global/employees/useV2GlobalEmployees';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';
import { FormatDateForYearMonthDay, formatNumber } from '~/utils/format';
import Filter from '../Components/FilterPerDay';
import TextFieldEdit from '../Components/TextFieldEdit';
import {
  Container, ContentSubmitButtons, StyledButtonBack, StyledFormControl, StyledGrid,
  StyledPaperTable, StyledSubmitButton, StyledTableCell, StyledTableContainer,
  StyledTableHead, StyledTextFieldForecast
} from './styles';

export default function Register() {
  const [currentDate, setCurrentDate] = useState(new Date());

  const { data: employees } = useV2GlobalEmployees();
  const { data, isLoading, error } = useV2GetTreatmentsForCreatePerDaysManufactoringDiet(!!currentDate ? FormatDateForYearMonthDay(currentDate) : null)

  const [newManufactoring, setNewManufactoring] = useState([]);
  const [percentMsDiet, setPercentMsDiet] = useState(0);
  const [realizedAmount, setRealizedAmount] = useState(0);
  const [totalPriceTon, setTotalPriceTon] = useState(0);
  const [employee, setEmployee] = useState('');
  const [currentEmployee, setCurrentEmployee] = useState('');

  function showDeviation(food) {
    const currentManufactoring = newManufactoring.find(
      manufactoring => manufactoring.secure_id === food.secure_id
    );

    if (currentManufactoring) {
      return formatNumber(currentManufactoring.deviation, 2, 2);
    }

    return '';
  }

  function handleBack() {
    history.goBack();
  }

  useEffect(() => {
    if (newManufactoring.length > 0) {
      const listManufactoringDiet = newManufactoring.filter(
        food => food.performed
      );

      const newListManufactoringDiets = listManufactoringDiet.map(food => ({
        ...food,
        performed_ms: Number(food.performed) * (food.percent_ms / 100),
      }));

      const {
        qtdPerformed,
        sumPercentMSDiet,
        price,
      } = newListManufactoringDiets.reduce(
        (accumulator, food) => {
          accumulator.qtdPerformed += Number(food.performed);
          accumulator.sumPercentMSDiet += Number(food.performed_ms);
          if (food.lastMovement) {
            accumulator.price +=
              food.lastMovement.price_ton <= 0
                ? 0
                : (Number(food.performed) / 1000) *
                Number(food.lastMovement.price_ton);
          } else {
            const priceTon = ((food.total_price === 0) && (food.total_amount_ton === 0)) ? 0 : food.total_price / food.total_amount_ton
            accumulator.price +=
              priceTon <= 0
                ? 0
                : (Number(food.performed) / 1000) *
                Number(priceTon);
          }

          return accumulator;
        },
        {
          qtdPerformed: 0,
          sumPercentMSDiet: 0,
          price: 0,
        }
      );
      const newPercentMsDiet =
        qtdPerformed === 0 ? 0 : (sumPercentMSDiet / qtdPerformed) * 100;
      const priceTon = qtdPerformed === 0 ? 0 : price / (qtdPerformed / 1000);
      setPercentMsDiet(newPercentMsDiet);
      setRealizedAmount(qtdPerformed);
      setTotalPriceTon(priceTon);
    } else {
      setRealizedAmount(0);
      setPercentMsDiet(0);
      setTotalPriceTon(0);
    }
  }, [newManufactoring]);

  function handleChangeEmployee(event) {
    if (event.target) {
      setCurrentEmployee(event.target.value);
      setEmployee(event.target.value);
    }
  }

  const addManufactoringDiet = useMutation(async (formDataCreate) => {
    return await apiV2.post(`v1/feedlot/food-management/manufacturing-diet-per-days`, formDataCreate)
  },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries('V2GlobalDiets');
        queryClient.invalidateQueries('V2GlobalEmployees');
        queryClient.invalidateQueries('manufactoringDiet');
        queryClient.invalidateQueries('getTreatmentsForCreatePerDaysManufactoringDiet');
        queryClient.invalidateQueries('getTreatmentsForCreateManufactoringDiet');
        toast.success(response.data?.message || 'A fabricação da dieta foi cadastrado com sucesso.')
        handleBack()
      },
      onError: (error) => {
        if (!!error.response.data?.type) {
          if (error.response.data?.type === 'warning') {
            toast.warning(error.response.data.message)
          } else {
            toast.error(error.response.data.message)
          }
        } else {
          toast.error('Falha ao tentar cadastrar a fabricação da dieta.')
        }
      }
    }
  );

  async function handleSubmit() {
    try {
      if (!employee) {
        toast.warning('Informe o funcionário responsável!');
        return;
      }

      if (newManufactoring.length !== data.foods.length) {
        toast.warning('Todos os campos devem ser preenchidos antes de serem salvos.');
        return;
      }

      const formattedDate = format(new Date(currentDate), 'yyyy-MM-dd');
      const newFoods = newManufactoring.map(food => ({
        food_id: food.secure_id,
        forecast: food.forecast,
        performed: food.performed,
        deviation: food.deviation,
        price_ton: food.lastMovement ? food.lastMovement.price_ton : 0,
      }));

      const qtdPerformed = newFoods.reduce(
        (total, food) => total + Number(food.performed),
        0
      );

      const deviation = (qtdPerformed / (!!data ? data.forecast : 0) - 1) * 100;

      const formDataCreate = {
        total_forecast: Math.round(Number(!!data ? data.forecast : 0)),
        total_performed: Math.round(Number(!!data ? data.performed : 0)),
        qtd_forecast: Math.round(Number(!!data ? data.performed : 0)),
        qtd_performed: Math.round(qtdPerformed),
        price_ton: totalPriceTon <= 0 ? 0 : Number(totalPriceTon).toFixed(2),
        deviation,
        percent_ms_diet: percentMsDiet,
        date: formattedDate,
        foods: newFoods,
        employee_id: employee,
      };

      await addManufactoringDiet.mutateAsync(formDataCreate)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="center" spacing={2}>
          <Grid item sm>
            <Filter
              totalForecast={!!data ? data.forecast : 0}
              totalPerformed={!!data ? data.performed : 0}
              isRegister
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
            />
          </Grid>
          <Box
            display="flex"
          >
            {isLoading && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height={36}
              >
                <CircularProgress
                  size={24}
                />
              </Box>
            )}
            <Box>
              <StyledButtonBack
                variant="contained"
                color="primary"
                onClick={handleBack}
              >
                Voltar
              </StyledButtonBack>
            </Box>
          </Box>
        </StyledGrid>

        {(!!error && error.response.data.type === 'alert') && (
          <Typography style={{ color: '#ff0000', textTransform: 'uppercase' }}>
            *** {error.response.data.message} ***
          </Typography>
        )}
        {!!data && (
          <StyledPaperTable>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell>Alimento</TableCell>
                    <TableCell align="right">Qtd. Prevista (Kg)</TableCell>
                    <TableCell align="right">Qtd. Realizada (Kg)</TableCell>
                    <TableCell align="right">Desvio (%)</TableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {!!data && data.foods.map(food => (
                    <TableRow key={food.secure_id}>
                      <TableCell component="th" scope="row">
                        {food.name}
                      </TableCell>
                      <TableCell align="right">
                        <StyledTextFieldForecast
                          disabled
                          type="number"
                          value={food.forecast}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <TextFieldEdit
                          item={food}
                          newManufactoring={newManufactoring}
                          setNewManufactoring={setNewManufactoring}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <StyledTextFieldForecast
                          disabled
                          value={showDeviation(food)}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <StyledTableCell align="left">
                      Quantidade
                    </StyledTableCell>
                    <TableCell align="right">
                      {formatNumber(data.performed, 0, 0)}
                    </TableCell>
                    <TableCell align="right">
                      {formatNumber(realizedAmount, 0, 0)}
                    </TableCell>
                    <TableCell align="right">
                      {formatNumber(((realizedAmount * 100) / data.performed) - 100, 2, 2)}  %
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="left">
                      MS na Dieta
                    </StyledTableCell>
                    <TableCell align="right">
                      {' '}
                      {`${formatNumber(data.percent_forecast_ms_diet, 2, 2)} %`}
                    </TableCell>
                    <TableCell align="right">
                      {' '}
                      {`${formatNumber(percentMsDiet, 2, 2)} %`}
                    </TableCell>
                    <TableCell align="right">
                      {' '}
                      {`${formatNumber(percentMsDiet - data.percent_forecast_ms_diet, 2, 2)} %`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <StyledTableCell align="left">Custo/Ton</StyledTableCell>
                    <TableCell align="right">{`R$ ${formatNumber(
                      data.priceTon,
                      2,
                      2
                    )}`}</TableCell>
                    <TableCell align="right">{`R$ ${formatNumber(
                      totalPriceTon,
                      2,
                      2
                    )}`}</TableCell>
                    <TableCell align="right">{`R$ ${formatNumber(
                      totalPriceTon - data.priceTon,
                      2,
                      2
                    )}`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </StyledTableContainer>
            <StyledGrid container justify="flex-end" spacing={2}>
              <Grid
                item
                sm={3}
                xs={12}
                style={{ margin: '6px 16px 0px 0px' }}
              >
                <StyledFormControl size="small" fullWidth>
                  <InputLabel id="input-line">Funcionário</InputLabel>
                  <Select
                    id="currentLine"
                    onChange={handleChangeEmployee}
                    value={currentEmployee}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                    }}
                  >
                    {!!employees && employees.map(employee => (
                      <MenuItem key={employee.value} value={employee.value}>
                        {employee.label}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
            </StyledGrid>
            <ContentSubmitButtons>
              <StyledSubmitButton
                variant="outlined"
                color="primary"
                onClick={handleBack}
              >
                Cancelar
              </StyledSubmitButton>
              <ButtonSubmit
                title="Cadastrar"
                disabled={!data || addManufactoringDiet.isLoading}
                loading={addManufactoringDiet.isLoading}
                onClick={handleSubmit}
              />
            </ContentSubmitButtons>
          </StyledPaperTable>
        )}
      </Container>
    </center>
  );
}

import React from 'react';
import Swal from 'sweetalert2';

import {
  TableCell,
  TableRow
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

// Query hooks
import { useApiV2RemoveTransferLot } from '~/hooks/apiV2/animalHandling/TransferLot/useApiV2RemoveTransferLot';

import Can from '~/components/Can';
import colors from '~/styles/colors';
import ActionLoader from '~/components/ActionLoader';

import {
  StyledTableCell,
} from './styles';
import { format } from 'date-fns';

export function CardTableTransferLot({ transfer }) {
  // Query
  const removeAnimalTransferLot = useApiV2RemoveTransferLot()

  function handleRemove({ secure_id }) {
    async function performRemoveLotTransfer() {
      await removeAnimalTransferLot.mutateAsync(secure_id)
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar essa transferência?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar transferência!',
    }).then(async result => {
      if (result.value) {
        performRemoveLotTransfer();
      }
    });
  }

  return (
    <TableRow>
      <TableCell align="left">
        {transfer.originLot && transfer.originLot.code}
      </TableCell>
      <TableCell component="th" scope="row">
        {transfer.destinationLot &&
          transfer.destinationLot.code}
      </TableCell>
      <TableCell align="center">
        {transfer?.amount_animals}
      </TableCell>
      <TableCell align="center">
        {format(new Date(transfer.date), 'dd-MM-yyy')}
      </TableCell>
      <StyledTableCell align="right">
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['animalTransferLot_delete']}
        >
          <ButtonTable
            icon={
              removeAnimalTransferLot.isLoading ? (
                <ActionLoader text="Deletando" />
              ) :
                (
                  <DeleteIcon color="error" />
                )
            }
            background={colors.error}
            onClick={() => handleRemove(transfer)}
          />
        </Can>
      </StyledTableCell>
    </TableRow>
  )
}

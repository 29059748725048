import React, { useEffect, useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Grid, TextField } from '@material-ui/core';
import * as Yup from 'yup';

import {
  Container,
  StyledPaper,
  StyledFodGrid,
  Title,
  StyledGrid,
  ContentSubmitButtons,
  StyledSubmitButton,
  Error,
  StyledTextField,
  CardTitle,
  CardBody,
  Card,
  AnimalInfos,
} from './styles';

import history from '~/services/history';
import NumberFormatForm from '~/components/NumberFormatForm';
import ButtonSubmit from '~/components/ButtonSubmit';
import InputDatePicker from '~/components/InputDatePicker';
import { useDebouncedPromise } from '~/utils/useDebouncedPromise';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';
import { searchAnimal } from '~/hooks/apiV2/global/searchAnimal/useApiV2SearchAnimal';
import { format } from 'date-fns';
import { useApiV2AddIndividualIntermediateWeight } from '~/hooks/apiV2/animalHandling/IntermediateWeightIndividual/useApiV2AddIndividualIntermediateWeight';

export default function Add() {
  const [animal, setAnimal] = useState(undefined);
  const [date, setDate] = useState(null);
  const [newWeight, setNewWeight] = useState(0);
  const [obs, setObs] = useState(null);
  const [animalValue, setAnimalValue] = useState('');

  const schema = Yup.object().shape({
    obs: Yup.string().required('Observação é obrigatória').min(10, 'Deve possuir pelo menos 3 palavras'),
  });
  const { data: settings } = useApiV2GlobalSetting();
  const addIntermediateWeight = useApiV2AddIndividualIntermediateWeight();

  const translateInputType = type => {
    let inputType;

    switch (type) {
      case 'purchase':
        inputType = 'Compra';
        break;

      case 'transfer':
        inputType = 'Transferência';
        break;

      case 'boitel':
        inputType = 'Boitel';
        break;

      default:
        inputType = '-';
        break;
    }

    return inputType;
  };
  const searchAnimalRequest = async (animalId) => {
    if (settings) {
      const animalSameId = !!animal && animal[settings.settings.default_identification] === animalId

      if (animalSameId) return;

      const resp = await searchAnimal(
        settings.settings.default_identification,
        animalId
      );

      if (resp) {
        setAnimal(resp);
      } else {
        setAnimal(undefined);
      }
    }
  }

  useEffect(() => {
    if(animal) {
      setDate(animal.input_date)
    }
  }, [animal])

  const debounceSearchAnimal = useDebouncedPromise(searchAnimalRequest, 1000);

  async function handleSubmit() {
    const newData = {
      oldAverageWeight: animal.current_weight,
      newAverageWeight: newWeight,
      date,
      obs,
      animalSecureId: animal.secure_id,
      type: 'inputWeight'
    };

    await addIntermediateWeight.mutateAsync(newData);
  }

  function handleBack() {
    history.goBack();
  }

  function helperFormatNumber(num) {
    return num.replaceAll('.', '').replaceAll(',', '.');
  }

  return (
    <center>
      <Container>
        <StyledPaper>
          <Formik
            validationSchema={schema}
            initialValues={{
              date: new Date(),
              obs: '',
              weight: '',
            }}
            onSubmit={handleSubmit}
          >
            {({ errors, values, touched, isSubmitting, setFieldValue }) => (
              <Form>
                <StyledFodGrid container justify="space-between" spacing={2}>
                  <Grid item sm xs>
                    <StyledTextField
                      id="animal_id"
                      name="animal_id"
                      fullWidth
                      variant="outlined"
                      label="Identificador do animal"
                      autoComplete="off"
                      size="small"
                      value={animalValue}
                      onChange={e => {
                        debounceSearchAnimal(e.target.value);
                        setAnimalValue(e.target.value)
                      }}
                    />
                  </Grid>
                  <Grid item sm md lg xl>
                    <InputDatePicker
                      name="date"
                      label="Data"
                      inputVariant="outlined"
                      fullWidth
                      required
                      selectedDate={date}
                      handleChangeDate={dat => {
                        setDate(dat);
                      }}
                      maxDate={date}
                      minDate={date}
                    />
                    <ErrorMessage name="error" component={Error} />
                  </Grid>
                </StyledFodGrid>

                {animal && animal.secure_id && (
                  <>
                    <Title>Dados atuais</Title>
                    <AnimalInfos container spacing={2}>
                      <Card>
                        <CardTitle>Data de Entrada</CardTitle>
                        <CardBody>
                          {animal && animal.input_date
                            ? format(new Date(animal.input_date), 'dd/MM/yyyy')
                            : '-'}
                        </CardBody>
                      </Card>
                      <Card>
                        <CardTitle>Tipo de Entrada</CardTitle>
                        <CardBody>
                          {animal ? translateInputType(animal.input_type) : '-'}
                        </CardBody>
                      </Card>
                      <Card>
                        <CardTitle>Raça</CardTitle>
                        <CardBody>{animal?.breed?.name || '-'}</CardBody>
                      </Card>
                      <Card>
                        <CardTitle>Categoria</CardTitle>
                        <CardBody>{animal?.type || '-'}</CardBody>
                      </Card>
                      <Card>
                        <CardTitle>Sexo</CardTitle>
                        <CardBody>{animal?.sex || '-'}</CardBody>
                      </Card>
                      <Card>
                        <CardTitle>Idade</CardTitle>
                        <CardBody>{animal?.age || '-'}</CardBody>
                      </Card>
                      <Card>
                        <CardTitle>Tamanho Corporal</CardTitle>
                        <CardBody>{animal?.body_size || '-'}</CardBody>
                      </Card>
                      <Card>
                        <CardTitle>Peso do Animal</CardTitle>
                        <CardBody>
                          {animal.current_weight ? `${animal.current_weight}kg` : '-'}
                        </CardBody>
                      </Card>
                      <Card>
                        <CardTitle>Lote</CardTitle>
                        <CardBody>{animal.lot ? animal.lot.code : '-'}</CardBody>
                      </Card>
                      <Card>
                        <CardTitle>Piquete</CardTitle>
                        <CardBody>{animal.lot ? animal.lot.paddock?.name : '-'}</CardBody>
                      </Card>
                    </AnimalInfos>
                  </>
                )}
                <StyledGrid container spacing={2}>
                  <Grid item sm xs={12}>
                    <NumberFormatForm
                      label="Novo peso (kg)"
                      variant="outlined"
                      size="small"
                      fixedDecimalScale
                      fullWidth
                      required
                      name="average_weight"
                      onChange={event =>
                        setNewWeight(helperFormatNumber(event.target.value))
                      }
                    />
                  </Grid>
                </StyledGrid>

                <Grid container>
                  <Grid item sm>
                    <TextField
                      name="obs"
                      variant="outlined"
                      label="Observação *"
                      autoComplete="nope"
                      fullWidth
                      multiline
                      required
                      value={values.obs}
                      error={!!errors.obs && touched.obs}
                      onChange={target => {
                        setFieldValue('obs', target.target.value)
                        setObs(target.target.value)
                      }}
                    />
                    <ErrorMessage name="obs" component={Error} />
                  </Grid>
                </Grid>

                <ContentSubmitButtons>
                  <ButtonSubmit title="Atualizar" loading={isSubmitting} />
                  <StyledSubmitButton
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={handleBack}
                  >
                    Cancelar
                  </StyledSubmitButton>
                </ContentSubmitButtons>
              </Form>
            )}
          </Formik>
        </StyledPaper>
      </Container>
    </center>
  );
}

import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { Grid, TextField, Divider, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

// Query
import { useApiV2AddInputWeightCorrection } from '~/hooks/apiV2/animalHandling/intermediateWeight/useApiV2AddInputWeightCorrection';
import { useApiV2ShowDataWeightCorrection } from '~/hooks/apiV2/animalHandling/intermediateWeight/useApiV2ShowDataWeightCorrection';

import {
  Container,
  StyledPaper,
  StyledFodGrid,
  Title,
  StyledGrid,
  ContentSubmitButtons,
  StyledSubmitButton,
  Error,
} from './styles';

import history from '~/services/history';
import NumberFormatForm from '~/components/NumberFormatForm';
import ButtonSubmit from '~/components/ButtonSubmit';
import { parseISO } from 'date-fns';
import { formatDateOnly } from '~/utils/format';

export default function Add() {
  const [listLots, setListLots] = useState([]);
  const [listDates, setListDates] = useState([]);
  const [lot, setLot] = useState('');
  const [date, setDate] = useState(new Date());
  const [newAmount, setNewAmount] = useState(0);
  const [atualWeight, setAtualWeight] = useState(0);
  const [newWeight, setNewWeight] = useState(0);
  const [obs, setObs] = useState(null);
  const [lots, setLots] = useState([]);

  // Query
  const addInputWeightCorrection = useApiV2AddInputWeightCorrection();
  const { data: lotsData } = useApiV2ShowDataWeightCorrection();

  useEffect(() => {
    if (lotsData) {
      const newList = lotsData.map(currentLot => ({
        secure_id: currentLot.secure_id,
        value: currentLot.name,
      }));

      setListLots(newList);

      setLots(lotsData);
    }
  }, [lotsData]);

  function handleSelectLot(currentLot) {
    if (currentLot) {
      const findLot = lots.find(l => l.secure_id === currentLot.secure_id);
      setLot(currentLot.secure_id);
      setListDates(findLot.inputDates);
    } else {
      setLot('');
      setListDates([]);
    }
  }

  async function handleSubmit() {
    const newData = {
      oldInputWeight: atualWeight,
      newInputWeight: newWeight,
      lotSecureId: lot,
      date: parseISO(date),
      obs,
    };

    await addInputWeightCorrection.mutateAsync(newData);
  }

  function handleBack() {
    history.goBack();
  }

  function helperFormatNumber(num) {
    return num.replaceAll('.', '').replaceAll(',', '.');
  }

  useEffect(() => {
    if (lot) {
      const lotSelected = lots.find(data => data.secure_id === lot);

      if (lotSelected) {
        const weight = lotSelected.average_input_weight;
        const amount = lotSelected.amount_animals;

        setAtualWeight(weight);
        setNewAmount(amount);
      }
    }
  }, [lot, lots]);

  return (
    <center>
      <Container>
        <StyledPaper>
          <Formik
            initialValues={{
              date: '',
              amount_animals: '',
              obs: '',
              weight: '',
            }}
            // enableReinitialize={treatmentLot ? treatmentLot.secure_id : true}
            onSubmit={handleSubmit}
          >
            {({ errors, values, touched, isSubmitting }) => (
              <Form>
                <StyledFodGrid container justify="space-between" spacing={2}>
                  <Grid item sm xs>
                    <Autocomplete
                      name="lot"
                      size="small"
                      style={{ height: 38, width: '100%' }}
                      options={listLots}
                      noOptionsText="Sem opções"
                      getOptionLabel={option => option.value}
                      onChange={(e, value) => {
                        handleSelectLot(value);
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          label="Lote"
                          required
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item sm md lg xl>
                    <TextField
                      name="date"
                      label="Data"
                      fullWidth
                      select
                      required
                      size="small"
                      InputLabelProps={{
                        shrink: values.age,
                      }}
                      value={date}
                      onChange={event => setDate(event.target.value)}
                    >
                      {listDates.map(currentDate => (
                        <MenuItem value={currentDate}>
                          {date ? formatDateOnly(parseISO(currentDate)) : ''}
                        </MenuItem>
                      ))}
                    </TextField>
                    <ErrorMessage name="date" component={Error} />
                  </Grid>
                </StyledFodGrid>

                <Divider ligth />

                <Title>Dados atuais</Title>
                <StyledGrid container spacing={2}>
                  <Grid item sm xs={12}>
                    <NumberFormatForm
                      label="Quantidade de animais"
                      variant="outlined"
                      name="amount_animals"
                      size="small"
                      disabled
                      fullWidth
                      fixedDecimalScale
                      value={
                        values.amount_animals
                          ? values.amount_animals
                          : newAmount
                      }
                    />
                  </Grid>
                  <Grid item sm xs={12}>
                    <NumberFormatForm
                      name="weight"
                      label="Média peso de entrada"
                      variant="outlined"
                      size="small"
                      fixedDecimalScale
                      decimalScale={2}
                      disabled
                      fullWidth
                      value={values.weight ? values.weight : atualWeight}
                    />
                  </Grid>
                  <Grid item sm xs={12}>
                    <NumberFormatForm
                      label="Novo peso de entrada(kg)"
                      variant="outlined"
                      size="small"
                      fixedDecimalScale
                      fullWidth
                      required
                      name="average_weight"
                      onChange={event =>
                        setNewWeight(helperFormatNumber(event.target.value))
                      }
                    />
                  </Grid>
                </StyledGrid>

                <Grid container>
                  <Grid item sm>
                    <TextField
                      name="obs"
                      variant="outlined"
                      label="Observação *"
                      autoComplete="nope"
                      fullWidth
                      multiline
                      error={!!errors.obs && touched.obs}
                      onChange={target => setObs(target.target.value)}
                    />
                    <ErrorMessage name="obs" component={Error} />
                  </Grid>
                </Grid>

                <ContentSubmitButtons>
                  <ButtonSubmit title="Atualizar" loading={isSubmitting} />
                  <StyledSubmitButton
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={handleBack}
                  >
                    Cancelar
                  </StyledSubmitButton>
                </ContentSubmitButtons>
              </Form>
            )}
          </Formik>
        </StyledPaper>
      </Container>
    </center>
  );
}

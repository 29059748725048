import React, { useState } from 'react';

import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';

import {
  Container,
  StyledGrid,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledButton
} from './styles';

import history from '~/services/history';
import Can from '~/components/Can';
import ButtonNew from '~/components/ButtonNew';
import Loader from '~/components/Loader';

// Query
import {
  useApiV2IndividualAnimalTransfer
} from '~/hooks/apiV2/animalHandling/IndividualAnimalTransfer/useApiV2IndividualAnimalTransfer';

import Filter from './Components/Filter';
import { CardTableAnimalTransfer } from './Components/CardTableAnimalInputUnity';

export default function TransferLot() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [field, setField] = useState('date');
  const [direction, setDirection] = useState('asc');
  const [date, setDate] = useState(null);
  const [originLot, setOriginLot] = useState(null);
  const [destinationLot, setDestinationLot] = useState(null);

  // Query
  const { data, isLoading } = useApiV2IndividualAnimalTransfer(`${field}%7C${direction}`, page + 1, rowsPerPage, date, destinationLot, originLot);

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleAdd() {
    history.push('/dashboard/individualAnimalTransferLot/add');
  }
  return (
    <center>
      <Container>
        <StyledPaper>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <StyledGrid container justify="space-between" spacing={2}>
                <Grid item xs sm md lg xl>
                  <Filter
                    date={date}
                    setDate={setDate}
                    setOriginLot={setOriginLot}
                    setDestinationLot={setDestinationLot}
                  />
                </Grid>
              </StyledGrid>
              <StyledTableContainer>
                <Table stickyHeader>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell>
                        Lote de Origem
                      </TableCell>
                      <TableCell align="left">
                        Lote de Destino
                      </TableCell>
                      <TableCell align="center">
                        <TableSortLabel
                          active={field === 'amount_animals'}
                          direction={field === 'amount_animals' ? direction : 'asc'}
                          onClick={() => handleChangeOrder('amount_animals')}
                        >
                          Quantidade de animais
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        <TableSortLabel
                          active={field === 'date'}
                          direction={field === 'date' ? direction : 'asc'}
                          onClick={() => handleChangeOrder('date')}
                        >
                          Data da Transferência
                        </TableSortLabel>
                      </TableCell>
                      <Can checkRole={['administrator', 'owner', 'manager']}>
                        <TableCell align="right" />
                      </Can>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {data && data.animalsTransfer
                      .map(transfer => (
                        <CardTableAnimalTransfer
                          key={transfer.secure_id}
                          transfer={transfer}
                        />
                      ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                labelRowsPerPage="Linhas por pág."
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={data ? data.perPage : 0}
                page={data ? data.page - 1 : 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </>
          )}
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['animalTransferLot_new']}
          >
            <StyledButton container justify="center" spacing={2}>
              <Grid item>
                <ButtonNew title="Nova Transferência " onClick={handleAdd} />
              </Grid>
            </StyledButton>
          </Can>
        </StyledPaper>
      </Container>
    </center>
  );
}

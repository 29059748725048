import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Query
import { useApiV2GlobalFood } from '~/hooks/apiV2/global/food/useApiV2GlobalFood';

import { Container, StyledPaper, ContentDate } from './styles';

import InputDatePicker from '~/components/InputDatePicker';

export default function FilterMsBeats({ setFood, date, handleChangeDate }) {
  const [listFoods, setListFoods] = useState([]);

  // Query
  const { data: foodsData } = useApiV2GlobalFood();

  function handleSelectFood(food) {
    if (food) {
      setFood(food.secure_id);
    } else {
      setFood(null);
    }
  }

  useEffect(() => {
    if (foodsData && foodsData.length > 0) {
      const newList = foodsData.map(d => ({
        secure_id: d.secure_id,
        value: d.name,
        text: d.name,
      }));

      setListFoods(newList);
    }
  }, [foodsData]);

  return (
    <Container>
      <StyledPaper>
        <Grid container spacing={2} justify="space-around">
          <Grid item sm xs>
            <Autocomplete
              id="food-input"
              name="food"
              size="small"
              noOptionsText="Sem opções"
              options={listFoods}
              getOptionLabel={option => option.value}
              onChange={(e, value) => {
                handleSelectFood(value);
              }}
              renderInput={params => (
                <TextField {...params} label="Alimento" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs sm={5}>
            <ContentDate>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <InputDatePicker
                  selectedDate={date}
                  fullWidth
                  handleChangeDate={(d, value) => handleChangeDate(d, value)}
                />
              </MuiPickersUtilsProvider>
            </ContentDate>
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

FilterMsBeats.defaultProps = {
  date: null,
};

FilterMsBeats.propTypes = {
  setFood: PropTypes.func.isRequired,
  date: PropTypes.string,
  handleChangeDate: PropTypes.func.isRequired,
};

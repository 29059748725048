import { useQuery } from "react-query";
import apiV2 from '~/services/apiV2';

async function getV2GetLastOperationalCost() {
  const { data } = await apiV2.get(`v1/feedlot/admin/last-operational-cost`)

  return data
}

export function useV2GetLastOperationalCost() {
  return useQuery(
    ['V2GetLastOperationalCost'],
    () => getV2GetLastOperationalCost())
}

import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { Grid } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import {
  Container,
  StyledPaper,
  ContentItem,
  StyledTextField,
  StyledGridItem,
  StyledDivider,
  Error,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';


import colors from '~/styles/colors';
import history from '~/services/history';
import Loader from '~/components/Loader';
import AvatarInput from './AvatarInput';
import { useAuthContext } from '~/contexts/AuthContext';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';

const schema = Yup.object().shape({
  name: Yup.string().required('Informe um nome'),
  email: Yup.string().email('E-mail inválido').required('Informe um e-mail'),
  oldPassword: Yup.string().when('password', {
    is: value => !!value,
    then: Yup.string().required('Senha anterior obrigatória').min(6, 'Necessário pelo menos 6 caracteres'),
    otherwise: Yup.string()
  }),
  password: Yup.string().min(6, 'Necessário pelo menos 6 caracteres'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Confirmação diferente da senha'),
});

export default function Add() {
  const { user, handleLoadUser } = useAuthContext();

  async function handleChangeProfile(data, { setFieldValue }) {
    try {
      await apiV2.put('/v1/feedlot/user', data);
      handleLoadUser();

      setFieldValue('oldPassword', '');
      setFieldValue('password', '');
      setFieldValue('passwordConfirmation', '');

      // resetForm({
      //   name: data.name,
      //   email: data.email,
      //   oldPassword: '',
      //   password: '',
      //   passwordConfirmation: ''
      // });

      toast.success('Dados atualizados!');
    } catch (error) {
      const type = error.response.data?.type ? error.response.data.type : 'error';
      const message = error.response.data?.message ? error.response.data.message : 'Não foi possível atualizar cadastro!';
      toast[type](message);
    }
  }

  function handleBack() {
    history.push('/');
  }

  return (
    <center>
      <Container>
        {!user.name ? (
          <Loader />
        ) : (
          <>
            <Formik
              validationSchema={schema}
              initialValues={{
                name: user.name,
                email: user.email,
                oldPassword: '',
                password: '',
                passwordConfirmation: ''
              }}
              onSubmit={handleChangeProfile}
            >
              {({ handleChange, errors, touched, values, isSubmitting }) => (
                <Form>
                  <ContentItem>
                    <StyledPaper>
                      <Grid container justify="center">
                        <StyledGridItem item>
                          <AvatarInput name="avatar_id" />
                        </StyledGridItem>
                      </Grid>

                      <Grid container>
                        <StyledGridItem item sm xs>
                          <StyledTextField
                            name="name"
                            variant="outlined"
                            label="Nome"
                            autoComplete="nope"
                            fullWidth
                            autoFocus
                            size="small"
                            value={values.name}
                            error={!!errors.name && touched.name}
                            onChange={handleChange}
                          />
                          <ErrorMessage name="name" component={Error} />
                        </StyledGridItem>
                      </Grid>

                      <Grid container>
                        <StyledGridItem item sm xs>
                          <StyledTextField
                            name="email"
                            variant="outlined"
                            label="Email"
                            autoComplete="nope"
                            fullWidth
                            size="small"
                            value={values.email}
                            error={!!errors.email && touched.email}
                            onChange={handleChange}
                          />
                          <ErrorMessage name="email" component={Error} />
                        </StyledGridItem>
                      </Grid>

                      <StyledDivider />

                      <Grid container>
                        <StyledGridItem item sm xs>
                          <StyledTextField
                            name="oldPassword"
                            variant="outlined"
                            label="Senha atual"
                            autoComplete="nope"
                            type="password"
                            fullWidth
                            size="small"
                            value={values.oldPassword}
                            error={!!errors.oldPassword && touched.oldPassword}
                            onChange={handleChange}
                          />
                          <ErrorMessage name="oldPassword" component={Error} />
                        </StyledGridItem>
                      </Grid>

                      <Grid container>
                        <StyledGridItem item sm xs>
                          <StyledTextField
                            name="password"
                            variant="outlined"
                            label="Nova senha"
                            autoComplete="nope"
                            type="password"
                            fullWidth
                            size="small"
                            value={values.password}
                            error={!!errors.password && touched.password}
                            onChange={handleChange}
                          />
                          <ErrorMessage name="password" component={Error} />
                        </StyledGridItem>
                      </Grid>

                      <Grid container>
                        <StyledGridItem item sm xs>
                          <StyledTextField
                            name="passwordConfirmation"
                            variant="outlined"
                            label="Confirmação de senha"
                            autoComplete="nope"
                            type="password"
                            fullWidth
                            size="small"
                            value={values.passwordConfirmation}
                            error={
                              !!errors.passwordConfirmation &&
                              touched.passwordConfirmation
                            }
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="passwordConfirmation"
                            component={Error}
                          />
                        </StyledGridItem>
                      </Grid>

                      <ContentSubmitButtons>
                        <StyledSubmitButton
                          variant="outlined"
                          color="primary"
                          onClick={handleBack}
                        >
                          Cancelar
                        </StyledSubmitButton>
                        <StyledSubmitButton
                          variant="contained"
                          color={colors.white}
                          background={colors.success}
                          startIcon={<SaveIcon />}
                          type="submit"
                        >
                          {isSubmitting ? 'Alterando...' : 'Alterar'}
                        </StyledSubmitButton>
                      </ContentSubmitButtons>
                    </StyledPaper>
                  </ContentItem>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Container>
    </center>
  );
}

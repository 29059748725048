import styled, { css } from 'styled-components';
import { Paper, Grid, TableHead, TextField, TableCell } from '@material-ui/core';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  bottom: 0;
  right: 0;
  transform: translateX(-50%);
  z-index: 10000;

  width: 100%;
  max-width: 960px;
  max-height: 100vh;
  margin: 50px 40px;
  border-radius: 5px;
`;

export const StyledPaperLoader = styled(Paper)`
  position: relative;
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledPaper = styled(Paper)`
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const StyledGrid = styled(Grid)`
  margin: 16px 0px;
`;

export const StyledGridItem = styled(Grid)`
  margin: 8px;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const StyledTextField = styled(TextField)`
  input {
    text-align: right;
    ${props =>
      props.color &&
      css`
        color: props.color;
      `}
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -18px;
  right: -16px;
  border: none;
  background: none;

  svg {
    background: ${colors.darkWhite};
    border-radius: 50%;
    overflow: hidden;
    transition: background 2s;

    &:hover {
      background: ${darken(0.1, colors.darkWhite)};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  color: ${colors.primary};
  font-weight: bold;
  ${props =>
    props.notBorderBottom &&
    css`
      border-bottom: none;
    `}
`;

import { useQuery } from "react-query";
import apiV2 from '~/services/apiV2';

async function getV2GetOperationalCostShow(secure_id) {
  const { data } = await apiV2.get(`v1/feedlot/admin/operational-cost/${secure_id}`)

  return data
}

export function useV2GetOperationalCostShow(secure_id) {
  return useQuery(
    ['V5GetOperationalCostShow', secure_id],
    () => getV2GetOperationalCostShow(secure_id))
}

import styled, { css } from 'styled-components';
import { Paper, Grid, TextField, Divider, Button } from '@material-ui/core';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 960px;
  width: 80%;
  padding: 40px 0px;

  text-align: left;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 18px;
`;

export const ContentButton = styled.div`
  margin-left: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const StyledGridContainer = styled(Grid)`
  margin: 24px 0px;
`;

export const StyledTextField = styled(TextField)`
  label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    right: 22px;
    bottom: 0px;
  }

  .Mui-focused {
    overflow: visible;
    right: 0px;
  }
`;

export const StyledAddButton = styled(Button)`
  margin-bottom: 10px;
  color: ${colors.white};
  background: ${colors.success};
  transition: background 2s;

  &:hover {
    background: ${darken(0.06, colors.success)};
  }
`;

export const StyledPaperGrid = styled(Paper)`
  padding: 12px;
`;

export const StyledDivider = styled(Divider)`
  margin-bottom: 28px;
`;

export const StyledGrid = styled(Grid)`
  margin-bottom: ${props => props.marginBottom || 8}px;
`;

export const StyledPaddockEditGrid = styled(Grid)`
  margin-right: 5px;
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.marginTop || 50}px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${props => props.color};
  background: ${props => props.background};
  transition: background 2s;

  ${props =>
    props.background &&
    css`
      &:hover {
        background: ${darken(0.06, props.background)};
      }
    `}
`;

export const Error = styled.span`
  margin: 8px 14px 0;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

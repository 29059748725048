import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const Container = styled.div`
  max-width: 800px;
`;

export const StyledPaper = styled.div`
  width: 100%;
  padding: 16px;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
`;

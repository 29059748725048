import React from 'react';
import Swal from 'sweetalert2';

import {
  TableCell,
  TableRow
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

import { useApiV2RemoveEmployees } from '~/hooks/apiV2/register/employee/useApiV2RemoveEmployees';

import ActionLoader from '~/components/ActionLoader';
import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';
import { formatCpf, formatTel } from '~/utils/format';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';

export function CardTableEmployee({ employee }) {
  const removeEmployee = useApiV2RemoveEmployees();

  async function handleSelect(secureId) {
    history.push(`/dashboard/employee/edit/${secureId}`);
  }

  function handleRemoveEmployee(secure_id) {
    async function performRemoveEmployee() {
      await removeEmployee.mutateAsync(secure_id);
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja remover este funcionário?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, remover funcionário!',
    }).then(result => {
      if (result.value) {
        performRemoveEmployee();
      }
    });
  }

  return (
    <TableRow>
      <TableCell scope="row">{employee.name}</TableCell>
      <StyledTableCell>
        {employee.cpf && formatCpf(employee.cpf)}
      </StyledTableCell>
      <StyledTableCellEllipsis>
        {employee.function}
      </StyledTableCellEllipsis>
      <StyledTableCellEllipsis>
        {employee.email}
      </StyledTableCellEllipsis>
      <StyledTableCell>
        {employee.telephone && formatTel(employee.telephone)}
      </StyledTableCell>
      <StyledTableCell align="right">
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['employee_edit']}
        >
          <ButtonTable
            icon={<EditIcon color="primary" />}
            background={colors.primary}
            lighten={0.6}
            onClick={() => handleSelect(employee.secure_id)}
          />
        </Can>
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['employee_delete']}
        >
          <ButtonTable
            icon={
              removeEmployee.isLoading ? (
                <ActionLoader text="Deletando" />
              ) :
                (
                  <DeleteIcon color={
                    employee.has_manufactoring_diets > 0 ||
                      employee.has_treatments > 0 ||
                      employee.has_trough_readings > 0 ? "disabled" : "error"
                  } />
                )
            }
            background={
              employee.has_manufactoring_diets > 0 ||
                employee.has_treatments > 0 ||
                employee.has_trough_readings > 0
                ? colors.grey
                : colors.error
            }
            disabled={
              employee.has_manufactoring_diets > 0 ||
              employee.has_treatments > 0 ||
              employee.has_trough_readings > 0
            }
            onClick={() =>
              handleRemoveEmployee(employee.secure_id)
            }
          />
        </Can>
      </StyledTableCell>
    </TableRow>
  )
}

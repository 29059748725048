import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: calc(100vw - 2rem);
  max-height: calc(100vh - 2rem);
  width: 100%;
  border-radius: 5px;
  overflow: auto;
  position: relative;
`;

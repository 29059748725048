import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import {
  Content,
  StyledPaper,
  StyledGridItem,
} from './styles';

import { regexDate } from '~/utils/common';
import InputDatePicker from '~/components/InputDatePicker';

export default function Filter({
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
}) {
  function handleInitialDate(date, value) {
    if (value && !regexDate.test(value)) return;
    setInitialDate(date);
  }
  function handleFinalDate(date, value) {
    if (value && !regexDate.test(value)) return;
    setFinalDate(date);
  }

  return (
    <Content>
      <StyledPaper>
        <Grid container alignItems="center" justify="space-between">
          <StyledGridItem item sm xs={12}>
            <InputDatePicker
              label="A partir de"
              maxDate={new Date()}
              selectedDate={initialDate}
              handleChangeDate={(dateValue, value) => handleInitialDate(dateValue, value)}
            />
          </StyledGridItem>
          <StyledGridItem item sm xs={12}>
            <InputDatePicker
              label="Até a data"
              selectedDate={finalDate}
              handleChangeDate={(dateValue, value) => handleFinalDate(dateValue, value)}
            />
          </StyledGridItem>
        </Grid>
      </StyledPaper>
    </Content>
  );
}

Filter.defaultProps = {
  date: null,
};

Filter.propTypes = {
  initialDate: PropTypes.string,
  setInitialDate: PropTypes.string.isRequired,
  finalDate: PropTypes.string,
  setFinalDate: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';

import { StyledIconButton } from './styles';

import colors from '~/styles/colors';

export default function ButtonTable({
  icon,
  background,
  lighten,
  disabled = false,
  onClick,
  ...rest
}) {
  return (
    <StyledIconButton
      variant="contained"
      size="small"
      background={background}
      lighten={lighten}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    >
      {icon}
    </StyledIconButton>
  );
}

ButtonTable.defaultProps = {
  background: colors.primary,
  disabled: false,
  lighten: null,
};

ButtonTable.propTypes = {
  icon: PropTypes.element.isRequired,
  background: PropTypes.string,
  lighten: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import { queryClient } from '~/services/queryClient';

export function useApiV2RemoveModule() {
  return useMutation(
    async (secure_id) => {
      await apiV2.delete(`/v1/feedlot/register/module/${secure_id}`)
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries('modules');
        toast.success('O módulo foi apagado com sucesso!')
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao apagar módulo.'
        toast[type](message)
      }
    }
  );
}

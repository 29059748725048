import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';

export function useApiV2EditDeathOutput(secure_id) {
  return useMutation(
    async (data) => {
      const response = await apiV2.put(`/v1/feedlot/animal-handling/deathOutput/${secure_id}`, data);

      return response.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();

        history.push('/dashboard/animalDeathOutput');

        toast.success('Atualizado com sucesso!');
      },
      onError: () => {
        toast.error('Não foi possível atualizar saída de animais!');
      }
    }
  );
}

import React, { useState, useMemo } from 'react';
import { toast } from 'react-toastify'
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  Hidden,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterNoneIcon from '@material-ui/icons/FilterNone';

// Query
import { useApiV2SelectModule } from '~/hooks/apiV2/register/module/useApiV2SelectModule'
import { useApiV2AddModule } from '~/hooks/apiV2/register/module/useApiV2AddModule'
import { useApiV2EditModule } from '~/hooks/apiV2/register/module/useApiV2EditModule'

import {
  Container,
  ContentButton,
  StyledPaper,
  StyledGridContainer,
  StyledTextField,
  StyledAddButton,
  StyledPaperGrid,
  StyledDivider,
  StyledGrid,
  StyledPaddockEditGrid,
  ContentSubmitButtons,
  StyledSubmitButton,
  Error,
} from './styles';

import history from '~/services/history';
import colors from '~/styles/colors';

import Loader from '~/components/Loader';
import ButtonTable from '~/components/ButtonTable';
import ButtonSubmit from '~/components/ButtonSubmit';
import NumberFormatForm from '~/components/NumberFormatForm';

const schema = Yup.object().shape({
  name: Yup.string().required('Necessário informar o nome'),
  lines: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Necessário informar o nome da linha'),
      })
    )
    .min(1, 'Necessário cadastrar uma linha'),
});

export default function Edit({ match }) {
  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  const [nameLine, setNameLine] = useState('');
  const [enableEditLineIndex, setEnableEditLineIndex] = useState(null);
  const [namePaddock, setNamePaddock] = useState('');
  const [areaPaddock, setAreaPaddock] = useState('');
  const [troughSizePaddock, setTroughSizePaddock] = useState('');
  const [amountSupportPaddock, setAmountSupportPaddock] = useState('');
  const [linePaddock, setLinePaddock] = useState(null);
  const [enableEditPaddockIndex, setEnableEditPaddockIndex] = useState(null);
  const [errorPaddock, setErrorPaddock] = useState('');
  const [errorLine, setErrorLine] = useState('');
  const [errorLinePaddock, setErrorLinePaddock] = useState('');
  const [errorNameCurrentPaddock, setErrorNameCurrentPaddock] = useState('');
  const [errorNameCurrentLine, setErrorNameCurrentLine] = useState('');

  // Query
  const { data: moduleData, isLoading, error } = useApiV2SelectModule(secure_id)
  const addModule = useApiV2AddModule()
  const editModule = useApiV2EditModule()

  function disableLine({ paddocks }) {
    if (paddocks) {
      return paddocks.find(paddock => (paddock.has_treatments || paddock.has_lots || paddock.has_trough_readings));
    }

    return false;
  }

  function handleChangeNameLine(event) {
    if (event.target.value && errorLine) {
      setErrorLine('');
    }

    setNameLine(event.target.value);
  }

  function handleChangeNamePaddock(event) {
    if (event.target.value && errorPaddock) {
      setErrorPaddock('');
    }

    setNamePaddock(event.target.value);
  }

  function handleAddLine(lines, setFieldValue) {
    if (nameLine === null || nameLine === '') {
      setErrorLine('Informe um nome para a linha');
      return;
    }

    const existLine = lines.filter(
      itemLine =>
        String(itemLine.name).trim().toUpperCase() ===
        nameLine.trim().toUpperCase()
    );

    if (existLine.length >= 1) {
      setErrorLine('Nome já cadastrado');
      return;
    }

    setFieldValue('lines', [...lines, { name: nameLine, paddocks: [] }]);
    setNameLine('');
  }

  function handleChangeLine(event, index, line, lines, setFieldValue) {
    const newLines = lines;

    newLines.splice(index, 1, { ...line, name: event.target.value });

    setFieldValue('lines', newLines);
  }

  function handleSaveLine(line, lines) {
    if (line.name === null || line.name === '') {
      setErrorNameCurrentLine('Informe o nome');
      return;
    }

    const existLine = lines.filter(
      itemLine =>
        String(itemLine.name).trim().toUpperCase() ===
        line.name.trim().toUpperCase()
    );

    if (existLine.length > 1) {
      setErrorNameCurrentLine('Nome já cadastrado');
      return;
    }

    setEnableEditLineIndex(null);
  }

  function handleDuplicateLine(index, lines, setFieldValue) {
    if (enableEditLineIndex !== null) return;

    const searchLine = lines.find((item, i) => i === index);

    const duplicateLine = {
      ...searchLine,
      paddocks: [],
      name: '',
      secure_id: null,
    };

    delete duplicateLine.has_treatments;
    delete duplicateLine.has_lots;
    delete duplicateLine.has_trough_readings;

    const newLines = [...lines, duplicateLine];

    setEnableEditLineIndex(newLines.length - 1);

    setFieldValue('lines', newLines);
  }

  function handleDeleteLine(index, lines, setFieldValue) {
    const newLines = lines.filter((line, i) => i !== index);

    setFieldValue('lines', newLines);
    setEnableEditLineIndex(null);
  }

  function handleAddPaddock(lines, setFieldValue) {
    const paddocks = [];

    lines.map(line => {
      paddocks.push(...line.paddocks);
      return null;
    });

    if (namePaddock === null || namePaddock === '') {
      setErrorPaddock('Informe o nome do piquete');
      return;
    }

    if (!linePaddock) {
      setErrorLinePaddock('Necessário informar a linha');
      return;
    }

    const existPaddock = paddocks.find(
      paddock =>
        paddock.name.trim().toUpperCase() ===
        namePaddock.trim().toUpperCase() &&
        linePaddock.paddocks.find(
          paddock2 =>
            paddock2.name.trim().toUpperCase() ===
            namePaddock.trim().toUpperCase()
        )
    );

    if (existPaddock) {
      setErrorLinePaddock('Nome já cadastrado na mesma linha');
      return;
    }

    const newPaddock = {
      name: namePaddock,
      area: Number(areaPaddock),
      trough_size: Number(troughSizePaddock),
      amount_support: Number(amountSupportPaddock),
    };

    const newLines = lines;

    const lineIndex = newLines.findIndex(
      line =>
        line.name.trim().toUpperCase() === linePaddock.name.trim().toUpperCase()
    );

    newLines.splice(lineIndex, 1, {
      ...linePaddock,
      paddocks: [...linePaddock.paddocks, newPaddock],
    });

    setFieldValue('lines', newLines);
    setNamePaddock('');
    setAreaPaddock('');
    setTroughSizePaddock('');
    setAmountSupportPaddock('');
    setLinePaddock(null);
    setErrorLinePaddock('');
    setErrorPaddock('');
  }

  function handleChangePaddock(
    event,
    type,
    paddock,
    indexLine,
    lines,
    setFieldValue
  ) {
    let newIndexLine = indexLine;
    let newPaddock = paddock;

    if (type === 'line') {
      newIndexLine = lines.findIndex(
        line =>
          line.name.trim().toUpperCase() ===
          String(event.target.value).trim().toUpperCase()
      );
    } else {
      newPaddock = {
        ...paddock,
        [type]: type !== 'name' ? event.floatValue : event.target.value,
      };
    }

    const newLines = [...lines];
    const currentLine = newLines.find((line, index) => index === newIndexLine);

    const newPaddocks = [...currentLine.paddocks];

    if (enableEditPaddockIndex) {
      if (newIndexLine === indexLine) {
        newPaddocks.splice(
          Number(enableEditPaddockIndex.split('|')[1]),
          1,
          newPaddock
        );
      } else {
        const oldLine = newLines.find((line, index) => index === indexLine);

        const oldPaddocks = oldLine.paddocks.filter(
          (_, index) => index !== Number(enableEditPaddockIndex.split('|')[1])
        );

        newLines.splice(indexLine, 1, { ...oldLine, paddocks: oldPaddocks });
        newPaddocks.push(newPaddock);
      }
    }

    if (type === 'line') {
      setEnableEditPaddockIndex(`${newIndexLine}|${newPaddocks.length - 1}`);
    }

    newLines.splice(newIndexLine, 1, {
      ...currentLine,
      paddocks: newPaddocks,
    });

    setFieldValue('lines', newLines);
  }

  function handleSavePaddock(paddock, line) {
    if (paddock.name === null || paddock.name === '') {
      setErrorNameCurrentPaddock('Informe o nome');
      return;
    }

    const existPaddock = line.paddocks.find(
      itemPaddock =>
        itemPaddock.secure_id !== paddock.secure_id &&
        String(paddock.name).trim().toUpperCase() ===
        itemPaddock.name.trim().toUpperCase() &&
        line.paddocks.find(
          paddock2 =>
            paddock2.name.trim().toUpperCase() ===
            itemPaddock.name.trim().toUpperCase()
        )
    );

    if (existPaddock) {
      setErrorNameCurrentPaddock('Piquete já cadastrado na mesma linha');
      return;
    }

    setEnableEditPaddockIndex(null);
    setErrorNameCurrentPaddock(null);
  }

  function handleDuplicatePaddock(
    index,
    indexLine,
    lines,
    line,
    setFieldValue
  ) {
    if (enableEditPaddockIndex) return;

    const currentPaddock = line.paddocks.find((item, i) => i === index);

    const duplicatePaddock = {
      ...currentPaddock,
      name: '',
      secure_id: null,
    };

    delete duplicatePaddock.has_treatments;
    delete duplicatePaddock.has_lots;
    delete duplicatePaddock.has_trough_readings;

    const newPaddocks = [...line.paddocks, duplicatePaddock];

    const newLine = [...lines];
    newLine.splice(indexLine, 1, {
      ...line,
      paddocks: newPaddocks,
    });

    setFieldValue('lines', newLine);
    setEnableEditPaddockIndex(`${indexLine}|${newPaddocks.length - 1}`);
  }

  function handleDeletePaddock(lines, indexLine, index, setFieldValue) {
    const line = lines.find((_, i) => i === indexLine);
    const paddocks = line.paddocks.filter((_, i) => i !== index);

    const newLine = {
      ...line,
      paddocks,
    };

    const newLines = [...lines];

    newLines.splice(indexLine, 1, newLine);

    setFieldValue('lines', newLines);
    setEnableEditPaddockIndex(null);
  }

  async function handleSubmit(data) {

    const paddocks = [];

    data.lines.forEach(line => {
      paddocks.push(...line.paddocks);
    });

    if (paddocks.length === 0) {
      setErrorPaddock('Necessário adicionar um piquete');
      return;
    }

    const newData = {
      ...data,
      lines: data.lines.map(newLine => ({
        name: newLine.name,
        secureId: newLine.secure_id,
        paddocks: newLine.paddocks.map(newPaddock => ({
          secureId: newPaddock.secure_id,
          name: newPaddock.name,
          amountSupport: newPaddock.amount_support,
          area: newPaddock.area,
          troughSize: newPaddock.trough_size
        }))
      }))
    }

    if (secure_id) {
      await editModule.mutateAsync(newData)
    } else {
      await addModule.mutateAsync(newData);
    }

    setErrorPaddock('');
  }

  function handleBack() {
    history.goBack();
  }

  if (secure_id && error) {
    history.push('/dashboard/module');
    toast.error('Falha para carregar os dados do módulo!');

    return (
      <Container>
        <p>Não foi possível carregar os dados do módulo!</p>
      </Container>
    )
  }

  return (
    <center>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            validationSchema={schema}
            initialValues={{
              secure_id: secure_id || null,
              name: moduleData ? moduleData.currentModule.name : '' || '',
              lines: moduleData ? [...moduleData.currentModule.lines] : [],
            }}
            onSubmit={handleSubmit}
          >
            {({ handleChange, setFieldValue, values, errors, touched, isSubmitting }) => (
              <Form>
                <StyledPaper>
                  <Grid container>
                    <StyledTextField
                      name="name"
                      label="Módulo *"
                      variant="outlined"
                      size="small"
                      autoComplete="nope"
                      autoFocus
                      fullWidth
                      value={values.name}
                      error={!!errors.name && touched.name}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="name" component={Error} />
                  </Grid>

                  <StyledGridContainer>
                    <Grid container justify="space-between" spacing={2}>
                      <Grid item sm xs={12}>
                        <StyledTextField
                          label="Linha *"
                          variant="outlined"
                          size="small"
                          autoComplete="nope"
                          fullWidth
                          error={(!!errors.lines && touched.lines) || errorLine}
                          value={nameLine}
                          onChange={handleChangeNameLine}
                        />
                        {errorLine ? (
                          <Error>{errorLine}</Error>
                        ) : (
                          <ErrorMessage name="lines" component={Error} />
                        )}
                      </Grid>
                      <Grid item sm="auto" xs="auto">
                        <ContentButton>
                          <StyledAddButton
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={() =>
                              handleAddLine(values.lines, setFieldValue)
                            }
                          >
                            Adicionar
                          </StyledAddButton>
                        </ContentButton>
                      </Grid>
                    </Grid>

                    <Grid container direction="column" spacing={2}>
                      {values.lines.map((line, index) => (
                        <StyledPaperGrid
                          key={String(line.secure_id + index)}
                          elevation={0}
                        >
                          <Grid container justify="space-between">
                            <Grid item sm>
                              <TextField
                                id={`line${index}`}
                                autoComplete="nope"
                                fullWidth
                                autoFocus
                                disabled={enableEditLineIndex !== index}
                                error={
                                  enableEditLineIndex === index &&
                                  errorNameCurrentLine
                                }
                                value={line.name}
                                onChange={event =>
                                  handleChangeLine(
                                    event,
                                    index,
                                    line,
                                    values.lines,
                                    setFieldValue
                                  )
                                }
                              />
                              {enableEditLineIndex === index &&
                                errorNameCurrentLine && (
                                  <Error>{errorNameCurrentLine}</Error>
                                )}
                            </Grid>
                            <Grid item sm="auto" xs="auto">
                              <ContentButton>
                                {enableEditLineIndex === index ? (
                                  <ButtonTable
                                    icon={<SaveIcon />}
                                    background={colors.success}
                                    onClick={() =>
                                      handleSaveLine(line, values.lines)
                                    }
                                  />
                                ) : (
                                  <ButtonTable
                                    icon={<EditIcon />}
                                    background={colors.primary}
                                    lighten={0.6}
                                    onClick={() =>
                                      setEnableEditLineIndex(index)
                                    }
                                  />
                                )}
                                <ButtonTable
                                  icon={<FilterNoneIcon />}
                                  background={colors.dark}
                                  lighten={0.5}
                                  onClick={() =>
                                    handleDuplicateLine(
                                      index,
                                      values.lines,
                                      setFieldValue
                                    )
                                  }
                                />
                                <ButtonTable
                                  icon={<DeleteIcon />}
                                  background={colors.error}
                                  disabled={disableLine(line)}
                                  onClick={() =>
                                    handleDeleteLine(
                                      index,
                                      values.lines,
                                      setFieldValue
                                    )
                                  }
                                />
                              </ContentButton>
                            </Grid>
                          </Grid>
                        </StyledPaperGrid>
                      ))}
                    </Grid>
                  </StyledGridContainer>

                  <StyledDivider light />

                  <StyledGrid container justify="space-between" spacing={2}>
                    <Grid item sm>
                      <StyledTextField
                        label="Piquete *"
                        variant="outlined"
                        autoComplete="nope"
                        size="small"
                        fullWidth
                        error={errorPaddock}
                        value={namePaddock}
                        onChange={handleChangeNamePaddock}
                      />
                      {errorPaddock && <Error>{errorPaddock}</Error>}
                    </Grid>
                    <Grid item sm xs={12}>
                      <NumberFormatForm
                        label="Área m²"
                        value={areaPaddock}
                        decimalScale={2}
                        allowNegative={false}
                        onValueChange={val => setAreaPaddock(val.floatValue)}
                      />
                    </Grid>
                    <Grid item sm xs={12}>
                      <NumberFormatForm
                        label="Tamanho de cocho"
                        value={troughSizePaddock}
                        decimalScale={2}
                        allowNegative={false}
                        onValueChange={val =>
                          setTroughSizePaddock(val.floatValue)
                        }
                      />
                    </Grid>
                  </StyledGrid>

                  <StyledGrid
                    container
                    justify="space-between"
                    marginBottom={18}
                    spacing={2}
                  >
                    <Grid item sm xs={12}>
                      <NumberFormatForm
                        label="Qtd. Suportada"
                        value={amountSupportPaddock}
                        decimalScale={0}
                        allowNegative={false}
                        onValueChange={val =>
                          setAmountSupportPaddock(val.floatValue)
                        }
                      />
                    </Grid>
                    <Grid item sm xs={12}>
                      <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel shrink={linePaddock}>Linha *</InputLabel>
                        <Select
                          labelWidth={linePaddock ? 50 : 0}
                          value={linePaddock}
                          onChange={event => setLinePaddock(event.target.value)}
                          error={errorLinePaddock}
                        >
                          {values.lines.map((line, index) => (
                            <MenuItem
                              id={String(line + index)}
                              key={line.id}
                              value={line}
                            >
                              {line.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {errorLinePaddock && <Error>{errorLinePaddock}</Error>}
                    </Grid>
                    <Hidden only="xs">
                      <Grid item auto>
                        <StyledAddButton
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={() =>
                            handleAddPaddock(values.lines, setFieldValue)
                          }
                        >
                          Adicionar
                        </StyledAddButton>
                      </Grid>
                    </Hidden>
                    <Grid item sm />
                  </StyledGrid>

                  <Hidden smUp>
                    <StyledAddButton
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={() =>
                        handleAddPaddock(values.lines, setFieldValue)
                      }
                    >
                      Adicionar
                    </StyledAddButton>
                  </Hidden>

                  {values.lines.map((line, indexLine) => {
                    return line.paddocks.map((paddock, index) => (
                      <StyledPaperGrid
                        key={String(paddock + index)}
                        elevation={0}
                      >
                        <Grid container>
                          <StyledPaddockEditGrid item sm>
                            <TextField
                              id={`name${index}`}
                              label="Piquete"
                              autoComplete="nope"
                              size="small"
                              fullWidth
                              disabled={
                                enableEditPaddockIndex !==
                                `${indexLine}|${index}`
                              }
                              error={
                                enableEditPaddockIndex ===
                                `${indexLine}|${index}` &&
                                errorNameCurrentPaddock
                              }
                              value={paddock.name}
                              onChange={event =>
                                handleChangePaddock(
                                  event,
                                  'name',
                                  paddock,
                                  indexLine,
                                  values.lines,
                                  setFieldValue
                                )
                              }
                            />
                            {enableEditPaddockIndex ===
                              `${indexLine}|${index}` &&
                              errorNameCurrentPaddock && (
                                <Error>{errorNameCurrentPaddock}</Error>
                              )}
                          </StyledPaddockEditGrid>
                          <StyledPaddockEditGrid item sm>
                            <NumberFormatForm
                              id={`area${index}`}
                              label="Área m²"
                              variant="standard"
                              decimalScale={2}
                              allowNegative={false}
                              disabled={
                                enableEditPaddockIndex !==
                                `${indexLine}|${index}`
                              }
                              value={paddock.area}
                              onValueChange={val =>
                                handleChangePaddock(
                                  val,
                                  'area',
                                  paddock,
                                  indexLine,
                                  values.lines,
                                  setFieldValue
                                )
                              }
                            />
                          </StyledPaddockEditGrid>
                          <StyledPaddockEditGrid item sm>
                            <NumberFormatForm
                              id={`trough_size${index}`}
                              label="Tamanho de cocho"
                              variant="standard"
                              decimalScale={2}
                              allowNegative={false}
                              disabled={
                                enableEditPaddockIndex !==
                                `${indexLine}|${index}`
                              }
                              value={paddock.trough_size}
                              onValueChange={val =>
                                handleChangePaddock(
                                  val,
                                  'trough_size',
                                  paddock,
                                  indexLine,
                                  values.lines,
                                  setFieldValue
                                )
                              }
                            />
                          </StyledPaddockEditGrid>
                          <StyledPaddockEditGrid item sm>
                            <NumberFormatForm
                              id={`amount_support${index}`}
                              label="Qtd. Suportada"
                              variant="standard"
                              decimalScale={0}
                              allowNegative={false}
                              disabled={
                                enableEditPaddockIndex !==
                                `${indexLine}|${index}`
                              }
                              value={paddock.amount_support}
                              onValueChange={val =>
                                handleChangePaddock(
                                  val,
                                  'amount_support',
                                  paddock,
                                  indexLine,
                                  values.lines,
                                  setFieldValue
                                )
                              }
                            />
                          </StyledPaddockEditGrid>
                          <StyledPaddockEditGrid item sm direction="column">
                            <FormControl fullWidth size="small">
                              <InputLabel id="paddock-line-select">
                                Linha
                              </InputLabel>
                              <Select
                                id={`line${index}`}
                                disabled={
                                  enableEditPaddockIndex !==
                                  `${indexLine}|${index}`
                                }
                                value={line.name}
                                onChange={event =>
                                  handleChangePaddock(
                                    event,
                                    'line',
                                    paddock,
                                    indexLine,
                                    values.lines,
                                    setFieldValue
                                  )
                                }
                              >
                                {values.lines.map((itemLine, indexMenu) => (
                                  <MenuItem
                                    id={String(itemLine + indexMenu)}
                                    key={itemLine.secure_id}
                                    value={itemLine.name}
                                  >
                                    {itemLine.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </StyledPaddockEditGrid>

                          <Grid item sm="auto">
                            <ContentButton>
                              {enableEditPaddockIndex ===
                                `${indexLine}|${index}` ? (
                                <ButtonTable
                                  icon={<SaveIcon />}
                                  background={colors.success}
                                  onClick={() =>
                                    handleSavePaddock(paddock, line)
                                  }
                                />
                              ) : (
                                <ButtonTable
                                  icon={<EditIcon />}
                                  background={colors.primary}
                                  lighten={0.6}
                                  onClick={() =>
                                    setEnableEditPaddockIndex(
                                      `${indexLine}|${index}`
                                    )
                                  }
                                />
                              )}
                              <ButtonTable
                                icon={<FilterNoneIcon />}
                                background={colors.dark}
                                lighten={0.5}
                                onClick={() =>
                                  handleDuplicatePaddock(
                                    index,
                                    indexLine,
                                    values.lines,
                                    line,
                                    setFieldValue
                                  )
                                }
                              />
                              <ButtonTable
                                icon={<DeleteIcon />}
                                background={colors.error}
                                disabled={
                                  paddock.has_treatments ||
                                  paddock.has_lots ||
                                  paddock.has_trough_readings
                                }
                                onClick={() =>
                                  handleDeletePaddock(
                                    values.lines,
                                    indexLine,
                                    index,
                                    setFieldValue
                                  )
                                }
                              />
                            </ContentButton>
                          </Grid>
                        </Grid>
                      </StyledPaperGrid>
                    ));
                  })}

                  <ContentSubmitButtons>
                    <StyledSubmitButton
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={handleBack}
                    >
                      Cancelar
                    </StyledSubmitButton>
                    <ButtonSubmit
                      title={secure_id ? 'Alterar' : 'Cadastrar'}
                      background={colors.success}
                      disabled={
                        enableEditLineIndex !== null ||
                        enableEditPaddockIndex !== null ||
                        isSubmitting
                      }
                      loading={isSubmitting}
                    />
                  </ContentSubmitButtons>
                </StyledPaper>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </center>
  );
}

Edit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

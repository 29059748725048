import { useQuery } from "react-query";
import apiV2 from '~/services/apiV2';

async function getTreatmentsForCreateManufactoringDiet(currentDiet, currentDate) {
  const { data } = await apiV2.get(`v1/feedlot/food-management/manufacturing-diet-data-create`, {
    params: {
      currentDiet,
      currentDate
    }
  })

  return data
}

export function useV2GetTreatmentsForCreateManufactoringDiet(currentDiet, currentDate) {
  return useQuery(
    ['getTreatmentsForCreateManufactoringDiet', currentDiet, currentDate],
    () => getTreatmentsForCreateManufactoringDiet(currentDiet, currentDate))
}

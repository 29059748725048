import styled from 'styled-components';
import {
  Paper,
  Grid,
  FormControl,
  TableContainer,
  TableHead,
  TableCell,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 960px;
  width: 80%;
  padding: 40px 0px;
`;

export const StyledGrid = styled(Grid)`
  margin-bottom: 20px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
`;

export const StyledGridItem = styled(Grid)`
  margin: 5px 10px;
`;

export const ContentAddButton = styled.div`
  max-width: 500px;
  margin-bottom: 15px;
`;

export const ContentButton = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const StyledFormControl = styled(FormControl)`
  margin-top: 8px;
  text-align: left;
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const TitleTotal = styled.span`
  font-weight: bold;
  color: ${colors.darkPrimary};
`;

export const TitleTable = styled.b`
  color: ${colors.primary};
`;

export const TableCellStyled = styled(TableCell)`
  white-space: nowrap;
  color: ${colors.error};
`;

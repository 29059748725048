/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableCell,
  Typography,
  TableRow,
  Grid,
} from '@material-ui/core';

import {
  Container,
  Header,
  StyledTableHead,
  StyledTableBody,
  Column,
  StyledTablePaper,
  StyledPaperTwo,
} from './styles';

import logo from '~/assets/images/logo_black.png';
import { formatNumber } from '~/utils/format';

export default class PrintActiveLot extends Component {
  render() {
    const { activeLots, date, data } = this.props;

    return (
      <Container>
        <Header>
          <img src={logo} alt="logo" />
          <Typography variant="h2">Relatório econômico resumido</Typography>
          <Column>
            <Typography variant="h5">{`Data: ${String(
              format(date, 'dd/MM/yyyy')
            )}`}</Typography>
          </Column>
        </Header>

        <StyledTablePaper elevation={0}>
          <TableContainer>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableRow>
                    <TableCell>Lote</TableCell>
                    <TableCell colSpan="4">Entrada</TableCell>
                    <TableCell colSpan="3">Saída</TableCell>
                    <TableCell colSpan="11">Reultados</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Lote</TableCell>
                    <TableCell>Qtd</TableCell>
                    <TableCell>R$/an</TableCell>
                    <TableCell>Peso vivo/an</TableCell>
                    <TableCell>Peso car/an</TableCell>
                    <TableCell>Qtd</TableCell>
                    <TableCell>Peso vivo/an</TableCell>
                    <TableCell>Peso car/an</TableCell>
                    <TableCell>% RC</TableCell>
                    <TableCell>GMD</TableCell>
                    <TableCell>GMC</TableCell>
                    <TableCell>@ col</TableCell>
                    <TableCell>Dias de trato</TableCell>
                    <TableCell>Custo alim./dia</TableCell>
                    <TableCell>Custo alim. total</TableCell>
                    <TableCell>Custo total an/dia</TableCell>
                    <TableCell>Custo total do lote</TableCell>
                    <TableCell>R$ venda @</TableCell>
                    <TableCell>R$ venda an</TableCell>
                    <TableCell>R$ venda lote</TableCell>
                    <TableCell>Custo @col</TableCell>
                    <TableCell>Custo @prod</TableCell>
                    <TableCell>Bal/an</TableCell>
                    <TableCell>Rent. a.m.</TableCell>
                    <TableCell>Lucr. a.m.</TableCell>
                  </TableRow>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody>
                {activeLots.map(resum => (
                  <TableRow key={resum.secure_id}>
                    <TableCell>{resum.lot_code}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {resum.input_amount_animals}
                    </TableCell>
                    <TableCell>{resum.input_value_head}</TableCell>
                    <TableCell>
                      {formatNumber(resum.input_average_weight, 2, 2)}
                    </TableCell>
                    <TableCell>{formatNumber(resum.input_at, 2, 2)}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                      {resum.output_amount_animals}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.output_average_weight, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.output_animal_carcass_weight, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.output_carcass_yield, 2, 2)}
                    </TableCell>
                    <TableCell>{formatNumber(resum.gmd, 2, 2)}</TableCell>
                    <TableCell>{formatNumber(resum.gmc, 2, 2)}</TableCell>
                    <TableCell>
                      {formatNumber(resum.arroba_at_gain, 2, 2)}
                    </TableCell>
                    <TableCell>{resum.confinament_days}</TableCell>
                    <TableCell>
                      {formatNumber(resum.feed_cost_head_day, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.feed_cost_lot, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.final_cost_head_day, 2, 2)}
                    </TableCell>

                    <TableCell>
                      {formatNumber(resum.final_cost_lot, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.value_arroba_sale, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.value_revenue_head, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.value_revenue_total, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.cost_arroba_gain, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.costAtProduce, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.balance_animal, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.rentability_total, 2, 2)}
                    </TableCell>
                    <TableCell>
                      {formatNumber(resum.profitability_total, 2, 2)}
                    </TableCell>
                  </TableRow>
                ))}
              </StyledTableBody>
            </Table>
          </TableContainer>
        </StyledTablePaper>
        <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
        <StyledPaperTwo>
          <Header>
            <h3>Resumo</h3>
          </Header>
          <Grid container>
            <Grid item xs sm>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Total de lotes encerrados:
                </Typography>
                <strong>{activeLots.length}</strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Entrada de Animais:
                </Typography>
                <strong>{data.inputAnimals}</strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Animais abatidos:
                </Typography>
                <strong>{data.outputAnimals}</strong>
              </Grid>
            </Grid>
            <Grid item xs ms>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Peso vivo de entrada (kgPV/an):
                </Typography>
                <strong>
                  {formatNumber(
                    data.avereigthWeightInput / data.inputAnimals,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Peso vivo de entrada (@/an):
                </Typography>
                <strong>
                  {formatNumber(
                    data.avereigthWeightInputArroba / data.inputAnimals,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center" style={{ marginTop: 24 }}>
                <Typography variant="body2" component="h5">
                  Peso vivo de saída (kgPV/an):
                </Typography>
                <strong>
                  {formatNumber(
                    data.avereigthWeightOutput / data.outputAnimals,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Peso de carcaça (Kg/an):
                </Typography>
                <strong>
                  {formatNumber(
                    data.carcassWeightData / data.outputAnimals,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Peso de carcaça (@/an):
                </Typography>
                <strong>
                  {formatNumber(
                    data.carcassWeightData / data.outputAnimals / 15,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Rendimento de carcaça (%):
                </Typography>
                <strong>
                  {formatNumber(
                    (data.carcassWeightData / data.avereigthWeightOutput) * 100,
                    2,
                    2
                  )}
                </strong>
              </Grid>

              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Dias médio de confinamento:
                </Typography>
                <strong>
                  {formatNumber(
                    data.confinamentDaysData / data.inputAnimals,
                    0,
                    0
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Ganho médio diário:
                </Typography>
                <strong>
                  {formatNumber(data.gmdData / data.outputAnimals, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Ganho (@/an):
                </Typography>
                <strong>
                  {formatNumber(data.arrobaData / data.outputAnimals, 2, 2)}
                </strong>
              </Grid>
            </Grid>
            <Grid item xs ms>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Custo de compra de animais ($/an):
                </Typography>
                <strong>
                  {formatNumber(data.animalCostData / data.outputAnimals, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Custo de compra de animais ($/@):
                </Typography>
                <strong>
                  {formatNumber(
                    data.animalCostData /
                      data.outputAnimals /
                      (data.avereigthWeightInputArroba / data.outputAnimals),
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Custo alimentar ($/an/dia):
                </Typography>
                <strong>
                  {formatNumber(
                    data.feedCostDayData / data.outputAnimals,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Custo alimentar total ($/an):
                </Typography>
                <strong>
                  {formatNumber(
                    data.feedCostlotData / data.outputAnimals,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Custo total ($/an):
                </Typography>
                <strong>
                  {formatNumber(
                    data.costLotTotalData / data.outputAnimals,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Custo da arroba colocada ($/@):
                </Typography>
                <strong>
                  {formatNumber(data.arrobaColData / data.outputAnimals, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Custo da arroba produzida ($/@):
                </Typography>
                <strong>
                  {formatNumber(
                    data.arriobaProdData / data.outputAnimals,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Preço de venda ($/an):
                </Typography>
                <strong>
                  {formatNumber(
                    data.valueRevenueHeadData / data.outputAnimals,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Preço de venda ($/@):
                </Typography>
                <strong>
                  {formatNumber(
                    data.valueArrobaSaleData / data.outputAnimals,
                    2,
                    2
                  )}
                </strong>
              </Grid>
              {/* <Grid
                      container
                      alignItems="center"
                      style={{ marginTop: 24 }}
                    >
                      <Typography variant="body2" component="h5">
                        Resultado (@/an)
                      </Typography>
                      <strong>
                        { {formatNumber(mediaGeneralPvDOne / animals, 2, 2)} }
                      </strong>
                    </Grid> */}
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Balanço ($/an):
                </Typography>
                <strong>
                  {formatNumber(data.balanceData / data.outputAnimals, 2, 2)}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Rentabilidade (%/a.m.):
                </Typography>
                <strong>
                  {formatNumber(
                    ((data.balanceData /
                      data.outputAnimals /
                      (data.costLotTotalData / data.outputAnimals)) *
                      100) /
                      (data.confinamentDaysData / data.inputAnimals / 30.4),
                    2,
                    2
                  )}
                </strong>
              </Grid>
              <Grid container alignItems="center">
                <Typography variant="body2" component="h5">
                  Lucratividade (%/a.m.):
                </Typography>
                <strong>
                  {formatNumber(
                    ((data.balanceData /
                      data.outputAnimals /
                      (data.valueRevenueHeadData / data.outputAnimals)) *
                      100) /
                      (data.confinamentDaysData / data.inputAnimals / 30.4),
                    2,
                    2
                  )}
                </strong>
              </Grid>
            </Grid>
          </Grid>
        </StyledPaperTwo>
      </Container>
    );
  }
}

PrintActiveLot.propTypes = {
  activeLots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  date: PropTypes.string.isRequired,
  data: PropTypes.shape().isRequired,
};

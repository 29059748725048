import { Grid, TextField } from '@material-ui/core';
import { format } from 'date-fns';
import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';
import { useMutation } from 'react-query';
import { toast } from "react-toastify";
import * as Yup from 'yup';
import ButtonSubmit from '~/components/ButtonSubmit';
import InputDatePicker from '~/components/InputDatePicker';
import Loader from '~/components/Loader';
import NumberFormatForm from '~/components/NumberFormatForm';
import { useV2GetLastOperationalCost } from '~/hooks/apiV2/admin/operationalCost/useV2GetLastOperationalCost';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';
import { queryClient } from '~/services/queryClient';
import {
  Container, ContentItem,
  ContentSubmitButtons, Error, StyledPaper, StyledSubmitButton
} from './styles';

export default function Register() {
  const { data, error, isLoading } = useV2GetLastOperationalCost()

  const schema = Yup.object().shape({
    initial_date: Yup.date()
      .typeError('Insira uma data válida')
      .required('Informe a data inicial'),
    final_date: Yup.date().typeError('Insira uma data válida').nullable(),
    cost: Yup.number()
      .typeError('Insira um custo válido')
      .max(9999999999.99, 'O valor deve ser menor que 9999999999.99')
      .positive('O custo deve ser positivo')
      .required('Informe o custo'),
    obs: Yup.string().nullable(),
  });

  function handleBack() {
    history.push('/dashboard/operationalCost');
  }

  const addOperationalCost = useMutation(async (data) => {
    return await apiV2.post(`v1/feedlot/admin/operational-cost`, data);
  },
    {
      onSuccess: (response) => {
        queryClient.refetchQueries('operationalCost');
        toast.success(response.data?.message || 'O custo operacional foi cadastrado com sucesso.')
        handleBack()
      },
      onError: (error) => {
        if (!!error.response.data?.type) {
          if (error.response.data?.type === 'warning') {
            toast.warning(error.response.data.message)
          } else {
            toast.error(error.response.data.message)
          }
        } else {
          toast.error('Falha ao tentar cadastrar o custo operacional, verifique se preencheu todos os campos corretamente.')
        }
      }
    }
  );

  async function handleSubmit(data) {
    try {
      const newData = {
        ...data,
        initial_date: format(new Date(data.initial_date), 'yyyy-MM-dd'),
      };
      await addOperationalCost.mutateAsync(newData);
    } catch { }
  }

  return (
    <center>
      <Container>
        {error ? (
          <p>{error.response?.data?.message || 'Falha para carregar os dados do custo operacional!'}</p>
        ) : isLoading ? (
          <Loader />
        ) : (
          <Formik
            validationSchema={schema}
            initialValues={{
              initial_date: new Date(),
              final_date: null,
              cost: '',
              obs: '',
            }}
            onSubmit={handleSubmit}
          >
            {({ handleChange, setFieldValue, values, errors, touched }) => (
              <Form>
                <ContentItem>
                  <StyledPaper>
                    <Grid container spacing={2} direction="column">
                      <Grid item>
                        <Grid container spacing={2} direction="column">
                          <Grid item xs={12} sm="auto">
                            <InputDatePicker
                              name="initial_date"
                              label="Data Inicial"
                              inputVariant="outlined"
                              selectedDate={values.initial_date}
                              handleChangeDate={date =>
                                setFieldValue('initial_date', date)
                              }
                              minDate={data}
                            />
                            <ErrorMessage
                              name="initial_date"
                              component={Error}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item>
                        <Grid container spacing={2} direction="column">
                          <Grid item xs={12} sm={4}>
                            <NumberFormatForm
                              name="cost"
                              label="Custo/cab/dia"
                              variant="outlined"
                              size="small"
                              fullWidth
                              autoComplete="nope"
                              prefix="R$"
                              required
                              autoFocus={true}
                              disabled={values.final_date}
                              fixedDecimalScale
                              decimalScale={2}
                              allowNegative={false}
                              isAllowed={val => {
                                const { formattedValue, floatValue } = val;
                                return (
                                  formattedValue === '' ||
                                  floatValue <= 9999999999.99
                                );
                              }}
                              error={!!errors.cost && touched.cost}
                              value={values.cost}
                              setFieldValue={setFieldValue}
                            />
                            <ErrorMessage name="cost" component={Error} />
                          </Grid>
                          <Grid item xs={12} sm>
                            <TextField
                              name="obs"
                              label="Observação"
                              variant="outlined"
                              size="small"
                              fullWidth
                              autoComplete="nope"
                              onChange={handleChange}
                              value={values.obs}
                            />
                            <ErrorMessage name="obs" component={Error} />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <ContentSubmitButtons>
                      <ButtonSubmit title="Cadastrar" loading={addOperationalCost.isLoading} />
                      <StyledSubmitButton
                        color="primary"
                        variant="outlined"
                        type="button"
                        onClick={handleBack}
                      >
                        Cancelar
                      </StyledSubmitButton>
                    </ContentSubmitButtons>
                  </StyledPaper>
                </ContentItem>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </center>
  );
}

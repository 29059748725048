import styled from 'styled-components';
import {
  Paper,
  Grid,
  TextField,
  FormControl,
  Button,
  Divider,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  width: 80%;
  max-width: 800px;
  padding: 40px 0px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
`;

export const StyledGridItem = styled(Grid)`
  margin: 5px 10px;
  text-align: left;
`;

export const StyledTextField = styled(TextField)`
  margin: 5px 0px;
`;

export const StyledFormControl = styled(FormControl)`
  margin-top: 8px;
`;

export const ContentItem = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const Error = styled.span`
  margin: 8px 14px 0;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.marginTop || 50}px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 30px;
`;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { Grid, TextField } from '@material-ui/core';
import { toast } from 'react-toastify'

// Query
import { useApiV2SelectTreaty } from '~/hooks/apiV2/register/treaty/useApiV2SelectTreaty';
import { useApiV2AddTreaty } from '~/hooks/apiV2/register/treaty/useApiV2AddTreaty';
import { useApiV2EditTreaty } from '~/hooks/apiV2/register/treaty/useApiV2EditTreaty';

import {
  Container,
  StyledPaper,
  ContentItem,
  Error,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';

import history from '~/services/history';
import Loader from '~/components/Loader';
import NumberFormatPercent from '~/components/NumberFormatPercent';
import ButtonSubmit from '~/components/ButtonSubmit';

const schema = Yup.object().shape({
  name: Yup.string().required('Informe uma trato'),
  proportion: Yup.string().required('Informe uma proporção'),
});

export default function Register({ match }) {
  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  // Query
  const { data: CurrentTreatyData, isLoading, error } = useApiV2SelectTreaty(secure_id);
  const addTreaty = useApiV2AddTreaty();
  const editTraty = useApiV2EditTreaty();

  async function handleSubmit(data) {
    if (secure_id) {
      await editTraty.mutateAsync({
        ...data,
        secure_id
      });
    } else {
      await addTreaty.mutateAsync(data);
    }
  }

  function handleBack() {
    history.goBack();
  }

  if (secure_id && error) {
    history.push('/dashboard/treatySetting');
    toast.error('Falha para carregar os dados do trato!');

    return (
      <center>
        <p>Não foi possível carregar o trato!</p>
      </center>
    )
  }

  return (
    <center>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            validationSchema={schema}
            initialValues={{
              name: (CurrentTreatyData ? CurrentTreatyData.currentTreaty.name : ''),
              proportion:
                CurrentTreatyData ?
                  String(CurrentTreatyData.currentTreaty.proportion) !== '' ? CurrentTreatyData.currentTreaty.proportion : ''
                  : ''
            }}
            onSubmit={handleSubmit}
          >
            {({
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              touched,
              values,
              isSubmitting
            }) => (
              <Form>
                <ContentItem>
                  <StyledPaper>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm>
                        <TextField
                          name="name"
                          variant="outlined"
                          label="Trato *"
                          autoComplete="nope"
                          size="small"
                          autoFocus
                          fullWidth
                          value={values.name}
                          error={!!errors.name && touched.name}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="name" component={Error} />
                      </Grid>
                      <Grid item xs={12} sm>
                        <NumberFormatPercent
                          name="proportion"
                          label="Proporção (%) *"
                          fullWidth
                          placeholder="0,00"
                          value={values.proportion}
                          setFieldValue={setFieldValue}
                          onBlur={handleBlur}
                          error={!!errors.proportion && touched.proportion}
                        />
                        <ErrorMessage name="proportion" component={Error} />
                      </Grid>
                    </Grid>

                    <ContentSubmitButtons>
                      {secure_id ? (
                        <ButtonSubmit title="Alterar" loading={isSubmitting} />
                      ) : (
                        <ButtonSubmit
                          title="Cadastrar"
                          loading={isSubmitting}
                        // disabled={totalProportion === 100}
                        />
                      )}
                      <StyledSubmitButton
                        variant="outlined"
                        color="primary"
                        type="button"
                        onClick={handleBack}
                      >
                        Cancelar
                      </StyledSubmitButton>
                    </ContentSubmitButtons>
                  </StyledPaper>
                </ContentItem>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </center>
  );
}

Register.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

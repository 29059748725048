import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import { Autocomplete } from '@material-ui/lab';
import {
  Grid,
  FormGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { toast } from 'react-toastify';

import {
  Container,
  StyledPaper,
  ContentItem,
  ContentSubmitButtons,
  StyledSubmitButton,
  StyledTextField,
  Error,
} from './styles';

import { states, funcsPersonal, typesPersonal } from '~/utils/options';
import colors from '~/styles/colors';
import history from '~/services/history';
import Loader from '~/components/Loader';
import { useApiV2EditStakeholder } from '~/hooks/apiV2/register/stakeholder/useApiV2EditStakeholder';
import { useApiV2SelectStakeholder } from '~/hooks/apiV2/register/stakeholder/useApiV2SelectStakeholder';

const schema = Yup.object().shape({
  fantasyName: Yup.string().nullable(),
  name: Yup.string().required('Digite a razão social'),
  contactName: Yup.string().required('Digite um nome para contato'),
  email: Yup.string().email('E-mail inválido').required('Digite um e-mail'),
  telephone: Yup.string().required('Digite o telefone'),
  doc: Yup.string().required('Digite o documento'),
  cep: Yup.string().required('Digite o CEP'),
  addressStreet: Yup.string().required('Digite o endereço'),
  addressNumber: Yup.string().required('Digite o número'),
  neighborhood: Yup.string().required('Digite o bairro'),
  city: Yup.string().required('Digite a cidade'),
  state: Yup.string().required('Selecione o estado'),
  complement: Yup.string().nullable(),
  client: Yup.boolean().when('provider', {
    is: false,
    then: Yup.boolean()
      .required('Selecione um campo')
      .oneOf([true], 'Selecione um campo'),
  }),
  provider: Yup.boolean(),
  obs: Yup.string().nullable(),
});

export default function StakeholderEdit({ match }) {
  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  const editStakeholder = useApiV2EditStakeholder()
  const { data, isLoading, error } = useApiV2SelectStakeholder(secure_id)

  async function handleSubmit(data) {
    const newData = {
      ...data,
      secure_id,
    };

    delete newData.client;
    delete newData.provider;

    if (data.client && data.provider) {
      newData.type = 'both';
    } else if (data.client) {
      newData.type = 'client';
    } else {
      newData.type = 'provider';
    }

    if (data.subtype === 'individual') {
      newData.fantasyName = null;
    }

    await editStakeholder.mutateAsync(newData)
  }

  function handleBack() {
    history.goBack();
  }

  if (error) {
    history.push('/dashboard/stakeholder');
    toast.error('Não foi possível carregar o registro!');

    return (
      <Container>
        <p>Não foi possível carregar o registro!</p>
      </Container>
    )
  }

  return (
    <center>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Formik
              enableReinitialize
              validationSchema={schema}
              initialValues={{
                name: data.currentStakeholder.name,
                fantasyName: data.currentStakeholder.fantasy_name,
                doc: data.currentStakeholder.doc,
                contactName: data.currentStakeholder.contact_name,
                telephone: data.currentStakeholder.telephone,
                email: data.currentStakeholder.email,
                cep: data.currentStakeholder.cep,
                addressStreet: data.currentStakeholder.address_street,
                addressNumber: data.currentStakeholder.address_number,
                neighborhood: data.currentStakeholder.neighborhood,
                city: data.currentStakeholder.city,
                state: data.currentStakeholder.state,
                complement: data.currentStakeholder.complement,
                obs: data.currentStakeholder.complement,
                client:
                  data.currentStakeholder.type === 'client' ||
                  data.currentStakeholder.type === 'both',
                provider:
                  data.currentStakeholder.type === 'provider' ||
                  data.currentStakeholder.type === 'both',
                subtype: data.currentStakeholder.subtype,
              }}
              onSubmit={handleSubmit}
            >
              {({ handleChange, setFieldValue, values, errors, touched, isSubmitting }) => (
                <Form>
                  <ContentItem>
                    <StyledPaper>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm>
                          <FormGroup row>
                            {funcsPersonal.map(func => (
                              <FormControlLabel
                                label={func.label}
                                key={func.id}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={values[func.value]}
                                    name={func.value}
                                    onChange={handleChange}
                                  />
                                }
                              />
                            ))}
                          </FormGroup>
                          <ErrorMessage
                            style={{ display: 'block' }}
                            name="client"
                            component={Error}
                          />
                        </Grid>
                        <Grid item xs={12} sm>
                          <RadioGroup
                            name="subtype"
                            value={values.subtype}
                            row
                            onChange={handleChange}
                          >
                            {typesPersonal.map(type => (
                              <FormControlLabel
                                key={type.id}
                                value={type.value}
                                control={<Radio color="primary" size="small" />}
                                label={type.label}
                              />
                            ))}
                          </RadioGroup>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm>
                          <StyledTextField
                            name="name"
                            variant="outlined"
                            label={
                              values.subtype === 'individual'
                                ? 'Nome'
                                : 'Razão social'
                            }
                            autoComplete="nope"
                            size="small"
                            fullWidth
                            required
                            error={!!errors.name && touched.name}
                            defaultValue={values.name}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <ErrorMessage name="name" component={Error} />
                        </Grid>
                      </Grid>

                      {values.subtype === 'legal_entity' && (
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm>
                            <StyledTextField
                              id="fantasyName"
                              name="fantasyName"
                              variant="outlined"
                              label="Nome fantasia"
                              autoComplete="nope"
                              defaultValue={values.fantasyName}
                              fullWidth
                              size="small"
                              error={
                                !!errors.fantasyName && touched.fantasyName
                              }
                              onChange={handleChange}
                            />
                            <ErrorMessage
                              name="fantasyName"
                              component={Error}
                            />
                          </Grid>
                        </Grid>
                      )}

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm>
                          <NumberFormat
                            name="doc"
                            label={
                              values.subtype === 'individual' ? 'CPF' : 'CNPJ'
                            }
                            placeholder={
                              values.subtype === 'individual'
                                ? '000.000.000-00'
                                : '00.000.000/0000-00'
                            }
                            format={
                              values.subtype === 'individual'
                                ? '###.###.###-##'
                                : '##.###.###/####-##'
                            }
                            variant="outlined"
                            mask="_"
                            customInput={StyledTextField}
                            autoComplete="nope"
                            size="small"
                            fullWidth
                            required
                            error={!!errors.doc && touched.doc}
                            defaultValue={values.doc}
                            onValueChange={val =>
                              setFieldValue('doc', val.formattedValue || '')
                            }
                          />
                          <ErrorMessage name="doc" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm>
                          <StyledTextField
                            name="contactName"
                            variant="outlined"
                            label="Nome do contato"
                            autoComplete="nope"
                            fullWidth
                            required
                            size="small"
                            error={
                              !!errors.contactName && touched.contactName
                            }
                            defaultValue={values.contactName}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <ErrorMessage name="contactName" component={Error} />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm>
                          <NumberFormat
                            name="telephone"
                            label="Telefone"
                            placeholder="(00) 00000-0000"
                            format="(##) #####-####"
                            variant="outlined"
                            mask="_"
                            customInput={StyledTextField}
                            autoComplete="nope"
                            size="small"
                            fullWidth
                            required
                            error={!!errors.telephone && touched.telephone}
                            defaultValue={values.telephone}
                            onValueChange={val =>
                              setFieldValue(
                                'telephone',
                                val.formattedValue || ''
                              )
                            }
                          />
                          <ErrorMessage name="telephone" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm>
                          <StyledTextField
                            name="email"
                            variant="outlined"
                            label="Email"
                            autoComplete="nope"
                            fullWidth
                            required
                            size="small"
                            error={!!errors.email && touched.email}
                            defaultValue={values.email}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <ErrorMessage name="email" component={Error} />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm>
                          <NumberFormat
                            name="cep"
                            label="CEP"
                            variant="outlined"
                            placeholder="00000-000"
                            format="#####-###"
                            mask="_"
                            customInput={StyledTextField}
                            autoComplete="nope"
                            size="small"
                            fullWidth
                            required
                            error={!!errors.cep && touched.cep}
                            defaultValue={values.cep}
                            onValueChange={val =>
                              setFieldValue('cep', val.formattedValue || '')
                            }
                          />
                          <ErrorMessage name="cep" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm />
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm>
                          <StyledTextField
                            id="addressStreet"
                            name="addressStreet"
                            variant="outlined"
                            label="Rua"
                            autoComplete="nope"
                            size="small"
                            fullWidth
                            required
                            error={
                              !!errors.addressStreet && touched.addressStreet
                            }
                            defaultValue={values.addressStreet}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <ErrorMessage
                            name="addressStreet"
                            component={Error}
                          />
                        </Grid>
                        <Grid item xs={12} sm="auto">
                          <StyledTextField
                            id="addressNumber"
                            name="addressNumber"
                            variant="outlined"
                            label="Número"
                            autoComplete="nope"
                            size="small"
                            fullWidth
                            required
                            error={
                              !!errors.addressNumber && touched.addressNumber
                            }
                            defaultValue={values.addressNumber}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <ErrorMessage
                            name="addressNumber"
                            component={Error}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm>
                          <StyledTextField
                            name="neighborhood"
                            variant="outlined"
                            label="Bairro"
                            autoComplete="nope"
                            size="small"
                            fullWidth
                            required
                            error={
                              !!errors.neighborhood && touched.neighborhood
                            }
                            defaultValue={values.neighborhood}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <ErrorMessage name="neighborhood" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm>
                          <StyledTextField
                            name="city"
                            variant="outlined"
                            label="Cidade"
                            autoComplete="nope"
                            size="small"
                            fullWidth
                            required
                            error={!!errors.city && touched.city}
                            defaultValue={values.city}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                          <ErrorMessage name="city" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm="auto">
                          <Autocomplete
                            name="state"
                            size="small"
                            options={states.map(state => state.value)}
                            getOptionLabel={option => option}
                            defaultValue={values.state}
                            onChange={(e, value) => {
                              setFieldValue('state', value);
                            }}
                            renderInput={params => (
                              <StyledTextField
                                {...params}
                                name="state"
                                label="Estado"
                                variant="outlined"
                                fullWidth
                                required
                                error={!!errors.state && touched.state}
                              />
                            )}
                          />
                          <ErrorMessage name="state" component={Error} />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm>
                          <StyledTextField
                            name="complement"
                            variant="outlined"
                            label="Complemento"
                            autoComplete="nope"
                            fullWidth
                            size="small"
                            defaultValue={values.complement}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm>
                          <StyledTextField
                            name="obs"
                            variant="outlined"
                            label="Observação"
                            autoComplete="nope"
                            fullWidth
                            size="small"
                            defaultValue={values.obs}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />
                        </Grid>
                      </Grid>

                      <ContentSubmitButtons>
                        <StyledSubmitButton
                          variant="contained"
                          color={colors.white}
                          background={colors.success}
                          startIcon={<SaveIcon />}
                          type="submit"
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Alterando...' : 'Alterar'}
                        </StyledSubmitButton>
                        <StyledSubmitButton
                          color="primary"
                          variant="outlined"
                          type="button"
                          onClick={handleBack}
                        >
                          Cancelar
                        </StyledSubmitButton>
                      </ContentSubmitButtons>
                    </StyledPaper>
                  </ContentItem>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Container>
    </center>
  );
}

StakeholderEdit.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

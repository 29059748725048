import styled, { css } from 'styled-components';
import { TableCell, TableRow, styled as styledMUI } from '@material-ui/core';

export const Container = styled.div`
  padding: 20px;
  width: 100%;
`;

export const TableCellNoBg = styled(TableCell)`
  ${props =>
    props.empty &&
    css`
      min-width: 70px;
    `}

  border-left: 0;
  border-right: 0;
`

export const StyledTableCell = styled(TableCell)`
  ${props =>
    props.empty &&
    css`
      min-width: 70px;
    `}

  border-right: 1px solid rgba(224, 224, 224, 1);

  &:first-child {
    border-left: 1px solid rgba(224, 224, 224, 1);
  }

  &:last-child {
    border-left: none;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 50px;
  padding: 10px;

  img {
    height: 70px;
    width: 140px;
  }
`;

export const Column = styled.div``;

export const StyledTableCellNoBorderBottom = styled(TableCell)`
  border-bottom: none;
  border-right: 1px solid rgba(224, 224, 224, 1);
`;

export const StyledTableRow = styledMUI(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

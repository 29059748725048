import React, { useEffect, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import {
  Grid,
  TextField,
  Hidden,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';

// Query
import { useApiV2FilterAnimalOutput } from '~/hooks/apiV2/Report/animalOutput/useApiV2FilterAnimalOutput';

import {
  Container,
  StyledPaper,
  StyledButtonGenerator,
  StyledButton,
} from './styles';

import DatePicker from '~/components/DatePicker';

const filterOptions = createFilterOptions({
  matchFrom: 'start',
  stringify: option => option.value,
});
export default function Filter({
  setFilters
}) {
  const [date, setDate] = useState(new Date());
  const [selectedClient, setSelectedClient] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [selectedLot, setSelectedLot] = useState('all');

  // Query
  const { data } = useApiV2FilterAnimalOutput({ date });

  function cleanState() {
    setSelectedClient(null);
    setSelectedProvider(null);
    setSelectedLot(null);
  }

  async function handleGenarateReport() {
    setFilters({
      date,
      lot: selectedLot === 'all' ? null : selectedLot,
      client: selectedClient ? selectedClient.secure_id : '',
      provider: selectedProvider ? selectedProvider.secure_id : ''
    })
  }

  useEffect(() => {
    if (data) {
      cleanState();
    }
  }, [data]);

  return (
    <Container>
      <StyledPaper>
        <Grid container spacing={2} alignItems="center" justify="space-around">
          <Grid item xs={12} sm>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                label="Data"
                selectedDate={date}
                fullWidth
                style={{ margin: 0 }}
                maxDate={new Date()}
                handleChangeDate={currentDate => setDate(currentDate)}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12} sm>
            <Autocomplete
              size="small"
              noOptionsText="Sem opções"
              filterOptions={filterOptions}
              options={data ? data.optionsProviders : []}
              getOptionLabel={option => option.value}
              value={selectedProvider}
              onChange={(event, value) => setSelectedProvider(value)}
              renderInput={params => (
                <TextField {...params} label="Fornecedor" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} sm>
            <Autocomplete
              size="small"
              noOptionsText="Sem opções"
              filterOptions={filterOptions}
              options={data ? data.optionsCustomers : []}
              getOptionLabel={option => option.value}
              value={selectedClient}
              onChange={(event, value) => setSelectedClient(value)}
              renderInput={params => (
                <TextField {...params} label="Cliente" fullWidth />
              )}
            />
          </Grid>
        </Grid>
        {data && data.optionsLots.length > 0 && (
          <Grid
            container
            spacing={2}
            alignItems="flex-start"
            style={{ marginTop: 16 }}
          >
            <Grid item md={4} sm xs={12}>
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel id="select-lot-input-label">Lote</InputLabel>
                <Select
                  labelId="select-lot-label"
                  id="select-lot"
                  value={selectedLot}
                  labelWidth={35}
                  onChange={e => setSelectedLot(e.target.value)}
                >
                  <MenuItem value="all">Todos</MenuItem>
                  {data.optionsLots.map(lot => (
                    <MenuItem key={lot.secure_id} value={lot.secure_id}>
                      {lot.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Hidden only="xs">
            <Grid item sm lg md />
          </Hidden>

          <Grid item xs={12} sm="auto">
            <Hidden only="xs">
              <StyledButtonGenerator
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleGenarateReport}
              >
                Gerar
              </StyledButtonGenerator>
            </Hidden>

            <Hidden smUp>
              <StyledButton
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleGenarateReport}
              >
                Gerar
              </StyledButton>
            </Hidden>
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

Filter.propTypes = {
  setFilters: PropTypes.func.isRequired,
};

Filter.defaultProps = {
  lotsList: [],
};

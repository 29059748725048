/* eslint-disable react/prefer-stateless-function */
import {
  Table, TableBody,
  TableCell, TableRow, Typography
} from '@material-ui/core';
import { format } from 'date-fns';
import React, { Component } from 'react';
import logo from '~/assets/images/logo_black.png';
import {
  Column, Container, Header, StyledTableHead,
  StyledPaperTable,
  StyledTableContainer,
  StyledTextFieldForecast,
} from './styles';

export class PrintManufactoringDiet extends Component {
  render() {
    const {
      data,
      date,
    } = this.props;

    console.log(data)

    return (
      <Container>
        {!!data && data.map(diet => (
          <div key={diet.secure_id}>
            <Header>
              <img src={logo} alt="logo" />
              <Typography variant="h5">{diet.diet}</Typography>
              <Column>
                <Typography variant="body2">{`Data: ${!!date ? format(date, 'dd/MM/yyyy') : String(
                  format(new Date(), 'dd/MM/yyyy HH:mm:ss')
                )}`}</Typography>
              </Column>
            </Header>
            <StyledPaperTable>
              <StyledTableContainer>
                <Table stickyHeader>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell>Alimento</TableCell>
                      <TableCell align="right">Qtd. Prevista (Kg)</TableCell>
                      <TableCell align="right">Qtd. Realizada (Kg)</TableCell>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {diet.foods.map(food => (
                      <TableRow key={food.secure_id}>
                        <TableCell component="th" scope="row">
                          {food.name}
                        </TableCell>
                        <TableCell align="right">
                          <StyledTextFieldForecast
                            disabled
                            type="number"
                            value={food.forecast}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <StyledTextFieldForecast
                            disabled
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">Total</TableCell>
                      <TableCell align="right">
                        <StyledTextFieldForecast
                          disabled
                          type="number"
                          value={!!diet ? diet.performed : 0}
                        />
                      </TableCell>
                      <TableCell align="right">
                        <StyledTextFieldForecast
                          disabled
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </StyledTableContainer>
            </StyledPaperTable>
            {/* <h1>nioawfniofnawoinfioanfaiownoifanwio-------------------------fwaniofnwaoiawfnoi</h1> */}
            <p style={{ pageBreakAfter: 'always' }}>&nbsp;</p>
            {/* <h1>nioawfniofnawoinfioanfaiownoifanwiofwaniofnwaoiawfnoi</h1> */}
          </div>
        ))}
      </Container>
    )
  }
}

import {
  Backdrop, Divider, Fade,
  FormControl, Grid, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableRow
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useApiV2SelectPurchaseFood } from '~/hooks/apiV2/admin/purchaseFood/useApiV2SelectPurchaseFood';
import { formatNumber } from '~/utils/format';
import {
  CloseButton, Container, StyledGrid,
  StyledGridItem, StyledPaper, StyledTableCell, StyledTableHead,
  StyledTextField
} from './styles';

export function ModalPurchaseFoods({
  visibleModal,
  handleCloseModal,
  secure_id
}) {

  const { data: currentShoppingData, isLoading, error } = useApiV2SelectPurchaseFood(secure_id)

  useEffect(() => {
    console.log(currentShoppingData)
  }, [currentShoppingData])

  return (
    <Modal
      open={visibleModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visibleModal}>
        <Container>
          {!!currentShoppingData && (
            <StyledPaper>
              <StyledGrid container justify="space-between" alignItems="center">
                <StyledGridItem item sm={6} xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-diet-input-label">Fornecedor</InputLabel>
                    <Select
                      labelId="select-diet-label"
                      id="select-provider"
                      value={currentShoppingData.currentShopping.provider.secure_id}
                      disabled
                      labelWidth={40}
                    >
                      <MenuItem
                        id={String(currentShoppingData.currentShopping.provider.secure_id)}
                        value={currentShoppingData.currentShopping.provider.secure_id}
                      >
                        {currentShoppingData.currentShopping.provider.name}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
                <StyledGridItem item sm={5} xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-date-input-label">Data</InputLabel>
                    <Select
                      labelId="select-date-label"
                      id="select-date"
                      value={currentShoppingData.currentShopping}
                      disabled
                      labelWidth={35}
                    >
                      <MenuItem
                        id={String(currentShoppingData.currentShopping.secure_id)}
                        value={currentShoppingData.currentShopping}
                      >
                        {format(
                          new Date(currentShoppingData.currentShopping.date),
                          'dd/MM/yyyy'
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
              </StyledGrid>

              <Divider light />

              <StyledGrid container justify="space-between" alignItems="center">
                <TableContainer>
                  <Table stickyHeader>
                    <StyledTableHead>
                      <TableRow>
                        <TableCell>Alimento</TableCell>
                        <TableCell align="right">Preço (ton)</TableCell>
                        <TableCell align="right">
                          Qtd/Ton
                        </TableCell>
                        <TableCell align="right">
                          Total
                        </TableCell>
                      </TableRow>
                    </StyledTableHead>
                    {currentShoppingData.currentShopping.purchaseFoodItems.map(food => (
                      <TableRow key={food.secure_id}>
                        <TableCell component="th" scope="row">
                          {food.food.name}
                        </TableCell>
                        <TableCell align="right">
                          <StyledTextField
                            disabled
                            value={`R$ ${food.price_ton}`}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <StyledTextField
                            disabled
                            type="number"
                            value={food.amount_ton}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <StyledTextField
                            disabled
                            value={`R$ ${formatNumber((food.price_ton * food.amount_ton), 2, 2)}`}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </TableContainer>
                <StyledGrid container justify="space-between" alignItems="center">
                  <StyledGridItem item sm={3} xs={12}>
                    <FormControl size="small" fullWidth variant="outlined">
                      <InputLabel id="select-diet-input-label">
                        Total do Frete
                      </InputLabel>
                      <Select
                        labelId="select-diet-label"
                        id="select-provider"
                        value={currentShoppingData.currentShopping}
                        disabled
                        labelWidth={40}
                      >
                        <MenuItem
                          id={String(currentShoppingData.currentShopping)}
                          value={currentShoppingData.currentShopping}
                        >
                          {formatNumber(currentShoppingData.currentShopping.total_freight, 0, 0)}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </StyledGridItem>
                  <StyledGridItem item sm={3} xs={12}>
                    <FormControl size="small" fullWidth variant="outlined">
                      <InputLabel id="select-diet-input-label">
                        Total dos produtos
                      </InputLabel>
                      <Select
                        labelId="select-diet-label"
                        id="select-provider"
                        value={currentShoppingData.currentShopping}
                        disabled
                        labelWidth={40}
                      >
                        <MenuItem
                          id={String(currentShoppingData.currentShopping)}
                          value={currentShoppingData.currentShopping}
                        >
                          {`R$ ${formatNumber(currentShoppingData.currentShopping.purchaseFoodItems.reduce((total, food) => {
                            return (
                              total + food.amount_ton * food.price_ton
                            );
                          }, 0), 2, 2)}`}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </StyledGridItem>
                  <StyledGridItem item sm={3} xs={12}>
                    <FormControl size="small" fullWidth variant="outlined">
                      <InputLabel id="select-diet-input-label">
                        Total do pedido
                      </InputLabel>
                      <Select
                        labelId="select-diet-label"
                        id="select-provider"
                        value={currentShoppingData.currentShopping}
                        disabled
                        labelWidth={40}
                      >
                        <MenuItem
                          id={String(currentShoppingData.currentShopping)}
                          value={currentShoppingData.currentShopping}
                        >
                          {`R$ ${formatNumber(
                            currentShoppingData.currentShopping.total_freight +
                            currentShoppingData.currentShopping.purchaseFoodItems.reduce((total, food) => {
                              return (
                                total + food.amount_ton * food.price_ton
                              );
                            }, 0), 2, 2)}`}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </StyledGridItem>
                </StyledGrid>
              </StyledGrid>
              <StyledTextField
                id="obs"
                name="obs"
                variant="outlined"
                label="Observação"
                autoComplete="nope"
                fullWidth
                value={currentShoppingData.currentShopping.obs}
                disabled
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </StyledPaper>
          )}
          <CloseButton stype="button" onClick={handleCloseModal}>
            <CancelIcon fontSize="large" variant="outlined" />
          </CloseButton>
        </Container>
      </Fade>
    </Modal>
  );
}

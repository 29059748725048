import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';
import { queryClient } from '~/services/queryClient';

export function useApiV2RemoveCauseOfDeath() {
  return useMutation(
    async (secure_id) => {
      await apiV2.delete(`/v1/feedlot/register/causeOfDeath/${secure_id}`)
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries('causeOfDeaths');
        toast.success('A causa da morte foi apagada com sucesso!')
      },
      onError: (error) => {
        const type = error.response.data?.type ? error.response.data.type : 'error'
        const message = error.response.data?.message ? error.response.data.message : 'Falha ao tentar apagar a causa da morte.'
        toast[type](message)
      }
    }
  );
}

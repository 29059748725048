import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, InputLabel, Select, MenuItem } from '@material-ui/core';

// Query
import { useV2GlobalDiets } from '~/hooks/apiV2/global/diet/useV2GlobalDiets';
import { useApiV2GlobalPaddock } from '~/hooks/apiV2/global/paddock/useApiV2GlobalPaddock';

import {
  Content,
  StyledPaper,
  StyledGridItem,
  StyledFormControl,
} from './styles';

import { formatNamePaddock } from '~/utils/format';

const dietDefaultOption = {
  secure_id: null,
  name: 'Todas',
};

const paddockDefaultOption = {
  secure_id: null,
  name: 'Todos',
};

export default function FilterDietSwitch({
  setCurrentDiet,
  setCurrentPaddock,
  settings
}) {
  const [diets, setDiets] = useState([]);
  const [paddocks, setPaddocks] = useState([]);

  // Query
  const { data: dietsData } = useV2GlobalDiets();
  const { data: paddocksData } = useApiV2GlobalPaddock();

  function handleChangeDiet(event) {
    setCurrentDiet(event.target.value.value);
  }

  function handleChangePaddock(event) {
    setCurrentPaddock(event.target.value.secure_id);
  }

  useEffect(() => {
    if (paddocksData) {
      setPaddocks(paddocksData.paddocks);
    }

    if (dietsData) {
      setDiets(dietsData.diets);
    }
  }, [dietsData, paddocksData]);

  return (
    <Content>
      <StyledPaper>
        <Grid container alignItems="center" justify="space-between">
          <StyledGridItem item xl lg md sm xs={12}>
            <StyledFormControl size="small" fullWidth>
              <InputLabel id="input-diet">Dieta Atual</InputLabel>
              <Select
                id="currentDiet"
                defaultValue={dietDefaultOption}
                onChange={handleChangeDiet}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value={dietDefaultOption}>
                  {dietDefaultOption.name}
                </MenuItem>
                {diets.map(diet => (
                  <MenuItem key={diet.value} value={diet}>
                    {diet.label}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </StyledGridItem>

          <StyledGridItem item xl lg md sm xs={12}>
            <StyledFormControl size="small" fullWidth>
              <InputLabel id="input-lots">Piquete</InputLabel>
              <Select
                id="currentPaddock"
                defaultValue={paddockDefaultOption}
                onChange={handleChangePaddock}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
              >
                <MenuItem value={paddockDefaultOption}>
                  {paddockDefaultOption.name}
                </MenuItem>
                {paddocks && paddocks.map(paddock => (
                  <MenuItem key={paddock.secure_id} value={paddock}>
                    {formatNamePaddock({ visible_module: 1, visible_line: 1, visible_paddock: 1 }, paddock)}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </StyledGridItem>
        </Grid>
      </StyledPaper>
    </Content>
  );
}

FilterDietSwitch.propTypes = {
  setCurrentDiet: PropTypes.func.isRequired,
  setCurrentPaddock: PropTypes.func.isRequired,
};

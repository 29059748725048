import React, { useEffect, useState } from 'react';
import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import ClearIcon from '@material-ui/icons/Clear';

// Query
import { useApiV2GlobalBreeds } from '~/hooks/apiV2/global/breed/useApiV2GlobalBreeds'

import {
  Content,
  StyledTablePaper,
  ContentDate,
  StyledGridItem,
  StyledIconButton,
} from './styles';

import { regexDate } from '~/utils/common';
import InputDatePicker from '~/components/InputDatePicker';

const breedDefaultOption = {
  id: null,
  name: 'Todas',
};

export default function Filter({
  currentDate,
  setCurrentDate,
  setCurrentBreed,
}) {
  const [breeds, setBreeds] = useState([]);

  const { data: breedsData } = useApiV2GlobalBreeds();

  useEffect(() => {
    if (breedsData) {
      setBreeds(breedsData.breeds);
    }
  }, [breedsData]);

  function handleChangeDate(date, value) {
    if (value && !regexDate.test(value)) return;
    setCurrentDate(date);
  }

  function handleChangeBreed(event) {
    if (event.target.value.id === null) {
      setCurrentBreed(null);
    } else {
      setCurrentBreed(event.target.value);
    }
  }

  return (
    <Content>
      <StyledTablePaper>
        <Grid container justify="space-around" spacing={2}>
          <Grid item xs={12} sm style={{ marginTop: 11 }}>
            <ContentDate>
              <InputDatePicker
                label="Data Inicial"
                maxDate={new Date()}
                selectedDate={currentDate}
                handleChangeDate={(date, value) =>
                  handleChangeDate(date, value)
                }
                InputProps={{
                  startAdornment: (
                    <StyledIconButton onClick={() => handleChangeDate(null)}>
                      <ClearIcon />
                    </StyledIconButton>
                  ),
                }}
                InputAdornmentProps={{
                  position: 'end',
                }}
              />
            </ContentDate>
          </Grid>
          <StyledGridItem item xs={12} sm style={{ marginTop: 11 }}>
            <FormControl size="small" fullWidth>
              <InputLabel id="input-diet">Raça</InputLabel>
              <Select
                id="diet"
                defaultValue={breedDefaultOption}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                }}
                onChange={handleChangeBreed}
              >
                <MenuItem value={breedDefaultOption}>
                  {breedDefaultOption.name}
                </MenuItem>
                {breeds.map(item => (
                  <MenuItem key={item.secure_id} value={item.secure_id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </StyledGridItem>
        </Grid>
      </StyledTablePaper>
    </Content>
  );
}

Filter.propTypes = {
  currentDate: PropTypes.string,
  setCurrentDate: PropTypes.func.isRequired,
  setCurrentBreed: PropTypes.func.isRequired,
  setIdentification: PropTypes.func.isRequired,
  identification: PropTypes.string,
};

Filter.defaultProps = {
  currentDate: null,
  identification: '',
};

import React from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@material-ui/icons/Edit';

import { Content, StyledAddButton } from './styles';

export default function ButtonEdit({ title, onClick }) {
  return (
    <Content>
      <StyledAddButton
        variant="contained"
        startIcon={<EditIcon />}
        onClick={onClick}
      >
        {title}
      </StyledAddButton>
    </Content>
  );
}

ButtonEdit.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

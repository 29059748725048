import styled from 'styled-components';
import { Paper, TableHead, Typography } from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 50px;
  padding: 10px;

  img {
    height: 70px;
    width: 140px;
  }
`;

export const Column = styled.div``;

export const StyledTablePaper = styled(Paper)`
  width: 100%;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const StyledTablePaperMix = styled(Paper)`
  width: 100%;
  margin-top: 32px;
`;

export const Title = styled(Typography)`
  font-size: 18px;
  font-weight: 400;
  text-align: center;

  padding: 18px 6px 8px;
`;

export const TitleTable = styled.b`
  color: ${colors.primary};
`;

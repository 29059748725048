import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import {
  FormControlLabel,
  Grid,
  Switch,
  Button,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Chart from 'react-apexcharts';

//Query
import { useApiV2EfficiencyTreatment } from '~/hooks/apiV2/Report/efficiencyTreatment/useApiV2EfficiencyTreatment';

import Print from './Print'

import {
  Container,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  ContentPrint
} from './styles';

import Filter from './Components/Filter';

import colors from '~/styles/colors';
import Loader from '~/components/Loader';

export default function EfficiencyTreatment() {
  const [series, setSeries] = useState([]);
  const [days, setDays] = useState([]);
  const [showLabel, setShowLabel] = useState(false);

  const [filters, setFilters] = useState({
    initialDate: new Date(),
    finalDate: new Date(),
    paddock: ''
  });

  const PrintScreen = useRef();

  //Query
  const { data, isLoading } = useApiV2EfficiencyTreatment(filters);

  function handleChangeLabel() {
    setShowLabel(!showLabel);
  }

  useEffect(() => {
    if (data && data.length > 0) {
      const newDays = data.map(item => item.date);
      const performed = data.map(item => item.total_performed);
      const forecast = data.map(item => item.total_forecast);

      setDays(newDays);
      setSeries([
        {
          name: 'Total Realizado',
          type: 'column',
          data: performed,
        },
        {
          name: 'Total Previsto',
          type: 'line',
          data: forecast,
        },
      ]);
    } else {
      setDays([]);
      setSeries([]);
    }
  }, [data]);

  const options = {
    chart: {
      toolbar: {
        tools: {
          zoomin: false,
          zoomout: false,
          zoom: false,
          selection: true,
          reset: false,
          pan: false,
          download: true,
        },
      },
    },
    noData: {
      text: 'Sem dados',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: 0,
      style: {
        color: colors.grey,
        fontSize: '32px',
        fontFamily: 'Roboto',
      },
    },
    stroke: {
      width: [0, 4],
    },
    colors: [colors.chartSecondary, colors.chartPrimary],
    dataLabels: {
      enabled: showLabel,
      enabledOnSeries: [1],
    },
    labels: days,
    yaxis: [
      {
        title: {
          text: 'Total (Kg)',
        },
      },
    ],
  };

  return (
    <>
      <center>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs sm md lg xl>
              <Filter
                setFilters={setFilters}
              />
            </Grid>
            <Grid item xs="auto" sm="auto">
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={data && data.length === 0}
                    startIcon={<PrintIcon />}
                  >
                    Imprimir
                  </Button>
                )}
                content={() => PrintScreen.current}
              />
              <div style={{ marginTop: 10 }}>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Exportar"
                  style={{
                    padding: 0,
                  }}
                />
              </div>
            </Grid>
          </Grid>


          <Grid container justify="space-between" alignItems="center">
            <FormControlLabel
              value={showLabel}
              label="Rótulos"
              control={<Switch color="primary" />}
              labelPlacement="end"
              onChange={handleChangeLabel}
            />
          </Grid>

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <StyledPaper>
                <Chart
                  options={options}
                  series={series}
                  type="line"
                  height="600"
                />
              </StyledPaper>

              <StyledPaper>
                <StyledTableContainer>
                  <Table stickyHeader id="table-to-xls">
                    <StyledTableHead>
                      <TableRow>
                        <TableCell>Data</TableCell>
                        <TableCell>Dieta</TableCell>
                        <TableCell align="right">Qtd.Previsto</TableCell>
                        <TableCell align="right">Qtd. Realizado</TableCell>
                        <TableCell align="right">Desvio (%)</TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <TableBody>
                      {data && data.map(efficency => (
                        <TableRow key={efficency.secure_id}>
                          <TableCell component="th" scope="row">
                            {efficency.date}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {`${efficency.diet && efficency.diet.name}`}
                          </TableCell>
                          <TableCell align="right">
                            {efficency.total_forecast}
                          </TableCell>
                          <TableCell align="right">
                            {efficency.total_performed}
                          </TableCell>
                          <TableCell align="right">
                            {`${efficency.deviation} %`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </StyledTableContainer>
              </StyledPaper>
            </>
          )}
        </Container>
      </center>
      <ContentPrint>
        {data && (
          <Print
            ref={PrintScreen}
            data={data && data}
            initialDate={filters.initialDate}
            finalDate={filters.finalDate}
            series={series}
            days={days}
          />
        )}
      </ContentPrint>
    </>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  max-width: 600px;
  width: 80%;
  border-radius: 5px;
  overflow: hideen;
`;

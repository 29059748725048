/* eslint-disable array-callback-return */
import React, { useMemo } from 'react';

import { Formik, Form, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// MUI
import {
  Grid,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
} from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';

// Local

import colors from '~/styles/colors';
import history from '~/services/history';
import Loader from '~/components/Loader';

// Styled Components
import {
  Container,
  StyledPaper,
  StyledGrid,
  ContentItem,
  ContentSubmitButtons,
  StyledSubmitButton,
  Error,
} from './styles';
import { useApiV2SelectUser } from '~/hooks/apiV2/register/user/useApiV2SelectUser';
import { useApiV2Permissions } from '~/hooks/apiV2/register/user/useApiV2Permissions';
import { useApiV2AddUser } from '~/hooks/apiV2/register/user/useApiV2AddUser';
import { useApiV2EditUser } from '~/hooks/apiV2/register/user/useApiV2EditUser';

export default function User({ match }) {
  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  const { data: user, isLoading } = useApiV2SelectUser(secure_id);
  const { data: listPermissions } = useApiV2Permissions();
  const createUser = useApiV2AddUser();
  const updateUser = useApiV2EditUser();

  const permissions = user && user.permissions.map(p => p.slug);

  const schema = Yup.object().shape({
    name: Yup.string().required('Digite o nome'),
    email: Yup.string().required('Digite o email'),
    valid: Yup.string(),
    password: Yup.string().when('valid', {
      is: () => !secure_id,
      then: Yup.string()
        .required('Senha obrigatória')
        .min(6, 'Necessário pelo menos 6 caracteres'),
    }),
    passwordConfirmation: Yup.string().when('valid', {
      is: () => !secure_id,
      then: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Confirmação diferente da senha')
        .required('Necessário confirmar sua senha')
        .min(6, 'Necessário pelo menos 6 caracteres'),
    }),
  });

  async function handleSubmit(formData) {
    if (secure_id) {
      await updateUser.mutateAsync({ secure_id, ...formData });
    } else {
      await createUser.mutateAsync(formData);
    }
  }

  function handleBack() {
    history.goBack();
  }

  return (
    <center>
      <Container>
        {secure_id && isLoading && !user ? (
          <Loader />
        ) : (
          <>
            <ContentItem>
              <StyledPaper>
                <Formik
                  validationSchema={schema}
                  enableReinitialize
                  initialValues={{
                    name: (user && user.name) || '',
                    email: (user && user.email) || '',
                    permissions: permissions || [],
                  }}
                  onSubmit={handleSubmit}
                >
                  {({
                    setFieldValue,
                    values,
                    errors,
                    touched,
                    handleChange,
                    isSubmitting,
                  }) => (
                    <Form>
                      <StyledGrid container spacing={2} justify="space-around">
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="name"
                            name="name"
                            variant="outlined"
                            label="Nome Completo"
                            autoComplete="nope"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            size="small"
                            fullWidth
                            required
                            autoFocus
                            value={values.name}
                            error={touched.name && errors.name}
                            disabled={secure_id}
                            onChange={e =>
                              setFieldValue('name', e.target.value)
                            }
                          />
                          <ErrorMessage name="name" component={Error} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextField
                            id="email"
                            name="email"
                            type="email"
                            InputProps={{
                              form: {
                                autocomplete: 'off',
                              },
                            }}
                            variant="outlined"
                            label="Email"
                            autoComplete="nope"
                            size="small"
                            value={values.email}
                            error={touched.email && errors.email}
                            fullWidth
                            disabled={secure_id}
                            onChange={e =>
                              setFieldValue('email', e.target.value)
                            }
                          />
                          <ErrorMessage name="email" component={Error} />
                        </Grid>
                      </StyledGrid>
                      {!secure_id && (
                        <StyledGrid
                          container
                          spacing={2}
                          justify="space-around"
                        >
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="password"
                              name="password"
                              type="password"
                              InputProps={{
                                form: {
                                  autocomplete: 'off',
                                },
                              }}
                              variant="outlined"
                              label="Senha"
                              autoComplete="nope"
                              size="small"
                              value={values.password}
                              error={touched.password && errors.password}
                              fullWidth
                              onChange={e =>
                                setFieldValue('password', e.target.value)
                              }
                            />
                            <ErrorMessage name="password" component={Error} />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              id="passwordConfirmation"
                              name="passwordConfirmation"
                              type="password"
                              InputProps={{
                                form: {
                                  autocomplete: 'off',
                                },
                              }}
                              variant="outlined"
                              label="Confirme a Senha"
                              autoComplete="nope"
                              size="small"
                              value={values.passwordConfirmation}
                              error={
                                touched.passwordConfirmation &&
                                errors.passwordConfirmation
                              }
                              fullWidth
                              onChange={e =>
                                setFieldValue(
                                  'passwordConfirmation',
                                  e.target.value
                                )
                              }
                            />
                            <ErrorMessage
                              name="passwordConfirmation"
                              component={Error}
                            />
                          </Grid>
                        </StyledGrid>
                      )}
                      <h2>Permissões</h2>
                      <StyledGrid container spacing={3} justify="space-around">
                        {listPermissions &&
                          listPermissions.map(groupPermissions => (
                            <Grid key={groupPermissions.slug} item xs={12} sm={6}>
                              <h3>
                                {
                                  groupPermissions[0].name.split(
                                    'Visualizar '
                                  )[1]
                                }
                              </h3>
                              <Box borderColor="#ccc" border={1} padding={1}>
                                {groupPermissions.map(permission => (
                                  <FormControlLabel
                                    key={permission.slug}
                                    control={
                                      <Checkbox
                                        defaultChecked={
                                          user
                                            ? user.permissions.some(
                                              p => p.slug === permission.slug
                                            )
                                            : false
                                        }
                                        value={permission.slug}
                                        onChange={handleChange}
                                        name="permissions"
                                      />
                                    }
                                    label={permission.name}
                                  />
                                ))}
                              </Box>
                            </Grid>
                          ))}
                      </StyledGrid>

                      <ContentSubmitButtons>
                        {!secure_id ? (
                          <StyledSubmitButton
                            variant="contained"
                            type="submit"
                            color={colors.white}
                            background={colors.success}
                            startIcon={<SaveIcon />}
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? 'Cadastrando...' : 'Cadastrar'}
                          </StyledSubmitButton>
                        ) : (
                          <StyledSubmitButton
                            variant="contained"
                            type="submit"
                            color={colors.white}
                            background={colors.success}
                            startIcon={<SaveIcon />}
                            disabled={isSubmitting}
                          >
                            {isSubmitting ? 'Alterando...' : 'Alterar'}
                          </StyledSubmitButton>
                        )}
                        <StyledSubmitButton
                          variant="outlined"
                          color="primary"
                          onClick={handleBack}
                        >
                          Cancelar
                        </StyledSubmitButton>
                      </ContentSubmitButtons>
                    </Form>
                  )}
                </Formik>
              </StyledPaper>
            </ContentItem>
          </>
        )}
      </Container>
    </center>
  );
}

User.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

import React, { useState } from 'react';
import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

import {
  Container,
  StyledGrid,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableCell,
  ContentButton,
  StyledAddButton,
} from './styles';

import history from '~/services/history';
import Can from '~/components/Can';
import Loader from '~/components/Loader';

import Filter from './Components/Filter';
import { useApiV2Stakeholders } from '~/hooks/apiV2/register/stakeholder/useApiV2Stakeholder';
import { CardTableStakeholder } from './Components/CardTableStakeholder';

export default function Stakeholder() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('name');
  const [direction, setDirection] = useState('asc');
  const [type, setType] = useState('all');

  const { data, isLoading } = useApiV2Stakeholders(type, `${field}%7C${direction}`, page + 1, rowsPerPage)

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    if (field === fieldOrder) {
      if (direction === 'asc') {
        setDirection('desc');
      } else {
        setDirection('asc');
      }
    } else {
      setField(fieldOrder);
      setDirection('asc');
    }
  }

  function handleAdd() {
    history.push('/dashboard/stakeholder/add');
  }

  return (
    <center>
      <Container>
        <StyledGrid container justify="space-between" spacing={1}>
          <Grid item xs={12} sm md lg xl>
            <Filter setType={setType} />
          </Grid>

          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['stakeholder_new']}
          >
            <Grid item xs="auto" sm="auto">
              <ContentButton>
                <StyledAddButton
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={handleAdd}
                >
                  Novo
                </StyledAddButton>
              </ContentButton>
            </Grid>
          </Can>
        </StyledGrid>

        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <StyledTableContainer>
              <Table stickyHeader>
                <StyledTableHead>
                  <TableRow>
                    <TableCell
                      sortDirection={field === 'name' ? direction : false}
                    >
                      <TableSortLabel
                        active={field === 'name'}
                        direction={field === 'name' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('name')}
                      >
                        Nome
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={
                        field === 'fantasy_name' ? direction : false
                      }
                    >
                      <TableSortLabel
                        active={field === 'fantasy_name'}
                        direction={field === 'fantasy_name' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('fantasy_name')}
                      >
                        Nome Fantasia
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={field === 'doc' ? direction : false}
                    >
                      <TableSortLabel
                        active={field === 'doc'}
                        direction={field === 'doc' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('doc')}
                      >
                        CNPJ/CPF
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={field === 'telephone' ? direction : false}
                    >
                      <TableSortLabel
                        active={field === 'telephone'}
                        direction={field === 'telephone' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('telephone')}
                      >
                        Telefone
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={field === 'email' ? direction : false}
                    >
                      <TableSortLabel
                        active={field === 'email'}
                        direction={field === 'email' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('email')}
                      >
                        Email
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={
                        field === 'contact_name' ? direction : false
                      }
                    >
                      <TableSortLabel
                        active={field === 'contact_name'}
                        direction={field === 'contact_name' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('contact_name')}
                      >
                        Contato
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sortDirection={field === 'type' ? direction : false}
                    >
                      <TableSortLabel
                        active={field === 'type'}
                        direction={field === 'type' ? direction : 'asc'}
                        onClick={() => handleChangeOrder('type')}
                      >
                        Tipo
                      </TableSortLabel>
                    </TableCell>
                    <Can checkRole={['administrator', 'owner', 'manager']}>
                      <StyledTableCell align="right" colSpan={2} />
                    </Can>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {data && data.stakeholders
                    .map(stakeholder => (
                      <CardTableStakeholder
                        key={stakeholder.secure_id}
                        stakeholder={stakeholder}
                      />
                    ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              labelRowsPerPage="Linhas por pág."
              component="div"
              count={data ? data.total : 0}
              rowsPerPage={data ? data.perPage : 0}
              page={data ? data.page - 1 : 0}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

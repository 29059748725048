import { useQuery } from "react-query";
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';

async function getV2GlobalEmployees() {
  try {
    const { data } = await apiV2.get(`v1/feedlot/global/list/employee`)
    const employee = data.map(diet => {
      return {
        label: diet.name,
        value: diet.secure_id,
        function: diet.function
      }
    })
    return employee
  } catch {
    toast.error('Falha ao tentar carregar as dietas!');
  }
}

export function useV2GlobalEmployees() {
  return useQuery(
    ['V2GlobalEmployees',],
    () => getV2GlobalEmployees()
  )
}

import React from 'react';
import Swal from 'sweetalert2';
import {
  TableRow,
  TableCell,
  Fade,
  Tooltip
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';

// Query
import { useApiV2RemoveIndividualTransferApartLot } from '~/hooks/apiV2/animalHandling/IndividualTransferApartLot/useApiV2RemoveIndividualTransferApartLot';

import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell
} from './styles';
import ButtonTable from '~/components/ButtonTable';
import ActionLoader from '~/components/ActionLoader';
import { formatNamePaddock } from '~/utils/format';

export function CardAnimalInputLotApart({ animal, settings }) {
  const removeIndividualTransferApartLot = useApiV2RemoveIndividualTransferApartLot();

  function handleSelectAnimalInput({ secure_id }) {
    history.push(`/dashboard/animalInputApart/view/${secure_id}`);
  }

  function handleRemoveAnimalInput(secure_id) {
    async function performRemoveIndividualTransferApartLot() {
      removeIndividualTransferApartLot.mutateAsync(secure_id)
    }

    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar essa entrada?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar entrada!',
    }).then(async result => {
      if (result.value) {
        performRemoveIndividualTransferApartLot();
      }
    });
  }

  return (
    <TableRow key={animal.id}>
      <TableCell component="th" scope="row">
        {animal.apart}
      </TableCell>
      <TableCell align="left">
        {animal.date}
      </TableCell>
      <TableCell align="left">
        {`${animal.lot.code} - ${formatNamePaddock(settings, animal.paddock)}`}
      </TableCell>
      <TableCell align="right">
        {animal.amount_animals}
      </TableCell>
      <Can
        checkRole={['administrator', 'owner', 'beefer']}
        checkPermission={['animalInput_view', 'animalInput_delete']}
      >
        <StyledTableCell align="right" colSpan={1}>
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['animalInput_view']}
          >
            <ButtonTable
              icon={<VisibilityIcon />}
              background={colors.dark}
              lighten={0.5}
              onClick={() =>
                handleSelectAnimalInput(animal)
              }
            />
          </Can>
          <Can
            checkRole={['administrator', 'owner', 'beefer']}
            checkPermission={['animalInput_delete']}
          >
            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title="Já existe tratamento realizado para este lote"
              disableHoverListener={
                !(
                  animal.meta.has_treatment_lots > 0
                )
              }
            >
              <span>
                <ButtonTable
                  icon={
                    removeIndividualTransferApartLot.isLoading ? (
                      <ActionLoader text="Deletando" />
                    ) : (
                      <DeleteIcon
                        color={
                          animal.meta.has_treatment_lots > 0
                            ? 'disabled'
                            : 'error'
                        }
                      />
                    )
                  }
                  background={colors.error}
                  onClick={() =>
                    handleRemoveAnimalInput(
                      animal.secure_id,
                      animal
                    )
                  }
                  disabled={
                    animal.meta.has_treatment_lots > 0
                  }
                />
              </span>
            </Tooltip>
          </Can>
        </StyledTableCell>
      </Can>
    </TableRow>
  )
}

import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";

export async function getConsumptionLot({
  initialDate = new Date(), finalDate = new Date(), modules, lines, paddocks, lots
}) {
  const { data } = await apiV2.get('/v1/feedlot/report/consumptionLot', {
    params: { initialDate, finalDate, modules, lines, paddocks, lots }
  });

  return data
}

export function useApiV2ConsumptionLot({ initialDate, finalDate, modules, lines, paddocks, lots }) {
  return useQuery(
    ['consumptionLot', { initialDate, finalDate, modules, lines, paddocks, lots }],
    () => getConsumptionLot({ initialDate, finalDate, modules, lines, paddocks, lots }),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  );
}

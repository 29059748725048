/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
} from '@material-ui/core';
import { format } from 'date-fns';

import {
  Container,
  StyledTableCell,
  Header,
  Column,
  StyledTableCellNoBorderBottom,
  StyledTableRow,
  TableCellNoBg
} from './styles';

import logo from '~/assets/images/logo_black.png';
import { formatNamePaddock } from '~/utils/format';

export default class Print extends Component {
  render() {
    const {
      diet,
      date,
      treatments,
      treaties,
      totalTreaties,
      settings,
      totalsForecast
    } = this.props;

    return (
      <Container>
        <Header>
          <img src={logo} alt="logo" />
          <Typography variant="h5">Ficha de Tratos</Typography>
          <Column>
            <Typography variant="subtitle2">{`Dieta: ${
              diet ? diet.name : 'Todas'
            }`}</Typography>
            <Typography variant="body2">{`Data: ${String(
              format(date, "dd/MM/yyyy 'às' HH:mm'h'")
            )}`}</Typography>
          </Column>
        </Header>

        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>Piquete</StyledTableCell>
                {treaties.map(treaty => (
                  <>
                    {treaty.selected ? (
                      <>
                        <StyledTableCell key={treaty.secure_id} align="right">
                          {treaty.name}
                        </StyledTableCell>
                        <StyledTableCell align="right" empty />
                      </>
                    ) : null}
                  </>
                ))}
                <TableCellNoBg empty />
                <StyledTableCell align="left">Total Previsto</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {treatments.map(treatment => (
                <StyledTableRow key={treatment.secure_id}>
                  <StyledTableCell component="th" scope="row">
                    {formatNamePaddock(settings, treatment.paddock)}
                  </StyledTableCell>
                  {treatment.treatyTreatments.map(treaty => (
                    <>
                      {treaty.selected ? (
                        <>
                          <StyledTableCell key={treaty.treaty.secure_id} align="right">
                            {treaty.forecast}
                          </StyledTableCell>
                          <StyledTableCell align="right" empty>
                            {treaty.performed}
                          </StyledTableCell>
                        </>
                      ) : null}
                    </>
                  ))}
                  <TableCellNoBg empty />
                  <StyledTableCell align="left">
                    {treatment.total_forecast}
                  </StyledTableCell>
                </StyledTableRow>
              ))}

              <TableRow>
                <StyledTableCellNoBorderBottom />
                {totalTreaties.map(treaty => (
                  <>
                    <StyledTableCell align="right">
                      {treaty.totalForecast}
                    </StyledTableCell>
                    <StyledTableCell align="right" empty>
                      {treaty.totalPerformed || ''}
                    </StyledTableCell>
                  </>
                ))}
                <TableCellNoBg empty />
                <StyledTableCell align="left" empty>
                  {totalsForecast && totalsForecast || ''}
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    );
  }
}

Print.propTypes = {
  diet: PropTypes.shape({
    secure_id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  date: PropTypes.string.isRequired,
  treatments: PropTypes.arrayOf(
    PropTypes.shape({
      secure_id: PropTypes.number,
      total_forecast: PropTypes.number,
      date: PropTypes.string,
      module: PropTypes.shape({
        name: PropTypes.string,
      }),
      line: PropTypes.shape({
        name: PropTypes.string,
      }),
      paddock: PropTypes.shape({
        name: PropTypes.string,
      }),
      diet: PropTypes.shape({
        name: PropTypes.string,
      }),
    })
  ).isRequired,
  treaties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalTreaties: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  settings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  totalsForecast: PropTypes.number.isRequired,
};

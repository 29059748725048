import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2";
import { formatDateString } from "~/utils/format";

export async function getData(sort, page = 1, limit = 50, food, date) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/food-management/msCorrection?sort=${sort}`, {
      params: { page, limit, food, date }
    });

    const newData = data.data.map(ms => ({
      ...ms,
      date: formatDateString(ms.date),
    }));

    return {
      msCorrections: newData,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    };
  } catch {
    toast.error('Não foi possível carregar os dados!');
  }
}

export function useApiV2MsCorrection(sort, page, limit, food, date) {
  return useQuery(['msCorrection', sort, page, limit, food, date],
    () => getData(sort, page, limit, food, date));
}

import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';

import { Container, Content, Wrapper, Row, ContentTitle, Logo } from './styles';

import logo from '~/assets/images/logo_black.png';

export default function HeaderPrint({ title, filters }) {
  return (
    <Container>
      <Content>
        <Wrapper>
          <ContentTitle>
            <h4>{title}</h4>
          </ContentTitle>
          <div>
            {filters.map(filter => (
              <Row key={filter}>
                <ContentTitle>
                  <h5>{filter.split(':')[0]}:</h5>
                  <h5>
                    {filter.substr(filter.split(':')[0].length, filter.length)}
                  </h5>
                </ContentTitle>
              </Row>
            ))}
          </div>
        </Wrapper>
        <Logo>
          <img src={logo} alt="logo" />
        </Logo>
      </Content>
      <Divider light />
    </Container>
  );
}

HeaderPrint.defaultProps = {
  filters: [],
};

HeaderPrint.propTypes = {
  title: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(PropTypes.string),
};

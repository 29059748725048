import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";

export async function getHistoricFood({ page = 1, limit = 50, initialDate = new Date(), finalDate = new Date(), food }) {
  const { data } = await apiV2.get('/v1/feedlot/report/historicFood', {
    params: { page, limit, initialDate, finalDate, food }
  });

  const list = data.data.map(hist => ({
    ...hist,
    price_ton_freight:
      hist.amount_ton > 0
        ? hist.price_ton + hist.freight / hist.amount_ton
        : 0,
  }));

  return {
    historic: list,
    total: data.meta.total,
    perPage: data.meta.per_page,
    page: data.meta.current_page,
    lastPage: data.meta.last_page,
  }
}

export function useApiV2HistoricFood({ page, limit, initialDate, finalDate, food }) {
  return useQuery(['historicFood', { page, limit, initialDate, finalDate, food }],
    () => getHistoricFood({ page, limit, initialDate, finalDate, food }));
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, TableRow, TableCell } from '@material-ui/core';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import history from '~/services/history';
import Loader from '~/components/Loader';
import { formatNumber } from '~/utils/format';

// Query
import { useApiV2DetailLot } from '~/hooks/apiV2/register/lot/useApiV2DetailLot'

import {
  StyledTableContainer,
  StyledTableHead,
  // StyledTableCellEllipsis,
  StyledTableBody,
  Container,
  StyledPaper,
  StyledSubmitButton,
  TableCellInfo,
  TableCellFixed,
  TableCellHeaderFixed
} from './styles';

const DetailLot = ({ match }) => {
  const secure_id = match.params.id;
  // const [loading, setLoading] = useState(false);
  const [currentLot, setCurrentLot] = useState(false);
  const [lotReport, setLotReport] = useState(false);

  const {
    data: lotReportData,
    error: errorLotReport,
    isLoading: isLoadingLotReport
  } = useApiV2DetailLot(secure_id);

  useEffect(() => {
    if (lotReportData) {
      setLotReport(lotReportData.currentLot.resultLot);
      setCurrentLot(lotReportData.currentLot.code);
    }
  }, [secure_id, lotReportData]);

  if (errorLotReport) {
    history.push('/dashboard/lots');
    toast.error('Falha para carregar os dados do lote!');

    return (
      <p>Falha para carregar os dados do lote!</p>
    )
  }

  return (
    <center>
      <>
        {isLoadingLotReport ? (
          <Loader />
        ) : (
          <Container>
            <StyledPaper>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <h1>
                  MATRIZ DIÁRIA DE LOTE: {currentLot ? currentLot : ''}
                </h1>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div style={{ marginTop: 10 }}>
                    <ReactHTMLTableToExcel
                      id="test-table-xls-button"
                      className="download-table-xls-button"
                      table="table-to-xls"
                      filename="tablexls"
                      sheet="tablexls"
                      buttonText="Exportar"
                      style={{
                        padding: 0,
                      }}
                    />
                  </div>
                  <StyledSubmitButton
                    variant="outlined"
                    color="primary"
                    onClick={() => history.goBack()}
                    style={{
                      marginLeft: 16,
                    }}
                  >
                    Voltar
                  </StyledSubmitButton>
                </div>
              </div>
              <StyledTableContainer>
                <Table stickyHeader id="table-to-xls">
                  <StyledTableHead>
                    <TableRow>
                      <TableCellHeaderFixed>DATA</TableCellHeaderFixed>
                      {lotReport && lotReport.length
                        ? lotReport.map(current => {
                          return (
                            <TableCell>
                              {format(parseISO(current.date.replace('Z', '')), 'dd/MM/yyy')}
                            </TableCell>
                          );
                        })
                        : ''}
                    </TableRow>
                  </StyledTableHead>
                  <StyledTableBody>
                    {lotReport && (
                      <>
                        <TableRow>
                          <TableCellFixed>PIQUETE</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>{current.paddock}</TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>DIAS NO CONFINAMENTO</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {current.daysOnFeedLot}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>ENTRADA DE ANIMAIS</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {current.amountInput
                                  ? current.amountInput
                                  : 0}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>
                            ENTRADA POR TRANFERÊNCIA
                          </TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {current.amountInputTransfer
                                  ? current.amountInputTransfer
                                  : 0}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>SAÍDA DE ANIMAIS</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {current.amountOutput
                                  ? current.amountOutput
                                  : 0}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>SAÍDA POR TRANFERÊNCIA</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {current.amountOutputTransfer
                                  ? current.amountOutputTransfer
                                  : 0}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>MORTE</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {current.amountOutputDeath}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>REFUCO DE COCHO</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.amountOutputScrap}  `}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>SALDO DE ANIMAIS</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.amount}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>GANHO MEDIO DIÁRIO</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.gmd}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>PESO TOTAL LOTE (KG)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.weightLot}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>PESO MÉDIO (KG/AN)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${formatNumber(
                                  current.averageWeight,
                                  2,
                                  2
                                )}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>DIETA</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.diet}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>LEITURA DE COCHO</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {current.troughReading
                                  ? `${current.troughReading}`
                                  : ''}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>%MS DA DIETA (PREV.)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.forcastPercentMSDiet}%`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>%MS DA DIETA (REAL.)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.performedPercentMSDiet}%`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>TOTAL PREV. MO (KG)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.totalForcastMO}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>TOTAL PREV. MS (KG)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.totalForcastMS}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>TOTAL REAL. MO (KG)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.totalPerformedMO}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>TOTAL REAL. MS (KG)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.totalPerformedMS}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>QTD PREV. MO (KG/AN)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.animalForcastMO}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>QTD PREV. MS (KG/AN)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.animalForcastMS}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>QTD PREV. %PV (AN)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.animalForcastPV}%`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>QTD REAL. MO (KG/AN)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.animalPerformedMO}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>QTD REAL. MS (KG/AN)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.animalPerformedMS}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>QTD REAL. %PV (AN)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${current.animalPerformedPV}%`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>
                            CONS. ACUM. MO LOTE (KG)
                          </TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${formatNumber(
                                  current.accumulatedMOLot,
                                  0,
                                  4
                                )}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>
                            CONS. ACUM. MS LOTE (KG)
                          </TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${formatNumber(
                                  current.accumulatedMSLot,
                                  0,
                                  4
                                )}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>CONS. ACUM. MO (KG/AN)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${formatNumber(
                                  current.accumulatedMOAnimal,
                                  0,
                                  4
                                )}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>CONS. ACUM. MS (KG/AN)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {`${formatNumber(
                                  current.accumulatedMSAnimal,
                                  0,
                                  4
                                )}`}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>CUSTO ALIM. LOTE (R$)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {formatNumber(current.feedCostLot, 2, 2)}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>CUSTO ALIM. (R$/AN)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {formatNumber(current.feedCostAnimal, 2, 2)}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>CUSTO OPER. LOTE</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {formatNumber(
                                  current.operationalCostLot,
                                  2,
                                  2
                                )}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>CUSTO OPER. (R$/AN)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {formatNumber(
                                  current.operationalCostAnimal,
                                  2,
                                  2
                                )}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>CUSTO SANI. LOTE (R$)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {formatNumber(current.sanitaryCostLot, 2, 2)}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>CUSTO SANI. (R$/AN)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {formatNumber(
                                  current.sanitaryCostAnimal,
                                  2,
                                  2
                                )}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>CUSTO FINAL LOTE (R$)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {formatNumber(current.finalCostLot, 2, 2)}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>CUSTO FINAL (R$/AN)</TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {formatNumber(current.finalCostAnimal, 2, 2)}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>
                            CUSTO ALIM. ACUM. LOTE (R$)
                          </TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {formatNumber(
                                  current.accumulatedfeedCostLot,
                                  2,
                                  2
                                )}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>
                            CUSTO OPER. ACUM. LOTE (R$)
                          </TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {formatNumber(
                                  current.accumulatedOperationalCostLot,
                                  2,
                                  2
                                )}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>
                            CUSTO SANI. ACUM. LOTE (R$)
                          </TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {formatNumber(
                                  current.accumulatedSanitaryCostLot,
                                  2,
                                  2
                                )}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>
                            CUSTO FINAL ACUM. (R$/AN)
                          </TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {formatNumber(
                                  current.accumulatedFinalCostAnimal,
                                  2,
                                  2
                                )}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                        <TableRow>
                          <TableCellFixed>
                            CUSTO FINAL ACUM. LOTE (R$)
                          </TableCellFixed>
                          {lotReport && lotReport.length
                            ? lotReport.map(current => (
                              <TableCellInfo>
                                {formatNumber(
                                  current.accumulatedFinalCostLot,
                                  2,
                                  2
                                )}
                              </TableCellInfo>
                            ))
                            : ''}
                        </TableRow>
                      </>
                    )}
                    {/* {activeLots.map(lot => (
                <TableRow key={lot.secure_id}>
                  <TableCell>{lot.code}</TableCell>
                  <TableCell>{`${formatNamePaddock(
                    settings,
                    lot.treatment.paddock
                  )}`}</TableCell>
                </TableRow>
              ))} */}
                  </StyledTableBody>
                </Table>
              </StyledTableContainer>
            </StyledPaper>
          </Container>
        )}
      </>
    </center>
  );
};

DetailLot.propTypes = {
  match: PropTypes.isRequired,
};

export default DetailLot;

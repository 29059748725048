import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Backdrop, Fade } from '@material-ui/core';

import {
  Container,
} from './styles';

import DietAdd from '~/pages/Diet/Register';
export default function ModalBreed({ visibleModal, handleModal }) {
  return (
    <Modal
      open={visibleModal}
      onClose={handleModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visibleModal}>
        <Container>
          <DietAdd closeModal={handleModal} match={{params: {}}} location={{pathname: "/dashboard/diet/add"}} />
        </Container>
      </Fade>
    </Modal>
  );
}

ModalBreed.propTypes = {
  visibleModal: PropTypes.bool.isRequired,
  handleModal: PropTypes.func.isRequired,
};

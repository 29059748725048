import React from 'react';
import Swal from 'sweetalert2';

import {
  TableCell,
  TableRow,
  Switch,
  Tooltip,
  Fade
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';
import IconWarning from '@material-ui/icons/Warning';

import { useApiV2RemoveFood } from '~/hooks/apiV2/register/food/useApiV2RemoveFood';
import { useApiV2EditFood } from '~/hooks/apiV2/register/food/useApiV2EditFood';
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';

import ActionLoader from '~/components/ActionLoader';
import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';
import { formatNumber } from '~/utils/format';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
  Alert
} from './styles';

export function CardTableFood({ food }) {
  const removeFood = useApiV2RemoveFood()
  const changeStatusFood = useApiV2EditFood()
  const { data: settingData } = useApiV2GlobalSetting();

  async function handleChangeActive({ secure_id, active }) {
    await changeStatusFood.mutateAsync({ secure_id, active: !active })
  }

  function handleVerifyProductStock({ total_amount_ton }) {
    if (total_amount_ton !== 0) {
      return true;
    }
    return false;
  }

  function messageExistDietOrMovement({ food_has_diets, food_has_feed_foods, food_has_pre_mix_foods }) {
    if (food_has_diets > 0) {
      return 'Alimento já cadastrado em dietas!';
    }

    if (food_has_feed_foods > 0) {
      return 'Alimento já cadastrado em rações!';
    }

    if (food_has_pre_mix_foods > 0) {
      return 'Alimento já cadastrado em premix!';
    }

    return null;
  }

  function handleSelectFood({ secure_id }) {
    history.push(`/dashboard/food/edit/${secure_id}`);
  }

  function handleRemoveFood({ secure_id }) {
    async function performRemoveFood() {
      await removeFood.mutateAsync(secure_id);
    }
    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar esse alimento?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar alimento!',
    }).then(result => {
      if (result.value) {
        performRemoveFood();
      }
    });
  }

  return (
    <TableRow key={food.secure_id}>
      <StyledTableCell component="th" scope="row">
        {`${food.name}`}
      </StyledTableCell>
      <StyledTableCell align="right">
        {`${formatNumber(food.percent_ms, 2, 4)} %`}
      </StyledTableCell>
      <TableCell align="center">
        {food.formattedUpdateAt}
      </TableCell>
      <StyledTableCell align="right">
        {formatNumber(food.total_amount_ton, 4, 4)}
      </StyledTableCell>
      {settingData && settingData.settings.enable_min_food_stock ? (
        <StyledTableCell align="right">
          {formatNumber(food.min_food_stock_ton, 4, 4)}
        </StyledTableCell>
      ) : null}
      <StyledTableCell align="right">
        {`R$ ${formatNumber(food.price_ton, 2, 2)}`}
      </StyledTableCell>
      <Can
        checkRole={['administrator', 'owner', 'beefer']}
        checkPermission={['food_edit']}
      >
        {can =>
          can ? (
            <TableCell align="right">
              <Switch
                color="primary"
                checked={food.active}
                disabled={handleVerifyProductStock(food)}
                onChange={() => handleChangeActive(food)}
              />
            </TableCell>
          ) : (
            <TableCell align="right">
              <Switch
                color="primary"
                checked={food.active}
                disabled={true}
                onChange={() => handleChangeActive(food)}
              />
            </TableCell>
          )}
      </Can>
      {settingData && settingData.settings.enable_min_food_stock ? (
        <TableCell align="center">
          {food.total_amount_ton <
            food.min_food_stock_ton ? (
            <Alert>
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 600 }}
                title="Estoque abaixo do mínimo"
              >
                <span>
                  <IconWarning />
                </span>
              </Tooltip>
            </Alert>
          ) : null}
        </TableCell>
      ) : null}
      <StyledTableCell
        align="right"
        style={{ position: 'relative' }}
      >
        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['food_edit']}
        >
          <ButtonTable
            icon={<EditIcon color="primary" />}
            background={colors.primary}
            lighten={0.6}
            onClick={() => handleSelectFood(food)}
          />
        </Can>

        <Can
          checkRole={['administrator', 'owner', 'beefer']}
          checkPermission={['food_delete']}
        >
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={messageExistDietOrMovement(food)}
            disableHoverListener={
              food.food_has_diets === 0 &&
              food.food_has_pre_mix_foods === 0 &&
              food.food_has_feed_foods === 0
            }
          >
            <span>
              <ButtonTable
                icon={
                  removeFood.isLoading ? (
                    <ActionLoader text="Deletando" />
                  ) :
                    (
                      <DeleteIcon color={
                        food.food_has_diets > 0 ||
                          food.food_has_feed_foods > 0 ||
                          food.food_has_pre_mix_foods > 0 ? "disabled" : "error"
                      } />
                    )
                }
                background={
                  food.food_has_diets > 0 ||
                    food.food_has_feed_foods > 0 ||
                    food.food_has_pre_mix_foods > 0
                    ? colors.disabled
                    : colors.error
                }
                disabled={
                  food.food_has_diets > 0 ||
                  food.food_has_feed_foods > 0 ||
                  food.food_has_pre_mix_foods > 0
                }
                onClick={() => handleRemoveFood(food)}
              />
            </span>
          </Tooltip>
        </Can>
      </StyledTableCell>
    </TableRow>
  )
}

import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";

export async function getNotification() {
  const { data } = await apiV2.get(`/v1/feedlot/user/notification`);

  return data
}

export function useApiV2Notification() {
  return useQuery(['notification'],
    () => getNotification());
}

import { useQuery } from "react-query"
import { toast } from 'react-toastify';
import apiV2 from "~/services/apiV2"
import { formatDateString } from '~/utils/format';

export async function getFoods(types, sort, page = 1, limit = 50) {
  try {
    const { data } = await apiV2.get(`/v1/feedlot/register/food?sort=${sort}`, {
      params: {
        types,
        page,
        limit
      }
    })

    const newData = data.data.map(food => ({
      ...food,
      formattedUpdateAt: formatDateString(food.updated_at),
    }));

    return {
      foods: newData,
      total: data.meta.total,
      perPage: data.meta.per_page,
      page: data.meta.current_page,
      lastPage: data.meta.last_page,
    }
  } catch {
    toast.error('Falha para carregar os dados dos alimentos cadastrados!');
  }
}

export function useApiV2Foods(types, sort, page, limit) {
  return useQuery(
    ['foods', types, sort, page, limit],
    () => getFoods(types, sort, page, limit),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

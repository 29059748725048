import styled from 'styled-components';
import { Paper, Grid, Button, FormControlLabel, TextField } from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 40px;
`;

export const StyledPaper = styled(Paper)`
  padding: 18px;

  text-align: left;
`;

export const StyledTextField = styled(TextField)`
  margin-right: 18px;
  margin-bottom: 18px;

  @media (max-width: 600px) {
    margin-top: 18px;
  }
`;

export const StyledFodGrid = styled(Grid)`
`;

export const CardTitle = styled.p`
  color: ${colors.primary};
  text-align: center;
  font-size: 12px;
  font-weight: 500;
`;

export const CardBody = styled.p`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
`;

export const Card = styled(Grid)`
  background-color: #f9f9f9;
  padding: 8px;
  border-radius: 8px;
`;

export const AnimalInfos = styled(Grid)`
  margin: 16px 0 16px 0;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  padding-right: 16px;
`;

export const Title = styled.h3`
  font-weight: 400;
  color: ${colors.grey};

  margin-top: 16px;
`;

export const StyledGrid = styled(Grid)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const StyledGridItem = styled(Grid)`
  display: flex;
  justify-content: center;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    color: ${colors.grey};
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.marginTop || 30}px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 12px;
`;

export const Error = styled.span`
  margin: 8px 14px 0;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

import styled from 'styled-components';
import { Paper, TableHead, TableBody, TableCell } from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  width: 100%;
  padding: 20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 50px;
  padding: 10px;

  img {
    height: 70px;
    width: 140px;
  }
`;

export const Column = styled.div``;

export const StyledTablePaper = styled(Paper)`
  width: 100%;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
      font-size: 1rem;
    }

    td:last-child {
      width: 1%;
      white-space: nowrap;
    }
  }
`;

export const StyledTableBody = styled(TableBody)`
  .MuiTableCell-body {
    font-size: 1rem;

    td:last-child {
      width: 1%;
      white-space: nowrap;
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  max-width: 300px;
`;

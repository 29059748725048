import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2"

export async function getOcurrencesTypes(sort, page = 1, limit = 50) {
  const { data } = await apiV2.get(`/v1/feedlot/register/ocurrenceType?sort=${sort}`, {
    params: {
      page,
      limit
    }
  })

  return {
    ocurrencesType: data.data,
    total: data.meta.total,
    perPage: data.meta.per_page,
    page: data.meta.current_page,
    lastPage: data.meta.last_page,
  }
}

export function useApiV2OcurrenceType(sort, page, limit) {
  return useQuery(
    ['ocurrencesType', sort, page, limit],
    () => getOcurrencesTypes(sort, page, limit),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { Divider } from '@material-ui/core';

import { Container, Wrapper, Row, Title, Empty, Content } from './styles';

import HeaderPrint from '~/components/HeaderPrint';

export default class PrintManufactoringDiet extends Component {
  render() {
    const { date, data } = this.props;

    return (
      <Container>
        <HeaderPrint
          title="Fabricação de Mistura/Ração"
          filters={[`Data: ${format(date, 'dd/MM/yyyy')}`]}
        />

        {data.map(feed => (
          <Wrapper key={feed.secure_id}>
            <Row>
              <Title>
                <h5>{feed.name}</h5>
              </Title>
              <Empty />
            </Row>
            {feed.feeds.map(currentFeed => (
              <>
                <Content key={currentFeed.secure_id}>
                  <span>{currentFeed.food.name}</span>
                  <span>Kg</span>
                </Content>
                <Divider light />
                {currentFeed.food.preMixes.length > 0 && (
                  <Wrapper key={`${currentFeed.secure_id}/mix`}>
                    <Row>
                      <Title>
                        <h5>{currentFeed.name}</h5>
                      </Title>
                      <Empty />
                    </Row>
                    {currentFeed.food.preMixes.map(mix => (
                      <>
                        <Content key={mix.secure_id}>
                          <span>{mix.food.name}</span>
                          <span>Kg</span>
                        </Content>
                        <Divider light />
                      </>
                    ))}
                  </Wrapper>
                )}
              </>
            ))}
          </Wrapper>
        ))}
      </Container>
    );
  }
}

PrintManufactoringDiet.propTypes = {
  diet: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  date: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      foods: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number,
          name: PropTypes.name,
        })
      ),
    })
  ).isRequired,
};

import styled from 'styled-components';
import { Paper, FormControl } from '@material-ui/core';

export const StyledPaperFilter = styled(Paper)`
  max-width: 400px;
  width: 100%;
  padding: 8px;
`;

export const StyledFormControl = styled(FormControl)`
  margin-top: 8px;
  text-align: center;
`;

import styled from 'styled-components';
import { Paper, Button } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const StyledPaper = styled(Paper)`
  padding: 10px;
  width: 100%;
  margin-bottom: 28px;
`;

export const StyledButtonGenerator = styled(Button)`
  margin-top: 16px;
  width: 200px;
`;

export const StyledButton = styled(Button)`
  margin-top: 28px;
`;

import React, { useMemo } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import { Grid, TextField } from '@material-ui/core';
import { toast } from 'react-toastify'

// Query
import { useApiV2SelectBreed } from '~/hooks/apiV2/register/breed/useApiV2SelectBreed';
import { useApiV2AddBreed } from '~/hooks/apiV2/register/breed/useApiV2AddBreed';
import { useApiV2EditBreed } from '~/hooks/apiV2/register/breed/useApiV2EditBreed';

import {
  Container,
  StyledPaper,
  ContentItem,
  Error,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';

import history from '~/services/history';
import Loader from '~/components/Loader';
import ButtonSubmit from '~/components/ButtonSubmit';

export default function Register({ match, closeModal }) {
  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  // Query
  const { data: currentBreedData, isLoading, error } = useApiV2SelectBreed(secure_id)
  const addBreed = useApiV2AddBreed(closeModal)
  const editBreed = useApiV2EditBreed()

  const schema = Yup.object().shape({
    name: Yup.string().nullable(),
    description: Yup.string().nullable(),
  });

  async function handleSubmit(data) {
    if (secure_id) {
      await editBreed.mutateAsync({ ...data, secure_id })
    } else {
      const newData = {
        ...data,
        name: data.name.toUpperCase(),
      };
      await addBreed.mutateAsync(newData);
    }
  }

  function handleBack() {
    history.goBack();
  }

  if (secure_id && error) {
    history.push('/dashboard/breeds');
    toast.error('Falha para carregar os dados da raça!');

    return (
      <Container>
        <p>Não foi possível carregar a raça!</p>
      </Container>
    )
  }

  return (
    <center>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <Formik
            enableReinitialize={secure_id}
            validationSchema={schema}
            initialValues={{
              name:
                (currentBreedData && currentBreedData.currentBreed.name) ? currentBreedData.currentBreed.name : '',
              description:
                (currentBreedData && currentBreedData.currentBreed.description) ? currentBreedData.currentBreed.description : '',
            }}
            onSubmit={handleSubmit}
          >
            {({ handleChange, values, errors, touched, isSubmitting }) => (
              <Form>
                <ContentItem>
                  <StyledPaper>
                    <Grid container spacing={2}>
                      {/* <Grid item xs={12} sm={12} md>
                        {/* <TextField
                          name="name"
                          variant="outlined"
                          label="Raça *"
                          autoComplete="off"
                          size="small"
                          fullWidth
                          autoFocus
                          value={values.name}
                          error={!!errors.name && touched.name}
                          onChange={handleChange}
                        />  </Grid> */}
                      <Grid item xs={12} sm={12} md>
                        <TextField
                          label="Nome da raça"
                          size="small"
                          variant="outlined"
                          name="name"
                          fullWidth
                          value={values.name}
                          error={!!errors.description && touched.description}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="name" component={Error} />
                      </Grid>
                      <Grid item xs={12} sm={12} md>
                        <TextField
                          name="description"
                          variant="outlined"
                          label="Descrição"
                          autoComplete="off"
                          size="small"
                          fullWidth
                          value={values.description}
                          error={!!errors.description && touched.description}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="description" component={Error} />
                      </Grid>
                    </Grid>
                    <ContentSubmitButtons>
                      {secure_id ? (
                        <ButtonSubmit title="Alterar" loading={isSubmitting} />
                      ) : (
                        <ButtonSubmit title="Cadastrar" loading={isSubmitting} />
                      )}
                      <StyledSubmitButton
                        variant="outlined"
                        color="primary"
                        type="button"
                        onClick={handleBack}
                      >
                        Cancelar
                      </StyledSubmitButton>
                    </ContentSubmitButtons>
                  </StyledPaper>
                </ContentItem>
              </Form>
            )}
          </Formik>
        )}
      </Container>
    </center>
  );
}

Register.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  closeModal: PropTypes.func,
};

import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2"

export async function getAnimal(secure_id) {
  if (!secure_id) {
    return null
  }

  const { data } = await apiV2.get(`/v1/feedlot/register/animal/${secure_id}`)

  return {
    currentAnimal: data
  }
}

export function useApiV2SelectAnimal(secure_id) {
  return useQuery(['animals', secure_id], () => getAnimal(secure_id))
}

import { useQuery } from "react-query";
import { toast } from 'react-toastify';
import apiV2 from '~/services/apiV2';

export async function searchAnimal(identificationType = null, searchId) {
  try {
    if (!searchId) {
      return null;
    }

    const { data } = await apiV2.get(`v1/feedlot/global/search/animal/${searchId}`, {
      params: { identificationType }
    });

    return data
  } catch {
    toast.error('Falha ao tentar carregar o animal!');
  }
}

export function useApiV2SearchAnimal(identificationType, searchId) {
  return useQuery(
    ['searchAnimal', identificationType, searchId],
    () => searchAnimal(identificationType, searchId),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  )
}

import React, { useEffect, useState } from 'react';

import {
  Grid,
  Table,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
  TablePagination,
} from '@material-ui/core';

// Query
import { useApiV2Feeds } from '~/hooks/apiV2/register/feed/useApiV2Feeds';

import {
  Container,
  StyledGrid,
  StyledPaper,
  ContentButton,
  StyledTableContainer,
  StyledTableHead
} from './styles';

import { handleSortArray } from '~/utils/common';
import history from '~/services/history';

import Can from '~/components/Can';
import Loader from '~/components/Loader';
import ButtonNew from '~/components/ButtonNew';
import { CardTableFeed } from './Components/CardTableFeed';

export default function Diet() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [field, setField] = useState('name');
  const [direction, setDirection] = useState('asc');
  const [listFeeds, setListFeeds] = useState([]);

  // Query
  const { data, isLoading } = useApiV2Feeds('feed', `${field}%7C${direction}`, page + 1, rowsPerPage)

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  function handleChangeOrder(fieldOrder) {
    handleSortArray(
      fieldOrder,
      listFeeds,
      setListFeeds,
      field,
      setField,
      direction,
      setDirection
    );
  }

  function handleAdd() {
    history.push('/dashboard/feed/add');
  }

  useEffect(() => {
    if (data) {
      setListFeeds(data.feeds);
    }
  }, [data]);

  return (
    <center>
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <Container>
            <StyledGrid container justify="space-between" spacing={1}>
              <Grid item sm md lg xl />

              <Can
                checkRole={['administrator', 'owner', 'beefer']}
                checkPermission={['feed_new']}
              >
                <Grid item sm md lg xl>
                  <ContentButton>
                    <ButtonNew title="Novo" onClick={handleAdd} />
                  </ContentButton>
                </Grid>
              </Can>
            </StyledGrid>

            <StyledPaper>
              <StyledTableContainer>
                <Table stickyHeader>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell align="left">
                        <TableSortLabel
                          active={field === 'name'}
                          direction={field === 'name' ? direction : 'asc'}
                          onClick={() => handleChangeOrder('name')}
                        >
                          Ração
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={field === 'description'}
                          direction={
                            field === 'description' ? direction : 'asc'
                          }
                          onClick={() => handleChangeOrder('description')}
                        >
                          Descrição
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="right">
                        <TableSortLabel
                          active={field === 'percent_ms'}
                          direction={field === 'percent_ms' ? direction : 'asc'}
                          onClick={() => handleChangeOrder('percent_ms')}
                        >
                          MS da ração
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="right">
                        <TableSortLabel
                          active={field === 'total_percent_incl_mo'}
                          direction={
                            field === 'total_percent_incl_mo'
                              ? direction
                              : 'asc'
                          }
                          onClick={() =>
                            handleChangeOrder('total_percent_incl_mo')
                          }
                        >
                          Incl. MO
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center">
                        <TableSortLabel
                          active={field === 'updated_at'}
                          direction={field === 'updated_at' ? direction : 'asc'}
                          onClick={() => handleChangeOrder('updated_at')}
                        >
                          Ultima alteração
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="right">
                        <TableSortLabel
                          active={field === 'active'}
                          direction={field === 'active' ? direction : 'asc'}
                          onClick={() => handleChangeOrder('active')}
                        >
                          Ativo
                        </TableSortLabel>
                      </TableCell>
                      <Can checkRole={['administrator', 'owner', 'manager']}>
                        <TableCell align="right" />
                      </Can>
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {listFeeds
                      .map(feed => (
                        <CardTableFeed
                          key={feed.secure_id}
                          feed={feed}
                        />
                      ))}
                  </TableBody>
                </Table>
              </StyledTableContainer>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                labelRowsPerPage="Linhas por pág."
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={data ? data.perPage : 0}
                page={data ? data.page - 1 : 0}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </StyledPaper>
          </Container>
        )}
      </>
    </center>
  );
}

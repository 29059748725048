import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import AddIcon from '@material-ui/icons/Add';

import {
  Grid,
  Typography,
  Hidden,
  Tooltip,
  Fade,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import IconError from '@material-ui/icons/Error';
import IconWarning from '@material-ui/icons/Warning';

// Query
import { useApiV2ShowDataTroughReading } from '~/hooks/apiV2/foodManagement/troughReading/useApiV2ShowDataTroughReading';
import { useApiV2AddTroughReading } from '~/hooks/apiV2/foodManagement/troughReading/useApiV2AddTroughReading';

import {
  Container,
  StyledPaper,
  ContentItem,
  ContentSubmitButtons,
  StyledSubmitButton,
  ContentAlerts,
  Alert,
  AlertWarning,
  StyledGridItem,
  StyledFormControl,
  StyledGrid,
  CheckedBox,
  CheckBoxContainer,
  StyledIconButton,
} from './styles';

import CheckBoxComponent from '../Components/CheckBox';
import Filter from '../Components/Filter';
import ModalDietSwitch from '../Components/ModalDietSwitch';

import history from '~/services/history';
import Loader from '~/components/Loader';
import ButtonSubmit from '~/components/ButtonSubmit';

export default function Add(props) {
  const [currentTreatments, setCurrentTreatments] = useState([]);
  const [arrayReading, setArrayReading] = useState([]);
  const [currentEmployee, setCurrentEmployee] = useState('');
  const [currentTreatment, setCurrentTreatment] = useState(null);
  const [visibleModalDiet, setVisibleModalDiet] = useState(false);
  const [changedDiet, setChangedDiet] = useState(false);
  const [modalCallback, setModalCallback] = useState(null);

  const [filters, setFilters] = useState({
    currentModule: '',
    currentLine: '',
    currentDate: new Date(),
  });
  const extra = props.location ? props.location.extra : ''; //eslint-disable-line

  const { currentModule, currentDate } = filters;

  // Query
  const { data: dataTroughReading, isLoading } = useApiV2ShowDataTroughReading(currentDate, currentModule.secure_id);
  const addTroughReading = useApiV2AddTroughReading(modalCallback, handlePush);

  useEffect(() => {
    if (dataTroughReading) {
      setCurrentTreatments(dataTroughReading.treatments);
    }
  }, [dataTroughReading]);

  useEffect(() => {
    setArrayReading([]);
  }, [currentDate]);

  function handleModalDiet(treatment, changed, callback) {
    if (treatment) {
      setCurrentTreatment(treatment);
    }

    if (changed) {
      setChangedDiet(true);
    }

    setModalCallback(callback);
    setVisibleModalDiet(true);
  }

  function closeModalDiet(treatment, changed, callback) { //eslint-disable-line
    if (treatment) {
      setCurrentTreatment(treatment);
    }

    if (changed) {
      setChangedDiet(true);
    }

    setVisibleModalDiet(false);
  }

  async function handleSubmit() {
    if (!currentEmployee) {
      toast.error('Informe o funcionário responsável!');
      return
    } else {
      const replicateTreatment = currentTreatments.filter(treatment => {
        return treatment.replicate === true;
      }).map(treatmentFiltered => ({
        date: parseISO(format(currentDate, 'yyyy-MM-dd')),
        paddockSecureId: treatmentFiltered.paddock.secure_id,
        treatmentSecureId: treatmentFiltered.secure_id,
        troughSettingSecureId: treatmentFiltered.trough_setting_id,
        employeeSecureId: currentEmployee
      }));
      const newArrayReading = arrayReading.map(treatment => ({
        date: parseISO(format(currentDate, 'yyyy-MM-dd')),
        paddockSecureId: treatment.paddock_id,
        treatmentSecureId: treatment.treatment_id,
        troughSettingSecureId: treatment.trough_setting_id,
        employeeSecureId: currentEmployee
      }));

      await addTroughReading.mutateAsync({
        data: newArrayReading,
        replicateTreatment
      });
    }
  }

  function handleBack() {
    history.push({
      pathname: '/dashboard/troughReading',
      extra: filters,
    });
  }

  function handlePush() {
    if (dataTroughReading.paddocksChangeDiet.length > 0) {
      history.push({
        pathname: '/dashboard/troughReading/dietSwitch',
        paddocksChangeDiet: dataTroughReading.paddocksChangeDiet,
        extra: filters
      });
    } else {
      history.push({
        pathname: '/dashboard/troughReading',
        extra: filters,
      });
    }
  }

  function handleChangeEmployee(event) {
    setCurrentEmployee(event.target.value);
  }

  function handleReplicate(item) {
    if (!item.performed) {
      Swal.fire({
        title: 'Ops!',
        text:
          'Não é possível replicar este trato pois não existe trato anterior.',
        icon: 'warning',
        showConfirmButton: true,
      });
    } else {
      const newTreatments = [...currentTreatments];

      const noteReplay = dataTroughReading.troughSettings.find(
        data => data.note === 'REPLY' || data.note === 'REPLAY'
      );

      const treatmentIndex = newTreatments.findIndex(
        treatment => treatment.secure_id === item.secure_id
      );

      const newTreatment = {
        ...item,
        replicate: !item.replicate,
        trough_setting_id: noteReplay.secure_id,
      };

      newTreatments.splice(treatmentIndex, 1, newTreatment);
      setCurrentTreatments(newTreatments);

      if (!item.replicate) {
        const newArrayReading = arrayReading.filter(
          read => read.treatment_id !== item.secure_id
        );

        setArrayReading(newArrayReading);
      }
    }
  }

  function handleChangeSetting(setting, treatment) {
    const newReading = {
      paddock_id: treatment.paddock.secure_id,
      trough_setting_id: setting,
      treatment_id: treatment.secure_id,
    };

    const searchReading = arrayReading.find(
      read => read.treatment_id === treatment.secure_id
    );

    if (searchReading) {
      const oldArrayReading = arrayReading.filter(
        read => read.treatment_id !== treatment.secure_id
      );

      if (searchReading.trough_setting_id !== newReading.trough_setting_id) {
        setArrayReading([...oldArrayReading, newReading]);
      } else {
        setArrayReading(oldArrayReading);
      }
    } else {
      setArrayReading([...arrayReading, newReading]);
    }
  }

  function handleCheckbox(settingId, treatmentId) {
    let backgroundColor;
    let textColor;

    const aux = arrayReading.filter(
      value =>
        value.trough_setting_id === settingId &&
        value.treatment_id === treatmentId
    );

    if (aux.length > 0) {
      backgroundColor = 'primary';
      textColor = 'white';
    } else {
      backgroundColor = 'white';
      textColor = 'primary';
    }
    return { backgroundColor, textColor };
  }

  useEffect(() => {
    if (extra) {
      setFilters(extra);
    }
  }, [extra]);

  useEffect(() => {
    if (!visibleModalDiet && changedDiet) {
      setCurrentTreatment(null);
      setChangedDiet(false);
    }
  }, [changedDiet, visibleModalDiet]);

  return (
    <center>
      <Container>
        <Grid container justify="space-between">
          <Grid item xs sm md lg xl>
            <Filter
              setArrayReading={setArrayReading}
              filters={filters}
              setFilters={setFilters}
            />
          </Grid>
        </Grid>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            {currentTreatments.map(treatment => (
              <ContentItem key={treatment.secure_id}>
                <StyledPaper>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={12} sm>
                      <Grid container direction="column">
                        <Grid item sm style={{ marginBottom: 5 }}>
                          <Hidden only="xs">
                            <Typography variant="body1" color="primary">
                              Piquete
                            </Typography>
                          </Hidden>
                          <Hidden smUp>
                            <Typography
                              variant="body1"
                              color="primary"
                              align="left"
                            >
                              Piquete
                            </Typography>
                          </Hidden>
                        </Grid>
                        <Grid item sm>
                          <Hidden only="xs">
                            <Typography variant="body2" color="textSecondary">
                              {treatment.paddock.name}
                            </Typography>
                          </Hidden>
                          <Hidden smUp>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              align="left"
                            >
                              {treatment.paddock.name}
                            </Typography>
                          </Hidden>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm>
                      <Grid container direction="column">
                        <Grid item sm style={{ marginBottom: 5 }}>
                          <Hidden only="xs">
                            <Typography variant="body1" color="primary">
                              Dieta
                            </Typography>
                          </Hidden>
                          <Hidden smUp>
                            <Typography
                              variant="body1"
                              color="primary"
                              align="left"
                            >
                              Dieta
                            </Typography>
                          </Hidden>
                        </Grid>
                        <Grid item sm>
                          <Hidden only="xs">
                            <Typography variant="body2" color="textSecondary">
                              {treatment.diet.name}
                            </Typography>
                          </Hidden>
                          <Hidden smUp>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              align="left"
                            >
                              {treatment.diet.name}
                            </Typography>
                          </Hidden>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sm>
                      <Hidden only="xs">
                        {(!treatment.changeDiet || (treatment.changeDiet && treatment.changeDietDifferenceInDays === 0)) ? (
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Grid item sm>
                              <Typography variant="body1" color="primary">
                                Leitura
                              </Typography>
                            </Grid>
                            <Grid item sm>
                              <CheckBoxContainer>
                                {dataTroughReading &&
                                  dataTroughReading.troughSettings.map(setting => {
                                    const {
                                      backgroundColor,
                                      textColor,
                                    } = handleCheckbox(
                                      setting.secure_id,
                                      treatment.secure_id
                                    );
                                    if (
                                      setting.note !== 'REPLY' &&
                                      setting.note !== 'REPLAY'
                                    ) {
                                      return (
                                        <>
                                          <CheckedBox
                                            backgroundColor={backgroundColor}
                                            textColor={textColor}
                                            type="button"
                                            onClick={() => {
                                              if (treatment.replicate) return
                                              handleChangeSetting(
                                                setting.secure_id,
                                                treatment
                                              )
                                            }}
                                            isReplicate={treatment.replicate}
                                          >
                                            <p>{setting.note}</p>
                                          </CheckedBox>
                                        </>
                                      );
                                    }
                                    return null;
                                  })}
                              </CheckBoxContainer>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                          >
                            <Typography variant="body1" color="primary">
                              Necessário realizar a troca de dieta
                            </Typography>
                            <Grid item sm={4} style={{ marginTop: 4 }}>
                              <StyledIconButton
                                variant="contained"
                                size="small"
                                onClick={() => handleModalDiet(treatment)}
                              // disabled={secure_id && hasTreatment}
                              >
                                <AddIcon />
                              </StyledIconButton>
                            </Grid>
                          </Grid>
                        )}
                      </Hidden>

                      <Hidden smUp>
                        {dataTroughReading
                          && (!treatment.changeDiet || (treatment.changeDiet && treatment.changeDietDifferenceInDays === 0))
                          && (
                            <Grid container direction="column" justify="center">
                              <Grid item sm>
                                <Typography
                                  variant="body1"
                                  color="primary"
                                  align="left"
                                >
                                  Leitura
                                </Typography>
                              </Grid>
                              <Grid item sm>
                                <CheckBoxComponent
                                  add
                                  treatment={treatment}
                                  troughSettings={dataTroughReading.troughSettings}
                                  // currentDate={currentDate}
                                  arrayReading={arrayReading}
                                  setArrayReading={setArrayReading}
                                />
                              </Grid>
                            </Grid>
                          )}
                      </Hidden>
                    </Grid>

                    <Grid item xs={12} sm="auto">
                      <Grid
                        container
                        alignItems="center"
                        style={{ height: '100%' }}
                      >
                        <Grid item xs sm>
                          {/* treatment.changeDiet && (
                            <StyledButton
                              variant="outlined"
                              color="primary"
                              fullWidth
                              onClick={() => handleModalDiet(treatment)}
                            >
                              Trocar dieta
                            </StyledButton>
                          ) */}

                          {(!treatment.changeDiet || (treatment.changeDiet && treatment.changeDietDifferenceInDays === 0)) && (
                            <>
                              <Hidden only="xs">
                                <Grid container direction="column">
                                  <Grid item sm>
                                    <Typography variant="body1" color="primary">
                                      Replicar trato
                                    </Typography>
                                  </Grid>
                                  <Grid item sm>
                                    <Checkbox
                                      color="primary"
                                      size="small"
                                      checked={treatment.replicate}
                                      onChange={() =>
                                        handleReplicate(treatment)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Hidden>
                              <Hidden smUp>
                                <Grid
                                  container
                                  direction="column"
                                  alignItems="flex-start"
                                >
                                  <Grid item sm>
                                    <Typography variant="body1" color="primary">
                                      Replicar trato
                                    </Typography>
                                  </Grid>
                                  <Grid item sm>
                                    <Checkbox
                                      color="primary"
                                      size="small"
                                      checked={treatment.replicate}
                                      onChange={() =>
                                        handleReplicate(treatment)
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Hidden>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <ContentAlerts changeDiet={treatment.changeDiet}>
                    {treatment.changeDiet && (
                      <Alert>
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          title={
                            treatment.changeDietDifferenceInDays === 0
                              ? "Após a leitura de cocho é necessário realizar a troca de dieta"
                              : "Necessário realizar a troca de dieta"
                          }
                        >
                          <span>
                            <IconError color="error" />
                          </span>
                        </Tooltip>
                      </Alert>
                    )}
                    {(treatment.changeDeviationUp ||
                      treatment.changeDeviationDown) && (
                        <AlertWarning>
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={
                              treatment.changeDeviationUp
                                ? 'Desvio acima do permitido'
                                : 'Desvio abaixo do permitido'
                            }
                          >
                            <span>
                              <IconWarning />
                            </span>
                          </Tooltip>
                        </AlertWarning>
                      )}
                  </ContentAlerts>
                </StyledPaper>
              </ContentItem>
            ))}
            <StyledGrid container justify="flex-end" spacing={2}>
              <StyledGridItem item xs={12} sm={3}>
                <StyledFormControl size="small" fullWidth>
                  <InputLabel id="input-line">Funcionário *</InputLabel>
                  <Select
                    id="currentEmployee"
                    onChange={handleChangeEmployee}
                    value={currentEmployee}
                    MenuProps={{
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                    }}
                  >
                    {dataTroughReading && dataTroughReading.employees
                      ? dataTroughReading.employees.map(line => {
                        const functions = line.function.split(',');
                        return functions.indexOf('Gerente') > -1 ||
                          functions.indexOf('Veterinário') > -1 ||
                          functions.indexOf('Tratador') > -1 ||
                          functions.indexOf('Fábrica de Ração') > -1 ||
                          functions.indexOf('Folguista') > -1 ||
                          functions.indexOf('Diarista') > -1 ||
                          functions.indexOf('Escritório') > -1 ||
                          functions.indexOf('Boiadeiro') > -1 ? (
                          <MenuItem
                            key={line.secure_id}
                            value={line.secure_id}
                          >
                            {line.name}
                          </MenuItem>
                        ) : (
                          ''
                        );
                      })
                      : ''}
                  </Select>
                </StyledFormControl>
              </StyledGridItem>
            </StyledGrid>

            <ContentSubmitButtons>
              <StyledSubmitButton
                color="primary"
                variant="outlined"
                disabled={!!addTroughReading.isLoading}
                onClick={handleBack}
              >
                Cancelar
              </StyledSubmitButton>
              <ButtonSubmit
                title="Cadastrar"
                disabled={!!addTroughReading.isLoading || currentTreatments.length === 0}
                titleLoading="Cadastrando"
                loading={addTroughReading.isLoading}
                onClick={handleSubmit}
              />
            </ContentSubmitButtons>
          </>
        )}

        <ModalDietSwitch
          paddockId={currentTreatment && currentTreatment.paddock.secure_id}
          visible={visibleModalDiet}
          handleModal={handleModalDiet}
          closeModal={closeModalDiet}
          callback={modalCallback}
        />
      </Container>
    </center>
  );
}

import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import DateFnsUtils from '@date-io/date-fns';
import { ptBR } from 'date-fns/locale';
import { format } from 'date-fns';
import * as Yup from 'yup';

// MUI
import {
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Hidden,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Query
import { useApiV2AddPurchaseFood } from '~/hooks/apiV2/admin/purchaseFood/useApiV2AddPurchaseFood';
import { useApiV2GlobalFood } from '~/hooks/apiV2/global/food/useApiV2GlobalFood';
import { useApiV2Stakeholders } from '~/hooks/apiV2/global/stakeholder/useApiV2GlobalStakeholder';

// Styled Components
import {
  Container,
  StyledPaper,
  ContentItem,
  ContentSubmitButtons,
  MobileContentSubmitButtons,
  StyledSubmitButton,
  StyledGridItem,
  StyledGridItem1st,
  StyledTextField,
  StyledDivider,
  StyledTableHead,
  StyledTableCell,
  StyledIconButton,
  Error,
  TextInfo,
  StyledButton,
  StyledGridItemColumn,
  MobileStyledGridItemColumn,
} from './styles';

// Local
import { formatNumber } from '~/utils/format';
import history from '~/services/history';
import Loader from '~/components/Loader';
import colors from '~/styles/colors';
import NumberFormatForm from '~/components/NumberFormatForm';
import ButtonTable from '~/components/ButtonTable';
import ModalFood from '~/components/ModalFood';
import InputDatePicker from '~/components/InputDatePicker';
import ModalStakeholder from '~/components/ModalStakeholder';
import ButtonSubmit from '~/components/ButtonSubmit';

const schema = Yup.object().shape({
  secure_id: Yup.string().required('Selecione o alimento'),
  amount_ton: Yup.string().required('Informe uma quantidade'),
  price_ton: Yup.string().required('Informe o preço do alimento'),
});

export default function Add() {
  const [providerId, setProviderId] = useState(null);
  const [date, setDate] = useState(new Date());
  const [listFood, setListFood] = useState([]);
  const [visibleModalStakeholder, setVisibleModalStakeholder] = useState(false);
  const [visibleModalFood, setVisibleModalFood] = useState(false);
  const [listProviders, setListProviders] = useState([]);
  const [totalProduct, setTotalProduct] = useState(0);
  const [totalFreight, setTotalFreight] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);
  const [obs, setObs] = useState('');
  const [messageErro, setMessageErro] = useState('');

  // Query
  const addPurchaseFood = useApiV2AddPurchaseFood()
  const { data: foodsData, isLoading: isLoadingFoods } = useApiV2GlobalFood([true], null, 'food');
  const { data: providersData, isLoading: isLoadingProviders } = useApiV2Stakeholders('provider,both')

  function handleRemoveFoodToList(indexFood) {
    const newlistFood = listFood.filter((food, index) => index !== indexFood);
    setListFood(newlistFood);
  }

  function handleAddFoodToList(
    { secure_id, amount_ton, price_ton },
    { resetForm }
  ) {
    if (!foodsData) {
      return
    }
    const existFood = listFood.find(food => food.secure_id === secure_id);
    const currentFood = foodsData.find(food => food.secure_id === secure_id);

    // Roles
    if (existFood) {
      setMessageErro('Item já cadastrado !');
      return;
    }

    // Add
    const totalPriceFood = amount_ton * price_ton;

    const newFood = {
      amount_ton,
      price_ton,
      total_price_food: totalPriceFood,
      secure_id,
      food_name: currentFood.name,
    };

    setListFood([newFood, ...listFood]);
    setMessageErro('');
    resetForm();
  }

  function handleModalFood() {
    setVisibleModalFood(!visibleModalFood);
  }

  function handleModalStakeholder() {
    setVisibleModalStakeholder(!visibleModalStakeholder);
  }

  async function handleAddFood() {
    if (!providerId) {
      setMessageErro('Necssário informar um fornecedor');
      return;
    }

    if (!date) {
      setMessageErro('Necssário informar uma data');
      return;
    }

    const newDate = format(date, 'yyyy-MM-dd');

    const newFoods = listFood.map(food => ({
      amountTon: food.amount_ton,
      priceTon: food.price_ton,
      foodSecureId: food.secure_id,
    }));

    const totalAmountTon = listFood.reduce(
      (total, food) => total + food.amount_ton,
      0
    );

    const data = {
      date: newDate,
      totalPrice: totalProduct,
      totalFreight: totalFreight,
      totalAmountTon: totalAmountTon,
      obs,
      providerSecureId: providerId,
      items: newFoods,
    };

    await addPurchaseFood.mutateAsync(data)
  }

  function handleBack() {
    history.goBack();
  }

  useEffect(() => {
    if (providersData && providersData.length > 0) {
      const newListProviders = providersData.map(providerData => ({
        id: providerData.secure_id,
        value: providerData.fantasy_name
          ? `${providerData.fantasy_name} - ${providerData.name}`
          : providerData.name,
      }));

      setListProviders(newListProviders);
    }
  }, [providersData]);

  useEffect(() => {
    if (listFood.length > 0) {
      const totalPriceProduct = listFood.reduce(
        (total, food) =>
          parseFloat(total + parseFloat(food.amount_ton * food.price_ton)),
        0
      );

      const totalPriceOrder = totalPriceProduct + totalFreight;

      setTotalProduct(totalPriceProduct);
      setTotalOrder(totalPriceOrder);
    }
  }, [listFood, totalFreight]);

  return (
    <center>
      <Container>
        {isLoadingFoods || isLoadingProviders ? (
          <Loader />
        ) : (
          <ContentItem>
            <StyledPaper>
              <Grid container>
                <StyledGridItem fullWidth item sm xs="12">
                  <Grid container>
                    <Grid item xs sm>
                      <Autocomplete
                        id="provider-input"
                        name="provider_id"
                        options={listProviders}
                        getOptionLabel={option => option.value}
                        style={{ width: '100%' }}
                        size="small"
                        onChange={(e, value) => {
                          setProviderId(value ? value.id : null);
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            name="provider_id"
                            label="Fornecedor"
                            variant="outlined"
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs="auto" sm="auto">
                      <StyledIconButton
                        style={{ marginTop: '5px' }}
                        variant="contained"
                        size="small"
                        onClick={handleModalStakeholder}
                      >
                        <AddIcon />
                      </StyledIconButton>
                    </Grid>
                  </Grid>
                </StyledGridItem>
                <StyledGridItem item sm>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                    <InputDatePicker
                      name="date"
                      inputVariant="outlined"
                      fullWidth
                      required
                      maxDate={new Date()}
                      selectedDate={date}
                      handleChangeDate={currentDate => setDate(currentDate)}
                    />
                  </MuiPickersUtilsProvider>
                </StyledGridItem>
              </Grid>

              <StyledDivider />

              <Formik
                validationSchema={schema}
                initialValues={{
                  secure_id: '',
                  price_ton: '',
                  amount_ton: '',
                }}
                onSubmit={handleAddFoodToList}
              >
                {({ setFieldValue, values, errors, touched }) => (
                  <Form>
                    <TextInfo>Alimentos</TextInfo>
                    <Grid container>
                      <StyledGridItem1st item sm>
                        <FormControl variant="outlined" fullWidth size="small">
                          <InputLabel id="food-label">Alimento</InputLabel>
                          <Select
                            labelId="food-label"
                            labelWidth={65}
                            id="food_input"
                            name="secure_id"
                            value={values.secure_id}
                            onChange={event =>
                              setFieldValue('secure_id', event.target.value)
                            }
                            error={touched.secure_id && errors.secure_id}
                          >
                            {foodsData && foodsData.map(food => (
                              <MenuItem key={food.secure_id} value={food.secure_id}>
                                {food.name}
                              </MenuItem>
                            ))}
                          </Select>
                          <ErrorMessage name="secure_id" component={Error} />
                        </FormControl>
                        <StyledIconButton
                          style={{ marginTop: '5px' }}
                          variant="contained"
                          size="small"
                          onClick={handleModalFood}
                        >
                          <AddIcon />
                        </StyledIconButton>
                      </StyledGridItem1st>
                      <StyledGridItem item sm>
                        <NumberFormatForm
                          name="amount_ton"
                          value={values.amount_ton}
                          label="Quantidade (ton)"
                          variant="outlined"
                          size="small"
                          fullWidth
                          isNumericString={false}
                          autoComplete="nope"
                          decimalScale={3}
                          setFieldValue={setFieldValue}
                          error={touched.amount_ton && errors.amount_ton}
                        />
                        <ErrorMessage name="amount_ton" component={Error} />
                      </StyledGridItem>
                      <StyledGridItem item sm>
                        <NumberFormatForm
                          name="price_ton"
                          label="Preço/(ton)"
                          prefix="R$"
                          variant="outlined"
                          size="small"
                          value={values.price_ton}
                          fullWidth
                          autoComplete="nope"
                          decimalScale={2}
                          fixedDecimalScale
                          setFieldValue={setFieldValue}
                          error={touched.price_ton && errors.price_ton}
                        />
                        <ErrorMessage name="price_ton" component={Error} />
                      </StyledGridItem>
                      <Hidden xsDown implementation="css">
                        <StyledGridItemColumn item sm="auto">
                          <StyledButton
                            variant="contained"
                            color={colors.white}
                            background={colors.success}
                            type="submit"
                          >
                            Adicionar
                          </StyledButton>
                          {messageErro !== '' && <Error>{messageErro}</Error>}
                        </StyledGridItemColumn>
                      </Hidden>
                      <Hidden smUp implementation="js">
                        <MobileStyledGridItemColumn item sm="auto">
                          <StyledButton
                            variant="contained"
                            color={colors.white}
                            background={colors.success}
                            type="submit"
                          >
                            Adicionar
                          </StyledButton>
                          {messageErro !== '' && <Error>{messageErro}</Error>}
                        </MobileStyledGridItemColumn>
                      </Hidden>
                    </Grid>
                  </Form>
                )}
              </Formik>

              <br />

              <TableContainer>
                <Table stickyHeader>
                  <StyledTableHead>
                    <TableRow>
                      <TableCell align="left">Descrição</TableCell>
                      <StyledTableCell align="center">
                        Preço(ton)
                      </StyledTableCell>
                      <TableCell align="center">Qtd/Ton</TableCell>
                      <TableCell align="center">Total</TableCell>
                      <TableCell />
                    </TableRow>
                  </StyledTableHead>
                  <TableBody>
                    {listFood.map((food, index) => (
                      <TableRow key={food.secure_id}>
                        <TableCell component="th" scope="row">
                          {food.food_name}
                        </TableCell>

                        <StyledTableCell align="center">
                          {`R$ ${formatNumber(food.price_ton, 2, 2)}`}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {food.amount_ton}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {`R$ ${formatNumber(food.total_price_food, 2, 2)}`}
                        </StyledTableCell>

                        <TableCell>
                          <ButtonTable
                            icon={<DeleteIcon color="error" />}
                            background={colors.error}
                            onClick={() => handleRemoveFoodToList(index)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <br />

              <TextInfo>Totais da compra</TextInfo>
              <Grid container>
                <StyledGridItem item sm style={{ marginTop: '9px' }}>
                  <NumberFormatForm
                    allowedDecimalSeparators={[',']}
                    name="total_freight"
                    label="Total do Frete"
                    variant="outlined"
                    fullWidth
                    autoComplete="nope"
                    decimalScale={2}
                    fixedDecimalScale
                    prefix="R$"
                    onValueChange={values => setTotalFreight(values.floatValue)}
                  />
                </StyledGridItem>
                <StyledGridItem item sm>
                  <StyledTextField
                    name="product"
                    variant="outlined"
                    size="small"
                    label="Total dos produtos"
                    fullWidth
                    disabled
                    value={`R$ ${formatNumber(totalProduct, 2, 2)}`}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </StyledGridItem>
                <StyledGridItem item sm>
                  <StyledTextField
                    name="order"
                    variant="outlined"
                    label="Total do pedido"
                    size="small"
                    fullWidth
                    disabled
                    value={`R$ ${formatNumber(totalOrder, 2, 2)}`}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </StyledGridItem>
              </Grid>
              <StyledDivider />
              <TextInfo>Dados adicionais</TextInfo>
              <Grid container>
                <StyledGridItem item sm>
                  <StyledTextField
                    id="obs"
                    name="obs"
                    variant="outlined"
                    label="Observação"
                    autoComplete="nope"
                    fullWidth
                    multiline
                    onChange={event => setObs(event.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </StyledGridItem>
              </Grid>
              <Hidden xsDown implementation="css">
                <ContentSubmitButtons>
                  <ButtonSubmit
                    title="Cadastrar"
                    disabled={addPurchaseFood.isLoading || listFood.length === 0}
                    loading={addPurchaseFood.isLoading}
                    onClick={handleAddFood}
                  />
                  <StyledSubmitButton
                    variant="outlined"
                    color="primary"
                    onClick={handleBack}
                  >
                    Cancelar
                  </StyledSubmitButton>
                </ContentSubmitButtons>
              </Hidden>

              <Hidden smUp implementation="css">
                <MobileContentSubmitButtons>
                  <ButtonSubmit
                    title="Cadastrar"
                    disabled={addPurchaseFood.isLoading || listFood.length === 0}
                    loading={addPurchaseFood.isLoading}
                    onClick={handleAddFood}
                  />
                  <StyledSubmitButton
                    variant="outlined"
                    color="primary"
                    onClick={handleBack}
                  >
                    Cancelar
                  </StyledSubmitButton>
                </MobileContentSubmitButtons>
              </Hidden>
            </StyledPaper>
          </ContentItem>
        )}

        {visibleModalStakeholder && (
          <ModalStakeholder
            visibleModal={visibleModalStakeholder}
            handleModal={handleModalStakeholder}
            type="provider,both"
          />
        )}

        {visibleModalFood && (
          <ModalFood
            visibleModal={visibleModalFood}
            handleModal={handleModalFood}
          />
        )}
      </Container>
    </center>
  );
}

import React from 'react';

import { Container, Content, ButtonBack } from './styles';

import not_found from '~/assets/images/not_found.jpg';
import history from '~/services/history';

function handleBack() {
  history.push('/');
}

function NotFound() {
  return (
    <center>
      <Container>
        <Content>
          <img src={not_found} alt="wallpaper" />
          <ButtonBack variant="contained" color="primary" onClick={handleBack}>
            Voltar
          </ButtonBack>
        </Content>
      </Container>
    </center>
  );
}

export default NotFound;

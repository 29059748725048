import styled from 'styled-components';
import {
  Paper,
  Grid,
  Button,
  TableContainer,
  TableHead,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  padding: 40px;
`;

export const StyledGrid = styled(Grid)`
  margin-bottom: 20px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const StyledGridItem = styled(Grid)`
  margin: 5px 10px;
`;

export const ContentAddButton = styled.div`
  max-width: 500px;
  margin-bottom: 15px;
`;

export const ContentButton = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
`;

export const StyledAddButton = styled(Button)`
  color: ${colors.white};
  background: ${colors.success};

  &:hover {
    background: ${colors.success};
  }
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${props => props.marginTop || 50}px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: 10px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;
export const TextInfo = styled.p`
  color: ${colors.primary};
  margin-bottom: 10px;
`;

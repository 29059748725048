import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { parseISO } from 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Query
import { StyledPaper, StyledGrid, ContentError, Error } from './styles';

import DatePicker from '~/components/DatePicker';
import NumberFormatForm from '~/components/NumberFormatForm';

const feedDefaultOption = {
  secure_id: -1,
  name: 'Nenhuma',
};

export default function FilterRegister({
  feeds,
  feedSecureId = '',
  errorTotalForecast,
  totalForecast,
  handleTotalForecast,
  secureId = null,
  currentDate,
  setCurrentFeed,
  setCurrentDate,
}) {
  const [currentFeedId, setCurrentFeedId] = useState(feedSecureId);

  function handleChangeFeed(event) {
    if (event.target.value !== -1) {
      setCurrentFeedId(event.target.value);
      if (feeds) {
        const feedSelected = feeds.filter(feed => feed.secure_id === event.target.value)[0];
        setCurrentFeed(feedSelected);
        handleTotalForecast(0);
      }

    } else {
      setCurrentFeed(null);
    }
  }

  function handleChangeDate(date) {
    setCurrentDate(date);
  }

  return (
    <StyledPaper>
      <StyledGrid container justify="space-around" spacing={2}>
        <Grid item sm xs={12}>
          <FormControl fullWidth>
            <InputLabel id="input-diet">Mistura/Ração</InputLabel>
            <Select
              id="currentFeed"
              defaultValue={feedDefaultOption.secure_id}
              MenuProps={{
                getContentAnchorEl: null,
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
              }}
              disabled={secureId}
              value={currentFeedId || feedDefaultOption.secure_id}
              onChange={handleChangeFeed}
            >
              <MenuItem value={feedDefaultOption.secure_id}>
                {feedDefaultOption.name}
              </MenuItem>
              {feeds.map(item => (
                <MenuItem key={item.secure_id} value={item.secure_id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm xs={12}>
          <NumberFormatForm
            name="total_forecast"
            label="Total Qtd. Prevista"
            variant="standard"
            autoComplete="nope"
            fullWidth
            allowNegative={false}
            isNumericString
            decimalScale={0}
            error={errorTotalForecast}
            onValueChange={values =>
              handleTotalForecast(values.value !== '' ? values.floatValue : '')
            }
            value={totalForecast}
          />
          {errorTotalForecast && (
            <ContentError>
              <Error>{errorTotalForecast}</Error>
            </ContentError>
          )}
        </Grid>
        <Grid item sm xs={12}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              margin="none"
              fullWidth
              disabled={secureId}
              style={{ marginTop: 3 }}
              selectedDate={currentDate}
              handleChangeDate={date => handleChangeDate(date)}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </StyledGrid>
    </StyledPaper>
  );
}

FilterRegister.defaultProps = {
  secureId: null,
};

FilterRegister.propTypes = {
  feeds: PropTypes.arrayOf(PropTypes.object).isRequired,
  feedSecureId: PropTypes.string,
  errorTotalForecast: PropTypes.string.isRequired,
  totalForecast: PropTypes.number.isRequired,
  handleTotalForecast: PropTypes.func.isRequired,
  secureId: PropTypes.string,
  currentDate: PropTypes.shape({}).isRequired,
  setCurrentFeed: PropTypes.func.isRequired,
  setCurrentDate: PropTypes.func.isRequired
};

/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import {
  TableContainer,
  Table,
  TableCell,
  Typography,
  TableRow,
  Grid,
} from '@material-ui/core';

import {
  Container,
  Header,
  Column,
  StyledTablePaper,
  StyledTableHead,
  StyledTableBody,
  StyledTableCell,
  // StyledTableCellEllipsis,
  StyledTableCellMin
} from './styles';

import logo from '~/assets/images/logo_black.png';
import { formatNumber, formatDateOnly } from '~/utils/format';

export default class PrintActivePaddock extends Component {
  render() {
    const { data, date } = this.props;

    const validatePV = (valuePV, dietTarget) => {
      const result = (Number(valuePV) * 100) / Number(dietTarget);
      if (result < 90) {
        return 'red';
      }
      if (result >= 90 && result < 100) {
        return 'orange';
      }
      if (result >= 100 && result < 120) {
        return 'green';
      }
      return 'blue ';
    };

    return (
      <Container>
        <Header>
          <img src={logo} alt="logo" style={{ flexBasis: '10%' }} />
          <div
            style={{
              flexBasis: '80%',
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h2">Piquetes Ativos</Typography>
            <Column>
              <Typography variant="h5">{`Data: ${String(
                format(date, 'dd/MM/yyyy')
              )}`}</Typography>
            </Column>
          </div>
        </Header>

        <StyledTablePaper elevation={0}>
          <TableContainer>
            <Table>
              <StyledTableHead>
                <TableRow>
                  <TableCell align="center">
                    <p style={{ fontSize: '10.9px' }}>
                      Piquete
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <p style={{ fontSize: '10.9px' }}>
                      Lote
                    </p>
                  </TableCell>
                  <TableCell>
                    <p style={{ fontSize: '10.9px' }}>
                      Raças
                    </p>
                  </TableCell>
                  <TableCell>
                    <p style={{ fontSize: '10.9px' }}>
                      Categ.
                    </p>
                  </TableCell>
                  <TableCell>
                    <p style={{ fontSize: '10.9px' }}>
                      Qtd
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <p style={{ fontSize: '10.9px' }}>
                      Entrada
                    </p>
                  </TableCell>
                  <TableCell align="right">
                    <p style={{ fontSize: '10.9px' }}>
                      Conf
                      <br />
                      (dias)
                    </p>
                  </TableCell>
                  <TableCell align="right">
                    <p style={{ fontSize: '10.9px' }}>
                      Trato
                      <br />
                      (dias)
                    </p>
                  </TableCell>
                  <TableCell align="right">
                    <p style={{ fontSize: '10.9px' }}>
                      Peso
                      <br />
                      (ent.)
                    </p>
                  </TableCell>
                  <TableCell>
                    <p style={{ fontSize: '10.9px' }}>
                      Dieta
                    </p>
                  </TableCell>
                  <TableCell align="right">
                    <p style={{ fontSize: '10.9px' }}>
                      MO
                    </p>
                  </TableCell>
                  <TableCell align="right">
                    <p style={{ fontSize: '10.9px' }}>
                      MS
                    </p>
                  </TableCell>
                  <StyledTableCell align="right">
                    <p style={{ fontSize: '10.9px' }}>
                      % PV
                    </p>
                  </StyledTableCell>
                  <TableCell align="center">
                    <p style={{ fontSize: '10.9px' }}>
                      MO/D1
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <p style={{ fontSize: '10.9px' }}>
                      MO/D2
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <p style={{ fontSize: '10.9px' }}>
                      MO/D3
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <p style={{ fontSize: '10.9px' }}>
                      MS/D1
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <p style={{ fontSize: '10.9px' }}>
                      MS/D2
                    </p>
                  </TableCell>
                  <TableCell align="center">
                    <p style={{ fontSize: '10.9px' }}>
                      MS/D3
                    </p>
                  </TableCell>
                  <StyledTableCell align="center">
                    <p style={{ fontSize: '10.9px' }}>
                      % PV/D1
                    </p>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <p style={{ fontSize: '10.9px' }}>
                      % PV/D2
                    </p>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <p style={{ fontSize: '10.9px' }}>
                      % PV/D3
                    </p>
                  </StyledTableCell>
                  <TableCell align="right">
                    <p style={{ fontSize: '10.9px' }}>
                      R$/cab
                      <br />
                      /dia
                    </p>
                  </TableCell>
                  <TableCell align="right">
                    <p style={{ fontSize: '10.9px' }}>
                      Peso
                      <br />
                      (atual)
                    </p>
                  </TableCell>
                  <TableCell align="right">
                    <p style={{ fontSize: '10.9px' }}>
                      Peso
                      <br />
                      (abate)
                    </p>
                  </TableCell>
                  <TableCell align="right">
                    <p style={{ fontSize: '10.9px' }}>
                      Data
                      <br />
                      (abate)
                    </p>
                  </TableCell>
                </TableRow>
              </StyledTableHead>
              <StyledTableBody>
                {data &&
                  data.activeLots.length && //eslint-disable-line
                  data.activeLots.map(module => { //eslint-disable-line
                    return (
                      <>
                        {module.lines.map(line => {
                          return (
                            <>
                              <TableRow key={module.module}>
                                <TableCell colSpan={4}>
                                  <b>
                                    Module: {module.module} - Linha: {line.line}
                                  </b>
                                </TableCell>
                                <TableCell colSpan={24} />
                              </TableRow>
                              {line.paddocks.map(lot => {
                                const colorPV1 = validatePV(
                                  lot.percentPV[0],
                                  lot.dietTarget
                                );
                                const colorPV2 = validatePV(
                                  lot.percentPV[1],
                                  lot.dietTarget
                                );
                                const colorPV3 = validatePV(
                                  lot.percentPV[2],
                                  lot.dietTarget
                                );
                                return (
                                  <TableRow key={lot.code}>
                                    <TableCell>
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.paddock.name}
                                      </p>
                                    </TableCell>
                                    <TableCell>
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.code}
                                      </p>
                                    </TableCell>

                                    <StyledTableCellMin>
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.breed_names}
                                      </p>
                                    </StyledTableCellMin>
                                    <StyledTableCellMin>
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.types}
                                      </p>
                                    </StyledTableCellMin>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.amount_animals}
                                      </p>
                                    </TableCell>
                                    <TableCell align="center">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {formatDateOnly(lot.date_animal_inputs)}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.days_active}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.days_treatments}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {formatNumber(
                                          lot.average_weight_input,
                                          0,
                                          2
                                        )}
                                      </p>
                                    </TableCell>
                                    <StyledTableCellMin>
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.diet_name}
                                      </p>
                                    </StyledTableCellMin>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {formatNumber(
                                          lot.total_average_consumption_performed_mo,
                                          2,
                                          2
                                        )}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {formatNumber(
                                          lot.total_average_consumption_performed_ms,
                                          2,
                                          2
                                        )}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {formatNumber(
                                          lot.total_percent_performed_pv,
                                          2,
                                          2
                                        )}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.mo[0] &&
                                          formatNumber(lot.mo[0], 2, 2)}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.mo[1] &&
                                          formatNumber(lot.mo[1], 2, 2)}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.mo[2] &&
                                          formatNumber(lot.mo[2], 2, 2)}
                                      </p>
                                    </TableCell>

                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.ms[0] &&
                                          formatNumber(lot.ms[0], 2, 2)}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.ms[1] &&
                                          formatNumber(lot.ms[1], 2, 2)}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.ms[2] &&
                                          formatNumber(lot.ms[2], 2, 2)}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ color: colorPV1, fontSize: '10.9px' }}>
                                        {lot.percentPV[0] &&
                                          formatNumber(lot.percentPV[0], 2, 2)}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ color: colorPV2, fontSize: '10.9px' }}>
                                        {lot.percentPV[1] &&
                                          formatNumber(lot.percentPV[1], 2, 2)}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ color: colorPV3, fontSize: '10.9px' }}>
                                        {lot.percentPV[2] &&
                                          formatNumber(lot.percentPV[2], 2, 2)}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {formatNumber(lot.price_animal_day, 2, 2)}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {formatNumber(
                                          lot.average_current_weight,
                                          2,
                                          2
                                        )}
                                      </p>
                                    </TableCell>
                                    <TableCell align="right">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {formatNumber(
                                          lot.average_weight_forecast,
                                          2,
                                          2
                                        )}
                                      </p>
                                    </TableCell>
                                    <TableCell align="center">
                                      <p style={{ fontSize: '10.9px' }}>
                                        {lot.date_forecast_slaughter &&
                                          formatDateOnly(
                                            lot.date_forecast_slaughter
                                          )}
                                      </p>
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </>
                          );
                        })}
                      </>
                    );
                  })}
              </StyledTableBody>
            </Table>
          </TableContainer>
        </StyledTablePaper>
        {data && data.activeLots.length ? ( //eslint-disable-line
          <div>
            <Header>
              <h3>Resumo</h3>
            </Header>
            <Grid container>
              <Grid item xs sm>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Total de lotes ativos:
                  </Typography>
                  <strong>{data.resumeLots.totalLots}</strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Total de animais:
                  </Typography>
                  <strong>{data.resumeLots.totalAnimals} </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Peso de entrada:
                  </Typography>
                  <strong>
                    {formatNumber(data.resumeLots.totalInputWeight, 2, 2)} kg
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Peso atual:
                  </Typography>
                  <strong>
                    {formatNumber(data.resumeLots.totalCurrentWeight, 2, 2)} kg
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo Verde:
                  </Typography>
                  <strong>
                    {data.resumeLots.consumptionGreen} lotes (
                    {formatNumber(
                      (data.resumeLots.consumptionGreen * 100) /
                      (data.resumeLots.consumptionGreen +
                        data.resumeLots.consumptionOrange +
                        data.resumeLots.consumptionRed),
                      2,
                      2
                    )}
                    %) - referência % PV D1
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo Laranja:
                  </Typography>
                  <strong>
                    {data.resumeLots.consumptionOrange} lotes (
                    {formatNumber(
                      (data.resumeLots.consumptionOrange * 100) /
                      (data.resumeLots.consumptionGreen +
                        data.resumeLots.consumptionOrange +
                        data.resumeLots.consumptionRed),
                      2,
                      2
                    )}
                    %) - referência % PV D1
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography ypography variant="body2" component="h5">
                    Consumo Vermelho:
                  </Typography>
                  <strong>
                    {data.resumeLots.consumptionRed} lotes (
                    {formatNumber(
                      (data.resumeLots.consumptionRed * 100) /
                      (data.resumeLots.consumptionGreen +
                        data.resumeLots.consumptionOrange +
                        data.resumeLots.consumptionRed),
                      2,
                      2
                    )}
                    %) - referência % PV D1
                  </strong>
                </Grid>
              </Grid>
              <Grid item xs ms>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo médio geral (mo/cab/dia):
                  </Typography>
                  <strong>
                    {formatNumber(data.resumeLots.consumptionAverageMo, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo médio geral (ms/cab/dia):
                  </Typography>
                  <strong>
                    {formatNumber(data.resumeLots.consumptionAverageMs, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo médio geral (%pv):
                  </Typography>
                  <strong>
                    {formatNumber(data.resumeLots.consumptionAveragePV, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo médio geral D1 (mo/cab/dia):
                  </Typography>
                  <strong>
                    {formatNumber(data.resumeLots.consumptionAverageMoD1, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo médio geral D2 (mo/cab/dia):
                  </Typography>
                  <strong>
                    {formatNumber(data.resumeLots.consumptionAverageMoD2, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo médio geral D3 (mo/cab/dia):
                  </Typography>
                  <strong>
                    {formatNumber(data.resumeLots.consumptionAverageMoD3, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo médio geral D1 (ms/cab/dia):
                  </Typography>
                  <strong>
                    {formatNumber(data.resumeLots.consumptionAverageMsD1, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo médio geral D2 (ms/cab/dia):
                  </Typography>
                  <strong>
                    {formatNumber(data.resumeLots.consumptionAverageMsD2, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo médio geral D3 (ms/cab/dia):
                  </Typography>
                  <strong>
                    {formatNumber(data.resumeLots.consumptionAverageMsD3, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo médio geral D1 (%pv):
                  </Typography>
                  <strong>
                    {formatNumber(data.resumeLots.consumptionAveragePVD1, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo médio geral D2 (%pv):
                  </Typography>
                  <strong>
                    {formatNumber(data.resumeLots.consumptionAveragePVD2, 2, 2)}
                  </strong>
                </Grid>
                <Grid container alignItems="center">
                  <Typography variant="body2" component="h5">
                    Consumo médio geral D3 (%pv):
                  </Typography>
                  <strong>
                    {formatNumber(data.resumeLots.consumptionAveragePVD3, 2, 2)}
                  </strong>
                </Grid>
              </Grid>
            </Grid>
          </div>
        ) : (
          ''
        )}
      </Container>
    );
  }
}

PrintActivePaddock.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  activeLots: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  resumeLots: PropTypes.shape({}).isRequired,
  date: PropTypes.string.isRequired,
};

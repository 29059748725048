import React, { useMemo } from 'react';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { Grid, TextField } from '@material-ui/core';

// Query
import { useApiV2SelectCauseOfDeath } from '~/hooks/apiV2/register/causeOfDeath/useApiV2SelectCauseOfDeath'
import { useApiV2AddCauseOfDeath } from '~/hooks/apiV2/register/causeOfDeath/useApiV2AddCauseOfDeath'
import { useApiV2EditCauseOfDeath } from '~/hooks/apiV2/register/causeOfDeath/useApiV2EditCauseOfDeath'

import {
  Container,
  StyledPaper,
  ContentItem,
  Error,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';

import history from '~/services/history';
import ButtonSubmit from '~/components/ButtonSubmit';
import Loader from '~/components/Loader';

const schema = Yup.object().shape({
  name: Yup.string().required('Informe a causa da morte'),
  description: Yup.string().nullable(),
});

export default function Register({ match }) {
  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  // Query
  const { data, error, isLoading } = useApiV2SelectCauseOfDeath(secure_id);
  const addCauseOfDeath = useApiV2AddCauseOfDeath(null);
  const editCauseOfDeath = useApiV2EditCauseOfDeath();

  async function handleSubmit(data) {
    if (secure_id) {
      await editCauseOfDeath.mutateAsync(data)
    } else {
      await addCauseOfDeath.mutateAsync(data)
    }
  }

  function handleBack() {
    history.goBack();
  }

  if (secure_id && error) {
    history.push('/dashboard/causeOfDeath')
    toast.error('Falha ao carregar a causa da morte!');
    return null
  }

  if (isLoading) {
    return (
      <Loader />
    )
  }

  return (
    <center>
      <Container>
        <Formik
          enableReinitialize={secure_id}
          validationSchema={schema}
          initialValues={{
            secure_id: data ? data.currentCauseOfDeath.secure_id : null,
            name: data ? data.currentCauseOfDeath.name : '',
            description: data ? data.currentCauseOfDeath.description : '',
          }}
          onSubmit={handleSubmit}
        >
          {({ handleChange, errors, touched, values, isSubmitting }) => (
            <Form>
              <ContentItem>
                <StyledPaper>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md>
                      <TextField
                        name="name"
                        variant="outlined"
                        label="Causa da Morte *"
                        autoComplete="off"
                        size="small"
                        fullWidth
                        autoFocus
                        value={values.name}
                        error={!!errors.name && touched.name}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="name" component={Error} />
                    </Grid>
                    <Grid item xs={12} sm={12} md>
                      <TextField
                        name="description"
                        variant="outlined"
                        label="Descrição"
                        autoComplete="off"
                        size="small"
                        fullWidth
                        value={values.description}
                        error={!!errors.description && touched.description}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="description" component={Error} />
                    </Grid>
                  </Grid>

                  <ContentSubmitButtons>
                    {secure_id ? (
                      <ButtonSubmit title="Alterar" loading={isSubmitting} />
                    ) : (
                      <ButtonSubmit title="Cadastrar" loading={isSubmitting} />
                    )}
                    <StyledSubmitButton
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={handleBack}
                    >
                      Cancelar
                    </StyledSubmitButton>
                  </ContentSubmitButtons>
                </StyledPaper>
              </ContentItem>
            </Form>
          )}
        </Formik>
      </Container>
    </center>
  );
}

Register.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};

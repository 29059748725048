import styled from 'styled-components';
import {
  FormControlLabel,
  Paper,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
} from '@material-ui/core';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 3000px;
  width: 100%;
  padding: 40px 40px 18px;

  text-align: left;
`;

export const Header = styled.div`
  position: absolute;
  top: -22px;
  left: -18px;
  padding: 8px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
`;

export const StyledPaperTwo = styled(Paper)`
  width: calc(100% - 16px);
  padding: 18px 16px 16px;
  position: relative;
  margin-top: 24px;
  top: 24px;
  left: 16px;

  & + div {
    margin-top: 38px;
  }

  h6 {
    font-weight: bold;
  }

  .MuiTypography-body2 {
    font-weight: 500;
    line-height: 25px;
    margin-right: 5px;

    /* text-transform: capitalize; */
  }
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  margin-right: 0px;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  margin-bottom: 24px;
`;

export const StyledTableBody = styled(TableBody)`
  tr td {
    font-size: larger;
  }
  td:first-child,
  th:first-child {
    position: 'sticky';
    left: 0;
    z-index: 998;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  max-height: calc(100vh - 255px);
  margin-bottom: 24px;

  thead {
    tr {
      td:first-child,
      th:first-child {
        position: 'sticky';
        left: 0;
        z-index: 999;
      }
    }
    th {
      background: ${colors.header};
    }
  }
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      font-size: larger;

      color: ${colors.primary};
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  white-space: nowrap;
`;

export const StyledTableCellSticky = styled(TableCell)`
  position: -webkit-sticky;
  position: sticky;
  background: #fff;
  left: 0;
  z-index: 1;
`;

export const TableCellFixed = styled(TableCell)`
  font-size: 14px;
  position: sticky;
  background: #fff;
  z-index: 1;
`;

export const StyledTableCellEllipsis = styled(TableCell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`;

export const ContentPrint = styled.div`
  display: none;
`;

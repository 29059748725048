import styled from 'styled-components';
import { Paper, Grid, FormControl } from '@material-ui/core';
import colors from '~/styles/colors';

export const StyledPaper = styled(Paper)`
  max-width: 800px;
  padding: 12px;
`;

export const StyledGrid = styled(Grid)`
  width: 100%;
`;

export const StyledFormControl = styled(FormControl)`
  margin-top: 4px;
  max-width: 194px;
  width: 100%;
`;

export const ContentError = styled.div`
  text-align: left;
`;

export const Error = styled.span`
  margin: 8px 14px 0;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

import styled from 'styled-components';
import colors from '~/styles/colors';

export const Container = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  margin-bottom: 48px;
  margin-right: 12px;
`;

export const Row = styled.div`
  display: flex;
`;

export const Title = styled.div`
  padding-left: 24px;
  border-bottom: 0.5px solid ${colors.light};

  h5 {
    font-size: 20px;
    font-weight: 400;
    color: ${colors.darker};
  }
`;

export const Empty = styled.div`
  display: flex;
`;

export const Content = styled.div`
  padding: 24px 6px 0px 48px;
  margin-right: 12px;

  display: flex;
  justify-content: space-between;

  span {
    font-size: 18px;
    color: ${colors.darker};
    font-weight: 400;

    &:last-child {
      color: ${colors.grey};
      font-weight: 300;
    }
  }
`;

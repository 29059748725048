import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";

export async function getAnimalOutput({
  date = new Date(), lot, client, provider
}) {
  const { data } = await apiV2.get('/v1/feedlot/report/animalOutput', {
    params: { date, lot, client, provider }
  });

  return data
}

export function useApiV2AnimalOutput({ date, lot, client, provider }) {
  return useQuery(
    ['animalOutputReport', { date, lot, client, provider }],
    () => getAnimalOutput({ date, lot, client, provider }),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  );
}

import React from 'react';
import Swal from 'sweetalert2';

import {
  TableRow
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ButtonTable from '~/components/ButtonTable';

import { useApiV2RemoveBreed } from '~/hooks/apiV2/register/breed/useApiV2RemoveBreed';

import ActionLoader from '~/components/ActionLoader';
import history from '~/services/history';
import Can from '~/components/Can';
import colors from '~/styles/colors';

import {
  StyledTableCell,
  StyledTableCellEllipsis,
} from './styles';

export function CardTableBreed({ breed }) {
  // Query
  const removeBreed = useApiV2RemoveBreed();

  function handleSelectBreed({ secure_id }) {
    history.push(`/dashboard/breed/edit/${secure_id}`);
  }

  function handleRemoveBreed({ secure_id }) {
    async function performRemoveBreed() {
      await removeBreed.mutateAsync(secure_id)
    }
    Swal.fire({
      title: 'Você tem certeza ?',
      text: 'Está ação não tem volta! Deseja deletar essa raça?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: colors.lightPrimary,
      cancelButtonColor: colors.grey,
      confirmButtonText: 'Ok, deletar raça!',
    }).then(result => {
      if (result.value) {
        performRemoveBreed();
      }
    });
  }

  return (
    <TableRow>
      <StyledTableCellEllipsis component="th" scope="row">
        {breed.name}
      </StyledTableCellEllipsis>
      <StyledTableCellEllipsis>
        {breed.description}
      </StyledTableCellEllipsis>
      <StyledTableCell align="right">
        {removeBreed.isLoading ? (
          <ActionLoader text="Deletando" />
        ) : (
          <>
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['breed_edit']}
            >
              <ButtonTable
                icon={<EditIcon color="primary" />}
                background={colors.primary}
                lighten={0.6}
                onClick={() => handleSelectBreed(breed)}
              />
            </Can>
            <Can
              checkRole={['administrator', 'owner', 'beefer']}
              checkPermission={['breed_delete']}
            >
              <ButtonTable
                icon={<DeleteIcon color={breed.has_animals > 0 ? "disabled" : "error"} />}
                background={
                  breed.has_animals > 0
                    ? colors.grey
                    : colors.error
                }
                disabled={breed.has_animals > 0}
                onClick={() => handleRemoveBreed(breed)}
              />
            </Can>
          </>
        )}
      </StyledTableCell>
    </TableRow>
  )
}

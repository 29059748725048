import React from 'react';
import {
  Backdrop, Divider, Fade,
  FormControl, Grid, InputLabel, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableRow
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { format } from 'date-fns';
import NumberFormatForm from '~/components/NumberFormatForm';
import { useApiV2SelectManufacturingFeed } from '~/hooks/apiV2/foodManagement/manufactoringFeed/useApiV2SelectManufacturingFeed';
import { formatNumber } from '~/utils/format';
import TextFieldEdit from '../TextFieldEdit';
import {
  CloseButton, Container, StyledGrid,
  StyledGridItem, StyledPaper, StyledTableCell, StyledTableHead,
  StyledTextField
} from './styles';

export function ModalManufactoringFeed({ visibleModal, handleCloseModal, secure_id }) {
  const { data: currentManufactoringFeed } = useApiV2SelectManufacturingFeed(secure_id);

  return (
    <Modal
      open={visibleModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visibleModal}>
        <Container>
          {!!currentManufactoringFeed && (
            <StyledPaper>
              <StyledGrid container justify="space-between" alignItems="center">
                <StyledGridItem item sm={5} xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-diet-input-label">Mistura/Ração</InputLabel>
                    <Select
                      labelId="select-diet-label"
                      id="select-diet"
                      value={currentManufactoringFeed.feed.secure_id}
                      disabled
                      labelWidth={40}
                    >
                      <MenuItem
                        id={String(currentManufactoringFeed.feed.secure_id)}
                        value={currentManufactoringFeed.feed.secure_id}
                      >
                        {currentManufactoringFeed.feed.name}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
                <StyledGridItem item sm={2} xs={12}>
                <StyledTextField
                    id="total_forecast"
                    label="Total Previsto"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                    value={currentManufactoringFeed.total_forecast}
                  />
                </StyledGridItem>
                <StyledGridItem item sm={4} xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-date-input-label">Data</InputLabel>
                    <Select
                      labelId="select-date-label"
                      id="select-date"
                      value={currentManufactoringFeed.date}
                      disabled
                      labelWidth={35}
                    >
                      <MenuItem
                        id={String(currentManufactoringFeed.date)}
                        value={currentManufactoringFeed.date}
                      >
                        {format(
                          new Date(currentManufactoringFeed.date),
                          'dd/MM/yyyy'
                        )}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
              </StyledGrid>
              <Divider light />
              <StyledGrid container justify="space-between" alignItems="center">
                <TableContainer>
                  <Table stickyHeader>
                    <StyledTableHead>
                      <TableRow>
                        <TableCell>Alimento</TableCell>
                        <TableCell align="right">Quantidade Prevista</TableCell>
                        <TableCell align="right">
                          Quantidade Realizada
                        </TableCell>
                        <TableCell align="right">
                          Desvio (%)
                        </TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <TableBody>
                      {currentManufactoringFeed.feedFoods.map(feed => (
                        <TableRow key={feed.id}>
                          <TableCell component="th" scope="row">
                            {feed.food.name}
                          </TableCell>
                          <TableCell align="right">
                            <NumberFormatForm
                              variant="standard"
                              disabled
                              fullWidth={false}
                              fixedDecimalScale
                              decimalScale={0}
                              value={feed.forecast}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextFieldEdit
                              defaultValue={feed.performed}
                              disabled
                              item={feed}
                            />
                          </TableCell>
                          <TableCell align="right">
                            <TextFieldEdit
                              defaultValue={feed.deviation}
                              disabled
                              item={feed}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow>
                        <StyledTableCell notBorderBottom rowSpan={4} colSpan={2} />
                        <StyledTableCell align="left">
                          Total Realizado
                        </StyledTableCell>
                        <TableCell align="right">
                          {formatNumber(currentManufactoringFeed.total_performed, 0, 0)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell align="left">Total Desvio</StyledTableCell>
                        <TableCell align="right">{`${formatNumber(
                          currentManufactoringFeed.deviation,
                          2,
                          2
                        )} %`}</TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell align="left">% MS</StyledTableCell>
                        <TableCell align="right">
                          {`${formatNumber(currentManufactoringFeed.percent_ms, 2, 2)} %`}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableCell align="left">Custo/Ton</StyledTableCell>
                        <TableCell align="right">
                          {`R$ ${formatNumber(currentManufactoringFeed.price_ton, 2, 2)}`}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <StyledGrid container justify="flex-end" spacing={2}>
                  <Grid
                    item
                    sm={3}
                    xs={12}
                    style={{ margin: '6px 16px 0px 0px' }}
                  >
                    Funcionário:
                    {' '}
                    <strong>
                      {currentManufactoringFeed.employee && currentManufactoringFeed.employee.name}
                    </strong>
                  </Grid>
                </StyledGrid>
              </StyledGrid>
            </StyledPaper>
          )}
          <CloseButton stype="button" onClick={handleCloseModal}>
            <CancelIcon fontSize="large" variant="outlined" />
          </CloseButton>
        </Container>
      </Fade>
    </Modal>
  );
}

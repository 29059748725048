/* eslint-disable use-isnan */
/* eslint-disable array-callback-return */
import React, { useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import {
  Grid,
  Button,
  Table,
  TableRow,
  TableCell,
  Typography,
  Checkbox,
} from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import { getReportEconomicClosingResumeV2 } from '~/hooks/apiV2/Report/economicClosingResume/useReportEconomicClosingResumeV2';

import {
  Container,
  StyledPaper,
  StyledTableContainer,
  StyledTableHead,
  StyledTableBody,
  ContentPrint,
  Header,
  StyledPaperTwo,
  StyledButton,
} from './styles';

import Print from './Print';
import Filter from './Components/Filter';

import { formatNumber } from '~/utils/format';
import Loader from '~/components/Loader';
import { format } from 'date-fns';

export default function EconomicClosingResume() {
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [economicResumn, setEconomicResumn] = useState([]);
  const [inputAnimalsTotal, setInputAnimalsTotal] = useState(0);
  const [outputAnimalsTotal, setOutputAnimalsTotal] = useState(0);
  const [inputWeightMedia, setInputWeightMedia] = useState(0);
  const [outputWeightMedia, setOutputWeightMedia] = useState(0);
  const [inputWeightMediaArroba, setInputWeightMediaArroba] = useState(0);
  const [carcassWeight, setCarcassWeight] = useState(0);
  const [confinamentDays, setConfinamentDays] = useState(0);
  const [gmd, setGmd] = useState(0);
  const [arrobaAtGain, setArrobaAtGain] = useState(0);
  const [animalCost, setAnimalCost] = useState(0);
  const [feedCostDay, setFeedCostDay] = useState(0);
  const [arrobaCol, setArrobaCol] = useState(0);
  const [arrobaProd, setArrobaProd] = useState(0);
  const [valueArrobaSale, setValueArrobaSale] = useState(0);
  const [valueRevenueHead, setValueRevenueHead] = useState(0);
  const [balance, setBalance] = useState(0);
  const [feedCostLot, setFeedCostLot] = useState(0);
  const [costLotTotal, setCostLotTotal] = useState(0);
  const [data, setData] = useState({});
  const [lotNotToShow, setLotNotToShow] = useState([]);

  const PrintScreen = useRef();

  async function handleGenarateReport(isLotsHidden) {
    setLoading(true);
    if (!isLotsHidden) {
      setLotNotToShow([]);
    }

    const reportEconomicClosingResume = await getReportEconomicClosingResumeV2(
      format(new Date(initialDate), 'yyyy-MM-dd'),
      format(new Date(finalDate), 'yyyy-MM-dd'),
      isLotsHidden ? lotNotToShow : undefined
    )

    setEconomicResumn(reportEconomicClosingResume.resultLot);
    setData(reportEconomicClosingResume.resultsResume)
    setInputAnimalsTotal(reportEconomicClosingResume.resultsResume.inputAnimals);
    setOutputAnimalsTotal(reportEconomicClosingResume.resultsResume.outputAnimals);
    setInputWeightMedia(reportEconomicClosingResume.resultsResume.avereigthWeightInput);
    setOutputWeightMedia(reportEconomicClosingResume.resultsResume.avereigthWeightOutput);
    setInputWeightMediaArroba(reportEconomicClosingResume.resultsResume.avereigthWeightInputArroba);
    setCarcassWeight(reportEconomicClosingResume.resultsResume.carcassWeightData);
    setConfinamentDays(reportEconomicClosingResume.resultsResume.confinamentDaysData);
    setGmd(reportEconomicClosingResume.resultsResume.gmdData);
    setArrobaAtGain(reportEconomicClosingResume.resultsResume.arrobaData);
    setAnimalCost(reportEconomicClosingResume.resultsResume.animalCostData);
    setFeedCostDay(reportEconomicClosingResume.resultsResume.feedCostDayData);
    setArrobaProd(reportEconomicClosingResume.resultsResume.arriobaProdData);
    setArrobaCol(reportEconomicClosingResume.resultsResume.arrobaColData);
    setValueArrobaSale(reportEconomicClosingResume.resultsResume.valueArrobaSaleData);
    setValueRevenueHead(reportEconomicClosingResume.resultsResume.valueRevenueHeadData);
    setBalance(reportEconomicClosingResume.resultsResume.balanceData);
    setFeedCostLot(reportEconomicClosingResume.resultsResume.feedCostlotData);
    setCostLotTotal(reportEconomicClosingResume.resultsResume.costLotTotalData);
    setLoading(false);
  }

  return (
    <>
      <center>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs sm md lg xl>
              <Filter
                initialDate={initialDate}
                setInitialDate={setInitialDate}
                finalDate={finalDate}
                setFinalDate={setFinalDate}
                handleGenerateReport={handleGenarateReport}
              />
            </Grid>
            <Grid item xs="auto" sm="auto">
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={economicResumn.length === 0}
                    startIcon={<PrintIcon />}
                  >
                    Imprimir
                  </Button>
                )}
                content={() => PrintScreen.current}
              />
              <div style={{ marginTop: 10 }}>
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button"
                  table="table-to-xls"
                  filename="tablexls"
                  sheet="tablexls"
                  buttonText="Exportar"
                  style={{
                    padding: 0,
                  }}
                />
              </div>
            </Grid>
          </Grid>

          {loading ? (
            <Loader />
          ) : (
            <>
              <StyledPaper>
                {lotNotToShow && lotNotToShow.length > 0 && (
                  <Grid item xs={12} sm>
                    <StyledButton
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleGenarateReport(true)
                      }
                    >
                      Atualizar
                    </StyledButton>
                  </Grid>
                )}
                <StyledTableContainer>
                  <Table stickyHeader id="table-to-xls">
                    <StyledTableHead>
                      <TableRow>
                        <TableCell>Lote</TableCell>
                        <TableCell colSpan="4">Entrada</TableCell>
                        <TableCell colSpan="3">Saída</TableCell>
                        <TableCell colSpan="11">Resultados</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Ocultar</TableCell>
                        <TableCell>Lote</TableCell>
                        <TableCell>Qtd</TableCell>
                        <TableCell>R$/an</TableCell>
                        <TableCell>Peso vivo/an</TableCell>
                        <TableCell>Peso car/an</TableCell>
                        <TableCell>Qtd</TableCell>
                        <TableCell>Peso vivo/an</TableCell>
                        <TableCell>Peso car/an</TableCell>
                        <TableCell>% RC</TableCell>
                        <TableCell>GMD</TableCell>
                        <TableCell>GMC</TableCell>
                        <TableCell>@ col</TableCell>
                        <TableCell>Dias de trato</TableCell>
                        <TableCell>Custo alim./dia</TableCell>
                        <TableCell>Custo alim. total</TableCell>
                        <TableCell>Custo total an/dia</TableCell>
                        <TableCell>Custo total do lote</TableCell>
                        <TableCell>R$ venda @</TableCell>
                        <TableCell>R$ venda an</TableCell>
                        <TableCell>R$ venda lote</TableCell>
                        <TableCell>Custo @col</TableCell>
                        <TableCell>Custo @prod</TableCell>
                        <TableCell>Bal/an</TableCell>
                        <TableCell>Rent. a.m.</TableCell>
                        <TableCell>Lucr. a.m.</TableCell>
                      </TableRow>
                    </StyledTableHead>
                    <StyledTableBody>
                      {economicResumn && economicResumn.length
                        ? economicResumn.map(resum => (
                          <TableRow key={resum.secure_id}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                defaultChecked={!!lotNotToShow.find(lot => lot === resum.lot_code)}
                                onClick={e => {
                                  if (e.target.checked === false) {
                                    setLotNotToShow(
                                      lotNotToShow.filter(
                                        value => value !== resum.lot_code
                                      )
                                    );
                                  } else {
                                    setLotNotToShow([
                                      ...lotNotToShow,
                                      resum.lot_code,
                                    ]);
                                  }
                                }}
                              />
                            </TableCell>
                            <TableCell>{resum.lot_code}</TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {resum.input_amount_animals}
                            </TableCell>
                            <TableCell>{formatNumber(resum.input_value_head, 2, 2)}</TableCell>
                            <TableCell>
                              {formatNumber(resum.input_average_weight, 2, 2)}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.input_at, 2, 2)}
                            </TableCell>
                            <TableCell style={{ textAlign: 'center' }}>
                              {resum.output_amount_animals}
                            </TableCell>
                            <TableCell>
                              {formatNumber(
                                resum.output_average_weight,
                                2,
                                2
                              )}
                            </TableCell>
                            <TableCell>
                              {formatNumber(
                                resum.output_animal_carcass_weight,
                                2,
                                2
                              )}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.output_carcass_yield, 2, 2)}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.gmd, 2, 2)}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.gmc, 2, 2)}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.arroba_at_gain, 2, 2)}
                            </TableCell>
                            <TableCell>{resum.confinament_days}</TableCell>
                            <TableCell>
                              {formatNumber(resum.feed_cost_head_day, 2, 2)}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.feed_cost_lot, 2, 2)}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.final_cost_head_day, 2, 2)}
                            </TableCell>

                            <TableCell>
                              {formatNumber(resum.final_cost_lot, 2, 2)}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.value_arroba_sale, 2, 2)}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.value_revenue_head, 2, 2)}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.value_revenue_total, 2, 2)}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.cost_arroba_gain, 2, 2)}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.costAtProduce, 2, 2)}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.balance_animal, 2, 2)}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.rentability_total, 2, 2)}
                            </TableCell>
                            <TableCell>
                              {formatNumber(resum.profitability_total, 2, 2)}
                            </TableCell>
                          </TableRow>
                        ))
                        : ''}
                    </StyledTableBody>
                  </Table>
                </StyledTableContainer>
              </StyledPaper>
              <StyledPaperTwo>
                <Header>
                  <h3>Resumo</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Total de lotes encerrados:
                      </Typography>
                      <strong>{economicResumn.length}</strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Entrada de Animais:
                      </Typography>
                      <strong>{inputAnimalsTotal}</strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Animais abatidos:
                      </Typography>
                      <strong>{outputAnimalsTotal}</strong>
                    </Grid>
                  </Grid>
                  <Grid item xs ms>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso vivo de entrada (kgPV/an):
                      </Typography>
                      <strong>
                        {formatNumber(
                          inputWeightMedia / inputAnimalsTotal,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso vivo de entrada (@/an):
                      </Typography>
                      <strong>
                        {formatNumber(
                          inputWeightMediaArroba / inputAnimalsTotal,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginTop: 24 }}
                    >
                      <Typography variant="body2" component="h5">
                        Peso vivo de saída (kgPV/an):
                      </Typography>
                      <strong>
                        {formatNumber(
                          outputWeightMedia / outputAnimalsTotal,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso de carcaça (Kg/an):
                      </Typography>
                      <strong>
                        {formatNumber(carcassWeight / outputAnimalsTotal, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso de carcaça (@/an):
                      </Typography>
                      <strong>
                        {formatNumber(
                          carcassWeight / outputAnimalsTotal / 15,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Rendimento de carcaça (%):
                      </Typography>
                      <strong>
                        {formatNumber(
                          (carcassWeight / outputWeightMedia) * 100,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>

                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Dias médio de confinamento:
                      </Typography>
                      <strong>
                        {formatNumber(
                          confinamentDays / inputAnimalsTotal,
                          0,
                          0
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Ganho médio diário:
                      </Typography>
                      <strong>
                        {formatNumber(gmd / outputAnimalsTotal, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Ganho (@/an):
                      </Typography>
                      <strong>
                        {formatNumber(arrobaAtGain / outputAnimalsTotal, 2, 2)}
                      </strong>
                    </Grid>
                  </Grid>
                  <Grid item xs ms>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo de compra de animais ($/an):
                      </Typography>
                      <strong>
                        {formatNumber(animalCost / outputAnimalsTotal, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo de compra de animais ($/@):
                      </Typography>
                      <strong>
                        {formatNumber(
                          animalCost /
                          inputAnimalsTotal /
                          (inputWeightMediaArroba / inputAnimalsTotal),
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo alimentar ($/an/dia):
                      </Typography>
                      <strong>
                        {formatNumber(feedCostDay / outputAnimalsTotal, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo alimentar total ($/an):
                      </Typography>
                      <strong>
                        {formatNumber(feedCostLot / outputAnimalsTotal, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo total ($/an):
                      </Typography>
                      <strong>
                        {formatNumber(costLotTotal / outputAnimalsTotal, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo da arroba colocada ($/@):
                      </Typography>
                      <strong>
                        {formatNumber(arrobaCol / outputAnimalsTotal, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Custo da arroba produzida ($/@):
                      </Typography>
                      <strong>
                        {formatNumber(arrobaProd / outputAnimalsTotal, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Preço de venda ($/an):
                      </Typography>
                      <strong>
                        {formatNumber(
                          valueRevenueHead / outputAnimalsTotal,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Preço de venda ($/@):
                      </Typography>
                      <strong>
                        {formatNumber(
                          valueArrobaSale / outputAnimalsTotal,
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Balanço ($/an):
                      </Typography>
                      <strong>
                        {formatNumber(balance / outputAnimalsTotal, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Rentabilidade (%/a.m.):
                      </Typography>
                      <strong>
                        {formatNumber(
                          ((balance /
                            outputAnimalsTotal /
                            (costLotTotal / outputAnimalsTotal)) *
                            100) /
                          (confinamentDays / inputAnimalsTotal / 30.4),
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Lucratividade (%/a.m.):
                      </Typography>
                      <strong>
                        {formatNumber(
                          ((balance /
                            outputAnimalsTotal /
                            (valueRevenueHead / outputAnimalsTotal)) *
                            100) /
                          (confinamentDays / inputAnimalsTotal / 30.4),
                          2,
                          2
                        )}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaperTwo>
            </>
          )}
        </Container>
      </center>
      <ContentPrint>
        <Print
          ref={PrintScreen}
          activeLots={economicResumn}
          date={initialDate}
          data={data}
        />
      </ContentPrint>
    </>
  );
}

import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

import colors from '~/styles/colors';
import SmallLoader from '~/components/SmallLoader';

export default function ActionLoader({ text, color }) {
  return (
    <Container color={color}>
      <span>{text}</span>
      <SmallLoader color={color} />
    </Container>
  );
}

ActionLoader.defaultProps = {
  color: colors.grey,
};

ActionLoader.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
};

import React, { useState, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Grid, Button, Typography } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import Chart from 'react-apexcharts';

//Query
import { useApiV2AnimalOutput } from '~/hooks/apiV2/Report/animalOutput/useApiV2AnimalOutput'

import {
  Container,
  StyledPaper,
  Header,
  SubHeader,
  StyledDivider,
  SubItem,
  SubItemRight,
  ContentChart,
  ContentPrint,
} from './styles';

import Print from './Print';
import Filter from './Components/Filter';

import { formatNumber } from '~/utils/format';
import Loader from '~/components/Loader';

const labels = ['Compra', 'Operacional', 'Sanitário', 'Alimentação'];

const optionsChart = {
  labels,
  colors: ['#14a36c', '#205678', '#edab47', '#782050'],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: 'bottom',
        },
      },
    },
  ],
  legend: {
    formatter(val, opts) {
      return `${val} - ${Number(
        opts.w.globals.seriesPercent[opts.seriesIndex]
      ).toFixed(2)}%`;
    },
  },
};

export default function AnimalOutput() {
  const [filters, setFilters] = useState({
    date: new Date(),
    lot: '',
    client: '',
    provider: ''
  })

  const { data: animalOutput, isLoading } = useApiV2AnimalOutput(filters);

  const PrintScreen = useRef();

  return (
    <>
      <center>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs sm md lg xl>
              <Filter
                setFilters={setFilters}
              />
            </Grid>
            <Grid item xs="auto" sm="auto">
              <ReactToPrint
                trigger={() => (
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={animalOutput && Object.keys(animalOutput).length === 0}
                    startIcon={<PrintIcon />}
                  >
                    Imprimir
                  </Button>
                )}
                content={() => PrintScreen.current}
              />
            </Grid>
          </Grid>

          {isLoading ? (
            <Loader />
          ) : (
            <>
              <StyledPaper>
                <Header>
                  <h3>Indentificação</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Data de entrada:
                      </Typography>
                      <strong>{animalOutput.input_date}</strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Fornecedor:
                      </Typography>
                      <strong>
                        {animalOutput.providers &&
                          animalOutput.providers
                            .map(provider => provider)
                            .join(', ')}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Qtd. de animais:
                      </Typography>
                      <strong>{animalOutput.amount_animals}</strong>
                    </Grid>
                  </Grid>

                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Data de abate:
                      </Typography>
                      <strong>{animalOutput.output_date}</strong>
                    </Grid>
                    <Grid container alignItems="center" wrap>
                      <Typography variant="body2" component="h5">
                        Clientes:
                      </Typography>
                      <strong>
                        {animalOutput.clients &&
                          animalOutput.clients.map(client => client).join(', ')}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Dias de conf.:
                      </Typography>
                      <strong>{formatNumber(animalOutput.total_day)}</strong>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaper>

              <StyledPaper>
                <Header>
                  <h3>Desempenho</h3>
                </Header>
                <Grid container>
                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso de entrada [Kg/cab]:
                      </Typography>
                      <strong>
                        {formatNumber(animalOutput.average_input_weight, 0, 4)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso de saída [Kg/cab]:
                      </Typography>
                      <strong>
                        {formatNumber(animalOutput.average_output_weight, 0, 4)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        GPT [Kg/cab]:
                      </Typography>
                      <strong>
                        {formatNumber(animalOutput.total_weight_gain, 0, 4)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        GMD [Kg/cab/dia]:
                      </Typography>
                      <strong>
                        {formatNumber(
                          animalOutput.average_daily_weight_gain,
                          0,
                          4
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Peso de carcaça [Kg/cab]:
                      </Typography>
                      <strong>
                        {formatNumber(
                          animalOutput.average_carcass_weight,
                          0,
                          4
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        RC [%]:
                      </Typography>
                      <strong>
                        {formatNumber(animalOutput.average_carcass_yield, 2, 2)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        GTC [Kg/cab]:
                      </Typography>
                      <strong>
                        {formatNumber(animalOutput.total_carcass_gain, 0, 4)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        GMC [Kg/cab/dia]:
                      </Typography>
                      <strong>
                        {formatNumber(
                          animalOutput.average_daily_carcass_gain,
                          0,
                          4
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Rend. ganho [%]:
                      </Typography>
                      <strong>
                        {formatNumber(animalOutput.gain_yield, 2, 2)}
                      </strong>
                    </Grid>
                  </Grid>

                  <Grid item xs sm>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Cons. MO [Kg]:
                      </Typography>
                      <strong>
                        {formatNumber(animalOutput.total_consumption_mo, 0, 4)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Cons. MS [Kg]:
                      </Typography>
                      <strong>
                        {formatNumber(animalOutput.total_consumption_ms, 0, 4)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Cons. MO/dia [Kg/cab]:
                      </Typography>
                      <strong>
                        {formatNumber(
                          animalOutput.average_daily_consumption_mo,
                          0,
                          4
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Cons. MS/dia [Kg/cab]:
                      </Typography>
                      <strong>
                        {formatNumber(
                          animalOutput.average_daily_consumption_ms,
                          0,
                          4
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Conv. Alimentar [KgMS/KgPV]:
                      </Typography>
                      <strong>
                        {formatNumber(animalOutput.food_conversion, 0, 4)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Efec. Aliementar [KgPV/KgMS]:
                      </Typography>
                      <strong>
                        {formatNumber(animalOutput.food_efficiency, 0, 4)}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Cons. MS em PV [%]:
                      </Typography>
                      <strong>
                        {formatNumber(
                          animalOutput.average_pv_consumption_ms,
                          0,
                          4
                        )}
                      </strong>
                    </Grid>
                    <Grid container alignItems="center">
                      <Typography variant="body2" component="h5">
                        Efec. Biológica [KgMS/@]:
                      </Typography>
                      <strong>
                        {formatNumber(animalOutput.biological_efficiency, 0, 4)}
                      </strong>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledPaper>

              <StyledPaper>
                <Header>
                  <h3>Análise Financeira</h3>
                </Header>
                <Grid container>
                  <Grid item xs={12} sm>
                    <SubHeader>
                      <MdSubdirectoryArrowRight size={20} />
                      <h4>Custos</h4>
                    </SubHeader>
                    <SubItem>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Compra [R$]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.total_input_price, 2, 2)}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Compra por animal [R$/cab]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.average_input_price, 2, 2)}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Operacional [R$]:
                        </Typography>
                        <strong>
                          {formatNumber(
                            animalOutput.total_operational_costs,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Operacional por animal [R$/cab]:
                        </Typography>
                        <strong>
                          {formatNumber(
                            animalOutput.average_operational_costs,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Sanitário [R$]:
                        </Typography>
                        <strong>
                          {formatNumber(
                            animalOutput.total_sanitary_costs,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Sanitário por animal [R$/cab]:
                        </Typography>
                        <strong>
                          {formatNumber(
                            animalOutput.average_sanitary_costs,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Alimentação [R$]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.total_feed_costs, 2, 2)}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Alimentação por animal [R$/cab]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.average_feed_costs, 2, 2)}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          @ colocada [R$]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.costs_arroba_placed, 2, 2)}
                        </strong>
                      </Grid>
                      <Grid container>
                        <Typography variant="body2" component="h5">
                          @ produzida [R$]:
                        </Typography>
                        <strong>
                          {formatNumber(
                            animalOutput.costs_arroba_produced,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Custo total [R$]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.total_costs, 2, 2)}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Custo por animal [R$/cab]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.average_costs, 2, 2)}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Custo por animal [R$/cab/dia]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.costs_animal_day, 2, 2)}
                        </strong>
                      </Grid>
                    </SubItem>
                  </Grid>
                  <Grid item xs={12} sm>
                    {Object.keys(animalOutput).length > 0 && (
                      <ContentChart>
                        <Chart
                          type="pie"
                          options={optionsChart}
                          series={[
                            animalOutput.total_input_price,
                            animalOutput.total_operational_costs,
                            animalOutput.total_sanitary_costs,
                            animalOutput.total_feed_costs,
                          ]}
                        />
                      </ContentChart>
                    )}
                  </Grid>
                </Grid>

                <StyledDivider light />

                <Grid container>
                  <Grid item xs sm>
                    <SubHeader>
                      <MdSubdirectoryArrowRight size={20} />
                      <h4>Receita</h4>
                    </SubHeader>
                    <SubItem>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Qtd. de venda [@/cab]:
                        </Typography>
                        <strong>
                          {formatNumber(
                            animalOutput.average_arroba_animal,
                            2,
                            2
                          )}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Venda [R$/@]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.real_arroba_price, 2, 2)}
                        </strong>
                      </Grid>
                    </SubItem>
                  </Grid>
                  <Grid item xs sm>
                    <SubItemRight>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Receita total [R$]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.total_income, 2, 2)}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Receita por animal [R$/cab]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.average_income, 2, 2)}
                        </strong>
                      </Grid>
                    </SubItemRight>
                  </Grid>
                </Grid>

                <StyledDivider light />

                <Grid container>
                  <Grid item xs sm>
                    <SubHeader>
                      <MdSubdirectoryArrowRight size={20} />
                      <h4>Balanço</h4>
                    </SubHeader>
                    <SubItem>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Rent. no período [%]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.period_yield, 2, 2)}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Rent. no mês [%]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.month_yield, 2, 2)}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Total diárias:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.daily, 2, 2)}
                        </strong>
                      </Grid>
                    </SubItem>
                  </Grid>
                  <Grid item xs sm>
                    <SubItemRight>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Balanço total [R$]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.total_balance, 2, 2)}
                        </strong>
                      </Grid>
                      <Grid container alignItems="center">
                        <Typography variant="body2" component="h5">
                          Balanço por animal [R$/cab]:
                        </Typography>
                        <strong>
                          {formatNumber(animalOutput.average_balance, 2, 2)}
                        </strong>
                      </Grid>
                    </SubItemRight>
                  </Grid>
                </Grid>
              </StyledPaper>
            </>
          )}

          <ContentPrint>
            {animalOutput && (
              <Print ref={PrintScreen} animalOutput={animalOutput} />
            )}
          </ContentPrint>
        </Container>
      </center>
    </>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { Container, StyledPaper, StyledIconButton } from './styles';

import { regexDate } from '~/utils/common';
import InputDatePicker from '~/components/InputDatePicker';

export default function TransferLotFilter({ date, setDate }) {
  function handleChangeDate(newDate, value) {
    if (value && !regexDate.test(value)) return;

    setDate(newDate);
  }

  return (
    <Container>
      <StyledPaper>
        <Grid container justify="center" spacing={2}>
          <Grid item>
            <InputDatePicker
              label="Filtrar por Data Inicial"
              maxDate={new Date()}
              selectedDate={date}
              handleChangeDate={(currentDate, value) =>
                handleChangeDate(currentDate, value)
              }
              InputProps={{
                startAdornment: (
                  <StyledIconButton onClick={() => setDate(null)}>
                    <ClearIcon />
                  </StyledIconButton>
                ),
              }}
              InputAdornmentProps={{
                position: 'end',
              }}
            />
          </Grid>
        </Grid>
      </StyledPaper>
    </Container>
  );
}

TransferLotFilter.defaultProps = {
  date: null,
};

TransferLotFilter.propTypes = {
  date: PropTypes.string,
  setDate: PropTypes.func.isRequired,
};

import React, { useMemo } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

// MUI
import { Grid, TextField, Hidden, MenuItem } from '@material-ui/core';

// Styled Components
import { parseISO } from 'date-fns';
import {
  Container,
  StyledPaper,
  StyledGrid,
  Error,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';

// Query
import { useApiV2GlobalSetting } from '~/hooks/apiV2/global/setting/useApiV2GlobalSetting';
import { useApiV2SelectScrapOutput } from '~/hooks/apiV2/animalHandling/ScrapOutput/useApiV2SelectScrapOutput';
import { useApiV2EditScrapOutput } from '~/hooks/apiV2/animalHandling/ScrapOutput/useApiV2EditScrapOutput';

// Locals
import { formatNamePaddock } from '~/utils/format';
import history from '~/services/history';
import Loader from '~/components/Loader';
import InputDatePicker from '~/components/InputDatePicker';
import NumberFormatForm from '~/components/NumberFormatForm';
import ButtonSubmit from '~/components/ButtonSubmit';

export default function EditScrapOutput({ match }) {
  const secure_id = useMemo(() => match.params.id, [match.params.id]);

  // Query
  const { data: settingData } = useApiV2GlobalSetting();
  const { data: currentData, isLoading } = useApiV2SelectScrapOutput(secure_id);
  const editAnimalScrapOutput = useApiV2EditScrapOutput(secure_id);

  const schema = Yup.object().shape({
    obs: Yup.string().nullable(),
  });

  async function handleSubmit(data) {
    const newData = {
      obs: data.obs
    };
    await editAnimalScrapOutput.mutateAsync(newData);
  }

  function handleBack() {
    history.goBack();
  }

  return (
    <center>
      <Container>
        {isLoading ? (
          <Loader />
        ) : (
          <StyledPaper>
            <Formik
              enableReinitialize={secure_id}
              validationSchema={schema}
              initialValues={{
                date: currentData.date,
                lot_id: currentData.originLot.secure_id,
                sex: currentData.movementHistory[0].animal.sex,
                breed_id: currentData.movementHistory[0].animal.breed.secure_id,
                age: currentData.movementHistory[0].animal.age,
                type: currentData.movementHistory[0].animal.type,
                amount_animals: currentData.amount_animals,
                obs: currentData.obs,
              }}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, resetForm, errors, touched, values, isSubmitting }) => (
                <Form>
                  <StyledGrid container justify="space-around" spacing={2}>
                    <Grid item xs={10} sm>
                      <InputDatePicker
                        name="date"
                        inputVariant="outlined"
                        fullWidth
                        disabled
                        maxDate={!!currentData && currentData.date ? parseISO(currentData.date) : new Date()}
                        minDate={!!currentData && currentData.date ? parseISO(currentData.date) : null}
                        selectedDate={values.date}
                      />
                    </Grid>
                    <Hidden xsDown>
                      <Grid item sm />
                    </Hidden>
                    <Hidden xsDown>
                      <Grid item sm />
                    </Hidden>
                  </StyledGrid>

                  <StyledGrid container justify="space-around" spacing={2}>
                    <Grid item xs={10} sm>
                      <TextField
                        name="lot_id"
                        label="Lote"
                        variant="outlined"
                        fullWidth
                        select
                        disabled
                        size="small"
                        InputLabelProps={{
                          shrink: values.age,
                        }}
                        value={values.lot_id}
                      >
                        <MenuItem value={currentData.originLot.secure_id}>
                          {settingData && `${currentData.originLot.code} - ${formatNamePaddock(settingData.settings, currentData.originLot.paddock)}`}
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={10} sm>
                      <TextField
                        name="sex"
                        label="Sexo"
                        variant="outlined"
                        fullWidth
                        select
                        disabled
                        size="small"
                        value={values.sex}
                      >
                        <MenuItem key={currentData.movementHistory[0].animal.sex} value={currentData.movementHistory[0].animal.sex}>
                          {currentData.movementHistory[0].animal.sex}
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={10} sm>
                      <TextField
                        name="breed_id"
                        label="Raça"
                        variant="outlined"
                        fullWidth
                        select
                        disabled
                        size="small"
                        value={values.breed_id}
                      >
                        <MenuItem key={currentData.movementHistory[0].animal.breed.secure_id} value={currentData.movementHistory[0].animal.breed.secure_id}>
                          {currentData.movementHistory[0].animal.breed.name}
                        </MenuItem>
                      </TextField>
                      <ErrorMessage name="breed_id" component={Error} />
                    </Grid>
                  </StyledGrid>

                  <StyledGrid container justify="space-around" spacing={2}>
                    <Grid item xs={10} sm>
                      <TextField
                        name="age"
                        label="Idade"
                        variant="outlined"
                        fullWidth
                        select
                        disabled
                        size="small"
                        value={values.age}
                      >
                        <MenuItem key={currentData.movementHistory[0].animal.age} value={currentData.movementHistory[0].animal.age}>
                          {currentData.movementHistory[0].animal.age}
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={10} sm>
                      <TextField
                        name="type"
                        label="Tipo"
                        variant="outlined"
                        fullWidth
                        select
                        disabled
                        size="small"
                        value={values.type}
                      >
                        <MenuItem key={currentData.movementHistory[0].animal.type} value={currentData.movementHistory[0].animal.type}>
                          {currentData.movementHistory[0].animal.type}
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={10} sm>
                      <NumberFormatForm
                        label="Qtd. no lote"
                        disabled
                        value={currentData ? currentData.amount_animals_active : 0}
                      />
                    </Grid>
                  </StyledGrid>

                  <StyledGrid container justify="space-around" spacing={2}>
                    <Grid item sm xs={10}>
                      <NumberFormatForm
                        name="amount_animals"
                        label="Quantidade"
                        variant="outlined"
                        size="small"
                        disabled
                        fullWidth
                        allowNegative={false}
                        autoComplete="nope"
                        decimalScale={0}
                        value={values.amount_animals}
                      />
                    </Grid>

                    <Hidden xsDown>
                      <Grid item sm />
                    </Hidden>
                  </StyledGrid>

                  <StyledGrid container justify="space-around" spacing={2}>
                    <Grid item xs={10} sm>
                      <TextField
                        name="obs"
                        variant="outlined"
                        label="Observação"
                        autoComplete="nope"
                        fullWidth
                        size="small"
                        value={values.obs}
                        onChange={e => setFieldValue('obs', e.target.value)}
                      />
                    </Grid>
                  </StyledGrid>

                  <ContentSubmitButtons>
                    <ButtonSubmit title="Cadastrar" loading={isSubmitting} />
                    <StyledSubmitButton
                      variant="outlined"
                      color="primary"
                      onClick={handleBack}
                    >
                      Cancelar
                    </StyledSubmitButton>
                  </ContentSubmitButtons>
                </Form>
              )}
            </Formik>
          </StyledPaper>
        )}
      </Container>
    </center>
  );
}

EditScrapOutput.defaultProps = {
  match: null,
};

EditScrapOutput.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
};

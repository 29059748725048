import { useAuthContext } from '~/contexts/AuthContext';

function checkAuth({ roles, permissions }, checkRole, checkPermission) {
  let hasRole = false;
  let hasPermission = false;
  if (checkRole) {
    hasRole = roles.some(role => checkRole.includes(role));
  }

  if (hasRole) {
    return hasRole
  }

  if (checkPermission) {
    hasPermission = permissions.some(permission => checkPermission.includes(permission));
  }

  if (hasPermission) {
    return hasPermission
  }

  return false;
}

export default function Can({ children, checkRole, checkPermission }) {
  const { user } = useAuthContext()

  if (typeof children === 'function') {
    return children(checkAuth(
      { permissions: user.permissions, roles: user.roles },
      checkRole,
      checkPermission
    ));
  }

  return checkAuth(
    { permissions: user.permissions, roles: user.roles },
    checkRole,
    checkPermission
  )
    && children;
}

import {
  Backdrop, Divider, Fade,
  FormControl, FormControlLabel, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, TextField
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import React from 'react';
import { toast } from 'react-toastify';
import Loader from '~/components/Loader';
import { useV2GetLotEntry } from '~/hooks/apiV2/animalHandling/LotsEntry/useV2GetLotEntry';
import {
  CloseButton, Container, StyledGrid,
  StyledGridItem, StyledPaper, StyledPaperLoader
} from './styles';

const optionsSex = [
  { value: 'Macho', label: 'Macho' },
  { value: 'Femea', label: 'Fêmea' },
];

export default function ModalAnimalInput({ visibleModal, handleCloseModal, secureIdView }) {
  const { data, isLoading, error } = useV2GetLotEntry(secureIdView)

  if (error) {
    handleCloseModal();
    if (!!error.response.data?.type) {
      if (error.response.data?.type === 'warning') {
        toast.warning(error.response.data.message)
      } else {
        toast.error(error.response.data.message)
      }
    } else {
      toast.error('Falha ao tentar carregar a entrada de animais')
    }
  }

  return (
    <Modal
      open={visibleModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visibleModal}>
        <Container>
          {isLoading ? (
            <StyledPaperLoader>
              <Loader />
            </StyledPaperLoader>
          ) : !!data && (
            <StyledPaper>
              <StyledGrid container justify="space-between" alignItems="center">
                <StyledGridItem item sm xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-lot-input-label">Lote</InputLabel>
                    <Select
                      labelId="select-lot-label"
                      id="select-lot"
                      value={data.firstAnimal.lot.secure_id}
                      disabled
                      labelWidth={35}
                    >
                      <MenuItem
                        id={data.firstAnimal.lot.secure_id}
                        value={data.firstAnimal.lot.secure_id}
                      >
                        {data.firstAnimal.lot.code}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
                <StyledGridItem item sm xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-diet-input-label">Dieta</InputLabel>
                    <Select
                      labelId="select-diet-label"
                      id="select-diet"
                      value={data.firstAnimal.lot.diet.secure_id}
                      disabled
                      labelWidth={40}
                    >
                      <MenuItem
                        id={String(data.firstAnimal.lot.diet.secure_id)}
                        value={data.firstAnimal.lot.diet.secure_id}
                      >
                        {data.firstAnimal.lot.diet.name}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
                <StyledGridItem item sm xs={12}>
                  <RadioGroup
                    name="radio-group-sex"
                    value={data.firstAnimal.sex}
                    row
                  >
                    {optionsSex.map(sex => (
                      <FormControlLabel
                        key={sex.value}
                        value={sex.value}
                        disabled
                        control={<Radio color="primary" size="small" />}
                        label={sex.label}
                      />
                    ))}
                  </RadioGroup>
                </StyledGridItem>
              </StyledGrid>

              <StyledGrid container justify="space-between" alignItems="center">
                <StyledGridItem item sm xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-age-input-label">Idade</InputLabel>
                    <Select
                      labelId="select-age-label"
                      id="select-age"
                      value={data.firstAnimal.age}
                      disabled
                      labelWidth={40}
                    >
                      <MenuItem
                        id={String(data.firstAnimal.age)}
                        value={data.firstAnimal.age}
                      >
                        {data.firstAnimal.age}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
                <StyledGridItem item sm xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-type-input-label">Tipo</InputLabel>
                    <Select
                      labelId="select-type-label"
                      id="select-type"
                      value={data.firstAnimal.type}
                      disabled
                      labelWidth={35}
                    >
                      <MenuItem
                        id={String(data.firstAnimal.type)}
                        value={data.firstAnimal.type}
                      >
                        {data.firstAnimal.type}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
                <StyledGridItem item sm xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-body_size-input-label">
                      Tamanho Corporal
                    </InputLabel>
                    <Select
                      labelId="select-body_size-label"
                      id="select-body_size"
                      value={data.firstAnimal.body_size}
                      disabled
                      labelWidth={135}
                    >
                      <MenuItem
                        id={String(data.firstAnimal.body_size)}
                        value={data.firstAnimal.body_size}
                      >
                        {data.firstAnimal.body_size}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
              </StyledGrid>

              <StyledGrid container justify="space-between" alignItems="center">
                <StyledGridItem item sm xs={12}>
                  <TextField
                    id="amount_animals"
                    label="Quantidade"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                    value={data.amount_animals}
                  />
                </StyledGridItem>
                <StyledGridItem item sm xs={12}>
                  <TextField
                    id="average_weight"
                    label="Peso médio"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                    value={data.firstAnimal.input_weight}
                  />
                </StyledGridItem>
                <StyledGridItem item sm xs={12}>
                  <TextField
                    id="date"
                    label="Data"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                    value={data.date}
                  />
                </StyledGridItem>
              </StyledGrid>

              <Divider light />

              <StyledGrid container justify="space-between" alignItems="center">
                <StyledGridItem item sm="auto" xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-breed-input-label">Raça</InputLabel>
                    <Select
                      labelId="select-breed-label"
                      id="select-breed"
                      value={data.firstAnimal.breed.secure_id}
                      disabled
                      labelWidth={40}
                    >
                      <MenuItem
                        id={String(data.firstAnimal.breed.secure_id)}
                        value={data.firstAnimal.breed.secure_id}
                      >
                        {data.firstAnimal.breed.name}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
                <StyledGridItem item sm xs={12}>
                  <TextField
                    id="breed"
                    label="Descrição"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled
                    value={data.firstAnimal.breed.description}
                  />
                </StyledGridItem>
              </StyledGrid>

              <Divider light />

              <StyledGrid container justify="space-between" alignItems="center">
                <StyledGridItem item sm xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-module-input-label">
                      Módulo
                    </InputLabel>
                    <Select
                      labelId="select-module-label"
                      id="select-module"
                      value={data.firstAnimal.lot.paddock.line.module.secure_id}
                      disabled
                      labelWidth={55}
                    >
                      <MenuItem
                        id={String(data.firstAnimal.lot.paddock.line.module.secure_id)}
                        value={data.firstAnimal.lot.paddock.line.module.secure_id}
                      >
                        {data.firstAnimal.lot.paddock.line.module.name}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
                <StyledGridItem item sm xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-line-input-label">Linha</InputLabel>
                    <Select
                      labelId="select-line-label"
                      id="select-line"
                      value={data.firstAnimal.lot.paddock.line.secure_id}
                      disabled
                      labelWidth={40}
                    >
                      <MenuItem
                        id={String(data.firstAnimal.lot.paddock.line.secure_id)}
                        value={data.firstAnimal.lot.paddock.line.secure_id}
                      >
                        {data.firstAnimal.lot.paddock.line.name}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
                <StyledGridItem item sm xs={12}>
                  <FormControl size="small" fullWidth variant="outlined">
                    <InputLabel id="select-paddock-input-label">
                      Piquete
                    </InputLabel>
                    <Select
                      labelId="select-paddock-label"
                      id="select-paddock"
                      value={data.firstAnimal.lot.paddock.secure_id}
                      disabled
                      labelWidth={55}
                    >
                      <MenuItem
                        id={String(data.firstAnimal.lot.paddock.secure_id)}
                        value={data.firstAnimal.lot.paddock.secure_id}
                      >
                        {data.firstAnimal.lot.paddock.name}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </StyledGridItem>
              </StyledGrid>
            </StyledPaper>
          )}

          <CloseButton stype="button" onClick={handleCloseModal}>
            <CancelIcon fontSize="large" variant="outlined" />
          </CloseButton>
        </Container>
      </Fade>
    </Modal>
  );
}

ModalAnimalInput.propTypes = {
  visibleModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  secureIdView: PropTypes.string
};

import styled from 'styled-components';
import {
	Paper,
	Grid,
	FormControl,
	Button,
	TableHead,
	IconButton,
	Divider,
	Table,
	TableCell,
} from '@material-ui/core';
import { darken } from 'polished';
import colors from '~/styles/colors';

export const Container = styled.div`
  max-width: 1280px;
  width: 80%;
  padding: 40px 0px;

  text-align: left;
`;

export const StyledPaper = styled(Paper)`
  width: 100%;
  padding: 20px;

  text-align: left;
`;

export const StyledGridItem = styled(Grid)`
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

export const StyledFormControl = styled(FormControl)`
  margin-top: 8px;
`;

export const ContentItem = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const ContentSubmitButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${props => props.marginTop || 32}px;
`;

export const StyledSubmitButton = styled(Button)`
  margin-left: ${props => props.marginLeft || 0}px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }

  @media (max-width: 546px) {
    margin-top: 15px;
  }
`;

export const StyledTable = styled(Table)`
  min-width: 580px;
`;

export const StyledTableHead = styled(TableHead)`
  tr {
    th {
      color: ${colors.primary};
    }
  }
`;

export const StyledButtonMix = styled(Button)`
  font-size: 0.875rem;
  text-align: left;
  font-weight: 400;
  text-transform: capitalize;
  line-height: 1.43;

  display: block;
  background: ${colors.darkWhite};
`;

export const FoodName = styled.div`
  padding-left: 8px;

  span {
    font-size: 0.875rem;
    text-align: left;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 1.43;
    vertical-align: inherit;
  }
`;

export const StyledGridFood = styled(Grid)`
  margin-top: 4px;
  margin-bottom: 22px;

  max-width: 960px;
`;

export const StyledIconButton = styled(IconButton)`
  margin-top: 4px;
  color: ${colors.white};
  background: ${colors.success};
  transition: background 1s;

  &:hover {
    background: ${darken(0.06, colors.success)};
  }
`;

export const StyledButton = styled(Button)`
  margin-top: 1px;
  color: ${props => props.color};
  background: ${props => props.background};

  &:hover {
    background: ${props => props.background};
  }
`;

export const StyledDivider = styled(Divider)`
  margin: 12px 0px;
`;

export const StyledTableCell = styled(TableCell)`
  border-bottom: none;
`;

export const TitleTable = styled.b`
  color: ${colors.primary};
`;

export const StyledButtonMixer = styled(Button)`
  text-align: center;

  span {
    svg {
      margin-right: 3px;
    }
  }
`;

export const Error = styled.span`
  margin: 8px 14px 0px;
  font-size: 0.75rem;
  min-height: 1em;
  text-align: left;
  font-family: Roboto, 'helvetica';
  font-weight: 400;
  line-height: 1em;
  color: ${colors.error};
`;

export const TableCellStyled = styled(TableCell)`
  color: ${colors.error};
`;

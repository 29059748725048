import { useQuery } from "react-query"
import apiV2 from "~/services/apiV2";

export async function getSummaryFood(initialDate = new Date(), finalDate = new Date()) {
  const { data } = await apiV2.get('/v1/feedlot/report/summaryFood', {
    params: { initialDate, finalDate }
  });

  return data
}

export function useApiV2SummaryFood(initialDate, finalDate) {
  return useQuery(['summaryFood', initialDate, finalDate],
    () => getSummaryFood(initialDate, finalDate),
    {
      staleTime: 1000 * 60 * 5, // 5 minutes,
    }
  );
}

import React from 'react';
import * as Yup from 'yup';
import { Formik, Form, ErrorMessage } from 'formik';
import { Grid, TextField } from '@material-ui/core';

// Query
import { useApiV2AddOcurrenceType } from '~/hooks/apiV2/register/ocurrenceType/useApiV2AddOcurrenceType'

import {
  Container,
  StyledPaper,
  ContentItem,
  Error,
  ContentSubmitButtons,
  StyledSubmitButton,
} from './styles';

import history from '~/services/history';
import ButtonSubmit from '~/components/ButtonSubmit';

const schema = Yup.object().shape({
  name: Yup.string().required('Informe o nome'),
  description: Yup.string().required('Informe a descrição'),
});

export default function AddOcurrencesType() {
  // Query
  const addOcurrencesType = useApiV2AddOcurrenceType();

  async function handleSubmit(data) {
    await addOcurrencesType.mutateAsync(data)
  }

  function handleBack() {
    history.goBack();
  }

  return (
    <center>
      <Container>
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            name: '',
            description: ''
          }}
        >
          {({ handleChange, errors, touched, isSubmitting }) => (
            <Form>
              <ContentItem>
                <StyledPaper>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md>
                      <TextField
                        name="name"
                        variant="outlined"
                        label="Nome *"
                        autoComplete="off"
                        size="small"
                        fullWidth
                        autoFocus
                        error={!!errors.name && touched.name}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="name" component={Error} />
                    </Grid>
                    <Grid item xs={12} sm={12} md>
                      <TextField
                        name="description"
                        variant="outlined"
                        label="Descrição *"
                        autoComplete="off"
                        size="small"
                        fullWidth
                        error={!!errors.description && touched.description}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="description" component={Error} />
                    </Grid>
                  </Grid>

                  <ContentSubmitButtons>
                    <ButtonSubmit title="Cadastrar" loading={isSubmitting} />
                    <StyledSubmitButton
                      variant="outlined"
                      color="primary"
                      type="button"
                      onClick={handleBack}
                    >
                      Cancelar
                    </StyledSubmitButton>
                  </ContentSubmitButtons>
                </StyledPaper>
              </ContentItem>
            </Form>
          )}
        </Formik>
      </Container>
    </center>
  );
}

import React, { useMemo } from 'react';
import { toast } from 'react-toastify'
import { Grid, TextField } from '@material-ui/core';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';

import {
  Container,
  Content,
  StyledGridItem,
  Error,
  StyledSubmitButton,
} from './styles';

import logo from '~/assets/images/logo_black.png';
import colors from '~/styles/colors';
import apiV2 from '~/services/apiV2';
import history from '~/services/history';

const schema = Yup.object().shape({
  password: Yup.string()
    .required('Senha obrigatória')
    .min(6, 'Necessário pelo menos 6 caracteres'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Confirmação diferente da senha')
    .required('Necessário confirmar sua senha')
    .min(6, 'Necessário pelo menos 6 caracteres'),
});

export default function ResetPassword({ match }) {
  const token = useMemo(() => match.params.token, [match.params.token]);

  const handleResetPassword = async ({ password }) => {
    try {
      await apiV2.put('/v1/feedlot/auth/forgotPassword', {
        password,
        token
      })

      toast.success('Senha alterada com sucesso!');
      history.push('/');
    } catch (error) {
      const type = error.response.data?.type ? error.response.data.type : 'error'
      const message = error.response.data?.message ? error.response.data.message : 'Ocorreu um erro ao mudar a senha.'
      toast[type](message)
    }
  }

  return (
    <Container>
      <Content>
        <img src={logo} alt="logo" />
        <Formik
          validationSchema={schema}
          initialValues={{ password: '', password_confirmation: '' }}
          onSubmit={handleResetPassword}
        >
          {({ handleChange, errors, touched, isSubmitting }) => (
            <Form style={{ width: '90%' }}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <StyledGridItem item marginTop={50}>
                  <TextField
                    name="password"
                    label="Sua nova senha"
                    type="password"
                    variant="outlined"
                    autoFocus
                    fullWidth
                    error={errors.password && touched.password}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="password" component={Error} />
                </StyledGridItem>

                <StyledGridItem item marginTop={20}>
                  <TextField
                    name="password_confirmation"
                    label="Confirmação da nova senha"
                    type="password"
                    variant="outlined"
                    fullWidth
                    error={
                      errors.password_confirmation &&
                      touched.password_confirmation
                    }
                    onChange={handleChange}
                  />
                  <ErrorMessage
                    name="password_confirmation"
                    component={Error}
                  />
                </StyledGridItem>
              </Grid>

              <center>
                <StyledGridItem item marginTop={50}>
                  <StyledSubmitButton
                    variant="contained"
                    background={colors.success}
                    color={colors.white}
                    type="submit"
                  >
                    {isSubmitting ? 'Alterando...' : 'Alterar'}
                  </StyledSubmitButton>
                </StyledGridItem>
              </center>
            </Form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

ResetPassword.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      token: PropTypes.string,
    }),
  }).isRequired,
};

import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Formik, Form, ErrorMessage } from 'formik';
import {
  Grid,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import { Autocomplete } from '@material-ui/lab';
import SaveIcon from '@material-ui/icons/Save';

import {
  Container,
  StyledPaper,
  ContentItem,
  ContentSubmitButtons,
  StyledSubmitButton,
  StyledTextField,
  Error,
} from './styles';

import { states, funcsPersonal, typesPersonal } from '~/utils/options';
import colors from '~/styles/colors';
import history from '~/services/history';
import { useApiV2AddStakeholder } from '~/hooks/apiV2/register/stakeholder/useApiV2AddStakeholder';

const schema = Yup.object().shape({
  fantasyName: Yup.string().nullable(),
  name: Yup.string().required('Digite a razão social'),
  contactName: Yup.string().required('Digite um nome para contato'),
  email: Yup.string().email('E-mail inválido').required('Digite um e-mail'),
  telephone: Yup.string().required('Digite o telefone'),
  doc: Yup.string().required('Digite o documento'),
  cep: Yup.string().required('Digite o CEP'),
  addressStreet: Yup.string().required('Digite o endereço'),
  addressNumber: Yup.string().required('Digite o número'),
  neighborhood: Yup.string().required('Digite o bairro'),
  city: Yup.string().required('Digite a cidade'),
  state: Yup.string().required('Selecione o estado'),
  complement: Yup.string().nullable(),
  client: Yup.boolean().when('provider', {
    is: false,
    then: Yup.boolean()
      .required('Selecione um campo')
      .oneOf([true], 'Selecione um campo'),
  }),
  provider: Yup.boolean(),
  obs: Yup.string().nullable(),
});

export default function Add({ closeModal, type }) {
  const addStakeholder = useApiV2AddStakeholder(closeModal);

  async function handleAdd(data) {
    const newData = { ...data };
    delete newData.client;
    delete newData.provider;
    if (data.client && data.provider) {
      newData.type = 'both';
    } else if (data.client) {
      newData.type = 'client';
    } else {
      newData.type = 'provider';
    }

    if (data.subtype === 'individual') {
      newData.fantasyName = null;
    }

    await addStakeholder.mutateAsync(newData)
  }

  function handleBack() {
    if (!closeModal) {
      history.goBack();
    } else {
      closeModal();
    }
  }

  return (
    <center>
      <Container isModal={!!closeModal}>
        <Formik
          validationSchema={schema}
          initialValues={{
            name: '',
            fantasyName: '',
            doc: '',
            contactName: '',
            telephone: '',
            email: '',
            cep: '',
            addressStreet: '',
            addressNumber: '',
            neighborhood: '',
            city: '',
            state: '',
            complement: null,
            obs: null,
            client: type ? type.split(',').some(client => client === 'client' || client === 'both') : false,
            provider: type ? type.split(',').some(provider => provider === 'provider' || provider === 'both') : false,
            subtype: 'individual',
          }}
          onSubmit={handleAdd}
        >
          {({ handleChange, setFieldValue, values, errors, touched, isSubmitting }) => (
            <Form>
              <ContentItem>
                <StyledPaper>
                  <Grid container spacing={3}>
                    <Grid item sm xs={12}>
                      <FormGroup row>
                        {funcsPersonal.map(func => (
                          <FormControlLabel
                            label={func.label}
                            key={func.id}
                            control={
                              <Checkbox
                                color="primary"
                                checked={values[func.value]}
                                name={func.value}
                                onChange={handleChange}
                              />
                            }
                          />
                        ))}
                      </FormGroup>
                      <ErrorMessage
                        style={{ display: 'block' }}
                        name="client"
                        component={Error}
                      />
                    </Grid>
                    <Grid item sm xs={12}>
                      <RadioGroup
                        name="subtype"
                        value={values.subtype}
                        row
                        onChange={handleChange}
                      >
                        {typesPersonal.map(type => (
                          <FormControlLabel
                            key={type.id}
                            value={type.value}
                            control={<Radio color="primary" size="small" />}
                            label={type.label}
                          />
                        ))}
                      </RadioGroup>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item sm xs={12}>
                      <StyledTextField
                        id="name"
                        name="name"
                        variant="outlined"
                        label={
                          values.subtype === 'individual'
                            ? 'Nome'
                            : 'Razão social'
                        }
                        autoComplete="nope"
                        InputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        size="small"
                        fullWidth
                        required
                        error={!!errors.name && touched.name}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="name" component={Error} />
                    </Grid>
                  </Grid>

                  {values.subtype === 'legal_entity' && (
                    <Grid container spacing={3}>
                      <Grid item sm xs={12}>
                        <StyledTextField
                          id="fantasyName"
                          name="fantasyName"
                          variant="outlined"
                          label="Nome fantasia"
                          InputProps={{
                            form: {
                              autocomplete: 'off',
                            },
                          }}
                          autoComplete="nope"
                          size="small"
                          fullWidth
                          error={!!errors.fantasyName && touched.fantasyName}
                          onChange={handleChange}
                        />
                        <ErrorMessage name="fantasyName" component={Error} />
                      </Grid>
                    </Grid>
                  )}

                  <Grid container spacing={3}>
                    <Grid item sm xs={12}>
                      <NumberFormat
                        name="doc"
                        label={values.subtype === 'individual' ? 'CPF' : 'CNPJ'}
                        placeholder={
                          values.subtype === 'individual'
                            ? '000.000.000-00'
                            : '00.000.000/0000-0'
                        }
                        format={
                          values.subtype === 'individual'
                            ? '###.###.###-##'
                            : '##.###.###/####-##'
                        }
                        variant="outlined"
                        mask="_"
                        customInput={StyledTextField}
                        autoComplete="nope"
                        size="small"
                        fullWidth
                        InputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        required
                        error={!!errors.doc && touched.doc}
                        onValueChange={val =>
                          setFieldValue('doc', val.formattedValue || '')
                        }
                      />
                      <ErrorMessage name="doc" component={Error} />
                    </Grid>
                    <Grid item sm xs={12}>
                      <StyledTextField
                        name="email"
                        type="email"
                        variant="outlined"
                        label="Email"
                        InputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        autoComplete="nope"
                        size="small"
                        fullWidth
                        required
                        error={!!errors.email && touched.email}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="email" component={Error} />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item sm xs={12}>
                      <NumberFormat
                        name="telephone"
                        label="Telefone"
                        placeholder="(00) 00000-0000"
                        format="(##) #####-####"
                        variant="outlined"
                        InputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        mask="_"
                        customInput={StyledTextField}
                        autoComplete="nope"
                        size="small"
                        fullWidth
                        required
                        error={!!errors.telephone && touched.telephone}
                        onValueChange={val =>
                          setFieldValue('telephone', val.formattedValue || '')
                        }
                      />
                      <ErrorMessage name="telephone" component={Error} />
                    </Grid>
                    <Grid item sm xs={12}>
                      <StyledTextField
                        id="contactName"
                        name="contactName"
                        variant="outlined"
                        label="Nome do contato"
                        autoComplete="nope"
                        InputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        size="small"
                        fullWidth
                        required
                        error={!!errors.contactName && touched.contactName}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="contactName" component={Error} />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item sm xs={12}>
                      <NumberFormat
                        name="cep"
                        label="CEP"
                        variant="outlined"
                        placeholder="00000-000"
                        format="#####-###"
                        InputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        mask="_"
                        customInput={StyledTextField}
                        autoComplete="nope"
                        size="small"
                        fullWidth
                        required
                        error={!!errors.cep && touched.cep}
                        onValueChange={val =>
                          setFieldValue('cep', val.formattedValue || '')
                        }
                      />
                      <ErrorMessage name="cep" component={Error} />
                    </Grid>
                    <Grid item sm xs={12} />
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item sm xs={12}>
                      <StyledTextField
                        id="addressStreet"
                        name="addressStreet"
                        variant="outlined"
                        label="Rua"
                        autoComplete="nope"
                        size="small"
                        InputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        fullWidth
                        required
                        error={
                          !!errors.addressStreet && touched.addressStreet
                        }
                        onChange={handleChange}
                      />
                      <ErrorMessage name="addressStreet" component={Error} />
                    </Grid>
                    <Grid item sm xs="auto">
                      <StyledTextField
                        name="addressNumber"
                        variant="outlined"
                        label="Número"
                        autoComplete="nope"
                        InputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        size="small"
                        fullWidth
                        required
                        error={
                          !!errors.addressNumber && touched.addressNumber
                        }
                        onChange={handleChange}
                      />
                      <ErrorMessage name="addressNumber" component={Error} />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item sm xs={12}>
                      <StyledTextField
                        name="neighborhood"
                        variant="outlined"
                        label="Bairro"
                        autoComplete="nope"
                        InputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        size="small"
                        fullWidth
                        required
                        error={!!errors.neighborhood && touched.neighborhood}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="neighborhood" component={Error} />
                    </Grid>
                    <Grid item sm xs={12}>
                      <StyledTextField
                        id="city"
                        name="city"
                        variant="outlined"
                        label="Cidade"
                        InputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        autoComplete="nope"
                        size="small"
                        fullWidth
                        required
                        error={!!errors.city && touched.city}
                        onChange={handleChange}
                      />
                      <ErrorMessage name="city" component={Error} />
                    </Grid>
                    <Grid item sm xs="auto">
                      <Autocomplete
                        id="state-input"
                        name="state"
                        options={states.map(state => state.value)}
                        getOptionLabel={option => option}
                        value={values.state}
                        size="small"
                        autoComplete="off"
                        onChange={(e, value) => {
                          value ? setFieldValue('state', value) : setFieldValue('state', '');
                        }}
                        renderInput={params => (
                          <StyledTextField
                            {...params}
                            name="state"
                            label="Estado"
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            fullWidth
                            required
                            error={!!errors.state && touched.state}
                          />
                        )}
                      />
                      <ErrorMessage name="state" component={Error} />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item sm xs={12}>
                      <StyledTextField
                        id="complement"
                        name="complement"
                        variant="outlined"
                        InputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        label="Complemento"
                        autoComplete="nope"
                        fullWidth
                        size="small"
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item sm xs={12}>
                      <StyledTextField
                        id="obs"
                        name="obs"
                        variant="outlined"
                        InputProps={{
                          form: {
                            autocomplete: 'off',
                          },
                        }}
                        label="Observação"
                        autoComplete="nope"
                        fullWidth
                        size="small"
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>

                  <ContentSubmitButtons>
                    <StyledSubmitButton
                      variant="contained"
                      color={colors.white}
                      background={colors.success}
                      startIcon={<SaveIcon />}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Cadastrando...' : 'Cadastrar'}
                    </StyledSubmitButton>
                    <StyledSubmitButton
                      color="primary"
                      variant="outlined"
                      type="button"
                      onClick={handleBack}
                    >
                      Cancelar
                    </StyledSubmitButton>
                  </ContentSubmitButtons>
                </StyledPaper>
              </ContentItem>
            </Form>
          )}
        </Formik>
      </Container>
    </center>
  );
}

Add.defaultProps = {
  closeModal: null,
  type: null,
};

Add.propTypes = {
  closeModal: PropTypes.func,
  type: PropTypes.string,
};

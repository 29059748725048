import React
//  { useState, useRef, useEffect }
  from 'react';
// import { useField } from '@rocketseat/unform';
// import { toast } from 'react-toastify';
import defaultAvatar from '~/assets/images/avatar.png';

import { Container } from './styles';

// import apiV2 from '~/services/apiV2';

export default function AvatarInput() {
  // const { defaultValue, registerField } = useField('avatar');
  // const [currentFile, setCurrentFile] = useState(
  //   defaultValue && defaultValue.id
  // );
  // const [preview, setPreview] = useState(defaultValue && defaultValue.url);
  // const [onErrorAvatar, setOnErrorAvatar] = useState(null);

  // const ref = useRef();

  // async function handleChange(e) {
  //   try {
  //     const data = new FormData();

  //     data.append('file', e.target.files[0]);

  //     const response = await apiV2.put('/v1/feedlot/user/avatar', data);

  //     const { file, url } = response.data;

  //     toast.success('Avatar atualizada com sucesso!');

  //     setCurrentFile(file);
  //     setPreview(url);
  //   } catch(error) {
  //     const type = error.response.data?.type ? error.response.data.type : 'error';
  //     const message = error.response.data?.message ? error.response.data.message : 'Não foi possível atualizar avatar!';
  //     toast[type](message);
  //   }
  // }

  // function onError() {
  //   setOnErrorAvatar(defaultAvatar);
  // }

  // useEffect(() => {
  //   if (ref.current) {
  //     registerField({
  //       name: 'avatar_id',
  //       ref: ref.current,
  //       path: 'dataset.file',
  //     });
  //   }
  // }, [ref, registerField]);

  return (
    <Container>
      <label htmlFor="avatar">
        <img
          src={
            // preview || onErrorAvatar || 
            defaultAvatar
          }
          alt="avatar"
          // onError={onError}
        />

        {/* <input
          type="file"
          id="avatar"
          accept="image/*"
          data-file={currentFile}
          onChange={handleChange}
          ref={ref}
        /> */}
      </label>
    </Container>
  );
}
